import { Component, OnInit } from '@angular/core'
import { BuilderService } from '../builder-services/builder.service'

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss']
})
export class StarRatingComponent implements OnInit {
  constructor(private builderService: BuilderService) {}

  ngOnInit(): void {}

  stars: number[] = [1, 2, 3, 4, 5]
  selectedValue: number = 0
  isMouseover = true

  countStar(star: number) {
    this.isMouseover = false
    this.selectedValue = star
    this.builderService.starRating = star
  }

  addClass(star: number) {
    if (this.isMouseover) {
      this.selectedValue = star
    }
  }

  removeClass() {
    if (this.isMouseover) {
      this.selectedValue = 0
    }
  }
}
