import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { PluginsServiceService } from 'src/app/plugins-loader/plugins-service.service';
import { PointsComponent } from 'plugins/points/components/points/points.component';
import { PointsService } from './components/points.service';
import { RouterModule, Routes, CanActivate } from '@angular/router';
import { DatabaseService } from 'src/app/builder-services/database.service';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { Authguard } from 'src/app/guards/authguard.service';
import { cancelledUserGuard } from 'src/app/guards/cancelled-user.guard';
import { restrictedPagesGuard } from 'src/app/guards/restricted-pages.guard';


// const routes: Routes = [
//   { path: '', component: PointsComponent, 
//     canActivate: [Authguard, cancelledUserGuard, restrictedPagesGuard]

//    },
// ];

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    // RouterModule.forChild(routes)
  ]
})
export class PointsModule { 
  constructor(
    private db: DatabaseService,
    private pluginsService: PluginsServiceService,
    public builderService: BuilderService,
    public Points: PointsService
  ) {
    // Listener from the plugins loader
    this.pluginsService.getPlugingsServices().subscribe((data: any) => {
      // Check if the addon is loaded
      this.db.getDatabase('projects/' + this.builderService.selectedProject + '/addons').subscribe((response: any) => {
        console.log(response);
        for (let index = 0; index < response.length; index++) {
          const element = response[index];
          if (element.name == "Points" && element.isChecked) {
            this.Points.init_plugin();
          }
        }
      });
    });
  }  
}
