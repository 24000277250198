import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { HelpersService } from 'src/app/helpers.service'
import { LoginService } from 'src/app/login/login.service'
import { Observable } from 'rxjs'
import { UserDataTable } from './admin-dashboard/admin-dashboard.component'

@Injectable({
  providedIn: 'root'
})
export class AdminDashboardService {
  builderUrl: string
  user_email: any
  token: any

  constructor(
    private helpers: HelpersService,
    private builderService: BuilderService,
    private loginService: LoginService,
    private http: HttpClient
  ) {
    this.builderUrl = this.helpers.getBuilderUrl()
    this.user_email = this.loginService.getCurrentUser()
  }

  options = {
    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  }

  // Updated method to retrieve data from builderService and return an observable
  getAllData(): Observable<UserDataTable[]> {
    return new Observable((observer) => {
      this.builderService.getProjects(this.user_email).subscribe((allProjects) => {
        const userArr: UserDataTable[] = []
        for (const key in allProjects) {
          if (allProjects.hasOwnProperty(key)) {
            const value = allProjects[key] as UserDataTable
            userArr.push(value)
          }
        }
        observer.next(userArr) // Emit the userArr
        observer.complete() // Complete the observable
      })
    })
  }

  // The function getTrials now returns a properly typed Observable
  getTrials(): Observable<{ [key: string]: UserDataTable }> {
    let postData = new URLSearchParams()
    postData.set('process', 'sync_ui')
    postData.set('email', this.user_email)
    this.token = this.builderService.getToken()
    postData.set('token', this.token)

    return this.http.post<{ [key: string]: UserDataTable }>(this.builderUrl + 'get_active_trials', postData.toString(), this.options)
  }

  getSubscriptions(): Observable<{ [key: string]: UserDataTable }> {
    let postData = new URLSearchParams()
    postData.set('process', 'sync_ui')
    postData.set('email', this.user_email)
    this.token = this.builderService.getToken()
    postData.set('token', this.token)

    return this.http.post<{ [key: string]: UserDataTable }>(this.builderUrl + 'get_all_premium', postData.toString(), this.options)
  }
}
