<ul [class.open]="builderService.sidebarOpened" class="sidabarWrapper">
  <button *ngIf="!mobile" (click)="toggleSideMenu()" [class.openSidebarArr]="builderService.sidebarOpened" class="sidebarToggle">
    <span class="material-icons-outlined"> keyboard_double_arrow_right </span>
  </button>
  <li title="Builder" routerLinkActive="active" [routerLink]="['/']" [routerLinkActiveOptions]="{ exact: true }" *ngIf="!isOrderManager && !isProductManager">
    <button mat-icon-button>
      <span class="material-icons-outlined"> phone_iphone </span>
    </button>
    <p *ngIf="mobile || builderService.sidebarOpened">{{ 'Builder' | translate }}</p>
  </li>
  <li title="Views Designer" routerLinkActive="active" [routerLink]="['/views']" [routerLinkActiveOptions]="{ exact: true }" *ngIf="!isOrderManager && !isProductManager">
    <button mat-icon-button>
      <img src="../../assets/images/sidebar/pages.png" alt="" />
      <img src="../../assets/images/sidebar/pages-active.png" class="active" alt="" />
    </button>
    <p *ngIf="mobile || builderService.sidebarOpened">{{ 'Views Design' | translate }}</p>
  </li>
  <li title="Mobile Preview" routerLinkActive="active" [routerLink]="['/app-preview']" *ngIf="!isOrderManager && !isProductManager">
    <button mat-icon-button>
      <span class="material-icons-outlined"> visibility </span>
    </button>
    <p *ngIf="mobile || builderService.sidebarOpened">{{ 'Mobile Preview' | translate }}</p>
  </li>
  <li class="toggler" #submenuItem id="ecommerceMainMenuTab" (click)="toggleSidebar('hasSubmenu', submenuItem)">
    <button mat-icon-button>
      <span class="material-icons-outlined expandMoreBtn"> expand_more </span>
      <div>
        <span class="material-icons-outlined ecommerce"> monetization_on </span>
        <p *ngIf="mobile || builderService.sidebarOpened">{{ 'E-Commerce' | translate }}</p>
      </div>
    </button>
    <ul title="E-Commerce" id="ecommerceSideMenu">
      <li (click)="$event.stopPropagation()" title="Products" routerLinkActive="active" id="productsSidebarTab" [routerLink]="['/products']" *ngIf="!isOrderManager">
        <button mat-icon-button>
          <span class="material-icons-outlined"> inventory_2 </span>
        </button>
        <p *ngIf="mobile || builderService.sidebarOpened">{{ 'Products' | translate }}</p>
      </li>
      <li (click)="$event.stopPropagation()" title="Categories" routerLinkActive="active" id="categoriesSidebarTab" [routerLink]="['/categories/categories']" *ngIf="!isOrderManager">
        <button mat-icon-button>
          <span class="material-icons-outlined"> dialpad </span>
        </button>
        <p *ngIf="mobile || builderService.sidebarOpened">{{ 'Categories' | translate }}</p>
      </li>
      <li (click)="$event.stopPropagation()" title="Shipping" routerLinkActive="active" id="shippingSidebarTab" [routerLink]="['/shipping']" *ngIf="!isOrderManager">
        <button mat-icon-button>
          <span class="material-icons-outlined"> local_shipping </span>
        </button>
        <p *ngIf="mobile || builderService.sidebarOpened">{{ 'Shipping' | translate }}</p>
      </li>

      <li (click)="$event.stopPropagation()" title="Settings" routerLinkActive="active" id="settingsSidebarTab" [routerLink]="['/e-commerce/settings']" *ngIf="!isOrderManager">
        <button mat-icon-button>
          <span class="material-icons-outlined"> settings </span>
        </button>
        <p *ngIf="mobile || builderService.sidebarOpened">{{ 'settings' | translate }}</p>
      </li>
      <li (click)="$event.stopPropagation()" title="Shipping" routerLinkActive="active" id="paymentMethods" [routerLink]="['/e-commerce/payments']" *ngIf="!isOrderManager">
        <button mat-icon-button>
          <span class="material-icons-outlined"> shopping_cart </span>
        </button>
        <p *ngIf="mobile || builderService.sidebarOpened">{{ 'payments Methods' | translate }}</p>
      </li>
      <li (click)="$event.stopPropagation()" title="Shipping" routerLinkActive="active" id="ordersSidebarTab" [routerLink]="['/orders']" *ngIf="!isProductManager">
        <button mat-icon-button>
          <span class="material-icons-outlined"> shopping_cart </span>
        </button>
        <p *ngIf="mobile || builderService.sidebarOpened">{{ 'Orders' | translate }}</p>
      </li>
      <li (click)="$event.stopPropagation()" title="Products" routerLinkActive="active" id="importerSidebarTab" [routerLink]="['/stacks-importer']" *ngIf="!isOrderManager">
        <button mat-icon-button>
          <span class="material-icons-outlined"> publish </span>
        </button>
        <p *ngIf="mobile || builderService.sidebarOpened">{{ 'Importer' | translate }}</p>
      </li>
    </ul>
  </li>
  <li class="toggler" #mobileBuild (click)="toggleSidebar('hasSubmenu', mobileBuild)" *ngIf="!isOrderManager && !isProductManager">
    <button mat-icon-button class="doubleIcon">
      <span [class.changeArrowDrop]="appDropdown" class="material-icons-outlined expandMoreBtn"> expand_more </span>
      <div>
        <img [class.open]="!builderService.sidebarOpened" class="android" src="../../assets/images/sidebar/android.png" alt="" />
        <!-- <img [class.open]="!builderService.sidebarOpened" src="../../assets/images/sidebar/ios.png" alt=""> -->
        <p *ngIf="mobile || builderService.sidebarOpened">{{ 'Generate App' | translate }}</p>
      </div>
    </button>
    <ul title="Generate Apps">
      <li (click)="$event.stopPropagation()" title="Android Build" routerLinkActive="active" [routerLink]="['/android']">
        <button mat-icon-button>
          <img src="../../assets/images/sidebar/android.png" alt="" />
          <img src="../../assets/images/sidebar/android-active.png" class="active" alt="" />
        </button>
        <p *ngIf="mobile || builderService.sidebarOpened">{{ 'Android Build' | translate }}</p>
      </li>
      <li (click)="$event.stopPropagation()" title="Android Build Apk" routerLinkActive="active" [routerLink]="['/android-apk']" *ngIf="isAdmin()">
        <button mat-icon-button>
          <img src="../../assets/images/sidebar/android.png" alt="" />
          <img src="../../assets/images/sidebar/android-active.png" class="active" alt="" />
        </button>
        <p *ngIf="mobile || builderService.sidebarOpened">{{ 'Android Build Apk' | translate }}</p>
      </li>
      <li (click)="$event.stopPropagation()" title="IOS Build" routerLinkActive="active" [routerLink]="['/ios']">
        <button mat-icon-button *ngIf="packageType != 'Starter'">
          <img src="../../assets/images/sidebar/ios.png" alt="" />
          <img src="../../assets/images/sidebar/ios-active.png" class="active" alt="" />
        </button>

        <button mat-icon-button *ngIf="packageType === 'Starter'">
          <img src="../../assets/images/sidebar/ios-disabled.png" class="" alt="" />
        </button>
        <p *ngIf="mobile || builderService.sidebarOpened" [ngClass]="{ 'restricted-page': packageType === 'Starter' }">{{ 'IOS Build' | translate }}</p>
      </li>
    </ul>
  </li>
  <li title="Templates" routerLinkActive="active" [routerLink]="['/templates']" [routerLinkActiveOptions]="{ exact: true }" *ngIf="!isOrderManager && !isProductManager">
    <button mat-icon-button>
      <img class="templateImg" src="../../assets/images/sidebar/templates.png" alt="" />
      <img src="../../assets/images/sidebar/templates-active.png" class="active" alt="" />
    </button>
    <p *ngIf="mobile || builderService.sidebarOpened">{{ 'Templates' | translate }}</p>
  </li>
  <!-- Subscribers -->
  <li (click)="$event.stopPropagation(); isActive = true" *ngIf="loginService.getCurrentUser().admin && !isOrderManager && !isProductManager" title="Users" routerLinkActive="active" [routerLink]="['/subscribers']">
    <button mat-icon-button>
      <span class="material-symbols-outlined" [ngClass]="{ active: isActive === true }"> group </span>
    </button>
    <p *ngIf="mobile || builderService.sidebarOpened">{{ 'Subscribers' | translate }}</p>
  </li>
  <li title="Connectivity" routerLinkActive="active" [routerLink]="['/connectivity']" *ngIf="!isOrderManager && !isProductManager">
    <button mat-icon-button>
      <!-- <mat-icon class="material-icons material-icons-outlined">power</mat-icon> -->
      <img src="../../assets/images/sidebar/plugin.png" alt="" />
      <img src="../../assets/images/sidebar/plugin-active.png" class="active" alt="" />
    </button>
    <p *ngIf="mobile || builderService.sidebarOpened">{{ 'Connectivity' | translate }}</p>
  </li>
  <!-- App users -->
  <li (click)="$event.stopPropagation(); isActive = true" title="Users" routerLinkActive="active" [routerLink]="['/users']" *ngIf="!isOrderManager && !isProductManager">
    <button mat-icon-button>
      <span class="material-symbols-outlined" [ngClass]="{ active: isActive === true }"> group </span>
    </button>
    <p *ngIf="mobile || builderService.sidebarOpened">{{ 'Users' | translate }}</p>
  </li>
  <!-- multilingual -->
  <li class="toggler multilingualContainer" #multilingual (click)="toggleSidebar('hasSubmenu', multilingual)" *ngIf="!isOrderManager && !isProductManager">
    <button mat-icon-button class="doubleIcon">
      <span [class.changeArrowDrop]="appDropdown" class="material-icons-outlined expandMoreBtn"> expand_more </span>
      <div>
        <img [class.open]="!builderService.sidebarOpened" class="multilingual" src="../../assets/images/sidebar/multilingual.png" alt="" />
        <p *ngIf="mobile || builderService.sidebarOpened">{{ 'Multilingual' | translate }}</p>
      </div>
    </button>
    <ul title="multilingual">
      <li (click)="$event.stopPropagation()" title="languages" routerLinkActive="active" [routerLink]="['/multilingual']" *ngIf="!isOrderManager && !isProductManager">
        <button mat-icon-button *ngIf="packageType != 'Starter'">
          <img src="../../assets/images/sidebar/multilingual.png" alt="" />
          <img src="../../assets/images/sidebar/multilingual-green.png" class="active" alt="" />
        </button>
        <button mat-icon-button *ngIf="packageType === 'Starter'">
          <img src="../../assets/images/sidebar/multilingual-disabled.png" class="" alt="" />
        </button>
        <p *ngIf="mobile || builderService.sidebarOpened" [ngClass]="{ 'restricted-page': packageType === 'Starter' }">{{ 'Languages' | translate }}</p>
      </li>
      <li (click)="$event.stopPropagation()" title="text" routerLinkActive="active" [routerLink]="['/txtTranslations']" *ngIf="!isOrderManager && !isProductManager">
        <button mat-icon-button *ngIf="packageType != 'Starter'">
          <img src="../../assets/images/sidebar/text-Translations.png" alt="" />
          <img src="../../assets/images/sidebar/text-Translations-green.png" class="active" alt="" />
        </button>
        <button mat-icon-button *ngIf="packageType === 'Starter'">
          <img src="../../assets/images/sidebar/text-Translations-disabled.png" class="" alt="" />
        </button>
        <p *ngIf="mobile || builderService.sidebarOpened" [ngClass]="{ 'restricted-page': packageType === 'Starter' }">{{ 'Texts' | translate }}</p>
      </li>
    </ul>
  </li>

  <li title="Globals" routerLinkActive="active" [routerLink]="['/globals']" *ngIf="!isOrderManager && !isProductManager">
    <button mat-icon-button>
      <img src="../../assets/images/sidebar/global.png" alt="" />
      <img src="../../assets/images/sidebar/global-active.png" class="active" alt="" />
    </button>
    <p *ngIf="mobile || builderService.sidebarOpened">{{ 'Globals' | translate }}</p>
  </li>

  <li title="Admin" *ngIf="loginService.getCurrentUser().admin" routerLinkActive="active" [routerLink]="['/admin']">
    <button mat-icon-button class="material-icons-outlined">admin_panel_settings</button>
    <p *ngIf="mobile || builderService.sidebarOpened">{{ 'Admin' | translate }}</p>
  </li>
  <li (click)="$event.stopPropagation()" title="addons" class="toggler configurationsContainer hasSubmenu" #configurations (click)="toggleSidebar('hasSubmenu', configurations)" aria-expanded="true" open *ngIf="!isOrderManager && !isProductManager">
    <button mat-icon-button class="doubleIcon">
      <span [class.changeArrowDrop]="appDropdown" class="material-icons-outlined expandMoreBtn"> expand_more </span>
      <div>
        <img [class.open]="!builderService.sidebarOpened" class="configurations" src="../../assets/images/sidebar/configuration.png" alt="" />
        <p *ngIf="mobile || builderService.sidebarOpened">{{ 'Addons' | translate }}</p>
      </div>
    </button>
    <ul title="configurations">
      <li (click)="$event.stopPropagation()" title="social" routerLinkActive="active" [routerLink]="['/social']">
        <button mat-icon-button>
          <img src="../../assets/images/sidebar/social.png" alt="" />
          <img src="../../assets/images/sidebar/social-green.png" class="active" alt="" />
        </button>
        <p *ngIf="mobile || builderService.sidebarOpened">{{ 'Login' | translate }}</p>
      </li>
      <li (click)="$event.stopPropagation()" title="Analytics" routerLinkActive="active" [routerLink]="['/analytics/page-stats']">
        <button mat-icon-button class="material-icons-outlined">
          <img src="../../assets/images/sidebar/analytics.png" alt="" />
          <img src="../../assets/images/sidebar/analytics-active.png" class="active" alt="" />
        </button>
        <p *ngIf="mobile || builderService.sidebarOpened">{{ 'Analytics' | translate }}</p>
      </li>
      <li (click)="$event.stopPropagation()" title="Push Notifications" routerLinkActive="active" [routerLink]="['/pushnotifications']">
        <button mat-icon-button>
          <img src="../../assets/images/sidebar/notification.png" alt="" />
          <img src="../../assets/images/sidebar/notification-active.png" class="active" alt="" />
        </button>
        <p *ngIf="mobile || builderService.sidebarOpened">{{ 'Push Notification' | translate }}</p>
      </li>
      <!-- adding automation notification route page  -->
      <li (click)="$event.stopPropagation();isActive=true" title="Automation Notifications" routerLinkActive="active" [routerLink]="['/automation']">
        <button mat-icon-button>
        <!-- <img src="../../assets/images/sidebar/notification.png" alt="">
          <img src="../../assets/images/sidebar/notification-active.png" class="active" alt=""> -->
          <span class="material-symbols-outlined" [ngClass]="{'active': isActive === true}">
            smart_toy
            </span>
        </button>
        <p *ngIf="mobile || builderService.sidebarOpened">{{'Automation' | translate }}</p>
      </li>
      <!-- Add your listener here -->
      <li *ngFor="let menu of menus" (click)="$event.stopPropagation()" title="{{ menu.name }}" routerLinkActive="active" [routerLink]="[menu.route]">
        <button mat-icon-button>
          <img src="../../assets/images/sidebar/{{ menu.icon }}.png" alt="" />
          <img src="../../assets/images/sidebar/{{ menu.icon }}-active.png" class="active" alt="" />
        </button>
        <p *ngIf="mobile || builderService.sidebarOpened">{{ menu.name | translate }}</p>
      </li>
      <li (click)="$event.stopPropagation()" title="Points" routerLinkActive="active" id="pointsSidebarTab" [routerLink]="['/points']">
        <button mat-icon-button *ngIf="packageType != 'Starter'">
          <img src="../../assets/images/sidebar/points.png" alt="" />
          <img src="../../assets/images/sidebar/points-active.png" class="active" alt="" />
        </button>
        <button mat-icon-button *ngIf="packageType === 'Starter'">
          <img src="../../assets/images/sidebar/points-disabled.png" class="" alt="" />
        </button>
        <p *ngIf="mobile || builderService.sidebarOpened" [ngClass]="{ 'restricted-page': packageType === 'Starter' }">{{ 'Points' | translate }}</p>
      </li>
      <li (click)="$event.stopPropagation()" title="Coupon" routerLinkActive="active" id="couponSidebarTab" [routerLink]="['/coupons']">
        <button mat-icon-button>
          <span class="material-symbols-outlined"> local_activity </span>
        </button>
        <p *ngIf="mobile || builderService.sidebarOpened">{{ 'coupons' | translate }}</p>
      </li>

      <li (click)="$event.stopPropagation()" title="Discount" routerLinkActive="active" id="couponSidebarTab" [routerLink]="['/discount']">
        <button mat-icon-button>
          <span class="material-symbols-outlined"> percent </span>
        </button>
        <p *ngIf="mobile || builderService.sidebarOpened">{{ 'Discount' | translate }}</p>
      </li>
    </ul>
  </li>
  <!-- <li   class="checklistBtn"><guided-tour *ngIf="mobile"></guided-tour></li> -->
</ul>
<!-- <div *ngIf="mobile" class="originalSidebarItem">
  <button mat-icon-button (click)="sidebarHome()">
    Pages
  </button>
</div> -->
