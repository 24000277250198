import { Component, OnInit, ViewContainerRef, ComponentRef, ViewChild } from '@angular/core'
import { BlockDataService } from '../block-services/block-data.service'
import { BlockFieldsService } from '../block-services/block-fields.service'

@Component({
  selector: 'block-settings',
  templateUrl: './block-settings.component.html',
  styleUrls: ['./block-settings.component.scss']
})
export class BlockSettingsComponent implements OnInit {
  currentBlockJSON: any

  @ViewChild('blockDataSettings', { read: ViewContainerRef })
  blockDataSettings!: ViewContainerRef

  private componentRef!: ComponentRef<any>
  blockId = ''
  // currentBlockFields : any;
  constructor(
    public blockDataService: BlockDataService,
    private viewContainerRef: ViewContainerRef,
    private blockFieldsService: BlockFieldsService
  ) {
    this.currentBlockJSON = {}
    this.blockId = this.blockDataService.getUniqueId(2)
    /**
     * when clicked inside the mobile preview, show the clicked block
     */

    this.blockDataService.getCurrentBlock().subscribe((currentBlockData) => {
      this.blockDataSettings.clear()
      /**
      let clonedComponent = this.viewContainerRef.resolveComponentFactory(currentBlockData);
      this.componentRef = this.blockDataSettings.createComponent(clonedComponent);
       */
      this.componentRef = this.blockDataSettings.createComponent(currentBlockData)
      let clonedBlockInstance = this.componentRef.instance
      clonedBlockInstance.showDataTab()
      clonedBlockInstance.blockId = this.blockFieldsService.clickedBlockId
      clonedBlockInstance.initialData = 'initial'
    })

    /**
     * when the block is added inside the mobile preview, show the clicked block
     */

    this.blockDataService.getBlockData().subscribe((blockData) => {
      this.blockDataSettings.clear()
      if (blockData.initial !== 'initial') {
        blockData.element.showData(blockData.element.blockId)
      }
    })
  }

  ngOnInit(): void {}
}
