<div class="productsWrapper">
  <div class="addProductWrapper" *ngIf="(posts | keyvalue).length === 0 && this.pageLoaded">
    <div class="imageWrapper">
      <img src="assets/images/products/add-product.png" alt="" />
    </div>
    <button [routerLink]="['new-product']">{{ 'Add New Product' | translate }}</button>
  </div>
  <div class="tableWrapper">
    <div class="tableHeader">
      <h1>POSTS</h1>
      <div class="headerBtns">
        <button [routerLink]="['../../posts/new-post']">+ ADD NEW POST</button>
        <form class="searchBar">
          <input type="text" matInput placeholder="{{ 'Search' | translate }}" />
        </form>
      </div>
    </div>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort (matSortChange)="announceSortChange($event)">
      <!-- Position Column -->
      <ng-container matColumnDef="image">
        <th mat-header-cell *matHeaderCellDef class="firstTitle">image</th>
        <td mat-cell *matCellDef="let elements"><img src="{{ elements.image }}" /></td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef id="name" mat-sort-header="name" sortActionDescription="Sort by name">
          <div class="hasIcons">name</div>
        </th>
        <td [class.productName]="mobile" mat-cell *matCellDef="let elements">{{ elements.name }}</td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th [class.hideTableHeaders]="mobile" mat-header-cell *matHeaderCellDef>
          <div class="hasIcons">date</div>
        </th>
        <td [class.hideTableHeaders]="mobile" mat-cell *matCellDef="let elements">{{ elements.date }}</td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="lastTitle">action</th>
        <td mat-cell *matCellDef="let elements">
          <button mat-icon-button (click)="editPost(elements.id)">
            <span class="mat-button-wrapper">
              <span class="material-icons material-icons-outlined editIcon">edit</span>
            </span>
          </button>
          <button mat-icon-button (click)="deletePost(elements.id, elements.image)">
            <span class="mat-button-wrapper">
              <span class="material-icons material-icons-outlined deleteIcon">delete</span>
            </span>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="postsColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: postsColumns"></tr>
    </table>
    <mat-paginator class="tablePaginator" [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons aria-label="Select page of periodic dataSource"> </mat-paginator>
  </div>
  <pre>
        {{ posts | json }}
    </pre
  >
</div>
