import { AfterViewInit, Component, OnInit } from '@angular/core'

@Component({
  selector: 'self',
  templateUrl: './self.component.html',
  styleUrls: ['./self.component.scss']
})
export class SelfComponent implements OnInit, AfterViewInit {
  constructor() {}
  ngAfterViewInit() {}

  ngOnInit(): void {}
}
