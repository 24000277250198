import { AfterViewInit, Component, Input, OnInit } from '@angular/core'
import { BlockFieldsService } from 'src/app/block-services/block-fields.service'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { BlockDataService } from '../../block-services/block-data.service'
import { CategoriesService } from './categories.service'
import { MixpanelService } from 'src/app/tracking/mixpanel.service'

@Component({
  selector: 'block-categories',
  templateUrl: './block-categories.component.html',
  styleUrls: ['./block-categories.component.scss']
})
export class BlockCategoriesComponent implements OnInit, AfterViewInit {
  previewBlock = false
  styleFields = false
  blockData: any

  previewFields = false

  blockFields: any

  blockId = ''

  categoriesData = []
  currency: any
  showCategoriesProgress = false
  categories = []

  storedCategories: any = []

  @Input() nestedBlock: any = {}
  @Input() importedBlockData: any
  /**
   * If the block is rendered from saved data, then this should be true, if added dynamically by drag and drop or click then it will be "initial"
   **/
  initialData: any = false

  constructor(
    public blockDataService: BlockDataService,
    private blockFieldsService: BlockFieldsService,
    public categoriesService: CategoriesService,
    public builderService: BuilderService,
    private mixPanel: MixpanelService
  ) {}

  ngOnInit() {
    if (this.importedBlockData) {
      this.previewBlock = true
      this.previewFields = false
      this.styleFields = false
    }
    if (this.initialData !== 'initial') {
      if (this.nestedBlock.type == 'categories') {
        this.blockData = this.nestedBlock
        this.blockId = this.nestedBlock.id
        this.previewBlock = true
      } else {
        this.blockData = this.importedBlockData ? this.importedBlockData : this.blockFieldsService.getCategoriesBlockFields()
        if (!this.blockId) {
          this.blockId = this.blockDataService.getUniqueId(2)
        }
      }
    } else {
      this.blockData = this.blockDataService.getSelectedBlockData(this.blockId)
    }
  }

  ngAfterViewInit() {
    /**
     * Initially get the categories from the localstorage
     */
    Promise.resolve().then(() => {
      let localCats = localStorage.getItem('categories')
      if (localCats) {
        this.storedCategories = JSON.parse(localCats)
      }
      if (localCats && this.initialData !== 'initial') {
        this.setCategoriesData(this.blockId, JSON.parse(localCats))
      } else {
        /**
         * Get and set if for the first time there is no categories in the localstorage
         */
        if (this.categoriesService.storedCategories.length == 0) {
          this.getCategories(this.builderService.getSelectedProject(), this.blockData.data, true)
        } else {
          this.storedCategories = this.categoriesService.storedCategories
        }
      }
    })
  }

  getCategories = (projectId: number, args: object, cached = true) => {
    this.showCategoriesProgress = true
    if (cached) {
      this.categoriesService.getCategoriesCached().subscribe(
        (response) => {
          this.showCategoriesProgress = false
          if (!response) {
            console.log('no response from categories, possibly empty result !!')
            return false
          }
          let categories: any = []
          for (const key in response) {
            if (Object.prototype.hasOwnProperty.call(response, key)) {
              const element = response[key]
              if (element.count > 0) {
                categories.push({ id: element.term_id, name: element.name, thumbnail: element.image_url })
                // this.categoriesIds.push(element.term_id);
              }
            }
          }
          this.storedCategories = categories
          this.setCategoriesData(this.blockId, categories)
          this.currency = response.currency_symbol
          return true
        },
        (err) => {
          this.showCategoriesProgress = false
        }
      )
    } else {
      this.categoriesService.getCategories(projectId, args).subscribe(
        (response) => {
          this.showCategoriesProgress = false
          if (!response) {
            console.log('no response from categories, possibly empty result !!')
            return false
          }
          let categories: any = []
          for (const key in response) {
            if (Object.prototype.hasOwnProperty.call(response, key)) {
              const element = response[key]
              if (element.count > 0) {
                categories.push({ id: element.term_id, name: element.name, thumbnail: element.image_url })
                // this.categoriesIds.push(element.term_id);
              }
            }
          }
          this.storedCategories = categories
          this.setCategoriesData(this.blockId, categories)
          this.currency = response.currency_symbol
          return true
        },
        (err) => {
          this.showCategoriesProgress = false
        }
      )
    }
    setTimeout(() => {
      this.showCategoriesProgress = false
    }, 3000)
    return true
  }

  /**
   * Getter for the Categories Data, The case of changing the modifying in one component and reflects into another component needs getters and setters
   * @param blockId
   * @returns
   */
  getCategoriesData = (blockId: string) => {
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return
    }
    let categoriesBlockFields = this.blockDataService.getSelectedBlockData(blockId)
    if (typeof categoriesBlockFields.categoriesData === 'undefined' || categoriesBlockFields.categoriesData.length === 0) {
      return false
    }
    return categoriesBlockFields.categoriesData
  }

  /**
   * Setter for the Categories Data, The case of changing the modifying in one component and reflects into another component needs getters and setters
   * @returns
   */
  setCategoriesData = (blockId: string, data: object) => {
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return
    }
    let categoriesBlockFields = this.blockDataService.getSelectedBlockData(blockId)
    categoriesBlockFields.categoriesData = data
  }

  /**
   * Appends Block to the Preview, Initial checks if the block is added as initial block addition or appended live
   */
  addBlockToPreview(initial: any = '') {
    let blockCategoriesData = {
      component: BlockCategoriesComponent,
      index: 'latest',
      element: this,
      initial: initial
    }
    this.blockDataService.sendBlockData(blockCategoriesData)
    this.builderService.blocksCollapsed = true
  }

  appendBlock($event: any) {
    if (!this.blockDataService.woocommerceActive || this.builderService.standalone) {
      return false
    }
    if (this.builderService.getTour()) {
      this.builderService.onboardingBlockAdded = true
    }
    let clonedBlockData = { ...this.blockData }
    this.blockId = this.blockDataService.appendBlocksData(clonedBlockData)
    this.addBlockToPreview()
    this.builderService.logData('Categories Block Added', 'Block Add', 'Categories Block Added').subscribe(
      (response) => {},
      (err) => {}
    )
    this.mixPanel.track('Block Added', {
      projectid: this.builderService.getSelectedProject(),
      block: 'categories'
    })
    return true
  }

  /**
   * Sync the clicked block on click inside the mobile preview
   */
  showData(blockId: any) {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      // show data of the last element instead
      blockId = this.blockDataService.getLatestBlockId()
    }
    this.blockFieldsService.clickedBlockId = blockId
    this.blockDataService.sendCurrentBlock(BlockCategoriesComponent)
  }

  queryChange(query: any, blockId: any, $event: any, type: string) {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return
    }
    let categoriesBlockFields = this.blockDataService.getSelectedBlockData(blockId)
    /**
     * Modifying Nested Object Directly doesn't work, so we have to clone the data object first then modify it
     */
    let clonedBlockFieldsData = { ...categoriesBlockFields.data }
    let data: any = ''
    if (type == 'select') {
      data = $event.value
    } else if (type == 'text') {
      data = $event.currentTarget.value
    } else if (type == 'slider') {
      data = $event.currentTarget.value
    }
    clonedBlockFieldsData[query] = data
    categoriesBlockFields.data = clonedBlockFieldsData

    let projectId = this.builderService.selectedProject
    if (query == 'categories') {
      categoriesBlockFields.data.limit = 200
    }
    this.getCategories(projectId, clonedBlockFieldsData, false)
  }

  styleChange(style: any, blockId: any, $event: any, type: string) {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return
    }
    let categoriesBlockFields = this.blockDataService.getSelectedBlockData(blockId)
    /**
     * Modifying Nested Object Directly doesn't work, so we have to clone the data object first then modify it
     */
    let clonedBlockFieldsData = { ...categoriesBlockFields.style }
    let data = ''
    if (type == 'select') {
      data = $event.value
    } else if (type == 'text') {
      data = $event.currentTarget.value
    } else if (type == 'slider') {
      data = $event.currentTarget.value + 'px'
    }

    clonedBlockFieldsData[style] = data
    categoriesBlockFields.style = clonedBlockFieldsData
  }

  getBlockFields = (id: string) => {
    if (this.importedBlockData) {
      return this.importedBlockData
    }
    return this.blockDataService.getSelectedBlockData(id)
  }

  getCategoriesList = (blockid: string) => {
    let block = this.blockDataService.getSelectedBlockData(blockid)
    return [block.data?.categories]
  }

  showStyleTab = () => {
    this.previewBlock = false
    this.previewFields = false
    this.styleFields = true
  }

  showDataTab = () => {
    this.previewBlock = false
    this.previewFields = true
    this.styleFields = false
  }

  checkSidebar = () => {
    if (!this.previewBlock && !this.previewFields && !this.styleFields) {
      return true
    }
    return false
  }

  checkPreview = () => {
    if (this.previewBlock && !this.previewFields && !this.styleFields) {
      return true
    }
    return false
  }

  checkData = () => {
    if (this.previewFields) {
      return true
    }
    return false
  }

  checkStyle = () => {
    if (this.styleFields && !this.previewFields && !this.previewBlock) {
      return true
    }
    return false
  }

  checkSettings = () => {
    if (!this.checkSidebar() && !this.checkPreview()) {
      return true
    }
    return false
  }

  /**
   * Deletes the Block from the mobile Preview
   * Deletes the Block from the JSON Object
   */
  deleteBlock = (blockId: any) => {
    this.blockDataService.removeBlockData(blockId)
  }

  /**
   * Refetch the Data of the Block from the customer server and refills the localstorage
   */
  refetchData = () => {
    this.categoriesService.getInitialCategories()
  }
}
