import { Pipe, PipeTransform } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, of } from 'rxjs'
import { BuilderService } from 'src/app/builder-services/builder.service'

@Pipe({
  name: 'googleTranslate'
})
export class GoogleTranslatePipe implements PipeTransform {
  constructor(
    private http: HttpClient,
    private builderService: BuilderService
  ) {}

  transform(text: string): Observable<any> {
    const apiKey = 'AIzaSyDZBU79QDwE28lMF9htfBV3cff92KISeLk' // Replace with your actual API key
    const encodedText = encodeURIComponent(text)
    let targetLanguage: any = localStorage.getItem('siteLang') ? localStorage.getItem('siteLang') : 'en'

    if (targetLanguage === 'en') {
      return of(text) // Return the original text as an Observable using 'of' operator from 'rxjs'
    }
    // let targetLanguage = 'ar';
    const apiUrl = `https://translation.googleapis.com/language/translate/v2?target=${targetLanguage}&key=${apiKey}&q=${encodedText}`
    return this.http.get(apiUrl)
  }
}
