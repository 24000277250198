<!-- add-coupon.component.html -->
<h1 class="createCoupon" *ngIf="!editPage">Create Coupon</h1>
<h1 class="createCoupon" *ngIf="editPage">Edit Coupon</h1>

<div class="newCoupon containerWrapper">
  <div class="new-coupon-wrapper">
    <form (ngSubmit)="onSubmit(f.value)" #f="ngForm">
      <!-- Default Coupon Section -->
      <section class="defaultCoupon">
        <h2 class="section-title">Default Coupon</h2>

        <!-- Coupon Name -->
        <div class="input-group couponNameGroup">
          <mat-form-field appearance="outline" class="couponName">
            <mat-label>Coupon Name</mat-label>
            <input required matInput name="couponName" [(ngModel)]="couponName" #couponInput="ngModel" />
            <mat-error *ngIf="couponInput.errors?.required">Coupon name is required.</mat-error>
          </mat-form-field>
          <div *ngIf="invalidationMessage">
            <mat-error class="invalidationError">{{ invalidationMessage }}</mat-error>
          </div>
        </div>

        <div class="input-row">
          <div class="input-group">
            <mat-form-field appearance="outline" class="half-width discountType">
              <mat-label>Discount Type</mat-label>
              <mat-select id="selectedType" required [(ngModel)]="selectedType" name="selectedType" #selectedTypeInput="ngModel">
                <mat-option value="" disabled>Select Discount Type</mat-option>
                <mat-option *ngFor="let type of discountTypeMap | keyvalue" [value]="type.key">{{ type.value }}</mat-option>
              </mat-select>
              <mat-error *ngIf="selectedTypeInput.errors?.required">Please select a discount type.</mat-error>
            </mat-form-field>
          </div>

          <!-- Discount Criteria -->
          <div class="input-group">
            <mat-form-field appearance="outline" class="half-width discountCriteria">
              <mat-label>Discount Criteria</mat-label>
              <mat-select required id="selectedCriteria" [(ngModel)]="selectedCriteria" name="selectedCriteria" #criteriaSelectInput="ngModel">
                <mat-option value="" disabled>Select Discount Criteria</mat-option>
                <mat-option *ngFor="let criteria of discountCriteriaMap | keyvalue" [value]="criteria.key">
                  {{ criteria.value }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="criteriaSelectInput.errors?.required">Please select a discount criteria.</mat-error>
            </mat-form-field>
          </div>
        </div>
        <!--  -->
        <!-- Products and Categories -->
        <!--   -->
        <div [class.hidden]="!showProducts()">
          <div class="tableWrapper">
            <div class="tableHeader">
              <h1>Select Specific Product(s)</h1>
              <input #query (keyup)="filterProducts(query.value)" type="text" placeholder="Search" id="searchInput" class="search-bar-products" />
            </div>

            <table mat-table mat-sort [dataSource]="productsDataSource" class="mat-elevation-z8">
              <ng-container matColumnDef="image">
                <th mat-header-cell *matHeaderCellDef class="firstTitle">
                  <!-- <mat-checkbox #selectAllCheckbox (change)="toggleSelectAllItems()" class="selectAllCheckbox"></mat-checkbox> -->
                  {{ 'Image' | translate }}
                </th>
                <td mat-cell class="tdI" *matCellDef="let product">
                  <mat-checkbox [checked]="isChecked(product, 'product')" class="products-checkbox" (change)="onProductSelect(product, $event)"></mat-checkbox>

                  <div class="productImgContainer">
                    <img *ngIf="product.image" src="{{ product.image }}" class="productImg" />
                    <img *ngIf="!product.image" src="assets/images/empty-image.png" class="productImg" alt="" />
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let product">{{ product.name }}</td>
              </ng-container>

              <ng-container matColumnDef="stock">
                <th mat-header-cell *matHeaderCellDef>Stock</th>
                <td mat-cell *matCellDef="let product">
                  {{ getStock(product) > 0 ? product.stock : 'Out of Stock' }}
                </td>
              </ng-container>

              <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef>Price</th>
                <td mat-cell *matCellDef="let product">{{ product.price }}</td>
              </ng-container>

              <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef>Date</th>
                <td mat-cell *matCellDef="let product">{{ product.date }}</td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="productsColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: productsColumns"></tr>
            </table>
            <mat-paginator class="tablePaginator" [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons aria-label="Select page of periodic dataSource"> </mat-paginator>
          </div>

          <div *ngIf="selectedProducts.length > 0">
            <div class="tableWrapper selectedProductsTable">
              <div class="tableHeader">
                <h1>Selected Products</h1>
              </div>

              <table mat-table [dataSource]="selectedProductsDataSource" class="mat-elevation-z8">
                <!-- Checkbox Column -->
                <ng-container matColumnDef="select">
                  <th mat-header-cell *matHeaderCellDef>Select</th>
                  <td mat-cell *matCellDef="let product">
                    <mat-checkbox [checked]="isChecked(product, 'product')" (change)="onProductSelect(product, $event)"> </mat-checkbox>
                  </td>
                </ng-container>

                <!-- Image Column -->
                <ng-container matColumnDef="image">
                  <th mat-header-cell *matHeaderCellDef>Image</th>
                  <td mat-cell *matCellDef="let product">
                    <img *ngIf="product.image" [src]="product.image" alt="Product Image" class="productImg" />
                    <img *ngIf="!product.image" src="assets/images/empty-image.png" alt="No image available" class="productImg" />
                  </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>Name</th>
                  <td mat-cell *matCellDef="let product">{{ product.name }}</td>
                </ng-container>

                <!-- Stock Column -->
                <ng-container matColumnDef="stock">
                  <th mat-header-cell *matHeaderCellDef>Stock</th>
                  <td mat-cell *matCellDef="let product">
                    {{ product.stock > 0 ? product.stock : 'Out of Stock' }}
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="selectedProductsColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: selectedProductsColumns"></tr>
              </table>
            </div>
          </div>
        </div>
        <!--  -->
        <div *ngIf="showCategories()">
          <div class="tableWrapper categoriesTable">
            <div class="tableHeader">
              <h1>Select Specific Categories</h1>
              <input #queryC (keyup)="filterCategories(queryC.value)" type="text" placeholder="Search" id="searchInputC" class="search-bar-products" />
            </div>
            <table mat-table [dataSource]="categoriesDataSource" class="mat-elevation-z8">
              <!-- Select, Image, and Name combined into a single column -->
              <ng-container matColumnDef="selectImageName">
                <th mat-header-cell *matHeaderCellDef>Select | Image | Name</th>
                <td mat-cell *matCellDef="let category" class="categoryTd">
                  <div class="categoryInfoWrapper">
                    <mat-checkbox [checked]="isChecked(category, 'category')" (change)="onCategorySelect(category, $event)"></mat-checkbox>
                    <img [src]="category.image" alt="Category Image" class="productImg" />
                    <span class="categoryName">{{ category.name }}</span>
                  </div>
                </td>
              </ng-container>

              <!-- Header and Row Declarations -->
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>

          <!-- Selected Categories Table -->
          <div *ngIf="selectedCategories.length > 0">
            <div class="tableWrapper selectedCategoriesTable">
              <div class="tableHeader">
                <h1>Selected Categories</h1>
              </div>
              <table mat-table [dataSource]="selectedCategoriesDataSource" class="mat-elevation-z8">
                <ng-container matColumnDef="selectImageName">
                  <th mat-header-cell *matHeaderCellDef class="tableHeaderCat">Select | Image | Name</th>
                  <td mat-cell *matCellDef="let category" class="categoryTd">
                    <div class="categoryInfoWrapper">
                      <mat-checkbox [checked]="isChecked(category, 'category')" (change)="onCategorySelect(category, $event)"></mat-checkbox>
                      <img [src]="category.image" alt="Category Image" class="productImg" />
                      <span class="categoryName">{{ category.name }}</span>
                    </div>
                  </td>
                </ng-container>
                <!-- Select Checkbox Column -->

                <!-- Header and Row Declarations -->
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              </table>
            </div>
          </div>
        </div>
      </section>

      <!-- Coupon Amount Section -->
      <section [ngClass]="{ couponAmount: showProducts() || showCategories() }">
        <h2 class="section-title">Coupon Amount</h2>

        <!-- Discount Amount -->
        <div *ngIf="!isPercentage() && !isAmount()" class="input-group">
          <label class="input-label">Discount Amount</label>

          <mat-form-field appearance="outline" class="discountField discountAmount">
            <input required type="number" min="1" matInput name="discountDefaultInput" [(ngModel)]="discountDefaultInput" #discountInput="ngModel" class="discountAmountInput" />
            <mat-error *ngIf="discountInput.touched">Please choose a discount type.</mat-error>
          </mat-form-field>
        </div>

        <div *ngIf="isPercentage()" class="input-group">
          <label class="input-label">Discount Percentage</label>

          <mat-form-field appearance="outline" class="discountField discountPercentage">
            <input required type="number" min="1" matInput name="discountPercentage" class="discountAmountInput" [(ngModel)]="discountPercentage" #discountPer="ngModel" />
            <mat-error *ngIf="discountPer.errors?.min">Discount Percentage is required.</mat-error>
            <mat-error *ngIf="discountPer.errors?.required">Discount Percentage is required.</mat-error>
          </mat-form-field>
          <mat-icon matSuffix>%</mat-icon>
        </div>

        <!-- Discount Amount -->
        <div *ngIf="isAmount()" class="input-group">
          <label class="input-label">Discount Amount</label>

          <mat-form-field appearance="outline" class="discountField discountAmount">
            <input required type="number" min="1" matInput name="discountAmount" class="discountAmountInput" [(ngModel)]="discountAmount" #discountAmountInput="ngModel" />
            <mat-error *ngIf="discountAmountInput.errors?.min">Discount Amount is required.</mat-error>
          </mat-form-field>
          <mat-icon matSuffix>{{ currency }}</mat-icon>
        </div>
      </section>

      <!-- Coupon Usage Section -->
      <section class="couponUsage">
        <h2 class="section-title">Coupon Usage</h2>

        <div class="coupon-usge-content">
          <!-- Usage Time -->
          <div class="input-group usageTimeInput">
            <label class="input-label">Usage Time</label>
            <mat-form-field appearance="outline" class="usageTime">
              <input type="number" required min="1" matInput class="usageTimeI" name="usageTime" [(ngModel)]="usageTime" #usageTimeInput="ngModel" />

              <mat-error *ngIf="usageTimeInput.errors?.min">Usage Time is required.</mat-error>
              <mat-error *ngIf="usageTimeInput.errors?.required">Usage Time is required.</mat-error>
            </mat-form-field>
            <label class="left-input-label">*how many times aval</label>
          </div>

          <div class="minmax">
            <div class="input-group usageTimeInput">
              <label class="input-label">Minimum</label>

              <mat-form-field appearance="outline" class="min">
                <input type="number" matInput class="minI" name="min" [(ngModel)]="min" #minimum="ngModel" />
              </mat-form-field>
            </div>
            <mat-icon matSuffix class="currencyLogo">{{ currency }}</mat-icon>

            <!-- Maximum -->
            <div class="input-group usageTimeInput">
              <label class="input-label">Maximum</label>
              <mat-form-field appearance="outline" class="max">
                <input type="number" matInput name="max" class="maxI" [(ngModel)]="max" #maximum="ngModel" />
              </mat-form-field>
            </div>
            <mat-icon matSuffix class="currencyLogo">{{ currency }}</mat-icon>
          </div>
        </div>
        <div class="userUsesForThisCode">
          <!-- Usage Times -->
          <div class="input-group usageTimeInput">
            <label class="input-label">How many times can user use this coupon</label>
            <mat-form-field appearance="outline" class="usageTimes">
              <input type="number" min="1" required matInput required name="usageTimes" class="usageTimesI" [(ngModel)]="usageTimes" #usageTimesInput="ngModel" />
              <mat-error *ngIf="usageTimesInput.errors?.min">User uses times is required.</mat-error>
              <mat-error *ngIf="usageTimesInput.errors?.required">User uses times is required.</mat-error>
            </mat-form-field>
            <label class="left-input-label">*how many times aval</label>
          </div>
        </div>
      </section>

      <!-- Coupon Expiration Date Section -->
      <section class="couponExpirationDate">
        <h2 class="section-title couponExpirationH2">Coupon Expiration Date</h2>
        <div class="input-group">
          <mat-form-field appearance="outline" class="datePickerInput">
            <input matInput [matDatepicker]="picker" [(ngModel)]="dateInput" required #dateInputNg="ngModel" name="dateInput" class="dateInput" />
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker></mat-datepicker>
            <mat-error *ngIf="dateInputNg.invalid && (dateInputNg.dirty || dateInputNg.touched)"> Expiration date is required. </mat-error>
          </mat-form-field>
        </div>
      </section>

      <section class="couponUsersList" *ngIf="editPage">
        <h2 class="section-title couponExpirationH2">Users</h2>
        <div class="tableWrapper">
          <div class="tableHeader">
            <h1>List</h1>
            <form class="searchBar">
              <input type="text" matInput placeholder="{{ 'Search' | translate }}" [(ngModel)]="searchText" (input)="applyFilter()" name="searchText" class="search-bar-products usersSearch" />
            </form>
            <mat-paginator #usersPaginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
          </div>

          <table mat-table [dataSource]="filteredUsersList" class="mat-elevation-z8 userTable" matSort>
            <ng-container matColumnDef="id">
              <th mat-header-cell *matHeaderCellDef>{{ 'ID' | translate }}</th>
              <td mat-cell *matCellDef="let user">{{ user.uid }}</td>
            </ng-container>
            <ng-container matColumnDef="userName">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'User Name' | translate }}
              </th>
              <td mat-cell *matCellDef="let user">{{ user.displayName }}</td>
            </ng-container>

            <ng-container matColumnDef="userUses">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'User uses' | translate }}
              </th>
              <td mat-cell *matCellDef="let user">
                {{ user.coupons.usedCoupons[this.couponId].userUses }}
              </td>
            </ng-container>
            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'Email' | translate }}
              </th>
              <td mat-cell *matCellDef="let user">{{ user.email }}</td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="usersColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: usersColumns"></tr>
          </table>
        </div>
      </section>
      <!-- Action Buttons -->
      <div class="actions">
        <button mat-icon-button *ngIf="!editPage" class="stacksBtn blue glow newC" type="submit">Create New Coupon</button>
        <button mat-icon-button *ngIf="editPage" class="stacksBtn green glow newC" type="submit">Save Changes</button>
      </div>
    </form>
  </div>
</div>
