import { Component, ViewChild } from '@angular/core'
import { MatTable, MatTableDataSource } from '@angular/material/table'
import { ActivatedRoute, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { BuilderService } from '../builder-services/builder.service'
import { MatSort } from '@angular/material/sort'
import { MatPaginator } from '@angular/material/paginator'
import { AppUsersService } from './services/app-users.service'

export interface usersTableColumns {
  date: string
  time: any
  email: any
  user_id: string
  nickname: string
  first_name: string
  last_name: string
  email_verified: string
  role: string
  action: string
}
@Component({
  selector: 'app-app-users',
  templateUrl: './app-users.component.html',
  styleUrl: './app-users.component.scss'
})
export class AppUsersComponent {
  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatTable)
  table!: MatTable<usersTableColumns>
  ELEMENT_DATA: usersTableColumns[] = []
  public dataSource: any = []
  pageLoaded = false
  public userColumns: string[] = []
  users: any; 
  @ViewChild(MatSort, { static: true })
  matSort!: MatSort
  usersList: any = []
  searchText: string = ''
  statisticsData: any[] = []
  projectUsers: any;
  constructor(
    private route: ActivatedRoute,
    private builderService: BuilderService,
    private router: Router,
    private AppUsersService: AppUsersService
  ) {}

  ngOnInit(): void {
    this.getAllData()
  }

  getAllData = () => {
    let projectId = String(localStorage.getItem('selectedProject'))
    this.users = [] // Reset users array
    this.AppUsersService.getUsers(projectId).subscribe((appUsers: any) => {
      this.users = appUsers // Store app users
      this.AppUsersService.getUsersByProjectId(projectId).subscribe((projectUsers: any) => {
        this.projectUsers = projectUsers
        this.users = [...this.users, ...this.projectUsers] // Combine both arrays
        this.ELEMENT_DATA = []
        this.dataSource = this.users
        this.statisticsData = [
          { name: 'Total Users', value: this.users.length },
          { name: 'Total Verified Users', value: this.users.filter((user: any) => user.email_verified).length },
          { name: 'Total Non Verified Users', value: this.users.length - this.users.filter((user: any) => user.email_verified).length }
        ]
        this.pageLoaded = true
        this.builderService.showUpdateProgress = true
        this.userColumns = ['nickname', 'email', 'date', 'email_verified', 'user_role', 'action']

        this.renderUsers(this.users).subscribe((response: any) => {
          this.dataSource = new MatTableDataSource<usersTableColumns>(this.ELEMENT_DATA)
          this.dataSource.paginator = this.paginator
          this.dataSource.sort = this.matSort
          this.dataSource.sortingDataAccessor = (item: any, property: any) => {
            if (property === 'date') {
              // Convert the DD-MM-YYYY format back to a Date object
              const [day, month, year] = item.date.split('-');
              return new Date(`${year}-${month}-${day}`).getTime();
            }
            return item[property];
          };

          // Set initial sort to date descending
          this.dataSource.sort.active = 'date';
          this.dataSource.sort.direction = 'desc';
          this.table.renderRows()
        })
      })
    })
  }

  convertDate = (dateString: string | null | undefined): string => {
    if (!dateString) {
      return "Invalid Date"; // Return a default value to avoid errors
    }
    
    const isoDate = dateString.split('T')[0]; // "2025-01-29"
  
    const parts = isoDate.split('-'); // ["2025", "01", "29"]
    
    if (parts.length !== 3) {
      return "Invalid Date"; // Handle unexpected formats
    }
  
    return `${parts[2]}-${parts[1]}-${parts[0]}`; // "29-01-2025"
  };
  

  getRoleLabel(role: string): string {
    const roleMap: { [key: string]: string } = {
      admin: 'Admin',
      orderManager: 'Order Manager',
      productManager: 'Product Manager',
      User: 'User'
    }

    return roleMap[role] || 'Unknown Role' // Return mapped role or fallback
  }

  renderUsers = (result: any) => {
    console.log(result);  
    return new Observable<any>((observer) => {
      this.builderService.showUpdateProgress = false;
      let usersList:any = Object.values(result);    
      console.log(usersList)  
      for (const key in usersList) {
        // if(usersList[key].sub){
        if (Object.prototype.hasOwnProperty.call(usersList, key)) {
          const element = usersList[key];
          console.log(element)

          let nickname = element.nickname || 
                        (element.firstName && element.lastName ? `${element.firstName} ${element.lastName}` : 
                        (element.first_name && element.last_name ? `${element.first_name} ${element.last_name}` : 'Unknown'));

          const displayDate = element.updated_at 
                        ? this.convertDate(element.updated_at) 
                        : (element.registration_date || 'Unknown Date');              
          this.ELEMENT_DATA.push({
            email: element.email || element.emailAddress || '',
            user_id: element.sub || element.id || '',
            nickname: nickname,
            email_verified: element.email_verified !== undefined ? element.email_verified : false,
            date: displayDate,
            time: element.time || '1',
            role: element.selectedRole || element.user_role || element.role || 'User',
            first_name: element.first_name || element.firstName || '',
            last_name: element.last_name || element.lastName || '',  
            action: ''
          })
          }
        // }
        }

        this.ELEMENT_DATA.sort((a, b) => {
          const [dayA, monthA, yearA] = a.date.split('-');
          const [dayB, monthB, yearB] = b.date.split('-');
          const dateA = new Date(`${yearA}-${monthA}-${dayA}`).getTime();
          const dateB = new Date(`${yearB}-${monthB}-${dayB}`).getTime();
          return dateB - dateA; // Descending order
        });
        this.dataSource = new MatTableDataSource<usersTableColumns>(this.ELEMENT_DATA);
        console.log(this.dataSource)

        setTimeout(() => {
          observer.next(); 
          observer.complete();
        }, 100);
    });
      
  }

  editUser = (id: string) => {
    this.router.navigate([`/users/${id}`], { relativeTo: this.route })
  }

  /**
   * Deletes a user after confirming the action with the user.
   * @param user_id - The unique identifier of the user to be deleted.
   * @param user_name - The name of the user to be deleted, used in the confirmation message.
   * @returns void
   */
  deleteUser = (user_id: string, user_name: string) => {
    const adminUser = this.projectUsers.some((user:any) => user.id == user_id)
    if (confirm('Are you sure to delete user ' + user_name + ' ? This step is unrecoverable')) {
   if (adminUser){
     this.AppUsersService.deleteStacksUser(user_id).subscribe(response => {
      alert('User deleted successfully')
      this.getAllData()
     })

   } else{

         this.AppUsersService.removeUser(user_id).subscribe((response: any) => {
          alert('User deleted successfully')
          this.getAllData()
          })
       } 
     
      }
    }
  /**
   * Applies a filter to the data source of the user table.
   * The filter is applied to the name, email, and date columns of the table.
   * The filter value is taken from the `searchText` property, which is trimmed and converted to lowercase.
   */
  applyFilter() {
    const filterValue = this.searchText ? this.searchText.trim().toLowerCase() : ''

    this.dataSource.filterPredicate = (data: any, filter: string) => {
      return data.nickname?.toLowerCase().includes(filter) || data.email?.toLowerCase().includes(filter) || data.date.includes(filter)
    }

    this.dataSource.filter = filterValue
  }
}
