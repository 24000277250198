import { Injectable } from '@angular/core'
import { ActivatedRoute, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { LoginService } from '../login/login.service'
import { AppUsersService } from '../app-users/services/app-users.service'
import { Observable, of } from 'rxjs'
import { map, catchError } from 'rxjs/operators'
import { BuilderService } from '../builder-services/builder.service'

@Injectable({
  providedIn: 'root'
})
export class userRoleGuard implements CanActivate {
  user = this.loginService.getCurrentUser()
  projectId = this.builderService.selectedProject
  users: any[] = []
  userRole: any
  constructor(
    private loginService: LoginService,
    private router: Router,
    private route: ActivatedRoute,
    private AppUsersService: AppUsersService,
    private builderService: BuilderService
  ) {}

  private isRouteAllowed(role: string | null, state: RouterStateSnapshot): boolean {
    const url = state.url
    if (!role) {
      return true
    }
    switch (role) {
      case 'orderManager':
        return url.startsWith('/orders')

      case 'productManager':
        return url.startsWith('/products') || url.startsWith('/shipping') || url.startsWith('/e-commerce/settings') || url.startsWith('/stacks-importer') || url.startsWith('/categories')

      case 'admin':
        return true
        
      case 'user':
        return true

      default:
        return true
    }
  }

  canActivate = (route: any, state: RouterStateSnapshot) => {
        const isAllowed = this.isRouteAllowed(this.user.user_role, state)
        if (!isAllowed) {
          return false
        }
        return isAllowed
  }
}
