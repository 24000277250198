import { Component, OnInit, ViewChild } from '@angular/core'
import { MatSort, Sort } from '@angular/material/sort'
import { LiveAnnouncer } from '@angular/cdk/a11y'
import { ActivatedRoute, Router } from '@angular/router'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { PostsService } from '../posts.service'
import { MatPaginator } from '@angular/material/paginator'
import { MatTable, MatTableDataSource } from '@angular/material/table'
import { Observable } from 'rxjs'

export interface postsTableColumns {
  id: string
  image: string
  name: string
  post: string
  date: string
  action: string
}

@Component({
  selector: 'app-posts-list',
  templateUrl: './posts-list.component.html',
  styleUrls: ['./posts-list.component.scss']
})
export class PostsListComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatTable)
  table!: MatTable<postsTableColumns>

  @ViewChild(MatSort, { static: true })
  matSort!: MatSort
  ELEMENT_DATA: postsTableColumns[] = []
  posts_data_counts: any = []

  public dataSource: any = []
  mobile = false
  posts: any = {}
  pageLoaded = false
  public postsColumns: string[] = []

  constructor(
    private builderService: BuilderService,
    private router: Router,
    private route: ActivatedRoute,
    private _liveAnnouncer: LiveAnnouncer,
    private postsService: PostsService
  ) {}

  ngOnInit(): void {
    if (window.screen.width <= 768) {
      // 768px portrait
      this.mobile = true
    }
  }
  getAllData = () => {
    this.postsService.getPosts().subscribe((response: any) => {
      this.pageLoaded = true
      this.builderService.showUpdateProgress = true
      this.postsColumns = ['image', 'name', 'post', 'date', 'action']
      this.posts = response
      // this.dataSource.sort = this.matSort;
      this.renderposts(this.posts).subscribe((response: any) => {
        this.dataSource.paginator = this.paginator
        this.dataSource.sort = this.matSort
        if (response) {
          this.table.renderRows()
        }
      })
    })
  }

  renderposts = (result: any) => {
    return new Observable<any>((observer) => {
      this.builderService.showUpdateProgress = false
      this.posts_data_counts = []
      for (const key in result) {
        if (Object.prototype.hasOwnProperty.call(result, key)) {
          const element = result[key]
          this.posts_data_counts.push(element.count)
          let date: any = element.date ? String(element.date) : ''
          let price: any = element.salePrice ? element.regularPrice + ' / ' + element.salePrice : element.regularPrice

          this.ELEMENT_DATA.push({
            id: key,
            image: element.imageUrl,
            post: element.post,
            name: element.userame,
            date: date ? date.replaceAll('/', '-') : '',
            action: ''
          })
        }
      }

      this.dataSource = new MatTableDataSource<postsTableColumns>(this.ELEMENT_DATA)
      setTimeout(() => {
        observer.next() // Notify the observer that the operation is complete
        observer.complete() // Complete the Observable
      }, 100)
    })
  }

  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`)
    } else {
      this._liveAnnouncer.announce('Sorting cleared')
    }
  }

  deletePost = (id: string, imageName: string) => {
    this.postsService.removePost(id)
    if (imageName) {
      this.builderService.deleteImage(imageName).subscribe(() => {
        this.getAllData()
      })
    } else {
      this.getAllData()
    }
  }

  editPost = (id: string) => {
    // let product_link = ;
    this.router.navigate([`/products/new-product/${id}`], { relativeTo: this.route })
  }
}
