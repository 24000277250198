<section class="stacksBlockContainer" id="block-header" [ngClass]="getBlockFieldsCustomPage(blockId)?.data?.global_header ? (getBlockFields(blockId)?.data?.sticky_header == 'yes' ? 'stickyHeader' : '') : getBlockFieldsCustomPage(blockId).data?.sticky_header == 'yes' ? 'stickyHeader' : ''">
  <div id="settingsTabs" *ngIf="checkSettings()">
    <a (click)="showDataTab()" [ngClass]="checkData() ? 'active' : ''">{{ 'Data' | translate }}</a>
    <a (click)="showStyleTab()" [ngClass]="checkStyle() ? 'active' : ''">{{ 'Style' | translate }}</a>
  </div>
  <!-- Mobile Preview -->
  <div
    class="previewHeader previewMobile"
    [ngClass]="[
      getBlockFieldsCustomPage(blockId).style?.colorSource ? getBlockFieldsCustomPage(blockId).style?.colorSource : 'globalColors',
      getBlockFieldsCustomPage(blockId).style?.header_shadow ? getBlockFieldsCustomPage(blockId).style?.header_shadow : 'withHeaderShadow',
      getBlockFieldsCustomPage(blockId).style?.icons_bg ? getBlockFieldsCustomPage(blockId).style?.icons_bg : 'squareIconBG',
      getBlockFields(blockId).data?.disable_header ? 'disable_header' : '',
      getBlockFieldsCustomPage(blockId).data?.type == 'image' ? 'backgroundImageHeader' : ''
    ]"
    [ngStyle]="{
      'background-color': getBlockFieldsCustomPage(blockId).data?.type !== 'image' && getBlockFieldsCustomPage(blockId).data?.bgColor ? getBlockFieldsCustomPage(blockId).data?.bgColor : '#ffffff',
      'background-image': getBlockFieldsCustomPage(blockId).data?.type == 'image' && getBlockFieldsCustomPage(blockId).data?.src ? 'url(' + getBlockFieldsCustomPage(blockId).data?.src + ')' : ''
    }"
    *ngIf="checkPreview() && !getBlockFieldsCustomPage(blockId).data?.global_header && getBlockFieldsCustomPage(blockId).data?.global_header !== undefined && !builderService.globals"
    id="{{ 'blockid' + blockId }}"
  >
    <img src="../../../assets/images/adsbanner.gif" *ngIf="blockDataService.gAdMobActive" />
    <div class="blockControlHandlers">
      <div class="editBlock blockActionBtn" (click)="showData(blockId)">
        <mat-icon>edit</mat-icon>
      </div>
    </div>
    <div class="headerActionBtns" [ngClass]="blockDataService.gAdMobActive ? 'activeAdMobHeader' : ''">
      <div class="headerLeftBtns">
        <mat-icon
          *ngIf="getBlockFieldsCustomPage(blockId).data?.menuIcon || getBlockFieldsCustomPage(blockId).data?.menuIcon == undefined"
          class="material-icons-outlined"
          [ngStyle]="{ color: getBlockFieldsCustomPage(blockId).style?.color, background: getBlockFields(blockId).style?.colorSource == 'customColors' ? convertHex(getBlockFieldsCustomPage(blockId).style?.color, 0.15) : '' }"
        >
          {{ getBlockFieldsCustomPage(blockId).style?.menu_icon ? getBlockFieldsCustomPage(blockId).style?.menu_icon : 'menu' }}
        </mat-icon>
      </div>
      <div class="headerRightBtns">
        <div>
          <mat-icon
            *ngIf="getBlockFieldsCustomPage(blockId).data?.sidemenuIcon || getBlockFieldsCustomPage(blockId).data?.sidemenuIcon == undefined"
            class="material-icons-outlined"
            [ngStyle]="{ color: getBlockFieldsCustomPage(blockId).style?.color, background: getBlockFields(blockId).style?.colorSource == 'customColors' ? convertHex(getBlockFieldsCustomPage(blockId).style?.color, 0.15) : '' }"
          >
            {{ getBlockFieldsCustomPage(blockId).style?.side_menu ? getBlockFieldsCustomPage(blockId).style?.side_menu : 'more_vert' }}
          </mat-icon>
        </div>
        <div>
          <mat-icon
            *ngIf="getBlockFieldsCustomPage(blockId).data?.cartIcon || getBlockFieldsCustomPage(blockId).data?.cartIcon == undefined"
            class="material-icons-outlined"
            [ngStyle]="{ color: getBlockFieldsCustomPage(blockId).style?.color, background: getBlockFields(blockId).style?.colorSource == 'customColors' ? convertHex(getBlockFieldsCustomPage(blockId).style?.color, 0.15) : '' }"
          >
            {{ getBlockFieldsCustomPage(blockId).style?.cart_icon ? getBlockFieldsCustomPage(blockId).style?.cart_icon : 'shopping_basket' }}
          </mat-icon>
        </div>
        <div id="searchField" [ngClass]="getBlockFieldsCustomPage(blockId).data?.search_opened == 'yes' ? 'searchOpened' : ''">
          <mat-icon
            *ngIf="getBlockFieldsCustomPage(blockId).data?.searchIcon || getBlockFieldsCustomPage(blockId).data?.cartIcon == undefined"
            class="material-icons-outlined"
            [ngStyle]="{ color: getBlockFieldsCustomPage(blockId).style?.color, background: getBlockFields(blockId).style?.colorSource == 'customColors' ? convertHex(getBlockFieldsCustomPage(blockId).style?.color, 0.15) : 'transparent' }"
          >
            {{ getBlockFieldsCustomPage(blockId).style?.search_icon ? getBlockFieldsCustomPage(blockId).style?.search_icon : 'search' }}
          </mat-icon>
          <input type="text" *ngIf="getBlockFieldsCustomPage(blockId).data?.search_opened == 'yes'" />
        </div>
      </div>
    </div>
    <div id="headerMainAreaWrapper">
      <div *ngIf="getBlockFieldsCustomPage(blockId).data?.type == 'color'" [ngStyle]="{ 'background-color': getBlockFieldsCustomPage(blockId).data?.bgColor ? getBlockFieldsCustomPage(blockId).data?.bgColor : '#4fb371', height: '50px' }"></div>
    </div>
    <!-- <div *ngIf="getBlockFieldsCustomPage(blockId).data?.search_opened == 'yes'" id="searchField">
      <mat-icon class="material-icons-outlined">
        {{getBlockFieldsCustomPage(blockId).style?.search_icon ? getBlockFieldsCustomPage(blockId).style?.search_icon : 'search' }}
      </mat-icon>
      <input type="text">
    </div> -->
  </div>

  <!-- Global Header -->
  <div
    class="previewHeader previewMobile"
    [ngStyle]="{
      'background-color': getBlockFields(blockId).data?.type !== 'image' && getBlockFields(blockId).data?.bgColor ? getBlockFields(blockId).data?.bgColor : '#ffffff',
      'background-image': getBlockFields(blockId).data?.type == 'image' && getBlockFields(blockId).data?.src ? 'url(' + getBlockFields(blockId).data?.src + ')' : ''
    }"
    *ngIf="checkPreview() && (getBlockFieldsCustomPage(blockId).data?.global_header || getBlockFieldsCustomPage(blockId).data?.global_header == undefined || builderService.globals)"
    id="{{ 'blockid' + blockId }}"
    [ngClass]="[
      getBlockFields(blockId).style?.colorSource ? getBlockFields(blockId).style?.colorSource : 'globalColors',
      getBlockFields(blockId).style?.header_shadow ? getBlockFields(blockId).style?.header_shadow : 'withHeaderShadow',
      getBlockFields(blockId).style?.icons_bg ? getBlockFields(blockId).style?.icons_bg : 'squareIconBG',
      getBlockFields(blockId).data?.type == 'image' ? 'backgroundImageHeader' : ''
    ]"
  >
    <img src="../../../assets/images/adsbanner.gif" *ngIf="blockDataService.gAdMobActive" />
    <div class="blockControlHandlers">
      <div class="editBlock blockActionBtn" (click)="showData(blockId)">
        <mat-icon>edit</mat-icon>
      </div>
    </div>
    <div class="headerActionBtns" [ngClass]="blockDataService.gAdMobActive ? 'activeAdMobHeader' : ''" *ngIf="getBlockFields(blockId)">
      <div class="headerLeftBtns">
        <mat-icon
          *ngIf="getBlockFields(blockId).data?.menuIcon || getBlockFields(blockId).data?.menuIcon == undefined"
          class="material-icons-outlined"
          [ngStyle]="{ color: getBlockFields(blockId).style?.color, background: getBlockFields(blockId).style?.colorSource == 'customColors' ? convertHex(getBlockFields(blockId).style?.color, 0.15) : '' }"
        >
          {{ getBlockFields(blockId).style?.menu_icon ? getBlockFields(blockId).style?.menu_icon : 'menu' }}
        </mat-icon>
      </div>
      <div class="headerRightBtns">
        <div>
          <mat-icon
            *ngIf="getBlockFields(blockId).data?.sidemenuIcon || getBlockFields(blockId).data?.sidemenuIcon == undefined"
            class="material-icons-outlined"
            [ngStyle]="{ color: getBlockFields(blockId).style?.color, background: getBlockFields(blockId).style?.colorSource == 'customColors' ? convertHex(getBlockFields(blockId).style?.color, 0.15) : '' }"
          >
            {{ getBlockFields(blockId).style?.side_menu ? getBlockFields(blockId).style?.side_menu : 'more_vert' }}
          </mat-icon>
        </div>
        <div>
          <mat-icon
            *ngIf="getBlockFields(blockId).data?.cartIcon || getBlockFields(blockId).data?.cartIcon == undefined"
            class="material-icons-outlined"
            [ngStyle]="{ color: getBlockFields(blockId).style?.color, background: getBlockFields(blockId).style?.colorSource == 'customColors' ? convertHex(getBlockFields(blockId).style?.color, 0.15) : '' }"
          >
            {{ getBlockFields(blockId).style?.cart_icon ? getBlockFields(blockId).style?.cart_icon : 'shopping_basket' }}
          </mat-icon>
        </div>
        <div *ngIf="getBlockFields(blockId).data?.search_opened !== 'yes'">
          <mat-icon
            *ngIf="getBlockFields(blockId).data?.searchIcon || getBlockFields(blockId).data?.cartIcon == undefined"
            class="material-icons-outlined"
            [ngStyle]="{ color: getBlockFields(blockId).style?.color, background: getBlockFields(blockId).style?.colorSource == 'customColors' ? convertHex(getBlockFields(blockId).style?.color, 0.15) : 'transparent' }"
          >
            {{ getBlockFields(blockId).style?.search_icon ? getBlockFields(blockId).style?.search_icon : 'search' }}
          </mat-icon>
        </div>

        <!--search it -->
        <div id="searchField" [ngClass]="getBlockFields(blockId).data?.search_opened == 'yes' ? 'searchOpened' : ''">
          <mat-icon
            *ngIf="getBlockFields(blockId) && getBlockFields(blockId).data?.search_opened == 'yes' && (getBlockFields(blockId).data?.searchIcon || getBlockFields(blockId).data?.cartIcon == undefined)"
            class="material-icons-outlined"
            [ngStyle]="{ color: getBlockFieldsCustomPage(blockId).style?.color, background: getBlockFields(blockId).style?.colorSource == 'customColors' ? convertHex(getBlockFields(blockId).style?.color, 0.15) : 'transparent' }"
          >
            {{ getBlockFieldsCustomPage(blockId).style?.search_icon ? getBlockFieldsCustomPage(blockId).style?.search_icon : 'search' }}
          </mat-icon>
          <input type="text" *ngIf="getBlockFields(blockId) && getBlockFields(blockId).data?.search_opened === 'yes'" />
        </div>
      </div>
    </div>
    <div id="headerMainAreaWrapper" *ngIf="getBlockFields(blockId)">
      <div *ngIf="getBlockFields(blockId).data?.type == 'color'" [ngStyle]="{ 'background-color': getBlockFields(blockId).data?.bgColor ? getBlockFields(blockId).data?.bgColor : '#4fb371', height: '50px' }"></div>
    </div>
  </div>
  <!-- Data and Style Tabs -->
  <div class="previewFields" *ngIf="checkData()">
    <mat-progress-bar mode="indeterminate" *ngIf="showProgress"></mat-progress-bar>
    <div class="sectionSettings" *ngIf="!builderService.globals">
      <div class="sectionHeader">{{ 'Activate Global Settings' | translate }}</div>
      <ul>
        <li>
          <mat-slide-toggle (change)="toggleChanged(blockId, 'global_header')" [(ngModel)]="blockFieldsService.readGlobalHeader">
            <span>{{ 'Activate Global Header' | translate }}</span>
          </mat-slide-toggle>
        </li>
      </ul>
    </div>
    <div class="sectionSettings" *ngIf="!blockFieldsService.readGlobalHeader || builderService.globals">
      <!-- Globals Page Settings -->
      <div class="sectionHeader">{{ 'Header Settings' | translate }}</div>
      <ul>
        <li *ngIf="!builderService.globals">
          <mat-slide-toggle (change)="toggleChanged(blockId, 'disable_header')" [(ngModel)]="blockFieldsService.disable_header">
            <span>{{ 'Disable Header' | translate }}</span>
          </mat-slide-toggle>
        </li>
        <li>
          <mat-slide-toggle (change)="toggleChanged(blockId, 'background')" [(ngModel)]="blockFieldsService.toggleChecked">
            <span *ngIf="!blockFieldsService.toggleChecked">{{ 'Image Header' | translate }}</span>
            <span *ngIf="blockFieldsService.toggleChecked">{{ 'Background Color Header' | translate }}</span>
          </mat-slide-toggle>
        </li>
        <li *ngIf="!blockFieldsService.toggleChecked">
          <label class="fileUpload" for="image"
            >{{ 'Choose an Image' | translate }}
            <input type="file" id="image" (change)="this.processFile($event, '', 'logo', blockId)" />
          </label>
        </li>
        <li *ngIf="!blockFieldsService.toggleChecked">
          <div class="imageDiContainer">
            <div>
              <span class="imageDimensions"> {{ 'Image Dimensions' | translate }}</span>
              <span class="imageDimensions">{{ 'Width:-100px-Height:-40px' | translate }}</span>
            </div>
            <span class="imageDimensions">{{ 'Extensions png or jpg' | translate }}</span>
          </div>
        </li>
        <li *ngIf="!blockFieldsService.toggleChecked">
          <p>{{ getBlockFields(blockId).data?.src }}</p>
        </li>
        <li *ngIf="blockFieldsService.toggleChecked">
          <mat-label>{{ 'Background Color' | translate }}</mat-label>
          <mat-form-field>
            <p class="colorPickerValue">{{ getBlockFields(blockId).data?.bgColor }}</p>
            <label for="bgColor" class="colorLabel">
              <input type="color" id="bgColor" matInput class="colorPicker" name="bgColor" (change)="queryChange('bgColor', blockId, $event, 'text')" value="{{ getBlockFields(blockId).data?.bgColor }}" />
            </label>
          </mat-form-field>
        </li>
      </ul>
    </div>
    <div class="sectionSettings" *ngIf="!blockFieldsService.readGlobalHeader || builderService.globals">
      <!-- Globals Page Settings -->
      <!-- <div class="sectionHeader">Google Admob</div> -->
      <!-- <ul>
        <ul class="singleMenuSettings">
          <li *ngFor="let gHeaderBanner of getBlockFields(blockId).data?.gHeaderBanners">
              <button *ngIf="gHeaderBanner.index !== 0" class="deleteGBannerItem material-icons-outlined" (click)="deleteGBannerItem(blockId, gHeaderBanner.index)">delete</button>
              <div class="gHeaderBannerData" [ngClass]="gHeaderBanner.index == 0 ? 'firstMenuItemData': ''">
                <li>
                  <mat-form-field appearance="outline">
                    <mat-label>Banner Ad Activation</mat-label>
                    <mat-select (selectionChange)="queryChange('status', blockId, $event, 'multilevelSelect', gHeaderBanner.index, 'gHeaderBanners')"
                      value="{{getBlockFields(blockId).data?.gHeaderBanners[gHeaderBanner.index]?.status}}">
                        <mat-option value="active">
                          Enable Banner Ad
                        </mat-option>
                        <mat-option value="inactive">
                          Disable Banner Ad
                        </mat-option>
                      </mat-select>
                  </mat-form-field>
                </li>
                <mat-form-field>
                  <mat-label>Ad Unit Id</mat-label>
                  <input type="text" matInput class="bannerAdId" name="bannerAdId"
                    (change)="queryChange('bannerAdId', blockId, $event, 'multilevelText', gHeaderBanner.index, 'gHeaderBanners')" 
                    value="{{getBlockFields(blockId).data?.gHeaderBanners[gHeaderBanner.index]?.bannerAdId ? getBlockFields(blockId).data?.gHeaderBanners[gHeaderBanner.index]?.bannerAdId : '' }}">
                </mat-form-field>
                <ul class="menuLinker">
                  <li>
                    <mat-form-field appearance="outline">
                        <mat-label>Ad Page Selector</mat-label>
                        <mat-select (selectionChange)="queryChange('gHeaderBannerPage', blockId, $event, 'multilevelSelect', gHeaderBanner.index, 'gHeaderBanners')"
                          value="{{getBlockFields(blockId).data?.gHeaderBanners[gHeaderBanner.index]?.gHeaderBannerPage}}">
                          <mat-option value="home">
                            Home
                          </mat-option>
                          <mat-option value="products">
                            Products
                          </mat-option>
                          <mat-option value="productsCategories">
                            Products Categories
                          </mat-option>
                          <mat-option value="posts">
                            Posts
                          </mat-option>
                          <mat-option value="account">
                            Account Page
                          </mat-option>
                          <mat-option value="signup">
                            Signup / Signin
                          </mat-option>
                          <mat-option value="contact">
                            Contact Us
                          </mat-option>
                          <mat-option value="about">
                            About Us
                          </mat-option>
                          <mat-option value="orders">
                            Orders
                          </mat-option>
                          <mat-option value="cart">
                            Cart
                          </mat-option>
                        </mat-select>
                    </mat-form-field>
                  </li>
                </ul>
              </div>
          </li>
        </ul>
        <li>
          <button class="addItem" (click)="addGBannerItem(blockId)">Add Item</button>
        </li>
      </ul> -->
    </div>
  </div>
  <div class="styleFields" *ngIf="checkStyle() && (!blockFieldsService.readGlobalHeader || builderService.globals)">
    <ul>
      <li>
        <mat-label>{{ 'Sticky' | translate }}</mat-label>
        <mat-select (selectionChange)="queryChange('sticky_header', blockId, $event, 'select')" value="{{ getBlockFields(blockId).data?.sticky_header ? getBlockFields(blockId).data?.sticky_header : 'no' }}">
          <mat-option value="yes">
            {{ 'yes' | translate }}
          </mat-option>
          <mat-option value="no">
            {{ 'No' | translate }}
          </mat-option>
        </mat-select>
      </li>
      <li>
        <mat-label>{{ 'Header Shadow' | translate }}</mat-label>
        <mat-select (selectionChange)="styleChange('header_shadow', blockId, $event, 'select')" value="{{ getBlockFields(blockId).style?.header_shadow ? getBlockFields(blockId).style?.header_shadow : 'withHeaderShadow' }}">
          <mat-option value="withHeaderShadow">
            {{ 'With Shadow' | translate }}
          </mat-option>
          <mat-option value="noHeaderShadow">
            {{ 'No Shadow' | translate }}
          </mat-option>
        </mat-select>
      </li>
      <li>
        <mat-label>{{ 'Search Expanded' | translate }}</mat-label>
        <mat-select (selectionChange)="queryChange('search_opened', blockId, $event, 'select')" value="{{ getBlockFields(blockId).data?.search_opened ? getBlockFields(blockId).data?.search_opened : 'no' }}">
          <mat-option value="yes">
            {{ 'yes' | translate }}
          </mat-option>
          <mat-option value="no">
            {{ 'No' | translate }}
          </mat-option>
        </mat-select>
      </li>
      <li>
        <mat-label>{{ 'Icons Background' | translate }}</mat-label>
        <mat-select (selectionChange)="styleChange('icons_bg', blockId, $event, 'select')" value="{{ getBlockFields(blockId).style?.icons_bg ? getBlockFields(blockId).style?.icons_bg : 'squareIconBG' }}">
          <mat-option value="squareIconBG">
            {{ 'Squared Icons' | translate }}
          </mat-option>
          <mat-option value="circleIconBG">
            {{ 'Circle Icons' | translate }}
          </mat-option>
          <mat-option value="noIconBG">
            {{ 'No-Background' | translate }}
          </mat-option>
        </mat-select>
      </li>
      <li>
        <mat-label>{{ 'Navigation Corner' | translate }}</mat-label>
        <mat-select (selectionChange)="queryChange('backButton', blockId, $event, 'select')" value="{{ getBlockFields(blockId).data?.backButton ? getBlockFields(blockId).data?.backButton : 'yes' }}">
          <mat-option value="yes">
            {{ 'Back Button' | translate }}
          </mat-option>
          <mat-option value="no">
            {{ 'Side Menu' | translate }}
          </mat-option>
        </mat-select>
      </li>
      <li class="contentCenter">
        <mat-form-field>
          <mat-select (selectionChange)="styleChange('colorSource', blockId, $event, 'select')" value="{{ getBlockFields(blockId).style?.colorSource ? getBlockFields(blockId).style?.colorSource : 'globalColors' }}">
            <mat-option value="" disabled>
              {{ 'Select Color Source' | translate }}
            </mat-option>
            <mat-option value="globalColors">
              {{ 'Global Colors' | translate }}
            </mat-option>
            <mat-option value="customColors">
              {{ 'Custom Color' | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </li>
      <li class="contentContainerColor" *ngIf="getBlockFields(blockId).style?.colorSource == 'customColors'">
        <label>{{ 'Icon Color' | translate }}</label>
        <mat-form-field>
          <p class="colorPickerValue">{{ getBlockFields(blockId).style?.color }}</p>
          <label for="color" class="colorLabel">
            <input type="color" id="color" matInput class="colorPicker" name="color" (change)="styleChange('color', blockId, $event, 'text')" value="{{ getBlockFields(blockId).style?.color }}" />
          </label>
        </mat-form-field>
      </li>
      <li>
        <p>{{ 'Go to this link' | translate }} <a href="https://fonts.google.com/icons" target="_blank">https://fonts.google.com/icons</a> {{ 'to change the icons choose the icon' | translate }}</p>
      </li>
      <li>
        <div class="optionContainer">
          <mat-label>{{ 'Menu Icon' | translate }}</mat-label>
          <mat-form-field>
            <input type="text" matInput name="menu_icon" (keyup)="styleChange('menu_icon', blockId, $event, 'text')" value="{{ getBlockFields(blockId).style?.menu_icon ? getBlockFields(blockId).style?.menu_icon : 'menu' }}" />
          </mat-form-field>
        </div>

        <mat-slide-toggle (change)="toggleChanged(blockId)" [(ngModel)]="blockFieldsService.menuIconToggleChecked">
          {{ 'Enable' | translate }}
        </mat-slide-toggle>
      </li>
      <li>
        <div class="optionContainer">
          <mat-label>{{ 'Search Icon' | translate }}</mat-label>
          <mat-form-field>
            <input type="text" matInput name="search_icon" (keyup)="styleChange('search_icon', blockId, $event, 'text')" value="{{ getBlockFields(blockId).style?.search_icon ? getBlockFields(blockId).style?.search_icon : 'search' }}" />
          </mat-form-field>
        </div>
        <mat-slide-toggle (change)="toggleChanged(blockId)" [(ngModel)]="blockFieldsService.searchIconToggleChecked">
          {{ 'Enable' | translate }}
        </mat-slide-toggle>
      </li>
      <li>
        <div class="optionContainer">
          <mat-label>{{ 'Cart Icon' | translate }}</mat-label>
          <mat-form-field>
            <input type="text" matInput name="cart_icon" (keyup)="styleChange('cart_icon', blockId, $event, 'text')" value="{{ getBlockFields(blockId).style?.cart_icon ? getBlockFields(blockId).style?.cart_icon : 'shopping_basket' }}" />
          </mat-form-field>
        </div>
        <mat-slide-toggle (change)="toggleChanged(blockId)" [(ngModel)]="blockFieldsService.cartIconToggleChecked">
          {{ 'Enable' | translate }}
        </mat-slide-toggle>
      </li>
      <li>
        <div class="optionContainer">
          <mat-label>{{ 'Side Menu Icon' | translate }}</mat-label>
          <mat-form-field>
            <input type="text" matInput name="side_menu" (keyup)="styleChange('side_menu', blockId, $event, 'text')" value="{{ getBlockFields(blockId).style?.side_menu ? getBlockFields(blockId).style?.side_menu : 'more_vert' }}" />
          </mat-form-field>
        </div>
        <mat-slide-toggle (change)="toggleChanged(blockId)" [(ngModel)]="blockFieldsService.sidemenuIconToggleChecked">
          {{ 'Enable' | translate }}
        </mat-slide-toggle>
      </li>
    </ul>
  </div>
</section>
