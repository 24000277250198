<div class="pricingContainer">
  <div class="offerSelection">
    <button
      (click)="togglePricingPlan('quarterly')"
      [ngClass]="{
        active: pricingPlan === 'quarterly',
        inactive: pricingPlan !== 'quarterly'
      }"
    >
      Quarterly
    </button>
    <button
      (click)="togglePricingPlan('yearly')"
      [ngClass]="{
        active: pricingPlan === 'yearly',
        inactive: pricingPlan !== 'yearly'
      }"
    >
      Yearly
    </button>
  </div>

  <div class="packageTable" id="price_section">
    <div class="columnWrapper">
      <!-- Updated Columns without the first column -->
      <div
        class="cell"
        *ngFor="let package of packages; let colIndex = index"
        (mouseenter)="onMouseEnter(colIndex + 1)"
        (mouseleave)="onMouseLeave()"
        [ngClass]="{
          hovered: package.pricing_id === premiumData.price_id || (!mobile && hoveredColumn === colIndex + 1)
        }"
      
      >
        <div class="titleContainer">
          <div class="textImage">
            <img *ngIf="colIndex === 0" src="../../../../../assets/images/gccLanding/gcc_starter.png" alt="" />
            <img *ngIf="colIndex === 1" src="../../../../../assets/images/gccLanding/gcc_growth.png" alt="" />
            <h3 [ngStyle]="getTitleColor(colIndex)">
              {{ package.type }}
            </h3>
          </div>
          <div>
            <div [ngClass]="{ 'hovered-text': package.pricing_id === premiumData.price_id || hoveredColumn === colIndex + 1 }">
              Get started with everything you need to launch your app. Simple, affordable, and just right for taking that first big step!
              <span [ngStyle]="getSpanColor(colIndex)">
                <div class="priceBreak">
                  <span class="price"> {{ package.currency }}{{ package.price }} </span>
                  <p [ngStyle]="getTitleColor(colIndex)" [ngSwitch]="pricingPlan">
                    <span [ngStyle]="getTimeColor(colIndex)" *ngSwitchCase="'yearly'">/ Per Year</span>
                    <span [ngStyle]="getTimeColor(colIndex)" *ngSwitchDefault>/ month (billed quarterly)</span>
                  </p>
                  <br />
                </div>
                <h4 class="included">What’s included</h4>
              </span>
            </div>
          </div>
        </div>
        <div class="bodyContent" *ngFor="let feature of package.features">
          <div class="featureItem">
            <span [ngStyle]="getTitleColor(colIndex)" class="featureName">{{ feature.name }}</span>
            <img [src]="getHoveredImage(feature.icon2, colIndex)" class="icon" alt="{{ feature.name }}" />
          </div>
        </div>
        <button 
        (click)="handlePackageSelection(package)" 
        [ngStyle]="getButtonColor(colIndex)">
        {{
          package.duration.toLowerCase() !== premiumData.period.toLowerCase()
            ? 'Choose Plan'
            : (
                package.pricing_id === premiumData.price_id
                  ? 'Your Plan'
                  : (
                      premiumData.type.toLowerCase() === 'growth'
                        ? (package.type.toLowerCase() === 'starter' ? 'Downgrade Now' : 'Upgrade Now')
                        : (premiumData.type.toLowerCase() === 'starter'
                            ? (package.type.toLowerCase() === 'growth' ? 'Upgrade Now' : 'Downgrade Now')
                            : ''
                          )
                    )
              )
        }}
        <span [ngStyle]="getArrowColor(colIndex)" *ngIf="!isArabic && package.pricing_id != premiumData.price_id" class="material-icons">arrow_forward</span>
        <span [ngStyle]="getArrowColor(colIndex)" *ngIf="isArabic && package.pricing_id != premiumData.price_id" class="material-icons">arrow_back</span>
      </button>
      </div>
    </div>
  </div>
</div>
