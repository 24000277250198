import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { HelpersService } from 'src/app/helpers.service'

@Injectable({
  providedIn: 'root'
})
export class LtdService {
  private stacksURL: any

  options = {
    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  }
  constructor(
    private http: HttpClient,
    public helpers: HelpersService
  ) {}

  checkCoupon = (coupon: string): Observable<any> => {
    this.stacksURL = this.helpers.stacksURL
    let postData = new URLSearchParams()
    postData.set('coupon', coupon)

    return this.http.post(this.stacksURL + 'wp-json/v4/checkCoupon', postData.toString(), this.options)
  }
}
