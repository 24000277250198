<mat-toolbar color="accent">
  <span>Mobile App Trials</span>
</mat-toolbar>
<mat-form-field appearance="outline">
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applySubFilter($event)" placeholder="Ex. ium" #input />
</mat-form-field>
<button mat-raised-button class="fetchData" (click)="fetchTrials()">Fetch New Data</button>
<form [formGroup]="form" class="dateModule" (submit)="applyDateFilter()">
  <mat-form-field>
    <input matInput placeholder="Date From" [matDatepicker]="picker1" formControlName="fromDate" required (click)="picker1.open()" />
    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
    <mat-datepicker #picker1></mat-datepicker>
  </mat-form-field>
  <mat-form-field>
    <input matInput placeholder="toDate" [matDatepicker]="picker2" formControlName="toDate" required (click)="picker2.open()" />
    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
    <mat-datepicker #picker2></mat-datepicker>
  </mat-form-field>
  <button class="dateActionBtn" mat-raised-button color="accent" (click)="applyDateFilter()" [disabled]="form.invalid">Submit</button>
  <button mat-raised-button class="dateActionBtn" color="accent" type="button" [disabled]="form.invalid" (click)="clearDateFilter()">Clear</button>
</form>
<table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
  <!-- Position Column -->
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>date.</th>
    <td mat-cell *matCellDef="let elements">{{ elements.date }}</td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="email">
    <th mat-header-cell *matHeaderCellDef>E-Mail</th>
    <td mat-cell *matCellDef="let elements">{{ elements.email }}</td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="order_id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>Order Id</th>
    <td mat-cell *matCellDef="let elements">{{ elements.order_id }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="trialsColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: trialsColumns"></tr>
</table>
<mat-paginator [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons aria-label="Select page of periodic dataSource"> </mat-paginator>
<div class="fullGrid">
  <div class="widgetChart">
    <canvas baseChart [data]="subChartData" [options]="subChartOptions" type="bar"></canvas>
  </div>
</div>
