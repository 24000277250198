<div class="containerWrapper">
  <div class="currencyWrapper">
    <form [formGroup]="settingsForm" id="settingsForm" (ngSubmit)="saveSettings()">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Select-currency' | translate }}</mat-label>
        <mat-select [(ngModel)]="currency" (ngModelChange)="activateSave()" formControlName="currency">
          <mat-option *ngFor="let currency of CurrencyList" value="{{ currency.code }}"> {{ currency.symbol }} : {{ currency.name }} </mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label>{{ 'Select Country Option' | translate }}</mat-label>
        <mat-select formControlName="countryOptions">
          <mat-option [value]="'all'">{{ 'All Countries' | translate }}</mat-option>
          <mat-option [value]="'multi'">{{ 'Multi Countries' | translate }}</mat-option>
          <mat-option [value]="'single'">{{ 'Single Country' | translate }}</mat-option>
        </mat-select>
      </mat-form-field>

      <mat-form-field appearance="outline" *ngIf="settingsForm.get('countryOptions')?.value === 'multi'">
        <mat-label>{{ 'Select Countries' | translate }}</mat-label>
        <mat-select formControlName="selectedCountries" multiple>
          <mat-option *ngFor="let country of countries" [value]="country.dial_code"> {{ country.emoji }} {{ country.name }} ({{ country.dial_code }}) </mat-option>
        </mat-select>
      </mat-form-field>

      <div class="countryNumber" *ngIf="settingsForm.get('countryOptions')?.value === 'single'">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'Enter Country Code' | translate }}</mat-label>
          <input matInput formControlName="countryNumber" placeholder="20" (input)="startWithPlus($event)" />
        </mat-form-field>
      </div>
      <div class="countryNumber">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'Estimated Delivery Time' | translate }}</mat-label>
          <input matInput formControlName="estimatedDeliveryTime" placeholder="20" />
        </mat-form-field>
      </div>
      <div class="countryNumber">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'Time Unit' | translate }}</mat-label>
          <mat-select formControlName="estimatedDeliveryUnit">
            <mat-option value="minutes">{{ 'Minutes' | translate }}</mat-option>
            <mat-option value="hours">{{ 'Hours' | translate }}</mat-option>
            <mat-option value="days">{{ 'Days' | translate }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </form>
  </div>
</div>
