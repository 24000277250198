<h1 class="couponList">Coupons List</h1>

<div class="couponsWrapper">
  <div *ngIf="!hasCoupons()" class="noCoupons">
    <button class="addCouponBtn" routerLink="/coupons/add-coupon">
      <span>+</span>
      Add New Coupon
    </button>
    <img class="addCouponLogo" src="assets/images/addcoupon.webp" />
  </div>
  <div class="tableWrapper" *ngIf="hasCoupons()">
    <div class="tableHeader">
      <div class="headerBtns">
        <button class="stacksBtn blue" routerLink="/coupons/add-coupon" class="addNewCoupon">
          <span>+</span>
          Add New Coupon
        </button>
        <form class="searchBar">
          <input type="text" placeholder="Search" [(ngModel)]="searchTerm" [ngModelOptions]="{ standalone: true }" (ngModelChange)="applyFilter($event)" />
        </form>
      </div>
    </div>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let coupon">{{ coupon.name }}</td>
      </ng-container>

      <ng-container matColumnDef="expirationDate">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Expiration Date</th>
        <td mat-cell *matCellDef="let coupon">{{ coupon.expirationDate }}</td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
        <td mat-cell *matCellDef="let coupon">{{ coupon.amount }}</td>
      </ng-container>

      <ng-container matColumnDef="uses">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Uses</th>
        <td mat-cell *matCellDef="let coupon">{{ coupon.uses }}</td>
      </ng-container>

      <ng-container matColumnDef="discountType">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Discount Type</th>
        <td mat-cell *matCellDef="let coupon">
          {{ discountTypeMap[coupon.discountType] }}
        </td>
      </ng-container>

      <ng-container matColumnDef="discountCriteria">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Discount Criteria</th>
        <td mat-cell *matCellDef="let coupon">
          {{ discountCriteriaMap[coupon.discountCriteria] }}
        </td>
      </ng-container>
      <ng-container matColumnDef="active">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Active</th>
        <td mat-cell *matCellDef="let coupon">
          <mat-slide-toggle [checked]="coupon.active" (change)="toggleActiveStatus(coupon.id, $event.checked)"> </mat-slide-toggle>
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let coupon">
          <button mat-button [routerLink]="['/coupons/add-coupon/', coupon.id]" class="editCoupon">
            <span class="material-icons">edit</span>
          </button>
          <button mat-button (click)="deleteCoupon(coupon.id)" class="deleteCoupon">
            <span class="material-icons">delete</span>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25]" showFirstLastButtons></mat-paginator>
  </div>
</div>
