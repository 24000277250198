import { BuilderService } from 'src/app/builder-services/builder.service'
import { Component } from '@angular/core'
import { DiscordService } from 'src/app/builder-services/discord.service'
import { environment } from 'src/environments/environment'
import { GoogleAnalyticsService } from 'ngx-google-analytics'
import { LoginService } from 'src/app/login/login.service'
import { MixpanelService } from 'src/app/tracking/mixpanel.service'
import { PixelService } from 'ngx-multi-pixel'
import { stripeService } from '../../payment/stripe.service'
import { Router } from '@angular/router'
import { LanguageService } from 'src/app/language.service'
import { SubscriptionFlowService } from 'src/app/subscription-flow/subscription-flow.service'
import { MatDialog } from '@angular/material/dialog'
import { WorksDialogComponent } from '../../works-dialog/works-dialog.component'
import { BillingService } from '../billing.service'

declare var Stripe: any
@Component({
  selector: 'app-pricing-tables',
  templateUrl: './pricing-tables.component.html',
  styleUrl: './pricing-tables.component.scss'
})
export class PricingTablesComponent {
  hoveredColumn: number | null = null
  isArabic: boolean = false
  loadingStripe = false
  mobile = false
  packages: any = []
  package: any
  pricingPlan: 'quarterly' | 'yearly' = 'quarterly'
  private stripe: any
  public price_ID: any = ''
  quarterlyPricingIds: string[] = []
  yearlyPricingIds: string[] = []

  premiumData: any
  priceId: any
  project: any
  constructor(
    private builderService: BuilderService,
    private discordService: DiscordService,
    private gaService: GoogleAnalyticsService,
    private loginService: LoginService,
    private mixPanel: MixpanelService,
    private pixel: PixelService,
    private router: Router,
    private stripeService: stripeService,
    private translate: LanguageService,
    private SubscriptionFlow: SubscriptionFlowService,
    private dialog: MatDialog,
    public billing: BillingService
  ) {
    this.project = this.builderService.cacheProjectReq.response
    this.premiumData = JSON.parse(this.project?.premium[0]?.package ?? '{}')
    this.priceId = this.premiumData.price_id
    const period = this.premiumData?.period ? this.premiumData.period.toLowerCase() : null
    if (period && ['quarterly', 'yearly'].includes(period)) {
      this.pricingPlan = period
      this.loadPackages(period)
    }
  }

  ngAfterViewInit() {
    this.stripe = Stripe(environment.stripePublishableKey)
  }

  ngOnInit() {
    this.pixel.initialize()
    this.translate.getLanguage()
    if (this.translate.selectedLanguage === 'ar') {
      this.isArabic = true
    }
  }

  onMouseEnter(columnIndex: number) {
    if (!this.mobile) {
      setTimeout(() => {
        this.hoveredColumn = columnIndex
      }, 200)
    }
  }

  onMouseLeave() {
    if (!this.mobile) {
      this.hoveredColumn = null
    }
  }

  getHoveredImage(imageName: string, colIndex: number): string {
    const isPremium = this.packages[colIndex]?.pricing_id === this.premiumData.price_id
    if (this.hoveredColumn === colIndex + 1 || isPremium) {
      return imageName.replace('assets/images/gccLanding/blueCheck.png', 'assets/images/gccLanding/whiteCheck.png')
    }
    return imageName
  }

  getTitleColor(colIndex: number): any {
    const isPremium = this.packages[colIndex]?.pricing_id === this.premiumData.price_id
    return this.hoveredColumn === colIndex + 1 || isPremium || this.mobile ? { color: '#fff' } : { color: '#090420' }
  }

  getArrowColor(colIndex: number): any {
    const isPremium = this.packages[colIndex]?.pricing_id === this.premiumData.price_id
    return this.hoveredColumn === colIndex + 1 || isPremium || this.mobile ? { color: '#1bbc9b' } : { color: '#fff' }
  }

  getSpanColor(colIndex: number): any {
    const isPremium = this.packages[colIndex]?.pricing_id === this.premiumData.price_id
    return this.hoveredColumn === colIndex + 1 || isPremium || this.mobile ? { color: '#fff' } : { color: '#090420' }
  }

  getTimeColor(colIndex: number): any {
    const isPremium = this.packages[colIndex]?.pricing_id === this.premiumData.price_id
    return this.hoveredColumn === colIndex + 1 || isPremium || this.mobile ? { color: '#FFFFFFB3' } : { color: '#000000B3' }
  }

  getButtonColor(colIndex: number): any {
    const isPremium = this.packages[colIndex]?.pricing_id === this.premiumData.price_id
    // Update the hovered condition to include the premium package
    const isHovered = this.hoveredColumn === colIndex + 1 || isPremium || this.mobile
    return {
      'background-color': isHovered ? '#ffffff' : '#567EDC',
      color: isHovered ? '#090420' : '#ffffff'
    }
  }

  togglePricingPlan(plan: 'quarterly' | 'yearly') {
    this.pricingPlan = plan
    this.loadPackages(plan)
  }

  private loadPackages(plan: 'quarterly' | 'yearly') {
    this.premiumData = JSON.parse(this.project.premium[0].package)
    this.premiumData.period = this.premiumData.period
    this.priceId = this.premiumData.price_id

    this.quarterlyPricingIds = []
    this.yearlyPricingIds = []
    this.project.package.forEach((packageItem: any) => {
      if (packageItem.duration === 'Quarterly') {
        this.quarterlyPricingIds.push(packageItem.pricing_id)
      } else if (packageItem.duration === 'Yearly') {
        this.yearlyPricingIds.push(packageItem.pricing_id)
      }
    })
    const selectedPricingIds = plan === 'quarterly' ? this.quarterlyPricingIds : this.yearlyPricingIds

    this.packages = this.project.package.filter((pkg: any) => selectedPricingIds.includes(pkg.pricing_id))
  }

  getPrice(index: number) {
    for (let i of environment.pricing_packages) {
      console.log(i)
    }
  }

  routeToLogin(id: any) {
    // alert(id)
    if (!this.loginService.getCurrentUser()) {
      this.router.navigate(['/signup', id])
    } else {
      this.redirectToCheckout(id)
    }
  }

  notifyStacks(type = 'stripe', price_id: string = '') {
    this.gaService.gtag('event', 'Add to Cart', { send_to: environment.gtm })
    this.gaService.gtag('event', 'Add to Cart', { send_to: environment.ga })
    this.pixel.trackCustom('Add to Cart')
    this.mixPanel.init(this.builderService.selectedProject)
    this.mixPanel.track('Add to Cart', {
      projectid: this.builderService.selectedProject
    })

    let content = 'New Customer Add to Cart through ' + type + ' with email ' + this.loginService.getCurrentUser().email + ' for package ' + price_id
    if (environment.production) {
      this.discordService.sendMessage(content, 'Sales').subscribe(
        () => {
          console.log('Message sent to Discord successfully!')
          // Handle success
        },
        (error) => {
          console.error('Error sending message to Discord:', error)
          // Handle error
        }
      )
    }
  }

  async redirectToCheckout(price_id: string) {
    if (!this.SubscriptionFlow.stripe?.payment_methods) {
      alert('No payment methods available.')
      return
    } else {
      const isConfirmed = confirm('Are you sure you want to proceed with this payment?')

      if (!isConfirmed) {
        return
      }
      this.loadingStripe = true
      this.notifyStacks('stripe', price_id)

      this.stripeService.updateSubscription(this.SubscriptionFlow.stripe.invoices.subscription!, price_id).subscribe((res: any) => {
        this.loadingStripe = false
      })
    }
    this.howItWorksDialog()
  }

  howItWorksDialog = () => {
    const dialogRef = this.dialog.open(WorksDialogComponent, {
      width: '900px',
      maxWidth: '100%',
      data: { name: 'successPayment' }
    })

    dialogRef.afterClosed().subscribe((result) => {
      this.router.navigate(['/']).then(() => {
        window.location.reload()
      })
    })
  }
  handlePackageSelection(pkg: any): void {
    if (pkg.pricing_id != this.premiumData.price_id) {
      this.routeToLogin(pkg.pricing_id)
    }
    this.billing.setSelectedPackage(pkg)
  }
}
