import { Injectable } from '@angular/core'
import { environment } from 'src/environments/environment'
import { initializeApp } from 'firebase/app'
@Injectable({
  providedIn: 'root'
})
export class HelpersService {
  builderURL = environment.builderURL
  stacksURL = environment.stacksURL
  firebaseApp: any

  constructor() {
    this.initializeFirebase()
  }

  /**
   * Clones the object to assure that we didn't manipulate the original object
   * @param object the object that needs to be cloned
   * @returns the cloned object
   */
  cloneObject = (object: any) => {
    return JSON.parse(JSON.stringify(object))
  }

  getBuilderUrl = () => {
    return this.builderURL
  }

  toObject = (arr: any) => {
    var rv: any = {}
    for (var i = 0; i < arr.length; ++i) if (arr[i] !== undefined) rv[i] = arr[i]
    return rv
  }

  initializeFirebase = () => {
    this.firebaseApp = initializeApp(environment.firebaseConfig)
  }

  // Generate a random string of a specified length
  generateRandomString = (length: number) => {
    const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    let result = ''
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length)
      result += charset.charAt(randomIndex)
    }
    return result
  }

  getLastItem(items: any[]): any {
    return items?.[items.length - 1] || null
  }
}
