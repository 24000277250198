import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BillingService {
  private selectedPackageSubject = new BehaviorSubject<any>(null); // Stores the latest selected package

  constructor() { }

  setSelectedPackage(pkg: any): void {
    this.selectedPackageSubject.next(pkg);
  }

 getSelectedPackage(): Observable<any> {
    return this.selectedPackageSubject.asObservable();
  }

  getLatestSelectedPackage(): any {
    return this.selectedPackageSubject.value;
  }
}
