import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { BlockDataService } from '../block-services/block-data.service'
import { BuilderService } from '../builder-services/builder.service'
import { HelpersService } from '../helpers.service'

@Injectable({
  providedIn: 'root'
})
export class TemplatesService extends BlockDataService {
  private builderUrl = ''

  projects = {}
  selectedProject = 0
  headerBtn = ''
  options = {
    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  }
  token: any
  constructor(
    private http: HttpClient,
    private helper: HelpersService,
    private builderService: BuilderService
  ) {
    super()
    this.builderUrl = this.helper.getBuilderUrl()
  }

  /**
   * Get all templates from Backend
   */
  getTemplates = (): Observable<any> => {
    let postData = new URLSearchParams()
    postData.set('process', 'sync_ui')

    return this.http.post(this.builderUrl + 'getTemplates', postData.toString(), this.options)
  }

  /**
   * Get Certain Project id Data
   * @param id
   */
  getTemplate = (id: string) => {
    let postData = new URLSearchParams()
    postData.set('process', 'sync_ui')
    postData.set('templateId', id)

    return this.http.post(this.builderUrl + 'getTemplate', postData.toString(), this.options)
  }

  /**
   * Update Project with Data
   */
  updateTemplate = (id: any, name: string, image: string, allBlocks: object) => {
    let data = {
      template_name: name,
      template_image: image,
      all_blocks: allBlocks,
      id: id
    }
    // let postData = new FormData();
    // postData.append('process', "sync_ui");
    // postData.append('id', id);
    // postData.append('templateData', templateData);
    let optionsTemplate = {
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    }
    return this.http.post<any>(this.builderUrl + 'updateTemplate', JSON.stringify(data), optionsTemplate)
  }

  /**
   * Insert New Template
   */
  insertTemplate = (template: object, templateImage: string, allBlocks: object) => {
    let templateData = {
      template_name: template,
      template_image: templateImage,
      all_blocks: allBlocks
    }

    let optionsTemplate = {
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    }

    return this.http.post(this.builderUrl + 'insertTemplate', JSON.stringify(templateData), optionsTemplate)
  }

  public uploadFile(file: File, id: any, type: string, name: string): Observable<any> {
    const formData = new FormData()
    this.token = this.builderService.getToken()
    formData.append('file', file)
    formData.append('token', this.token)
    formData.append('projectId', id)
    formData.append('file_type', type)
    formData.append('file_name', name)
    formData.append('template', 'true')

    return this.http.post(this.builderUrl + 'receive-file', formData)
  }
}
