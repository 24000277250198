import { moveItemInArray } from '@angular/cdk/drag-drop'
import { Component, ComponentFactoryResolver, ViewContainerRef, ComponentRef, ViewChild } from '@angular/core'
import { BlockDataService } from '../block-services/block-data.service'
import { BuilderService } from '../builder-services/builder.service'

@Component({
  selector: 'mobile-preview',
  templateUrl: './mobile-preview.component.html',
  styleUrls: ['./mobile-preview.component.scss']
})
export class MobilePreviewComponent {
  @ViewChild('mainMobileAppPreview', { read: ViewContainerRef })
  mainMobileAppPreview!: ViewContainerRef

  private componentRef!: ComponentRef<any>
  blockId = ''
  constructor(
    private blockDataService: BlockDataService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private builderService: BuilderService
  ) {
    this.blockDataService.getBlockData().subscribe((blockTextData) => {
      this.blockId = this.blockDataService.getUniqueId(2)
      let BlockComponent = blockTextData.component
      let index = blockTextData.index
      let resolvedBlock = this.blockResolver(BlockComponent)
      this.appendBlockCreation(resolvedBlock, index, blockTextData.initial)
    })

    this.blockDataService.getSelectedView().subscribe((selectedView) => {
      this.blockDataService.selectedView = selectedView
    })

    setTimeout(() => {
      this.sortFooterMobilePreview()
    }, 3000)
  }

  /**
   * Resolves the Subscribed Block
   * @param BlockComponentCloned
   */
  blockResolver(BlockComponentCloned: any) {
    return this.componentFactoryResolver.resolveComponentFactory(BlockComponentCloned)
  }
  /**
   * Takes the cloned component and creates a component inside the resolved block
   * @param clonedComponent
   */
  appendBlockCreation(clonedComponent: any, index: any, initial: any) {
    this.sortFooter()
    if (index == 'latest') {
      this.componentRef = this.mainMobileAppPreview.createComponent(clonedComponent)
    } else {
      this.componentRef = this.mainMobileAppPreview.createComponent(clonedComponent, index)
    }

    let clonedBlockInstance = this.componentRef.instance
    clonedBlockInstance.blockId = this.blockDataService.blockId
    // clonedBlockInstance.blockId = this.blockId;
    // this.blockId++;
    clonedBlockInstance.previewBlock = true
    clonedBlockInstance.initialData = initial
    if (initial !== 'initial') {
      setTimeout(() => {
        let element = document.getElementById('blockid' + clonedBlockInstance.blockId)
        if (element == null) {
          // it is a section
          element = document.querySelector("[blockid='" + clonedBlockInstance.blockId + "']")
        }
        // if (window.screen.width > 768) { // 768px portrait
        element?.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' })
        // }
      }, 250)
    }
    this.sortFooterMobilePreview()
  }
  /** make the footer json at the bottom of the array */
  sortFooter = () => {
    let allBlocks = this.blockDataService.getAllBlocksData()
    for (let index = 0; index < allBlocks.length; index++) {
      const element = allBlocks[index]
      if (element.type == 'footer') {
        /**
         * Adjust the block data JSON Object
         */
        moveItemInArray(this.blockDataService.getAllBlocksData(), index, allBlocks.length - 1)
      }
    }
  }

  sortFooterMobilePreview = () => {
    let allBlocks = this.blockDataService.getAllBlocksData()
    for (let index = 0; index < allBlocks.length; index++) {
      const element = allBlocks[index]
      if (element.type == 'footer') {
        /**
         * Move Footer Element to the bottom
         *
         */
        let footerId = 'blockid' + element.id
        // console.log(footerId);
        let footerElement = document.getElementById(footerId)
        // console.log(footerElement);
        let footerElementIndex = getElementIndex(element.id)
        // console.log(footerId, footerElement, footerElementIndex)
        if (footerElement && footerElementIndex) {
          let mobilePreview = footerElement.parentNode?.parentNode?.parentNode
          let mobilePreviewLength = mobilePreview?.childNodes.length
          // console.log(mobilePreview, footerElementIndex,mobilePreviewLength);
          if (mobilePreviewLength) {
            moveWithinContainer(mobilePreview, footerElementIndex, mobilePreviewLength - 2)
          }
        }
      }
    }
  }
}

function moveWithinContainer(container: any, fromIndex: any, toIndex: any) {
  if (fromIndex === toIndex) {
    return
  }
  // console.log(toIndex, fromIndex);
  const nodeToMove = container.children[fromIndex]
  const targetNode = container.children[toIndex]
  // console.log(container.children, nodeToMove, targetNode);
  if (fromIndex < toIndex) {
    targetNode.parentNode.insertBefore(nodeToMove, targetNode.nextSibling)
  } else {
    targetNode.parentNode.insertBefore(nodeToMove, targetNode)
  }
}

function getElementIndex(id: string) {
  let modifiedId = 'blockid' + id
  var child = document.getElementById(modifiedId)?.parentNode?.parentNode
  if (!child) {
    child = document.querySelector('[blockid="' + id + '"]')?.parentNode?.parentNode?.parentNode?.parentNode
  }
  // console.log(child);
  var parent = child?.parentNode
  // console.log(parent);
  // The equivalent of parent.children.indexOf(child)
  return parent ? Array.prototype.indexOf.call(parent?.children, child) : null
}
