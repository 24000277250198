import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTable, MatTableDataSource } from '@angular/material/table'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { DatabaseService } from 'src/app/builder-services/database.service'
import { Router } from '@angular/router'

export interface PointsSetup {
  id: string
  points_system?: string
  currency_system?: string
  order_system?: string
  product_option?: string
  enable_points?: boolean
  [key: string]: any
}

@Component({
  selector: 'app-points-list',
  templateUrl: './points-list.component.html',
  styleUrls: ['./points-list.component.scss']
})
export class PointsListComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatSort) matSort!: MatSort
  @ViewChild(MatTable) table!: MatTable<PointsSetup>

  displayedColumns: string[] = ['id', 'points_system', 'details', 'enable_points', 'actions']
  dataSource = new MatTableDataSource<PointsSetup>()
  searchTerm: string = ''
  project_id = this.builderService.selectedProject

  constructor(private builderService: BuilderService, private db: DatabaseService, private router: Router) {}

  ngOnInit() {
    this.loadPointsSetups()
  }

  /**
   * If we’re using MatPaginator, we set it after view init
   */
  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.matSort
  }

  /**
   * Load points setups from the database
   */
  loadPointsSetups() {
    const path = `/projects/${this.project_id}/settings/points/`

    this.db.getDatabase(path).subscribe(
      (data: any) => {
        // data might be an object: { key1: {...}, key2: {...} }
        if (data) {
          const pointsArray: PointsSetup[] = Object.keys(data).map((key) => ({
            id: key,
            ...data[key]
          }))
          this.dataSource.data = pointsArray
        } else {
          // No data or data is not an object
          this.dataSource.data = []
        }
      },
      (error) => {
        console.error('Error fetching points setups:', error)
      }
    )
  }

  /**
   * Delete a points setup by ID
   */
  deletePoints(setupId: string) {
    if (confirm('Are you sure you want to delete this Points setup?')) {
      const path = `/projects/${this.project_id}/settings/points/${setupId}`

      // Remove from DB
      this.db.removeDatabaseEndpoint(path).subscribe(
        () => {
          // Reload the table data
          this.loadPointsSetups()
        },
        (error) => {
          console.error('Error deleting points setup:', error)
        }
      )
    }
  }

  /**
   * Filter the data in the table
   */
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase()
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage()
    }
  }

  /**
   * Toggle the enable_points value for a specific Points setup.
   * @param point The PointsSetup object (row in the table).
   * @param newStatus The boolean value from the slide-toggle (true or false).
   */
  toggleEnablePoints(point: PointsSetup, newStatus: boolean) {
    const path = `/projects/${this.project_id}/settings/points/${point.id}`

    // Prepare the updated data. You can simply update `enable_points` in the existing object.
    const updatedData = {
      ...point,
      enable_points: newStatus
    }

    // Call your service to set the data at the given path.
    // If you have a method like `setDatabase(path, value)` or something similar, use that.
    this.db.setDatabase(path, updatedData).subscribe(
      () => {
        // Optionally, update local dataSource so the UI matches immediately
        point.enable_points = newStatus
        console.log(`enable_points updated to ${newStatus} for setup ID:`, point.id)
      },
      (error) => {
        console.error('Error updating enable_points:', error)
      }
    )
  }

  /**
   * Check if we have any points setups
   */
  hasPoints(): boolean {
    return this.dataSource.data.length > 0
  }
}
