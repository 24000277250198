<div id="couponLogo">
  <img src="assets/images/stacks.png" />
</div>
<div id="middleContainerWrapper">
  <mat-progress-bar mode="indeterminate" *ngIf="couponProgress"></mat-progress-bar>
  <form id="couponContainer" [formGroup]="couponForm" (ngSubmit)="onSubmit()">
    <h1>Welcome to Stacks</h1>
    <ul>
      <li>
        <mat-error *ngIf="couponError">
          {{ couponErrorMsg }}
        </mat-error>
      </li>
      <li *ngIf="this.ltdMessage" id="ltdMessage" [innerHTML]="this.ltdMessage"></li>
      <li>
        <mat-form-field appearance="outline">
          <mat-label>Enter Coupon Code</mat-label>
          <input matInput placeholder="" formControlName="couponValue" type="text" />
        </mat-form-field>
      </li>
    </ul>
    <button mat-icon-button class="submitCoupon" type="submit">
      <span>Apply Coupon</span>
    </button>
  </form>
</div>
