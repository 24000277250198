import { Component, Input, OnInit } from '@angular/core'
import { BlockFieldsService } from 'src/app/block-services/block-fields.service'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { BlockDataService } from '../../block-services/block-data.service'
import { MixpanelService } from 'src/app/tracking/mixpanel.service'
import { ProductsService } from 'src/app/e-commerce/products/products.service'
import { CategoriesService } from 'src/app/e-commerce/categories/categories.service'
import { Observable } from 'rxjs'
import { SettingsService } from 'src/app/e-commerce/settings/settings.service'

@Component({
  selector: 'stacks-products',
  templateUrl: './stacks-products.component.html',
  styleUrls: ['./stacks-products.component.scss']
})
export class StacksProductsComponent implements OnInit {
  previewBlock = false
  styleFields = false
  blockData: any
  /**
   * If the block is rendered from saved data, then this should be true, if added dynamically by drag and drop or click then it will be "initial"
   **/
  @Input() importedBlockData: any
  @Input() nestedBlock: any = {}
  allCategories: any = []
  allowedSubcategories: any = []
  allProducts: any = []
  blockFields: any
  blockId = ''
  blockProducts = ''
  categories = []
  currency: any
  initialData: any = false
  previewFields = false
  productsData = []
  showCategoriesList = false
  shownProducts: any = []

  constructor(
    public blockDataService: BlockDataService,
    private blockFieldsService: BlockFieldsService,
    private productsService: ProductsService,
    public builderService: BuilderService,
    private stacksCategoriesService: CategoriesService,
    private mixPanel: MixpanelService,
    private settingsService: SettingsService
  ) {}

  ngOnInit() {
    if (this.importedBlockData) {
      this.previewBlock = true
      this.previewFields = false
      this.styleFields = false
    }
    let projectId = this.builderService.getSelectedProject()
    if (this.initialData !== 'initial') {
      if (this.nestedBlock.type == 'products') {
        this.blockData = this.nestedBlock
        this.blockId = this.nestedBlock.id
        this.previewBlock = true
      } else {
        this.blockData = this.importedBlockData ? this.importedBlockData : this.blockFieldsService.getStacksProductsBlockFields()
        if (!this.blockId) {
          this.blockId = this.blockDataService.getUniqueId(2)
        }
      }
    } else {
      this.blockData = this.blockDataService.getSelectedBlockData(this.blockId)
    }

    this.getProducts(this.blockData.data).subscribe((response: any) => {
      this.blockProducts = this.blockData.data.value
      let products = Array.isArray(response) ? response : Object.values(response)
      products.forEach((product) => {
        const prices = this.getLowestPrices(product.productVariations || [])
        product.lowestSalePrice = prices.lowestSalePrice
        product.correspondingRegularPrice = prices.correspondingRegularPrice
        product.lowestRegularPrice = prices.lowestRegularPrice
      })
      this.stacksCategoriesService.getCategories().subscribe((resp: any) => {
        this.allCategories = []
        for (const key in resp) {
          if (Object.prototype.hasOwnProperty.call(resp, key)) {
            const element = resp[key]
            this.allCategories.push(element)
          }
        }
        this.adjustArgs(this.blockId, this.blockData.data)
        this.setCategoriesData(this.blockId, this.allCategories)
      })

      this.builderService.showSettingsProgress = false
      this.shownProducts = this.allProducts
    })

    this.settingsService.getSettings().subscribe((response: any) => {
      this.currency = response.currency
    })
  }

  getProducts = (args: object) => {
    this.builderService.showSettingsProgress = true
    return new Observable<any>((observer) => {
      this.productsService.getProducts().subscribe(
        (response) => {
          this.builderService.showSettingsProgress = false
          this.allProducts = []
          for (const key in response) {
            if (Object.prototype.hasOwnProperty.call(response, key)) {
              const element = response[key]
              this.allProducts.push(element)
            }
          }
          // this.setProductsData(this.blockId, this.allProducts);
          observer.next(response) // Notify the observer that the operation is complete
          observer.complete() // Complete the Observable
          // this.currency = response.currency_symbol;
        },
        (err) => {
          observer.error(err) // Notify the observer of an error
        }
      )
    })
    // return true;
  }

  getLowestPrices(productVariations: any[]) {
    let lowestSalePrice = Infinity
    let correspondingRegularPrice = null
    let lowestRegularPrice = Infinity

    productVariations.forEach((variation) => {
      if (!variation.values) {
        return
      }

      const salePrice = parseFloat(variation.salePrice || '0')
      const regularPrice = parseFloat(variation.regularPrice || '0')

      if (salePrice > 0 && salePrice < lowestSalePrice) {
        lowestSalePrice = salePrice
        correspondingRegularPrice = regularPrice
      }

      if (regularPrice < lowestRegularPrice) {
        lowestRegularPrice = regularPrice
      }
    })

    return {
      lowestSalePrice: lowestSalePrice === Infinity ? null : lowestSalePrice,
      correspondingRegularPrice: lowestSalePrice === Infinity ? null : correspondingRegularPrice,
      lowestRegularPrice: lowestRegularPrice === Infinity ? null : lowestRegularPrice
    }
  }

  /**
   * Getter for the Products Data, The case of changing the modifying in one component and reflects into another component needs getters and setters
   * @param blockId
   * @returns
   */
  getProductsData = (blockId: string) => {
    if (this.importedBlockData) {
      return this.importedBlockData.productsData
    }
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return
    }
    return this.blockData.productsData
  }

  /**
   * Setter for the Products Data, The case of changing the modifying in one component and reflects into another component needs getters and setters
   * @returns
   */
  setProductsData = (blockId: string, data: any) => {
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return
    }
    this.blockData = this.blockDataService.getSelectedBlockData(blockId)
    this.blockData.productsData = data
  }

  /**
   * Getter for the Categories, The case of changing the modifying in one component and reflects into another component needs getters and setters
   * @param blockId
   * @returns
   */
  getCategoriesData = (blockId: string) => {
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return
    }
    let productsBlockFields = this.blockDataService.getSelectedBlockData(blockId)
    return productsBlockFields.categories
  }

  /**
   * Setter for the Categories, The case of changing the modifying in one component and reflects into another component needs getters and setters
   * @returns
   */
  setCategoriesData = (blockId: string, data: object) => {
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return
    }
    let productsBlockFields = this.blockDataService.getSelectedBlockData(blockId)
    productsBlockFields.categories = data
  }
  /**
   * Appends Block to the Preview, Initial checks if the block is added as initial block addition or appended live
   */
  addBlockToPreview(initial: any = '') {
    let blockProductsData = {
      component: StacksProductsComponent,
      index: 'latest',
      element: this,
      initial: initial
    }
    this.blockDataService.sendBlockData(blockProductsData)
    this.builderService.blocksCollapsed = true
  }

  appendBlock($event: any) {
    if (!this.builderService.standalone) {
      return false
    }
    let clonedBlockData = { ...this.blockData }
    this.blockId = this.blockDataService.appendBlocksData(clonedBlockData)
    this.addBlockToPreview()
    this.builderService.logData('Products Block Added', 'Block Add', 'Prodicts Block Added').subscribe(
      (response) => {},
      (err) => {}
    )
    this.mixPanel.track('Block Added', {
      projectid: this.builderService.getSelectedProject(),
      block: 'stacksProducts'
    })
    return true
  }

  /**
   * Sync the clicked block on click inside the mobile preview
   */
  showData(blockId: any) {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      // show data of the last element instead
      blockId = this.blockDataService.getLatestBlockId()
    }
    this.blockFieldsService.clickedBlockId = blockId
    this.blockDataService.sendCurrentBlock(StacksProductsComponent)
  }

  queryChange(query: any, blockId: any, $event: any, type: string) {
    // console.log(blockId, this.blockDataService.checkBlockAvailability(blockId));
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return
    }
    let productsBlockFields = this.blockDataService.getSelectedBlockData(blockId)
    /**
     * Modifying Nested Object Directly doesn't work, so we have to clone the data object first then modify it
     */
    let clonedBlockFieldsData = { ...productsBlockFields.data }
    let data = ''
    if (type == 'select') {
      data = $event.value
    } else if (type == 'text') {
      data = $event.currentTarget.value
    } else if (type == 'slider') {
      data = $event.currentTarget.value
    }

    clonedBlockFieldsData[query] = data
    productsBlockFields.data = clonedBlockFieldsData

    if (productsBlockFields.data.query_post_type == 'by_category') {
      this.showCategoriesList = true
    } else {
      this.showCategoriesList = false
    }
    this.adjustArgs(blockId, clonedBlockFieldsData)
  }

  /**
   * Makes all the needed adjustments and Filterations to the Query Args and outputs the result, in the correct order
   */
  adjustArgs = (blockId: any, args: any) => {
    let filteredData: any

    let limit = args.limit
    let categories = args.products_categories
    let sub_categories = args.products_sub_categories
    let type = args.query_post_type
    let order_by = args.query_orderby
    let order_type = args.query_order
    // First thing is to filter the categories selected
    if (type == 'by_category') {
      this.categoryChange(args.products_categories)
      if (sub_categories && sub_categories.length && this.filterSubCategories(sub_categories).length) {
        filteredData = this.filterSubCategories(sub_categories)
      } else {
        filteredData = this.filterCategories(categories)
      }
    } else {
      filteredData = this.shownProducts
    }

    // Next Adjust the Limit
    filteredData = this.filterLimit(limit, filteredData)

    // Then Order them
    filteredData = this.orderProducts(order_by, order_type, filteredData)

    this.setProductsData(blockId, filteredData)
  }

  /**
   * Changes the limit of the elements shown for the product
   * @param blockId
   * @param limit
   * @returns products limited to the amount selected
   */
  filterLimit = (limit: any, filteredData: any) => {
    return filteredData.slice(0, limit)
  }

  filterCategories = (categories: any) => {
    return this.productsService.getProductsByCategories(categories, this.allProducts)
  }

  filterSubCategories = (categories: any) => {
    return this.productsService.getProductsBySubCategories(categories, this.allProducts)
  }

  orderProducts = (order_by: string, order_type: string, filteredData: any) => {
    if (order_by == 'date') {
      filteredData.sort((a: any, b: any) => {
        const dateA: any = new Date(a.date)
        const dateB: any = new Date(b.date)
        if (order_type == 'ASC') {
          return dateA - dateB
        } else {
          return dateB - dateA
        }
      })
    } else if (order_by == 'title') {
      filteredData.sort((a: any, b: any) => {
        const titleA = a.productName.toLowerCase()
        const titleB = b.productName.toLowerCase()
        if (order_type == 'ASC') {
          return titleA.localeCompare(titleB)
        } else {
          return titleB.localeCompare(titleA)
        }
      })
    } else if (order_by == 'price') {
      filteredData.sort((a: any, b: any) => {
        const priceA: any = a.regularPrice
        const priceB: any = b.regularPrice
        if (order_type == 'ASC') {
          return priceA - priceB
        } else {
          return priceB - priceA
        }
      })
    }

    return filteredData
  }

  styleChange(style: any, blockId: any, $event: any, type: string, stylePosition = 'style') {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return
    }
    let productsBlockFields = this.blockDataService.getSelectedBlockData(blockId)
    /**
     * Modifying Nested Object Directly doesn't work, so we have to clone the data object first then modify it
     */
    let data = ''
    if (type == 'select') {
      data = $event.value
    } else if (type == 'text') {
      data = $event.currentTarget.value
    } else if (type == 'slider') {
      data = $event.currentTarget.value + 'px'
    }

    if (stylePosition == 'buttonClass') {
      let clonedBlockFieldsData = { ...productsBlockFields.buttonClass }
      clonedBlockFieldsData[style] = data
      productsBlockFields.buttonClass = clonedBlockFieldsData
    } else {
      let clonedBlockFieldsData = { ...productsBlockFields.style }
      clonedBlockFieldsData[style] = data
      productsBlockFields.style = clonedBlockFieldsData
    }
  }

  getBlockFields = (id: string) => {
    if (this.importedBlockData) {
      return this.importedBlockData
    }
    return this.blockDataService.getSelectedBlockData(id)
  }

  showStyleTab = () => {
    this.previewBlock = false
    this.previewFields = false
    this.styleFields = true
  }

  showDataTab = () => {
    this.previewBlock = false
    this.previewFields = true
    this.styleFields = false
  }

  checkSidebar = () => {
    if (!this.previewBlock && !this.previewFields && !this.styleFields) {
      return true
    }
    return false
  }

  checkPreview = () => {
    if (this.previewBlock && !this.previewFields && !this.styleFields) {
      return true
    }
    return false
  }

  checkData = () => {
    if (this.previewFields) {
      return true
    }
    return false
  }

  checkStyle = () => {
    if (this.styleFields && !this.previewFields && !this.previewBlock) {
      return true
    }
    return false
  }

  checkSettings = () => {
    if (!this.checkSidebar() && !this.checkPreview()) {
      return true
    }
    return false
  }

  /**
   * Deletes the Block from the mobile Preview
   * Deletes the Block from the JSON Object
   */
  deleteBlock = (blockId: any) => {
    this.blockDataService.removeBlockData(blockId)
  }

  categoryChange = (value: string) => {
    let selectedCategory = this.stacksCategoriesService.findCategoryById(this.allCategories, value)
    this.allowedSubcategories = []
    for (const category of selectedCategory) {
      if (!category.subcategories || !category.subcategories.length) {
        continue
      }
      for (const subcategory of category.subcategories) {
        this.allowedSubcategories.push(subcategory)
      }
    }
  }
}
