<div #GlobalSettingsWrapper *ngIf="!mobile" #elementRef>
  <div id="globalSettingsHeader">Global Settings</div>
  <div id="globalSettingsBody">
    <div id="globalSettingsForm" (ngSubmit)="onSubmit()" class="styleFields">
      <mat-progress-bar mode="indeterminate" *ngIf="showGlobalSettingsProgress"></mat-progress-bar>
      <ul>
        <li>
          <mat-label>Font Family</mat-label>
          <mat-select *ngIf="this.builderService.globalSettings" (selectionChange)="styleChange('font-family', $event, 'font', 'style')" value="{{ this.builderService.globalSettings['font-family'] }}">
            <mat-option value="{{ font.family }}" *ngFor="let font of fonts">
              {{ font.family }}
            </mat-option>
          </mat-select>
        </li>
        <li>
          <mat-label>{{ 'Font Size' | translate }}</mat-label>
          <mat-slider showTickMarks discrete *ngIf="this.builderService.globalSettings" max="70" min="5" step="1" thumbLabel="true">
            <input matSliderThumb ngModel="{{ this.builderService.globalSettings['font-size']?.slice(0, -2) }}" (change)="styleChange('font-size', $event, 'slider', 'style')" />
          </mat-slider>
        </li>
        <!--  menue settings -->
        <li>
          <div>
            <mat-label>{{ 'Menu Name' | translate }}</mat-label>
            <mat-form-field>
              <input matInput [(ngModel)]="sideMenuName" />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'Link Type' | translate }}</mat-label>
              <mat-select [(ngModel)]="selectOpt">
                <mat-option value="home">
                  {{ 'Home' | translate }}
                </mat-option>
                <!-- <mat-option value="products">
                  {{'Products' | translate }}
                </mat-option> -->
                <mat-option value="allProductsCategories">
                  {{ 'All Products Categories' | translate }}
                </mat-option>
                <!-- <mat-option value="productsCategories">
                  {{ "Product Category" | translate }}
                </mat-option> -->
                <!-- <mat-option value="posts">
                  {{'Posts' | translate }}
                </mat-option> -->
                <mat-option value="externalURL">
                  {{ 'External URL' | translate }}
                </mat-option>
                <mat-option value="account">
                  {{ 'Account Page' | translate }}
                </mat-option>
                <mat-option value="signup">
                  {{ 'Signup / Signin' | translate }}
                </mat-option>
                <mat-option value="contact">
                  {{ 'Contact Us' | translate }}
                </mat-option>
                <mat-option value="about">
                  {{ 'About Us' | translate }}
                </mat-option>
                <mat-option value="orders">
                  {{ 'Orders' | translate }}
                </mat-option>
                <mat-option value="cart">
                  {{ 'Cart' | translate }}
                </mat-option>
                <mat-option value="terms">
                  {{ 'Terms' | translate }}
                </mat-option>
                <mat-option *ngFor="let customView of builderService.customViews" value="{{ customView.view_name }}">
                  {{ customView.view_name }}
                </mat-option>
              </mat-select>
            </mat-form-field>
            <div>
              <button (click)="setMenuData(sideMenuName)" class="btn sbtMenue">
                {{ 'Add Menu' | translate }}
              </button>
            </div>
            <div class="data" *ngIf="sideMenueData.length !== 0">
              <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
                <div *ngFor="let item of sideMenueData; let i = index">
                  <mat-expansion-panel [expanded]="panelState === i" (opened)="panelOpened(i)" cdkDrag>
                    <mat-expansion-panel-header>
                      <mat-panel-title>
                        <div class="flex-title">
                          <div>{{ item.name }}</div>
                          <div (click)="remove(i)">
                            <mat-icon class="cl-danger">delete_outline</mat-icon>
                          </div>
                        </div>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="formDataEdit" *ngIf="isMenueEdited">
                      <mat-form-field>
                        <input matInput [(ngModel)]="this.builderService.globalSettings.menu[i].name" />
                      </mat-form-field>
                      <mat-form-field appearance="outline">
                        <mat-label>{{ 'Link Type' | translate }}</mat-label>
                        <mat-select [(ngModel)]="this.builderService.globalSettings.menu[i].link">
                          <mat-option value="home">
                            {{ 'Home' | translate }}
                          </mat-option>
                          <mat-option value="allProductsCategories">
                            {{ 'All Products Categories' | translate }}
                          </mat-option>
                          <mat-option value="externalURL">
                            {{ 'External URL' | translate }}
                          </mat-option>
                          <mat-option value="account">
                            {{ 'Account Page' | translate }}
                          </mat-option>
                          <mat-option value="signup">
                            {{ 'Signup / Signin' | translate }}
                          </mat-option>
                          <mat-option value="contact">
                            {{ 'Contact Us' | translate }}
                          </mat-option>
                          <mat-option value="about">
                            {{ 'About Us' | translate }}
                          </mat-option>
                          <mat-option value="orders">
                            {{ 'Orders' | translate }}
                          </mat-option>
                          <mat-option value="cart">
                            {{ 'Cart' | translate }}
                          </mat-option>
                          <mat-option value="terms">
                            {{ 'Terms' | translate }}
                          </mat-option>
                          <mat-option *ngFor="let customView of builderService.customViews" value="{{ customView.view_name }}">
                            {{ customView.view_name }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </mat-expansion-panel>
                </div>
              </div>
            </div>
          </div>
        </li>
        <!-- <li>
            <mat-label>Font Weight</mat-label>
            <mat-select (selectionChange)="styleChange('font-weight', blockId, $event, 'select','style')"
              value="{{getBlockFields(blockId).style['font-weight']}}">
              <mat-option value="normal">
                Normal
              </mat-option>
              <mat-option value="bold">
                Bold
              </mat-option>
            </mat-select>
          </li> -->
        <!-- <li>
            <mat-label>Font Variant</mat-label>
            <mat-select (selectionChange)="styleChange('font-variant', $event, 'select','style')"
            value="{{this.builderService.globalSettings['font-variant']}}">
              <mat-option value="{{variants}}" *ngFor="let variants of selectedFont.variants">
                {{variants}}
              </mat-option>
            </mat-select>
          </li> -->
      </ul>
    </div>
  </div>
</div>
