<mat-progress-bar mode="indeterminate" *ngIf="builderService.showUpdateProgress"></mat-progress-bar>

<div class="containerWrapper autoWrapper">
    <div class="automationHeader">
        <h1>{{ 'Automation' | translate }}</h1>
        <div class="headerBtns">
            <button class="stacksBtn blue" (click)="addEvent()">Add new event</button>
            <div class="search">
                <form (ngSubmit)="onSubmit()">
                    <input type="text" matInput placeholder="Search..." [(ngModel)]="searchKey" name="search">
                    <button type="submit">Search</button>
                </form>
            </div>
        </div>
    </div>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>

        <!-- ID Column -->
        <ng-container matColumnDef="id">
            <th mat-header-cell *matHeaderCellDef> {{ 'ID' | translate }} </th>
            <td mat-cell *matCellDef="let element">
                <p>
                    {{ element.id }}
                </p>
            </td>
        </ng-container>

        <!-- Date Column -->
        <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef> {{ 'Date' | translate }} </th>
            <td mat-cell *matCellDef="let element">
                <p>
                    {{ element.date }}
                </p>
            </td>
        </ng-container>
        <!-- Name Column -->
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> {{ 'When Send Notify' | translate }} </th>
            <td mat-cell *matCellDef="let element let i = index">
                <mat-form-field appearance="outline">
                    <mat-select [(ngModel)]="dataSource.data[i].name" (ngModelChange)="onNameChange(element)">
                        <mat-option *ngFor="let nameOption of pnService.nameOptions" [value]="nameOption">
                            {{ nameOption }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </ng-container>

        <!-- Type Column -->
        <ng-container matColumnDef="types">
            <th mat-header-cell *matHeaderCellDef> {{ 'Event' | translate }} </th>
            <td mat-cell *matCellDef="let element let i = index">
                <mat-form-field appearance="outline">
                    <mat-label>{{ 'Event' | translate }}</mat-label>
                    <mat-select [(ngModel)]="dataSource.data[i].selectedType" (ngModelChange)="onTypeChange(element)">
                        <mat-option *ngFor="let typeOption of element.types" [value]="typeOption">
                            {{ typeOption }}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </td>
        </ng-container>

        <!-- Message Column -->
        <ng-container matColumnDef="message">
            <th mat-header-cell *matHeaderCellDef> {{ 'Message' | translate }} </th>
            <td class="messageWrapper" mat-cell *matCellDef="let element let i = index">
                <mat-form-field class="messageInput" appearance="outline">
                    <mat-label>{{ 'Message' | translate }}</mat-label>
                    <input matInput [(ngModel)]="dataSource.data[i].selectedMessage" />
                </mat-form-field>
            </td>
        </ng-container>

        <!-- Delete Column -->
        <ng-container matColumnDef="delete">
            <th mat-header-cell *matHeaderCellDef> {{ 'Actions' | translate }} </th>
            <td class="delete" mat-cell *matCellDef="let element; let i = index">
                <button mat-icon-button (click)="delete(i)">
                    <span class="mat-button-wrapper">
                        <span class="material-icons material-icons-outlined deleteIcon">delete</span>
                    </span>
                </button>
            </td>
        </ng-container>

        <!-- Table Header and Row -->
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

    </table>
    <mat-paginator [length]="length" showFirstLastButtons [pageSizeOptions]="[5, 10, 20, 50, 100]"
        aria-label="Select page">
    </mat-paginator>
    <button class="saveBtn" (click)="save()">Save</button>
</div>