import { BuilderService } from 'src/app/builder-services/builder.service'
import { Component, OnInit } from '@angular/core'
import { DatabaseService } from 'src/app/builder-services/database.service'
import { FormBuilder, FormGroup } from '@angular/forms'
import { HttpClient } from '@angular/common/http'
import { MatTableDataSource } from '@angular/material/table'
import { SettingsService } from 'src/app/e-commerce/settings/settings.service'
import { forkJoin, from, Observable, of } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'
import { MultilingualPipe } from '../multilingual.pipe'
import { BlockDataService } from 'src/app/block-services/block-data.service'
import { ProductsService } from 'src/app/e-commerce/products/products.service'
import { ShippingService } from 'src/app/e-commerce/shipping/shipping.service'
import { CategoriesService } from 'src/app/e-commerce/categories/categories.service'

interface TableRow {
  [key: string]: any
  Currency?: string
  En: string
}

@Component({
  selector: 'app-txt-translations',
  templateUrl: './txt-translations.component.html',
  styleUrl: './txt-translations.component.scss'
})
export class TxtTranslationsComponent implements OnInit {
  currency: any
  currentPageIndex: number = 0
  displayedColumns: string[] = []
  editingCell: { row: TableRow | null; lang: string | null } = {
    row: null,
    lang: null
  }
  filteredData: TableRow[] = []
  languages: { title: string; translation?: string }[] = []
  pagedTableData: MatTableDataSource<TableRow> = new MatTableDataSource<TableRow>([])
  pageSize: number = 10
  pageSizeOptions: number[] = [5, 10, 25, 50, 100]
  searchText: string = ''
  stacksTranslation = false
  tableData: TableRow[] = []
  txtTranslations!: FormGroup
  productProgress: boolean = false
  allSectionTexts: string[] = []
  project_id: string | undefined
  constructor(
    private BuilderService: BuilderService,
    private db: DatabaseService,
    private http: HttpClient,
    private blockDataService: BlockDataService,
    private translatePipe: MultilingualPipe,
    private settingsService: SettingsService,
    private productService: ProductsService,
    private categoriesService: CategoriesService,
    private shippingService: ShippingService,
    private fb: FormBuilder
  ) {
    this.project_id = this.BuilderService.selectedProject
  }

  /*
   * ngOnInit: Initializes the component.
   * - Sets up the form group for translations.
   * - Initializes an array for languages.
   * - Loads available languages and retrieves currency data.
   */

  ngOnInit(): void {
    this.txtTranslations = this.fb.group({
      languages: this.fb.array([])
    })
    this.loadLanguages()
  }

  /*
   * updatePageData: Updates the displayed data in the paginated table.
   * - Takes a pageIndex as a parameter to determine which data to display.
   * - Calculates start and end indices based on the current page index and page size.
   * - Slices the filtered data to set the data for the paginated table.
   */

  updatePageData(pageIndex: number): void {
    const startIndex = pageIndex * this.pageSize
    const endIndex = startIndex + this.pageSize
    this.pagedTableData.data = this.filteredData.slice(startIndex, endIndex)
  }

  /*
   * onPageChange: Handles the page change event for the paginated table.
   * - Updates the page size and current page index based on the event.
   * - Calls updatePageData to refresh the displayed data for the current page.
   */

  onPageChange(event: any) {
    this.pageSize = event.pageSize
    this.currentPageIndex = event.pageIndex
    this.updatePageData(this.currentPageIndex)
  }
  /*
   * getTranslate: Fetches translations for the selected project and aggregates
   * the data from multiple languages.
   * - Retrieves the selected project ID from the BuilderService.
   * - Checks if any languages are available; logs a message and exits if none.
   * - Initializes a counter and an object to hold aggregated translation data.
   * - Iterates over each language to construct the unique database path for translations.
   * - Subscribes to the database observable to fetch translations for each language.
   * - Aggregates translations into the allData object, using the 'En' key as a reference.
   * - When all language data is loaded, converts the aggregated data to an array,
   *   adds a currency row, filters the data, and updates the displayed page data.
   * - Logs an error if the fetched data is not in the expected format or if the fetch fails.
   */

  getTranslate(): void {
    if (!this.languages.length) {
      console.log('No languages available.')
      return
    }

    let dataLoadedCount = 0
    const allData: { [key: string]: any } = {}

    // Only load translation data for languages that have a valid object (skip empty ones)
    const validLanguages = this.languages.filter((lang) => lang.translation && typeof lang.translation === 'object' && Object.keys(lang.translation).length > 0)
    validLanguages.forEach((lang) => {
      const uniquePath = `/projects/${this.project_id}/translations/languages/${lang.title}`
      this.db.getDatabase(uniquePath).subscribe(
        (translationsData: any) => {
          if (translationsData) {
            for (const key in translationsData) {
              if (translationsData.hasOwnProperty(key)) {
                if (!allData[key]) {
                  allData[key] = { En: key } // English as a reference
                }
                allData[key][lang.title] = translationsData[key].value
              }
            }
            // Check if all valid languages are loaded
            dataLoadedCount++
            if (dataLoadedCount === validLanguages.length) {
              this.tableData = Object.values(allData)
              this.fetchAndAddTextRows()
              this.getCurrency().subscribe((currencyData) => {
                this.addCurrencyRow()
              })
              this.filterData()
              this.updatePageData(this.currentPageIndex)
            }
          } else {
            console.error(`Translations data for ${lang.title} is not in the expected format:`, translationsData)
          }
        },
        (error: any) => {
          console.error(`Failed to fetch translations for ${lang.title}:`, error)
        }
      )
    })
  }

  /*
   * handleMissingTranslations: Processes translation requests for missing language translations in the provided data object.
   * @param allData - An object where each key represents a row of data, and each value is an object mapping language codes
   *                  (e.g., 'En', 'fr', 'es') to translated strings or undefined if no translation exists yet.
   * @returns void - This method does not return a value but updates the `allData` object in place with missing translations,
   *                 and subsequently updates the component's `tableData` for display.
   *
   * Detailed Functionality:
   * - **Purpose**: Fills in missing translations for each language column in the `allData` object by leveraging the Google Translate API
   *   through the `MultilingualPipe`. It processes translations language-by-language (column-wise) rather than row-by-row to optimize
   *   network efficiency and reduce the number of API requests.
   * - **Approach**:
   *   1. For each language in `this.languages`, it identifies all rows in `allData` missing a translation for that language.
   *   2. Collects the English text (`row.En`) of those rows into an array (`textsToTranslate`) and tracks their corresponding keys
   *      (`keysToUpdate`) to map translations back to the correct rows.
   *   3. Batches the texts into smaller groups to avoid overwhelming the Google Translate API, which has a URL length limit of
   *      approximately 2,000 characters (including encoding) per request.
   *   4. Uses `forkJoin` to execute all batched translation requests for a single language concurrently, then updates `allData` with
   *      the results.
   *   5. Processes languages sequentially using `mergeMap` with a concurrency of 1 to prevent overloading the browser or hitting
   *      API rate limits (e.g., 100 requests per second per user for Google Translate).
   * - **Key Optimization (Batching)**:
   *   - Instead of making an individual API call for each missing translation (which could result in thousands of requests for large
   *     datasets, e.g., 1,000 texts * 10 languages = 10,000 requests), this method batches texts into groups, reducing the request
   *     count to `ceil(number of texts / maxBatchSize) * number of languages`.
   * - **maxBatchSize Explanation**:
   *   - Defined as `const maxBatchSize = 100`, this variable controls the maximum number of texts sent in a single API request.
   *   - **Why 100?**: This value is a practical default based on balancing API limits and performance:
   *     - The Google Translate API's `q` parameter (query string) supports multiple texts separated by `&q=`, but the total URL length
   *       must stay under ~2,000 characters. Assuming an average text length of 10-15 characters (plus encoding overhead), 100 texts
   *       typically fit within this limit (e.g., 100 * 15 ≈ 1,500 characters).
   *     - Larger batches reduce the number of requests but increase the risk of exceeding the URL length limit, causing a 414 URI
   *       Too Long error.
   *     - Smaller batches increase request count but ensure compatibility with shorter texts or stricter limits.
   *   - **Tuning maxBatchSize**:
   *     - If your texts are short (e.g., 5-10 characters), you could increase `maxBatchSize` to 150-200 to further reduce requests.
   *     - If texts are long (e.g., 50+ characters), reduce it to 20-50 to stay under the limit.
   *     - For precision, you could dynamically calculate the total character length of `textsToTranslate` and split when it nears
   *       1,500 characters, but `maxBatchSize = 100` is a safe, static compromise for most cases.
   *   - **Impact Example**: For 1,000 texts and 10 languages:
   *     - Without batching: 10,000 requests.
   *     - With `maxBatchSize = 100`: 10 batches per language * 10 languages = 100 requests.
   * - **Error Handling**: If a batch fails (e.g., due to API errors), the error is logged, but the process continues for remaining
   *   languages, ensuring partial success rather than a complete failure.
   * - **Final Steps**: After all translations are fetched and applied, it updates `tableData`, adds a currency row, filters the data,
   *   and refreshes the displayed page.
   */

  handleMissingTranslations(allData: { [key: string]: { [key: string]: string | undefined } }): void {
    const maxBatchSize = 25
    const translateColumn = (lang: { title: string }) => {
      const textsToTranslate: string[] = []
      const keysToUpdate: string[] = []
      Object.keys(allData).forEach((key) => {
        const row = allData[key]
        if (!row[lang.title]) {
          textsToTranslate.push(row.En || '')
          keysToUpdate.push(key)
        }
      })

      if (textsToTranslate.length === 0) {
        return of(null)
      }

      // Split into batches
      const batches: Observable<any>[] = []
      for (let i = 0; i < textsToTranslate.length; i += maxBatchSize) {
        const batchTexts = textsToTranslate.slice(i, i + maxBatchSize)
        const batchKeys = keysToUpdate.slice(i, i + maxBatchSize)
        batches.push(
          this.translatePipe.transform(batchTexts, lang.title).pipe(
            map((translatedTexts) => {
              const translations = translatedTexts as string[]
              translations.forEach((translatedText, index) => {
                const key = batchKeys[index]
                allData[key][lang.title] = translatedText
              })
            })
          )
        )
      }

      return forkJoin(batches)
    }

    from(this.languages)
      .pipe(mergeMap((lang) => translateColumn(lang), 1))
      .subscribe(
        () => {
          this.tableData = Object.values(allData) as TableRow[]
          this.addCurrencyRow()
          this.filterData()
          this.updatePageData(this.currentPageIndex)
        },
        (error) => {
          console.error('Error translating data:', error)
        }
      )
  }

  /*
   * callStacksTranslation: Initiates the translation process by setting a loading indicator.
   * - Sets the stacksTranslation flag to true to indicate that translation is in progress.
   * - Calls the loadData() method to fetch the necessary translations.
   */
  callStacksTranslation() {
    this.stacksTranslation = true
    this.loadData()
  }

  /**
   * Loads data from multiple sources including products, menu items, section texts, button texts,
   * product tags, and country names. Integrates the fetched data into the `tableData` structure
   * and ensures duplicates are removed. Handles asynchronous fetching of:
   * - Product variation values and attribute names
   * - Product tag categories (e.g., "brands", "colors") and individual tag values (e.g., "adidas", "red")
   * - Menu items, section texts, and button texts
   * - Country and governorate names
   * - Translation data from a static JSON file
   *
   * Ensures the `tableData` is updated with the latest combined information, with each unique
   * text value added as a separate row for translation.
   */
  loadData(): void {
    this.productService.getProducts().subscribe(
      (productsObject) => {
        const { productVariationValues, productAttributeNames, productNames, tagCategories, tagValues } = this.extractProductData(productsObject)
        const menuItems = this.getMenuItems()
        const blocksData = this.blockDataService.getAllBlocksData()
        const buttonTexts = this.extractButtonTexts(blocksData)
        const newSectionTexts = this.extractSectionTexts(blocksData)

        // Flatten so we get strings, not arrays
        const flattenedSectionTexts = newSectionTexts.flat()

        const uniqueNewSectionTexts = flattenedSectionTexts.filter((text) => !this.allSectionTexts.includes(text))

        // Push unique items
        this.allSectionTexts.push(...uniqueNewSectionTexts)

        forkJoin({
          categories: this.getCategoryNames(),
          countries: this.getCountryAndGovernorateNames()
        }).subscribe(
          ({ categories, countries }) => {
            const { countryNames, governorateNames } = countries
            this.http.get<string[]>('assets/i18n/txtTranslation.json').subscribe(
              (translationData: string[]) => {
                this.integrateDataIntoTable({
                  translationData,
                  menuItems,
                  buttonTexts,
                  sectionTexts: this.allSectionTexts,
                  productVariationValues,
                  productAttributeNames,
                  tagCategories,
                  tagValues,
                  countryNames,
                  governorateNames,
                  productNames,
                  categoryNames: categories
                })

                this.postProcessUpdates()
              },
              (error) => {
                console.error('Error fetching translation data:', error)
              }
            )
          },
          (error) => {
            console.error('Error fetching categories or countries:', error)
          }
        )
      },
      (error) => {
        console.error('Error fetching products:', error)
      }
    )
  }
  /**
   * Fetches category names from the CategoriesService.
   * @returns An Observable of category names as an array of strings.
   */
  private getCategoryNames(): Observable<string[]> {
    return this.categoriesService.getCategories().pipe(
      map((response) => {
        if (response && Array.isArray(response)) {
          return response.map((category: any) => category.categoryName).filter((name: string) => name && typeof name === 'string' && name.trim().length > 0)
        }
        return []
      })
    )
  }

  /* ---------------------------------------------------------------------
   Helper Methods
   --------------------------------------------------------------------- */

  /**
   * Extracts product variation values and attribute names from the given products array.
   *
   * @param productsObject Array of product objects returned from the productService.
   * @returns An object containing arrays of productVariationValues and productAttributeNames.
   */
  private extractProductData(productsObject: any[]): {
    productVariationValues: string[]
    productAttributeNames: string[]
    productNames: string[]
    tagCategories: string[]
    tagValues: string[]
  } {
    const productVariationValues: string[] = []
    const productAttributeNames: string[] = []
    const productNames: string[] = []
    const tagCategories: string[] = []
    const tagValues: string[] = []
    if (!Array.isArray(productsObject) && typeof productsObject !== 'object') {
      return {
        productVariationValues,
        productAttributeNames,
        productNames,
        tagCategories,
        tagValues
      }
    }
    const products = Array.isArray(productsObject) ? productsObject : Object.values(productsObject)
    products.forEach((product: any) => {
      if (product?.productName && typeof product.productName === 'string' && product.productName.trim().length > 0) {
        productNames.push(product.productName.trim())
      }
      if (product?.productVariations && Array.isArray(product.productVariations)) {
        product.productVariations.forEach((variation: any) => {
          if (variation?.values && typeof variation.values === 'string' && variation.values.trim().length > 0) {
            const trimmedVal = variation.values.trim()
            if (trimmedVal.includes(',')) {
              trimmedVal.split(',').forEach((val: string) => {
                productVariationValues.push(val.trim())
              })
            } else {
              productVariationValues.push(trimmedVal)
            }
          }
        })
      }
      if (product?.productAttributes && Array.isArray(product.productAttributes)) {
        product.productAttributes.forEach((attr: any) => {
          if (attr?.name && typeof attr.name === 'string') {
            productAttributeNames.push(attr.name)
          }
        })
      }
      if (product?.tags && typeof product.tags === 'object' && product.tags !== null) {
        Object.keys(product.tags).forEach((category) => {
          if (category && typeof category === 'string' && category.trim().length > 0) {
            tagCategories.push(category.trim())
            const tagArray = product.tags[category]
            if (Array.isArray(tagArray)) {
              tagArray.forEach((tag: any) => {
                if (tag && typeof tag === 'string' && tag.trim().length > 0) {
                  tagValues.push(tag.trim())
                }
              })
            }
          }
        })
      }
    })

    return {
      productVariationValues,
      productAttributeNames,
      productNames: Array.from(new Set(productNames)),
      tagCategories: Array.from(new Set(tagCategories)),
      tagValues: Array.from(new Set(tagValues))
    }
  }

  /**
   * Retrieves menu items from the BuilderService global footer data.
   */
  private getMenuItems(): string[] {
    return (this.BuilderService.globalFooter.data.menuItems as { menuText: string }[] | undefined)?.map((item) => item.menuText) || []
  }

  /**
   * Extracts button texts from any blocks of type "button".
   *
   * @param blocksData The array of blocks from the blockDataService.
   */
  private extractButtonTexts(blocksData: any[]): string[] {
    return blocksData.filter((block: any) => block.type === 'button' && block.data?.btnText).map((block: any) => block.data.btnText) || []
  }

  /**
   * Extracts textual content (including button text) from section blocks.
   *
   * @param blocksData The array of blocks from the blockDataService.
   */
  private extractSectionTexts(blocksData: any[]): string[] {
    return blocksData
      .filter((block: any) => block.type === 'section')
      .flatMap((block: any) => {
        return block.elements.flatMap((column: any) => {
          return column.elements.map((element: any) => {
            const btnText = element.data?.btnText || ''
            const value = element.data?.value ? this.extractTextFromHTML(element.data.value) : ''
            // Filter out empty strings
            return [btnText, value].filter(Boolean)
          })
        })
      })
  }

  /**
   * Combines all data sources, removes duplicates, maps them to table rows,
   * and updates the existing `tableData`.
   */
  private integrateDataIntoTable({
    translationData,
    menuItems,
    buttonTexts,
    sectionTexts,
    productVariationValues,
    productAttributeNames,
    tagCategories,
    tagValues,
    countryNames,
    governorateNames,
    productNames,
    categoryNames
  }: {
    translationData: string[]
    menuItems: string[]
    buttonTexts: string[]
    sectionTexts: string[]
    productVariationValues: string[]
    productAttributeNames: string[]
    tagCategories: string[]
    tagValues: string[]
    countryNames: string[]
    governorateNames: string[]
    productNames: string[]
    categoryNames: string[]
  }): void {
    // Combine data
    const allData = [...translationData, ...menuItems, ...buttonTexts, ...sectionTexts, ...productVariationValues, ...productAttributeNames, ...tagCategories, ...tagValues, ...countryNames, ...governorateNames, ...productNames, ...categoryNames].filter((text) => text && text.trim().length > 0)
    // Remove duplicates
    const uniqueData = Array.from(new Set(allData))

    // Map to TableRow objects
    const newData: TableRow[] = uniqueData.map((text) => ({ En: text }))

    // Merge new data with existing tableData
    newData.forEach((newRow) => {
      const existingRow = this.tableData.find((row) => row.En === newRow.En)
      if (!existingRow) {
        this.tableData.push(newRow)
      }
    })
  }

  /**
   * Retrieves a list of country names and their governorate names by fetching data from the shipping service.
   */
  private getCountryAndGovernorateNames(): Observable<{ countryNames: string[]; governorateNames: string[] }> {
    return this.shippingService.getCountries().pipe(
      map((countries: any[]) => {
        if (!countries || !countries.length) {
          return { countryNames: [], governorateNames: [] }
        }
        const countryNames = countries.map((country) => country.countryName)
        const governorateNames: string[] = []

        countries.forEach((country) => {
          if (country.governorates && Array.isArray(country.governorates)) {
            country.governorates.forEach((governorate: any) => {
              governorateNames.push(governorate.governorateName)
            })
          }
        })
        return { countryNames, governorateNames }
      })
    )
  }

  /**
   * postProcessUpdates: Performs necessary post-processing steps after `this.tableData` has been updated.
   * - Fetches and adds additional text rows by calling `fetchAndAddTextRows()`.
   * - Incorporates currency-specific data into the table by invoking `addCurrencyRow()`.
   * - Applies any active filters to the updated data using `filterData()`.
   * - Refreshes the displayed data for the current page index with `updatePageData()`.
   * - If `stacksTranslation` is enabled:
   *   - Converts `tableData` (an array of rows) into an object format with unique keys for compatibility with `handleMissingTranslations`.
   *   - Calls `handleMissingTranslations()` to process and fill in any missing translations in the data.
   */

  private postProcessUpdates(): void {
    this.fetchAndAddTextRows()
    this.addCurrencyRow()
    this.filterData()
    this.currentPageIndex = 0
    this.updatePageData(this.currentPageIndex)

    if (this.stacksTranslation) {
      // Convert tableData array into an object
      const tableDataObject = this.tableData.reduce((acc, row, index) => {
        acc[index] = row
        return acc
      }, {} as { [key: string]: { [key: string]: string | undefined } })

      this.handleMissingTranslations(tableDataObject)
    }
  }

  /*
   * toggleEdit: Toggles the edit mode for a specific cell in the table.
   * - Accepts a row of type TableRow and a language string as parameters.
   * - If the specified row and language are already being edited, it resets the editingCell to null.
   * - Otherwise, it sets the editingCell to the provided row and language, enabling editing for that cell.
   */

  toggleEdit(row: TableRow, lang: string): void {
    if (this.editingCell.row === row && this.editingCell.lang === lang) {
      this.editingCell = { row: null, lang: null }
    } else {
      this.editingCell = { row, lang }
    }
  }

  /*
   * filterData: Filters the table data based on the user's search input.
   *
   * 1. If there is a search string, convert it to lowercase for case-insensitive matching.
   * 2. For each row in `tableData`, check the following fields:
   *    - `En` (English field), if present.
   *    - `Currency`, if present.
   *    - Each language-specific field (from `this.languages`), if present.
   *    If any of these contains the search string, include the row in `filteredData`.
   * 3. If no search string is provided, copy the entire `tableData` into `filteredData`.
   * 4. Call `updatePageData(0)` to reset pagination to the first page.
   */

  filterData(): void {
    if (this.searchText) {
      const searchLower = this.searchText.toLowerCase()
      this.filteredData = this.tableData.filter((row) => {
        const enValue = row.En ? String(row.En).toLowerCase() : ''
        const currencyValue = row.Currency ? String(row.Currency).toLowerCase() : ''
        const matchEn = enValue.includes(searchLower)
        const matchCurrency = currencyValue.includes(searchLower)

        // Check across your languages array:
        const matchLang = this.languages.some((lang) => {
          const langValue = row[lang.title] ? String(row[lang.title]).toLowerCase() : ''
          return langValue.includes(searchLower)
        })

        return matchEn || matchCurrency || matchLang
      })
    } else {
      // Copy all data if no search text
      this.filteredData = [...this.tableData]
    }
    this.updatePageData(0) // Reset to the first page after filtering
  }

  /*
   * loadLanguages: Loads available languages for the selected project from the database.
   * - Constructs a unique database path using the selected project ID.
   * - Fetches language data and logs it to the console.
   * - Checks if the fetched data is an object; if so, transforms it into an array of language objects,
   *   each containing a title and its corresponding translation.
   * - Searches for the index of the "English" language and, if found and not already first,
   *   moves it to the beginning of the languages array.
   * - Updates the displayedColumns to reflect the available languages and calls getTranslate() to
   *   load the translations.
   * - Logs an error if the data format is unexpected or if the fetch operation fails.
   */

  loadLanguages(): void {
    const uniquePath = `/projects/${this.project_id}/translations/languages`

    this.db.getDatabase(uniquePath).subscribe(
      (languagesData: any) => {
        if (languagesData && typeof languagesData === 'object') {
          // Transform languagesData object into an array of language objects
          this.languages = Object.keys(languagesData).map((key) => ({
            title: key,
            translation: languagesData[key]
          }))

          // Find the index of "English" language
          const englishIndex = this.languages.findIndex((lang) => lang.title === 'English')

          // If "English" is found and it's not already the first element
          if (englishIndex !== -1 && englishIndex !== 0) {
            // Move "English" to the beginning of the array
            const englishLanguage = this.languages.splice(englishIndex, 1)[0]
            this.languages.unshift(englishLanguage)
          }
          this.displayedColumns = [...this.languages.map((lang) => lang.title)]
          this.getTranslate()
        } else {
          console.error('Languages data is not in the expected format:', languagesData)
        }
      },
      (error) => {
        console.error('Error fetching languages data:', error)
      }
    )
  }

  /*
   * saveChanges: Saves updated translations for all languages associated with the selected project.
   * - Sets a loading indicator to indicate that the save operation is in progress.
   * - Defines an interface for translations to ensure proper typing.
   * - Iterates over each row in tableData, initializing language-specific fields if they are undefined.
   * - For each language, creates a translationsObject where each key is a valid English term and
   *   its value is an object containing the translation.
   * - Special handling is applied for the English language, where the value is set to the term itself.
   * - Constructs a unique database path for each language's translations and saves the translationsObject
   *   to the database.
   * - Logs success messages upon saving or error messages if the save operation fails,
   *   and updates the loading indicator accordingly.
   */

  saveChanges(): void {
    this.productProgress = true
    interface Translations {
      [key: string]: { value: string }
    }
    // Iterate over each row in tableData and update local values if needed
    this.tableData.forEach((row) => {
      this.languages.forEach((lang) => {
        row[lang.title] = row[lang.title] || ''
      })
    })

    this.languages.forEach((lang) => {
      let translationsObject: Translations = {} // Define the type of translationsObject

      // Create an object where key is the English term and value is the nested translation
      this.tableData.forEach((row) => {
        const key = this.generateValidKey(row.En)
        if (!key) {
          return
        }
        // Set the translation using the valid key
        if (lang.title === 'English') {
          // If the language is English, set the value to be equal to the key
          translationsObject[key] = { value: key.replace(/-/g, ' ') }
        } else {
          // Otherwise, set the value normally
          translationsObject[key] = { value: row[lang.title] }
        }
      })

      const uniquePath = `/projects/${this.project_id}/translations/languages/${lang.title}`

      this.db.setDatabase(uniquePath, translationsObject).subscribe(
        () => {
          // No `response` expected
          this.productProgress = false
        },
        (error) => {
          this.productProgress = false
          console.error(`Failed to save translations for ${lang.title}:`, error)
        }
      )
    })
  }
  /*
   * generateValidKey: Generates a valid key from the input string by replacing
   * specific characters (such as #, $, /, [, ], and spaces) with spaces.
   * - Returns the sanitized string, which can be used as a valid key in translations.
   */
  generateValidKey(input: string): string {
    return input.replace(/[.#$\/\[\] ]/g, ' ')
  }

  /*
   * getCurrency: Fetches the currency settings for the application.
   * - Subscribes to the settingsService to retrieve currency data.
   * - On successful retrieval, assigns the currency data to the currency property
   *   and calls addCurrencyRow() to incorporate the currency into the table.
   * - Logs an error message if fetching the currency data fails.
   */

  getCurrency(): Observable<any> {
    return new Observable((observer) => {
      this.settingsService.getCurrencySettings().subscribe(
        (data) => {
          if (data) {
            this.currency = data
            observer.next(this.currency)
            observer.complete()
          } else {
            console.warn('Currency data is empty or invalid')
            observer.error('Currency data is empty')
          }
        },
        (error) => {
          console.error('Error fetching currency data:', error)
          observer.error(error)
        }
      )
    })
  }

  /*
   * addCurrencyRow: Adds a new row for the currency to the table data.
   * - Checks if currency data is available; if so, creates a new currencyRow
   *   object with the currency symbol.
   * - For each language, constructs a unique database path to fetch the currency
   *   translation.
   * - Subscribes to the database to retrieve the currency translation for each language,
   *   adding it to the currencyRow.
   * - If the currencyRow does not already exist in the tableData, it adds it.
   * - Calls filterData() to apply any active search filters after updating the table.
   * - Logs an error if fetching the currency translation fails for any language.
   */
  addCurrencyRow() {
    if (!this.currency || typeof this.currency === 'object') {
      console.warn('Skipping execution: currency is empty.')
      return
    }
    if (this.currency) {
      const currencySymbol = this.currency
      const currencyRow: TableRow = { En: currencySymbol }

      // Add the currency translations for each language
      this.languages.forEach((lang) => {
        const uniquePath = `/projects/${this.project_id}/translations/languages/${lang.title}/${currencySymbol}`
        this.db.getDatabase(uniquePath).subscribe(
          (currencyTranslation: any) => {
            if (currencyTranslation) {
              currencyRow[lang.title] = currencyTranslation.value
              if (!this.tableData.some((row) => row.En === currencyRow.En)) {
                this.tableData.push(currencyRow)
              }
              this.filterData()
            } else {
              console.error(`Currency translation for ${lang.title} is not in the expected format:`, currencyTranslation)
            }
          },
          (error: any) => {
            console.error(`Failed to fetch currency translation for ${lang.title}:`, error)
          }
        )
      })
    }
  }

  // Helper function to sanitize text so it can be used as a Firebase key
  private sanitizeKey(key: string): string {
    // Replace invalid Firebase path characters with underscores (or any valid character)
    return key.replace(/[.#$[\]]/g, '')
  }

  /*
   * addTextRows: Adds new rows to the table data for text blocks retrieved from the database.
   *
   * 1. Iterate over the keys in `textBlocks`, and for each key, retrieve the `allBlocks` array.
   * 2. Within `allBlocks`, look for blocks of type 'text':
   *    - Extract the text content (e.g., from HTML) and skip if empty.
   *    - Sanitize the text to create a valid key path for database queries.
   *    - Create a new table row object (`textRow`) with this text under `En`.
   * 3. For each configured language:
   *    - Build the path to fetch an existing translation from the database.
   *    - If a translation is found, use it; otherwise, default to "Not Translated."
   *    - Check if this exact `textRow` (by `row.En`) is already in `tableData`.
   *      If not, push it.
   * 4. After updating the row with translations, call `filterData()` to re-apply any active filters.
   */

  addTextRows(textBlocks: any) {
    if (!textBlocks) return

    Object.keys(textBlocks).forEach((key) => {
      const allBlocks = textBlocks[key].allBlocks
      if (!Array.isArray(allBlocks)) return

      allBlocks.forEach((block: any) => {
        if (block.type === 'text') {
          // Extract and trim text
          const textValue = this.extractTextFromHTML(block.data.value)

          // Skip if empty
          if (!textValue) {
            return
          }

          // Sanitize the extracted text to avoid invalid Firebase path characters
          const sanitizedTextValue = this.sanitizeKey(textValue)

          // Prepare a row with the English text
          const textRow: TableRow = { En: sanitizedTextValue }

          // Loop through languages and fetch existing translations
          this.languages.forEach((lang) => {
            const uniquePath = `/projects/${this.project_id}/translations/languages/${lang.title}/${sanitizedTextValue}`

            // Get translation from Firebase (or any other DB you’re using)
            this.db.getDatabase(uniquePath).subscribe((translation: any) => {
              // If translation doesn't exist, use a fallback
              textRow[lang.title] = translation ? translation.value : 'Not Translated'

              // Only add the new row if it doesn’t already exist
              if (!this.tableData.some((row) => row.En === textRow.En)) {
                this.tableData.push(textRow)
              }

              this.filterData()
            })
          })
        }
      })
    })
  }

  /*
   * extractTextFromHTML: Helper function to extract plain text from HTML strings.
   * - Uses DOMParser to parse the HTML content and extract the text content.
   */
  extractTextFromHTML(html: string): string {
    const parser = new DOMParser()
    const doc = parser.parseFromString(html, 'text/html')

    // Select all <p> elements
    const pElements = doc.querySelectorAll('p')

    // Loop through and remove <p> that contain only <br> or are empty
    pElements.forEach((p) => {
      if (p.innerHTML.trim() === '<br>' || p.innerHTML.trim() === '') {
        p.remove()
      }
    })

    // Extract text content of remaining <p> tags, filtering out empty strings
    const paragraphs = Array.from(doc.querySelectorAll('p'))
      .map((p) => p.textContent?.trim() || '')
      .filter((text) => text !== '') // Filter out empty strings

    return paragraphs.join(' ').trim()
  }

  /*
   * fetchAndAddTextRows: Fetches text blocks from the database and processes them.
   * - Constructs the database path based on the selected project.
   * - Subscribes to the database to retrieve text blocks.
   * - If text blocks are found, passes them to the `addTextRows` function for processing and translation.
   * - Logs a message if no text blocks are found.
   */

  fetchAndAddTextRows() {
    const uniquePath = `/projects/${this.project_id}/views`
    this.db.getDatabase(uniquePath).subscribe((textBlock: any) => {
      if (textBlock) {
        this.addTextRows(textBlock)
      } else {
        console.log('No text blocks found.')
      }
    })
  }

  /**
   * Extracts tag categories and individual tag values from products.tags, removing duplicates.
   * @param productsObject Array of product objects returned from the productService.
   * @returns An object with tagCategories (e.g., "brands", "colors") and tagValues (e.g., "adidas", "red").
   */
  private extractProductTags(productsObject: any[]): { tagCategories: string[]; tagValues: string[] } {
    const tagCategories: string[] = []
    const tagValues: string[] = []

    // Iterate through products
    Object.values(productsObject).forEach((product: any) => {
      if (product.tags && typeof product.tags === 'object') {
        // Extract categories (e.g., "brands", "colors")
        Object.keys(product.tags).forEach((category) => {
          if (category && typeof category === 'string' && category.trim().length > 0) {
            tagCategories.push(category.trim())
          }

          // Extract tag values (e.g., "adidas", "red")
          const tagArray = product.tags[category]
          if (Array.isArray(tagArray)) {
            tagArray.forEach((tag: string) => {
              if (tag && typeof tag === 'string' && tag.trim().length > 0) {
                tagValues.push(tag.trim())
              }
            })
          }
        })
      }
    })

    // Remove duplicates using Set and return as arrays
    return {
      tagCategories: Array.from(new Set(tagCategories)),
      tagValues: Array.from(new Set(tagValues))
    }
  }
}
