<div class="secondarySidebar">
  <div class="secondarySBTitle">
    <h2>{{ 'Analytics' | translate }}</h2>
  </div>
  <div class="secondarySBContent">
    <ul>
      <li [routerLink]="['/analytics/page-stats']">{{ 'Page Stats' | translate }}</li>
      <li class="selected" [routerLink]="['/analytics/orders']">{{ 'Orders' | translate }}</li>
      <!-- <li [routerLink]="['/admin/logs']">Billing</li> -->
    </ul>
  </div>
</div>
<div id="selfWrapper" class="containerWrapper withSidebar noBG">
  <!-- <mat-progress-bar mode="indeterminate" *ngIf="PNProgress"></mat-progress-bar> -->
  <div id="titleContainer" class="innerContainerTitle">
    <h1 class="desktopOnly">{{ 'Orders Stats' | translate }}</h1>
  </div>
  <div id="selfContainer" class="innerContainerContent">
    <div class="gridWrapper">
      <div class="fullGrid">
        <mat-toolbar>
          <span>{{ 'Mobile App Sales' | translate }}</span>
        </mat-toolbar>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'Filter' | translate }}</mat-label>
          <input matInput (keyup)="applySubFilter($event)" placeholder="Ex. Home" #input />
        </mat-form-field>

        <form [formGroup]="form" class="dateModule" (submit)="applyDateFilter()">
          <mat-form-field>
            <input matInput placeholder="Date From" [matDatepicker]="picker1" formControlName="fromDate" required (click)="picker1.open()" />
            <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
            <mat-datepicker #picker1></mat-datepicker>
          </mat-form-field>
          <mat-form-field>
            <input matInput placeholder="toDate" [matDatepicker]="picker2" formControlName="toDate" required (click)="picker2.open()" />
            <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
            <mat-datepicker #picker2></mat-datepicker>
          </mat-form-field>
          <button class="dateActionBtn stacksBtn blue" mat-raised-button (click)="applyDateFilter()" [disabled]="form.invalid">{{ 'Submit' | translate }}</button>
          <button mat-raised-button class="dateActionBtn stacksBtn green" type="button" [disabled]="form.invalid" (click)="clearDateFilter()">Clear</button>
        </form>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 fullWidthTable" matSort matSortActive="date" matSortDirection="desc">
          <ng-container matColumnDef="order_id">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'OrderId' | translate }}</th>
            <td mat-cell *matCellDef="let elements">{{ elements.order_id }}</td>
          </ng-container>

          <ng-container matColumnDef="order_date">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Date' | translate }}</th>
            <td mat-cell *matCellDef="let elements">{{ elements.order_date }}</td>
          </ng-container>

          <ng-container matColumnDef="order_status">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Status' | translate }}</th>
            <td mat-cell *matCellDef="let elements">{{ elements.order_status }}</td>
          </ng-container>

          <ng-container matColumnDef="order_total">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Order Total' | translate }}</th>
            <td mat-cell *matCellDef="let elements">{{ elements.order_total }}</td>
          </ng-container>

          <ng-container matColumnDef="user_email">
            <th mat-header-cell *matHeaderCellDef>{{ 'E-Mail' | translate }}</th>
            <td mat-cell *matCellDef="let elements">{{ elements.user_email }}</td>
          </ng-container>

          <ng-container matColumnDef="order_link">
            <th mat-header-cell *matHeaderCellDef>{{ 'Order Link' | translate }}</th>
            <td mat-cell *matCellDef="let elements">
              <a href="{{ elements.order_link }}" style="margin-left: -60px" class="stacksBtn smallBtn lightBtn green" target="_blank">Order Link</a>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="ordersColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: ordersColumns"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons aria-label="Select order of periodic dataSource"> </mat-paginator>
        <div class="fullGrid">
          <div class="widgetChart">
            <canvas baseChart [data]="subChartData" [options]="subChartOptions" type="bar"></canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
