import { Component, ViewChild, AfterViewInit, OnInit } from '@angular/core'
import { MatTableDataSource } from '@angular/material/table'
import { MatPaginator } from '@angular/material/paginator'
import { AdminDashboardService } from '../admin-dashboard.service'

export interface UserDataTable {
  id: string | null
  email: string | null
  status: string | null
  type: string | null
  access: string | null
  domain: string | null
  premium_package: string | null
  premium_status: string | null
  start_date: string | null
}

@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.scss']
})
export class AdminDashboardComponent implements OnInit, AfterViewInit {
  @ViewChild('paginator1') paginator1!: MatPaginator
  @ViewChild('paginator2') paginator2!: MatPaginator

  mobile: boolean = false
  searchValTab1: string = ''
  searchValTab2: string = ''
  originalData: any
  originalData2: any

  displayedColumns: string[] = ['id', 'email', 'access', 'domain', 'premium_package', 'premium_status', 'start_date', 'status', 'type']

  dataSourceTab1: MatTableDataSource<UserDataTable> = new MatTableDataSource<UserDataTable>()
  dataSourceTab2: MatTableDataSource<UserDataTable> = new MatTableDataSource<UserDataTable>()

  constructor(private adminDashService: AdminDashboardService) {}

  ngOnInit() {
    this.adminDashService.getTrials().subscribe((data) => {
      const transformedData: UserDataTable[] = Object.keys(data).map((key) => {
        const trialData = { ...data[key], id: key }

        return trialData
      })

      this.dataSourceTab1.data = transformedData
      this.originalData = transformedData // Store the original data
      this.dataSourceTab1.paginator = this.paginator1
    })
    this.adminDashService.getSubscriptions().subscribe((data) => {
      const transformedData: UserDataTable[] = Object.keys(data).map((key) => {
        const subData = { ...data[key], id: key }
        console.log(subData)

        return subData
      })

      this.dataSourceTab2.data = transformedData
      this.originalData2 = transformedData // Store the original data
      this.dataSourceTab2.paginator = this.paginator2
    })
  }

  ngAfterViewInit() {
    this.dataSourceTab1.paginator = this.paginator1
    this.dataSourceTab2.paginator = this.paginator2
  }

  searchTab1() {
    if (!this.searchValTab1) {
      this.dataSourceTab1.data = this.originalData // Restore original data if search is empty
      return
    }

    let filteredData = this.originalData.filter((item: UserDataTable) => item.id?.includes(this.searchValTab1) || item.email?.includes(this.searchValTab1) || item.access?.includes(this.searchValTab1) || item.status?.includes(this.searchValTab1) || item.type?.includes(this.searchValTab1))

    this.dataSourceTab1.data = filteredData
  }

  // Search function for Tab 2
  searchTab2() {
    if (!this.searchValTab2) {
      this.dataSourceTab2.data = this.originalData2 // Restore original data if search is empty
      return
    }

    let filteredData2 = this.originalData2.filter((item: UserDataTable) => item.id?.includes(this.searchValTab2) || item.email?.includes(this.searchValTab2) || item.access?.includes(this.searchValTab2) || item.status?.includes(this.searchValTab2) || item.type?.includes(this.searchValTab2))

    this.dataSourceTab2.data = filteredData2
  }
}
