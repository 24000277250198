import { Component, OnInit } from '@angular/core'
import { ChartConfiguration } from 'chart.js'
import { CloudService } from '../cloud.service'

@Component({
  selector: 'cloud-churn',
  templateUrl: './cloud-churn.component.html',
  styleUrls: ['./cloud-churn.component.scss']
})
export class CloudChurnComponent implements OnInit {
  public churn: any = []
  public churn_dates: any = []
  public churn_data_counts: any = []
  constructor(public cloudService: CloudService) {}

  ngOnInit(): void {
    let allUnsubs = this.cloudService.allUnSubsData
    let allSubs = this.cloudService.allSubsData

    for (const key in allSubs) {
      if (Object.prototype.hasOwnProperty.call(allSubs, key)) {
        this.churn[key] = (allUnsubs[key] / allSubs[key]) * 100
        this.churn_dates.push(key)
        this.churn_data_counts.push(this.churn[key])
      }
    }
    this.churnChartData.labels = this.churn_dates
    this.churnChartData.datasets[0].data = this.churn_data_counts
  }

  public churnChartData: ChartConfiguration['data'] = {
    datasets: [
      {
        data: [],
        label: 'Churn Rate',
        yAxisID: 'y-axis-1',
        backgroundColor: 'rgba(255,0,0,0.3)',
        borderColor: 'red',
        pointBackgroundColor: 'rgba(148,159,177,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)',
        fill: 'origin'
      }
    ]
    // labels: [ 'January', 'February', 'March', 'April', 'May', 'June', 'July' ]
  }

  public churnChartOptions: ChartConfiguration['options'] = {
    elements: {
      line: {
        tension: 0.5
      }
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      x: {},
      'y-axis-1': {
        position: 'right',
        grid: {
          color: 'rgba(255,0,0,0.3)'
        },
        ticks: {
          color: 'red'
        }
      }
    }
  }
}
