import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { SettingsService } from '../settings/settings.service'

interface PaymentMethod {
  id: string
  description: string
  title: string
  checked: boolean
  paymentId?: string
  category?: string
}

interface Settings {
  paymentMethod?: PaymentMethod[]
  stripApiKey?: string
  geidea?: {
    publicKey: string
    apiPassword: string
    enCodedGeideaAuth?: string
  }
  paymob?: {
    apiKey: string
    iframeUrl: string
  }
  [key: string]: any
}

@Component({
  selector: 'app-payment-methods',
  templateUrl: './payment-methods.component.html',
  styleUrl: './payment-methods.component.scss'
})
export class PaymentMethodsComponent implements OnInit {
  settings: Settings = {}
  settingsProgress = false
  saveState = false
  paymentData: PaymentMethod[] = []
  paymobSubMethods: PaymentMethod[] = []
  isStripChecked = false
  isGeideaChecked = false
  isCodChecked = false
  isPaymobChecked = false
  isPaymobMigsChecked = false
  isPaymobWalletChecked = false
  isPaymobPaypalChecked = false
  isPaymobKioskChecked = false
  isValidGeidea = true
  isValidStripeApiKey = true
  invalidStripeApiKey = ''
  invalidGeideaKey = ''

  paymentSettingsForm: FormGroup

  paymentMethods: PaymentMethod[] = [
    { id: 'cod', description: 'Cash On Delivery (COD)', title: 'Cash On Delivery (COD)', checked: true },
    { id: 'stripe', description: 'Pay With Stripe', title: 'Pay With Stripe', checked: false },
    { id: 'geidea', description: 'Pay With Geidea', title: 'Pay With Geidea', checked: false },
    { id: 'paymob', description: 'Paymob', title: 'Paymob', checked: false, category: 'paymob' },
    { id: 'paymob-migs', description: 'Paymob card', title: 'Debit-Credit Card', paymentId: '', category: 'paymob', checked: false },
    { id: 'paymob-wallet', description: 'Paymob Wallet', title: 'Mobile Wallet', paymentId: '', category: 'paymob', checked: false },
    { id: 'paymob-paypal', description: 'Paymob Paypal', title: 'Paypal', paymentId: '', category: 'paymob', checked: false },
    { id: 'paymob-kiosk', description: 'Paymob Kiosk', title: 'Kiosk', paymentId: '', category: 'paymob', checked: false }
  ]

  constructor(private settingService: SettingsService, private fb: FormBuilder) {
    this.paymentSettingsForm = this.fb.group({
      stripApiKey: [''],
      geideaPublicKey: [''],
      geideaApiPassword: [''],
      paymobMigsPaymentId: [''],
      paymobWalletPaymentId: [''],
      paymobPaypalPaymentId: [''],
      paymobKioskPaymentId: [''],
      paymobApiKey: [''],
      paymobIframeUrl: ['']
    })
    this.updatePaymobSubMethods()
  }

  ngOnInit(): void {
    this.loadPaymentSettings()
  }

  private loadPaymentSettings(): void {
    this.settingService.getSettings().subscribe((settings: Settings) => {
      const effectiveSettings = settings || {}
      this.settings = effectiveSettings

      this.paymentSettingsForm.patchValue({
        stripApiKey: effectiveSettings.stripApiKey || '',
        geideaPublicKey: effectiveSettings.geidea?.publicKey || '',
        geideaApiPassword: effectiveSettings.geidea?.apiPassword || '',
        paymobApiKey: effectiveSettings.paymob?.apiKey || '',
        paymobIframeUrl: effectiveSettings.paymob?.iframeUrl || ''
      })
      const paymobMethod = this.paymentMethods.find((method) => method.id === 'paymob')
      if (effectiveSettings.paymentMethod && effectiveSettings.paymentMethod.length > 0) {
        this.compareAndSetChecked(this.paymentMethods, effectiveSettings.paymentMethod)
        if (paymobMethod && effectiveSettings.paymob?.apiKey && effectiveSettings.paymob?.iframeUrl) {
          paymobMethod.checked = true
          this.updatePaymentState(paymobMethod, true)
        }
        this.paymentMethods.forEach((method) => {
          const dbMethod = effectiveSettings.paymentMethod!.find((pm: PaymentMethod) => pm.id === method.id)
          if (dbMethod && dbMethod.paymentId) {
            method.paymentId = dbMethod.paymentId
            const controlName = this.getControlName(method.id)
            this.paymentSettingsForm.patchValue({ [controlName]: method.paymentId })
          }
        })
      } else {
        this.paymentMethods.forEach((method) => {
          if (method.id === 'cod') {
            method.checked = true
            this.isCodChecked = true
            this.paymentData.push(method)
          }
        })
      }
      this.updatePaymobSubMethods()
    })
  }

  togglePayment(payment: PaymentMethod): void {
    // Prevent unchecking if it's the last checked method
    if (!payment.checked && this.paymentData.length === 1 && this.paymentData[0].id === payment.id) {
      alert('You must have at least one payment method enabled.')
      return
    }

    payment.checked = !payment.checked
    this.updatePaymentState(payment, payment.checked)
    this.toggleValidators(payment, payment.checked)
    this.updatePaymobSubMethods()
  }

  updatePaymentId(payment: PaymentMethod, paymentId: string): void {
    payment.paymentId = paymentId
  }

  compareAndSetChecked(availableMethods: PaymentMethod[], selectedMethods: PaymentMethod[]): void {
    availableMethods.forEach((method) => {
      const isSelected = selectedMethods.some((selected) => selected.id === method.id)
      method.checked = isSelected
      this.updatePaymentState(method, isSelected)
    })
  }

  private updatePaymentState(payment: PaymentMethod, isChecked: boolean): void {
    switch (payment.id) {
      case 'stripe':
        this.isStripChecked = isChecked
        break
      case 'geidea':
        this.isGeideaChecked = isChecked
        break
      case 'cod':
        this.isCodChecked = isChecked
        break
      case 'paymob':
        this.isPaymobChecked = isChecked
        break
      case 'paymob-migs':
        this.isPaymobMigsChecked = isChecked
        break
      case 'paymob-wallet':
        this.isPaymobWalletChecked = isChecked
        break
      case 'paymob-paypal':
        this.isPaymobPaypalChecked = isChecked
        break
      case 'paymob-kiosk':
        this.isPaymobKioskChecked = isChecked
        break
    }

    // Only add to paymentData if it's not the paymob parent
    if (payment.id !== 'paymob') {
      const index = this.paymentData.findIndex((p) => p.id === payment.id)
      if (isChecked && index === -1) {
        // Remove paymentId for stripe and geidea before adding
        const paymentCopy = { ...payment }
        if (paymentCopy.id === 'stripe' || paymentCopy.id === 'geidea') {
          delete paymentCopy.paymentId
        }
        this.paymentData.push(paymentCopy)
      } else if (!isChecked && index !== -1) {
        this.paymentData.splice(index, 1)
      }
    }
  }

  private toggleValidators(payment: PaymentMethod, isChecked: boolean): void {
    const controlName = this.getControlName(payment.id)
    const control = this.paymentSettingsForm.get(controlName)
    if (control) {
      if (isChecked) {
        control.setValidators(Validators.required)
      } else {
        control.clearValidators()
      }
      control.updateValueAndValidity()
    }
  }

  getControlName(paymentId: string): string {
    const map: { [key: string]: string } = {
      stripe: 'stripApiKey',
      geidea: 'geideaPublicKey',
      'paymob-migs': 'paymobMigsPaymentId',
      'paymob-wallet': 'paymobWalletPaymentId',
      'paymob-paypal': 'paymobPaypalPaymentId',
      'paymob-kiosk': 'paymobKioskPaymentId'
    }
    return map[paymentId] || ''
  }

  arePaymobFieldsFilled(): boolean {
    const formValue = this.paymentSettingsForm.value
    return !!formValue.paymobApiKey && !!formValue.paymobIframeUrl
  }

  private updatePaymobSubMethods(): void {
    this.paymobSubMethods = this.paymentMethods.filter((m) => m.category === 'paymob' && m.id !== 'paymob')
  }

  saveSettings(): void {
    this.settingsProgress = true
    this.saveState = true
    const formValue = this.paymentSettingsForm.value

    // Check if at least one payment method is selected
    if (this.paymentData.length === 0) {
      alert('You must enable at least one payment method.')
      this.settingsProgress = false
      this.saveState = false
      return
    }

    // Ensure all payment methods have their paymentId updated from the form (only for Paymob sub-methods)
    this.paymentMethods.forEach((method) => {
      const controlName = this.getControlName(method.id)
      if (formValue[controlName] && method.category === 'paymob' && method.id !== 'paymob') {
        method.paymentId = formValue[controlName]
        const paymentIndex = this.paymentData.findIndex((p) => p.id === method.id)
        if (paymentIndex !== -1) {
          this.paymentData[paymentIndex].paymentId = method.paymentId
        }
      }
    })

    this.settings = {
      ...this.settings,
      paymentMethod: this.paymentData,
      stripApiKey: this.isStripChecked ? formValue.stripApiKey : '',
      geidea: this.isGeideaChecked
        ? {
            publicKey: formValue.geideaPublicKey,
            apiPassword: formValue.geideaApiPassword,
            enCodedGeideaAuth: btoa(`${formValue.geideaPublicKey}:${formValue.geideaApiPassword}`)
          }
        : { publicKey: '', apiPassword: '', enCodedGeideaAuth: '' },
      ...(this.paymobSubMethods.some((m) => m.checked) && {
        paymob: {
          apiKey: formValue.paymobApiKey,
          iframeUrl: formValue.paymobIframeUrl
        }
      })
    }

    if (this.isStripChecked && formValue.stripApiKey) {
      this.validateStripeKey(formValue.stripApiKey)
      if (!this.isValidStripeApiKey) {
        this.settingsProgress = false
        return
      }
    }

    if (this.isGeideaChecked && formValue.geideaPublicKey && formValue.geideaApiPassword) {
      const token = this.settings.geidea?.enCodedGeideaAuth
      if (token) {
        this.validateGeidea(token)
      }
      if (!this.isValidGeidea) {
        this.settingsProgress = false
        return
      }
    }

    this.settingService.submitSettings(this.settings).subscribe(
      (result: any) => {
        this.settingsProgress = false
        this.saveState = false
      },
      (error) => {
        console.error('Save failed', error)
        this.settingsProgress = false
      }
    )
  }

  validateStripeKey(apiKey: string): void {
    this.settingService.validateStripeApiKey(apiKey).subscribe({
      next: () => {
        this.paymentSettingsForm.get('stripApiKey')?.setErrors(null)
        this.isValidStripeApiKey = true
        this.invalidStripeApiKey = ''
      },
      error: (err) => {
        const message = err.error.error.message
        this.invalidStripeApiKey = message
        this.paymentSettingsForm.get('stripApiKey')?.setErrors({ invalidStripeApiKey: true })
        this.isValidStripeApiKey = false
      }
    })
  }

  validateGeidea(token: string): void {
    this.settingService.validateGeideaToken(token).subscribe({
      next: (response) => {
        if (response.detailedResponseCode === '000') {
          this.paymentSettingsForm.get('geideaPublicKey')?.setErrors(null)
          this.paymentSettingsForm.get('geideaApiPassword')?.setErrors(null)
          this.isValidGeidea = true
          this.invalidGeideaKey = ''
        } else {
          this.invalidGeideaKey = response.detailedResponseMessage
          this.paymentSettingsForm.get('geideaPublicKey')?.setErrors({ invalidPublicKey: true })
          this.paymentSettingsForm.get('geideaApiPassword')?.setErrors({ invalidPassword: true })
          this.isValidGeidea = false
        }
      },
      error: () => {
        this.invalidGeideaKey = 'Invalid Geidea credentials'
        this.paymentSettingsForm.get('geideaPublicKey')?.setErrors({ invalidPublicKey: true })
        this.paymentSettingsForm.get('geideaApiPassword')?.setErrors({ invalidPassword: true })
        this.isValidGeidea = false
      }
    })
  }
  getPaymentIcon(paymentId: string, isChecked: boolean): string {
    const icons: { [key: string]: { checked: string; unchecked: string } } = {
      cod: {
        checked: 'assets/images/payments/cod-checked.png',
        unchecked: 'assets/images/payments/cod.png'
      },
      stripe: {
        checked: 'assets/images/payments/stripe-checked.png',
        unchecked: 'assets/images/payments/stripe.png'
      },
      geidea: {
        checked: 'assets/images/payments/geidea-checked.png',
        unchecked: 'assets/images/payments/geidea.png'
      },
      paymob: {
        checked: 'assets/images/payments/paymob-checked.png',
        unchecked: 'assets/images/payments/paymob.png'
      },
      'paymob-migs': {
        checked: 'assets/images/payments/paymob-checked.png',
        unchecked: 'assets/images/payments/paymob.png'
      },
      'paymob-wallet': {
        checked: 'assets/images/payments/paymob-checked.png',
        unchecked: 'assets/images/payments/paymob.png'
      },
      'paymob-paypal': {
        checked: 'assets/images/payments/paymob-checked.png',
        unchecked: 'assets/images/payments/paymob.png'
      },
      'paymob-kiosk': {
        checked: 'assets/images/payments/paymob-checked.png',
        unchecked: 'assets/images/payments/paymob.png'
      }
    }
    return isChecked ? icons[paymentId]?.checked || '' : icons[paymentId]?.unchecked || ''
  }
}
