<div class="containerWrapper">
  <div class="innerWrapper">
    <div class="orderWrapper">
      <div class="itemsAndInfo">
        <div class="customerInfo">
          <h1>{{ 'User Info' | translate }}</h1>
          <div class="customerWrapper">
            <section *ngIf="userData">
              <div>
                <div class="customerDetails">
                  <div class="row-container">
                    <div class="item-container">
                      <p>{{ 'First Name' | translate }}</p>
                      <p>{{ userData.given_name || userData.first_name }}</p>
                    </div>
                    <div class="item-container">
                      <p>{{ 'Last Name' | translate }}</p>
                      <p>{{ userData.family_name || userData.last_name }}</p>
                    </div>
                  </div>

                  <div class="row-container">
                    <div class="item-container" *ngIf="!adminUser">
                      <p>{{ 'Name' | translate }}</p>
                      <p>{{ userData.name }}</p>
                    </div>
                  </div>

                  <div class="row-container">
                    <div class="item-container" *ngIf="!adminUser">
                      <p>{{ 'Nickname' | translate }}</p>
                      <p>{{ userData.nickname }}</p>
                    </div>

                    <div class="item-container">
                      <p>{{ 'Join Date' | translate }}</p>
                      <p>{{ userData.updated_at || userData.registration_date }}</p>
                    </div>
                  </div>

                  <div class="row-container">
                    <div class="item-container">
                      <p>{{ 'Email' | translate }}</p>
                      <p>{{ userData.email }}</p>
                    </div>
                  </div>

                  <div class="row-container">
                    <div class="item-container" *ngIf="!adminUser">
                      <p>{{ 'Email Verifiction' | translate }}</p>
                      <p>{{ userData.email_verified }}</p>
                    </div>
                    <div class="item-container" *ngIf="!adminUser">
                      <p>{{ 'Points' | translate }}</p>
                      <p>{{ userData.totalPoints }}</p>
                    </div>
                  </div>
                  <div class="row-container">
                    <div class="item-container" *ngIf="adminUser">
                      <p>{{ 'User Role' | translate }}</p>
                      <mat-select required id="selectedRole" required [(ngModel)]="newRole" name="newRole" #selectedRoleInput="ngModel">
                        <mat-option value="" disabled>Select User Role</mat-option>
                        <mat-option value="admin">Admin</mat-option>
                        <mat-option value="orderManager">Order Manager</mat-option>
                        <mat-option value="productManager">Product Manager</mat-option>
                      </mat-select>
                    </div>
                    <div class="item-container" *ngIf="adminUser">
                      <p>{{ 'Phone Number' | translate }}</p>
                      <p>{{ userData.phoneNumber || userData.phone_number }}</p>
                    </div>
                  </div>

                  <div class="row-container" *ngIf="adminUser">
                    <button class="blue stacksBtn" (click)="updateNewRole()">Update</button>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <!--last order details -->
      <div class="itemsAndInfo">
        <div class="customerInfo" *ngIf="ELEMENT_DATA.length">
          <h1>{{ 'Last Order Details' | translate }}</h1>
          <div class="customerWrapper">
            <section>
              <div>
                <div class="customerDetails">
                  <div class="row-container">
                    <div class="item-container">
                      <p>{{ 'Order ID' | translate }}</p>
                      <p>#{{ ELEMENT_DATA[ELEMENT_DATA.length - 1].humId }}</p>
                    </div>
                    <div class="item-container">
                      <p>{{ 'total Order' | translate }}</p>
                      <p>{{ ELEMENT_DATA[ELEMENT_DATA.length - 1].total }}</p>
                    </div>
                    <div class="item-container">
                      <p>{{ 'payment Method' | translate }}</p>
                      <p>{{ ELEMENT_DATA[ELEMENT_DATA.length - 1].payment_method }}</p>
                    </div>
                  </div>

                  <div class="row-container">
                    <div class="item-container">
                      <p>{{ 'Order Status' | translate }}</p>
                      <p>{{ ELEMENT_DATA[ELEMENT_DATA.length - 1].status }}</p>
                    </div>
                    <div class="item-container">
                      <p>{{ 'Order Date' | translate }}</p>
                      <p>{{ ELEMENT_DATA[ELEMENT_DATA.length - 1].date }}</p>
                    </div>
                  </div>
                  <div class="row-container">
                    <div class="item-container">
                      <p>{{ 'Total Orders' | translate }}</p>
                      <p>{{ totalOrdersPrice }}</p>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>

    <!-- ////// orders table /////////-->

    <div class="tableWrapper" *ngIf="orders && !adminUser">
      <div class="tableHeader">
        <h1>{{ 'Orders List' | translate }}</h1>
        <div class="headerBtns">
          <form class="searchBar">
            <input (input)="applyFilter()" [(ngModel)]="searchText" data-search matInput name="searchText" placeholder="{{ 'Search' | translate }}" type="text" />
          </form>
        </div>
      </div>
      <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef class="smallHeader firstTitle">{{ 'Order ID' | translate }}</th>
          <td mat-cell *matCellDef="let elements">{{ elements.humId }}</td>
        </ng-container>
        <ng-container matColumnDef="date">
          <th mat-header-cell *matHeaderCellDef class="smallHeader firstTitle">{{ 'Order Date' | translate }}</th>
          <td mat-cell *matCellDef="let elements">{{ elements.date }}</td>
        </ng-container>
        <ng-container matColumnDef="payment_method">
          <th mat-header-cell *matHeaderCellDef class="firstTitle">{{ 'Payment Method' | translate }}</th>
          <td mat-cell *matCellDef="let elements">{{ elements.payment_method }}</td>
        </ng-container>
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef class="firstTitle">{{ 'Statues' | translate }}</th>
          <td mat-cell *matCellDef="let elements">{{ elements.status }}</td>
        </ng-container>
        <ng-container matColumnDef="total">
          <th mat-header-cell *matHeaderCellDef class="smallHeader firstTitle">{{ 'Total' | translate }}</th>
          <td mat-cell *matCellDef="let elements">{{ elements.total }}</td>
        </ng-container>

        <ng-container matColumnDef="action">
          <th mat-header-cell *matHeaderCellDef class="smallHeader firstTitle lastTitle">{{ 'Action' | translate }}</th>
          <td mat-cell *matCellDef="let elements">
            <button mat-icon-button (click)="editOrder(elements.id)" class="viewOrder">
              <span class="mat-button-wrapper">
                <span class="material-icons-outlined"> visibility </span>
              </span>
            </button>
          </td>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="ordersColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: ordersColumns"></tr>
      </table>

      <mat-paginator class="tablePaginator" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons aria-label="Select page of periodic dataSource"> </mat-paginator>
      <div *ngIf="orders.length === 0">
        <p class="noOrders">{{ 'no Orders yet' | translate }}</p>
      </div>
    </div>
    <div *ngIf="!orders">
      <p class="noOrders">{{ 'No Orders Yet' | translate }}</p>
    </div>
  </div>
</div>
