import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core'
import { MixpanelService } from '../tracking/mixpanel.service'
import { LanguageService } from '../language.service'

@Component({
  selector: 'app-stepper',
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StepperComponent implements OnInit {
  englishSteps = [
    {
      id: '1',
      class: 'notDone',
      description: ''
    },
    {
      id: '2',
      class: 'notDone',
      description: ''
    },
    {
      id: '3',
      class: 'notDone',
      description: ''
    },
    {
      id: '4',
      class: 'notDone',
      description: "<p>We added sample products for you to test, but now it's time that you try adding your own product</p>"
    },
    {
      id: '5',
      class: 'notDone',
      description: `<div class="productDescWrapper"> \
    <p>Let's add your first product from your store</p>\
    <ul><li>Add your product name</li>\
    <li>Add a Stock number</li>\
    <li>Add the Regular Price</li>\
    <li>Click <strong>Publish</strong> to continue</li></ul>\
    </div>`
    },
    {
      id: '6',
      class: 'notDone',
      description: `\
    <div class="descriptionRowWrapper">\
      <div>
      <p>In this step you will be able to fully customize the home view of the application, You can try with something simple as the following</p>\
      <ul><li>Add an image from the left menu</li>\
      <li>Move your block with the arrow at the top</li>\
      <li>Click Update</li></ul></div>\
      <img src="assets/images/tour-blockadd2.gif" class="desktop tourImg" alt="step7" />\
      <img src="assets/images/tour-blockadd.gif" class="mobile" alt="step7" />\
    </div>`
    }
  ]

  arabicSteps = [
    {
      id: '1',
      class: 'notDone',
      description: ''
    },
    {
      id: '2',
      class: 'notDone',
      description: ''
    },
    {
      id: '3',
      class: 'notDone',
      description: ''
    },
    {
      id: '4',
      class: 'notDone',
      description: '<p>لقد أضفنا منتجات عينية لك للاختبار، ولكن الآن حان الوقت لتجربة إضافة منتجك الخاص</p>'
    },
    {
      id: '5',
      class: 'notDone',
      description: `<div class="productDescWrapper"> \
    <p>لنقم بإضافة أول منتج لك من متجرك</p>\
    <ul><li>أضف اسم المنتج الخاص بك</li>\
    <li>أضف رقم المخزون</li>\
    <li>أضف السعر العادي</li>\
    <li>انقر <strong>نشر</strong> للمتابعة</li></ul>\
    </div>`
    },
    {
      id: '6',
      class: 'notDone',
      description: `\
    <div class="descriptionRowWrapper">\
      <div>
      <p>في هذه الخطوة يمكنك تخصيص عرض الصفحة الرئيسية للتطبيق بالكامل</p>\
      <ul><li class="arabicList">يمكنك المحاولة بشيء بسيط مثل ما يلي</li>\
      <li class="arabicList">تحريك الكتلة الخاصة بك باستخدام السهم في الأعلى</li>\
      <li class="arabicList">انقر تحديث</li></ul></div>\
      <img src="assets/images/tour-blockadd2.gif" class="desktop tourImg" alt="step7" />\
      <img src="assets/images/tour-blockadd.gif" class="mobile" alt="step7" />\
    </div>`
    }
  ]

  constructor(
    public mixPanel: MixpanelService,
    public languageService: LanguageService
  ) {}
  @Input() step: any = '1'
  ngOnInit(): void {
    this.step = Number(this.step)
    this.mixPanel.track('onboarding_step ', {
      step: this.step
    })
    for (let index = 0; index < this.englishSteps.length; index++) {
      const element = this.englishSteps[index]
      if (index < this.step) {
        element.class = 'done'
      } else {
        element.class = 'notDone'
      }
    }
    for (let index = 0; index < this.arabicSteps.length; index++) {
      const element = this.arabicSteps[index]
      if (index < this.step) {
        element.class = 'done'
      } else {
        element.class = 'notDone'
      }
    }
  }
}
