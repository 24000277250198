<mat-toolbar color="primary">
  <span>Unsubscribers</span>
</mat-toolbar>
<mat-form-field appearance="outline">
  <mat-label>Filter</mat-label>
  <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium" #input />
</mat-form-field>
<button mat-raised-button class="fetchData" (click)="fetchUnSubscribers()">Fetch New Data</button>
<table mat-table matSort [dataSource]="unSubDataSource" class="mat-elevation-z8">
  <ng-container matColumnDef="date">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>date.</th>
    <td mat-cell *matCellDef="let element">{{ element.date }}</td>
  </ng-container>

  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef>Subscription ID</th>
    <td mat-cell *matCellDef="let element">{{ element.id }}</td>
  </ng-container>

  <ng-container matColumnDef="mail">
    <th mat-header-cell *matHeaderCellDef>Mail</th>
    <td mat-cell *matCellDef="let element">{{ element.mail }}</td>
  </ng-container>

  <ng-container matColumnDef="name">
    <th mat-header-cell *matHeaderCellDef>Name</th>
    <td mat-cell *matCellDef="let element">{{ element.name }}</td>
  </ng-container>

  <ng-container matColumnDef="value">
    <th mat-header-cell *matHeaderCellDef>Value</th>
    <td mat-cell *matCellDef="let element">{{ element.value }}</td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="unSubscribersColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: unSubscribersColumns"></tr>
</table>
<mat-paginator [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons aria-label="Select page of periodic unSubDataSource"> </mat-paginator>
<div class="widgetChart">
  <canvas baseChart [data]="unSubChartData" [options]="unSubChartOptions" type="bar"></canvas>
</div>
