import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AppRoutingModule } from './app-routing.module'
import { EditorModule } from '@tinymce/tinymce-angular'
import { SafeHtmlPipe } from './safe-html.pipe'
import { HttpClientModule, HttpClient } from '@angular/common/http'

// Components
import { AppComponent } from './app.component'
import { BlocksWrapperComponent } from './blocks-wrapper/blocks-wrapper.component'
import { HeaderComponent } from './header/header.component'
import { MobilePreviewComponent } from './mobile-preview/mobile-preview.component'
import { BlockSettingsComponent } from './block-settings/block-settings.component'
import { SidebarComponent } from './sidebar/sidebar.component'
import { BlockImageComponent } from './blocks-wrapper/block-image/block-image.component'
import { BlockTextComponent } from './blocks-wrapper/block-text/block-text.component'
import { GeneralSettingsComponent } from './settings/general-settings/general-settings.component'
import { ApplicationSettingsComponent } from './settings/application-settings/application-settings.component'
import { ContentSettingsComponent } from './settings/content-settings/content-settings.component'
import { AppleSettingsComponent } from './settings/apple-settings/apple-settings.component'
import { ViewBuilderComponent } from './view-builder/view-builder.component'

// Forms
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
// Material Modules
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatFormFieldModule } from '@angular/material/form-field'
import { MatInputModule } from '@angular/material/input'
import { NgxMatFileInputModule } from '@angular-material-components/file-input'
import { MatIconModule } from '@angular/material/icon'
import { MatMenuModule } from '@angular/material/menu'
import { MatButtonModule } from '@angular/material/button'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { DragDropModule } from '@angular/cdk/drag-drop'
import { MatSnackBarModule } from '@angular/material/snack-bar'
import { TemplatesComponent } from './templates/templates.component'
import { MatDialogModule } from '@angular/material/dialog'
import { TemplateViewComponent } from './templates/template-view/template-view.component'
import { MatSelectModule } from '@angular/material/select'
import { BlockSectionComponent } from './blocks-wrapper/block-section/block-section.component'
import { MatButtonToggleModule } from '@angular/material/button-toggle'
import { MatDividerModule } from '@angular/material/divider'
import { MatListModule } from '@angular/material/list'
import { MatSlideToggleModule } from '@angular/material/slide-toggle'
import { LoginComponent } from './login/login.component'
import { Authguard } from './guards/authguard.service'
import { Tourguard } from './guards/tourguard.service'
import { AdminauthGuard } from './guards/adminauth.guard'
import { ConnectivityComponent } from './connectivity/connectivity.component'
import { AndroidBuildComponent } from './build/android-build/android-build.component'
import { PushNotificationsComponent } from './addons/push-notifications/push-notifications.component'
import { MatSliderModule } from '@angular/material/slider'
import { MatCardModule } from '@angular/material/card'
import { BlockProductsComponent } from './blocks-wrapper/block-products/block-products.component'
import { BlockCategoriesComponent } from './blocks-wrapper/block-categories/block-categories.component'
import { BlockPostsComponent } from './blocks-wrapper/block-posts/block-posts.component'
import { BuildStatusComponent } from './build/build-status/build-status.component'
import { IosBuildComponent } from './build/ios-build/ios-build.component'
import { BlockSliderComponent } from './blocks-wrapper/block-slider/block-slider.component'
import { BlockVideoComponent } from './blocks-wrapper/block-video/block-video.component'
import { BlockHeaderComponent } from './blocks-wrapper/block-header/block-header.component'
import { IvyCarouselModule } from 'angular-responsive-carousel2'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatTableModule } from '@angular/material/table'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatRadioModule } from '@angular/material/radio'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatToolbarModule } from '@angular/material/toolbar'
import { NgChartsModule } from 'ng2-charts'
/**
 * Analytics Libraries
 */
import { NgxGoogleAnalyticsModule } from 'ngx-google-analytics'
import { environment } from 'src/environments/environment'
import { ChangelogComponent } from './pages/changelog/changelog.component'
import { BlockButtonComponent } from './blocks-wrapper/block-button/block-button.component'
import { GlobalSettingsComponent } from './settings/global-settings/global-settings.component'
import { ViewsComponent } from './views/views.component'
import { SearchProjectsComponent } from './admin/search-projects/search-projects.component'
import { BlockStaticComponent } from './blocks-wrapper/block-static/block-static.component'
import { AdminComponent } from './admin/admin/admin.component'
import { ProjectsComponent } from './pages/projects/projects.component'
import { WorksDialogComponent } from './pages/works-dialog/works-dialog.component'
import { AppPreviewComponent } from './pages/app-preview/app-preview.component'
import { BlockFooterComponent } from './blocks-wrapper/block-footer/block-footer.component'
import { IconsSelectorComponent } from './pages/icons-selector/icons-selector.component'
import { SelfComponent } from './admin/admin-analytics/self/self.component'
import { CloudComponent } from './admin/admin-analytics/cloud/cloud.component'
import { CloudSubsComponent } from './admin/admin-analytics/cloud/cloud-subs/cloud-subs.component'
import { CloudUnsubsComponent } from './admin/admin-analytics/cloud/cloud-unsubs/cloud-unsubs.component'
import { CloudAllSubsComponent } from './admin/admin-analytics/cloud/cloud-all-subs/cloud-all-subs.component'
import { CloudChurnComponent } from './admin/admin-analytics/cloud/cloud-churn/cloud-churn.component'
import { SelfTrialsComponent } from './admin/admin-analytics/self/self-trials/self-trials.component'
import { SelfSalesComponent } from './admin/admin-analytics/self/self-sales/self-sales.component'
import { SelfTrialsConversionComponent } from './admin/admin-analytics/self/self-trials-conversion/self-trials-conversion.component'
import { LogsComponent } from './admin/admin-analytics/logs/logs.component'
import { MatSortModule } from '@angular/material/sort'
import { MatNativeDateModule } from '@angular/material/core'
import { MomentDateModule } from '@angular/material-moment-adapter'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { AnalyticsPageStatsComponent } from './pages/analytics/analytics-page-stats/analytics-page-stats.component'
import { ExpiredComponent } from './pages/expired/expired.component'
import { SocialLoginModule, SocialAuthServiceConfig, GoogleLoginProvider, GoogleSigninButtonModule, GoogleInitOptions } from '@abacritt/angularx-social-login'
import { AnalyticsOrdersComponent } from './pages/analytics/analytics-orders/analytics-orders.component'
import { SafePipe } from './blocks-wrapper/block-video/safe.pipe'
import { AppCounterComponent } from './app-counter/app-counter.component'
import { SignupComponent } from './signup/signup.component'
import { PixelModule } from 'ngx-multi-pixel'
import { SiteTypeComponent } from './pages/site-type/site-type.component'
import { LtdComponent } from './pages/ltd/ltd.component'
import { VersionsComponent } from './pages/versions/versions.component'
import { AppsUploadedComponent } from './admin/admin-analytics/apps-uploaded/apps-uploaded.component'
import { AllAppsComponent } from './admin/admin-analytics/all-apps/all-apps.component'
import { provideNativeDateAdapter } from '@angular/material/core'

import '@stripe/stripe-js'
import { PaymentSuccessComponent } from './pages/payment-success/payment-success.component'
import { BillingComponent } from './pages/billing/billing.component'
import { GoogleTranslatePipe } from './pipes/google-translate/google-translate.pipe'
import { ExtractGoogleTranslationPipe } from './pipes/google-translate/extract-google-translation.pipe'
import { HelpComponent } from './pages/help/help.component'
import { GuidedTourComponent } from './guided-tour/guided-tour.component'
import { NewProductComponent } from './e-commerce/products/new-product/new-product.component'
import { ProductsComponent } from './e-commerce/products/list/products.component'
import { CategoriesComponent } from './e-commerce/categories/list/categories.component'
import { StacksProductsComponent } from './blocks-wrapper/stacks-products/stacks-products.component'
import { StacksCategoriesComponent } from './blocks-wrapper/stacks-categories/stacks-categories.component'
import { SettingsComponent } from './e-commerce/settings/settings.component'
import { ShippingComponent } from './e-commerce/shipping/shipping.component'
import { PostsListComponent } from './posts/posts-list/posts-list.component'
import { NewPostComponent } from './posts/new-post/new-post.component'
import { PostsCategoriesComponent } from './posts/posts-categories/posts-categories.component'
import { OrdersComponent } from './e-commerce/orders/orders.component'
import { SingleOrderComponent } from './e-commerce/orders/single-order/single-order.component'
import { StacksImporterComponent } from './e-commerce/stacks-importer/stacks-importer.component'
import { SubscribersListComponent } from './subscribers/subscribers-list/subscribers-list.component'
import { NewSubscriberComponent } from './subscribers/new-subscriber/new-subscriber.component'
import { MobileViewComponent } from './pages/mobile-view/mobile-view.component'
import { MatStepperModule } from '@angular/material/stepper'
import { StepperComponent } from './stepper/stepper.component'
import { StarRatingComponent } from './star-rating/star-rating.component'
import { TemplatesNewComponent } from './templates/templates-new/templates-new.component'
import { MatTabsModule } from '@angular/material/tabs'
import { TranslateModule, TranslateLoader } from '@ngx-translate/core'
import { TranslateHttpLoader } from '@ngx-translate/http-loader'
import { TxtTranslationsComponent } from './translations/txt-translations/txt-translations.component'
import { PointsComponent } from './plugins/points/components/points/points.component'
import { SocialLoginComponent } from './addons/social-login/social-login.component'
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard/admin-dashboard.component'
import { AddonsComponent } from './addons/addons/addons.component'
import { PointsModule } from 'plugins/points/points.module'
import { CustomizationComponent } from 'plugins/points/components/customization/customization.component'
import { UserComponent } from 'plugins/points/components/user/user.component'
import { CouponsComponent } from './e-commerce/coupons/coupons/coupons.component'
import { AddCouponComponent } from './e-commerce/coupons/add-coupon/add-coupon.component'
import { CouponsService } from './coupons.service'
import { MultilingualPipe } from './translations/multilingual.pipe'
import { FirebaseSettingsComponent } from './settings/firebase-settings/firebase-settings.component'

import { ToastrModule } from 'ngx-toastr'
import { AndroidApkComponent } from './build/android-apk/android-apk.component'
import { AppUsersComponent } from './app-users/app-users.component'
import { UserDetailsComponent } from './app-users/user-details/user-details.component'
import { PointsListComponent } from 'plugins/points/components/points-list/points-list.component'

import { AllSubscribersComponent } from './subscription-flow/all-subscribers/all-subscribers.component'
import { SubscriberInfoComponent } from './subscription-flow/subscriber-info/subscriber-info.component'
import { ToastrComponent } from './pages/toastr/toastr.component'
import { PricingTablesComponent } from './pages/billing/pricing-tables/pricing-tables.component'
import { DiscontComponent } from './e-commerce/discount/discont/discont.component'
import { DiscountListComponent } from './e-commerce/discount/discount-list/discount-list.component'
import { MatChipsModule } from '@angular/material/chips'
import { DynamicSamplesComponent } from './admin/dynamic-samples/dynamic-samples.component'
import { CreateUserComponent } from './app-users/create-user/create-user.component'
import { PnAutomationComponent } from './addons/push-notifications/pn-automation/pn-automation.component'
import { PaymentMethodsComponent } from './e-commerce/payment-methods/payment-methods.component'

const googleLoginOptions: GoogleInitOptions = {
  oneTapEnabled: false // default is true
}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http)
}

@NgModule({
  declarations: [
    AppComponent,
    BlocksWrapperComponent,
    HeaderComponent,
    MobilePreviewComponent,
    BlockSettingsComponent,
    SidebarComponent,
    BlockImageComponent,
    BlockTextComponent,
    SafeHtmlPipe,
    GeneralSettingsComponent,
    ApplicationSettingsComponent,
    ContentSettingsComponent,
    AppleSettingsComponent,
    ViewBuilderComponent,
    TemplatesComponent,
    TemplateViewComponent,
    BlockSectionComponent,
    LoginComponent,
    ConnectivityComponent,
    AndroidBuildComponent,
    PushNotificationsComponent,
    BlockProductsComponent,
    BlockCategoriesComponent,
    BlockPostsComponent,
    BuildStatusComponent,
    IosBuildComponent,
    BlockSliderComponent,
    BlockVideoComponent,
    BlockHeaderComponent,
    ChangelogComponent,
    BlockButtonComponent,
    GlobalSettingsComponent,
    ViewsComponent,
    SearchProjectsComponent,
    BlockStaticComponent,
    AdminComponent,
    ProjectsComponent,
    BlockFooterComponent,
    WorksDialogComponent,
    AppPreviewComponent,
    IconsSelectorComponent,
    SelfComponent,
    CloudComponent,
    PaymentMethodsComponent,
    CloudSubsComponent,
    CloudUnsubsComponent,
    CloudAllSubsComponent,
    CloudChurnComponent,
    SelfTrialsComponent,
    SelfSalesComponent,
    SelfTrialsConversionComponent,
    LogsComponent,
    AnalyticsPageStatsComponent,
    DynamicSamplesComponent,
    ExpiredComponent,
    AnalyticsOrdersComponent,
    SafePipe,
    AppCounterComponent,
    SignupComponent,
    SiteTypeComponent,
    LtdComponent,
    CreateUserComponent,
    VersionsComponent,
    AppsUploadedComponent,
    AllAppsComponent,
    CustomizationComponent,
    PaymentSuccessComponent,
    BillingComponent,
    GoogleTranslatePipe,
    ExtractGoogleTranslationPipe,
    HelpComponent,
    GuidedTourComponent,
    ProductsComponent,
    NewProductComponent,
    CategoriesComponent,
    StacksProductsComponent,
    StacksCategoriesComponent,
    SettingsComponent,
    ShippingComponent,
    PostsListComponent,
    NewPostComponent,
    PostsCategoriesComponent,
    OrdersComponent,
    SingleOrderComponent,
    UserComponent,
    StacksImporterComponent,
    SubscribersListComponent,
    NewSubscriberComponent,
    MobileViewComponent,
    StepperComponent,
    StarRatingComponent,
    TemplatesNewComponent,
    TxtTranslationsComponent,
    PointsComponent,
    SocialLoginComponent,
    AdminDashboardComponent,
    AddonsComponent,
    CouponsComponent,
    AddCouponComponent,
    AndroidApkComponent,
    FirebaseSettingsComponent,
    PointsListComponent,
    AppUsersComponent,
    UserDetailsComponent,
    AllSubscribersComponent,
    SubscriberInfoComponent,
    ToastrComponent,
    PricingTablesComponent,
    DiscontComponent,
    DiscountListComponent,
    PnAutomationComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    MatButtonModule,
    MatCheckboxModule,
    DragDropModule,
    EditorModule,
    MatIconModule,
    MatChipsModule,
    MatButtonModule,
    MatMenuModule,
    MatFormFieldModule,
    MatInputModule,
    NgxMatFileInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatProgressBarModule,
    MatSnackBarModule,
    HttpClientModule,
    MatDialogModule,
    MatStepperModule,
    MatSelectModule,
    MatButtonToggleModule,
    MatDividerModule,
    MatListModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatCardModule,
    IvyCarouselModule,
    MatExpansionModule,
    NgxGoogleAnalyticsModule.forRoot(environment.ga),
    MatTableModule,
    MatPaginatorModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    NgChartsModule,
    MatToolbarModule,
    MatSortModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MomentDateModule,
    SocialLoginModule,
    MatTabsModule,
    GoogleSigninButtonModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    PixelModule.forRoot({ enabled: true, pixelId: [environment.fb] }),
    PointsModule,
    ToastrModule.forRoot()
  ],
  providers: [
    Authguard,
    Tourguard,
    CouponsService,
    MultilingualPipe,
    AdminauthGuard,
    [provideNativeDateAdapter()],
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(environment.googleLogin, googleLoginOptions)
          }
        ],
        onError: (err) => {
          console.error(err)
        }
      } as SocialAuthServiceConfig
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {}
