<div class="secondarySidebar">
  <div class="secondarySBTitle">
    <h2>Admin Analytics</h2>
  </div>
  <div class="secondarySBContent">
    <ul>
      <li [routerLink]="['/admin/self']">Self</li>
      <li [routerLink]="['/admin/cloud']">Cloud</li>
      <li [routerLink]="['/admin/logs']" class="selected">Logs</li>
      <li [routerLink]="['/admin/apps-uploaded']">Apps Uploaded</li>
    </ul>
  </div>
</div>
<div class="containerWrapper withSidebar noBG">
  <!-- <mat-progress-bar mode="indeterminate" *ngIf="PNProgress"></mat-progress-bar> -->
  <div id="titleContainer" class="innerContainerTitle">
    <h1>Stacks Logs</h1>
  </div>
  <div id="selfContainer" class="innerContainerContent">
    <div class="gridWrapper">
      <div class="fullGrid">
        <mat-form-field appearance="outline">
          <mat-label>Filter</mat-label>
          <input matInput (keyup)="applySubFilter($event)" placeholder="Ex. ium" #input />
        </mat-form-field>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <!-- ID Column -->
          <!-- <ng-container matColumnDef="id">
                    <th mat-header-cell *matHeaderCellDef > id </th>
                    <td mat-cell *matCellDef="let elements"> {{elements.id}} </td>
                    </ng-container> -->
          <!-- Project ID Column -->
          <ng-container matColumnDef="project_id">
            <th mat-header-cell *matHeaderCellDef>project id</th>
            <td mat-cell *matCellDef="let elements">{{ elements.project_id }}</td>
          </ng-container>
          <!-- Message Column -->
          <ng-container matColumnDef="message">
            <th mat-header-cell *matHeaderCellDef>message</th>
            <td mat-cell *matCellDef="let elements">{{ elements.message }}</td>
          </ng-container>
          <!-- User ID Column -->
          <ng-container matColumnDef="user_id">
            <th mat-header-cell *matHeaderCellDef>user id</th>
            <td mat-cell *matCellDef="let elements">{{ elements.user_id }}</td>
          </ng-container>
          <!-- User Name Column -->
          <ng-container matColumnDef="user_name">
            <th mat-header-cell *matHeaderCellDef>user name</th>
            <td mat-cell *matCellDef="let elements">{{ elements.user_name }}</td>
          </ng-container>
          <!-- Created At Column -->
          <ng-container matColumnDef="created_at">
            <th mat-header-cell *matHeaderCellDef>created at</th>
            <td mat-cell *matCellDef="let elements">{{ elements.created_at }}</td>
          </ng-container>
          <!-- Position Column -->
          <ng-container matColumnDef="updated_at">
            <th mat-header-cell *matHeaderCellDef>updated at</th>
            <td mat-cell *matCellDef="let elements">{{ elements.updated_at }}</td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="path">
            <th mat-header-cell *matHeaderCellDef>path</th>
            <td mat-cell *matCellDef="let elements">{{ elements.path }}</td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="event_name">
            <th mat-header-cell *matHeaderCellDef>event name</th>
            <td mat-cell *matCellDef="let elements">{{ elements.event_name }}</td>
          </ng-container>
          <!-- Weight Column -->
          <ng-container matColumnDef="event_action">
            <th mat-header-cell *matHeaderCellDef>event action</th>
            <td mat-cell *matCellDef="let elements">{{ elements.event_action }}</td>
          </ng-container>
          <!-- Weight Column -->
          <ng-container matColumnDef="cid">
            <th mat-header-cell *matHeaderCellDef>cid</th>
            <td mat-cell *matCellDef="let elements">{{ elements.cid }}</td>
          </ng-container>

          <!-- Weight Column -->
          <!-- <ng-container matColumnDef="ids">
                        <th mat-header-cell *matHeaderCellDef> ids </th>
                        <td mat-cell *matCellDef="let elements"> {{elements.ids}} </td>
                    </ng-container> -->

          <tr mat-header-row *matHeaderRowDef="subscribersColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: subscribersColumns"></tr>
        </table>
        <mat-paginator [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons aria-label="Select page of periodic dataSource"> </mat-paginator>
      </div>
    </div>
  </div>
</div>
