import { Component, OnInit, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { DatabaseService } from 'src/app/builder-services/database.service'
import { PushNotificationsService } from '../push-notifications.service'
import { MatTableDataSource } from '@angular/material/table'
import { formatDate } from '@angular/common'
import { Observable } from 'rxjs'
import { map } from 'rxjs/operators'

export interface TypeObject {
  type: string
  message: string // Ensure message is always present, but it can be an empty string initially.
}

export interface PeriodicElement {
  id?: number
  date: string
  name: string
  types: string[]
  selectedType?: string
  selectedMessage?: string
}

@Component({
  selector: 'app-pn-automation',
  templateUrl: './pn-automation.component.html',
  styleUrl: './pn-automation.component.scss'
})
export class PnAutomationComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator
  public chosenEvents: PeriodicElement[] = []
  ELEMENT_DATA: PeriodicElement[] = []
  myDate: string = ''
  project_id = this.builderService.getSelectedProject()
  // MatPaginator Inputs
  length = 1
  pageSize = 5
  foundItem: PeriodicElement[] = []
  searchKey: string = ''

  usersList: any = []
  isExcistData!: boolean

  displayedColumns: string[] = ['id', 'date', 'name', 'types', 'message', 'delete']
  dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA)

  constructor(private db: DatabaseService, public builderService: BuilderService, public pnService: PushNotificationsService) {
    this.dataSource.paginator = this.paginator
    this.length = this.chosenEvents.length
  }

  ngOnInit(): void {
    this.builderService.showUpdateProgress = true
    this.db.getDatabase('projects/' + this.project_id + '/automation').subscribe((response) => {
      console.log('automation response', response)
      if (response && response.length > 0) {
        this.chosenEvents = response
        this.updateTableData();
      } else {
        this.chosenEvents=this.pnService.defaultEvents;
        this.updateTableData();
      }
    })
    this.builderService.showUpdateProgress = false
  }

  // Update table data and paginator
  private updateTableData(): void {
    this.dataSource.data = [...this.chosenEvents];
    this.dataSource.paginator = this.paginator;
    this.length = this.chosenEvents.length;
    this.builderService.showUpdateProgress = false; // Hide progress after update
  }
  indexGetter(arr: any) {
    let i
    for (i = 0; i < arr.length; i++) {}
    return i
  }

  /**
   * Adds a new event to the automation list.
   * Before adding the event, it checks if push notification data exists in the database.
   * If the data exists, a new event is created with the current date, an empty name,
   * the available types from the Push Notifications service, and an empty message.
   * The new event is then added to the chosenEvents array and the MatTableDataSource is updated.
   * If the data does not exist, an alert is displayed asking the user to add push notification data.
   */
  addEvent(): void {
    this.checkPushNotificationData().subscribe((exists) => {
      if (!exists) {
        alert('Please Add Push Notification Data')
        return
      }
      let newEvent: PeriodicElement = {
        id: this.indexGetter(this.chosenEvents) + 1,
        date: formatDate(new Date(), 'dd/MM/yyyy', 'en'),
        name: '',
        types: this.pnService.typeOptions,
        selectedType: '',
        selectedMessage: ''
      }
      this.chosenEvents.push(newEvent)
      this.dataSource.data = [...this.chosenEvents]
    })
  }

  /**
   * Deletes an event from the automation list at the specified index.
   * @param index - The index of the event to be deleted.
   * @returns {void}
   */
  delete(index: number): void {
    this.chosenEvents.splice(index, 1)
    for (let i = 0; i < this.chosenEvents.length; i++) {
      this.chosenEvents[i].id = i + 1
    }
    this.dataSource.data = [...this.chosenEvents]
  }

  /**
   * Saves the current automation events to the database.
   * This function sets the `showUpdateProgress` flag to true to indicate that an update is in progress.
   * After that, it updates the 'projects/{project_id}/automation' path in the database with the `chosenEvents` array.
   * Once the update is complete, it sets the `showUpdateProgress` flag to false.
   *
   * @returns {void}
   */
  save(): void {
    this.builderService.showUpdateProgress = true
    this.db.setDatabase('projects/' + this.project_id + '/automation', this.chosenEvents).subscribe((response) => {
      this.builderService.showUpdateProgress = false
    })
  }

  /**
   * Handles the name change event for a specific event in the automation list.
   * When the name of an event is changed, this function updates the available types for that event
   * and sets the selected type to the first option in the types array.
   * @param element - The event for which the name change event occurred.
   * @returns {void}
   */
  onNameChange(element: PeriodicElement): void {
    element.types = this.pnService.typeOptions
    element.selectedType = element.types[0]
  }

  onTypeChange(element: PeriodicElement): void {
    const selectedType = element.selectedType
    // if (selectedType) {
    //   element.selectedType = selectedType;
    // }
  }

  /**
   * Handles the search functionality for events in the automation list.
   * This function filters the events based on the search key provided by the user.
   * If a matching event is found, it updates the MatTableDataSource with the found event.
   * If no matching event is found, it displays an alert message and resets the MatTableDataSource to the original list.
   *
   * @returns {void}
   */
  onSubmit() {
    this.dataSource.data = this.chosenEvents
    this.foundItem = []
    this.dataSource.data.forEach((item: PeriodicElement) => (item.name.toLowerCase() === this.searchKey .toLowerCase() ? this.foundItem.push(item) : ''))
    if (this.foundItem.length) {
      this.dataSource.data = this.foundItem
    } else {
      alert('Not found')
      this.dataSource.data = this.chosenEvents
    }
  }

  /**
   * Checks if push notification data exists in the database.
   * @returns An Observable that emits a boolean value indicating whether the data exists.
   *          - `true`: The data exists and is valid.
   *          - `false`: The data does not exist or is invalid.
   */
  checkPushNotificationData(): Observable<boolean> {
    return this.db.getDatabase('projects/' + this.project_id + '/settings/application_settings').pipe(
      // Map the response to a boolean value indicating whether the data exists
      map((response) => {
        // Check if the fields are present in the response
        if (response.android_sender_id && response.databaseURL && response.serviceAccBase64 && (response.jsonFileBase64 || response.plistFileBase64)) {
          // Set the isExcistData flag to true and return true
          this.isExcistData = true
          return true
        } else {
          // Set the isExcistData flag to false and return false
          this.isExcistData = false
          return false
        }
      })
    )
  }
}
