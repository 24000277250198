import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { MixpanelService } from 'src/app/tracking/mixpanel.service'
import { SiteTypeService } from './site-type.service'
import { LoginService } from 'src/app/login/login.service'
import { DatabaseService } from 'src/app/builder-services/database.service'
import { DiscordService } from 'src/app/builder-services/discord.service'
import { environment } from 'src/environments/environment'

@Component({
  selector: 'site-type',
  templateUrl: './site-type.component.html',
  styleUrls: ['./site-type.component.scss']
})
export class SiteTypeComponent implements OnInit {
  websiteActive = false
  wordpressActive = false
  wooActive = true
  siteType = 'woocommerce'
  websiteURL: any
  businessName = ''
  websiteLink = ''
  businessStatus = ''
  businessCategory = ''
  missingRequiredField = false
  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public loginService: LoginService,
    private siteTypeService: SiteTypeService,
    public builderService: BuilderService,
    public mixPanel: MixpanelService,
    private db: DatabaseService,
    private discord: DiscordService
  ) {}

  ngOnInit(): void {}

  skipPlatform() {
    this.mixPanel.track('Site Type Skipped', {})
    this.builderService.logData('Site Type Skipped', 'Site Type Skipped', 'Site Type Skipped').subscribe(
      (response) => {},
      (err) => {}
    )
    this.router.navigate(['/templates'], { relativeTo: this.route })
  }

  siteTypeSubmit = () => {
    if (!this.businessCategory) {
      this.missingRequiredField = true
      alert('Please type your business category, to help us customize your application')
    } else {
      this.missingRequiredField = false
      this.mixPanel.track('Business Details ', {
        businessName: this.businessName,
        // websiteLink: this.websiteLink,
        businessStatus: this.businessStatus,
        businessCategory: this.businessCategory
      })
      this.db
        .setDatabase('projects/' + this.builderService.selectedProject + '/onboarding/business_data', {
          businessName: this.businessName,
          // websiteLink: this.websiteLink,
          businessStatus: this.businessStatus,
          businessCategory: this.businessCategory
        })
        .subscribe((response) => {
          this.builderService.customerProfile.first_name = this.builderService.projects[0]?.first_name
          this.builderService.customerProfile.businessName = this.businessName
          this.builderService.customerProfile.onlineBusiness = this.businessStatus
          this.builderService.customerProfile.businessCategory = this.businessCategory
          if (environment.production) {
            this.discord.sendMessage(JSON.stringify(this.builderService.customerProfile), 'Feedback').subscribe(
              () => {
                console.log('Message sent to Discord successfully!')
                // Handle success
              },
              (error) => {
                console.error('Error sending message to Discord:', error)
                // Handle error
              }
            )
          }
          this.router.navigate(['/templates'], { relativeTo: this.route })
        })
    }
  }
}
