import { Component, ViewChild } from '@angular/core'
import { NgForm } from '@angular/forms'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTable, MatTableDataSource } from '@angular/material/table'
import { ProductsService } from '../../products/products.service'
import { CategoriesService } from '../../categories/categories.service'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { ActivatedRoute, Router } from '@angular/router'
import { SettingsService } from '../../settings/settings.service'
import { Observable } from 'rxjs'
import { DiscountService } from '../discount.service'

export interface productsTableColumns {
  id: string
  image: string
  name: string
  sku: string
  stock: string
  price: string
  date: string
  checked?: boolean
}

export interface categoriesTableColumns {
  id: string
  image: string
  name: string
  checked?: boolean
}

export interface Discount {
  id?: string
  name: string
  expirationDate: string
  amount: number
  discountCriteria: 'fixed_products_discount' | 'fixed_categories_discount' | 'whole_cart_discount'

  selectedProducts?: productsTableColumns[]
  selectedCategories?: categoriesTableColumns[]
  active: boolean
}

@Component({
  selector: 'app-discont',
  templateUrl: './discont.component.html',
  styleUrl: './discont.component.scss'
})
export class DiscontComponent {
  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatSort, { static: true }) matSort!: MatSort
  @ViewChild('productPaginator') productPaginator!: MatPaginator
  @ViewChild(MatTable) pTable!: MatTable<productsTableColumns>
  @ViewChild(MatTable) cTable!: MatTable<categoriesTableColumns>
  @ViewChild('f', { static: true }) discountForm!: NgForm

  products: any = {}
  categories: any = {}
  ELEMENT_DATAP: productsTableColumns[] = []
  ELEMENT_DATAC: categoriesTableColumns[] = []
  displayedColumns: string[] = ['selectImageName']
  public selectedCategoriesDataSource = new MatTableDataSource<categoriesTableColumns>() // Assuming Category is your model interface

  public selectedProductsDataSource: MatTableDataSource<productsTableColumns> = new MatTableDataSource()

  public selectedProductsColumns: string[] = ['select', 'image', 'name', 'stock']

  public productsDataSource: MatTableDataSource<productsTableColumns> = new MatTableDataSource()
  public categoriesDataSource: MatTableDataSource<categoriesTableColumns> = new MatTableDataSource()
  public productsColumns: string[] = []

  public dataSource: any = []
  public elements: any[] = []
  products_data_counts: any = []
  selectedCategoriesIds: any[] = []
  selectedProductscIds: any[] = []

  discountCirteria = ['fixed_products_discount', 'fixed_categories_discount']
  discountCriteriaMap: { [key: string]: string } = {
    fixed_products_discount: 'Fixed Products',
    fixed_categories_discount: 'Fixed Category'
  }

  invalidationMessage: string = ''
  selectedCriteria: string = ''
  selectedType: string = ''
  discountPercentage!: number
  discountAmount: number = 0
  discountName: string = ''
  dateTo: any = new Date()
  dateFrom: any = new Date()
  currency: any
  discountId: string = ''
  active: boolean = true
  searchText: string = ''
  editPage: boolean = false
  selectedCategories: any[] = []
  selectedProducts: any[] = []
  productsById: any[] = []
  selectedProdCats: any[] = []
  duplicateProduct: boolean = false
  duplicateCat: boolean = false

  selectedTimeTo: any = '12:00 AM'
  selectedTime: any = '12:00 PM'
  isPM: boolean = false // Toggle for AM/PM
  discountsArray: any[] = []
  isAvailable: boolean = false
  constructor(
    private productsService: ProductsService,
    private categoriesService: CategoriesService,
    public builderService: BuilderService,
    private router: Router,
    private route: ActivatedRoute,
    private settingsService: SettingsService,
    private discountService: DiscountService
  ) {}
  ngAfterViewInit() {
    this.productsDataSource.paginator = this.paginator
    this.productsDataSource.sort = this.matSort
  }

  ngOnInit() {
    this.getCurrency()
    this.route.paramMap.subscribe((params) => {
      this.discountId = params.get('id') || ''
      if (this.discountId) {
        this.loadDiscount(this.discountId)
        console.log(this.discountId)
      }
    })

    this.loadDiscounts()
    this.selectedCategoriesDataSource.data = this.selectedCategories

    this.getAllProducts() // Fetch products
    this.getCategories()
    // Fetch categories in a similar way
    this.categoriesService.getCategories().subscribe((categories: any) => {
      console.log('catt', categories) // Ensure image URLs are correct here
      if (categories) {
        this.categories = this.processCategories(categories)
        this.ELEMENT_DATAC = this.categories // Populate ELEMENT_DATAC
        this.dataSource = new MatTableDataSource<categoriesTableColumns>(this.ELEMENT_DATAC)
      }
      if (!Array.isArray(this.categories)) {
        this.categories = []
      }
    })
    setTimeout(() => {
      this.updateSelectedProductsTable()
      this.updateSelectedCategories()
    }, 500)
  }

  /**
   * Retrieves the currency settings from the server and updates the component's currency property.
   * @returns {void} No return value.
   */
  getCurrency() {
    this.settingsService.getCurrencySettings().subscribe((response) => {
      this.currency = response
      console.log(this.currency)
    })
  }

  /**
   * Updates the selected products table with the current list of selected products.
   * @returns {void} No return value.
   */
  updateSelectedProductsTable() {
    this.selectedProductsDataSource = new MatTableDataSource(this.selectedProducts)
  }

  /**
   * Updates the data source for the selected categories table with the current selected categories.
   * @returns {void} No return value.
   */
  updateSelectedCategories() {
    this.selectedCategoriesDataSource.data = this.selectedCategories
  }

  /**
   * Processes the categories data and returns an array of objects with the required structure for the categories table.
   * @param categories - An object containing the categories data.
   * @returns An array of objects with the following structure:
   * [
   *   { id: string, image: string, name: string, checked: boolean },
   *   ...
   * ]
   */
  processCategories(categories: any): categoriesTableColumns[] {
    const categoryArray: categoriesTableColumns[] = []
    for (const key in categories) {
      if (Object.prototype.hasOwnProperty.call(categories, key)) {
        const category = categories[key]
        // Log URL for debugging
        categoryArray.push({
          id: category.categoryId,
          image: category.categoryImage,
          name: category.categoryName,
          checked: false
        })
      }
    }
    return categoryArray
  }

  /**
   * Fetches all discounts from the database and transforms the data to include the id as a property.
   * @returns {void}
   */
  loadDiscounts() {
    this.discountService.getDiscounts().subscribe(
      (discount: any) => {
        // Transform the data to include id as a property
        this.discountsArray = Object.keys(discount).map((key) => ({
          id: key,
          ...discount[key] // Spread the properties from the coupon object
        }))

        // dateFrom,dateTo,selectedTime,selectedTimeTo
      },
      (error) => {
        console.error('Error fetching discount:', error)
      }
    )
  }

  /**
   * Converts a given date to a timestamp in milliseconds.
   * @param {any} date - The date to be converted.
   * @returns {number} - The timestamp in milliseconds.
   */
  changeToTimeStamp(date: any): number {
    const dateStamp = new Date(date).getTime()
    return dateStamp
  }

  /**
   * Checks if a discount can be published based on the selected products, categories, and date/time.
   * It ensures that there are no overlapping discounts for the same products or categories within the same date/time range.
   * @returns {boolean} - Returns true if the discount can be published, false otherwise.
   */
  isAvailableDiscount(): boolean {
    this.duplicateProduct = false;
    this.duplicateCat = false;
  
    let selectedDateFrom = this.dateFrom;
    let selectedTimeFrom = this.selectedTime;
    let selectedDateTo = this.dateTo;
    let selectedTimeTo = this.selectedTimeTo;
  
    if (!this.selectedProducts.length && !this.selectedCategoriesIds.length) {
      console.log("No products or categories selected");
      return false;
    }
  
    let hasOverlap = false;
    const editDiscountId = this.discountId; // Set to null for create, ID for edit
  
    console.log("Checking discountsArray:", this.discountsArray);
  
    for (let i = 0; i < this.discountsArray.length; i++) {
      // Skip the discount being edited
      if (editDiscountId && this.discountsArray[i].id === editDiscountId) {
        console.log(`Skipping discount ${editDiscountId} as it’s being edited`);
        continue;
      }
  
      if (this.selectedProducts.length > 0) {
        // Check for duplicate products
        if (this.discountsArray[i].selectedProducts) {
          for (let j = 0; j < this.discountsArray[i].selectedProducts.length; j++) {
            for (let p = 0; p < this.selectedProducts.length; p++) {
              if (this.selectedProducts[p].id === this.discountsArray[i].selectedProducts[j]) {
                this.duplicateProduct = true;
                console.log(`Duplicate product: ${this.selectedProducts[p].id}`);
              }
            }
          }
        }
  
        // Check if selected product's category matches an existing category discount
        if (this.discountsArray[i].selectedCategories) {
          for (let j = 0; j < this.discountsArray[i].selectedCategories.length; j++) {
            for (let p = 0; p < this.selectedProducts.length; p++) {
              const productCategory = this.productsById.find(prod => prod.Id === this.selectedProducts[p].id)?.category;
              if (productCategory && this.discountsArray[i].selectedCategories[j] === productCategory) {
                this.duplicateCat = true;
                console.log(`Product ${this.selectedProducts[p].id} belongs to existing category ${productCategory}`);
              }
            }
          }
        }
  
        if (this.duplicateProduct || this.duplicateCat) {
          let dbDateFrom = this.discountsArray[i].dateFrom;
          let dbTimeFrom = this.discountsArray[i].timeFrom;
          let dbDateTo = this.discountsArray[i].dateTo;
          let dbTimeTo = this.discountsArray[i].timeTo;
  
          if (this.checkOverlapingTime(selectedDateFrom, selectedTimeFrom, selectedDateTo, selectedTimeTo, dbDateFrom, dbTimeFrom, dbDateTo, dbTimeTo)) {
            hasOverlap = true;
            console.log(`Overlap with discount ${i}`);
          }
        }
      } else {
        // Check for duplicate categories
        if (this.discountsArray[i].selectedCategories) {
          for (let j = 0; j < this.discountsArray[i].selectedCategories.length; j++) {
            for (let p = 0; p < this.selectedCategoriesIds.length; p++) {
              if (this.selectedCategoriesIds[p] === this.discountsArray[i].selectedCategories[j]) {
                this.duplicateCat = true;
                console.log(`Duplicate category: ${this.selectedCategoriesIds[p]}`);
              }
            }
          }
        }
  
        // Check if existing product discounts belong to selected categories
        if (this.discountsArray[i].selectedProducts) {
          for (let j = 0; j < this.discountsArray[i].selectedProducts.length; j++) {
            const productCategory = this.productsById.find(prod => prod.Id === this.discountsArray[i].selectedProducts[j])?.category;
            if (productCategory && this.selectedCategoriesIds.includes(productCategory)) {
              this.duplicateCat = true;
              console.log(`Existing product ${this.discountsArray[i].selectedProducts[j]} in category ${productCategory} matches selected category`);
            }
          }
        }
  
        if (this.duplicateCat) {
          let dbDateFrom = this.discountsArray[i].dateFrom;
          let dbTimeFrom = this.discountsArray[i].timeFrom;
          let dbDateTo = this.discountsArray[i].dateTo;
          let dbTimeTo = this.discountsArray[i].timeTo;
  
          if (this.checkOverlapingTime(selectedDateFrom, selectedTimeFrom, selectedDateTo, selectedTimeTo, dbDateFrom, dbTimeFrom, dbDateTo, dbTimeTo)) {
            hasOverlap = true;
            console.log(`Overlap with discount ${i}`);
          }
        }
      }
    }
  
    console.log(`Final hasOverlap: ${hasOverlap}`);
    return !hasOverlap;
  }
  

  /**
   * Checks if the chosen discount period overlaps with any existing discount periods.
   * @param dateFrom - The start date of the chosen discount period.
   * @param timeFrom - The start time of the chosen discount period.
   * @param dateTo - The end date of the chosen discount period.
   * @param timeTo - The end time of the chosen discount period.
   * @param dbDateFrom - The start date of an existing discount period.
   * @param dbTimeFrom - The start time of an existing discount period.
   * @param dbDateTo - The end date of an existing discount period.
   * @param dbTimeTo - The end time of an existing discount period.
   * @returns {boolean} - Returns `true` if the chosen discount period overlaps with an existing discount period, `false` otherwise.
   */
  checkOverlapingTime(dateFrom: any, timeFrom: any, dateTo: any, timeTo: any, dbDateFrom: any, dbTimeFrom: any, dbDateTo: any, dbTimeTo: any) {
    //converting every date & time to timestamps to easily compare
    let chosenStart = this.changeToTimeStamp(dateFrom)
    let chosenEnd = this.changeToTimeStamp(dateTo)
    let dbStart = this.changeToTimeStamp(dbDateFrom + ' ' + dbTimeFrom)
    let dbEnd = this.changeToTimeStamp(dbDateTo + ' ' + dbTimeTo)
    if ((chosenStart >= dbStart && chosenEnd < dbEnd) || (chosenStart > dbStart && chosenEnd <= dbEnd)) {
      // the chosen period starts withing another discount
      console.log('the chosen period starts withing another discount')
      return true
    } else if (chosenStart < dbStart) {
      if (chosenEnd < dbStart) {
        // the chosen period starts and ends before any other discounts
        console.log('the chosen period starts and ends before any other discounts')
        return false
      } else if (chosenEnd >= dbStart && chosenEnd < dbEnd) {
        // the chosen period ends inside another discount
        console.log('the chosen period ends inside another discount')
        return true
      } else if (chosenEnd >= dbEnd) {
        // the chosen period has another discount inside it
        console.log('the chosen period has another discount inside it')
        return true
      }
    } else if (chosenStart > dbEnd) {
      // the chosen period starts after every discount
      console.log('the chosen period starts after every discount')
      return false
    }
    console.log('the chosen period didn go to any condition')
    return true
  }

  /**
   * Retrieves products associated with a specific category ID.
   * @param {any} catId - The ID of the category for which to retrieve products.
   * @returns {void} - The function does not return a value, but it sets the `productsById` property of the class instance.
   */
  loadProductsCats(catId: any) {
    //getting products by id
    this.productsById = this.productsService.getProductsByCatId(catId)
  }

  /**
   * Retrieves all products from the database and prepares them for display in a table.
   * @remarks
   * This function subscribes to the `getProducts` method of the `productsService` and performs the following steps:
   * 1. Sets the `showUpdateProgress` property of the `builderService` to `true`.
   * 2. Defines the column names for the products table.
   * 3. Assigns the response from the `getProducts` method to the `products` property.
   * 4. Initializes an empty array `ELEMENT_DATAP` to store the processed product data.
   * 5. Calls the `renderProducts` method with the `products` array as an argument.
   * 6. Subscribes to the `renderProducts` method's Observable to handle the completion of the rendering process.
   * 7. Assigns the rendered products data to the `productsDataSource` property of the class instance.
   * 8. Sets the sort and paginator for the `productsDataSource`.
   * 9. Sets the `showUpdateProgress` property of the `builderService` to `false`.
   */
  getAllProducts() {
    this.productsService.getProducts().subscribe((response_products: any) => {
      this.builderService.showUpdateProgress = true
      this.productsColumns = ['image', 'name', 'stock', 'price', 'date']
      this.products = response_products
      this.ELEMENT_DATAP = []

      this.renderProducts(this.products).subscribe(() => {
        this.productsDataSource = new MatTableDataSource(this.ELEMENT_DATAP)
        this.productsDataSource.sort = this.matSort
        this.productsDataSource.paginator = this.paginator // Assign paginator

        this.builderService.showUpdateProgress = false
      })
    })
  }

  /**
   * This function renders products data for display in a table.
   * @remarks
   * The function takes an array of product data as input and performs the following steps:
   * 1. Initializes an empty array `ELEMENT_DATAP` to store the processed product data.
   * 2. Iterates over the input array and processes each product:
   *    - Extracts relevant product properties.
   *    - Calculates the price display based on the presence of a sale price.
   *    - Formats the date display.
   *    - Adds the processed product data to the `ELEMENT_DATAP` array.
   * 3. Assigns the rendered products data to the `productsDataSource` property of the class instance.
   * 4. Sets the sort and paginator for the `productsDataSource`.
   * 5. Sets the `showUpdateProgress` property of the `builderService` to `false`.
   *
   * @param result - An array of product data to be rendered.
   * @returns An Observable that completes when the rendering process is complete.
   */
  renderProducts(result: any): Observable<any> {
    return new Observable<any>((observer) => {
      this.builderService.showUpdateProgress = false
      this.products_data_counts = []
      for (const key in result) {
        if (Object.prototype.hasOwnProperty.call(result, key)) {
          const element = result[key]
          this.products_data_counts.push(element.count)
          let date: any = element.date ? String(element.date) : ''
          let price: any = element.salePrice ? element.regularPrice + ' / ' + element.salePrice : element.regularPrice
          //getting the selected products categories ids to check for overlapping products
          if (element.categoriesIds) {
            for (let i of element.categoriesIds) {
              this.selectedProdCats.push(i)
            }
          }
          this.ELEMENT_DATAP.push({
            id: element.Id,
            image: element.imageUrl,
            name: element.productName,
            sku: element.productSKU,
            stock: element.productStock,
            price: price,
            date: date ? date.replaceAll('/', '-') : ''
          })
        }
      }

      this.dataSource = new MatTableDataSource<productsTableColumns>(this.ELEMENT_DATAP)
      setTimeout(() => {
        observer.next() // Notify the observer that the operation is complete
        observer.complete() // Complete the Observable
      }, 100)
    })
  }

  /**
   * Retrieves categories from the server and prepares them for display in a table.
   * @remarks
   * This function subscribes to the `getCategories` method of the `categoriesService` and performs the following steps:
   * 1. Assigns the response from the `getCategories` method to the `categories` property.
   * 2. Initializes an empty array `ELEMENT_DATAC` to store the processed category data.
   * 3. Calls the `processCategories` method with the `categories` array as an argument.
   * 4. Assigns the processed categories data to the `ELEMENT_DATAC` property of the class instance.
   * 5. Initializes the `categoriesDataSource` property with the processed categories data.
   * 6. Sets the paginator and sort for the `categoriesDataSource`.
   */
  getCategories() {
    this.categoriesService.getCategories().subscribe((categories: any) => {
      this.ELEMENT_DATAC = this.processCategories(categories) // Process categories
      this.categoriesDataSource = new MatTableDataSource(this.ELEMENT_DATAC)
      this.categoriesDataSource.paginator = this.paginator
      this.categoriesDataSource.sort = this.matSort
    })
  }

  /**
   * Checks if the selected discount criteria is 'Fixed Products Discount'.
   * @returns A boolean value indicating whether the selected discount criteria is 'Fixed Products Discount'.
   * - Returns `true` if the selected discount criteria is 'Fixed Products Discount'.
   * - Returns `false` if the selected discount criteria is not 'Fixed Products Discount'.
   */
  showProducts(): boolean {
    return this.selectedCriteria === 'fixed_products_discount'
  }

  /**
   * Checks if the selected discount criteria is 'Fixed Categories Discount'.
   * @returns A boolean value indicating whether the selected discount criteria is 'Fixed Categories Discount'.
   * - Returns `true` if the selected discount criteria is 'Fixed Categories Discount'.
   * - Returns `false` if the selected discount criteria is not 'Fixed Categories Discount'.
   */
  showCategories(): boolean {
    return this.selectedCriteria === 'fixed_categories_discount'
  }

  /**
   * This function formats a date string into the 'YYYY-MM-DD' format.
   * @param dateValue - The date string or object to be formatted.
   * @returns A formatted date string in the 'YYYY-MM-DD' format, or null if the input is not a valid date string.
   */
  dateFormatter(dateValue: any): string | null {
    // If dateValue is an object with a 'dateInput' property, extract the value
    const dateString = typeof dateValue === 'object' && dateValue?.dateInput ? dateValue.dateInput : dateValue

    if (!dateString) {
      return null // Return null if no valid input
    }

    const date = new Date(dateString)
    if (!isNaN(date.getTime())) {
      // Get the date parts manually to avoid time zone issues
      const year = date.getFullYear()
      const month = (date.getMonth() + 1).toString().padStart(2, '0') // Months are 0-indexed
      const day = date.getDate().toString().padStart(2, '0')
      console.log(day, month, year)

      return `${year}-${month}-${day}`
    }

    return null // Return null if the date is invalid
  }

  /**
   * This function saves the discount data to the database.
   * @remarks
   * The function constructs a discount object with the form values and selected product and category IDs.
   * If the `discountId` property is truthy, the function updates the existing discount with the new data.
   * Otherwise, it creates a new discount in the database.
   * @param formValues - An object containing the discount form values.
   * @returns {void}
   */
  save(formValues: any) {
    const discount = {
      discountName: formValues.discountName,
      discountPercentage: formValues.discountPercentage,
      selectedProducts: this.selectedProductscIds,
      selectedCategories: this.selectedCategoriesIds,
      dateFrom: this.dateFormatter(formValues.dateFrom),
      dateTo: this.dateFormatter(formValues.dateTo),
      timeFrom: formValues.timeFrom,
      timeTo: formValues.timeTo,
      discountCriteria: this.selectedCriteria
    }

    if (this.discountId) {
      console.log('discount data from edit', discount)
      this.discountService.updateDiscount(this.discountId, discount).subscribe(
        (response) => {
          console.log('discount Updated successfully:', response)
          this.discountId = '';
          setTimeout(() => {
            this.router.navigate(['/discount'])
          }, 1000)
        },
        (error) => {
          console.log('Error saving discount:', error)
        }
      )
    } else {
      this.discountService.submitDiscount(discount).subscribe(
        (response) => {
          console.log('discount saved successfully:', response)
          setTimeout(() => {
          this.router.navigate(['/discount'])
          })
        },
        (error) => {
          console.log('Error saving discount:', error)
        }
      )
    }
  }

  /**
   * this method i use in the (Publish) submit button so when i click on submit it checks if all the inputs are filled and if they are
   * it publish the coupon and if they arenot it doesnt publish the coupon and get the validation errors in the inputs
   * @param form which is any
   * @returns null
   */

  onSubmit(formValue: any) {
    if (this.discountForm.invalid) {
      this.discountForm.form.markAllAsTouched();
      return;
    }
    console.log("Checking availability before save"); // Debug
    if (this.isAvailableDiscount()) {
      console.log("Discount available, proceeding to save");
      this.save(formValue);
    } else {
      console.log("Discount not available, showing alert");
      alert('Some products or categories have been used in a same date or time');
    }
  }

  /**
   * Handles the selection or deselection of a product in the product list.
   * @param product - The selected product object.
   * @param event - The event object associated with the selection or deselection.
   * This function adds or removes the selected product from the `selectedProducts` array,
   * and updates the corresponding product IDs in the `selectedProductscIds` array.
   * It also updates the selected products table and logs the selected products.
   */
  onProductSelect(product: any, event: any): void {
    console.log(product)
    const productWithType = { ...product, type: 'product' } // Add type to the product
    if (event.checked) {
      if (!this.selectedProducts.some((p) => p.name === productWithType.name)) {
        this.selectedProducts.push(productWithType)
        if (this.selectedProductscIds.length > 0) {
          this.selectedProductscIds = [...this.selectedProductscIds, productWithType.id]
        } else {
          this.selectedProductscIds.push(productWithType.id)
        }
      }
    } else {
      const index = this.selectedProducts.findIndex((p) => p.name === productWithType.name && p.type === 'product')
      const indexIds = this.selectedProductscIds.findIndex((p) => p === productWithType.id && p.type === 'product')

      if (index > -1) {
        this.selectedProducts.splice(index, 1) // Remove the product from the array
        this.selectedProductscIds.splice(indexIds, 1)
      }
    }
    this.updateSelectedProductsTable()

    console.log('Selected products:', this.selectedProducts)
  }

  /**
   * Handles the selection or deselection of a category in the category list.
   * @param category - The selected category object.
   * @param event - The event object associated with the selection or deselection.
   * This function adds or removes the selected category from the `selectedCategories` array,
   * and updates the corresponding category IDs in the `selectedCategoriesIds` array.
   * It also updates the selected categories table and logs the selected categories.
   */
  onCategorySelect(category: any, event: any): void {
    const isChecked = event.checked
    const index = this.categories.findIndex((c: any) => c.name === category.name)
    if (index > -1) {
      this.categories[index].checked = isChecked
    }
    const categoryWithType = { ...category, type: 'category' }

    if (isChecked) {
      if (!this.selectedCategories.some((c) => c.name === categoryWithType.name && c.type === 'category')) {
        this.selectedCategories.push(categoryWithType)
        if (this.selectedCategoriesIds.length > 0) {
          this.selectedCategoriesIds = [...this.selectedCategoriesIds, categoryWithType.id]
        } else {
          this.selectedCategoriesIds.push(categoryWithType.id)
        }
      }
    } else {
      const catIndex = this.selectedCategories.findIndex((c) => c.name === categoryWithType.name && c.type === 'category')
      const indexIds = this.selectedCategoriesIds.findIndex((c) => c === categoryWithType.id && c.type === 'category')

      if (catIndex > -1) {
        this.selectedCategories.splice(catIndex, 1)
        this.selectedCategoriesIds.splice(indexIds, 1)
      }
    }
    this.updateSelectedCategories()

    this.loadProductsCats(categoryWithType.id)

    console.log('Selected categories:', this.selectedCategories)
  }

  /**
   * Checks if a given item (product or category) is selected.
   * @param item - The item to check (must have a 'name' property).
   * @param type - The type of the item ('product' or 'category').
   * @returns A boolean indicating whether the item is selected.
   * - Returns `true` if the item is selected.
   * - Returns `false` if the item is not selected.
   */
  isChecked(item: any, type: 'product' | 'category'): boolean {
    if (type === 'product') {
      return this.selectedProducts.some((p) => p.name === item.name && p.type === 'product')
    } else if (type === 'category') {
      return this.selectedCategories.some((c) => c.name === item.name && c.type === 'category')
    }

    return false
  }

  /**
   * Filters the displayed products based on the given search query.
   * @param query - The search query string.
   * If the query is not empty, the function filters the products in the `ELEMENT_DATAP` array based on the product name.
   * The filtered products are then assigned to the `productsDataSource.data` property.
   * If the query is empty, the function restores the original products data to the `productsDataSource.data` property.
   */
  filterProducts(query: string) {
    if (query) {
      const filteredProducts = this.ELEMENT_DATAP.filter((product) => product.name.toLowerCase().includes(query.toLowerCase()))
      this.productsDataSource.data = filteredProducts
    } else {
      this.productsDataSource.data = this.ELEMENT_DATAP
    }
  }

  /**
   * Filters the displayed categories based on the given search query.
   * @param queryC - The search query string.
   * If the query is not empty, the function filters the categories in the `ELEMENT_DATAC` array based on the category name.
   * The filtered categories are then assigned to the `categoriesDataSource.data` property.
   * If the query is empty, the function restores the original categories data to the `categoriesDataSource.data` property.
   */
  filterCategories(queryC: string) {
    if (queryC) {
      const filteredCategories = this.ELEMENT_DATAC.filter((category) => category.name.toLowerCase().includes(queryC.toLowerCase()))
      this.categoriesDataSource.data = filteredCategories
    } else {
      this.categoriesDataSource.data = this.ELEMENT_DATAC
    }
  }

  /**
   * Retrieves the stock quantity of a product.
   * @param product - The product object containing the stock quantity.
   * @returns The stock quantity as a number. If the stock quantity is not provided or is not a number, it returns 0.
   */
  getStock(product: any): number {
    return Number(product.stock) || 0
  }

  /**
   * Sets the selected time for the discount.
   * @param hour - The selected hour in 24-hour format.
   * @param minute - The selected minute.
   * @param menu - The menu object associated with the time selection.
   * This function converts the selected hour to 12-hour format and sets the selected time string.
   * It also logs the selected time to the console.
   */
  setTime(hour: number, minute: number, menu: any) {
    const period = this.isPM ? 'PM' : 'AM' // Set AM/PM
    const formattedHour = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour // Convert 24-hour format to 12-hour
    this.selectedTime = `${formattedHour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')} ${period}`
  }

  /**
   * Sets the selected time for the discount.
   * @param hour - The selected hour in 24-hour format.
   * @param minute - The selected minute.
   * @param menu - The menu object associated with the time selection.
   * This function converts the selected hour to 12-hour format and sets the selected time string.
   * It also logs the selected time to the console.
   */
  setTimeTo(hour: number, minute: number, menu: any) {
    const period = this.isPM ? 'PM' : 'AM' // Set AM/PM
    const formattedHour = hour === 0 ? 12 : hour > 12 ? hour - 12 : hour // Convert 24-hour format to 12-hour
    this.selectedTimeTo = `${formattedHour.toString().padStart(2, '0')}:${minute.toString().padStart(2, '0')} ${period}`
  }

  /**
   * Toggles the period between AM and PM.
   * This function is used to switch between 12-hour and 24-hour time formats.
   * When invoked, it toggles the `isPM` property, which determines whether the time is displayed in AM or PM format.
   */
  togglePeriod() {
    this.isPM = !this.isPM // Toggle between AM and PM
  }

  /**
   * Loads a discount from the database based on the provided ID.
   * @param id - The unique identifier of the discount to load.
   * This function sets the `editPage` flag to `true` to indicate that we are editing an existing discount.
   * It then retrieves the discount data from the database using the `discountService`.
   * Once the discount data is received, it assigns the relevant properties to the component's variables.
   *
   * If the discount has selected categories, it retrieves the categories data from the database using the `categoriesService`.
   * It filters the selected categories from the retrieved categories and maps them to the `selectedCategories` array.
   * It also extracts the category IDs from the selected categories and assigns them to the `selectedCategoriesIds` array.
   *
   * If the discount has selected products, it retrieves the products data from the database using the `productsService`.
   * It filters the selected products from the retrieved products and maps them to the `selectedProducts` array.
   * It also extracts the product IDs from the selected products and assigns them to the `selectedProductscIds` array.
   */
  loadDiscount(id: string) {
    this.editPage = true
    this.discountService.getDiscount(id).subscribe((discount) => {
      this.discountName = discount.discountName
      this.selectedCriteria = discount.discountCriteria
      this.discountPercentage = discount.discountPercentage
      this.dateFrom = discount.dateFrom
      this.dateTo = discount.dateTo
      this.selectedTime = discount.timeFrom
      this.selectedTimeTo = discount.timeTo

      if (discount.selectedCategories != null) {
        this.categoriesService.getCategories().subscribe((categories: any) => {
          this.selectedCategories = categories
            .filter((category: any) => discount.selectedCategories.includes(category.categoryId))
            .map((cat: any) => ({
              id: cat.categoryId,
              name: cat.categoryName,
              image: cat.categoryImage,
              type: 'category'
            }))
          this.selectedCategoriesIds = this.selectedCategories.map((cat) => cat.id)
        })
      } else if (discount.selectedProducts != null) {
        this.productsService.getProducts().subscribe((response_products: any) => {
          this.products = response_products
          this.selectedProducts = this.ELEMENT_DATAP.filter((product: any) => discount.selectedProducts.includes(product.id)).map((prod: any) => ({
            id: prod.id,
            name: prod.name,
            image: prod.image,
            sku: prod.sku,
            stock: prod.stock,
            price: prod.price,
            type: 'product'
          }))
          console.log('this.selectedProducts', this.selectedProducts)
          this.selectedProductscIds = this.selectedProducts.map((product) => product.id)
        })
      }
    })
  }
}
