import { AfterViewInit, Component, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatTableDataSource } from '@angular/material/table'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { BlockDataService } from 'src/app/block-services/block-data.service'
import { ActivatedRoute, Router } from '@angular/router'
import { LoginService } from 'src/app/login/login.service'
import { AppsUploadedService } from './apps-uploaded.service'

export interface PeriodicElement {
  project_id: string
  package_name: string
  link: string
  visitors: string
}

@Component({
  selector: 'apps-uploaded',
  templateUrl: './apps-uploaded.component.html',
  styleUrls: ['./apps-uploaded.component.scss']
})
export class AppsUploadedComponent implements OnInit, AfterViewInit {
  private user_email: string
  public projects_length = 0
  public starting_point: any = 0
  public step = 100
  public chunk = 100

  public active_apps: any = []
  displayedColumns: string[] = ['project_id', 'package_name', 'link', 'visitors']
  ELEMENT_DATA: PeriodicElement[] = []
  dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA)
  versionFormControl = new FormControl()
  constructor(
    public builderService: BuilderService,
    private blockDataService: BlockDataService,
    private router: Router,
    private route: ActivatedRoute,
    private loginService: LoginService,
    private appsUploadedService: AppsUploadedService
  ) {
    this.user_email = loginService.getCurrentUser().email
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    Promise.resolve().then(() => {
      this.starting_point = localStorage.getItem('active_apps_starting_point') ? localStorage.getItem('active_apps_starting_point') : 0
      this.starting_point = parseInt(this.starting_point)
      if (localStorage.getItem('active_apps')) {
        this.active_apps = localStorage.getItem('active_apps')
        // this.active_apps = JSON.parse("["+this.active_apps+"]");
      } else {
      }

      this.builderService.getProjects(this.user_email).subscribe((allProjects) => {
        this.projects_length = allProjects.length
        if (this.starting_point < this.projects_length - this.step) {
          this.getAppStatuses()
        } else if (this.starting_point > this.projects_length) {
          // Do Nothing
        } else if (this.starting_point + this.step > this.projects_length) {
          this.chunk = this.projects_length - this.starting_point
          this.getAppStatuses()
        }
      })
    })
  }

  getAppStatuses = () => {
    this.builderService.showUpdateProgress = true
    let newDataArray: PeriodicElement[] = []

    let active_apps_rendered: any = localStorage.getItem('active_apps')
    active_apps_rendered = JSON.parse('[' + active_apps_rendered + ']')
    if (active_apps_rendered !== null) {
      for (let index = 0; index < active_apps_rendered.length; index++) {
        const element = active_apps_rendered[index]
        if (element) {
          newDataArray.push({ project_id: element.id, package_name: element.package_name, link: element.android_url, visitors: element.number_of_visitors })
        }
      }
      const newData: PeriodicElement[] = newDataArray
      this.ELEMENT_DATA = newData
      this.dataSource.data = newData
    }

    this.appsUploadedService.getAppsUploaded(this.starting_point, this.chunk).subscribe((response: any) => {
      if (response.active.length) {
        for (let index = 0; index < response.active.length; index++) {
          const element = response.active[index]
          this.active_apps.push(JSON.stringify(element))
        }
        localStorage.setItem('active_apps', this.active_apps)
      }
      this.starting_point = this.starting_point + this.step
      if (this.starting_point < this.projects_length - this.step) {
        this.getAppStatuses()
      } else if (this.starting_point > this.projects_length) {
        // Do Nothing
      } else if (this.starting_point + this.step > this.projects_length) {
        this.chunk = this.projects_length - this.starting_point
        this.getAppStatuses()
      }
      localStorage.setItem('active_apps_starting_point', this.starting_point)
      this.builderService.showUpdateProgress = false
    })
  }
}
