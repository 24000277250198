<div class="containerWrapper">
  <h5>Searching for the next {{ this.chunk }} Projects starting from Project {{ this.starting_point }}</h5>
  <h5>Total Number of projects = {{ projects_length }}</h5>
  <table mat-table [dataSource]="dataSource">
    <ng-container matColumnDef="project_id">
      <th mat-header-cell *matHeaderCellDef id="project_id">Project Id</th>
      <td mat-cell *matCellDef="let element">{{ element.project_id }}</td>
    </ng-container>

    <ng-container matColumnDef="package_name">
      <th mat-header-cell *matHeaderCellDef id="date">Package Name</th>
      <td mat-cell *matCellDef="let element">{{ element.package_name }}</td>
    </ng-container>

    <ng-container matColumnDef="link">
      <th mat-header-cell *matHeaderCellDef id="link">Android URL</th>
      <td mat-cell *matCellDef="let element">{{ element.link }}</td>
    </ng-container>

    <ng-container matColumnDef="visitors">
      <th mat-header-cell *matHeaderCellDef id="visitors">Number of Visitosr</th>
      <td mat-cell *matCellDef="let element">{{ element.visitors }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
