import { Component, OnInit, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTable, MatTableDataSource } from '@angular/material/table'
import { Observable } from 'rxjs'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { SubscribersService } from '../subscribers.service'
import { ActivatedRoute, Router } from '@angular/router'

export interface subscribersTableColumns {
  id: string
  name: string
  email: string
  plan: string
  planPrice: string
  subDate: string
  renewDate: string
  status: string
  currency: string
  action: string
}

@Component({
  selector: 'app-subscribers-list',
  templateUrl: './subscribers-list.component.html',
  styleUrls: ['./subscribers-list.component.scss']
})
export class SubscribersListComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatTable)
  table!: MatTable<subscribersTableColumns>

  @ViewChild(MatSort, { static: true })
  matSort!: MatSort

  subscribers: any = {}
  public subscribersColumns: string[] = []
  public dataSource: any = []
  pageLoaded = false
  subscribers_data_counts: any = []

  ELEMENT_DATA: subscribersTableColumns[] = []
  mobile = false

  constructor(
    private subscribersService: SubscribersService,
    private builderService: BuilderService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.getAllData()
    if (window.screen.width <= 768) {
      // 768px portrait
      this.mobile = true
    }
  }

  getAllData = () => {
    this.subscribersService.getSubscribers().subscribe((response_subscribers: any) => {
      this.pageLoaded = true
      this.builderService.showUpdateProgress = true
      this.subscribersColumns = ['name', 'email', 'plan', 'planPrice', 'date', 'renewDate', 'status', 'action']
      this.subscribers = response_subscribers
      // this.dataSource.sort = this.matSort;
      this.ELEMENT_DATA = []
      this.renderSubscribers(this.subscribers).subscribe((response: any) => {
        this.dataSource.paginator = this.paginator
        this.dataSource.sort = this.matSort
        if (response) {
          this.table.renderRows()
        }
      })
    })
  }

  renderSubscribers = (result: any) => {
    return new Observable<any>((observer) => {
      this.builderService.showUpdateProgress = false
      this.subscribers_data_counts = []
      for (const key in result) {
        if (Object.prototype.hasOwnProperty.call(result, key)) {
          const element = result[key]
          this.subscribers_data_counts.push(element.count)
          let date: any = element.dateOfSub ? String(element.dateOfSub) : ''

          this.ELEMENT_DATA.push({
            id: key,
            email: element.subscriberEmail,
            name: element.subscriberName,
            plan: element.subscriptionPlan,
            renewDate: element.renewalDate,
            planPrice: element.subscriptionPrice,
            subDate: date ? date.replaceAll('/', '-') : '',
            status: element.status,
            currency: element.currency,
            action: ''
          })
        }
      }

      this.dataSource = new MatTableDataSource<subscribersTableColumns>(this.ELEMENT_DATA)
      setTimeout(() => {
        observer.next() // Notify the observer that the operation is complete
        observer.complete() // Complete the Observable
      }, 100)
    })
  }

  deleteSubscriber = (id: string) => {
    this.subscribersService.removeSubscriber(id).subscribe(() => {
      this.getAllData()
    })
  }

  editSubscriber = (id: string) => {
    // let product_link = ;
    this.router.navigate([`/admin/newSub/${id}`], { relativeTo: this.route })
  }
}
