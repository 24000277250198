<mat-progress-bar mode="indeterminate" *ngIf="productProgress"></mat-progress-bar>
<div class="SocialContainer">
  <h2>{{ 'Auth0 Credentials' | translate }}</h2>
  <div class="GooglePlus copyContainer">
    <div class="textCopyContainer">
      <h5>{{ 'Auth0 Allowed Origins' | translate }}</h5>
      <div>
        <p id="textToCopy">capacitor://localhost, https://localhost/, https:/*.auth.com/oauth/token</p>
        <button class="copy" type="button" (click)="copyToClipboard()">
          {{ 'Copy' | translate }}
        </button>
      </div>
    </div>
  </div>
  <div class="GooglePlus">
    <div>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Auth0 Domain' | translate }}</mat-label>
        <input [(ngModel)]="auth0_domain" matInput placeholder="{{ 'Enter Auth0 Domain' | translate }}" />
      </mat-form-field>
    </div>

    <div>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Auth0 Client ID' | translate }}</mat-label>
        <input [(ngModel)]="auth0_clientId" matInput placeholder="{{ 'Enter Auth0 Client ID' | translate }}" />
      </mat-form-field>
    </div>

    <div>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Auth0 Redirect Url' | translate }}</mat-label>
        <input [(ngModel)]="auth0_redirect_uri" matInput placeholder="{{ 'Enter Auth0 Redirect Url' | translate }}" />
      </mat-form-field>
    </div>

    <div *ngIf="error" class="buildError">
      <h2>
        {{ error }}
      </h2>
    </div>
  </div>

  <form (ngSubmit)="saveToApplicationSettings()" [formGroup]="social" id="social"></form>
</div>
