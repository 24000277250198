import { Component, OnInit, ViewEncapsulation } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { LtdService } from './ltd.service'
import { LoginService } from 'src/app/login/login.service'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { MixpanelService } from 'src/app/tracking/mixpanel.service'
import { environment } from 'src/environments/environment'
import { GoogleAnalyticsService } from 'ngx-google-analytics'
import { Router } from '@angular/router'
import { PixelService } from 'ngx-multi-pixel'

@Component({
  selector: 'ltd',
  templateUrl: './ltd.component.html',
  styleUrls: ['./ltd.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LtdComponent implements OnInit {
  couponProgress = false
  couponError = false
  couponErrorMsg = ''

  ltdMessage = ''

  constructor(
    private formBuilder: FormBuilder,
    private ltdService: LtdService,
    private loginService: LoginService,
    private builderService: BuilderService,
    private mixPanel: MixpanelService,
    private gaService: GoogleAnalyticsService,
    private router: Router,
    private pixel: PixelService
  ) {}

  couponForm = this.formBuilder.group({
    couponValue: ''
  })

  ngOnInit(): void {}

  /**
   * coupon the user, sets the token and navigate to the correct url
   */
  onSubmit(): void {
    let couponFormVal = this.couponForm.value
    this.couponProgress = true

    if (couponFormVal.couponValue)
      this.ltdService.checkCoupon(couponFormVal.couponValue).subscribe(
        (coupon: any) => {
          this.couponError = false
          let coupon_apps = coupon.apps
          let coupon_platform = coupon.platform
          let coupon_description = JSON.stringify(coupon)
          if (!coupon_apps) {
            this.couponProgress = false
            this.ltdMessage = "<p><span class='error'>Error: </span> " + coupon.data + '</p>'
            return
          }
          if (coupon_apps > 1) {
            this.ltdMessage = '<p><span>Thanks for purchasing</span> a license from <b>' + coupon_platform + '</b>, This gives you access to <b>' + coupon_apps + ' Apps</b></p>'
          } else {
            this.ltdMessage = '<p><span>Thanks for purchasing</span> a license from <b>' + coupon_platform + '</b>, This gives you access to <b>' + coupon_apps + ' App</b></p>'
          }
          let user_email = this.loginService.getCurrentUser().email
          this.builderService.createFreeProject(user_email, coupon_description).subscribe(
            (response: any) => {
              this.builderService.setTour(true)
              this.couponError = false

              this.builderService.selectedProject = response.project_id
              localStorage.setItem('selectedProject', response.project_id)
              this.mixPanel.init(response.project_id)
              this.mixPanel.track('Signed Up', {
                projectid: response.project_id,
                email: user_email
              })
              if (environment.production) {
                this.gaService.gtag('event', 'conversion', { send_to: environment.gtm })
                this.gaService.gtag('event', 'Signed Up', { send_to: environment.gtm })
                // this.gaService.gtag('event', 'conversion', {'send_to': 'AW-972505779/l9hZCMzVvocYELOF3c8D'});
                // this.gaService.gtag('event', 'Signed Up', {'send_to': 'AW-972505779/l9hZCMzVvocYELOF3c8D'});
                this.gaService.gtag('event', 'Signed Up', { send_to: environment.ga })
                this.pixel.trackCustom('Signed Up')
                this.pixel.track('StartTrial')
              }
              setTimeout(() => {
                this.couponProgress = false
                window.location.href = '/'
              }, 3000)
            },
            (err) => {
              this.couponProgress = false
              this.couponError = true
              this.couponErrorMsg = err.error.message
            }
          )
        },
        (err) => {
          this.couponProgress = false
          this.couponError = true
          this.couponErrorMsg = err.error.message
        }
      )
  }
}
