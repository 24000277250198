import { Component, OnInit, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatTable, MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'
import { ChartConfiguration } from 'chart.js'
import { BaseChartDirective } from 'ng2-charts'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core'
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import { AnalyticsService } from '../analytics.service'
import { Router } from '@angular/router'
import { MatDialog } from '@angular/material/dialog'

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD'
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
}

export interface pagesTableColumns {
  date: string
  date_formatted: any
  email: string
  user_id: string
  page_visited: string
}

let ELEMENT_DATA: pagesTableColumns[] = []

@Component({
  selector: 'analytics-page-stats',
  templateUrl: './analytics-page-stats.component.html',
  styleUrls: ['./analytics-page-stats.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class AnalyticsPageStatsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatTable)
  table!: MatTable<pagesTableColumns>

  @ViewChild(MatSort) sort!: MatSort
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective

  constructor(
    public analyticsService: AnalyticsService,
    public builderService: BuilderService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  public pagesColumns: string[] = []
  public dataSource: any = []
  public selfpages: any
  pages_dates: any = []

  initial_datasource: any

  form = new FormGroup({
    fromDate: new FormControl(null, { validators: [Validators.required] }),
    toDate: new FormControl(null, { validators: [Validators.required] })
  })

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
  }

  ngOnInit(): void {
    this.getPagesAnalytics()
    /**
     * Disable Access for free users
     */
    //  this.builderService.getProject(String( localStorage.getItem("selectedProject") ) ).subscribe((project: any) => {
    //   if( project.details[0].access == 'free' ) {
    //     const dialogTemplateLocked = this.dialog.open(WorksDialogComponent, {
    //       width: '700px',
    //       maxWidth: '80%',
    //       data: {name: "viewLocked"},
    //     });

    //     dialogTemplateLocked.afterClosed().subscribe(result => {
    //       this.router.navigate(['/']);
    //     });
    //   }
    // });
  }

  /**
   * Fill Page Table
   */
  getPagesAnalytics = () => {
    this.builderService.showUpdateProgress = true
    this.pagesColumns = ['date', 'email', 'user_id', 'page_visited']
    ELEMENT_DATA = []
    this.fetchNewPages()
  }

  /**
   * Retrieves the new Sales from the server
   */
  fetchNewPages = () => {
    this.builderService.showUpdateProgress = true
    this.analyticsService.getPageStats().subscribe((response) => {
      this.builderService.showUpdateProgress = false
      this.renderPageAnalytics(response)
    })
  }

  /**
   * Renders the Sales data
   */
  renderPageAnalytics = (result: any) => {
    this.builderService.showUpdateProgress = false
    let all_pages = result
    all_pages = Object.entries(all_pages)
      .sort()
      .reduce((o: any, [k, v]) => ((o[k] = v), o), {})
    this.selfpages = all_pages
    ELEMENT_DATA = []
    this.formatChartData(result)
    this.renderChartData()
    for (const key in all_pages) {
      if (Object.prototype.hasOwnProperty.call(all_pages, key)) {
        const element = all_pages[key]
        ELEMENT_DATA.push({ date: element.created_at, date_formatted: new Date(element.created_at), email: element.email, user_id: element.user_id, page_visited: element.page_visited })
      }
    }
    this.dataSource = new MatTableDataSource<pagesTableColumns>(ELEMENT_DATA)
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.initial_datasource = [...this.dataSource.data]
    setTimeout(() => {
      this.table.renderRows()
    }, 1000)
  }

  /**
   * Renders the chart data labels and data
   * @param data_type : the filtered data type doesn't need reversing the chart however the initial data rendered needs that reversal
   */

  renderChartData = (data_type: string = 'initial') => {
    let chartDataArray = []
    let chartLabelsArray = []
    for (const key in this.pages_dates) {
      if (Object.prototype.hasOwnProperty.call(this.pages_dates, key)) {
        const element = this.pages_dates[key]
        chartLabelsArray.push(key)
        chartDataArray.push(element.length)
      }
    }
    if (data_type == 'filtered') {
      this.subChartData.datasets[0].data = chartDataArray
      this.subChartData.labels = chartLabelsArray
    } else {
      this.subChartData.datasets[0].data = chartDataArray.reverse()
      this.subChartData.labels = chartLabelsArray.reverse()
    }
    this.chart?.update()
  }

  /**
   * The chart data needs date formatting to be year-month-days, this function is intended to grab the creation date from the array and format it correctly
   * @param data : the data that needs formatting
   * @param date : the date object
   */
  formatChartData = (data: any, date: any = 'created_at') => {
    this.pages_dates = []
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const element = data[key]
        // if(!this.pages_dates[key]) {
        //   this.pages_dates.push(key);
        // }
        let formatted_dates = new Date(element[date])
        let formatted_date = formatted_dates.getFullYear() + '-' + (formatted_dates.getMonth() + 1) + '-' + +formatted_dates.getDate()
        if (!this.pages_dates[formatted_date]) {
          this.pages_dates[formatted_date] = []
        }
        this.pages_dates[formatted_date].push(element)
      }
    }
  }

  // Filter the datasource, format the chart data with dates and renders the chart
  applySubFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
    this.dataSource.filter = filterValue.trim().toLowerCase()
    this.formatChartData(this.dataSource.filteredData, 'date')
    this.renderChartData('filtered')
  }

  applyDateFilter() {
    let fixedFromDate = this.addDays(this.form.value.fromDate, 0)
    let fixedToDate = this.addDays(this.form.value.toDate, 1)
    this.dataSource.data = this.dataSource.data.filter((e: { date_formatted: any }) => e.date_formatted >= fixedFromDate && e.date_formatted <= fixedToDate)
    let selfpages = this.pages_dates
    let filteredChartSales: any = []
    let fromDate: any = this.form.value.fromDate
    let toDate: any = this.form.value.toDate
    Object.keys(selfpages).map(function (key, index) {
      if (new Date(key) >= fromDate && new Date(key) <= toDate) {
        filteredChartSales[key] = selfpages[key]
      }
    })
    this.pages_dates = filteredChartSales
    this.renderChartData()
    if (this.form.invalid) {
      return
    }
  }

  /**
   * Refetches everything again
   */
  clearDateFilter = () => {
    this.fetchNewPages()
  }

  /**
   * Add Days to the passed date
   * @param date : normal date
   * @param days : days that needs to be added
   * @returns the date after adding the days
   */

  addDays = (date: any, days: any) => {
    var result = new Date(date)
    result.setDate(result.getDate() + days)
    return result
  }

  public subChartData: ChartConfiguration['data'] = {
    datasets: [
      {
        data: [],
        label: 'Pages per day',
        yAxisID: 'y-axis-1',
        backgroundColor: 'rgba(96,132,222,0.3)',
        borderColor: 'rgba(96,132,222,1)',
        pointBackgroundColor: 'rgba(96,132,222,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(96,132,222,0.8)',
        fill: 'origin'
      }
    ]
    // labels: [ 'January', 'February', 'March', 'April', 'May', 'June', 'July' ]
  }

  public subChartOptions: ChartConfiguration['options'] = {
    elements: {
      line: {
        tension: 0.5
      }
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      x: {},
      'y-axis-1': {
        position: 'right',
        grid: {
          color: 'rgba(96,132,222,0.3)'
        },
        ticks: {
          color: 'rgba(96,132,222,1)'
        }
      }
    }
  }
}
