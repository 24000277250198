<div class="newProduct containerWrapper">
  <app-stepper step="5" *ngIf="builderService.getTour()"></app-stepper>
  <h1>{{ 'Add New Product' | translate }}</h1>
  <div class="productDetail">
    <mat-progress-bar mode="indeterminate" *ngIf="productProgress"></mat-progress-bar>
    <form [formGroup]="productForm" (ngSubmit)="onSubmit()">
      <div class="productHeader">
        <h1>{{ 'Products' | translate }}</h1>
        <button class="stacksBtn green" *ngIf="!builderService.getTour()" [routerLink]="['/products']">SEE ALL PRODUCTS</button>
        <button mat-icon-button *ngIf="builderService.getTour()" class="stacksBtn blue glow" type="submit">
          <span>{{ 'Publish' | translate }}</span>
        </button>
      </div>
      <div class="productForm">
        <div class="left">
          <mat-form-field appearance="outline" [ngClass]="builderService.getTour() ? 'glowingGreenElement' : ''">
            <mat-label>{{ 'Product Name' | translate }}</mat-label>
            <input required matInput formControlName="productName" />
            <mat-error>
              {{ 'Product Name required' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" [ngClass]="builderService.getTour() ? 'glowingGreenElement' : ''">
            <mat-label>{{ 'Stock' | translate }}</mat-label>
            <input type="number" required matInput formControlName="productStock" />
            <mat-error>
              {{ 'Product Stock required' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline" [ngClass]="builderService.getTour() ? 'glowingGreenElement' : ''">
            <mat-label>{{ 'Regular Price' | translate }}</mat-label>
            <input required type="number" min="0" matInput formControlName="regularPrice" />
            <mat-error>
              {{ 'Regular Price required' | translate }}
            </mat-error>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'Sale Price' | translate }}</mat-label>
            <input type="number" matInput formControlName="salePrice" />
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'sku' | translate }}</mat-label>
            <input matInput formControlName="productSKU" />
          </mat-form-field>
          <div class="timeContainer">
            <mat-form-field appearance="outline">
              <mat-label>{{ 'Estimated Delivery Time' | translate }}</mat-label>
              <input matInput type="number" formControlName="estimatedDeliveryTime" placeholder="Enter time" />
            </mat-form-field>
            <mat-form-field appearance="outline">
              <mat-label>{{ 'Time Unit' | translate }}</mat-label>
              <mat-select formControlName="estimatedDeliveryUnit">
                <mat-option value="minutes">{{ 'Minutes' | translate }}</mat-option>
                <mat-option value="hours">{{ 'Hours' | translate }}</mat-option>
                <mat-option value="days">{{ 'Days' | translate }}</mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="right">
          <mat-label>{{ 'Product description' | translate }}</mat-label>
          <!-- <input matInput formControlName="productDescription"> -->
          <editor
            [(ngModel)]="productDescription"
            [ngModelOptions]="{ standalone: true }"
            apiKey="dh6nv3idqamf38bn1tnknqxd0qn9vz2yli1zjpjehily2stk"
            [init]="{
              base_url: '/tinymce',
              suffix: '.min',
              height: 320,
              menubar: false,
              plugins: ['lists', 'help', 'textcolor', 'colorpicker', 'code'],
              toolbar:
                'code | undo redo | bold italic | \
                        alignleft aligncenter alignright alignjustify | \
                        bullist numlist outdent indent forecolor backcolor | help'
            }"
          ></editor>
          <mat-form-field appearance="outline" class="productCategories" *ngIf="categories.length">
            <mat-label>{{ 'Product Categories' | translate }}</mat-label>
            <mat-select multiple (selectionChange)="categoryChange($event.value)" formControlName="categoriesIds">
              <mat-option *ngFor="let category of categories" value="{{ category.categoryId }}">
                <div>
                  <img src="{{ category.categoryImage }}" />
                  <p>{{ category.categoryName }}</p>
                </div>
              </mat-option>
            </mat-select>
            <mat-select *ngIf="!categories.length" formControlName="categoriesIds">
              <mat-option>{{ 'Add Categories First' | translate }}</mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'Product Subcategories' | translate }}</mat-label>
            <mat-select multiple (selectionChange)="subCategoryChange($event.value)" formControlName="subCategoriesIds">
              <mat-option value="{{ subCategory.subCategoryId }}" *ngFor="let subCategory of allowedSubcategories">
                {{ subCategory.subcategory }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
      <div class="fileWrapperContainer">
        <div class="fileWrapper">
          <ul class="imgWrapper" *ngIf="imageUrl && imageUrl.length">
            <li class="imgContainer" *ngFor="let i of imageUrl; let indexOfelement = index">
              <button class="remove" (click)="removeFromImgArr(indexOfelement)">X</button>
              <div id="smallImgWrapper">
                <img *ngIf="imageUrl[indexOfelement]" src="{{ imageUrl[indexOfelement] }}" alt="" />
                <img *ngIf="!imageUrl[indexOfelement]" src="assets/images/empty-image.png" />
                <div class="inputContainer editContainer">
                  <input type="file" class="editImg custom-file-input" (change)="editImg($event, indexOfelement)" accept="image/*" />
                </div>
              </div>
            </li>
          </ul>
          <mat-progress-bar mode="determinate" value="{{ this.builderService.fileUploadProgress }}" *ngIf="showUploadProgress"></mat-progress-bar>
          <div class="inputContainer">
            <input type="file" class="custom-file-input" (change)="onFileSelected($event)" accept="image/*" />
          </div>
          <p class="preferred">"{{ 'preferred size' | translate }}"</p>
          <p class="preferred">"{{ 'Extensions png' | translate }}"</p>
        </div>
        <div class="fileWrapper">
          <ul class="imgWrapper" *ngIf="sizeChartUrl">
            <li class="imgContainer">
              <button class="remove" (click)="removeSizeChart()">X</button>
              <div id="smallImgWrapper">
                <img *ngIf="sizeChartUrl" [src]="sizeChartUrl" alt="Size Chart" />
                <img *ngIf="!sizeChartUrl" src="assets/images/empty-image.png" />
                <div class="inputContainer editContainer">
                  <input type="file" class="editImg custom-file-input" (change)="onSizeChartSelected($event)" accept="image/*" />
                </div>
              </div>
            </li>
          </ul>
          <mat-progress-bar mode="determinate" value="{{ this.builderService.fileUploadProgress }}" *ngIf="showUploadProgress"></mat-progress-bar>
          <div class="inputContainer">
            <input type="file" class="custom-file-input sizeChart" (change)="onSizeChartSelected($event)" accept="image/*" />
          </div>
          <p class="preferred">"{{ 'Extensions png' | translate }}"</p>
        </div>
      </div>
      <div class="attributeWrapper">
        <span *ngIf="!mobile" (click)="tagsMep.expanded = !tagsMep.expanded" [class.openPanel]="!tagsOpen" class="material-icons material-icons-outlined">expand_more</span>
        <mat-accordion>
          <mat-expansion-panel hideToggle (opened)="tagsOpen = true" (closed)="tagsOpen = false" [expanded]="true" [disabled]="true" #tagsMep="matExpansionPanel">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span *ngIf="mobile" (click)="tagsMep.expanded = !tagsMep.expanded" [class.openPanel]="!tagsOpen" class="material-icons material-icons-outlined">expand_more</span>
                {{ 'Tags' | translate }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="attrDescription">
              <ul>
                <li *ngFor="let tag of tags; let indexOfelement = index">
                  <mat-form-field appearance="outline" class="attrName">
                    <mat-label>{{ 'Tag Key (e.g., brands)' | translate }}</mat-label>
                    <input matInput [value]="tag.key" (change)="updateTagKey(indexOfelement, $event)" placeholder="e.g., brands" />
                  </mat-form-field>
                  <mat-form-field appearance="outline" class="attrValue" floatLabel="always">
                    <mat-label>{{ 'Values (e.g., adidas, nike)' | translate }}</mat-label>
                    <mat-chip-grid #chipGrid>
                      <mat-chip-row *ngFor="let value of tag.values" [removable]="true" (removed)="removeTagValue(indexOfelement, value)">
                        {{ value }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                      </mat-chip-row>
                      <input placeholder="{{ 'Add a value' | translate }}" [matChipInputFor]="chipGrid" [matChipInputSeparatorKeyCodes]="separatorKeysCodes" (matChipInputTokenEnd)="addTagValue($event, indexOfelement)" />
                    </mat-chip-grid>
                  </mat-form-field>
                  <div class="buttonsWrapper">
                    <button mat-icon-button class="addAttr" type="button" (click)="addTagCategory()">
                      <span class="material-icons material-icons-outlined">add</span>
                    </button>
                    <button mat-icon-button class="removeAttr" type="button" (click)="removeTagCategory(indexOfelement)">
                      <span class="material-icons material-icons-outlined">remove</span>
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="attributeWrapper">
        <span *ngIf="!mobile" (click)="mep.expanded = !mep.expanded" [class.openPanel]="!attributeOpen" class="material-icons material-icons-outlined">expand_more</span>
        <mat-accordion>
          <mat-expansion-panel hideToggle (opened)="attributeOpen = true" (closed)="attributeOpen = false" [expanded]="true" [disabled]="true" #mep="matExpansionPanel">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span *ngIf="mobile" (click)="mep.expanded = !mep.expanded" [class.openPanel]="!attributeOpen" class="material-icons material-icons-outlined"> expand_more </span>
                {{ 'Attribute' | translate }}</mat-panel-title
              >
            </mat-expansion-panel-header>
            <div class="attrDescription">
              <ul>
                <li *ngFor="let productAttribute of productAttributes; let indexOfelement = index">
                  <mat-form-field appearance="outline" class="attrName">
                    <mat-label>{{ 'Name-f.e' | translate }}</mat-label>
                    <input matInput (change)="attributeChange('attrName', $event, indexOfelement)" value="{{ productAttribute.name }}" />
                  </mat-form-field>

                  <mat-form-field appearance="outline" class="attrValue" floatLabel="always">
                    <mat-label>{{ 'Value(S)' | translate }}</mat-label>
                    <input matInput (change)="attributeChange('attrValue', $event, indexOfelement)" value="{{ productAttribute.value }}" />
                  </mat-form-field>
                  <div class="buttonsWrapper">
                    <button mat-icon-button class="addAttr" type="button" (click)="addAttr()">
                      <span class="material-icons material-icons-outlined">add</span>
                    </button>
                    <button mat-icon-button class="removeAttr" type="button" (click)="removeAttr(indexOfelement)" *ngIf="indexOfelement !== 0">
                      <span class="material-icons material-icons-outlined">remove</span>
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="variationWrapper" *ngIf="(possibileVariations | keyvalue).length >= 1">
        <span *ngIf="!mobile" [class.openPanel]="!variationOpen" class="material-icons material-icons-outlined togglePanel" (click)="mep.expanded = !mep.expanded"> expand_more </span>
        <mat-accordion>
          <mat-expansion-panel hideToggle (opened)="variationOpen = true" (closed)="variationOpen = false" [expanded]="true" [disabled]="true" #mep="matExpansionPanel">
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span *ngIf="mobile" [class.openPanel]="!variationOpen" class="material-icons material-icons-outlined togglePanel" (click)="mep.expanded = !mep.expanded"> expand_more </span>
                {{ 'Variations' | translate }}</mat-panel-title
              >
            </mat-expansion-panel-header>
            <div class="varDescription">
              <!-- <div class="varPriceDesc">
                                <h2>{{'Variation Price Range' | translate }}</h2>
                                <p>{{'The value in these fields' | translate }}</p>
                                <div class="varPrice">
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{'Min Price' | translate }}</mat-label>
                                        <input type="number" min="0" matInput formControlName="minVarPrice">
                                        <mat-error>
                                            {{'Min Price' | translate }}
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field Variation Price Rangeappearance="outline">
                                        <mat-label>{{'Max Price' | translate }}</mat-label>
                                        <input type="number" min="0" matInput formControlName="maxVarPrice">
                                        <mat-error>
                                            {{'Max Price' | translate }}
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{'Min Sale' | translate }}</mat-label>
                                        <input type="number" min="0" matInput formControlName="minVarSale">
                                        <mat-error>
                                            {{'Min Sale' | translate }}
                                        </mat-error>
                                    </mat-form-field>
                                    <mat-form-field appearance="outline">
                                        <mat-label>{{'Max Sale' | translate }}</mat-label>
                                        <input type="number" min="0" matInput formControlName="maxVarSale">
                                        <mat-error>
                                            {{'Max Sale' | translate }}
                                        </mat-error>
                                    </mat-form-field>
                                </div>
                            </div> -->
              <ul class="varRow">
                <li *ngFor="let productVariation of productVariations; let indexOfelement = index">
                  <mat-accordion>
                    <mat-expansion-panel hideToggle (opened)="varDescOpen = true" (closed)="varDescOpen = false" [expanded]="true" [disabled]="true" #mep="matExpansionPanel">
                      <mat-expansion-panel-header>
                        <ul class="varParametersWrapper">
                          <div class="varExpandArrow">
                            <span [class.openPanel]="!mep.expanded" (click)="mep.expanded = !mep.expanded" class="material-icons material-icons-outlined"> expand_more </span>
                          </div>
                          <li *ngFor="let possibileVariation of possibileVariations; let indexOfpossibleVariations = index">
                            <mat-form-field appearance="outline" class="variationValues">
                              <mat-label>
                                {{ 'Choose Attribute' | translate }}
                              </mat-label>
                              <mat-select [value]="variationLookup(productVariations[indexOfelement]['values'], possibileVariation)" (click)="$event.stopPropagation()" (selectionChange)="variationChange('values', $event.value, indexOfelement, productVariation.values)">
                                <mat-option value="{{ variation }}" *ngFor="let variation of possibileVariation">
                                  {{ variation }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                          </li>
                          <div class="buttonsWrapper">
                            <button mat-icon-button class="addAttr" type="button" (click)="$event.stopPropagation()" (click)="addVar()">
                              <span class="material-icons material-icons-outlined">add</span>
                            </button>
                            <button mat-icon-button class="removeAttr" type="button" (click)="$event.stopPropagation()" (click)="removeVar(indexOfelement)" *ngIf="indexOfelement !== 0">
                              <span class="material-icons material-icons-outlined">remove</span>
                            </button>
                          </div>
                        </ul>
                      </mat-expansion-panel-header>
                      <div *ngIf="!mobile" class="fileWrapper">
                        <mat-progress-bar mode="determinate" value="{{ this.builderService.fileUploadProgress }}" *ngIf="showVarUploadProgress[indexOfelement]"></mat-progress-bar>
                        <img src="{{ productVariations[indexOfelement]['image'] }}" alt="" />
                        <div class="inputContainer">
                          <input type="file" class="custom-file-input" (change)="onVarFileSelected($event, 'variation', indexOfelement)" accept="image/*" />
                        </div>
                        <button (click)="removeVariationImg(indexOfelement)" type="button">Remove</button>
                      </div>
                      <div class="varInputs">
                        <div class="error">
                          <mat-form-field appearance="outline" class="attrValue">
                            <mat-label>{{ 'Regular Price' | translate }}</mat-label>
                            <input required matInput #regPrice value="{{ productVariations[indexOfelement]['regularPrice'] }}" (change)="variationChange('regularPrice', regPrice.value, indexOfelement, productVariation.values)" />
                          </mat-form-field>
                          <p *ngIf="!productVariations[indexOfelement]['regularPrice']">
                            {{ 'Regular Price is required' | translate }}
                          </p>
                        </div>
                        <mat-form-field appearance="outline" class="attrValue">
                          <mat-label>{{ 'Sale Price' | translate }}</mat-label>
                          <input matInput #salePrice value="{{ productVariations[indexOfelement]['salePrice'] }}" (change)="variationChange('salePrice', salePrice.value, indexOfelement, productVariation.values)" />
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="attrValue">
                          <mat-label>{{ 'sku' | translate }}</mat-label>
                          <input matInput #sku value="{{ productVariations[indexOfelement]['sku'] }}" (change)="variationChange('sku', sku.value, indexOfelement, productVariation.values)" />
                        </mat-form-field>
                        <div class="error">
                          <mat-form-field appearance="outline" class="attrValue">
                            <mat-label>{{ 'Variation Stock' | translate }}</mat-label>
                            <input matInput type="number" #varStock value="{{ getVariationStock(productVariations[indexOfelement]) }}" (change)="variationChange('stock', varStock.value, indexOfelement, productVariation.values)" />
                          </mat-form-field>
                          <p *ngIf="!getVariationStock(productVariations[indexOfelement])">
                            {{ 'Stock is required' | translate }}
                          </p>
                        </div>
                      </div>

                      <div *ngIf="mobile" class="fileWrapper">
                        <mat-progress-bar mode="determinate" value="{{ this.builderService.fileUploadProgress }}" *ngIf="showUploadProgress"></mat-progress-bar>
                        <img src="{{ imageUrl }}" alt="" />
                        <div class="inputContainer">
                          <input type="file" class="custom-file-input" (change)="onFileSelected($event)" accept="image/*" />
                        </div>
                        <button (click)="removeVariationImg(indexOfelement)" type="button">hi</button>
                      </div>
                    </mat-expansion-panel>
                  </mat-accordion>
                </li>
              </ul>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <button mat-icon-button class="publishBtn glowingBlueElement" type="submit">
        <span>{{ 'Publish' | translate }}</span>
      </button>
    </form>
  </div>
</div>
