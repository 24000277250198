import { Component, OnInit, ViewChild } from '@angular/core'
import { OrdersService } from './orders.service'
import { MatPaginator } from '@angular/material/paginator'
import { MatTable, MatTableDataSource } from '@angular/material/table'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { MatSort } from '@angular/material/sort'
import { Observable } from 'rxjs'
import { ActivatedRoute, Router } from '@angular/router'

export interface OrdersTableColumns {
  [key: string]: any // This allows for dynamic fields
}
/*
For this piece of code it just reassings the id of every order to its index in the orders array
and sets the last index in the array in the array to be easily added in the app and assigned
to every new order and updated in the app and in the database

for(let i of this.ELEMENT_DATA.keys()){
  this.ELEMENT_DATA[i].humId = i
  if(i+1 === this.ELEMENT_DATA.length){
    this.ordersService.lastIndex = i;
    this.ordersService.addLastIndex(this.ordersService.lastIndex);
  }
}
  */

@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatTable) table!: MatTable<OrdersTableColumns>
  @ViewChild(MatSort, { static: true }) matSort!: MatSort

  ELEMENT_DATA: OrdersTableColumns[] = []
  public dataSource: any = []
  pageLoaded = false
  public ordersColumns: string[] = []
  orders: any = {}
  orders_dates: any = []
  orders_data_counts: any = []

  constructor(
    private route: ActivatedRoute,
    private ordersService: OrdersService,
    private builderService: BuilderService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.getAllData()
  }

  getAllData = () => {
    this.ordersService.getOrders().subscribe((orders: any) => {
      console.log('Raw orders from service:', orders) // Debug log

      this.ELEMENT_DATA = []
      this.dataSource = orders
      this.pageLoaded = true
      this.builderService.showUpdateProgress = true

      // Keep these columns for display purposes only
      this.ordersColumns = ['id', 'date', 'user_data', 'status', 'total', 'action']
      this.orders = orders

      this.renderOrders(this.orders).subscribe((response: any) => {
        // let lastHumId = 0
        // for (let i = 0; i < this.ELEMENT_DATA.length; i++) {
        //   this.ELEMENT_DATA[i].humId = i + 1
        //   lastHumId = this.ELEMENT_DATA[i].humId
        // }

        this.ELEMENT_DATA.sort((a, b) => {
          return b.humId - a.humId
        })

        // if (this.ELEMENT_DATA.length > 0) {
        //   this.ordersService.lastIndex = Math.max(...this.ELEMENT_DATA.map((o) => o.humId)) // Get the highest humId
        //   this.ordersService.addLastIndex(this.ordersService.lastIndex)
        // } else {
        //   this.ordersService.lastIndex = 0
        //   this.ordersService.addLastIndex(this.ordersService.lastIndex)
        // }

        this.dataSource = new MatTableDataSource<OrdersTableColumns>(this.ELEMENT_DATA)
        this.dataSource.paginator = this.paginator
        this.dataSource.sort = this.matSort
        this.table.renderRows()
      })
    })
  }

  convertDate = (dateString: string): string => {
    const parts = dateString.split('-')
    return `${parts[2]}-${parts[1]}-${parts[0]}`
  }

  renderOrders(result: any) {
    return new Observable<any>((observer) => {
      this.builderService.showUpdateProgress = false
      let ordersList: any = Object.values(result)

      for (const key in ordersList) {
        if (Object.prototype.hasOwnProperty.call(ordersList, key)) {
          const element = ordersList[key]

          // Push the entire element to maintain all data
          this.ELEMENT_DATA.push({
            ...element, // Spread all fields from Firebase
            action: ''
          })
        }
      }

      // console.log('Complete ELEMENT_DATA:', this.ELEMENT_DATA);

      this.dataSource = new MatTableDataSource<OrdersTableColumns>(this.ELEMENT_DATA)
      setTimeout(() => {
        observer.next()
        observer.complete()
      }, 100)
    })
  }

  editOrder = (id: string) => {
    // let product_link = ;
    this.router.navigate([`/orders/single-order/${id}`], { relativeTo: this.route })
  }

  deleteOrder = (id: string, user_id: string) => {
    if (confirm('Are you sure to delete order ' + id + ', This step is unrecoverable')) {
      this.ordersService.removeOrder(id, user_id).subscribe((response: any) => {
        this.getAllData()
      })
    }
  }

  exportToExcel = () => {
    if (this.ELEMENT_DATA && this.ELEMENT_DATA.length > 0) {
      this.ordersService.exportOrdersToExcel(this.ELEMENT_DATA)
    } else {
      alert('No orders to export')
    }
  }
}
