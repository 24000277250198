import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class CloudService {
  options = {
    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  }

  stacksURL = 'https://stacksmarket.co/wp-json/v4/'
  public allSubsData: any = []
  public allUnSubsData: any = []
  constructor(private http: HttpClient) {}

  getUnsubscribers = () => {
    return this.http.get(this.stacksURL + 'getUnCloudSalesStats?password=4YT7ydf38ytzaaHJ')
  }

  getSubscribers = () => {
    return this.http.get(this.stacksURL + 'getActiveCloudSalesStats?password=4YT7ydf38ytzaaHJ')
  }

  getAllSubscribers = () => {
    return this.http.get(this.stacksURL + 'getAllCloudSalesStats?password=4YT7ydf38ytzaaHJ')
  }
}
