<form [formGroup]="contentSettingsForm" id="contentSettingsWrapper" class="settingsWrapper" (ngSubmit)="onSubmit()">
  <h1 *ngIf="builderService.projectAccess !== 'webview'">{{ 'Content Settings' | translate }}</h1>
  <h2 class="settingsSubHeading" *ngIf="builderService.projectAccess !== 'webview'">{{ 'Style Settings' | translate }}</h2>
  <ul *ngIf="builderService.projectAccess == 'webview'">
    <li>
      <h2>{{ 'Add the link of website' | translate }}</h2>
      <p>{{ 'This option defines' | translate }}</p>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Add the link of website' | translate }}</mat-label>
        <input matInput placeholder="https://google.com" formControlName="mobile_webview_link" value="{{ this.contentSettingsData?.mobile_webview_link !== '' ? this.contentSettingsData?.mobile_webview_link : this.builderService.selectedProjectDomain }}" />
      </mat-form-field>
    </li>
  </ul>
  <div class="contentContainer" *ngIf="builderService.projectAccess !== 'webview'">
    <div>
      <h2>{{ 'Category Sub Page' | translate }}</h2>
      <p>{{ 'Click on category from main page' | translate }}</p>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Category Sub-Page' | translate }}</mat-label>
        <mat-select formControlName="category_sub_page">
          <mat-option value="1">
            {{ 'Enabled' | translate }}
          </mat-option>
          <mat-option value="null">
            {{ 'Disabled' | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <h2>{{ 'Contact Us-Email' | translate }}</h2>
      <p>{{ 'recieve emails from contact' | translate }}</p>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Contact Us-Email' | translate }}</mat-label>
        <input matInput placeholder="info@yourwebsite.com" formControlName="contact_email" value="{{ this.contentSettingsData?.contact_email }}" />
      </mat-form-field>
    </div>
    <!-- <div>
      <h2>Contact Us Phone</h2>
      <p>Put the phone you want it to appear in contact us page</p>
      <mat-form-field appearance="outline">
        <mat-label>Contact Us Phone</mat-label>
        <input matInput placeholder="+123456789" formControlName="contact_phone"
          value="{{this.contentSettingsData?.contact_phone}}">
      </mat-form-field>
    </div>
    <div>
      <h2>Product Title Number of Characters</h2>
      <p>This option limit the number of characters for the product title</p>
      <mat-form-field appearance="outline">
        <mat-label>Product Title Number of Characters</mat-label>
        <input matInput type="number" placeholder="10" formControlName="product_title_length"
          value="{{this.contentSettingsData?.product_title_length}}">
      </mat-form-field>
    </div>
    <div>
      <h2>Title extended dotes</h2>
      <p>After trimming title set the ending dots, example: "..."</p>
      <mat-form-field appearance="outline">
        <mat-label>Title extended dotes</mat-label>
        <input matInput type="number" placeholder="..." formControlName="product_title_dots"
          value="{{this.contentSettingsData?.product_title_dots}}">
      </mat-form-field>
    </div> -->
    <div>
      <h2>{{ 'Webview Link' | translate }}</h2>
      <p>{{ 'This option defines the home' | translate }}</p>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Add link Home Page' | translate }}</mat-label>
        <input matInput placeholder="https://google.com" formControlName="mobile_webview_link" value="{{ this.contentSettingsData?.mobile_webview_link }}" />
      </mat-form-field>
    </div>
    <div>
      <h2>{{ 'Choose Browser Type in webview' | translate }}</h2>
      <p>{{ 'Open the Webview in App Browser' | translate }}</p>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Choose Browser Type' | translate }}</mat-label>
        <mat-select formControlName="browser_type">
          <mat-option value="appBrowser">
            {{ 'Webview in the App Browser' | translate }}
          </mat-option>
          <mat-option value="mobileBrowser">
            {{ 'Default Mobile Device' | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
    <div>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Guest User Permission' | translate }}</mat-label>
        <mat-select formControlName="disable_guest">
          <mat-option value="disabled">
            {{ 'Login is must' | translate }}
          </mat-option>
          <mat-option value="1">
            {{ 'Navigate without logging' | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <p>{{ 'Any user Can navigate' | translate }}</p>
    </div>
    <div *ngIf="!builderService.standalone">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Webview Checkout' | translate }}</mat-label>
        <mat-select formControlName="enable_website_checkout">
          <mat-option value="disabled">
            {{ 'Disabled' | translate }}
          </mat-option>
          <mat-option value="1">
            {{ 'Enabled' | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
      <p>{{ 'Disable the Native Checkout' | translate }}</p>
    </div>
    <div *ngIf="builderService.standalone">
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Search Results Posts WordPress' | translate }}</mat-label>
        <input matInput type="text" placeholder="https://mywordpressite.com" formControlName="search_results_posts" value="{{ this.contentSettingsData?.search_results_posts }}" />
      </mat-form-field>
      <p>{{ 'If you have wordpress site' | translate }}</p>
    </div>
  </div>
</form>
