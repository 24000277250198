import { Component, OnInit, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTable, MatTableDataSource } from '@angular/material/table'
import { ActivatedRoute, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { ProductsService } from '../../products/products.service'
import { CouponsService } from '../coupons.service'
import { AfterViewInit } from '@angular/core'
import { SettingsService } from '../../settings/settings.service'
import { NgForm } from '@angular/forms'
import { CategoriesService } from '../../categories/categories.service'
export interface productsTableColumns {
  id: string
  image: string
  name: string
  sku: string
  stock: string
  price: string
  date: string
  checked?: boolean
}

export interface categoriesTableColumns {
  id: string
  image: string // Optional property
  name: string
  checked?: boolean
}

export interface Coupon {
  id?: string
  name: string
  expirationDate: string
  amount: number
  discountType: 'percentage' | 'specific_amount'
  discountCriteria: 'fixed_products_discount' | 'fixed_categories_discount' | 'whole_cart_discount'

  usageTime: number
  usageTimes: number
  uses: number
  min: number
  max: number
  selectedProducts?: productsTableColumns[]
  selectedCategories?: categoriesTableColumns[]
  active: boolean
}
export interface User {
  id?: string
  name: string
  email: string
}
@Component({
  selector: 'app-add-coupon',
  templateUrl: './add-coupon.component.html',
  styleUrls: ['./add-coupon.component.scss']
})
export class AddCouponComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatSort, { static: true }) matSort!: MatSort
  @ViewChild('usersPaginator') usersPaginator!: MatPaginator
  @ViewChild('productPaginator') productPaginator!: MatPaginator
  @ViewChild(MatTable) pTable!: MatTable<productsTableColumns>
  @ViewChild(MatTable) cTable!: MatTable<categoriesTableColumns>
  @ViewChild('f', { static: true }) couponForm!: NgForm

  products: any = {}
  categories: any = {}
  ELEMENT_DATAP: productsTableColumns[] = []
  ELEMENT_DATAC: categoriesTableColumns[] = []
  displayedColumns: string[] = ['selectImageName']
  public selectedCategoriesDataSource = new MatTableDataSource<categoriesTableColumns>() // Assuming Category is your model interface

  public selectedProductsDataSource: MatTableDataSource<productsTableColumns> = new MatTableDataSource()

  public selectedProductsColumns: string[] = ['select', 'image', 'name', 'stock']
  public usersColumns: string[] = ['id', 'userName', 'userUses', 'email']
  public usersData: any = []
  public usersList: any = []
  filteredUsersList = new MatTableDataSource<User>()

  public users: User[] = []
  public productsDataSource: MatTableDataSource<productsTableColumns> = new MatTableDataSource()
  public categoriesDataSource: MatTableDataSource<categoriesTableColumns> = new MatTableDataSource()
  public productsColumns: string[] = []

  public dataSource: any = []
  products_dates: any = []
  public elements: any[] = []
  products_data_counts: any = []

  discountType = ['percentage', 'specific_amount']
  discountCirteria = ['fixed_products_discount', 'fixed_categories_discount', 'Whole Cart Discount']
  discountCriteriaMap: { [key: string]: string } = {
    fixed_products_discount: 'Fixed Products Discount',
    fixed_categories_discount: 'Fixed Category Discount',
    whole_cart_discount: 'Whole Cart Discount'
  }
  discountTypeMap: { [key: string]: string } = {
    percentage: 'Percentage',
    specific_amount: 'Specific Amount'
  }
  public invalidationMessage: string = ''
  selectedCriteria: string = ''
  selectedType: string = ''
  discountDefaultInput: number = 0
  discountPercentage: number = 0
  discountAmount: number = 0
  couponName: string = ''
  dateInput: string = ''
  currency: any
  couponUid: string = ''
  couponId: string = ''
  usageTime: number = 0
  usageTimes: number = 0
  uses: number = 0
  min: number = 0
  active: boolean = true
  searchText: string = ''
  max: number = 0
  couponsArray: Coupon[] = [] // Define it as a class property
  public editPage: boolean = false
  selectedCategories: any[] = []
  selectedProducts: any[] = []

  constructor(
    private couponsService: CouponsService,
    private productsService: ProductsService,
    private categoriesService: CategoriesService,
    public builderService: BuilderService,
    private router: Router,
    private route: ActivatedRoute,
    private settingsService: SettingsService
  ) {}
  ngAfterViewInit() {
    this.productsDataSource.paginator = this.paginator
    this.productsDataSource.sort = this.matSort
    this.filteredUsersList.paginator = this.usersPaginator
    this.filteredUsersList.sort = this.matSort
  }

  ngOnInit() {
    this.getCurrency()
    this.route.paramMap.subscribe((params) => {
      this.couponId = params.get('id') || ''
      if (this.couponId) {
        this.loadCoupon(this.couponId)
        console.log(this.couponId)
      }
    })

    this.loadCoupons()
    this.loadUsers()
    this.selectedCategoriesDataSource.data = this.selectedCategories

    this.getAllProducts() // Fetch products
    this.getCategories()
    // Fetch categories in a similar way
    this.categoriesService.getCategories().subscribe((categories: any) => {
      console.log('catt', categories) // Ensure image URLs are correct here
      if (categories) {
        this.categories = this.processCategories(categories)
        this.ELEMENT_DATAC = this.categories // Populate ELEMENT_DATAC
        this.dataSource = new MatTableDataSource<categoriesTableColumns>(this.ELEMENT_DATAC)
      }
      if (!Array.isArray(this.categories)) {
        this.categories = []
      }
    })
    setTimeout(() => {
      this.updateSelectedProductsTable()
      this.updateSelectedCategories()
    }, 500)
  }
  getCurrency() {
    this.settingsService.getCurrencySettings().subscribe((response) => {
      this.currency = response
      console.log(this.currency)
    })
  }
  updateSelectedProductsTable() {
    this.selectedProductsDataSource = new MatTableDataSource(this.selectedProducts)
  }
  updateSelectedCategories() {
    this.selectedCategoriesDataSource.data = this.selectedCategories
  }

  // Process categories similar to products
  processCategories(categories: any): categoriesTableColumns[] {
    const categoryArray: categoriesTableColumns[] = []
    for (const key in categories) {
      if (Object.prototype.hasOwnProperty.call(categories, key)) {
        const category = categories[key]
        // Log URL for debugging
        categoryArray.push({
          id: category.categoryId,
          image: category.categoryImage,
          name: category.categoryName,
          checked: false
        })
      }
      console.log(categories)
    }
    return categoryArray
  }

  /**
  loadCoupon is for the edit button so when i can get the coupon id to use it in the edit page to edit an existed coupon
  @param id which is string
  @returns null
    */
  loadCoupon(id: string) {
    this.editPage = true
    this.couponsService.getCoupon(id).subscribe((coupon) => {
      this.couponUid = id
      this.couponName = coupon.name
      this.dateInput = coupon.expirationDate
      this.discountPercentage = coupon.discountType === 'percentage' ? coupon.amount : 0
      this.discountAmount = coupon.discountType === 'specific_amount' ? coupon.amount : 0
      this.selectedType = coupon.discountType
      this.selectedCriteria = coupon.discountCriteria
      this.usageTime = coupon.usageTime
      this.usageTimes = coupon.usageTimes
      if (coupon.uses) {
        this.uses = coupon.uses
      }
      this.min = coupon.min
      this.max = coupon.max
      this.selectedProducts = coupon.selectedProducts || []
      this.selectedCategories = coupon.selectedCategories || []
      this.active = coupon.active
    })
  }
  loadUsers() {
    this.couponsService.getUsers().subscribe(
      (users: any) => {
        console.log('Fetched users:', users)

        this.users = Object.keys(users).map((key) => ({
          id: key,
          ...users[key]
        }))

        console.log('Processed users:', this.users)
        this.usersData = new MatTableDataSource<User>(this.users)
        console.log(this.usersData)
        this.getUsersList()
        this.filteredUsersList.data = [...this.usersList] // Set filteredUsersList here
      },
      (error) => {
        console.error('Error fetching users:', error)
      }
    )
  }

  loadCoupons() {
    this.couponsService.getCoupons().subscribe((coupons: any) => {
      console.log('Fetched coupons:', coupons)

      this.couponsArray = Object.keys(coupons).map((key) => ({
        id: key,
        ...coupons[key]
      }))
    })
  }
  /**
   this getAllProducts i get with it the products list so i can put it in the table and i specified a columns which is 
  ['image', 'name', 'stock', 'price', 'date'] so i can put these in the table 
  @input null
  @returns null
  */
  getAllProducts() {
    this.productsService.getProducts().subscribe((response_products: any) => {
      console.log(response_products) // Check data structure here
      this.builderService.showUpdateProgress = true
      this.productsColumns = ['image', 'name', 'stock', 'price', 'date']
      this.products = response_products
      this.ELEMENT_DATAP = []

      this.renderProducts(this.products).subscribe(() => {
        this.productsDataSource = new MatTableDataSource(this.ELEMENT_DATAP)
        this.productsDataSource.sort = this.matSort
        this.productsDataSource.paginator = this.paginator // Assign paginator

        this.builderService.showUpdateProgress = false
      })
    })
  }
  /**
   this method changes the data of the products from the database into a data that i can put in a table
  so i can make a table of the products with a real time database
  @param result 
    @returns Observable<any>
  */
  renderProducts(result: any): Observable<any> {
    return new Observable<any>((observer) => {
      this.builderService.showUpdateProgress = false
      this.products_data_counts = []
      for (const key in result) {
        if (Object.prototype.hasOwnProperty.call(result, key)) {
          const element = result[key]
          this.products_data_counts.push(element.count)
          let date: any = element.date ? String(element.date) : ''
          let price: any = element.salePrice ? element.regularPrice + ' / ' + element.salePrice : element.regularPrice
          console.log(element)
          this.ELEMENT_DATAP.push({
            id: element.Id,
            image: element.imageUrl,
            name: element.productName,
            sku: element.productSKU,
            stock: element.productStock,
            price: price,
            date: date ? date.replaceAll('/', '-') : ''
          })
        }
        console.log(this.elements)
        console.log(this.ELEMENT_DATAP)
        console.log(this.products)
      }

      this.dataSource = new MatTableDataSource<productsTableColumns>(this.ELEMENT_DATAP)
      setTimeout(() => {
        observer.next() // Notify the observer that the operation is complete
        observer.complete() // Complete the Observable
      }, 100)
    })
  }
  getCategories() {
    this.categoriesService.getCategories().subscribe((categories: any) => {
      this.ELEMENT_DATAC = this.processCategories(categories) // Process categories
      this.categoriesDataSource = new MatTableDataSource(this.ELEMENT_DATAC)
      this.categoriesDataSource.paginator = this.paginator
      this.categoriesDataSource.sort = this.matSort
    })
  }
  /**
   * this method checks if the selected discount type is'Percentage' and if it is returns boolean(true) if its not returns
   * boolean(false) so when if i choose the discount type 'Percentage' the 'Percentage' input appears.
   @params null
    @returns boolean 
  */
  isPercentage(): boolean {
    return this.selectedType === 'percentage'
  }

  /**
   * this method checks if the selected discount type is 'Specific Amount' and if it is returns boolean(true) if its not returns
   * boolean(false) so when if i choose the discount type : 'Specific Amount' the 'Specific Amount' input appears.
   @params null
    @returns boolean 
  */
  isAmount(): boolean {
    return this.selectedType === 'specific_amount'
  }
  /**
   * this method checks if the selected discount criteria is 'Fixed Product Discount' and if it is returns boolean(true) if its not returns
   * boolean(false) 
   @params null
    @returns boolean 
  */
  showProducts(): boolean {
    return this.selectedCriteria === 'fixed_products_discount'
  }
  /**
   * this method checks if the selected discount criteria is 'Fixed Category Discount' and if it is returns boolean(true) if its not returns
   * boolean(false) 
   @params null
    @returns boolean [class.hidden]="!showCategories()"
  */
  showCategories(): boolean {
    return this.selectedCriteria === 'fixed_categories_discount'
  }

  /**
   * this method save the coupon in the database so when i fill all the input fields and click (Publish) the coupon now is save in the database
   * with these properties ['name','expirationDate','amount','discountType','discountCriteria','usageTime','usageTimes','min','max']
   * and checks if there is a coupon id (when i press on edit so it get me to the route /coupons/add-coupon/:id) then i can edit it with the existed properties
   * @params formValues which got any type
   * @returns null
   */
  save(formValues: any) {
    if (formValues.min == null || formValues.max == null) {
      formValues.min = 0
      formValues.max = 0
      console.log(formValues.min, formValues.max)
    }
    let expirationDate = ''
    console.log(formValues)

    if (formValues.dateInput) {
      const date = new Date(formValues.dateInput)

      if (!isNaN(date.getTime())) {
        // Get the date parts manually to avoid time zone issues
        const year = date.getFullYear()
        const month = (date.getMonth() + 1).toString().padStart(2, '0') // Months are 0-indexed
        const day = date.getDate().toString().padStart(2, '0')

        expirationDate = `${year}-${month}-${day}`
      }
    }
    const couponName = formValues.couponName
    this.invalidationMessage = ''
    const nameExists = this.couponsArray.some((coupon) => coupon.name.toUpperCase() === couponName.toUpperCase() && coupon.id !== this.couponId)

    if (nameExists) {
      // Coupon name already exists (excluding the current coupon being edited)
      this.invalidationMessage = 'Coupon name already exists.'
      console.error('Coupon name already exists.')
      return
    }

    const coupon: Coupon = {
      name: couponName,
      expirationDate: expirationDate,
      amount: this.isPercentage() ? this.discountPercentage : this.discountAmount,
      discountType: this.selectedType as 'percentage' | 'specific_amount',
      discountCriteria: this.selectedCriteria as 'fixed_products_discount' | 'fixed_categories_discount' | 'whole_cart_discount',

      usageTime: formValues.usageTime,
      usageTimes: formValues.usageTimes,
      min: formValues.min,
      max: formValues.max,
      selectedProducts: this.selectedProducts, // Include selected products
      selectedCategories: this.selectedCategories,
      uses: this.uses,
      active: this.active
    }

    if (this.couponId) {
      this.couponsService.updateCoupon(this.couponId, coupon).subscribe(
        (response) => {
          this.router.navigate(['/coupons'])
        },
        (error) => {
          console.error('Error updating coupon:', error)
        }
      )
    } else {
      // Create new coupon
      this.couponsService.submitCoupon(coupon).subscribe(
        (response) => {
          console.log('Coupon saved successfully:', response)
          this.router.navigate(['/coupons'])
        },
        (error) => {
          console.error('Error saving coupon:', error)
        }
      )
    }
  }

  /**
   * this method i use in the (Publish) submit button so when i click on submit it checks if all the inputs are filled and if they are
   * it publish the coupon and if they arenot it doesnt publish the coupon and get the validation errors in the inputs
   * @param form which is any
   * @returns null
   */
  onSubmit(formValue: any) {
    // Check if the form is valid
    if (this.couponForm.invalid) {
      // Mark all fields as touched to show validation errors
      this.couponForm.form.markAllAsTouched()

      // Optionally, log the validation errors to the console for debugging
      console.error('Form is invalid:', this.couponForm.errors)

      // Prevent form submission if invalid
      return
    }

    // If the form is valid, proceed with saving the coupon
    this.save(formValue)
  }

  onProductSelect(product: any, event: any): void {
    const productWithType = { ...product, type: 'product' } // Add type to the product
    if (event.checked) {
      if (!this.selectedProducts.some((p) => p.name === productWithType.name)) {
        this.selectedProducts.push(productWithType)
      }
    } else {
      const index = this.selectedProducts.findIndex((p) => p.name === productWithType.name && p.type === 'product')
      if (index > -1) {
        this.selectedProducts.splice(index, 1) // Remove the product from the array
      }
    }
    this.updateSelectedProductsTable()

    console.log('Selected products:', this.selectedProducts)
  }

  onCategorySelect(category: any, event: any): void {
    const isChecked = event.checked
    const index = this.categories.findIndex((c: any) => c.name === category.name)
    if (index > -1) {
      this.categories[index].checked = isChecked
    }
    const categoryWithType = { ...category, type: 'category' }

    if (isChecked) {
      if (!this.selectedCategories.some((c) => c.name === categoryWithType.name && c.type === 'category')) {
        this.selectedCategories.push(categoryWithType)
      }
    } else {
      const catIndex = this.selectedCategories.findIndex((c) => c.name === categoryWithType.name && c.type === 'category')
      if (catIndex > -1) {
        this.selectedCategories.splice(catIndex, 1)
      }
    }
    this.updateSelectedCategories()

    console.log('Selected categories:', this.selectedCategories)
  }

  isChecked(item: any, type: 'product' | 'category'): boolean {
    if (type === 'product') {
      return this.selectedProducts.some((p) => p.name === item.name && p.type === 'product')
    } else if (type === 'category') {
      return this.selectedCategories.some((c) => c.name === item.name && c.type === 'category')
    }

    return false
  }
  filterProducts(query: string) {
    if (query) {
      const filteredProducts = this.ELEMENT_DATAP.filter((product) => product.name.toLowerCase().includes(query.toLowerCase()))
      this.productsDataSource.data = filteredProducts
    } else {
      this.productsDataSource.data = this.ELEMENT_DATAP
    }
  }

  filterCategories(queryC: string) {
    if (queryC) {
      const filteredCategories = this.ELEMENT_DATAC.filter((category) => category.name.toLowerCase().includes(queryC.toLowerCase()))
      this.categoriesDataSource.data = filteredCategories
    } else {
      this.categoriesDataSource.data = this.ELEMENT_DATAC
    }
  }
  getStock(product: any): number {
    return Number(product.stock) || 0
  }
  getUsersList() {
    this.usersList = []

    if (!this.users || this.users.length === 0) {
      console.error('No users available.')
      return
    }

    console.log('Looking for users with coupon ID:', this.couponId)

    this.users.forEach((user: any) => {
      const userCoupons = user.coupons?.usedCoupons || {}

      console.log(`User ${user.id} has used coupons:`, userCoupons)

      if (userCoupons[this.couponId]) {
        this.usersList.push(user)
      }
    })

    console.log('Users who have used the coupon:', this.usersList)
  }

  applyFilter(): void {
    const filterValue = this.searchText ? this.searchText.trim().toLowerCase() : ''
    this.filteredUsersList.filter = filterValue
  }
}
