<div class="containerWrapper">
  <div class="orderWrapper">
    <div class="itemsAndInfo">
      <!-- Order Details -->
      <div class="orderDetails">
        <h1>{{ 'Order Details' | translate }}</h1>
        <ul *ngIf="orderData?.order">
          <li>
            {{ 'Order Date' | translate }}
            <p>{{ orderData.order.date }}</p>
          </li>
          <li>
            {{ 'Order Summary' | translate }}
            <p>{{ this.currency }}{{ orderData.order.total }}</p>
          </li>
          <li>
            {{ 'Order ID' | translate }}
            <p>#{{ orderData.order.humId }}</p>
          </li>
          <li>
            {{ 'Payment Method' | translate }}
            <p>{{ orderData.order.payment_method }}</p>
          </li>
          <li>
            {{ 'Order Status' | translate }}
            <form [formGroup]="orderStatusForm" id="orderStatusForm" (ngSubmit)="saveUpdateStatus()">
              <mat-form-field appearance="outline" class="statusSelect">
                <mat-select (selectionChange)="updateStatus(true, $event)" formControlName="status">
                  <mat-option *ngFor="let status of possibleStatuses" [value]="status">{{ status }}</mat-option>
                </mat-select>
              </mat-form-field>
            </form>
          </li>
        </ul>
      </div>

      <!-- Order Tracking -->
      <div class="orderTrackingWrapper" *ngIf="this.orderService.orderStatuses.length !== 0">
        <h1>{{ 'Order Tracking' | translate }}</h1>
        <div class="statusData" [ngClass]="{ expanded: isExpanded }">
          <div *ngFor="let order of this.orderService.orderStatuses.slice().reverse(); let i = index">
            <div [class.firstLine]="i === 0" class="trackingLine">
              <div [class.first]="i === 0" [class.firstSecondCol]="(i === 5 && !isExpanded) || (i === 10 && isExpanded)" class="line"></div>
              <img [class.firstImg]="i === 0" src="assets/images/Ellipse 2059.svg" alt="" />
            </div>
            <p [class.firstP]="i === 0" class="status">{{ order.orderStatus }} :</p>
            <p [class.firstP]="i === 0" class="time">{{ order.stateChangeTime }}</p>
          </div>
        </div>
        <button class="showMore" [ngClass]="{ expanded: isExpanded }" *ngIf="this.orderService.orderStatuses.length > 10" (click)="toggleExpand()">
          <span class="material-symbols-outlined">keyboard_arrow_down</span>
          {{ isExpanded ? 'View Less' : 'View More' }}
        </button>
      </div>

      <!-- orders tabel-->
      <div class="order-items">
        <div class="tableWrapper">
          <ng-container *ngIf="orderData?.order.products !== 0">
            <div class="tableHeader">
              <h1>{{ 'Order Items' | translate }}</h1>
            </div>
            <table mat-table [dataSource]="orderData?.order.products" class="mat-elevation-z8">
              <ng-container matColumnDef="image">
                <th mat-header-cell *matHeaderCellDef class="firstTitle">{{ 'Image' | translate }}</th>
                <td mat-cell *matCellDef="let elements">
                  <div class="productImgContainer">
                    <img *ngIf="elements.imageUrl" src="{{ elements.imageUrl }}" class="productImg" alt="product-image" />
                    <img *ngIf="!elements.imageUrl" src="assets/images/empty-image.png" class="productImg" alt="product-image" />
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="product_name">
                <th mat-header-cell *matHeaderCellDef class="firstTitle">{{ 'Product Name' | translate }}</th>
                <td mat-cell *matCellDef="let elements">{{ elements.productName }}</td>
              </ng-container>

              <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef class="firstTitle">{{ 'Price' | translate }}</th>
                <td mat-cell *matCellDef="let elements">{{ this.currency }}{{ elements.regularPrice }}</td>
              </ng-container>

              <ng-container matColumnDef="sale_price">
                <th mat-header-cell *matHeaderCellDef class="firstTitle">{{ 'Sale Price' | translate }}</th>
                <td mat-cell *matCellDef="let elements">
                  <ng-container *ngIf="elements.salePrice">{{ this.currency }}{{ elements.salePrice }} </ng-container>
                  <ng-container *ngIf="!elements.salePrice">__</ng-container>
                </td>
              </ng-container>

              <ng-container matColumnDef="size">
                <th mat-header-cell *matHeaderCellDef class="firstTitle">{{ 'Size' | translate }}</th>
                <td mat-cell *matCellDef="let elements">{{ elements?.selectedVariation?.values ? elements?.selectedVariation?.values : '__' }}</td>
              </ng-container>

              <ng-container matColumnDef="quantity">
                <th mat-header-cell *matHeaderCellDef class="firstTitle">{{ 'Quantity' | translate }}</th>
                <td mat-cell *matCellDef="let elements">X{{ elements.quantity }}</td>
              </ng-container>

              <ng-container matColumnDef="total_price">
                <th mat-header-cell *matHeaderCellDef class="firstTitle">{{ 'Total Price' | translate }}</th>
                <td mat-cell *matCellDef="let elements">{{ this.currency }}{{ elements.item_finalPrice }}</td>
              </ng-container>

              <!--   <ng-container matColumnDef="email_verified">
                        <th mat-header-cell *matHeaderCellDef class="firstTitle" > {{'Email Verified' | translate }} </th>
                        <td mat-cell *matCellDef="let elements"[ngStyle]="elements.email_verified ?{'color' : '#45ae6b'} :{'color':'#de606f'}">{{elements.email_verified}}</td>
                    </ng-container>  -->

              <tr mat-header-row *matHeaderRowDef="userColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: userColumns"></tr>
            </table>
          </ng-container>
        </div>
      </div>
      <!--user & address data-->
      <div class="userDetails">
        <div class="itemsAndInfo">
          <div class="customerInfo">
            <h1>{{ 'User Info' | translate }}</h1>
            <div class="customerWrapper">
              <section *ngIf="orderData">
                <div>
                  <div class="customerDetails">
                    <div class="row-container">
                      <div class="item-container">
                        <p>{{ 'First Name' | translate }}</p>
                        <p>{{ orderData.user_data.given_name }}</p>
                      </div>
                      <div class="item-container">
                        <p>{{ 'Last Name' | translate }}</p>
                        <p>{{ orderData.user_data.family_name }}</p>
                      </div>
                    </div>
                    <div class="row-container">
                      <div class="item-container">
                        <p>{{ 'Email' | translate }}</p>
                        <p>{{ orderData?.user_data.email }}</p>
                      </div>
                    </div>

                    <div class="row-container">
                      <div class="item-container">
                        <p>{{ 'Payment Method' | translate }}</p>
                        <p>{{ orderData.order.payment_method }}</p>
                      </div>
                      <div class="item-container">
                        <p>{{ 'Join Date' | translate }}</p>
                        <p>{{ orderData?.user_data.updated_at | date: 'dd-MM-yyyy' }}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>

        <div class="itemsAndInfo">
          <div class="customerInfo">
            <h1>{{ 'Billing Address' | translate }}</h1>
            <div class="customerWrapper">
              <section *ngIf="orderData">
                <div>
                  <div class="customerDetails">
                    <div class="row-container">
                      <div class="item-container" *ngIf="selectedAddress.billing_state">
                        <p>{{ 'First Name' | translate }}</p>
                        <p>{{ selectedAddress.first_name ? selectedAddress.first_name : orderData.user_data.given_name }}</p>
                      </div>
                      <div class="item-container">
                        <p>{{ 'Last Name' | translate }}</p>
                        <p>{{ selectedAddress.last_name ? selectedAddress.last_name : orderData.user_data.family_name }}</p>
                      </div>
                    </div>
                    <div class="row-container">
                      <div class="item-container">
                        <p>{{ selectedAddress.billing_address_1 ? 'First Address' : 'Address' }}</p>
                        <p>{{ selectedAddress.billing_address_1 ? selectedAddress.billing_address_1 : selectedAddress.billing_street_No + ',' + selectedAddress.billing_street_Name }}</p>
                      </div>
                    </div>
                    <div class="row-container" *ngIf="selectedAddress.billing_address_2">
                      <div class="item-container">
                        <p>{{ 'Second Address' }}</p>
                        <p>{{ selectedAddress.billing_address_2 }}</p>
                      </div>
                    </div>

                    <div class="row-container">
                      <div class="item-container" *ngIf="selectedAddress.billing_state">
                        <p>{{ 'State' | translate }}</p>
                        <p>{{ selectedAddress.billing_state }}</p>
                      </div>
                      <div class="item-container">
                        <p>{{ 'Country' | translate }}</p>
                        <p>{{ selectedAddress.billing_country }}</p>
                      </div>
                    </div>
                    <div class="row-container">
                      <div class="item-container">
                        <p>{{ 'Country Code' | translate }}</p>
                        <p>{{ selectedAddress.country_code }}</p>
                      </div>
                      <div class="item-container">
                        <p>{{ 'Phone Number' | translate }}</p>
                        <p>{{ selectedAddress.phone_number }}</p>
                      </div>
                    </div>

                    <div class="row-container" *ngIf="selectedAddress.billing_postcode">
                      <div class="item-container">
                        <p>{{ 'Postal Code' | translate }}</p>
                        <p>{{ selectedAddress.billing_postcode }}</p>
                      </div>
                      <div class="" *ngIf="!selectedAddress.billing_address_type">
                        <button class="stacksBtn blue" (click)="editAddresses()" *ngIf="loginService.getCurrentUser().admin">Add Custom Address</button>
                      </div>
                    </div>

                    <div class="row-container" *ngIf="selectedAddress.billing_address_type">
                      <div class="item-container">
                        <p>{{ 'Address Type' | translate }}</p>
                        <p>{{ selectedAddress.billing_address_type }}</p>
                      </div>
                      <div class="">
                        <button class="stacksBtn blue" (click)="editAddresses()" *ngIf="loginService.getCurrentUser().admin && !this.customAddress">Add Custom Address</button>
                        <button class="stacksBtn blue" (click)="editAddresses()" *ngIf="loginService.getCurrentUser().admin && this.customAddress != null">Edit Custom Address</button>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>

      <div class="AddressContainer" *ngIf="editAddress || customAddress">
        <!-- Add Addresses -->
        <div class="addressInfo">
          <div class="address-header">
            <h1>{{ 'Use Custom Address' | translate }}</h1>
          </div>
          <div class="customerWrapper">
            <form [formGroup]="addressForm">
              <div class="formWrapper">
                <ul>
                  <div class="address-form" *ngIf="editAddress">
                    <p>{{ 'Address' | translate }}:</p>
                    <li>
                      <div class="row-container">
                        <mat-form-field appearance="outline">
                          <mat-label>{{ 'First Name' | translate }}</mat-label>
                          <input matInput formControlName="first_name" />
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                          <mat-label>{{ 'Last Name' | translate }}</mat-label>
                          <input matInput formControlName="last_name" />
                        </mat-form-field>
                      </div>
                    </li>

                    <li>
                      <div class="row-container">
                        <mat-form-field appearance="outline" class="country-code">
                          <mat-label>{{ 'Select Code' | translate }}</mat-label>
                          <mat-select formControlName="country_code" (selectionChange)="updateCountry($event.value)" [(value)]="addressForm.value.country_code">
                            <mat-option *ngFor="let code of countryDialCodes" [value]="code.dial_code || addressForm.controls['country_code'].value"> {{ code.emoji }} {{ code.code }} ({{ code.dial_code }}) </mat-option>
                          </mat-select>
                        </mat-form-field>
                        <mat-form-field appearance="outline">
                          <mat-label>{{ 'Phone number' | translate }}</mat-label>
                          <input
                            matInput
                            formControlName="billing_phone"
                            type="tel"
                            [class.invalid]="!addressForm.controls.billing_phone.valid && addressForm.controls.billing_phone.touched"
                            (input)="updatePhoneNumber(addressForm.controls.country_code.value, $event)"
                            [value]="addressForm.controls['phone_number'].value"
                          />
                        </mat-form-field>
                      </div>
                    </li>

                    <li>
                      <div class="row-container">
                        <ng-container>
                          <mat-form-field appearance="outline">
                            <mat-label>{{ 'Street No' | translate }}</mat-label>
                            <input matInput formControlName="billing_street_No" />
                          </mat-form-field>

                          <mat-form-field appearance="outline">
                            <mat-label>{{ 'Street Name' | translate }}</mat-label>
                            <input matInput formControlName="billing_street_Name" />
                          </mat-form-field>
                        </ng-container>
                      </div>
                    </li>
                    <li>
                      <div class="row-container">
                        <mat-form-field appearance="outline">
                          <mat-label>{{ 'Select Country' | translate }}</mat-label>
                          <mat-select formControlName="billing_country" (selectionChange)="updateCountry($event.value)" [(value)]="addressForm.value.billing_country">
                            <mat-option *ngFor="let country of shippingCountries" [value]="country.countryName">
                              {{ country.countryName }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                          <mat-label>{{ 'Select State' | translate }}</mat-label>
                          <mat-select formControlName="billing_state" (selectionChange)="updateState($event.value)" [(value)]="addressForm.value.billing_state" [disabled]="!possibleGovernates || possibleGovernates.length === 0">
                            <mat-option *ngFor="let state of possibleGovernates" [value]="state.governorateName">
                              {{ state.governorateName }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </li>
                    <li>
                      <div class="row-container">
                        <mat-form-field appearance="outline">
                          <mat-label>{{ 'Post code' | translate }}</mat-label>
                          <input matInput formControlName="billing_postcode" />
                        </mat-form-field>

                        <mat-form-field appearance="outline">
                          <mat-label>{{ 'Address Type' | translate }}</mat-label>
                          <mat-select formControlName="billing_address_type" [(value)]="addressForm.value.billing_address_type">
                            <mat-option *ngFor="let type of addressTypes" [value]="type">
                              {{ type }}
                            </mat-option>
                          </mat-select>
                        </mat-form-field>
                      </div>
                    </li>
                  </div>
                  <div class="customerDetails" *ngIf="!editAddress && customAddress">
                    <div class="row-container">
                      <div class="item-container" *ngIf="addressForm.controls.first_name.value">
                        <p>{{ 'First Name' | translate }}</p>
                        <p>{{ addressForm.controls.first_name.value }}</p>
                      </div>
                      <div class="item-container" *ngIf="addressForm.controls.last_name.value">
                        <p>{{ 'Last Name' | translate }}</p>
                        <p>{{ addressForm.controls.last_name.value }}</p>
                      </div>
                    </div>

                    <div class="row-container">
                      <div class="item-container">
                        <p>{{ 'Address' }}</p>
                        <p>{{ addressForm.controls.billing_street_No.value }},{{ addressForm.controls.billing_street_Name.value }}</p>
                      </div>
                    </div>

                    <div class="row-container">
                      <div class="item-container">
                        <p>{{ 'Country' | translate }}</p>
                        <p>{{ addressForm.controls.billing_country.value }}</p>
                      </div>
                      <div class="item-container" *ngIf="addressForm.controls.billing_state.value">
                        <p>{{ 'State' | translate }}</p>
                        <p>{{ addressForm.controls.billing_state.value }}</p>
                      </div>
                    </div>

                    <div class="row-container">
                      <div class="item-container" *ngIf="addressForm.controls.country_code.value">
                        <p>{{ 'Country Code' | translate }}</p>
                        <p>{{ addressForm.controls.country_code.value }}</p>
                      </div>
                      <div class="item-container" *ngIf="addressForm.controls.phone_number.value">
                        <p>{{ 'Phone Number' | translate }}</p>
                        <p>{{ addressForm.controls.phone_number.value }}</p>
                      </div>
                    </div>

                    <div class="row-container" *ngIf="addressForm.controls.billing_postcode.value">
                      <div class="item-container">
                        <p>{{ 'Postal Code' | translate }}</p>
                        <p>{{ addressForm.controls.billing_postcode.value }}</p>
                      </div>
                      <div class="item-container">
                        <p>{{ 'Address Type' | translate }}</p>
                        <p>{{ addressForm.controls.billing_address_type.value }}</p>
                      </div>
                    </div>
                  </div>
                </ul>
                <ul>
                  <div class="address-form" *ngIf="editAddress">
                    <p>{{ 'Notes' | translate }}:</p>
                    <li>
                      <mat-form-field appearance="outline" class="order-notes-textarea">
                        <mat-label>{{ 'Order Notes' | translate }}</mat-label>
                        <textarea matInput max-length="500" formControlName="order_notes" class="order-notes" rows="12">{{ addressForm.value.order_notes }}</textarea>
                      </mat-form-field>
                    </li>
                  </div>

                  <div class="customerDetails" *ngIf="!editAddress && customAddress">
                    <div class="row-container">
                      <div class="item-container" *ngIf="addressForm.controls.order_notes.value">
                        <p>{{ 'Order Notes' | translate }}</p>
                        <p>{{ addressForm.controls.order_notes.value }}</p>
                      </div>
                    </div>
                  </div>
                </ul>
              </div>
            </form>
            <div class="address-btn">
              <button class="stacksBtn blue" *ngIf="editAddress && !customAddress" (click)="addCustomAddress()">Save Address</button>
              <button class="stacksBtn blue" *ngIf="editAddress && customAddress" (click)="updateAddressData()">Save Address</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
