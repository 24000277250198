<div class="containerWrapper">
  <div class="general-info">
    <h1>{{ 'General Info' | translate }}</h1>
    <div class="info-row">
      <div class="info-item">
        <h2>{{ 'Email Address' | translate }}</h2>
        <p [title]="this.user?.email">{{ this.user?.email }}</p>
      </div>
      <div class="info-item">
        <h2>{{ 'First Name' | translate }}</h2>
        <p>{{ this.user?.first_name }}</p>
      </div>
      <div class="info-item">
        <h2>{{ 'Last Name' | translate }}</h2>
        <p>{{ this.user?.last_name }}</p>
      </div>
      <div class="info-item">
        <h2>{{ 'Application Name' | translate }}</h2>
        <p>{{ this.generalSettingsData?.application_name }}</p>
      </div>
      <div class="info-item">
        <h2>{{ 'Phone Number' | translate }}</h2>
        <p>{{ this.user?.billing_phone }}</p>
      </div>
    </div>
    <hr />
  </div>
  <div id="subscriptionDetails">
    <h1>{{ 'Billing' | translate }}</h1>
    <div class="info-row billingInfo">
      <div class="info-item">
        <h2>{{ 'Your Plan' | translate }}</h2>
        <p class="pricingType">{{ this.SubscriptionFlow.package?.name || 'Free Plan' }}</p>
      </div>
      <div class="info-item">
        <h2>{{ 'Plan Cycle' | translate }}</h2>
        <p class="pricingType">
          {{ this.SubscriptionFlow.package?.period || 'None' }}
        </p>
      </div>
      <div class="info-item">
        <h2>{{ 'Start Date' | translate }}</h2>
        <p>
          {{ this.SubscriptionFlow.stripe?.invoices?.lines.data[0].period?.start ? (this.SubscriptionFlow.stripe?.invoices?.lines.data[0].period.start * 1000 | date: 'MMMM d, y, h:mm:ss a') : 'None' }}
        </p>
      </div>
      <div class="info-item">
        <h2>{{ 'End Date' | translate }}</h2>
        <p>
          {{ this.SubscriptionFlow.stripe?.invoices?.lines.data[0].period?.end ? (this.SubscriptionFlow.stripe?.invoices?.lines.data[0].period.end * 1000 | date: 'MMMM d, y, h:mm:ss a') : 'None' }}
        </p>
      </div>
      <div class="info-item">
        <h2>{{ 'Total Price' | translate }}</h2>
        <p class="pricingType">{{ this.SubscriptionFlow.package?.price || '0' }}</p>
      </div>
    </div>
    <div class="billingData">
      <div class="leftBillingData">
        <mat-icon>credit_card</mat-icon>
        <h2 *ngIf="this.SubscriptionFlow.stripe?.payment_methods?.card?.last4">{{ 'MasterCard ending ****' | translate }}{{ this.SubscriptionFlow.stripe?.payment_methods?.card?.last4 }}</h2>
        <h2 *ngIf="!this.SubscriptionFlow.stripe?.payment_methods">No payment methods available.</h2>
        <button *ngIf="this.SubscriptionFlow.stripe?.subscriptions?.length !== 0" class="stacksBtn blue" (click)="this.cancelSubscription()" mat-raised-button>{{ 'cancel subscription' | translate }}</button>
      </div>
      <div class="rightBillingData">
        <button class="stacksBtn blue" (click)="billingRedirect()" mat-raised-button>{{ 'Edit billing and payment' | translate }}</button>
      </div>
    </div>
    <div class="refundData">
      <div class="leftRefundData">
        <mat-icon>refresh</mat-icon>
        <h2>{{ 'Refund Your Payment' | translate }}</h2>
        <p>{{ 'Refund requests via email are initial and subject to review per our policies before confirmation.*' | translate }}</p>
      </div>
      <div class="rightRefundData">
        <a class="stacksBtn" target="_blank" href="mailto:support@stacksmarket.co">{{ 'Request Refund' | translate }}</a>
      </div>
    </div>
    <hr />
    <app-pricing-tables></app-pricing-tables>
    <hr />
    <h1>{{ 'Invoices' | translate }}</h1>
    <p>{{ 'Invoices are generated every 24 hours and transactions are rolled into one invoice during this period.' | translate }}</p>
    <h2>{{ 'Invoices History' | translate }}</h2>
    <div *ngIf="builderService.is_premium == true">
      <div class="table-container">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <!-- Date Column -->
          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef>{{ 'Date' | translate }}</th>
            <td mat-cell *matCellDef="let invoice">
              {{ invoice.lines.data[0].period.start * 1000 | date: 'MMMM d, y, h:mm:ss a' }}
            </td>
          </ng-container>

          <!-- Status Column -->
          <ng-container matColumnDef="status">
            <th mat-header-cell *matHeaderCellDef>{{ 'Status' | translate }}</th>
            <td mat-cell *matCellDef="let invoice" class="statusColumn">
              <mat-icon class="material-icons-outlined greenIcon" *ngIf="invoice.status == 'paid'">check_circle</mat-icon>
              {{ invoice.status }}
              <a *ngIf="invoice.status == 'open'" target="_blank" href="{{ invoice.hosted_invoice_url }}" class="unpaidInvoice">
                {{ 'Pay Invoice' | translate }}
              </a>
            </td>
          </ng-container>

          <!-- Amount Paid Column -->
          <ng-container matColumnDef="amount">
            <th mat-header-cell *matHeaderCellDef>{{ 'Amount Paid' | translate }}</th>
            <td mat-cell *matCellDef="let invoice">{{ invoice.amount_paid / 100 }} {{ invoice.currency }}</td>
          </ng-container>

          <!-- Download Invoice Column -->
          <ng-container matColumnDef="download">
            <th mat-header-cell *matHeaderCellDef>{{ 'Download Invoice' | translate }}</th>
            <td mat-cell *matCellDef="let invoice" class="downloadColumn">
              <a target="_blank" href="{{ invoice.invoice_pdf }}" class="unpaidInvoice">
                <mat-icon class="material-icons-outlined greenIcon downloadIcon">cloud_download</mat-icon>
              </a>
            </td>
          </ng-container>

          <!-- Table Header & Row Definitions -->
          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
        </table>

        <!-- Paginator -->
        <mat-paginator [pageSize]="5" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
      </div>
    </div>
  </div>
</div>
