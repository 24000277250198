import { AfterViewInit, Component, Input } from '@angular/core'
import { BlockFieldsService } from 'src/app/block-services/block-fields.service'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { BlockDataService } from '../../block-services/block-data.service'

@Component({
  selector: 'block-header',
  templateUrl: './block-header.component.html',
  styleUrls: ['./block-header.component.scss']
})
export class BlockHeaderComponent implements AfterViewInit {
  previewBlock = true
  styleFields = false
  blockData: any

  previewFields = false

  blockHeaderSrc = ''
  blockHeaderBg = ''
  blockFields: any
  blockFieldsCustomPage: any

  blockId = ''

  showProgress = false
  menuIconToggleChecked = true
  searchIconToggleChecked = true
  cartIconToggleChecked = true
  sidemenuIconToggleChecked = true
  @Input() importedBlockData: any

  constructor(
    public blockDataService: BlockDataService,
    public blockFieldsService: BlockFieldsService,
    public builderService: BuilderService
  ) {
    this.blockDataService.getGlobalHeader().subscribe(() => {
      if (!this.blockId) {
        this.blockId = this.blockDataService.getUniqueId(2)
      }
      this.blockFields = this.getBlockFields(this.blockId)
      this.blockFieldsCustomPage = this.getBlockFieldsCustomPage(this.blockId)
      // let headerBlockData = this.blockDataService.getSelectedBlockData(this.blockId);
      let headerBlockData = this.blockDataService.getAllBlocksData()[0]
      if (headerBlockData && headerBlockData.data.global_header == undefined) {
        headerBlockData.data.global_header = true
      }
      if (this.builderService.globals || headerBlockData?.data?.global_header || headerBlockData?.data?.global_header == undefined) {
        this.blockData = this.builderService.globalHeader
        headerBlockData = this.builderService.globalHeader
        if (!headerBlockData) {
          headerBlockData = {
            data: ''
          }
          this.blockData = headerBlockData
        }
        this.blockFieldsService.readGlobalHeader = true
        if (headerBlockData?.data) {
          headerBlockData.data.global_header = true
        } else {
          headerBlockData.data = {
            global_header: true
          }
        }

        // console.log("Read from Global Header");
      } else {
        this.blockData = this.importedBlockData ? this.importedBlockData : this.blockFieldsService.getHeaderBlockFields()
        headerBlockData = this.blockFieldsCustomPage
        this.blockFieldsService.readGlobalHeader = headerBlockData.data?.global_header == undefined ? true : headerBlockData.data?.global_header
        // console.log("Read from Local Header");
      }
      this.blockHeaderSrc = this.blockData.data?.src

      this.blockFieldsService.toggleChecked = headerBlockData.data?.type == 'color' ? true : false
      this.blockFieldsService.disable_header = headerBlockData.data?.disable_header == undefined ? false : headerBlockData.data?.disable_header
      this.menuIconToggleChecked = headerBlockData.data?.menuIcon == undefined ? true : headerBlockData.data?.menuIcon
      this.searchIconToggleChecked = headerBlockData.data?.searchIcon == undefined ? true : headerBlockData.data?.searchIcon
      this.cartIconToggleChecked = headerBlockData.data?.cartIcon == undefined ? true : headerBlockData.data?.cartIcon
      this.sidemenuIconToggleChecked = headerBlockData.data?.sidemenuIcon == undefined ? true : headerBlockData.data?.sidemenuIcon

      this.blockFieldsService.menuIconToggleChecked = this.menuIconToggleChecked
      this.blockFieldsService.searchIconToggleChecked = this.searchIconToggleChecked
      this.blockFieldsService.cartIconToggleChecked = this.cartIconToggleChecked
      this.blockFieldsService.sidemenuIconToggleChecked = this.sidemenuIconToggleChecked

      // console.log("header menu icon",headerBlockData.data?.menuIcon, this.menuIconToggleChecked);
      // console.log(headerBlockData, this.builderService.globalHeader, this.blockFieldsCustomPage, this.blockFieldsService.readGlobalHeader);
      // console.log(this.builderService.globalHeader);
      // console.log(this.blockFieldsService.readGlobalHeader, headerBlockData.data?.global_header);
      // this.toggleChanged(this.blockId);
      // this.toggleChanged(this.blockId, 'global_header', headerBlockData.data?.global_header);
      // this.toggleChanged(this.blockId, 'disable_header');
      // if( this.builderService.globals ) {
      //   let headerBlockFields = this.blockDataService.getSelectedBlockData(this.blockId);
      //   this.builderService.globalHeader = headerBlockFields;
      // }
      let gAdMob = headerBlockData.data?.gHeaderBanners
      if (gAdMob) {
        let gAdmobHeaderFlag = false
        for (const key in gAdMob) {
          if (Object.prototype.hasOwnProperty.call(gAdMob, key)) {
            const element = gAdMob[key]
            // console.log(element);
            if (element.status == 'active') {
              gAdmobHeaderFlag = true
            }
          }
        }
        this.blockDataService.gAdMobActive = gAdmobHeaderFlag
      }
    })
  }

  ngAfterViewInit() {}

  toggleChanged(blockId: any, type: string = 'background') {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return
    }
    let headerBlockFields = this.getBlockFields(blockId)
    /**
     * Modifying Nested Object Directly doesn't work, so we have to clone the data object first then modify it
     */
    let clonedBlockFieldsData = { ...headerBlockFields.data }
    let clonedBlockFieldsStyle = { ...headerBlockFields.style }
    if (type == 'background') {
      clonedBlockFieldsData['type'] = this.blockFieldsService.toggleChecked ? 'color' : 'image'
      clonedBlockFieldsData['menuIcon'] = this.blockFieldsService.menuIconToggleChecked
      clonedBlockFieldsData['searchIcon'] = this.blockFieldsService.searchIconToggleChecked
      clonedBlockFieldsData['cartIcon'] = this.blockFieldsService.cartIconToggleChecked
      clonedBlockFieldsData['sidemenuIcon'] = this.blockFieldsService.sidemenuIconToggleChecked
    } else if (type == 'global_header') {
      clonedBlockFieldsData['global_header'] = this.blockFieldsService.readGlobalHeader ? true : false
      this.getBlockFieldsCustomPage(blockId).data.global_header = clonedBlockFieldsData['global_header']
      this.blockFields = this.getBlockFields(this.blockId)
    } else if (type == 'disable_header') {
      clonedBlockFieldsData['disable_header'] = this.blockFieldsService.disable_header ? true : false
    }

    headerBlockFields.data = clonedBlockFieldsData
    headerBlockFields.style = clonedBlockFieldsStyle
    if (this.builderService.globals) {
      this.builderService.globalHeader = headerBlockFields
    }
  }
  /**
   * Appends Block to the Preview, Initial checks if the block is added as initial block addition or appended live
   */
  addBlockToPreview(initial: any = '') {
    let blockHeaderData = {
      component: BlockHeaderComponent,
      index: 'latest',
      element: this,
      initial: initial
    }
    this.blockDataService.sendBlockData(blockHeaderData)
  }

  appendBlock() {
    let clonedBlockData = { ...this.blockData }
    this.blockId = this.blockDataService.appendBlocksData(clonedBlockData)
  }

  /**
   * Sync the clicked block on click inside the mobile preview
   */
  showData(blockId: any) {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      // show data of the last element instead
      blockId = this.blockDataService.getLatestBlockId()
    }
    this.blockFieldsService.clickedBlockId = blockId
    this.blockDataService.sendCurrentBlock(BlockHeaderComponent)
  }

  // queryChange(query: any, blockId: any, $event: any, type: string) {
  //   console.log(blockId, this.blockDataService.checkBlockAvailability(blockId));
  //   // Check Block Availability before taking action
  //   if (!this.blockDataService.checkBlockAvailability(blockId)) {
  //     return;
  //   }
  //   let productsBlockFields = this.blockDataService.getSelectedBlockData(blockId);
  //   /**
  //    * Modifying Nested Object Directly doesn't work, so we have to clone the data object first then modify it
  //    */
  //   let clonedBlockFieldsData = { ...productsBlockFields.data };
  //   let data = '';
  //   if (type == 'select') {
  //     data = $event.value
  //   } else if (type == 'text') {
  //     data = $event.currentTarget.value;
  //   } else if (type == 'slider') {
  //     data = $event.value;
  //   }

  //   clonedBlockFieldsData[query] = data;
  //   productsBlockFields.data = clonedBlockFieldsData;
  // }

  queryChange(query: any, blockId: any, $event: any, type: string, index = 0, dataType: string = 'menuItems') {
    // console.log(blockId, this.blockDataService.checkBlockAvailability(blockId), query);
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return
    }
    let headerBlockFields = this.getBlockFields(blockId)
    /**
     * Modifying Nested Object Directly doesn't work, so we have to clone the data object first then modify it
     */
    let clonedBlockFieldsData = { ...headerBlockFields.data }
    console.log(clonedBlockFieldsData)
    let data = ''
    if (type == 'select') {
      data = $event.value
    } else if (type == 'text') {
      data = $event.currentTarget.value
    } else if (type == 'slider') {
      data = $event.currentTarget.value
    } else if (type == 'multilevelText') {
      data = $event.currentTarget.value
    } else if (type == 'multilevelSelect') {
      data = $event.value
    }

    if (type == 'multilevelText' || type == 'multilevelSelect') {
      clonedBlockFieldsData[dataType][index][query] = data
    } else {
      clonedBlockFieldsData[query] = data
    }
    headerBlockFields.data = clonedBlockFieldsData
    // console.log(headerBlockFields.data);
    // this.builderService.globalHeader = headerBlockFields;
    let gAdMob = headerBlockFields.data.gHeaderBanners
    if (gAdMob) {
      let gAdmobHeaderFlag = false
      for (const key in gAdMob) {
        if (Object.prototype.hasOwnProperty.call(gAdMob, key)) {
          const element = gAdMob[key]
          // console.log(element);
          if (element.status == 'active') {
            gAdmobHeaderFlag = true
          }
        }
      }
      this.blockDataService.gAdMobActive = gAdmobHeaderFlag
    }
  }

  addGBannerItem = (blockId: string) => {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return
    }
    let headerBlockFields = this.blockDataService.getSelectedBlockData(blockId)
    /**
     * Modifying Nested Object Directly doesn't work, so we have to clone the data object first then modify it
     */
    let clonedBlockFieldsData = { ...headerBlockFields.data }
    let data: any = ''
    if (!clonedBlockFieldsData.gHeaderBanners) {
      clonedBlockFieldsData['gHeaderBanners'] = [
        {
          index: 0,
          status: 'inactive',
          bannerAdId: '',
          gHeaderBannerPage: 'Home'
        }
      ]
    } else {
      clonedBlockFieldsData.gHeaderBanners.push({
        index: clonedBlockFieldsData.gHeaderBanners.length,
        status: 'inactive',
        bannerAdId: '',
        gHeaderBannerPage: 'Home'
      })
    }

    headerBlockFields.data = clonedBlockFieldsData
  }

  hexToRgb = (hex: string) => {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16)
        }
      : null
  }

  convertHex = (hex: string, opacity: number) => {
    let rgb: any = this.hexToRgb(hex)
    if (!rgb) {
      return
    }
    return `rgba(${rgb.r},${rgb.g},${rgb.b},${opacity})`
  }

  processFile(fileInput: any, type: string, name: string, blockId: string) {
    const file: File = fileInput.target.files[0]
    const reader = new FileReader()
    this.showProgress = true
    reader.addEventListener('load', (event: any) => {
      this.builderService.uploadFileCustomerServer(file, this.builderService.selectedProject, type).subscribe(
        (res) => {
          this.showProgress = false
          let imgBlockFields = this.blockDataService.getSelectedBlockData(blockId)
          /**
           * Modifying Nested Object Directly doesn't work, so we have to clone the data object first then modify it
           */
          let clonedBlockFieldsData = { ...imgBlockFields.data }
          clonedBlockFieldsData.src = res
          imgBlockFields.data = clonedBlockFieldsData
          imgBlockFields.data.type = 'image'
          this.builderService.globalHeader = imgBlockFields
        },
        (err) => {
          this.showProgress = false
          alert('error uploading file, please contact support')
        }
      )
    })

    reader.readAsDataURL(file)
  }
  /**
   * get all the image block fields from the service and adjust the src only
   * @param $event change event on the input field
   */
  imgSrcChange($event: any, blockId: any) {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return
    }
  }

  styleChange(style: any, blockId: any, $event: any, type: string) {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return
    }
    let textBlockFields = this.getBlockFields(blockId)
    /**
     * Modifying Nested Object Directly doesn't work, so we have to clone the data object first then modify it
     */
    let clonedBlockFieldsData = { ...textBlockFields.style }
    let data = ''
    if (type == 'select') {
      data = $event.value
    } else if (type == 'text') {
      data = $event.currentTarget.value
    } else if (type == 'slider') {
      data = $event.currentTarget.value + 'px'
    }

    clonedBlockFieldsData[style] = data
    textBlockFields.style = clonedBlockFieldsData
    // this.builderService.globalHeader = textBlockFields;
  }

  getBlockFields = (id: string) => {
    if (!this.builderService.globals && !this.blockFieldsService.readGlobalHeader) {
      return this.getBlockFieldsCustomPage(id)
    }
    if (this.builderService.globalHeader == undefined) {
      this.builderService.globalHeader = this.blockDataService.tempHeader
    }
    // move the data inside this.builderService.globalHeader and add it inside this.builderService.globalHeader.data
    // if(this.builderService.globalHeader && this.builderService.globalHeader?.data?.global_header == this.builderService.globalHeader?.global_header ) {
    //   const data = { ...this.builderService.globalHeader };
    //   delete data.data;
    //   this.builderService.globalHeader.data = data;
    // }
    return this.builderService.globalHeader
  }

  getBlockFieldsCustomPage = (id: string) => {
    if (this.importedBlockData) {
      return this.importedBlockData
    }
    if (!this.blockDataService.blocksData) {
      return false
    }
    return this.blockDataService.getSelectedBlockData(id)
  }

  showStyleTab = () => {
    this.previewBlock = false
    this.previewFields = false
    this.styleFields = true
  }

  showDataTab = () => {
    this.previewBlock = false
    this.previewFields = true
    this.styleFields = false
  }

  checkSidebar = () => {
    if (!this.previewBlock && !this.previewFields && !this.styleFields) {
      return true
    }
    return false
  }

  checkPreview = () => {
    if (this.previewBlock && !this.previewFields && !this.styleFields) {
      return true
    }
    return false
  }

  checkData = () => {
    if (this.previewFields) {
      return true
    }
    return false
  }

  checkStyle = () => {
    if (this.styleFields && !this.previewFields && !this.previewBlock) {
      return true
    }
    return false
  }

  checkSettings = () => {
    if (!this.checkSidebar() && !this.checkPreview()) {
      return true
    }
    return false
  }

  deleteGBannerItem = (blockId: string, gHeaderBannerIndex: number) => {
    let menuItemsBlockFields = this.blockDataService.getSelectedBlockData(blockId)
    for (let count = 0; count < menuItemsBlockFields.data?.gHeaderBanners?.length; count++) {
      const element = menuItemsBlockFields.data?.gHeaderBanners[count]
      if (element.index == gHeaderBannerIndex) {
        menuItemsBlockFields.data.gHeaderBanners.splice(count, 1)
      }
    }
    this.reindexMenuItems(blockId)
  }

  reindexMenuItems = (blockId: string) => {
    let menuItemsBlockFields = this.blockDataService.getSelectedBlockData(blockId)
    for (let i = 0; i < menuItemsBlockFields.data?.menuItems?.length; i++) {
      menuItemsBlockFields.data.menuItems[i].index = i
    }
  }
}
