<div id="templateViewWrapper" class="containerWrapper">
  <mat-progress-bar mode="indeterminate" *ngIf="showTemplatesProgress"></mat-progress-bar>
  <div [class]="builderService.loadingTemplates ? 'overlayBG' : 'hide'">
    <div class="overlayContent">
      <img _ngcontent-olv-c289="" src="assets/images/paint-roller.gif" />
      {{ 'Applying Template' | translate }}
    </div>
  </div>
  <app-stepper *ngIf="builderService.getTour()" step="3"></app-stepper>
  <div id="titleContainer">
    <h1>{{ 'Choose Template' | googleTranslate | async | extractGoogleTranslation }}</h1>
    <div class="howItWorks noselect" (click)="howItWorksDialog()">
      <span class="material-icons-outlined">play_circle_filled</span>
      {{ 'How it works?' | googleTranslate | async | extractGoogleTranslation }}
    </div>
  </div>
  <mat-tab-group>
    <mat-tab label="First"> Content 1 </mat-tab>
    <mat-tab label="Second"> Content 2 </mat-tab>
    <mat-tab label="Third"> Content 3 </mat-tab>
  </mat-tab-group>

  <ul id="templatesList">
    <li *ngFor="let template of templatesList" (click)="selectNewTemplate(template)">
      <div class="templateImg"><img src="{{ template.img }}" alt="" /></div>
      <p>{{ template.name | googleTranslate | async | extractGoogleTranslation }}</p>
      <div class="caption-separator"></div>
    </li>
  </ul>
</div>
