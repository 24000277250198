import { Injectable } from '@angular/core'
import { getDatabase, ref, set, child, get, remove } from 'firebase/database'
import { Observable } from 'rxjs'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { DatabaseService } from 'src/app/builder-services/database.service'
import * as XLSX from 'xlsx'

@Injectable({
  providedIn: 'root'
})
export class OrdersService {
  lastIndex: number = 0
  orderStatuses: any[] = []
  orderData: any[] = []
  constructor(private builderService: BuilderService, private db: DatabaseService) {
    this.db.getDatabase('projects/' + this.builderService.selectedProject + '/lastIndex').subscribe((resp) => {
      if (resp === 99999) {
        this.lastIndex = 0
      }
    })
  }

  getOrder(order_id: string) {
    let project_id = this.builderService.selectedProject
    const dbRef = ref(getDatabase())
    return new Observable<any>((observer) => {
      get(child(dbRef, 'projects/' + project_id + '/users/'))
        .then((snapshot) => {
          if (snapshot.exists()) {
            snapshot.forEach((userSnapshot) => {
              const userKey = userSnapshot.key
              const orders = userSnapshot.child('orders')
              orders.forEach((orderSnapshot) => {
                if (orderSnapshot.key === order_id) {
                  const order = orderSnapshot.val()
                  let order_data = [
                    {
                      user_id: userKey,
                      address: Object.values(userSnapshot.val().address),
                      user_data: userSnapshot.val(),
                      order_id: order_id,
                      order: order
                    }
                  ]
                  console.log(order_data)
                  observer.next(order_data) // Notify the observer that the operation is complete
                  observer.complete() // Complete the Observable
                }
              })
            })
          } else {
            console.log('No data available')
            observer.next({})
            observer.complete() // Complete the Observable
          }
        })
        .catch((error) => {
          observer.error(error) // Notify the observer of an error
        })
    })
  }

  getOrders() {
    let project_id = this.builderService.selectedProject
    const dbRef = ref(getDatabase())
    let allOrders: any = []
    return new Observable<any>((observer) => {
      this.db.getDatabase(`projects/${project_id}/users/`).subscribe((usersData) => {
        if (!usersData) {
          observer.next([])
          observer.complete()
          return
        }

        const userEmails: { [userId: string]: string } = {}
        Object.keys(usersData).forEach((userId) => {
          const user = usersData[userId]
          userEmails[userId] = user.email || user.address?.billing_email || ''
        })

        get(child(dbRef, 'projects/' + project_id + '/users/'))
          .then((snapshot) => {
            if (snapshot.exists()) {
              snapshot.forEach((userSnapshot) => {
                const userId = userSnapshot.key as string
                const orders = userSnapshot.child('orders')
                orders.forEach((orderSnapshot) => {
                  const order = orderSnapshot.val()

                  if (order.date) {
                    allOrders.push({
                      user: userId,
                      user_data: userSnapshot.val().data,
                      user_email: userEmails[userId],
                      order_id: orderSnapshot.key,
                      ...order
                    })
                  }
                })
              })

              console.log(allOrders)
              observer.next(this.sortOrdersByDate(allOrders))
              observer.complete() // Complete the Observable}
            } else {
              console.log('No data available')
              observer.next([])
              observer.complete() // Complete the Observable
            }
          })
          .catch((error) => {
            observer.error(error) // Notify the observer of an error
          })
      })
    })
  }

  addLastIndex(lastIndex: number) {
    let project_id = this.builderService.selectedProject
    this.db.setDatabase(`projects/${project_id}/lastIndex`, lastIndex).subscribe((resp) => {})
  }

  sortOrdersByDate(allOrders: any) {
    // Sort the orders by date (assuming the date field is a string)
    const sortedOrders = allOrders.sort((a: any, b: any) => {
      // Convert the date strings to actual Date objects for comparison
      const dateA: any = this.parseDate(a.date)
      const dateB: any = this.parseDate(b.date)
      return dateA - dateB
    })
    return sortedOrders
  }

  parseDate = (dateString: string) => {
    const [day, month, year] = dateString.split('-')
    return new Date(`${year}-${month}-${day}`)
  }

  removeOrder = (order_id: string, user_id: string) => {
    let project_id = this.builderService.selectedProject
    const db = getDatabase()
    return new Observable<any>((observer) => {
      remove(ref(db, `projects/${project_id}/users/${user_id}/orders/${order_id}`))
        .then(() => {
          // Data remove successfully!
          observer.next({})
          observer.complete()
        })
        .catch((error) => {
          observer.next({})
          observer.complete()
        })
    })
  }
  // gets the current time
  getCurrentTime() {
    let currentTime = new Date().toLocaleString('en-US', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
      hour: 'numeric',
      minute: '2-digit',
      hour12: true
    })
    return currentTime
  }

  updateOrderStatus = (order_id: string, status: string, user_id: string) => {
    let project_id = this.builderService.selectedProject
    // gets the current time & pushing the object inside the orderstatuses array
    let currentTime = this.getCurrentTime()

    this.orderStatuses.push({
      orderStatus: status,
      stateChangeTime: currentTime
    })
    const db = getDatabase()
    return new Observable<any>((observer) => {
      set(ref(db, `projects/${project_id}/users/${user_id}/orders/${order_id}/status`), status)
        .then(() => {
          this.db.setDatabase(`projects/${project_id}/users/${user_id}/orders/${order_id}/orderStatuses`, this.orderStatuses).subscribe((resp) => {})
          // Data saved successfully!
          observer.next({})
          observer.complete()
        })
        .catch((error) => {
          // The write failed...
          observer.next({})
          observer.complete()
        })
    })
  }
  public updateAddress(addressId: any, user_id: any, updatesAddress: any) {
    let project_id = this.builderService.selectedProject
    return new Observable<any>((observer) => {
      this.db.setDatabase('projects/' + project_id + '/users/' + user_id + `/address/${addressId}`, updatesAddress).subscribe((data: any) => {
        observer.next(data)
        observer.complete()
      })
    })
  }
  public addAddress(user_id: string, address: any) {
    let project_id = this.builderService.selectedProject
    return new Observable<any>((observer) => {
      this.db.setDatabase('projects/' + project_id + '/users/' + user_id + `/address/`, address, true).subscribe((data: any) => {
        observer.next(address)
        observer.complete()
      })
    })
  }

  public getShipping = () => {
    let project_id = this.builderService.selectedProject
    return new Observable<any>((observer) => {
      this.db.getDatabase('projects/' + project_id + '/shipping/').subscribe((shipping: any) => {
        observer.next(shipping)
        observer.complete()
      })
    })
  }

  exportOrdersToExcel = (orders: any[]) => {
    // if there is no orders
    if (!orders || orders.length === 0) {
      console.warn('No orders to export')
      return
    }

    // Function to convert any value to a string representation
    const formatValue = (value: any): string => {
      if (value === null || value === undefined) {
        return ''
      }

      // Special handling for products array
      if (Array.isArray(value) && value.some((item) => typeof item === 'object' && ('productName' in item || 'quantity' in item || 'regularPrice' in item))) {
        // This is likely a products array
        return value
          .map((product) => {
            const productName = product.productName || ''
            const regularPrice = product.regularPrice
              ? typeof product.regularPrice === 'object'
                ? Object.entries(product.regularPrice)
                    .map(([key, val]) => `${key}: ${val}`)
                    .join(', ')
                : product.regularPrice
              : ''
            const quantity = product.quantity || ''

            const selectedVariation = product.selectedVariation ? Object.entries(product.selectedVariation) : []
            if (selectedVariation.length > 0) {
              return `productName: ${productName} | regularPrice: ${regularPrice} | Qty: ${quantity} | Variation: ${selectedVariation.map(([key, val]) => `${key}: ${val}`).join(', ')}`
            }
            return `productName: ${productName} | regularPrice: ${regularPrice} | Qty: ${quantity}`
          })
          .join(' || ')
      }
      if (Array.isArray(value)) {
        return value
          .map((item) => {
            if (typeof item === 'object') {
              return Object.entries(item)
                .map(([key, val]) => `${key}: ${val}`)
                .join(', ')
            }
            return String(item)
          })
          .join(' | ')
      }
      if (typeof value === 'object') {
        return Object.entries(value)
          .map(([key, val]) => `${key}: ${formatValue(val)}`)
          .join(', ')
      }
      return String(value)
    }

    // Get all unique keys from all orders
    const allKeys = new Set<string>()
    orders.forEach((order) => {
      Object.keys(order).forEach((key) => allKeys.add(key))
    })

    // Transform orders data dynamically
    const exportData = orders.map((order) => {
      const formattedOrder: { [key: string]: string } = {}
      allKeys.forEach((key) => {
        formattedOrder[key] = formatValue(order[key])
      })
      return formattedOrder
    })

    // Create worksheet
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData)

    // Auto-size columns based on content
    const maxLength = 50 // Maximum column width
    const columnWidths = Array.from(allKeys).map((key) => ({
      wch: Math.min(Math.max(key.length, ...exportData.map((row) => String(row[key]).length)), maxLength)
    }))
    worksheet['!cols'] = columnWidths

    // Create workbook
    const workbook: XLSX.WorkBook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Orders')

    // Generating filename with current date
    const date = new Date()
    const filename = `orders_export_${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}.xlsx`

    // Generating and downloading Excel file
    XLSX.writeFile(workbook, filename)
  }

  countryDialCodes = [
    {
      name: 'Egypt',
      dial_code: '+20',
      emoji: '🇪🇬',
      code: 'EG'
    },
    {
      name: 'Afghanistan',
      dial_code: '+93',
      emoji: '🇦🇫',
      code: 'AF'
    },
    {
      name: 'Aland Islands',
      dial_code: '+358',
      emoji: '🇦🇽',
      code: 'AX'
    },
    {
      name: 'Albania',
      dial_code: '+355',
      emoji: '🇦🇱',
      code: 'AL'
    },
    {
      name: 'Algeria',
      dial_code: '+213',
      emoji: '🇩🇿',
      code: 'DZ'
    },
    {
      name: 'AmericanSamoa',
      dial_code: '+1684',
      emoji: '🇦🇸',
      code: 'AS'
    },
    {
      name: 'Andorra',
      dial_code: '+376',
      emoji: '🇦🇩',
      code: 'AD'
    },
    {
      name: 'Angola',
      dial_code: '+244',
      emoji: '🇦🇴',
      code: 'AO'
    },
    {
      name: 'Anguilla',
      dial_code: '+1264',
      emoji: '🇦🇮',
      code: 'AI'
    },
    {
      name: 'Antarctica',
      dial_code: '+672',
      emoji: '🇦🇶',
      code: 'AQ'
    },
    {
      name: 'Antigua and Barbuda',
      dial_code: '+1268',
      emoji: '🇦🇬',
      code: 'AG'
    },
    {
      name: 'Argentina',
      dial_code: '+54',
      emoji: '🇦🇷',
      code: 'AR'
    },
    {
      name: 'Armenia',
      dial_code: '+374',
      emoji: '🇦🇲',
      code: 'AM'
    },
    {
      name: 'Aruba',
      dial_code: '+297',
      emoji: '🇦🇼',
      code: 'AW'
    },
    {
      name: 'Australia',
      dial_code: '+61',
      emoji: '🇦🇺',
      code: 'AU'
    },
    {
      name: 'Austria',
      dial_code: '+43',
      emoji: '🇦🇹',
      code: 'AT'
    },
    {
      name: 'Azerbaijan',
      dial_code: '+994',
      emoji: '🇦🇿',
      code: 'AZ'
    },
    {
      name: 'Bahamas',
      dial_code: '+1242',
      emoji: '🇧🇸',
      code: 'BS'
    },
    {
      name: 'Bahrain',
      dial_code: '+973',
      emoji: '🇧🇭',
      code: 'BH'
    },
    {
      name: 'Bangladesh',
      dial_code: '+880',
      emoji: '🇧🇩',
      code: 'BD'
    },
    {
      name: 'Barbados',
      dial_code: '+1246',
      emoji: '🇧🇧',
      code: 'BB'
    },
    {
      name: 'Belarus',
      dial_code: '+375',
      emoji: '🇧🇾',
      code: 'BY'
    },
    {
      name: 'Belgium',
      dial_code: '+32',
      emoji: '🇧🇪',
      code: 'BE'
    },
    {
      name: 'Belize',
      dial_code: '+501',
      emoji: '🇧🇿',
      code: 'BZ'
    },
    {
      name: 'Benin',
      dial_code: '+229',
      emoji: '🇧🇯',
      code: 'BJ'
    },
    {
      name: 'Bermuda',
      dial_code: '+1441',
      emoji: '🇧🇲',
      code: 'BM'
    },
    {
      name: 'Bhutan',
      dial_code: '+975',
      emoji: '🇧🇹',
      code: 'BT'
    },
    {
      name: 'Bolivia, Plurinational State of',
      dial_code: '+591',
      emoji: '🇧🇴',
      code: 'BO'
    },
    {
      name: 'Bosnia and Herzegovina',
      dial_code: '+387',
      emoji: '🇧🇦',
      code: 'BA'
    },
    {
      name: 'Botswana',
      dial_code: '+267',
      emoji: '🇧🇼',
      code: 'BW'
    },
    {
      name: 'Brazil',
      dial_code: '+55',
      emoji: '🇧🇷',
      code: 'BR'
    },
    {
      name: 'British Indian Ocean Territory',
      dial_code: '+246',
      emoji: '🇮🇴',
      code: 'IO'
    },
    {
      name: 'Brunei Darussalam',
      dial_code: '+673',
      emoji: '🇧🇳',
      code: 'BN'
    },
    {
      name: 'Bulgaria',
      dial_code: '+359',
      emoji: '🇧🇬',
      code: 'BG'
    },
    {
      name: 'Burkina Faso',
      dial_code: '+226',
      emoji: '🇧🇫',
      code: 'BF'
    },
    {
      name: 'Burundi',
      dial_code: '+257',
      emoji: '🇧🇮',
      code: 'BI'
    },
    {
      name: 'Cambodia',
      dial_code: '+855',
      emoji: '🇰🇭',
      code: 'KH'
    },
    {
      name: 'Cameroon',
      dial_code: '+237',
      emoji: '🇨🇲',
      code: 'CM'
    },
    {
      name: 'Canada',
      dial_code: '+1',
      emoji: '🇨🇦',
      code: 'CA'
    },
    {
      name: 'Cape Verde',
      dial_code: '+238',
      emoji: '🇨🇻',
      code: 'CV'
    },
    {
      name: 'Cayman Islands',
      dial_code: '+345',
      emoji: '🇰🇾',
      code: 'KY'
    },
    {
      name: 'Central African Republic',
      dial_code: '+236',
      emoji: '🇨🇫',
      code: 'CF'
    },
    {
      name: 'Chad',
      dial_code: '+235',
      emoji: '🇹🇩',
      code: 'TD'
    },
    {
      name: 'Chile',
      dial_code: '+56',
      emoji: '🇨🇱',
      code: 'CL'
    },
    {
      name: 'China',
      dial_code: '+86',
      emoji: '🇨🇳',
      code: 'CN'
    },
    {
      name: 'Christmas Island',
      dial_code: '+61',
      emoji: '🇨🇽',
      code: 'CX'
    },
    {
      name: 'Cocos (Keeling) Islands',
      dial_code: '+61',
      emoji: '🇨🇨',
      code: 'CC'
    },
    {
      name: 'Colombia',
      dial_code: '+57',
      emoji: '🇨🇴',
      code: 'CO'
    },
    {
      name: 'Comoros',
      dial_code: '+269',
      emoji: '🇰🇲',
      code: 'KM'
    },
    {
      name: 'Congo',
      dial_code: '+242',
      emoji: '🇨🇬',
      code: 'CG'
    },
    {
      name: 'Congo, The Democratic Republic of the Congo',
      dial_code: '+243',
      emoji: '🇨🇩',
      code: 'CD'
    },
    {
      name: 'Cook Islands',
      dial_code: '+682',
      emoji: '🇨🇰',
      code: 'CK'
    },
    {
      name: 'Costa Rica',
      dial_code: '+506',
      emoji: '🇨🇷',
      code: 'CR'
    },
    {
      name: "Cote d'Ivoire",
      dial_code: '+225',
      emoji: '🇨🇮',
      code: 'CI'
    },
    {
      name: 'Croatia',
      dial_code: '+385',
      emoji: '🇭🇷',
      code: 'HR'
    },
    {
      name: 'Cuba',
      dial_code: '+53',
      emoji: '🇨🇺',
      code: 'CU'
    },
    {
      name: 'Cyprus',
      dial_code: '+357',
      emoji: '🇨🇾',
      code: 'CY'
    },
    {
      name: 'Czech Republic',
      dial_code: '+420',
      emoji: '🇨🇿',
      code: 'CZ'
    },
    {
      name: 'Denmark',
      dial_code: '+45',
      emoji: '🇩🇰',
      code: 'DK'
    },
    {
      name: 'Djibouti',
      dial_code: '+253',
      emoji: '🇩🇯',
      code: 'DJ'
    },
    {
      name: 'Dominica',
      dial_code: '+1767',
      emoji: '🇩🇲',
      code: 'DM'
    },
    {
      name: 'Dominican Republic',
      dial_code: '+1849',
      emoji: '🇩🇴',
      code: 'DO'
    },
    {
      name: 'Ecuador',
      dial_code: '+593',
      emoji: '🇪🇨',
      code: 'EC'
    },
    {
      name: 'El Salvador',
      dial_code: '+503',
      emoji: '🇸🇻',
      code: 'SV'
    },
    {
      name: 'Equatorial Guinea',
      dial_code: '+240',
      emoji: '🇬🇶',
      code: 'GQ'
    },
    {
      name: 'Eritrea',
      dial_code: '+291',
      emoji: '🇪🇷',
      code: 'ER'
    },
    {
      name: 'Estonia',
      dial_code: '+372',
      emoji: '🇪🇪',
      code: 'EE'
    },
    {
      name: 'Ethiopia',
      dial_code: '+251',
      emoji: '🇪🇹',
      code: 'ET'
    },
    {
      name: 'Falkland Islands (Malvinas)',
      dial_code: '+500',
      emoji: '🇫🇰',
      code: 'FK'
    },
    {
      name: 'Faroe Islands',
      dial_code: '+298',
      emoji: '🇫🇴',
      code: 'FO'
    },
    {
      name: 'Fiji',
      dial_code: '+679',
      emoji: '🇫🇯',
      code: 'FJ'
    },
    {
      name: 'Finland',
      dial_code: '+358',
      emoji: '🇫🇮',
      code: 'FI'
    },
    {
      name: 'France',
      dial_code: '+33',
      emoji: '🇫🇷',
      code: 'FR'
    },
    {
      name: 'French Guiana',
      dial_code: '+594',
      emoji: '🇬🇫',
      code: 'GF'
    },
    {
      name: 'French Polynesia',
      dial_code: '+689',
      emoji: '🇵🇫',
      code: 'PF'
    },
    {
      name: 'Gabon',
      dial_code: '+241',
      emoji: '🇬🇦',
      code: 'GA'
    },
    {
      name: 'Gambia',
      dial_code: '+220',
      emoji: '🇬🇲',
      code: 'GM'
    },
    {
      name: 'Georgia',
      dial_code: '+995',
      emoji: '🇬🇪',
      code: 'GE'
    },
    {
      name: 'Germany',
      dial_code: '+49',
      emoji: '🇩🇪',
      code: 'DE'
    },
    {
      name: 'Ghana',
      dial_code: '+233',
      emoji: '🇬🇭',
      code: 'GH'
    },
    {
      name: 'Gibraltar',
      dial_code: '+350',
      emoji: '🇬🇮',
      code: 'GI'
    },
    {
      name: 'Greece',
      dial_code: '+30',
      emoji: '🇬🇷',
      code: 'GR'
    },
    {
      name: 'Greenland',
      dial_code: '+299',
      emoji: '🇬🇱',
      code: 'GL'
    },
    {
      name: 'Grenada',
      dial_code: '+1473',
      emoji: '🇬🇩',
      code: 'GD'
    },
    {
      name: 'Guadeloupe',
      dial_code: '+590',
      emoji: '🇬🇵',
      code: 'GP'
    },
    {
      name: 'Guam',
      dial_code: '+1671',
      emoji: '🇬🇺',
      code: 'GU'
    },
    {
      name: 'Guatemala',
      dial_code: '+502',
      emoji: '🇬🇹',
      code: 'GT'
    },
    {
      name: 'Guernsey',
      dial_code: '+44',
      emoji: '🇬🇬',
      code: 'GG'
    },
    {
      name: 'Guinea',
      dial_code: '+224',
      emoji: '🇬🇳',
      code: 'GN'
    },
    {
      name: 'Guinea-Bissau',
      dial_code: '+245',
      emoji: '🇬🇼',
      code: 'GW'
    },
    {
      name: 'Guyana',
      dial_code: '+595',
      emoji: '🇬🇾',
      code: 'GY'
    },
    {
      name: 'Haiti',
      dial_code: '+509',
      emoji: '🇭🇹',
      code: 'HT'
    },
    {
      name: 'Holy See (Vatican City State)',
      dial_code: '+379',
      emoji: '🇻🇦',
      code: 'VA'
    },
    {
      name: 'Honduras',
      dial_code: '+504',
      emoji: '🇭🇳',
      code: 'HN'
    },
    {
      name: 'Hong Kong',
      dial_code: '+852',
      emoji: '🇭🇰',
      code: 'HK'
    },
    {
      name: 'Hungary',
      dial_code: '+36',
      emoji: '🇭🇺',
      code: 'HU'
    },
    {
      name: 'Iceland',
      dial_code: '+354',
      emoji: '🇮🇸',
      code: 'IS'
    },
    {
      name: 'India',
      dial_code: '+91',
      emoji: '🇮🇳',
      code: 'IN'
    },
    {
      name: 'Indonesia',
      dial_code: '+62',
      emoji: '🇮🇩',
      code: 'ID'
    },
    {
      name: 'Iran, Islamic Republic of Persian Gulf',
      dial_code: '+98',
      emoji: '🇮🇷',
      code: 'IR'
    },
    {
      name: 'Iraq',
      dial_code: '+964',
      emoji: '🇮🇷',
      code: 'IQ'
    },
    {
      name: 'Ireland',
      dial_code: '+353',
      emoji: '🇮🇪',
      code: 'IE'
    },
    {
      name: 'Isle of Man',
      dial_code: '+44',
      emoji: '🇮🇲',
      code: 'IM'
    },
    {
      name: 'Israel',
      dial_code: '+972',
      emoji: '🇮🇱',
      code: 'IL'
    },
    {
      name: 'Italy',
      dial_code: '+39',
      emoji: '🇮🇹',
      code: 'IT'
    },
    {
      name: 'Jamaica',
      dial_code: '+1876',
      emoji: '🇯🇲',
      code: 'JM'
    },
    {
      name: 'Japan',
      dial_code: '+81',
      emoji: '🇯🇵',
      code: 'JP'
    },
    {
      name: 'Jersey',
      dial_code: '+44',
      emoji: '🇯🇪',
      code: 'JE'
    },
    {
      name: 'Jordan',
      dial_code: '+962',
      emoji: '🇯🇴',
      code: 'JO'
    },
    {
      name: 'Kazakhstan',
      dial_code: '+77',
      emoji: '🇰🇿',
      code: 'KZ'
    },
    {
      name: 'Kenya',
      dial_code: '+254',
      emoji: '🇰🇪',
      code: 'KE'
    },
    {
      name: 'Kiribati',
      dial_code: '+686',
      emoji: '🇰🇮',
      code: 'KI'
    },
    {
      name: "Korea, Democratic People's Republic of Korea",
      dial_code: '+850',
      emoji: '🇰🇵',
      code: 'KP'
    },
    {
      name: 'Korea, Republic of South Korea',
      dial_code: '+82',
      emoji: '🇰🇷',
      code: 'KR'
    },
    {
      name: 'Kuwait',
      dial_code: '+965',
      emoji: '🇰🇼',
      code: 'KW'
    },
    {
      name: 'Kyrgyzstan',
      dial_code: '+996',
      emoji: '🇰🇬',
      code: 'KG'
    },
    {
      name: 'Laos',
      dial_code: '+856',
      emoji: '🇱🇦',
      code: 'LA'
    },
    {
      name: 'Latvia',
      dial_code: '+371',
      emoji: '🇱🇻',
      code: 'LV'
    },
    {
      name: 'Lebanon',
      dial_code: '+961',
      emoji: '🇱🇧',
      code: 'LB'
    },
    {
      name: 'Lesotho',
      dial_code: '+266',
      emoji: '🇱🇸',
      code: 'LS'
    },
    {
      name: 'Liberia',
      dial_code: '+231',
      emoji: '🇱🇷',
      code: 'LR'
    },
    {
      name: 'Libyan Arab Jamahiriya',
      dial_code: '+218',
      emoji: '🇱🇾',
      code: 'LY'
    },
    {
      name: 'Liechtenstein',
      dial_code: '+423',
      emoji: '🇱🇮',
      code: 'LI'
    },
    {
      name: 'Lithuania',
      dial_code: '+370',
      emoji: '🇱🇹',
      code: 'LT'
    },
    {
      name: 'Luxembourg',
      dial_code: '+352',
      emoji: '🇱🇺',
      code: 'LU'
    },
    {
      name: 'Macao',
      dial_code: '+853',
      emoji: '🇲🇴',
      code: 'MO'
    },
    {
      name: 'Macedonia',
      dial_code: '+389',
      emoji: '🇲🇰',
      code: 'MK'
    },
    {
      name: 'Madagascar',
      dial_code: '+261',
      emoji: '🇲🇬',
      code: 'MG'
    },
    {
      name: 'Malawi',
      dial_code: '+265',
      emoji: '🇲🇼',
      code: 'MW'
    },
    {
      name: 'Malaysia',
      dial_code: '+60',
      emoji: '🇲🇾',
      code: 'MY'
    },
    {
      name: 'Maldives',
      dial_code: '+960',
      emoji: '🇲🇻',
      code: 'MV'
    },
    {
      name: 'Mali',
      dial_code: '+223',
      emoji: '🇲🇱',
      code: 'ML'
    },
    {
      name: 'Malta',
      dial_code: '+356',
      emoji: '🇲🇹',
      code: 'MT'
    },
    {
      name: 'Marshall Islands',
      dial_code: '+692',
      emoji: '🇲🇭',
      code: 'MH'
    },
    {
      name: 'Martinique',
      dial_code: '+596',
      emoji: '🇲🇶',
      code: 'MQ'
    },
    {
      name: 'Mauritania',
      dial_code: '+222',
      emoji: '🇲🇷',
      code: 'MR'
    },
    {
      name: 'Mauritius',
      dial_code: '+230',
      emoji: '🇲🇺',
      code: 'MU'
    },
    {
      name: 'Mayotte',
      dial_code: '+262',
      emoji: '🇾🇹',
      code: 'YT'
    },
    {
      name: 'Mexico',
      dial_code: '+52',
      emoji: '🇲🇽',
      code: 'MX'
    },
    {
      name: 'Micronesia, Federated States of Micronesia',
      dial_code: '+691',
      emoji: '🇫🇲',
      code: 'FM'
    },
    {
      name: 'Moldova',
      dial_code: '+373',
      emoji: '🇲🇩',
      code: 'MD'
    },
    {
      name: 'Monaco',
      dial_code: '+377',
      emoji: '🇲🇨',
      code: 'MC'
    },
    {
      name: 'Mongolia',
      dial_code: '+976',
      emoji: '🇲🇳',
      code: 'MN'
    },
    {
      name: 'Montenegro',
      dial_code: '+382',
      emoji: '🇲🇪',
      code: 'ME'
    },
    {
      name: 'Montserrat',
      dial_code: '+1664',
      emoji: '🇲🇸',
      code: 'MS'
    },
    {
      name: 'Morocco',
      dial_code: '+212',
      emoji: '🇲🇦',
      code: 'MA'
    },
    {
      name: 'Mozambique',
      dial_code: '+258',
      emoji: '🇲🇿',
      code: 'MZ'
    },
    {
      name: 'Myanmar',
      dial_code: '+95',
      emoji: '🇲🇲',
      code: 'MM'
    },
    {
      name: 'Namibia',
      emoji: '🇳🇦',
      dial_code: '+264',
      code: 'NA'
    },
    {
      name: 'Nauru',
      dial_code: '+674',
      emoji: '🇳🇷',
      code: 'NR'
    },
    {
      name: 'Nepal',
      dial_code: '+977',
      emoji: '🇳🇵',
      code: 'NP'
    },
    {
      name: 'Netherlands',
      dial_code: '+31',
      emoji: '🇳🇱',
      code: 'NL'
    },
    {
      name: 'Netherlands Antilles',
      dial_code: '+599',
      emoji: '🇧🇶',
      code: 'AN'
    },
    {
      name: 'New Caledonia',
      dial_code: '+687',
      emoji: '🇳🇨',
      code: 'NC'
    },
    {
      name: 'New Zealand',
      dial_code: '+64',
      emoji: '🇳🇿',
      code: 'NZ'
    },
    {
      name: 'Nicaragua',
      dial_code: '+505',
      emoji: '🇳🇮',
      code: 'NI'
    },
    {
      name: 'Niger',
      dial_code: '+227',
      emoji: '🇳🇪',
      code: 'NE'
    },
    {
      name: 'Nigeria',
      dial_code: '+234',
      emoji: '🇳🇬',
      code: 'NG'
    },
    {
      name: 'Niue',
      dial_code: '+683',
      emoji: '🇳🇺',
      code: 'NU'
    },
    {
      name: 'Norfolk Island',
      dial_code: '+672',
      emoji: '🇳🇫',
      code: 'NF'
    },
    {
      name: 'Northern Mariana Islands',
      dial_code: '+1670',
      emoji: '🇲🇵',
      code: 'MP'
    },
    {
      name: 'Norway',
      dial_code: '+47',
      emoji: '🇳🇴',
      code: 'NO'
    },
    {
      name: 'Oman',
      dial_code: '+968',
      emoji: '🇴🇲',
      code: 'OM'
    },
    {
      name: 'Pakistan',
      dial_code: '+92',
      emoji: '🇵🇰',
      code: 'PK'
    },
    {
      name: 'Palau',
      dial_code: '+680',
      emoji: '🇵🇼',
      code: 'PW'
    },
    {
      name: 'Palestinian Territory, Occupied',
      dial_code: '+970',
      emoji: '🇵🇸',
      code: 'PS'
    },
    {
      name: 'Panama',
      dial_code: '+507',
      emoji: '🇵🇦',
      code: 'PA'
    },
    {
      name: 'Papua New Guinea',
      dial_code: '+675',
      emoji: '🇵🇬',
      code: 'PG'
    },
    {
      name: 'Paraguay',
      dial_code: '+595',
      emoji: '🇵🇾',
      code: 'PY'
    },
    {
      name: 'Peru',
      dial_code: '+51',
      emoji: '🇵🇪',
      code: 'PE'
    },
    {
      name: 'Philippines',
      dial_code: '+63',
      emoji: '🇵🇭',
      code: 'PH'
    },
    {
      name: 'Pitcairn',
      dial_code: '+872',
      emoji: '🇵🇳',
      code: 'PN'
    },
    {
      name: 'Poland',
      dial_code: '+48',
      emoji: '🇵🇱',
      code: 'PL'
    },
    {
      name: 'Portugal',
      dial_code: '+351',
      emoji: '🇵🇹',
      code: 'PT'
    },
    {
      name: 'Puerto Rico',
      dial_code: '+1939',
      emoji: '🇵🇷',
      code: 'PR'
    },
    {
      name: 'Qatar',
      dial_code: '+974',
      emoji: '🇶🇦',
      code: 'QA'
    },
    {
      name: 'Romania',
      dial_code: '+40',
      emoji: '🇷🇴',
      code: 'RO'
    },
    {
      name: 'Russia',
      dial_code: '+7',
      emoji: '🇷🇺',
      code: 'RU'
    },
    {
      name: 'Rwanda',
      dial_code: '+250',
      emoji: '🇷🇼',
      code: 'RW'
    },
    {
      name: 'Reunion',
      dial_code: '+262',
      emoji: '🇷🇪',
      code: 'RE'
    },
    {
      name: 'Saint Barthelemy',
      dial_code: '+590',
      emoji: '🇧🇱',
      code: 'BL'
    },
    {
      name: 'Saint Helena, Ascension and Tristan Da Cunha',
      dial_code: '+290',
      emoji: '🇸🇭',
      code: 'SH'
    },
    {
      name: 'Saint Kitts and Nevis',
      dial_code: '+1869',
      emoji: '🇰🇳',
      code: 'KN'
    },
    {
      name: 'Saint Lucia',
      dial_code: '+1758',
      emoji: '🇱🇨',
      code: 'LC'
    },
    {
      name: 'Saint Martin',
      dial_code: '+590',
      emoji: '🇲🇫',
      code: 'MF'
    },
    {
      name: 'Saint Pierre and Miquelon',
      dial_code: '+508',
      emoji: '🇵🇲',
      code: 'PM'
    },
    {
      name: 'Saint Vincent and the Grenadines',
      dial_code: '+1784',
      emoji: '🇻🇨',
      code: 'VC'
    },
    {
      name: 'Samoa',
      dial_code: '+685',
      emoji: '🇼🇸',
      code: 'WS'
    },
    {
      name: 'San Marino',
      dial_code: '+378',
      emoji: '🇸🇲',
      code: 'SM'
    },
    {
      name: 'Sao Tome and Principe',
      dial_code: '+239',
      emoji: '🇸🇹',
      code: 'ST'
    },
    {
      name: 'Saudi Arabia',
      dial_code: '+966',
      emoji: '🇸🇦',
      code: 'SA'
    },
    {
      name: 'Senegal',
      dial_code: '+221',
      emoji: '🇸🇳',
      code: 'SN'
    },
    {
      name: 'Serbia',
      dial_code: '+381',
      emoji: '🇷🇸',
      code: 'RS'
    },
    {
      name: 'Seychelles',
      dial_code: '+248',
      emoji: '🇸🇨',
      code: 'SC'
    },
    {
      name: 'Sierra Leone',
      dial_code: '+232',
      emoji: '🇸🇱',
      code: 'SL'
    },
    {
      name: 'Singapore',
      dial_code: '+65',
      emoji: '🇸🇬',
      code: 'SG'
    },
    {
      name: 'Slovakia',
      dial_code: '+421',
      emoji: '🇸🇰',
      code: 'SK'
    },
    {
      name: 'Slovenia',
      dial_code: '+386',
      emoji: '🇸🇮',
      code: 'SI'
    },
    {
      name: 'Solomon Islands',
      dial_code: '+677',
      emoji: '🇸🇧',
      code: 'SB'
    },
    {
      name: 'Somalia',
      dial_code: '+252',
      emoji: '🇸🇴',
      code: 'SO'
    },
    {
      name: 'South Africa',
      dial_code: '+27',
      emoji: '🇿🇦',
      code: 'ZA'
    },
    {
      name: 'South Sudan',
      dial_code: '+211',
      emoji: '🇸🇸',
      code: 'SS'
    },
    {
      name: 'South Georgia and the South Sandwich Islands',
      dial_code: '+500',
      emoji: '🇬🇸',
      code: 'GS'
    },
    {
      name: 'Spain',
      dial_code: '+34',
      emoji: '🇪🇸',
      code: 'ES'
    },
    {
      name: 'Sri Lanka',
      dial_code: '+94',
      emoji: '🇱🇰',
      code: 'LK'
    },
    {
      name: 'Sudan',
      dial_code: '+249',
      emoji: '🇸🇩',
      code: 'SD'
    },
    {
      name: 'Suriname',
      dial_code: '+597',
      emoji: '🇸🇷',
      code: 'SR'
    },
    {
      name: 'Svalbard and Jan Mayen',
      dial_code: '+47',
      emoji: '🇸🇯',
      code: 'SJ'
    },
    {
      name: 'Swaziland',
      dial_code: '+268',
      emoji: '🇸🇿',
      code: 'SZ'
    },
    {
      name: 'Sweden',
      dial_code: '+46',
      emoji: '🇸🇪',
      code: 'SE'
    },
    {
      name: 'Switzerland',
      dial_code: '+41',
      emoji: '🇨🇭',
      code: 'CH'
    },
    {
      name: 'Syrian Arab Republic',
      dial_code: '+963',
      emoji: '🇸🇾',
      code: 'SY'
    },
    {
      name: 'Taiwan',
      dial_code: '+886',
      emoji: '🇹🇼',
      code: 'TW'
    },
    {
      name: 'Tajikistan',
      dial_code: '+992',
      emoji: '🇹🇯',
      code: 'TJ'
    },
    {
      name: 'Tanzania, United Republic of Tanzania',
      dial_code: '+255',
      emoji: '🇹🇿',
      code: 'TZ'
    },
    {
      name: 'Thailand',
      dial_code: '+66',
      emoji: '🇹🇭',
      code: 'TH'
    },
    {
      name: 'Timor-Leste',
      dial_code: '+670',
      emoji: '🇹🇱',
      code: 'TL'
    },
    {
      name: 'Togo',
      dial_code: '+228',
      emoji: '🇹🇬',
      code: 'TG'
    },
    {
      name: 'Tokelau',
      dial_code: '+690',
      emoji: '🇹🇰',
      code: 'TK'
    },
    {
      name: 'Tonga',
      dial_code: '+676',
      emoji: '🇹🇴',
      code: 'TO'
    },
    {
      name: 'Trinidad and Tobago',
      dial_code: '+1868',
      emoji: '🇹🇹',
      code: 'TT'
    },
    {
      name: 'Tunisia',
      dial_code: '+216',
      emoji: '🇹🇳',
      code: 'TN'
    },
    {
      name: 'Turkey',
      dial_code: '+90',
      emoji: '🇹🇷',
      code: 'TR'
    },
    {
      name: 'Turkmenistan',
      dial_code: '+993',
      emoji: '🇹🇲',
      code: 'TM'
    },
    {
      name: 'Turks and Caicos Islands',
      dial_code: '+1649',
      emoji: '🇹🇨',
      code: 'TC'
    },
    {
      name: 'Tuvalu',
      dial_code: '+688',
      emoji: '🇹🇻',
      code: 'TV'
    },
    {
      name: 'Uganda',
      dial_code: '+256',
      emoji: '🇺🇬',
      code: 'UG'
    },
    {
      name: 'Ukraine',
      dial_code: '+380',
      emoji: '🇺🇦',
      code: 'UA'
    },
    {
      name: 'United Arab Emirates',
      dial_code: '+971',
      emoji: '🇦🇪',
      code: 'AE'
    },
    {
      name: 'United Kingdom',
      dial_code: '+44',
      emoji: '🇬🇧',
      code: 'GB'
    },
    {
      name: 'United States',
      dial_code: '+1',
      emoji: '🇺🇸',
      code: 'US'
    },
    {
      name: 'Uruguay',
      dial_code: '+598',
      emoji: '🇺🇾',
      code: 'UY'
    },
    {
      name: 'Uzbekistan',
      dial_code: '+998',
      emoji: '🇺🇿',
      code: 'UZ'
    },
    {
      name: 'Vanuatu',
      dial_code: '+678',
      emoji: '🇻🇺',
      code: 'VU'
    },
    {
      name: 'Venezuela, Bolivarian Republic of Venezuela',
      dial_code: '+58',
      emoji: '🇻🇪',
      code: 'VE'
    },
    {
      name: 'Vietnam',
      dial_code: '+84',
      emoji: '🇻🇳',
      code: 'VN'
    },
    {
      name: 'Virgin Islands, British',
      dial_code: '+1284',
      emoji: '🇻🇬',
      code: 'VG'
    },
    {
      name: 'Virgin Islands, U.S.',
      dial_code: '+1340',
      emoji: '🇻🇮',
      code: 'VI'
    },
    {
      name: 'Wallis and Futuna',
      dial_code: '+681',
      emoji: '🇼🇫',
      code: 'WF'
    },
    {
      name: 'Yemen',
      dial_code: '+967',
      emoji: '🇾🇪',
      code: 'YE'
    },
    {
      name: 'Zambia',
      dial_code: '+260',
      emoji: '🇿🇲',
      code: 'ZM'
    },
    {
      name: 'Zimbabwe',
      dial_code: '+263',
      emoji: '🇿🇼',
      code: 'ZW'
    }
  ]
  getCountryDialCodes() {
    return this.countryDialCodes
  }
}
