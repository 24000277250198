import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { AndroidBuildComponent } from './build/android-build/android-build.component'
import { ConnectivityComponent } from './connectivity/connectivity.component'
import { Authguard } from './guards/authguard.service'
import { Tourguard } from './guards/tourguard.service'
import { LoginComponent } from './login/login.component'
import { PushNotificationsComponent } from './addons/push-notifications/push-notifications.component'
import { AppleSettingsComponent } from './settings/apple-settings/apple-settings.component'
import { ApplicationSettingsComponent } from './settings/application-settings/application-settings.component'
import { ContentSettingsComponent } from './settings/content-settings/content-settings.component'
import { GeneralSettingsComponent } from './settings/general-settings/general-settings.component'
import { TemplateViewComponent } from './templates/template-view/template-view.component'
import { ViewBuilderComponent } from './view-builder/view-builder.component'
import { IosBuildComponent } from './build/ios-build/ios-build.component'
import { BuildStatusComponent } from './build/build-status/build-status.component'
import { ChangelogComponent } from './pages/changelog/changelog.component'
import { ViewsComponent } from './views/views.component'
import { AdminComponent } from './admin/admin/admin.component'
import { ProjectsComponent } from './pages/projects/projects.component'
import { AppPreviewComponent } from './pages/app-preview/app-preview.component'
import { CloudSubsComponent } from './admin/admin-analytics/cloud/cloud-subs/cloud-subs.component'
import { CloudComponent } from './admin/admin-analytics/cloud/cloud.component'
import { SelfComponent } from './admin/admin-analytics/self/self.component'
import { AdminauthGuard } from './guards/adminauth.guard'
import { ExpirationGuard } from './guards/expiration.guard'
import { LogsComponent } from './admin/admin-analytics/logs/logs.component'
import { AnalyticsPageStatsComponent } from './pages/analytics/analytics-page-stats/analytics-page-stats.component'
import { ExpiredComponent } from './pages/expired/expired.component'
import { AnalyticsOrdersComponent } from './pages/analytics/analytics-orders/analytics-orders.component'
import { WebviewGuard } from './guards/webview.guard'
import { SignupComponent } from './signup/signup.component'
import { SiteTypeComponent } from './pages/site-type/site-type.component'
import { NavigationEnd, Router } from '@angular/router'
import { MixpanelService } from './tracking/mixpanel.service'
import { LtdComponent } from './pages/ltd/ltd.component'
import { VersionsComponent } from './pages/versions/versions.component'
import { AppsUploadedComponent } from './admin/admin-analytics/apps-uploaded/apps-uploaded.component'
import { AllAppsComponent } from './admin/admin-analytics/all-apps/all-apps.component'
import { PaymentSuccessComponent } from './pages/payment-success/payment-success.component'
import { BillingComponent } from './pages/billing/billing.component'
import { BuilderService } from './builder-services/builder.service'
import { HelpComponent } from './pages/help/help.component'
import { NewProductComponent } from './e-commerce/products/new-product/new-product.component'
import { ProductsComponent } from './e-commerce/products/list/products.component'
import { CategoriesComponent } from './e-commerce/categories/list/categories.component'
import { ShippingComponent } from './e-commerce/shipping/shipping.component'
import { SettingsComponent } from './e-commerce/settings/settings.component'
import { PostsListComponent } from './posts/posts-list/posts-list.component'
import { PostsCategoriesComponent } from './posts/posts-categories/posts-categories.component'
import { NewPostComponent } from './posts/new-post/new-post.component'
import { OrdersComponent } from './e-commerce/orders/orders.component'
import { SingleOrderComponent } from './e-commerce/orders/single-order/single-order.component'
import { StacksImporterComponent } from './e-commerce/stacks-importer/stacks-importer.component'
import { SubscribersListComponent } from './subscribers/subscribers-list/subscribers-list.component'
import { NewSubscriberComponent } from './subscribers/new-subscriber/new-subscriber.component'
import { MobileViewComponent } from './pages/mobile-view/mobile-view.component'
import { TemplatesNewComponent } from './templates/templates-new/templates-new.component'
import { MultilingualComponent } from './translations/multilingual/multilingual.component'
import { TxtTranslationsComponent } from './translations/txt-translations/txt-translations.component'
import { PointsComponent } from './plugins/points/components/points/points.component'
import { SocialLoginComponent } from './addons/social-login/social-login.component'
import { AdminDashboardComponent } from './admin/admin-dashboard/admin-dashboard/admin-dashboard.component'
import { PluginsServiceService } from './plugins-loader/plugins-service.service'
import { AddonsComponent } from './addons/addons/addons.component'
import { AddCouponComponent } from './e-commerce/coupons/add-coupon/add-coupon.component'
import { CouponsComponent } from './e-commerce/coupons/coupons/coupons.component'
import { AndroidApkComponent } from './build/android-apk/android-apk.component'
import { FirebaseSettingsComponent } from './settings/firebase-settings/firebase-settings.component'
import { AppUsersComponent } from './app-users/app-users.component'
import { UserDetailsComponent } from './app-users/user-details/user-details.component'
import { AllSubscribersComponent } from './subscription-flow/all-subscribers/all-subscribers.component'
import { SubscriberInfoComponent } from './subscription-flow/subscriber-info/subscriber-info.component'
import { restrictedPagesGuard } from './guards/restricted-pages.guard'
import { cancelledUserGuard } from './guards/cancelled-user.guard'
import { PointsListComponent } from 'plugins/points/components/points-list/points-list.component'
import { DiscontComponent } from './e-commerce/discount/discont/discont.component'
import { DiscountListComponent } from './e-commerce/discount/discount-list/discount-list.component'
import { DynamicSamplesComponent } from './admin/dynamic-samples/dynamic-samples.component'
import { PnAutomationComponent } from './addons/push-notifications/pn-automation/pn-automation.component'
import { CreateUserComponent } from './app-users/create-user/create-user.component'
import { userRoleGuard } from './guards/user-role.guard'
import { PaymentMethodsComponent } from './e-commerce/payment-methods/payment-methods.component'

const routes: Routes = [
  {
    path: '',
    component: ViewBuilderComponent,
    canActivate: [Authguard, WebviewGuard, ExpirationGuard, userRoleGuard]
  },
  {
    path: 'globals',
    component: ViewBuilderComponent,
    canActivate: [Authguard, WebviewGuard, ExpirationGuard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'signup',
    component: SignupComponent
  },
  {
    path: 'generalsettings',
    component: GeneralSettingsComponent,
    canActivate: [Authguard, ExpirationGuard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'firebasesettings',
    component: FirebaseSettingsComponent,
    canActivate: [Authguard, ExpirationGuard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'applicationsettings',
    component: ApplicationSettingsComponent,
    canActivate: [Authguard, WebviewGuard, ExpirationGuard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'contentsettings',
    component: ContentSettingsComponent,
    canActivate: [Authguard, Tourguard, ExpirationGuard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'applesettings',
    component: AppleSettingsComponent,
    canActivate: [Authguard, Tourguard, WebviewGuard, ExpirationGuard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'templates_old',
    component: TemplateViewComponent,
    canActivate: [Authguard, WebviewGuard, ExpirationGuard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'templates',
    component: TemplatesNewComponent,
    canActivate: [Authguard, WebviewGuard, ExpirationGuard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'pushnotifications',
    component: PushNotificationsComponent,
    canActivate: [Authguard, WebviewGuard, ExpirationGuard, Tourguard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'automation',
    component: PnAutomationComponent,
    canActivate: [Authguard, WebviewGuard, ExpirationGuard, Tourguard,cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'android',
    component: AndroidBuildComponent,
    canActivate: [Authguard, ExpirationGuard, Tourguard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'e-commerce/payments',
    component: PaymentMethodsComponent,
    canActivate: [Authguard, ExpirationGuard, Tourguard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'android-apk',
    component: AndroidApkComponent,
    canActivate: [Authguard, AdminauthGuard, userRoleGuard]
  },
  {
    path: 'admin/dynamicSamples',
    component: DynamicSamplesComponent,
    canActivate: [AdminauthGuard, userRoleGuard]
  },
  {
    path: 'ios',
    component: IosBuildComponent,
    canActivate: [Authguard, ExpirationGuard, WebviewGuard, Tourguard, restrictedPagesGuard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'build-status',
    component: BuildStatusComponent,
    canActivate: [Authguard, ExpirationGuard, Tourguard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'connectivity',
    component: ConnectivityComponent,
    canActivate: [Authguard, WebviewGuard, ExpirationGuard, Tourguard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'users',
    component: AppUsersComponent,
    canActivate: [Authguard, ExpirationGuard, Tourguard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'users/:userId',
    component: UserDetailsComponent,
    canActivate: [Authguard, ExpirationGuard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'changelog',
    component: ChangelogComponent
  },
  {
    path: 'views',
    component: ViewsComponent,
    canActivate: [Authguard, WebviewGuard, ExpirationGuard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [Authguard, AdminauthGuard, ExpirationGuard]
  },
  {
    path: 'projects',
    component: ProjectsComponent,
    canActivate: [Authguard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'app-preview',
    component: AppPreviewComponent,
    canActivate: [Authguard, ExpirationGuard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'analytics/page-stats',
    component: AnalyticsPageStatsComponent,
    canActivate: [Authguard, WebviewGuard, ExpirationGuard, Tourguard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'analytics/orders',
    component: AnalyticsOrdersComponent,
    canActivate: [Authguard, WebviewGuard, ExpirationGuard, Tourguard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'admin/self',
    component: SelfComponent,
    canActivate: [AdminauthGuard]
  },
  {
    path: 'admin-dashboard',
    component: AdminDashboardComponent,
    canActivate: [AdminauthGuard]
  },
  {
    path: 'admin/cloud',
    component: CloudComponent,
    canActivate: [AdminauthGuard]
  },
  {
    path: 'admin/logs',
    component: LogsComponent,
    canActivate: [AdminauthGuard]
  },
  {
    path: 'admin/cloud-subs',
    component: CloudSubsComponent,
    canActivate: [AdminauthGuard]
  },
  {
    path: 'admin/apps-uploaded',
    component: AppsUploadedComponent,
    canActivate: [AdminauthGuard]
  },
  {
    path: 'admin/all-apps',
    component: AllAppsComponent,
    canActivate: [AdminauthGuard]
  },
  {
    path: 'expired',
    component: ExpiredComponent,
    canActivate: [Authguard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'site-type',
    component: SiteTypeComponent
  },
  {
    path: 'ltd',
    component: LtdComponent,
    canActivate: [Authguard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'versions',
    component: VersionsComponent,
    canActivate: [Authguard, ExpirationGuard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'payment_success',
    component: PaymentSuccessComponent
  },
  {
    path: 'payment_success/:token/:details/:id',
    component: PaymentSuccessComponent
  },
  {
    path: 'payment_success/:token/:package/:projectId/:priceID',
    component: PaymentSuccessComponent
  },
  {
    path: 'billing',
    component: BillingComponent,
    canActivate: [Authguard, userRoleGuard]
  },
  {
    path: 'subscribers',
    component: AllSubscribersComponent,
    canActivate: [AdminauthGuard, userRoleGuard]
  },
  {
    path: 'subscribers/info/:id',
    component: SubscriberInfoComponent,
    canActivate: [AdminauthGuard, userRoleGuard]
  },
  {
    path: 'help',
    component: HelpComponent,
    canActivate: [Authguard]
  },
  {
    path: 'products',
    component: ProductsComponent,
    canActivate: [Authguard, ExpirationGuard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'social',
    component: SocialLoginComponent,
    canActivate: [Authguard, ExpirationGuard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'multilingual',
    component: MultilingualComponent,
    canActivate: [Authguard, ExpirationGuard, restrictedPagesGuard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'txtTranslations',
    component: TxtTranslationsComponent,
    canActivate: [Authguard, ExpirationGuard, restrictedPagesGuard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'products/new-product',
    component: NewProductComponent,
    canActivate: [Authguard, ExpirationGuard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'products/new-product/:productId',
    component: NewProductComponent,
    canActivate: [Authguard, ExpirationGuard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'categories/categories',
    component: CategoriesComponent,
    canActivate: [Authguard, ExpirationGuard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'coupons',
    component: CouponsComponent,
    canActivate: [Authguard, ExpirationGuard, restrictedPagesGuard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'coupons/add-coupon',
    component: AddCouponComponent,
    canActivate: [Authguard, ExpirationGuard, restrictedPagesGuard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'discount',
    component: DiscountListComponent,
    canActivate: [Authguard, ExpirationGuard, restrictedPagesGuard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'discount/add-discount',
    component: DiscontComponent,
    canActivate: [Authguard, ExpirationGuard, restrictedPagesGuard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'discount/add-discount/:id',
    component: DiscontComponent,
    canActivate: [Authguard, ExpirationGuard, restrictedPagesGuard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'coupons/add-coupon/:id',
    component: AddCouponComponent,
    canActivate: [Authguard, userRoleGuard]
  },
  {
    path: 'shipping',
    component: ShippingComponent,
    canActivate: [Authguard, ExpirationGuard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'e-commerce/settings',
    component: SettingsComponent,
    canActivate: [Authguard, ExpirationGuard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'posts/list',
    component: PostsListComponent,
    canActivate: [Authguard, ExpirationGuard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'posts/new-post',
    component: NewPostComponent,
    canActivate: [Authguard, ExpirationGuard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'posts/categories',
    component: PostsCategoriesComponent,
    canActivate: [Authguard, ExpirationGuard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'orders',
    component: OrdersComponent,
    canActivate: [Authguard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'orders/single-order/:orderId',
    component: SingleOrderComponent,
    canActivate: [Authguard, ExpirationGuard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'stacks-importer',
    component: StacksImporterComponent,
    canActivate: [Authguard, ExpirationGuard, cancelledUserGuard ,userRoleGuard, Tourguard]
  },
  {
    path: 'admin/subList',
    component: SubscribersListComponent,
    canActivate: [Authguard]
  },
  {
    path: 'admin/newSub',
    component: NewSubscriberComponent,
    canActivate: [Authguard, ExpirationGuard]
  },
  {
    path: 'admin/newSub/:subId',
    component: NewSubscriberComponent,
    canActivate: [Authguard, ExpirationGuard]
  },
  {
    path: 'mobile-trials',
    component: MobileViewComponent,
    canActivate: [Authguard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: 'admin/subList',
    component: SubscribersListComponent,
    canActivate: [Authguard]
  },
  {
    path: 'admin/newSub',
    component: NewSubscriberComponent,
    canActivate: [Authguard]
  },
  {
    path: 'admin/newSub/:subId',
    component: NewSubscriberComponent,
    canActivate: [Authguard]
  },
  {
    path: 'plugins',
    component: AddonsComponent,
    canActivate: [Authguard, cancelledUserGuard ,userRoleGuard]
  },
  {
    path: '*',
    component: ViewBuilderComponent,
    canActivate: [Authguard, Tourguard]
  },
  {
    path: 'create-user',
    component: CreateUserComponent,
    canActivate: [Authguard, ExpirationGuard, userRoleGuard]
  },
  {
    path: 'points',
    component: PointsListComponent,
    canActivate: [Authguard, cancelledUserGuard ,userRoleGuard, restrictedPagesGuard]
  },
  {
    path: 'points/:id',
    component: PointsComponent,
    canActivate: [Authguard, cancelledUserGuard ,userRoleGuard, restrictedPagesGuard]
  }
]
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
  constructor(
    private router: Router,
    public mixPanel: MixpanelService,
    private builderService: BuilderService,
    private pluginsService: PluginsServiceService
  ) {
    this.router.events.subscribe((route_event) => {
      if (route_event instanceof NavigationEnd) {
        this.builderService.isListShown = true
        this.builderService.toggleSidebar = false
        this.mixPanel.init(this.builderService.selectedProject)
        this.mixPanel.track('Page View', {
          url: route_event.url
        })
      }
    })
  }
}
