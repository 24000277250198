import { Component, OnInit } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { LoginService } from 'src/app/login/login.service'
import { AppSettingsService } from '../services/app-settings.service'
import { environment } from 'src/environments/environment'

class ImageSnippet {
  constructor(
    public src: string,
    public file: File
  ) {}
}

@Component({
  selector: 'general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss']
})
export class GeneralSettingsComponent implements OnInit {
  selectedFile!: ImageSnippet
  isSampleEnabled: boolean = false
  project_id: string
  submittedData: any
  showProgress = false
  settingsForm = this.formBuilder.group({
    application_name: '',
    package_name: 'com.stacks.www',
    ipa_sku_number: '',
    application_description: 'Description',
    icon: '',
    splash_screen: '',
    release_app: ''
  })

  fileToUpload: File | null = null

  generalSettingsData: any = {}
  constructor(
    private formBuilder: FormBuilder,
    private appSettingsService: AppSettingsService,
    public builderService: BuilderService,
    private router: Router,
    private route: ActivatedRoute,
    public loginService: LoginService
  ) {
    this.builderService.showUpdateProgress = true
    // Wait for sometime till the project id is retrieved from the server if it is not available yet
    this.project_id = this.builderService.selectedProject
    if (!this.project_id) {
      setTimeout(() => {
        this.getInitialSettings()
      }, 1000)
    } else {
      this.getInitialSettings()
    }
  }
  /**
   * Initializes the settings form with data retrieved from the server.
   * @returns void
   *
   * Fetches the general settings for the specified project ID and updates the form with the retrieved values.
   * Sets default values for missing fields such as the application description and sample password. Ensures
   * the sample password is generated if not provided and updates the sample-enabled status. Hides the progress
   * indicator after the process is complete.
   */

  getInitialSettings = () => {
    this.generalSettingsData = this.appSettingsService.getGeneralSettings(this.project_id).subscribe(
      (settings: any) => {
        this.generalSettingsData = settings !== null ? settings : {}
        this.generalSettingsData.splash_screen = this.generalSettingsData.splash_screen.replace('C:\\fakepath\\', environment.builderNativeURL + 'wp-content/uploads/project_assets/images/' + this.project_id + '/')
        this.generalSettingsData.icon = this.generalSettingsData.icon.replace('C:\\fakepath\\', environment.builderNativeURL + 'wp-content/uploads/project_assets/images/' + this.project_id + '/')

        if (settings !== null) {
          this.settingsForm.patchValue({
            application_name: this.generalSettingsData.application_name || '',
            package_name: this.generalSettingsData.package_name || this.settingsForm.value.package_name,
            ipa_sku_number: this.generalSettingsData.ipa_sku_number || '',
            application_description: this.generalSettingsData.application_description || 'Description',
            // icon: this.generalSettingsData.icon || '',
            // splash_screen: this.generalSettingsData.splash_screen || '',
            release_app: this.generalSettingsData.release_app || ''
          })
        }
        this.isSampleEnabled = settings.isSampleEnabled || false
        this.builderService.showUpdateProgress = false
      },
      (err) => {
        console.log(err)
      }
    )
  }

  toggleSample(): void {
    this.isSampleEnabled = this.isSampleEnabled
  }

  ngOnInit(): void {}

  /**
   * Handles the submission of general settings for the project.
   * @returns void
   *
   * Collects and processes data from the settings form, ensuring any unchanged fields retain their current values
   * from the existing general settings. Updates specific fields like icons, splash screens, and sample settings if
   * not provided in the form. Submits the finalized settings to the server and triggers the Auth0 redirect update.
   * Displays a progress indicator during the process and hides it upon completion.
   */

  onSubmit(): void {
    // Process checkout data here
    this.builderService.showUpdateProgress = true
    let icon = this.settingsForm.value.icon
    let splash_screen = this.settingsForm.value.splash_screen
    /**
     * Loop on the Values and if the value isn't changed from the UI then retrieve the current available value
     */
    let newSubmittedData: any = {}
    this.submittedData = this.settingsForm.value
    let retrievedData = this.generalSettingsData
    for (var k in this.submittedData) {
      if (this.submittedData[k] != '') {
        newSubmittedData[k] = this.submittedData[k]
      } else {
        newSubmittedData[k] = retrievedData[k]
      }
    }
    /**
     * Step 1 Submit the Icons and Splash Screens
     */
    if (!icon) {
      newSubmittedData['icon'] = this.generalSettingsData.icon
    }
    if (!splash_screen) {
      newSubmittedData['splash_screen'] = this.generalSettingsData.splash_screen
    }

    if (retrievedData?.firebase_settings) {
      newSubmittedData['firebase_settings'] = this.generalSettingsData.firebase_settings
    }
    newSubmittedData['isSampleEnabled'] = this.isSampleEnabled

    this.appSettingsService.setGeneralSettings(this.builderService.selectedProject, newSubmittedData).subscribe(
      (response) => {
        this.updateAuthRedirect()
        this.builderService.showUpdateProgress = false
      },
      (err) => {}
    )
  }

  /**
   * Processes and uploads an image file for the specified type with validation.
   * @param imageInput The file input event containing the selected image.
   * @param type The type of the image being processed (e.g., 'icon', 'splash_screen').
   * @param minWidth The minimum required width for the image.
   * @param minHeight The minimum required height for the image.
   * @returns void
   *
   * Reads the selected image file, uploads it to the server, and validates its dimensions if the type requires it.
   * Updates the general settings data with the uploaded image URL upon successful upload. Displays a progress
   * indicator during the process and alerts the user if the image dimensions are smaller than the required minimum.
   * Handles errors gracefully and notifies the user if the upload fails.
   */

  processFile(imageInput: any, type: string, minWidth: number, minHeight: number) {
    const file: File = imageInput.target.files[0]
    const reader = new FileReader()
    this.builderService.showUpdateProgress = true
    reader.addEventListener('load', (event: any) => {
      this.selectedFile = new ImageSnippet(event.target.result, file)
      this.appSettingsService.uploadImage(this.selectedFile.file, this.builderService.selectedProject, type).subscribe(
        (res) => {
          if (type == 'icon' || type == 'splash_screen') {
            /**
             * Validate the image width and height
             */
            const img = new Image()
            let $this = this
            img.src = res
            img.addEventListener('load', function () {
              if (this.width < minWidth || this.height < minHeight) {
                alert('Min image Dimensions = ' + minWidth + ' x ' + minHeight + ', Uploaded Dimensions = ' + this.width + ' x ' + this.height)
              } else {
                $this.generalSettingsData[type] = res
              }
            })
          } else {
            this.generalSettingsData[type] = res
          }

          this.builderService.showUpdateProgress = false
        },
        (err) => {
          this.builderService.showUpdateProgress = false
          alert('error uploading file, please contact support')
        }
      )
    })

    reader.readAsDataURL(file)
  }

  /**
   * Proceeds to the next step in the general settings workflow.
   * @returns void
   *
   * Submits the current general settings form and navigates to the next page based on the project's access type.
   * If the project access is 'webview', disables the tour and redirects to the content settings page. Otherwise,
   * redirects to the site type selection page.
   */

  generalSettingsNext = () => {
    this.onSubmit()
    if (this.builderService.projectAccess == 'webview') {
      this.builderService.setTour(false).subscribe(
        (response) => {
          this.router.navigate(['/contentsettings'], {
            relativeTo: this.route
          })
        },
        (err) => {}
      )
    } else {
      this.router.navigate(['/site-type'], { relativeTo: this.route })
    }
  }

  /**
   * Updates the Auth0 redirect URI in the application settings for a given project.
   * @param projectId The ID of the project whose settings are being updated.
   * @param submittedData The form data containing updates, including a new package name, if provided.
   * @returns void
   *
   * Retrieves the current application settings for the specified project, checks for a new package name in the submitted data,
   * and updates the Auth0 redirect URI by replacing the package name with the new one. Merges the updated redirect URI into the
   * current settings and saves the changes back to the application and WordPress settings.
   */

  updateAuthRedirect() {
    this.builderService.showUpdateProgress = true
    this.appSettingsService.getApplicationSettings(this.project_id).subscribe(
      (currentSettings: any) => {
        const packageName = this.submittedData.package_name || this.settingsForm.value.package_name
        const auth0_domain = currentSettings.auth0_domain || 'dev-3t3cfn38mfbo482t.us.auth0.com'
        const auth0_redirect_uri = `${packageName}://${auth0_domain}/capacitor/${packageName}/callback`

        const updatedSettings = {
          ...currentSettings,
          auth0_redirect_uri: auth0_redirect_uri
        }

        this.appSettingsService.setApplicationSettings(this.project_id, updatedSettings).subscribe(() => {
          this.appSettingsService.setWPApplicationSettings(this.builderService.selectedProject, updatedSettings).subscribe(() => {
            console.log(updatedSettings)
            this.builderService.showUpdateProgress = false
          })
        })
      },
      (error: any) => {
        console.error('Error saving social login data', error)
      }
    )
  }
}
