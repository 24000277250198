<h1 class="pointsList">Points List</h1>

<div class="pointsWrapper">
  <!-- If there are no points setups, show this "empty" screen -->
  <div *ngIf="!hasPoints()" class="noPoints">
    <button class="addPointsBtn" routerLink="/points/:id">
      <span>+</span>
      Add New Points
    </button>
    <!-- You can replace the src with your own image -->
    <img class="addPointsLogo" src="assets/images/point&reward.png" />
  </div>

  <!-- If there are points, show the table -->
  <div class="tableWrapper" *ngIf="hasPoints()">
    <div class="tableHeader">
      <div class="headerBtns">
        <!-- "Add New Points" button in header -->
        <button class="stacksBtn blue addNewPoints" routerLink="/points/:id">
          <span>+</span>
          Add New Points
        </button>
        <!-- Search bar -->
        <form class="searchBar">
          <input type="text" placeholder="Search" [(ngModel)]="searchTerm" [ngModelOptions]="{ standalone: true }" (ngModelChange)="applyFilter($event)" />
        </form>
      </div>
    </div>

    <!-- Angular Material Table -->
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
      <!-- ID Column -->
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>ID</th>
        <td mat-cell *matCellDef="let point">{{ point.id }}</td>
      </ng-container>

      <!-- Points System Column -->
      <ng-container matColumnDef="points_system">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Points System</th>
        <td mat-cell *matCellDef="let point">{{ point.points_system }}</td>
      </ng-container>

      <!-- Generic Details Column -->
      <ng-container matColumnDef="details">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Details</th>
        <td mat-cell *matCellDef="let point">
          <ng-container *ngIf="point.points_system === 'byUnit'"> {{ point.currency_system || 'N/A' }} </ng-container>
          <ng-container *ngIf="point.points_system === 'byOrder'">{{ point.order_system || 'N/A' }} </ng-container>
          <ng-container *ngIf="point.points_system === 'byProductCategory'">{{ point.product_option || 'N/A' }} </ng-container>
        </td>
      </ng-container>

      <!-- "Enabled?" Column -->
      <ng-container matColumnDef="enable_points">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Enabled?</th>
        <td mat-cell *matCellDef="let point">
          <mat-slide-toggle color="primary" [checked]="point.enable_points" (change)="toggleEnablePoints(point, $event.checked)"> </mat-slide-toggle>
        </td>
      </ng-container>

      <!-- Actions Column -->
      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let point">
          <!-- Edit button: navigates to /points/:id -->
          <button mat-button [routerLink]="['/points', point.id]" class="editPoints">
            <span class="material-icons">edit</span>
          </button>

          <!-- Delete button -->
          <button mat-button (click)="deletePoints(point.id)" class="deletePoints">
            <span class="material-icons">delete</span>
          </button>
        </td>
      </ng-container>

      <!-- Table headers and rows -->
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <!-- Material Paginator -->
    <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25]" showFirstLastButtons></mat-paginator>
  </div>
</div>
