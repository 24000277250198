import { AfterViewInit, Component, OnInit } from '@angular/core'
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router'
import { GoogleAnalyticsService } from 'ngx-google-analytics'
import { environment } from 'src/environments/environment'
import { BlockDataService } from './block-services/block-data.service'
import { BlockFieldsService } from './block-services/block-fields.service'
import { CategoriesService } from './blocks-wrapper/block-categories/categories.service'
import { PostsService } from './blocks-wrapper/block-posts/posts.service'
import { ProductsService } from './blocks-wrapper/block-products/products.service'
import { BuilderService } from './builder-services/builder.service'
import { ConnectivityService } from './connectivity/connectivity.service'
import { LoginService } from './login/login.service'
import { MixpanelService } from './tracking/mixpanel.service'
import { MatDialog } from '@angular/material/dialog'
import { WorksDialogComponent } from '../app/pages/works-dialog/works-dialog.component'
import { LanguageService } from './language.service'
import { TranslateService } from '@ngx-translate/core'
import { PluginsServiceService } from './plugins-loader/plugins-service.service'
import { OrderNotificationService } from './e-commerce/orders/order-notification-service/order-notification.service'
import { SubscriptionFlowService } from './subscription-flow/subscription-flow.service'
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewInit, OnInit {
  args: object = {
    categories: [],
    hide_empty: true,
    orderby: 'date',
    order: 'DESC',
    source: 'by_id'
  }

  viewHeight = ''
  mobile = false
  translatedText!: string
  currentPage: any
  constructor(
    public builderService: BuilderService,
    public loginService: LoginService,
    private router: Router,
    private route: ActivatedRoute,
    public subscriptionFlow: SubscriptionFlowService,
    public LanguageService: LanguageService,
    private translate: TranslateService,
    private dialog: MatDialog,
    private categoriesService: CategoriesService,
    protected gaService: GoogleAnalyticsService,
    public blockDataService: BlockDataService,
    public connectivity: ConnectivityService,
    public mixPanel: MixpanelService,
    public postsService: PostsService,
    public productsService: ProductsService,
    private blockFields: BlockFieldsService,
    private pluginsService: PluginsServiceService,
    private orderNotificationService: OrderNotificationService
  ) {
    if (window.screen.width <= 768) {
      // 768px portrait
      this.mobile = true
    }

    this.blockDataService.getTokenData().subscribe((token) => {
      this.blockDataService.getProjectData().subscribe((projectId) => {
        // Check the Connectivity of the plugin and if connected then Check the Default Views
        this.connectivity.connectivityChecker()
        this.getInitialTour()
        this.getInitialProjectConfig()
        this.categoriesService.getInitialCategories()
        this.postsService.getInitialCategories()
        this.getProjectStatus(projectId)
        this.mixPanel.init(projectId)
        // if( this.builderService.user_type == 'self_ltd' ) {
        // }
        // let allBlocks = this.blockDataService.getAllBlocksData();
        this.getGlobalHeader(projectId)
        this.getGlobalFooter(projectId)

        this.getPosts(this.builderService.getSelectedProject(), this.blockFields.postsBlockData.data)

        this.getProducts(this.builderService.getSelectedProject(), this.blockFields.productsBlockData.data)
      })
    })

    this.builderService.getViews()
    if (environment.production) {
      this.gaService.gtag('config', 'AW-972505779')
      this.gaService.gtag('config', environment.gtm)
    }
  }

  getGlobalHeader = (projectId: any) => {
    this.builderService.getView(99, 'global_header').subscribe((response: any) => {
      // Globals page, pull the data of the globals header
      if (!response || response == undefined || response == null || response == '') {
        // There is no global Footer for this project, submit one
        this.builderService.updateProject(projectId, [this.blockFields.headerBlockData], false, 99, 'global_header').subscribe((result: any) => {
          this.builderService.globalHeader = this.blockFields.headerBlockData
          this.blockDataService.sendGlobalHeader(this.builderService.globalHeader)
        })
      } else {
        let parsedResponse
        if (typeof response == 'string') {
          if (JSON.parse(response)[0]) {
            parsedResponse = JSON.parse(response)[0].allBlocks ? JSON.parse(response)[0].allBlocks : JSON.parse(response)[0]
          } else {
            parsedResponse = JSON.parse(response).allBlocks ? JSON.parse(response).allBlocks : JSON.parse(response)
          }
        } else {
          parsedResponse = response.allBlocks && response.allBlocks[0] ? response.allBlocks[0] : response
        }
        this.builderService.globalHeader = parsedResponse
        this.blockDataService.sendGlobalHeader(this.builderService.globalHeader)
      }
    })
  }

  getGlobalFooter = (projectId: any) => {
    this.builderService.getView(98, 'global_footer').subscribe((response: any) => {
      // Globals page, pull the data of the globals footer
      if (!response || response == undefined || response == null || response == '') {
        // There is no global Footer for this project, submit one
        this.builderService.updateProject(projectId, [this.blockFields.footerBlockData], false, 98, 'global_footer').subscribe((result: any) => {
          this.builderService.globalFooter = this.blockFields.footerBlockData
          this.blockDataService.sendGlobalFooter(this.builderService.globalFooter)
          console.log(result)
        })
      } else {
        let parsedResponse
        if (typeof response == 'string') {
          if (JSON.parse(response)[0]) {
            parsedResponse = JSON.parse(response)[0].allBlocks ? JSON.parse(response)[0].allBlocks : JSON.parse(response)[0]
          } else {
            parsedResponse = JSON.parse(response).allBlocks ? JSON.parse(response).allBlocks : JSON.parse(response)
          }
        } else {
          parsedResponse = response.allBlocks && response.allBlocks[0] ? response.allBlocks[0] : response
        }
        this.builderService.globalFooter = parsedResponse[parsedResponse.length - 1] ? parsedResponse[parsedResponse.length - 1] : parsedResponse
        this.blockDataService.sendGlobalFooter(this.builderService.globalFooter)
        // if( parsedResponse == undefined || parsedResponse == null || parsedResponse == '' ) {
        //   parsedResponse = response.allBlocks[0].data;
        //   this.builderService.globalFooter = parsedResponse;
        //   this.blockDataService.sendGlobalFooter(this.builderService.globalFooter);
        // } else {
        //   this.builderService.globalFooter = parsedResponse[parsedResponse.length - 1];
        //   this.blockDataService.sendGlobalFooter(this.builderService.globalFooter);
        // }
      }
    })
  }

  getInitialTour = () => {
    /**
     * Get the initial tour status and sets the local value
     */
    this.builderService.getInitialTourStatus().subscribe(
      (tour: any) => {
        let tourStart = tour.data == '1' ? false : true
        this.builderService.setTour(tourStart)
        /**
         * If the tour should start then navigate to it
         */
        if (tourStart) {
          if (this.builderService.projectAccess == 'webview') {
            this.router.navigate(['/site-type'], { relativeTo: this.route })
          }
        }
      },
      (err) => {
        console.log('Initial Tour Error', err)
      }
    )
  }
  closeSidebars = () => {
    if (this.mobile) {
      this.builderService.toggleSidebar = false
      // this.builderService.blocksCollapsed = true;
    }
  }
  ngOnInit() {
    this.orderNotificationService.getNewOrderNotification().subscribe((hasNewOrder) => {
      if (hasNewOrder) {
        console.log('New order detected')
      }
    })

    this.viewHeight = window.screen.height - 60 + 'px' // sidebar height equals the view height minus the header height
    this.LanguageService.loadTranslations(this.LanguageService.getLanguage()).then(() => {
      // Set the active language for ngx-translate
      this.translate.use(this.LanguageService.getLanguage())
    })
    /**
     * Watch the ruoted pages to control the visibility of header elements
     */
    this.router.events.subscribe((route_event) => {
      if (route_event instanceof NavigationEnd) {
        this.builderService.globals = false
        this.currentPage = route_event.url
        switch (route_event.url) {
          case '/generalsettings':
            this.builderService.headerBtn = 'appSettings'
            break
          case '/firebasesettings':
            this.builderService.headerBtn = 'firebasesettings'
            break
          case '/applicationsettings':
            this.builderService.headerBtn = 'applicationSettings'
            break
          case '/contentsettings':
            this.builderService.headerBtn = 'contentsettings'
            break
          case '/applesettings':
            this.builderService.headerBtn = 'applesettings'
            break
          case '/multilingual':
            this.builderService.headerBtn = 'multilingual'
            break
          case '/txtTranslations':
            this.builderService.headerBtn = 'txtTranslations'
            break
          case '/social':
            this.builderService.headerBtn = 'social'
            break
          case '/pushnotifications':
            this.builderService.headerBtn = 'pushnotifications'
            break
          case '/e-commerce/settings':
            this.builderService.headerBtn = 'e-commerce/settings'
            break
          case '/orders/single-order':
            this.builderService.headerBtn = 'orders/single-order'
            break
          case '/points':
            this.builderService.headerBtn = 'points'
            break
          case '/android':
            this.builderService.headerBtn = 'android'
            break
          case '/ios':
            this.builderService.headerBtn = 'ios'
            break
          case '/e-commerce/payments':
            this.builderService.headerBtn = 'paymentSettingsForm'
            break
          case '/globals':
            this.builderService.globals = true
            this.builderService.headerBtn = 'globals'
            this.blockDataService.sendSelectedView({ view_name: 'home', view_id: 1, title: 'home' })
            break
          case '/':
            this.builderService.headerBtn = 'builderView'
            break
          default:
            this.builderService.headerBtn = ''
            break
        }

        this.getGlobalHeader(this.builderService.selectedProject)
        this.getGlobalFooter(this.builderService.selectedProject)
        this.gaService.pageView(route_event.url, 'Brickit - ' + route_event.url)
        this.gaService.gtag('config', environment.gtm)
        this.builderService.logData('Navigated to ' + route_event.url, 'builder page navigation', 'Navigated to ' + route_event.url).subscribe(
          (response) => {},
          (err) => {}
        )
      }
    })
  }

  switchLanguage(language: string): void {
    // Set the selected language
    this.LanguageService.setLanguage(language)
    // Load translations for the selected language
    this.LanguageService.loadTranslations(language).then(() => {
      // Set the active language for ngx-translate
      this.translate.use(language)
    })
  }

  async ngAfterViewInit() {
    this.pluginsService.setPluginsServices({})
  }

  getInitialProjectConfig = () => {
    /**
     * Get project Configuration
     */
    this.builderService.getProjectConfig().subscribe(
      (config: any) => {
        if (config !== false) {
          this.blockDataService.woocommerceActive = true
        }
      },
      (err) => {}
    )
  }

  howItWorksDialog = () => {
    const dialogRef = this.dialog.open(WorksDialogComponent, {
      width: '700px',
      maxWidth: '80%',
      data: { name: 'builderHome' }
    })

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed')
    })
  }

  getProjectStatus = (projectId: any, type: string = 'default') => {
    this.builderService.showUpdateProgress = true
    this.builderService.selectedProject = projectId
    this.builderService.getProject(projectId).subscribe((project: any) => {
      // Cache the response
      const pricingPackagesByRegion = {
        usd: environment.pricing_packages,
        egypt: environment.egypt_pricing_packages,
        emirates: environment.emirates_pricing_packages,
        saudi: environment.saudi_pricing_packages,
        test: environment.test_pricing_packages
      }
      const foundPackage = Object.values(pricingPackagesByRegion).find((packages) => packages?.some((pkg) => pkg?.pricing_id === JSON.parse(project?.premium?.[0]?.package || '{}')?.price_id))
      // Default to USD if no package is found
      project.package = foundPackage ?? environment.pricing_packages
      if (!project.premium || project.premium.length === 0) {
        project.premium = [
          {
            package: JSON.stringify({
              name: 'Stacks Plans',
              type: 'Growth',
              period: 'quarterly',
              price_id: 'price_1PxrXGDPC5jsdjOinEakBsMg'
            })
          }
        ]
      }
      this.builderService.user_type = project.user_type
      this.blockDataService.sendProjectCached(project)
      this.builderService.cacheProjectReq.response = project
      this.builderService.projectStatus = project.details[0].status
      this.builderService.projectAccess = project.details[0].access
      this.builderService.projectStartDate = project.details[0].start_date
    })
  }

  getPosts = (projectId: number, args: object) => {
    this.builderService.showUpdateProgress = true
    this.postsService.getPosts(projectId, args).subscribe(
      (response) => {
        this.blockDataService.sendPostsCached(response)
      },
      (err) => {}
    )
    return true
  }

  getProducts = (projectId: number, args: object) => {
    this.builderService.showUpdateProgress = true
    this.productsService.getProducts(projectId, args).subscribe(
      (response) => {
        this.blockDataService.productsCached = true
        this.builderService.showUpdateProgress = false
        this.blockDataService.sendProductsCached(response)
      },
      (err) => {}
    )
    return true
  }
  /**
   * Clears Token and redirects to login page
   */
  logout = () => {
    this.loginService.logout()
    this.router.navigate(['/login'], { relativeTo: this.route })
  }
  nextTourStep = () => {
    console.log(this.currentPage)
    switch (this.currentPage) {
      case '/':
        this.router.navigate(['/products'], { relativeTo: this.route })
        break
      case '/views':
        this.router.navigate(['/products'], { relativeTo: this.route })
        break
      case '/products':
        this.router.navigate(['/products/new-product'], { relativeTo: this.route })
        break
      case '/products/new-product':
        this.router.navigate(['/app-preview'], { relativeTo: this.route })
        break
      case '/app-preview':
        // Demo Completed Popup, now feel free to modify the content that you want
        //
        let dialogRef = this.dialog.open(WorksDialogComponent, {
          width: '700px',
          maxWidth: '80%',
          data: { name: 'tourCompleted' }
        })
        dialogRef.afterClosed().subscribe((result) => {
          console.log('The dialog was closed')
          // localStorage.setItem('mainScreen_Self_LTD', 'closed');
          this.builderService.setTour(false).subscribe((response) => {
            this.router.navigate(['/'], { relativeTo: this.route })
          })
        })
        break
      default:
        break
    }
  }

  validateTour = () => {
    if (this.currentPage) {
      if (this.currentPage.includes('templates') || this.currentPage.includes('login') || this.currentPage.includes('signup')) {
        return false
      } else {
        return true
      }
    } else {
      return false
    }
  }

  showSidebar = () => {
    if (this.currentPage.includes('templates') || this.currentPage.includes('site-type') || this.currentPage.includes('applicationsettings') || this.currentPage.includes('products') || this.currentPage.includes('app-preview') || this.currentPage.includes('login') || this.currentPage.includes('signup')) {
      return false
    } else {
      return true
    }
  }
}
