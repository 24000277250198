<section class="stacksBlockContainer">
  <div id="settingsTabs" *ngIf="checkSettings()">
    <a (click)="showDataTab()" [ngClass]="checkData() ? 'active' : ''">{{ 'Data' | translate }}</a>
    <a (click)="showStyleTab()" [ngClass]="checkStyle() ? 'active' : ''">{{ 'Style' | translate }}</a>
  </div>
  <!-- Sidebar -->
  <div *ngIf="checkSidebar()" class="blockSidebar" (click)="appendBlock()">
    <img src="assets/images/blocks/text.png" alt="" srcset="" />
    <p>{{ 'text / html' | translate }}</p>
  </div>
  <!-- Mobile Preview -->
  <div class="previewText previewMobile" *ngIf="checkPreview()" id="{{ 'blockid' + blockId }}" cdkDrag [cdkDragData]="blockId">
    <div class="blockControlHandlers" *ngIf="!builderService.globals">
      <div class="editBlock blockActionBtn" (click)="showData(blockId)">
        <mat-icon>edit</mat-icon>
      </div>
      <div class="blockHandle blockActionBtn" cdkDragHandle>
        <mat-icon>open_with</mat-icon>
      </div>
      <div class="deleteBlock blockActionBtn" (click)="deleteBlock(blockId)">
        <mat-icon>close</mat-icon>
      </div>
    </div>
    <div class="blockPlaceholder" *cdkDragPlaceholder></div>
    <div [ngStyle]="getBlockFields(blockId)?.style" [ngClass]="getBlockFields(blockId).textClass?.textStyle + getBlockFields(blockId).textClass?.textSize" [innerHTML]="getBlockFields(blockId).data?.value | safeHtml"></div>
    <!-- <p [ngStyle]="getBlockFields(blockId)?.style" *ngIf="builderService.globals">This is a sample text to adjust the fonts</p> -->
  </div>
  <!-- Data and Style Tabs -->
  <div class="previewFields" *ngIf="checkData()">
    <editor
      (onSelectionChange)="textChange($event, blockId)"
      apiKey="dh6nv3idqamf38bn1tnknqxd0qn9vz2yli1zjpjehily2stk"
      initialValue="<p>{{ getBlockFields(blockId).data?.value }}</p>"
      [init]="{
        base_url: '/tinymce',
        suffix: '.min',
        height: 320,
        menubar: false,
        plugins: ['lists', 'help', 'code'],
        toolbar:
          'code | undo redo | bold italic | \
      alignleft aligncenter alignright alignjustify | \
      bullist numlist outdent indent | help'
      }"
    ></editor>
  </div>
  <div class="styleFields" *ngIf="checkStyle()">
    <h4 class="listTitle">{{ 'Fonts' | translate }}</h4>
    <!-- <mat-divider></mat-divider> -->
    <!-- <mat-form-field appearance="standard">
      <mat-label>Font Family</mat-label>
      <mat-select (selectionChange)="styleChange('font-family', blockId, $event, 'select')"
        value="{{getBlockFields(blockId).style['font-family']}}">
        <mat-option *ngFor="let fontFamily of fontFamilies" [value]="fontFamily.id">
          {{fontFamily.name}}
        </mat-option>
      </mat-select>
    </mat-form-field> -->
    <mat-form-field appearance="outline">
      <mat-label>{{ 'Font Style' | translate }}</mat-label>
      <mat-select (selectionChange)="styleChange('textStyle', blockId, $event, 'select', 'textClass')" value="{{ getBlockFields(blockId).textClass?.textStyle }}">
        <mat-option value="heading">
          {{ 'Heading' | translate }}
        </mat-option>
        <mat-option value="medium">
          {{ 'Medium' | translate }}
        </mat-option>
        <mat-option value="regular">
          {{ 'Regular' | translate }}
        </mat-option>
        <mat-option value="semibold">
          {{ 'Semi Bold' | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field appearance="outline">
      <mat-label>{{ 'Font Size' | translate }}</mat-label>
      <mat-select (selectionChange)="styleChange('textSize', blockId, $event, 'select', 'textClass')" value="{{ getBlockFields(blockId).textClass?.textSize }}">
        <mat-option value="2XL"> 2XL </mat-option>
        <mat-option value="XL"> XL </mat-option>
        <mat-option value="L">
          {{ 'large' | translate }}
        </mat-option>
        <mat-option value="M">
          {{ 'Medium' | translate }}
        </mat-option>
        <mat-option value="S">
          {{ 'small' | translate }}
        </mat-option>
        <mat-option value="XS"> XS </mat-option>
        <mat-option value="2XS"> 2XS </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-label>{{ 'color' | translate }}</mat-label>
    <mat-form-field>
      <p class="colorPickerValue">{{ getBlockFields(blockId).style?.color }}</p>
      <label for="color" class="colorLabel">
        <input type="color" id="color" matInput class="colorPicker" name="color" (change)="styleChange('color', blockId, $event, 'text')" value="{{ getBlockFields(blockId).style?.color }}" />
      </label>
    </mat-form-field>
    <h4 class="listTitle">{{ 'Margins' | translate }}</h4>
    <mat-divider></mat-divider>
    <ul>
      <li>
        <mat-label>{{ 'Margin Top' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="-100" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['margin-top']?.slice(0, -2) }}" (change)="styleChange('margin-top', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
      <li>
        <mat-label>{{ 'Margin Right' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="-100" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['margin-right']?.slice(0, -2) }}" (change)="styleChange('margin-right', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
      <li>
        <mat-label>{{ 'Margin Bottom' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="-100" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['margin-bottom']?.slice(0, -2) }}" (change)="styleChange('margin-bottom', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
      <li>
        <mat-label>{{ 'Margin Left' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="-100" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['margin-left']?.slice(0, -2) }}" (change)="styleChange('margin-left', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
    </ul>
    <mat-label>{{ 'Paddings' | translate }}</mat-label>

    <mat-divider></mat-divider>
    <ul>
      <li>
        <mat-label>{{ 'Padding Top' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="1" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['padding-top']?.slice(0, -2) }}" (change)="styleChange('padding-top', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
      <li>
        <mat-label>{{ 'Padding Right' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="1" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['padding-right']?.slice(0, -2) }}" (change)="styleChange('padding-right', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
      <li>
        <mat-label>{{ 'Padding Bottom' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="1" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['padding-bottom']?.slice(0, -2) }}" (change)="styleChange('padding-bottom', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
      <li>
        <mat-label>{{ 'Padding Left' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="1" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['padding-left']?.slice(0, -2) }}" (change)="styleChange('padding-left', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
    </ul>
  </div>
</section>
