<div id="changelogWrapper">
  <!-- <img src="assets/images/stacks.png" alt="Stacks"> -->
  <h1>Changelog</h1>

  <mat-card>
    <mat-card-title>Version 6.8</mat-card-title>
    <mat-card-subtitle>19-2-2025</mat-card-subtitle>
    <mat-card-content>
      <h2>Features</h2>
      <ul>
        <li>
          <span class="greenPadder">App</span>
          <span class="bluePadder builderSign">Builder</span>
          Added support for multiple addresses.
        </li>
        <li>
          <span class="greenPadder">App</span>
          <span class="bluePadder builderSign">Builder</span>
          Implemented estimated delivery time feature.
        </li>
        <li>
          <span class="greenPadder">App</span>
          <span class="bluePadder builderSign">Builder</span>
          Added search page.
        </li>
        <li>
          <span class="greenPadder">App</span>
          <span class="bluePadder builderSign">Builder</span>
          Integrated related products in builder and product pages.
        </li>
        <li>
          <span class="greenPadder">App</span>
          <span class="bluePadder builderSign">Builder</span>
          Added option to hide product names.
        </li>
        <li>
          <span class="greenPadder">App</span>
          <span class="bluePadder builderSign">Builder</span>
          Added option to change the back button to a menu button.
        </li>
        <li>
          <span class="greenPadder">App</span>
          <span class="bluePadder builderSign">Builder</span>
          Added size chart in product details.
        </li>
        <li>
          <span class="greenPadder">App</span>
          Adjusted image dimensions on the Single Product Page to match the required portrait size
        </li>
        <li>
          <span class="greenPadder">App</span>
          Implemented wishlist.
        </li>
      </ul>

      <h2>Bug Fixes & Improvements</h2>
      <ul>
        <li><span class="bluePadder builderSign">Builder</span> Adjusted the design of the order page.</li>
        <li><span class="bluePadder builderSign">Builder</span> Added more text translations.</li>
        <li><span class="bluePadder builderSign">Builder</span> Fixed issue where the auto-translate button didn't work when no countries were set in shipping.</li>
        <li><span class="bluePadder builderSign">Builder</span> Added support for translating numbers to Arabic.</li>
        <li><span class="greenPadder">App</span> Displayed the Product SKU on a single product page.</li>
        <li><span class="greenPadder">App</span> Translated the Contact Us page.</li>
      </ul>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-title>Version 6.7</mat-card-title>
    <mat-card-subtitle>1-2-2025</mat-card-subtitle>
    <mat-card-content>
      <h2>Features</h2>
      <ul>
        <li>
          <span class="greenPadder">App</span>
          <span class="bluePadder builderSign">Builder</span>
          Upgraded points and rewards system to support multiple points and rewards.
        </li>
      </ul>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-title>Version 6.6</mat-card-title>
    <mat-card-subtitle>20-1-2025</mat-card-subtitle>
    <mat-card-content>
      <h2>Bug Fixes & Improvements</h2>
      <ul>
        <li><span class="bluePadder builderSign">Builder</span> Changed project password requirements to include only lowercase letters and numbers.</li>
        <li><span class="greenPadder">App</span> Adjusted design for toasters displaying new orders.</li>
      </ul>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-title>Version 6.5</mat-card-title>
    <mat-card-subtitle>8-1-2025</mat-card-subtitle>
    <mat-card-content>
      <h2>Features</h2>
      <ul>
        <li><span class="greenPadder">App</span> Redesigned the first screen of the sample app.</li>
        <li>
          <span class="greenPadder">App</span>
          <span class="bluePadder builderSign">Builder</span> Added translations for countries and cities.
        </li>
        <li><span class="greenPadder">App</span> Enabled search for products or categories in different languages.</li>
      </ul>
      <h2>Bug Fixes</h2>
      <ul>
        <li><span class="greenPadder">App</span> Removed posts from the search page.</li>
        <li><span class="greenPadder">App</span> Improved the design of the search page.</li>
        <li><span class="greenPadder">App</span> Translated product variations.</li>
        <li><span class="greenPadder">App</span> Adjusted RTL styling in product details.</li>
        <li><span class="bluePadder builderSign">Builder</span> Updated push notification styles.</li>
        <li><span class="bluePadder builderSign">Builder</span> Displayed user nicknames.</li>
        <li><span class="bluePadder builderSign">Builder</span> Corrected various typos.</li>
      </ul>
    </mat-card-content>
  </mat-card>

  <mat-card>
    <mat-card-title>Version 6.4</mat-card-title>
    <mat-card-subtitle>1-1-2025</mat-card-subtitle>
    <mat-card-content>
      <p>Feature</p>
      <ul>
        <li><span class="greenPadder">App</span> Firebase Analytics</li>
      </ul>
      <p>Bug Fixes</p>
      <ul>
        <li><span class="greenPadder">App</span> Min & max sale and regular displayed prices issue</li>
        <li><span class="greenPadder">App</span> Adjust the search page to be as the others, in terms of structure and design</li>
        <li><span class="bluePadder builderSign">Builder</span> Login & signup pages style</li>
        <li><span class="bluePadder builderSign">Builder</span> Resolve Errors from Translating into Multiple Languages</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Version 6.3</mat-card-title>
    <mat-card-subtitle>24-12-2024</mat-card-subtitle>
    <mat-card-content>
      <p>Feature</p>
      <ul>
        <li><span class="greenPadder">App</span><span class="bluePadder builderSign">Builder</span> Choose specific page to redirect the user to after signing up</li>
      </ul>
      <p>Bug Fixes</p>
      <ul>
        <li><span class="greenPadder">App</span> Searching input bug</li>
        <li><span class="greenPadder">App</span> Sticky header style</li>
        <li><span class="greenPadder">App</span> Translate footer text</li>
        <li><span class="bluePadder builderSign">Builder</span> Latest orders categorized on top</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Version 6.21</mat-card-title>
    <mat-card-subtitle>12-12-2024</mat-card-subtitle>
    <mat-card-content>
      <p>Feature</p>
      <ul>
        <li><span class="bluePadder builderSign">Builder</span> Updated Preview: Added QR code to download sample apps.</li>
        <li><span class="bluePadder builderSign">Builder</span> Adjusted fonts for Points & Rewards.</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Version 6.2</mat-card-title>
    <mat-card-subtitle>26-11-2024</mat-card-subtitle>
    <mat-card-content>
      <p>Feature</p>
      <ul>
        <li><span class="greenPadder">App</span> <span class="bluePadder builderSign">Builder</span> Human Readable Order Id</li>
      </ul>
      <p>Bug Fixes</p>
      <ul>
        <li><span class="greenPadder">App</span> Footer in my orders page</li>
        <li><span class="greenPadder">App</span> The header and footer on the About page are fixed</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Version 6.11</mat-card-title>
    <mat-card-subtitle>26-11-2024</mat-card-subtitle>
    <mat-card-content>
      <p>Feature</p>
      <ul>
        <li><span class="greenPadder">App</span> Change project samples</li>
      </ul>
      <p>Bug Fixes</p>
      <ul>
        <li><span class="greenPadder">App</span> Resolved an issue on the cart page where no message was displayed when the cart was empty. Added a placeholder message: "Your cart is empty."</li>
        <li><span class="greenPadder">App</span> Mobile number is now optional for returning customers starting from their second order, enhancing the checkout experience.</li>
        <li><span class="greenPadder">App</span> Resolved an issue where category names were not translating correctly on the single page</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Version 6.1</mat-card-title>
    <mat-card-subtitle>21-11-2024</mat-card-subtitle>
    <mat-card-content>
      <p>Feature</p>
      <ul>
        <li><span class="greenPadder">App</span> Auth0 login system</li>
        <li><span class="bluePadder">Builder</span> New order notification</li>
      </ul>
      <p>Bug Fixes</p>
      <ul>
        <li><span class="greenPadder">App</span> Cart page back button bug</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Version 6</mat-card-title>
    <mat-card-subtitle>27-9-2024</mat-card-subtitle>
    <mat-card-content>
      <p>Feature</p>
      <ul>
        <li><span class="greenPadder">App</span><span class="bluePadder builderSign">Builder</span> Points and rewards system</li>
        <li><span class="greenPadder">App</span><span class="bluePadder builderSign">Builder</span> Coupon management system</li>
      </ul>
      <p>Bug Fixes</p>
      <ul>
        <li><span class="greenPadder">App</span> Mobile number is not required after the second order</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Version 5.7</mat-card-title>
    <mat-card-subtitle>15-7-2024</mat-card-subtitle>
    <mat-card-content>
      <p>Feature</p>
      <ul>
        <li><span class="greenPadder">App</span><span class="bluePadder builderSign">Builder</span> Multilingual</li>
        <li><span class="greenPadder">App</span> Add delete order and go to the home button in the order summary</li>
      </ul>
      <p>Bug Fixes</p>
      <ul>
        <li><span class="greenPadder">App</span> Fixing style conflict</li>
        <li><span class="greenPadder">App</span> Submit order design system</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Version 5.4</mat-card-title>
    <mat-card-subtitle>10-2-2024</mat-card-subtitle>
    <mat-card-content>
      <p>Feature</p>
      <ul>
        <li><span class="greenPadder">App</span> Add multi-country selection</li>
        <li><span class="greenPadder">App</span> Update swiper</li>
        <li><span class="greenPadder">App</span> Add the country option on checkout pages</li>
      </ul>
      <p>Bug Fixes</p>
      <ul>
        <li><span class="bluePadder">Builder</span> Fix FooterActive Error</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Version 5.2</mat-card-title>
    <mat-card-subtitle>28-05-2023</mat-card-subtitle>
    <mat-card-content>
      <p>Feature</p>
      <ul>
        <li><span class="bluePadder">Builder</span> Builder version history</li>
        <li><span class="greenPadder">App</span> Checkout fields are editable from the builder</li>
      </ul>
      <p>Bug Fixes</p>
      <ul>
        <li><span class="greenPadder">App</span> Fix saving errors</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Version 5.1.1</mat-card-title>
    <mat-card-subtitle>18-05-2023</mat-card-subtitle>
    <mat-card-content>
      <p>Feature</p>
      <ul>
        <li><span class="greenPadder">App</span> Allow unlimited blocks to be added in any view</li>
        <li><span class="greenPadder">App</span> Allow Posts Block in custom views</li>
      </ul>
      <p>Bug Fixes</p>
      <ul>
        <li><span class="greenPadder">App</span> Fix Arabic Variations in Variable Products retrieval</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Version 5.1</mat-card-title>
    <mat-card-subtitle>10-05-2023</mat-card-subtitle>
    <mat-card-content>
      <p>Feature</p>
      <ul>
        <li><span class="greenPadder">App</span> Allow users to signup, login and checkout with their phone number instead of email</li>
        <li><span class="bluePadder">Builder</span> possibility to choose between required phone or email on signup</li>
        <li><span class="greenPadder">App</span> Loyalty Points and Rewards feature integration, integration with plugin WooCommerce Points and Rewards</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Version 5.0.7</mat-card-title>
    <mat-card-subtitle>28-04-2023</mat-card-subtitle>
    <mat-card-content>
      <p>Feature</p>
      <ul>
        <li><span class="darkBluePadder">Plugin</span> Intro Screen for the plugin with important action buttons to the builder</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Version 5.0.6</mat-card-title>
    <mat-card-subtitle>20-04-2023</mat-card-subtitle>
    <mat-card-content>
      <p>Bug Fixes</p>
      <ul>
        <li><span class="bluePadder">Builder</span> Fix Product selection in image and button</li>
        <li><span class="greenPadder">App</span> Fix Disable Guest Login</li>
        <li><span class="greenPadder">App</span> Make postalcode, lastname in checkout page not required fields</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Version 5.0.5</mat-card-title>
    <mat-card-subtitle>12-04-2023</mat-card-subtitle>
    <mat-card-content>
      <p>Bug Fixes</p>
      <ul>
        <li><span class="bluePadder">Builder</span> Posts Block with Categories Selection</li>
        <li><span class="bluePadder">Builder</span> Select posts in Image and Buttons Blocks</li>
        <li><span class="bluePadder">Builder</span> Global Header and Footer rendering with their icons</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Version 5.0.4</mat-card-title>
    <mat-card-subtitle>05-04-2023</mat-card-subtitle>
    <mat-card-content>
      <p>Bug Fixes</p>
      <ul>
        <li><span class="greenPadder">App</span> Remove Addons dependency on single view</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Version 5.0.3</mat-card-title>
    <mat-card-subtitle>09-03-2023</mat-card-subtitle>
    <mat-card-content>
      <p>Bug Fixes</p>
      <ul>
        <li><span class="greenPadder">App</span> Delete Order and Delete Coupon and Delete Cart item</li>
        <li><span class="greenPadder">App</span> Categories linked from Button, Image and Footer</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Version 5.0.2</mat-card-title>
    <mat-card-subtitle>02-03-2023</mat-card-subtitle>
    <mat-card-content>
      <p>Bug Fixes</p>
      <ul>
        <li><span class="greenPadder">App</span> Fix posts limit</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Version 5.0.1</mat-card-title>
    <mat-card-subtitle>02-27-2023</mat-card-subtitle>
    <mat-card-content>
      <p>Bug Fixes</p>
      <ul>
        <li><span class="greenPadder">App</span> Fix Categories Rendering</li>
        <li><span class="greenPadder">App</span> Fix Posts Rendering</li>
        <li><span class="greenPadder">App</span> Fix Products Rendering</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Version 5.0</mat-card-title>
    <mat-card-subtitle>01-02-2023</mat-card-subtitle>
    <mat-card-content>
      <p>Feature</p>
      <ul>
        <li>Rest of Views are Editable</li>
        <li>Possibility to add, edit or delete custom view</li>
        <li>Link from any view to another</li>
        <li>Add Global Header</li>
        <li>Add Header per view</li>
        <li>Possibility to disable Header on specific views</li>
        <li>Add Global Footer</li>
        <li>Add Footer per view</li>
        <li>Possibility to disable Footer on specific views</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Version 4.8.3</mat-card-title>
    <mat-card-subtitle>20-12-2022</mat-card-subtitle>
    <mat-card-content>
      <p>Feature</p>
      <ul>
        <li>Handle multiple projects connected to the same site</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Version 4.8.2</mat-card-title>
    <mat-card-subtitle>27-11-2022</mat-card-subtitle>
    <mat-card-content>
      <p>Bug Fixes</p>
      <ul>
        <li>Fix rendering membership courses with categories selected</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Version 4.8</mat-card-title>
    <mat-card-subtitle>23-11-2022</mat-card-subtitle>
    <mat-card-content>
      <p>Features</p>
      <ul>
        <li>Integrate with Paid Memberships Pro plugin to render courses and lessons</li>
        <li>Full Flow for the application for the memberships</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Version 4.7.3</mat-card-title>
    <mat-card-subtitle>18-10-2022</mat-card-subtitle>
    <mat-card-content>
      <p>Bug Fixes</p>
      <ul>
        <li>Fix Products Block Rendering</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Version 4.7.2</mat-card-title>
    <mat-card-subtitle>04-10-2022</mat-card-subtitle>
    <mat-card-content>
      <p>Features</p>
      <ul>
        <li>Change servers infrastructure</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Version 4.7.1</mat-card-title>
    <mat-card-subtitle>09-08-2022</mat-card-subtitle>
    <mat-card-content>
      <p>Bug Fixes</p>
      <ul>
        <li>Fix Mobile Checkout</li>
        <li>Fix Switching between views conflict in data</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Version 4.7</mat-card-title>
    <mat-card-subtitle>01-08-2022</mat-card-subtitle>
    <mat-card-content>
      <p>Features</p>
      <ul>
        <li>Redesign the Application to be trendy</li>
      </ul>
      <p>Bug Fixes</p>
      <ul>
        <li>Fix Youtube Fullscreen view</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Version 4.6.9</mat-card-title>
    <mat-card-subtitle>08-06-2022</mat-card-subtitle>
    <mat-card-content>
      <p>Features</p>
      <ul>
        <li>Post Block Renders Custom Post Types</li>
        <li>Footer Menu Items Renders Custom Post Types</li>
        <li>Native Checkout along with webview checkout</li>
      </ul>
      <p>Bug Fixes</p>
      <ul>
        <li>Load more in products block</li>
        <li>Fix Youtube Flicker Effect</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Version 4.6.8</mat-card-title>
    <mat-card-subtitle>25-05-2022</mat-card-subtitle>
    <mat-card-content>
      <p>Features</p>
      <ul>
        <li>Stacks Translation: Multilingual Support with Polylang integration and Hayyan for WooCommerce, Added default arabic translation</li>
        <li>Stacks Plugin code restructured and cleanedup</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Version 4.6.7</mat-card-title>
    <mat-card-subtitle>16-05-2022</mat-card-subtitle>
    <mat-card-content>
      <p>Features</p>
      <ul>
        <li>Stacks Analytics: Viewing Mobile Visits Statistics and Mobile App Orders</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Version 4.6.6</mat-card-title>
    <mat-card-subtitle>10-05-2022</mat-card-subtitle>
    <mat-card-content>
      <p>Features</p>
      <ul>
        <li>Integration with Google Admob Banner Ads for Android and IOS</li>
        <li>Fading animation on application opening</li>
      </ul>
      <p>Bug Fixes</p>
      <ul>
        <li>Time needed to correctly load header and footer is fixed</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Version 4.6.5</mat-card-title>
    <mat-card-subtitle>13-04-2022</mat-card-subtitle>
    <mat-card-content>
      <p>Features</p>
      <ul>
        <li>Youtube Video updated to work with normal url, just paste your url and we will convert it for you</li>
        <li>Each Trial will work with an independent site to prevent conflicts in the data</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Version 4.6.4</mat-card-title>
    <mat-card-subtitle>28-03-2022</mat-card-subtitle>
    <mat-card-content>
      <p>Features</p>
      <ul>
        <li>New Builder Design that fits better with most of the screen sizes</li>
        <li>Cleanup all icons</li>
        <li>Remove update buttons from incorrect places</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Version 4.6.3</mat-card-title>
    <mat-card-subtitle>17-03-2022</mat-card-subtitle>
    <mat-card-content>
      <p>Features</p>
      <ul>
        <li>Header icons can be enabled or disabled</li>
      </ul>
      <p>Bug Fixes</p>
      <ul>
        <li>Header Shadow appears in expanded search and non expanded</li>
        <li>Products Block : Select Products by Category retrieves correct products now "Plugin Version 4.5.7"</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Version 4.6.2</mat-card-title>
    <mat-card-subtitle>28-02-2022</mat-card-subtitle>
    <mat-card-content>
      <p>Features</p>
      <ul>
        <li>Handle no image in categories block case</li>
      </ul>
      <p>Bug Fixes</p>
      <ul>
        <li>Fixed External URL in Footer</li>
        <li>Render Categories from all languages</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Version 4.6.1</mat-card-title>
    <mat-card-subtitle>21-02-2022</mat-card-subtitle>
    <mat-card-content>
      <p>Features</p>
      <ul>
        <li>Mobile Application Redesigned</li>
      </ul>
      <p>Bug Fixes</p>
      <ul>
        <li>Mobile Application Fonts Adjusted</li>
        <li>Mobile Application Margins Fixed</li>
        <li>Mobile Application Products and Categories Blocks images Fixed</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Version 4.6</mat-card-title>
    <mat-card-subtitle>27-01-2022</mat-card-subtitle>
    <mat-card-content>
      <p>Features</p>
      <ul>
        <li>Menu in the Footer</li>
      </ul>
      <p>Bug Fixes</p>
      <ul>
        <li>Show error messages in details that happens while building the app</li>
        <li>Disable Guest User Feature Fixed</li>
        <li>Fix Push Notifications Users Listing</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Version 4.5.1</mat-card-title>
    <mat-card-subtitle>16-01-2022</mat-card-subtitle>
    <mat-card-content>
      <p>Features</p>
      <ul>
        <li>Preview Real app in the builder</li>
        <li>Added How it works to all pages</li>
      </ul>
      <p>Bug Fixes</p>
      <ul>
        <li>[Mobile App] Fix Back Button Color to match the header icon colors</li>
        <li>[Mobile App] Trim all the shortcodes from the product description page</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Version 4.5</mat-card-title>
    <mat-card-subtitle>06-01-2022</mat-card-subtitle>
    <mat-card-content>
      <p>Features</p>
      <ul>
        <li>Add Possibility to Edit The design of Pages other than Home</li>
        <li>Edit the Design of the Categories Page through Stacks Builder</li>
        <li>Edit the Design of the Single Products Page through Stacks Builder</li>
        <li>Modify the Projects Design</li>
        <li>Add capability to deactivate or activate a project</li>
      </ul>
      <br />
      <p>Bug Fixes</p>
      <ul>
        <li>Fix Video rendering in the application</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <mat-card>
    <mat-card-title>Version 4.4.1</mat-card-title>
    <mat-card-subtitle>18-11-2021</mat-card-subtitle>
    <mat-card-content>
      <p>Features</p>
      <ul>
        <li>Add Global Font Families and Font Sizes that reflects in the whole app</li>
        <li>Add 1000+ Google Font to the Application</li>
        <li>New Layout for Products 3 Columns Grid</li>
        <li>Add to cart button for Products in Single Category Page</li>
        <li>Redesign Single Category with subcategories page</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <br />
  <mat-card>
    <mat-card-title>Version 4.4</mat-card-title>
    <mat-card-subtitle>31-10-2021</mat-card-subtitle>
    <mat-card-content>
      <p>Features</p>
      <ul>
        <li>1 Click Apple Build Process with automatic uploader to itunes</li>
        <li>HTML in Block, Add Fonts, Horizontal Lines etc ...</li>
      </ul>
      <br />
      <p>Bug Fixes</p>
      <ul>
        <li>Checkout Editor for WooCommerce Plugin conflict fix</li>
        <li>Empty Page after Update</li>
        <li>Content Settings wasn't rendering data correctly in mobile app</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <br />
  <mat-card>
    <mat-card-title>Version 4.3</mat-card-title>
    <mat-card-subtitle>21-10-2021</mat-card-subtitle>
    <mat-card-content>
      <p>Features</p>
      <ul>
        <li>Introducing Button Block, Now you can add a button that redirects to your single post, product, category or an external webview</li>
        <li>Images are now linked and can redirect to your single post, product, category or an external webview</li>
        <li>An option to make Search expanded by default</li>
        <li>AAB Files for Google Play, this is the extension required to be uploaded to google play instead of APK Files</li>
        <li>Text Block, now you can colorize each part of the Text independently</li>
        <li>You can now view Blog posts with specific categories</li>
      </ul>
      <br />
      <p>Bug Fixes</p>
      <ul>
        <li>Issue with Free Trial fixed, now if the free trial ends then you can create a new trial from within the builder</li>
        <li>Building Issue stucks at 66% fixed</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <br />
  <mat-card>
    <mat-card-title>Version 4.2</mat-card-title>
    <mat-card-subtitle>1-10-2021</mat-card-subtitle>
    <mat-card-content>
      <p>Features</p>
      <ul>
        <li>Introducing Posts Block, Now you can integrate with your Magazine or News</li>
      </ul>
      <br />
      <p>Bug Fixes</p>
      <ul>
        <li>Add Dimension requirements over icons and splash screens</li>
        <li>Video Block Blinking Issue Fixed</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <br />
  <mat-card>
    <mat-card-title>Version 4.1</mat-card-title>
    <mat-card-subtitle>1-9-2021</mat-card-subtitle>
    <mat-card-content>
      <p>Features</p>
      <ul>
        <li>Send unlimited Push Notifications to your customers with offers or new products</li>
        <li>Allow your customers to login with Facebook Login</li>
      </ul>
      <br />
      <p>Bug Fixes</p>
      <ul>
        <li>blocks negative margins is introduced to decrease the margin of the elements</li>
        <li>login issues between stacks and builder</li>
        <li>Products and Categories wasn't working on some Websites, now it is fixed</li>
      </ul>
    </mat-card-content>
  </mat-card>
  <br />
  <mat-card>
    <mat-card-title>Version 4</mat-card-title>
    <mat-card-subtitle>1-8-2021</mat-card-subtitle>
    <mat-card-content>
      <p>Features</p>
      <ul>
        <li>Launch of Stacks Drag and Drop builder</li>
        <li>Total Revamp of the User Experience</li>
        <li>10X Faster and More reliable experience</li>
        <li>Develop the builder to be single page application</li>
      </ul>
    </mat-card-content>
  </mat-card>
</div>
