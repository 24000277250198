import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class SelfService {
  options = {
    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  }

  stacksURL = 'https://stacksmarket.co/wp-json/v4/'
  public allSubsData: any = []
  public allUnSubsData: any = []

  constructor(private http: HttpClient) {}

  getSelfTrials = () => {
    return this.http.get(this.stacksURL + 'getSelfTrials?password=Creiden@SALESSTATS@')
  }

  getSelfSales = () => {
    return this.http.get(this.stacksURL + 'getSelfSalesAnalytics?password=Creiden@SALESSTATS@')
  }
}
