<mat-progress-bar mode="indeterminate" *ngIf="productProgress"></mat-progress-bar>
<div class="productsWrapper">
  <div class="tableWrapper">
    <div class="tableHeader">
      <div class="headerBtns">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'Select Languages' | translate }}</mat-label>
          <mat-select id="languageSelect" [(value)]="selectedLanguages" (selectionChange)="onLanguagesSelected($event)" multiple>
            <mat-option *ngFor="let language of languageCodes" [value]="language" [disabled]="isLanguageInTable(language)">
              {{ language }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>

    <table mat-table [dataSource]="language" class="LangTable mat-elevation-z8" matSort>
      <ng-container matColumnDef="language">
        <th mat-header-cell *matHeaderCellDef>{{ 'Languages' | translate }}</th>
        <td mat-cell *matCellDef="let lang">
          <ng-container>
            {{ lang.title }}
          </ng-container>
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="actionColumn">
          {{ 'Action' | translate }}
        </th>
        <td mat-cell *matCellDef="let lang" class="actionColumn">
          <ng-container *ngIf="language.length > 1">
            <button
              class="default"
              (click)="selectDefaultLanguage(lang.title)"
              [ngClass]="{
                defaultNotSelected: defaultLanguage !== lang.title,
                defaultSelected: defaultLanguage === lang.title
              }"
              [disabled]="defaultLanguage === lang.title"
            >
              {{ defaultLanguage === lang.title ? 'Default' : 'Set as Default' }}
            </button>
            <span class="material-icons material-icons-outlined deleteIcon" (click)="deleteRow(lang.title)">delete</span>
          </ng-container>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="['language', 'action']"></tr>
      <tr mat-row *matRowDef="let row; columns: ['language', 'action']"></tr>
    </table>
  </div>
</div>

<form [formGroup]="multilingual" id="multilingual" (ngSubmit)="onSubmit()"></form>
