import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { BuilderService } from '../builder-services/builder.service';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class cancelledUserGuard implements CanActivate {
  constructor(private builderService: BuilderService, private router: Router) {}
// i had to make a db request because of the timing to get the data
  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const projectId = this.builderService.selectedProject;
    if (!projectId) {
      this.router.navigate(['/']); 
      return of(false);
    }

    return this.builderService.getProject(projectId).pipe(
      map((response: any) => {
        const premiumStatus = response?.premium?.[0]?.status;
        
        if (premiumStatus === 'cancelled') {
          this.router.navigate(['/']); 
          return false;
        } else {
          console.log('Access granted to this page.');
          return true;
        }
      }),
      catchError((error) => {
        console.error('Error fetching project:', error);
        return of(false);
      })
    );
  }
}
