<div class="containerWrapper">
  <h1>Create User</h1>
  <form (ngSubmit)="onSubmit(f.value)" #f="ngForm">
    <mat-form-field appearance="outline">
      <mat-label>First Name</mat-label>
      <input required matInput name="firstName" [(ngModel)]="user.firstName" #firstNameInput="ngModel" />
      <mat-error>First Name is required.</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Last Name</mat-label>
      <input required matInput name="lastName" [(ngModel)]="user.lastName" #lastNameInput="ngModel" />
      <mat-error>Last Name is required.</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Email Address</mat-label>
      <input required matInput name="emailAddress" [(ngModel)]="user.emailAddress" #emailInput="ngModel" />
      <mat-error>Email Address is required.</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Phone Number</mat-label>
      <input required matInput name="phoneNumber" [(ngModel)]="user.phoneNumber" #phoneInput="ngModel" />
      <mat-error>Phone Number is required.</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>Password</mat-label>
      <input [type]="hidePassword ? 'password' : 'text'" required matInput name="password" [(ngModel)]="user.password" #passwordInput="ngModel" />
      <button mat-icon-button matSuffix (click)="hidePassword = !hidePassword" type="button">
        <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
      </button>
      <mat-error>Password is required.</mat-error>
    </mat-form-field>

    <mat-form-field appearance="outline">
      <mat-label>User Role</mat-label>
      <mat-select required id="selectedRole" required [(ngModel)]="user.selectedRole" name="selectedRole" #selectedRoleInput="ngModel">
        <mat-option value="" disabled>Select User Role</mat-option>
        <mat-option value="admin">Admin</mat-option>
        <mat-option value="orderManager">Order Manager</mat-option>
        <mat-option value="productManager">Product Manager</mat-option>
      </mat-select>
      <mat-error *ngIf="selectedRoleInput.errors?.required">Please choose a user role.</mat-error>
    </mat-form-field>

    <div class="actions">
      <button class="stacksBtn red" type="button" [routerLink]="['/users']" *ngIf="editPage">Delete</button>
      <button class="stacksBtn blue" type="submit">Save</button>
    </div>
  </form>
</div>
