import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  private menuSubject = new Subject<any>()

  constructor() {}

  getMenuItems = () => {
    return this.menuSubject.asObservable()
  }
  setMenuItem = (data: object) => {
    this.menuSubject.next(data)
  }
}
