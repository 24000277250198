<div class="pointsContainer">
  <div class="pointPart pointPartText tableWrapper">
    <div class="tableHeader">
      <h1>{{ 'List' | translate }}</h1>
      <div class="headerBtns">
        <div class="add-sample-form">
          <!-- <input matInput placeholder="{{ 'Search' | translate }}" (input)="applyFilter($event)" /> -->
          <h2>{{ '+ Add New App Sample' | translate }}</h2>
          <input type="number" placeholder="Project ID" [(ngModel)]="newSample.projectId" name="projectId" />
          <button (click)="addSample()" class="addButton" [ngClass]="{ validButton: newSample.projectId }" [disabled]="!newSample.projectId">{{ 'Add Sample' | translate }}</button>
        </div>
      </div>
    </div>

    <!-- Table to Display App Samples -->
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 userTable" matSort>
      <ng-container matColumnDef="img">
        <th mat-header-cell *matHeaderCellDef>{{ 'App Image' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <img [src]="element.img" alt="App Icon" />
        </td>
      </ng-container>

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>{{ 'App Name' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <ng-container matColumnDef="projectId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
          {{ 'Project ID' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">{{ element.projectId }}</td>
      </ng-container>

      <ng-container matColumnDef="creationDate">
        <th mat-header-cell *matHeaderCellDef>{{ 'Creation Date' | translate }}</th>
        <td mat-cell *matCellDef="let element">{{ element.creationDate }}</td>
      </ng-container>

      <ng-container matColumnDef="activation">
        <th mat-header-cell *matHeaderCellDef>
          {{ 'Activation' | translate }}
        </th>
        <td mat-cell *matCellDef="let element">
          <mat-slide-toggle [checked]="element.activation" (change)="toggleActivation(element)"></mat-slide-toggle>
        </td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>{{ 'Action' | translate }}</th>
        <td mat-cell *matCellDef="let element">
          <span class="material-icons material-icons-outlined deleteIcon" (click)="deleteSample(element)">delete</span>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>

    <mat-paginator #paginator [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons aria-label="Select page of periodic data"></mat-paginator>
  </div>
</div>
