import { Component, OnInit } from '@angular/core'
import { ActivatedRoute, Router } from '@angular/router'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { LoginService } from 'src/app/login/login.service'

@Component({
  selector: 'expired',
  templateUrl: './expired.component.html',
  styleUrls: ['./expired.component.scss']
})
export class ExpiredComponent implements OnInit {
  projectStatus = 'active'

  constructor(
    public builderService: BuilderService,
    public loginService: LoginService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    // this.builderService.showUpdateProgress = false;
    // if(this.builderService.projectAccess !== 'free' || this.builderService.trialEnded == false ) {
    //   this.router.navigate(['/'], { relativeTo: this.route });
    // }
    // this.builderService.getProject(this.builderService.selectedProject).subscribe((project: any) => {
    //   // Cache the response
    //   this.builderService.user_type = project.user_type;
    //   this.builderService.cacheProjectReq.response = project;
    //   if( project.details[0].status == 'active' ) {
    //     this.router.navigate(['/'], { relativeTo: this.route });
    //   }
    // });
  }

  /**
   * Clears Token and redirects to login page
   */
  logout = () => {
    this.loginService.logout()
    this.router.navigate(['/login'], { relativeTo: this.route })
  }
}
