<div class="mainWrapper">
  <div class="headerWrapper"></div>
  <div class="contentWrapper">
    <div class="imgWrapper">
      <img *ngIf="package.type === 'Starter'" src="../assets/images/subscriptionFlow/starter-inner-icon.png" />
      <img *ngIf="package.type === 'Growth'" src="../assets/images/subscriptionFlow/growth-inner-icon.png" />
    </div>
    <h2>You have successfully subscribed to Package</h2>
    <span>🎉</span>
    <p>{{package?.name}} - {{package?.type}} - {{package?.period}} Plan</p>
    <button [routerLink]="['/site-type']" class="stacksBtn blue">Start Now</button>
  </div>
</div>

<div id="middleContainerWrapper" *ngIf="package?.name == 'LTD'">
  <img src="assets/images/payment_success.png" alt="" />
  <h1>You have successfully purchased our <span class="premiumDetails">Lifetime Offer</span>🎉</h1>
  <button [routerLink]="['/site-type']" class="stacksBtn blue">Back to builder</button>
</div>
