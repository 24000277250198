import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class DiscordService {
  private webhookUrl = 'https://discord.com/api/webhooks/1329079027113263144/NWKjF1Gq7F-mAHxMoEfygvJjoK6RSAbWMDDTzAQK4IrfgrOgHQdPn8xmHGvEfYXy4Ae9' // Replace with your webhook URL

  constructor(private http: HttpClient) {}

  sendMessage(content: string, botname: string) {
    const body = {
      content: content,
      username: botname
    }
    return this.http.post(this.webhookUrl, body)
  }
}
