import { Component, OnInit } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { BlockDataService } from '../block-services/block-data.service'
import { BuilderService } from '../builder-services/builder.service'
import { SocialAuthService } from '@abacritt/angularx-social-login'
import { GoogleLoginProvider } from '@abacritt/angularx-social-login'
import { SignupService } from './signup.service'
import { LoginService } from '../login/login.service'
import { MixpanelService } from '../tracking/mixpanel.service'
import { GoogleAnalyticsService } from 'ngx-google-analytics'
import { environment } from 'src/environments/environment'
import { CountriesService } from '../builder-services/countries/countries.service'
import { PixelService } from 'ngx-multi-pixel'
import { AppSettingsService } from '../settings/services/app-settings.service'
import { LanguageService } from '../language.service'
import { TranslateService } from '@ngx-translate/core'

// @ts-ignore
var gapi = window.gapi || {}

@Component({
  selector: 'signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  signupToken: any

  signupProgress = false
  signupError = false
  signupErrorMsg = ''
  type = 'normalSignup'
  selectedCode: any
  preservedReturnURL: any = ''
  paymentIncluded = false
  GoogleLoginProvider = GoogleLoginProvider
  searchValues = this.countries.countryDialCodes

  // resolved(captchaResponse: any) {
  //   console.log(`Resolved captcha with response: ${captchaResponse}`);
  // }

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private builderService: BuilderService,
    private blockDataService: BlockDataService,
    private authService: SocialAuthService,
    private signupService: SignupService,
    private loginService: LoginService,
    private mixPanel: MixpanelService,
    private gaService: GoogleAnalyticsService,
    private translate: TranslateService,
    public countries: CountriesService,
    public languageService: LanguageService,
    private pixel: PixelService,
    private appSettings: AppSettingsService
  ) {
    let code = navigator.language
    if (this.countries.countryListAllIsoData.find((obj) => obj.code === code)) {
      this.countries.selectedCountry = [this.countries.countryListAllIsoData.find((obj) => obj.code === code)]
    }

    if (code !== 'en') {
      this.countries.selectedCountry.push(this.countries.countryListAllIsoData.find((obj) => obj.code === 'en'))
    }
  }

  signupForm: any = this.formBuilder.group({
    email: '',
    password: '',
    firstname: '',
    lastname: '',
    country_code: '',
    phone_number: '',
    email2: ''
  })

  ngOnInit(): void {
    this.route.paramMap.subscribe((params) => {
      console.log(params)
      const token = params.get('id')
      this.signupToken = token
      localStorage.setItem('token', this.signupToken)
      this.blockDataService.sendTokenData(this.signupToken)
    })

    this.preservedReturnURL = this.route.snapshot.queryParamMap.get('returnUrl')
    if (this.preservedReturnURL && this.preservedReturnURL.includes('payment')) {
      this.paymentIncluded = true
      // Do something if 'payment' is in the URL
    }

    if (this.route.snapshot.queryParamMap.get('coupon_id')) {
      this.builderService.selected_coupon_id = this.route.snapshot.queryParamMap.get('coupon_id')
    }

    if (this.route.snapshot.queryParamMap.get('pricing_id')) {
      this.builderService.selected_pricing_id = this.route.snapshot.queryParamMap.get('pricing_id')
      this.builderService.cookieSetter('pricing_id', this.builderService.selected_pricing_id, 3)
    }

    if (this.loginService.isLoggedIn()) {
      if (this.paymentIncluded) {
        // window.location.href = '/payment';
        this.router.navigate(['/billing'])
      } else {
        this.router.navigate(['/'])
      }
    }

    this.route.queryParams.subscribe((params) => {
      if (params.type == 'ltd') {
        this.type = 'ltd'
      } else if (params.type == 'self_ltd') {
        this.type = 'self_ltd'
        this.builderService.user_type = this.type
      }

      if (params.returnUrl == 'payment') {
        this.builderService.redirect_to = 'payment'
      }

      if (params.coupon_id) {
        this.builderService.selected_coupon_id = params.coupon_id
      }
    })

    this.signupForm = this.formBuilder.group({
      firstname: ['', Validators.compose([Validators.required])],
      lastname: ['', Validators.compose([Validators.required])],
      email: ['', Validators.compose([Validators.required])],
      country_code: [''],
      phone_number: [''],
      password: [
        '',
        Validators.compose([
          Validators.required,
          Validators.minLength(8)
          // PasswordValidator.isValid
        ])
      ]
    })

    this.authService.authState.subscribe((user) => {
      // Logout from google authenticate after successful login to prevent authentication loop
      // this.authService.signOut();
      // if( user == null ) {
      //   return false;
      // }
      this.signupProgress = true
      this.signupService.signupSocial(user.email, user.firstName, user.lastName, this.type).subscribe(
        (signup) => {
          if (!signup.success) {
            this.signupProgress = false
            this.signupError = true
            this.signupErrorMsg = signup.message + ', If you want to login go to the login page'
          } else {
            this.signupProgress = false
            this.signupError = false
            let returnUrl = this.route.snapshot.queryParamMap.get('returnUrl')
            /**
             * Force Login
             */
            this.loginService.loginSocial(user.email).subscribe((login) => {
              this.forceLogin(user.email, login, '')
            })
          }
        },
        (err) => {
          this.signupProgress = false
          this.signupError = true
          this.signupErrorMsg = err.error.message
        }
      )
      return true
    })
    // gapi.accounts.id.renderButton(document.getElementById("signinDiv"), {
    //   theme: 'outline',
    //   size: 'large',
    //   click_listener: function () {
    //     console.log("Sign in with Google button clicked...")
    //   }
    // });
  }

  // signInWithGoogle(): void {
  //   this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((user) => {
  //     console.log(user);
  //   });
  // }

  /**
   * signup the user, sets the token and navigate to the correct url
   */
  onSubmit(): void {
    let signupFormVal: any = this.signupForm.value
    if (signupFormVal.email2) {
      return
    }
    if (this.signupProgress == true) {
      return
    }
    if (this.signupForm.status == 'INVALID') {
      return
    }
    this.signupProgress = true
    let phoneNumber = signupFormVal.country_code + signupFormVal.phone_number
    this.signupService.signup(signupFormVal.email, signupFormVal.password, signupFormVal.firstname, signupFormVal.lastname, phoneNumber, this.type).subscribe(
      (signup) => {
        this.signupProgress = false
        this.signupError = false
        if (!signup.success) {
          alert(signup.message)
        } else {
          /**
           * Force Login
           */
          this.loginService.login(signupFormVal.email, signupFormVal.password).subscribe((login) => {
            this.forceLogin(signupFormVal.email, login, phoneNumber)
          })
        }
      },
      (err) => {
        this.signupProgress = false
        this.signupError = true
        this.signupErrorMsg = err.error.message
      }
    )
  }

  forceLogin = (email: string, login: any, phoneNumber: string) => {
    localStorage.setItem('token', login.token)
    this.blockDataService.sendTokenData(login.token)
    if (this.type == 'ltd') {
      this.router.navigate(['ltd'])
    } else {
      this.builderService.setTour(true)
      /**
       * Create Project with self_ltd as type = ltd, access = free
       */
      if (this.type == 'self_ltd') {
        this.builderService.createFreeProject(email, '', true).subscribe((response: any) => {
          this.builderService.selectedProject = response.project_id
          localStorage.setItem('selectedProject', response.project_id)
          this.appSettings.addProjectDefaultSettings(response.project_id).subscribe(() => {
            this.mixPanel.init(response.project_id)
            this.mixPanel.track('Signed Up', {
              projectid: response.project_id,
              phoneNumber: phoneNumber,
              email: email
            })
            if (environment.production) {
              this.gaService.gtag('event', 'conversion', { send_to: environment.gtm })
              this.gaService.gtag('event', 'Signed Up', { send_to: environment.gtm })
              // this.gaService.gtag('event', 'conversion', {'send_to': 'AW-972505779/l9hZCMzVvocYELOF3c8D'});
              // this.gaService.gtag('event', 'Signed Up', {'send_to': 'AW-972505779/l9hZCMzVvocYELOF3c8D'});
              this.gaService.gtag('event', 'Signed Up', { send_to: environment.ga })
              this.pixel.trackCustom('Signed Up')
              this.pixel.track('StartTrial')
            }
            if (this.builderService.redirect_to == 'payment') {
              this.router.navigate(['/billing'])
            } else {
              //location.reload();
              window.location.href = 'site-type'
            }

            // this.router.navigate(['/site-type']);
          })
        })
      } else {
        /**
         * Create Project
         */
        this.builderService.createFreeProject(email).subscribe((response: any) => {
          this.builderService.selectedProject = response.project_id
          localStorage.setItem('selectedProject', response.project_id)
          this.appSettings.addProjectDefaultSettings(response.project_id).subscribe(() => {
            this.mixPanel.init(response.project_id)
            this.mixPanel.track('Signed Up', {
              projectid: response.project_id,
              phoneNumber: phoneNumber,
              email: email
            })
            if (environment.production) {
              this.gaService.gtag('event', 'conversion', { send_to: environment.gtm })
              this.gaService.gtag('event', 'Signed Up', { send_to: environment.gtm })
              // this.gaService.gtag('event', 'conversion', {'send_to': 'AW-972505779/l9hZCMzVvocYELOF3c8D'});
              // this.gaService.gtag('event', 'Signed Up', {'send_to': 'AW-972505779/l9hZCMzVvocYELOF3c8D'});
              this.gaService.gtag('event', 'Signed Up', { send_to: environment.ga })
              this.pixel.trackCustom('Signed Up')
              this.pixel.track('StartTrial')
            }

            if (this.builderService.redirect_to == 'payment') {
              // window.location.href = '/payment';
              this.router.navigate(['/billing'])
            } else {
              // location.reload();
              window.location.href = 'site-type'
            }
            // this.router.navigate(['/site-type']);
          })
        })
      }
    }
  }

  changeLang(language: string) {
    localStorage.setItem('siteLang', language)
    this.languageService.setLanguage(language)
    this.languageService.loadTranslations(language).then(() => {
      this.translate.use(language)
    })
  }

  search(event: KeyboardEvent) {
    const inputElement = event.target as HTMLInputElement
    const value = inputElement.value

    console.log('Search value:', value)

    for (let i of this.countries.countryDialCodes) {
      console.log('HI')

      if (i.code === value.toLocaleUpperCase() || i.dial_code === value) {
        this.searchValues = [i]
        return
      }
    }
    this.searchValues = this.countries.countryDialCodes
  }
}
