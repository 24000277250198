import { CdkDragDrop, copyArrayItem, moveItemInArray } from '@angular/cdk/drag-drop'
import { Component, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { environment } from 'src/environments/environment'
import { BlockDataService } from '../block-services/block-data.service'
import { BlockFieldsService } from '../block-services/block-fields.service'
import { BlockImageComponent } from '../blocks-wrapper/block-image/block-image.component'
import { BlockSectionComponent } from '../blocks-wrapper/block-section/block-section.component'
import { BlockTextComponent } from '../blocks-wrapper/block-text/block-text.component'
import { BuilderService } from '../builder-services/builder.service'
import { LoginService } from '../login/login.service'
import { WorksDialogComponent } from '../pages/works-dialog/works-dialog.component'
import { AppSettingsService } from '../settings/services/app-settings.service'
import { DatabaseService } from '../builder-services/database.service'

@Component({
  selector: 'app-view-builder',
  templateUrl: './view-builder.component.html',
  styleUrls: ['./view-builder.component.scss']
})
export class ViewBuilderComponent implements OnInit {
  debuggableJSON = environment.debuggableJSON
  blockData: any
  mobile = false
  openSettings = false
  constructor(
    public blockDataService: BlockDataService,
    private blockFieldService: BlockFieldsService,
    public loginService: LoginService,
    public builderService: BuilderService,
    private appSettingsService: AppSettingsService,
    public dialog: MatDialog,
    private db: DatabaseService
  ) {
    this.blockDataService.getCurrentBlock().subscribe((currentBlockData) => {
      this.openSettings = true
    })
  }

  ngOnInit() {
    if (window.screen.width <= 768) {
      // 768px portrait
      this.mobile = true
    }
    this.builderService.onboardingHomeStarted = false
    this.builderService.onboardingBlockAdded = false
    this.getGlobalStyles()

    this.applyColors()
  }

  startDesigningTour = () => {
    // let element = document.querySelector(".blocksHomeMobile");
    // element?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
    this.builderService.onboardingHomeStarted = true
    // window.scrollTo({left:0, top:document.body.scrollHeight, behavior: 'smooth'});
    this.builderService.setTour(false).subscribe((response) => {
      if (this.builderService.user_type == 'self_ltd') {
        const dialogRef = this.dialog.open(WorksDialogComponent, {
          width: '700px',
          maxWidth: '80%',
          data: { name: 'onboardingOffer' }
        })

        dialogRef.afterClosed().subscribe((result) => {
          console.log('The dialog was closed')
        })
      }
    })
  }

  /**
   * Close the Settings popup that opens in the mobile view
   */
  closeSettings() {
    this.openSettings = false
  }

  getElementIndex = (id: string) => {
    let modifiedId = 'blockid' + id
    var child = document.getElementById(modifiedId)?.parentNode?.parentNode
    if (!child) {
      child = document.querySelector('[blockid="' + id + '"]')?.parentNode?.parentNode?.parentNode?.parentNode
    }
    // console.log(child);
    var parent = child?.parentNode
    // console.log(parent);
    // console.log(Array.prototype.indexOf.call(parent?.children, child));
    // The equivalent of parent.children.indexOf(child)
    return Array.prototype.indexOf.call(parent?.children, child)
  }
  /**
   * Dropping from the Sidebar to the Mobile
   * @param event
   */
  drop(event: CdkDragDrop<string[]>) {
    // const nodeToMove = event.item.element.nativeElement;
    const { previousContainer, container, previousIndex, currentIndex } = event
    let realPreviousIndex = this.getElementIndex(event.item.data)
    let differenceIndex = realPreviousIndex - event.previousIndex

    let blockPreviousIndex = event.previousIndex + differenceIndex
    let blockCurrentIndex = event.currentIndex + differenceIndex
    // console.log(realPreviousIndex,differenceIndex);
    // console.log(blockPreviousIndex,blockCurrentIndex);
    // if going to header or something before header then add it as the first item
    let arrayCurrentIndex = blockCurrentIndex
    if (blockCurrentIndex < 2) {
      blockCurrentIndex = 2
      arrayCurrentIndex = 1
    }

    // event.item.data contains the item ID

    if (event.previousContainer === event.container) {
      // console.log("dropping inside the mobile");
      // console.log(event.previousContainer);
      // console.log(event.container);
      /**
       * Adjust the block data JSON Object
       */
      moveItemInArray(this.blockDataService.getAllBlocksData(), blockPreviousIndex - 1, blockCurrentIndex - 1)
      // event.previousIndex ,
      // event.currentIndex );
      /**
       * Move from Container to another Container for nested columns
       */
      /**
       * Adjust the preview inside the Mobile Preview
       */
      // console.log(blockPreviousIndex, blockCurrentIndex);
      moveWithinContainer(
        container.element.nativeElement,
        blockPreviousIndex,
        blockCurrentIndex
        // event.previousIndex - this.blockDataService.builderBlocksIndex,
        // event.currentIndex - this.blockDataService.builderBlocksIndex,
      )
    } else {
      //  console.log("from outside to inside")
      /**
       * Convert Block to Array
       */
      let droppedBlock = event.item.element.nativeElement.tagName
      let droppedBlockData
      this.blockData = {
        index: event.currentIndex
      }
      if (droppedBlock == 'BLOCK-TEXT') {
        droppedBlockData = this.blockFieldService.getTextBlockFields()
        this.blockData.component = BlockTextComponent
      } else if (droppedBlock == 'BLOCK-IMAGE') {
        droppedBlockData = this.blockFieldService.getImageBlockFields()
        this.blockData.component = BlockImageComponent
      } else if (droppedBlock == 'BLOCK-SECTION') {
        droppedBlockData = this.blockFieldService.getSectionBlockFields()
        this.blockData.component = BlockSectionComponent
      } else {
        droppedBlockData = this.blockFieldService.getTextBlockFields()
        this.blockData.component = BlockTextComponent
      }
      droppedBlockData = { ...droppedBlockData }
      // Increment the block id to be the latest one
      droppedBlockData.id = droppedBlockData.blockId = this.blockDataService.blockId = this.blockDataService.blocksData.length

      droppedBlockData = [droppedBlockData]
      /**
       * Copy the dropped block array to the allblocksdata
       */
      copyArrayItem(droppedBlockData, this.blockDataService.getAllBlocksData(), 0, event.currentIndex)

      /**
       * Send the Block to the Mobile Preview along with its place
       */
      this.blockDataService.sendBlockData(this.blockData)
      // transferNodeToContainer(
      //   event.item.element.nativeElement,
      //   container.element.nativeElement,
      //   currentIndex);
      /**
       * Reinitialize the whole preview
       */
      //  this.blockDataService.sendRenderAllBlocks();
    }
  }

  blocksHome = () => {
    this.builderService.blocksCollapsed = !this.builderService.blocksCollapsed
  }

  getColumnsIds = () => {
    return this.blockDataService.getColumnIds()
  }

  getGlobalStyles = () => {
    let project_id = this.builderService.getSelectedProject()
    this.appSettingsService.getGlobalSettings(project_id).subscribe(
      (data: any) => {
        if (!data) {
          this.builderService.globalSettings = {
            'font-family': 'Poppins',
            'font-size': '14px',
            file: ''
          }
        } else {
          this.builderService.globalSettings = data
        }

        this.applyGlobalFonts()
      },
      () => {
        console.log('error')
      }
    )
  }

  applyGlobalFonts = () => {
    const style = document.createElement('style')
    style.className = 'googleFontsStyle'
    let customStyles = this.builderService.globalSettings
    if (customStyles) {
      style.innerHTML = `.mat-typography #mainMobileAppPreview {
            font-family: "${customStyles['font-family']}", "Helvetica Neue", sans-serif;
            font-size: ${customStyles['font-size']};

        }`

      document.body.appendChild(style)
      let link = document.createElement('link')
      link.rel = 'stylesheet'
      link.href = customStyles['file']
      document.getElementsByTagName('head')[0].appendChild(link)
    }
  }

  /**
   * Apply the appsettings colors to the mobile preview
   */
  applyColors = () => {
    this.db.getDatabase('projects/' + this.builderService.selectedProject + '/settings/application_settings/').subscribe((application_settings: any) => {
      console.log(application_settings)
      let mobile_text_color = application_settings.mobile_text_color
      let mobile_primary_color = application_settings.mobile_primary_color
      const style = document.createElement('style')
      style.innerHTML = `
          :root {
            --ion-color-primary: ${mobile_primary_color} !important;
            --ion-color-primary-opacity: rgba(${parseInt(mobile_primary_color.slice(1, 3), 16)}, ${parseInt(mobile_primary_color.slice(3, 5), 16)}, ${parseInt(mobile_primary_color.slice(5, 7), 16)}, 15%) !important;
            --ion-color-text: ${mobile_text_color} !important;
          }
          `

      document.body.appendChild(style)
    })
  }

  howItWorksDialog = () => {
    if (localStorage.getItem('globals') !== 'closed') {
      const dialogRef = this.dialog.open(WorksDialogComponent, {
        width: '700px',
        maxWidth: '80%',
        data: { name: 'globals' }
      })

      dialogRef.afterClosed().subscribe((result) => {
        // console.log('The dialog was closed');
        localStorage.setItem('globals', 'closed')
      })
    }
  }
}
function moveWithinContainer(container: any, fromIndex: any, toIndex: any) {
  if (fromIndex === toIndex) {
    return
  }
  // if( toIndex == 0 ) {
  //   toIndex++;
  // }
  // fromIndex++;
  // toIndex++;
  // console.log(fromIndex, toIndex);
  // console.log(container.children);

  const nodeToMove = container.children[fromIndex]
  const targetNode = container.children[toIndex]
  // console.log(container.children, nodeToMove, targetNode, fromIndex, toIndex);
  if (fromIndex < toIndex) {
    targetNode.parentNode.insertBefore(nodeToMove, targetNode.nextSibling)
  } else {
    targetNode.parentNode.insertBefore(nodeToMove, targetNode)
  }
}

function transferNodeToContainer(node: any, container: any, toIndex: any) {
  toIndex++
  if (toIndex === container.children.length) {
    container.appendChild(node)
  } else {
    const targetItem = container.children[toIndex]
    targetItem.parentNode.insertBefore(node, targetItem)
  }
}
