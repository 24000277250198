<div class="containerWrapper">
  <div class="tableWrapper" *ngIf="orders !== 0">
    <div class="tableHeader">
      <h1>{{ 'Orders' | translate }}</h1>
      <div class="headerBtns">
        <form class="searchBar">
          <input type="text" matInput placeholder="{{ 'Search' | translate }}" />
        </form>

        <button mat-raised-button color="primary" (click)="exportToExcel()">
          <mat-icon>download</mat-icon>
          {{ 'Export to Excel' | translate }}
        </button>
      </div>
    </div>
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
      <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef class="smallHeader firstTitle">{{ 'id' | translate }}</th>
        <td mat-cell *matCellDef="let elements">{{ elements.humId }}</td>
      </ng-container>
      <ng-container matColumnDef="date">
        <th mat-header-cell *matHeaderCellDef class="smallHeader firstTitle">{{ 'Date' | translate }}</th>
        <td mat-cell *matCellDef="let elements">{{ elements.date }}</td>
      </ng-container>
      <ng-container matColumnDef="user_data">
        <th mat-header-cell *matHeaderCellDef class="firstTitle">{{ 'user' | translate }}</th>
        <td mat-cell *matCellDef="let elements">{{ elements.user_data ? elements.user_data.displayName : elements.user_email }}</td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef class="firstTitle">{{ 'Status' | translate }}</th>
        <td mat-cell *matCellDef="let elements">{{ elements.status }}</td>
      </ng-container>
      <ng-container matColumnDef="total">
        <th mat-header-cell *matHeaderCellDef class="smallHeader firstTitle">{{ 'Total' | translate }}</th>
        <td mat-cell *matCellDef="let elements">{{ elements.total }}</td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="smallHeader firstTitle lastTitle">{{ 'action' | translate }}</th>
        <td mat-cell *matCellDef="let elements">
          <button mat-icon-button (click)="editOrder(elements.order_id)" class="viewOrder">
            <span class="mat-button-wrapper">
              <span class="material-icons-outlined"> visibility </span>
            </span>
          </button>
          <button mat-icon-button (click)="deleteOrder(elements.order_id, elements.user)">
            <span class="mat-button-wrapper">
              <span class="material-icons material-icons-outlined deleteIcon"> delete </span>
            </span>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="ordersColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: ordersColumns"></tr>
    </table>

    <mat-paginator class="tablePaginator" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons aria-label="Select page of periodic dataSource"> </mat-paginator>
    <div *ngIf="orders.length === 0">
      <p class="noOrders">{{ 'no Orders yet' | translate }}</p>
    </div>
  </div>
</div>
