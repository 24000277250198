import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { SettingsService } from './settings.service'
import { CountriesService } from 'src/app/builder-services/countries/countries.service'

// Interfaces
interface DropDownList {
  code: string
  symbol: string
  name: string
}

interface Settings {
  currency?: string
  countryOptions?: string
  estimatedDeliveryTime?: string
  estimatedDeliveryUnit?: string
  selectedCountries?: string[]
  countryNumber?: string
}

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  // State
  settings: Settings = {}
  settingsProgress = false
  saveState = false
  countries: any[] = []
  currency: string = ''
  countryOptions: string = 'all'
  estimatedDeliveryTime: string = '20'
  estimatedDeliveryUnit: string = 'minutes'
  selectedCountries: string[] = []
  countryNumber: string = ''
  settingsForm: FormGroup

  CurrencyList: DropDownList[] = [
    {
      symbol: 'د.إ',
      code: 'AED',
      name: 'United Arab Emirates Dirham'
    },

    {
      symbol: '؋',
      code: 'AFN',
      name: 'Afghan Afghani'
    },
    {
      symbol: '₼',
      code: 'AZN',
      name: 'Azerbaijani Manat'
    },
    {
      symbol: 'лв',
      code: 'BGN',
      name: 'Bulgarian Lev'
    },
    {
      symbol: '৳',
      code: 'BDT',
      name: 'Bangladeshi Taka'
    },
    {
      symbol: 'Br',
      code: 'BYN',
      name: 'Belarusian Ruble'
    },
    {
      symbol: '៛',
      code: 'KHR',
      name: 'Cambodian Riel'
    },
    {
      symbol: '$',
      code: 'CAD',
      name: 'Canadian Dollar'
    },
    {
      symbol: '₡',
      code: 'CRC',
      name: 'Costa Rican Colón'
    },
    {
      symbol: 'kn',
      code: 'HRK',
      name: 'Croatian Kuna'
    },
    {
      symbol: 'Kč',
      code: 'CZK',
      name: 'Czech Koruna'
    },
    {
      symbol: 'kr',
      code: 'DKK',
      name: 'Danish Krone'
    },
    {
      symbol: 'RD$',
      code: 'DOP',
      name: 'Dominican Peso'
    },
    {
      symbol: 'EGP',
      code: 'EGP',
      name: 'Egyptian Pound'
    },
    {
      symbol: '¢',
      code: 'GHS',
      name: 'Ghanaian Cedi'
    },
    {
      symbol: 'Q',
      code: 'GTQ',
      name: 'Guatemalan Quetzal'
    },
    {
      symbol: 'L',
      code: 'HNL',
      name: 'Honduran Lempira'
    },
    {
      symbol: 'Ft',
      code: 'HUF',
      name: 'Hungarian Forint'
    },
    {
      symbol: 'Rp',
      code: 'IDR',
      name: 'Indonesian Rupiah'
    },
    {
      symbol: '₪',
      code: 'ILS',
      name: 'Israeli New Shekel'
    },
    {
      symbol: '₹',
      code: 'INR',
      name: 'Indian Rupee'
    },
    {
      symbol: '¥',
      code: 'JPY',
      name: 'Japanese Yen'
    },
    {
      symbol: '₩',
      code: 'KRW',
      name: 'South Korean Won'
    },
    {
      symbol: '₭',
      code: 'LAK',
      name: 'Lao Kip'
    },
    {
      symbol: '₨',
      code: 'LKR',
      name: 'Sri Lankan Rupee'
    },
    {
      symbol: 'ден',
      code: 'MKD',
      name: 'Macedonian Denar'
    },
    {
      symbol: 'RM',
      code: 'MYR',
      name: 'Malaysian Ringgit'
    },
    {
      symbol: '₮',
      code: 'MNT',
      name: 'Mongolian Tugrik'
    },
    {
      symbol: '₨',
      code: 'NPR',
      name: 'Nepalese Rupee'
    },
    {
      symbol: '₦',
      code: 'NGN',
      name: 'Nigerian Naira'
    },
    {
      symbol: 'kr',
      code: 'NOK',
      name: 'Norwegian Krone'
    },
    {
      symbol: '﷼',
      code: 'OMR',
      name: 'Omani Rial'
    },
    {
      symbol: 'S/',
      code: 'PEN',
      name: 'Peruvian Sol'
    },
    {
      symbol: '₱',
      code: 'PHP',
      name: 'Philippine Peso'
    },
    {
      symbol: 'zł',
      code: 'PLN',
      name: 'Polish Złoty'
    },
    {
      symbol: 'lei',
      code: 'RON',
      name: 'Romanian Leu'
    },
    {
      symbol: '₽',
      code: 'RUB',
      name: 'Russian Ruble'
    },
    {
      symbol: '﷼',
      code: 'SAR',
      name: 'Saudi Riyal'
    },
    {
      symbol: 'Дин.',
      code: 'RSD',
      name: 'Serbian Dinar'
    },
    {
      symbol: '₨',
      code: 'PKR',
      name: 'Pakistani Rupee'
    },
    {
      symbol: '₡',
      code: 'SCR',
      name: 'Seychellois Rupee'
    },
    {
      symbol: 'kr',
      code: 'SEK',
      name: 'Swedish Krona'
    },
    {
      symbol: '₺',
      code: 'TRY',
      name: 'Turkish Lira'
    },
    {
      symbol: '$',
      code: 'USD',
      name: 'United States Dollar'
    },
    {
      symbol: '₴',
      code: 'UAH',
      name: 'Ukrainian Hryvnia'
    },
    {
      symbol: '₫',
      code: 'VND',
      name: 'Vietnamese Dong'
    },
    {
      symbol: 'R',
      code: 'ZAR',
      name: 'South African Rand'
    },
    {
      symbol: 'Z$',
      code: 'ZWL',
      name: 'Zimbabwean Dollar'
    }
  ]

  constructor(private settingService: SettingsService, private fb: FormBuilder, private countriesService: CountriesService) {
    this.settingsForm = this.fb.group({
      currency: [''],
      countryOptions: [''],
      selectedCountries: [[]],
      countryNumber: [''],
      estimatedDeliveryUnit: [''],
      estimatedDeliveryTime: ['']
    })
  }

  ngOnInit(): void {
    this.countries = this.countriesService.getCountries()
    this.loadSettings()
  }

  // Load settings from service
  private loadSettings(): void {
    this.settingService.getSettings().subscribe((settings: Settings) => {
      const effectiveSettings = settings || {}

      // Define properties with defaults or custom getters
      const properties = [
        { key: 'currency', default: '' },
        { key: 'countryOptions', default: 'all' },
        { key: 'estimatedDeliveryTime', default: '20' },
        { key: 'estimatedDeliveryUnit', default: 'minutes' },
        { key: 'selectedCountries', default: [] },
        { key: 'countryNumber', default: '' }
      ]

      // Update form
      this.settingsForm.patchValue({
        currency: this.currency,
        countryOptions: this.countryOptions,
        estimatedDeliveryTime: this.estimatedDeliveryTime,
        estimatedDeliveryUnit: this.estimatedDeliveryUnit,
        selectedCountries: this.selectedCountries,
        countryNumber: this.countryNumber
      })

      this.settings = effectiveSettings
    })
  }

  saveSettings(): void {
    this.settingsProgress = true
    const formValue = this.settingsForm.value

    this.settings = {
      ...this.settings,
      currency: formValue.currency,
      countryOptions: formValue.countryOptions,
      estimatedDeliveryTime: formValue.estimatedDeliveryTime,
      estimatedDeliveryUnit: formValue.estimatedDeliveryUnit,
      selectedCountries: formValue.selectedCountries,
      countryNumber: formValue.countryNumber
    }

    this.settingService.submitSettings(this.settings).subscribe((result) => {
      this.settingsProgress = false
      this.saveState = false
    })
  }

  // Format country number
  startWithPlus(event: Event): void {
    const input = (event.target as HTMLInputElement).value
    const numericInput = input.replace(/\D/g, '')
    this.settingsForm.get('countryNumber')?.setValue(input.startsWith('+') ? `+${numericInput}` : `+${numericInput}`)
  }

  // Activate save state
  activateSave(): void {
    this.saveState = true
  }
}
