<h1 class="discount-header">Flash Sale Time</h1>
<div class="containerWrapper">
  <div class="discount-wrapper">
    <form (ngSubmit)="onSubmit(f.value)" #f="ngForm">
      <section class="defaultCoupon">
        <h2 class="section-title">Default Discount Timer</h2>
        <div class="input-row">
          <div>
            <mat-form-field appearance="outline" class="discountName">
              <mat-label>Sale Name</mat-label>
              <input required matInput name="discountName" [(ngModel)]="discountName" #discountInput="ngModel" />
              <mat-error *ngIf="discountInput.errors?.required">Sale name is required.</mat-error>
            </mat-form-field>
            <div *ngIf="invalidationMessage">
              <mat-error class="invalidationError">{{ invalidationMessage }}</mat-error>
            </div>
          </div>
          <div>
            <mat-form-field appearance="outline" class="discountCriteria">
              <mat-label>Discount Criteria</mat-label>
              <mat-select required id="selectedCriteria" [(ngModel)]="selectedCriteria" name="selectedCriteria" #criteriaSelectInput="ngModel">
                <mat-option *ngFor="let criteria of discountCriteriaMap | keyvalue" [value]="criteria.key">
                  {{ criteria.value }}
                </mat-option>
              </mat-select>
              <mat-error *ngIf="criteriaSelectInput.errors?.required">Please select a discount criteria.</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div [class.hidden]="!showProducts()">
          <div class="tableWrapper">
            <div class="tableHeader">
              <h1>Select Specific Product(s)</h1>
              <input #query (keyup)="filterProducts(query.value)" type="text" placeholder="Search" id="searchInput" class="search-bar-products" />
            </div>
            <table mat-table mat-sort [dataSource]="productsDataSource" class="mat-elevation-z8">
              <ng-container matColumnDef="image">
                <th mat-header-cell *matHeaderCellDef class="firstTitle">{{ 'Image' | translate }}</th>
                <td mat-cell class="tdI" *matCellDef="let product">
                  <mat-checkbox [checked]="isChecked(product, 'product')" class="products-checkbox" (change)="onProductSelect(product, $event)"></mat-checkbox>
                  <div class="productImgContainer">
                    <img *ngIf="product.image" [src]="product.image" class="productImg" alt="Product image" />
                    <img *ngIf="!product.image" src="assets/images/empty-image.png" class="productImg" alt="No image" />
                  </div>
                </td>
              </ng-container>
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef>Name</th>
                <td mat-cell *matCellDef="let product">{{ product.name }}</td>
              </ng-container>
              <ng-container matColumnDef="stock">
                <th mat-header-cell *matHeaderCellDef>Stock</th>
                <td mat-cell *matCellDef="let product">{{ getStock(product) > 0 ? product.stock : 'Out of Stock' }}</td>
              </ng-container>
              <ng-container matColumnDef="price">
                <th mat-header-cell *matHeaderCellDef>Price</th>
                <td mat-cell *matCellDef="let product">{{ product.price }}</td>
              </ng-container>
              <ng-container matColumnDef="date">
                <th mat-header-cell *matHeaderCellDef>Date</th>
                <td mat-cell *matCellDef="let product">{{ product.date }}</td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="productsColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: productsColumns"></tr>
            </table>
            <mat-paginator class="tablePaginator" [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons aria-label="Select page of products"></mat-paginator>
          </div>
          <div *ngIf="selectedProducts.length > 0">
            <div class="tableWrapper selectedProductsTable">
              <div class="tableHeader">
                <h1>Selected Products</h1>
              </div>
              <table mat-table [dataSource]="selectedProductsDataSource" class="mat-elevation-z8">
                <ng-container matColumnDef="select">
                  <th mat-header-cell *matHeaderCellDef>Select</th>
                  <td mat-cell *matCellDef="let product">
                    <mat-checkbox [checked]="isChecked(product, 'product')" (change)="onProductSelect(product, $event)"></mat-checkbox>
                  </td>
                </ng-container>
                <ng-container matColumnDef="image">
                  <th mat-header-cell *matHeaderCellDef>Image</th>
                  <td mat-cell *matCellDef="let product">
                    <img *ngIf="product.image" [src]="product.image" alt="Product Image" class="productImg" />
                    <img *ngIf="!product.image" src="assets/images/empty-image.png" alt="No image available" class="productImg" />
                  </td>
                </ng-container>
                <ng-container matColumnDef="name">
                  <th mat-header-cell *matHeaderCellDef>Name</th>
                  <td mat-cell *matCellDef="let product">{{ product.name }}</td>
                </ng-container>
                <ng-container matColumnDef="stock">
                  <th mat-header-cell *matHeaderCellDef>Stock</th>
                  <td mat-cell *matCellDef="let product">{{ product.stock > 0 ? product.stock : 'Out of Stock' }}</td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="selectedProductsColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: selectedProductsColumns"></tr>
              </table>
            </div>
          </div>
        </div>
        <div *ngIf="showCategories()">
          <div class="tableWrapper categoriesTable">
            <div class="tableHeader">
              <h1>Select Specific Categories</h1>
              <input #queryC (keyup)="filterCategories(queryC.value)" type="text" placeholder="Search" id="searchInputC" class="search-bar-products" />
            </div>
            <table mat-table [dataSource]="categoriesDataSource" class="mat-elevation-z8">
              <ng-container matColumnDef="selectImageName">
                <th mat-header-cell *matHeaderCellDef>Select | Image | Name</th>
                <td mat-cell *matCellDef="let category" class="categoryTd">
                  <div class="categoryInfoWrapper">
                    <mat-checkbox [checked]="isChecked(category, 'category')" (change)="onCategorySelect(category, $event)"></mat-checkbox>
                    <img [src]="category.image" alt="Category Image" class="productImg" />
                    <span class="categoryName">{{ category.name }}</span>
                  </div>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
            </table>
          </div>
          <div *ngIf="selectedCategories.length > 0">
            <div class="tableWrapper selectedCategoriesTable">
              <div class="tableHeader">
                <h1>Selected Categories</h1>
              </div>
              <table mat-table [dataSource]="selectedCategoriesDataSource" class="mat-elevation-z8">
                <ng-container matColumnDef="selectImageName">
                  <th mat-header-cell *matHeaderCellDef class="tableHeaderCat">Select | Image | Name</th>
                  <td mat-cell *matCellDef="let category" class="categoryTd">
                    <div class="categoryInfoWrapper">
                      <mat-checkbox [checked]="isChecked(category, 'category')" (change)="onCategorySelect(category, $event)"></mat-checkbox>
                      <img [src]="category.image" alt="Category Image" class="productImg" />
                      <span class="categoryName">{{ category.name }}</span>
                    </div>
                  </td>
                </ng-container>
                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              </table>
            </div>
          </div>
        </div>
      </section>
      <section [ngClass]="{ couponAmount: showProducts() || showCategories() }">
        <h2 class="section-title">Sale Amount</h2>
        <div>
          <label class="input-label">Discount Amount</label>
          <mat-form-field appearance="outline" class="discountField">
            <input required placeholder="10%" type="number" min="1" matInput name="discountPercentage" [(ngModel)]="discountPercentage" #discountInput="ngModel" class="discountAmountInput" />
            <mat-error *ngIf="discountInput.errors?.required">Discount Percentage is required.</mat-error>
          </mat-form-field>
          <mat-icon matSuffix>%</mat-icon>
          <span class="percentValue">*Enter percentage as a number</span>
        </div>
      </section>
      <section class="discountExpirationDate">
        <div>
          <h2 class="section-title">Sale Expiration</h2>
        </div>
        <div class="expire-row">
          <p class="sale-head">Sale Date</p>
          <div>
            <div class="date-picker-wrapper">
              <label class="date-label">{{ 'From' | translate }}</label>
              <mat-form-field appearance="outline" class="datePickerInput">
                <input matInput [matDatepicker]="picker" [(ngModel)]="dateFrom" required #dateInputNg="ngModel" name="dateFrom" class="dateInput" />
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
                <mat-error *ngIf="dateInputNg.invalid && (dateInputNg.dirty || dateInputNg.touched)">Sale Expiration From date is required.</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div>
            <div class="date-picker-wrapper">
              <label class="date-label">{{ 'To' | translate }}</label>
              <mat-form-field appearance="outline" class="datePickerInput">
                <input matInput [matDatepicker]="pickerTo" [(ngModel)]="dateTo" required #dateInputNg="ngModel" name="dateTo" class="dateInput" />
                <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
                <mat-datepicker #pickerTo></mat-datepicker>
                <mat-error *ngIf="dateInputNg.invalid && (dateInputNg.dirty || dateInputNg.touched)">Sale Expiration To date is required.</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="expire-row">
          <p class="sale-head">Sale Time</p>
          <div>
            <div class="date-picker-wrapper">
              <label class="date-label">{{ 'From' | translate }}</label>
              <mat-form-field appearance="outline" class="datePickerInput">
                <input matInput [(ngModel)]="selectedTime" placeholder="Select Time" readonly #selectedTimeNg="ngModel" name="timeFrom" class="dateInput" />
                <button type="button" matSuffix mat-icon-button [matMenuTriggerFor]="timeMenu">
                  <mat-icon>schedule</mat-icon>
                </button>
              </mat-form-field>
              <mat-menu #timeMenu="matMenu" overlapTrigger="false">
                <div class="time-menu-container">
                  <button type="button" *ngFor="let hour of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]" mat-menu-item (click)="setTime(hour, 0, timeMenu)">{{ hour | number: '2.0' }}:00</button>
                  <div class="am-pm-toggle">
                    <button mat-button (click)="togglePeriod()" [class.active]="!isPM">AM</button>
                    <button mat-button (click)="togglePeriod()" [class.active]="isPM">PM</button>
                  </div>
                </div>
              </mat-menu>
            </div>
          </div>
          <div>
            <div class="date-picker-wrapper">
              <label class="date-label">{{ 'To' | translate }}</label>
              <mat-form-field appearance="outline" class="datePickerInput">
                <input matInput [(ngModel)]="selectedTimeTo" placeholder="Select Time" readonly #selectedTimeNg="ngModel" name="timeTo" class="dateInput" />
                <button type="button" matSuffix mat-icon-button [matMenuTriggerFor]="timeMenuTo">
                  <mat-icon>schedule</mat-icon>
                </button>
              </mat-form-field>
              <mat-menu #timeMenuTo="matMenu" overlapTrigger="false">
                <div class="time-menu-container">
                  <button type="button" *ngFor="let hour of [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]" mat-menu-item (click)="setTimeTo(hour, 0, timeMenuTo)">{{ hour | number: '2.0' }}:00</button>
                  <div class="am-pm-toggle">
                    <button mat-button (click)="togglePeriod()" [class.active]="!isPM">AM</button>
                    <button mat-button (click)="togglePeriod()" [class.active]="isPM">PM</button>
                  </div>
                </div>
              </mat-menu>
            </div>
          </div>
        </div>
      </section>
      <div class="actions">
        <button mat-icon-button class="stacksBtn blue glow" type="submit">Create</button>
      </div>
    </form>
  </div>
</div>
