import { LogsService } from './logs.service'
import { Component, OnInit, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatTable, MatTableDataSource } from '@angular/material/table'
import { BuilderService } from 'src/app/builder-services/builder.service'

export interface subscribersTableColumns {
  // id: string;
  project_id: string
  message: string
  user_id: string
  user_name: string
  created_at: string
  updated_at: string
  path: string
  // ids: string;
  event_name: string
  event_action: string
  cid: string
}

let ELEMENT_DATA: subscribersTableColumns[] = []

@Component({
  selector: 'logs',
  templateUrl: './logs.component.html',
  styleUrls: ['./logs.component.scss']
})
export class LogsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatTable)
  table!: MatTable<subscribersTableColumns>

  constructor(
    private logsService: LogsService,
    public builderService: BuilderService
  ) {}
  public subscribersColumns: string[] = []
  public dataSource: any = []

  ngOnInit() {
    this.subscribersColumns = ['project_id', 'message', 'user_id', 'user_name', 'created_at', 'updated_at', 'path', 'event_name', 'event_action', 'cid']

    this.logsService.getLogs().subscribe((response: any) => {
      this.builderService.showUpdateProgress = false
      for (const key in response) {
        if (Object.prototype.hasOwnProperty.call(response, key)) {
          const element = response[key]
          ELEMENT_DATA.push({
            // id: element.id,
            project_id: element.project_id,
            message: element.message,
            user_id: element.user_id,
            user_name: element.user_name,
            created_at: element.created_at,
            updated_at: element.updated_at,
            path: element.path,
            // ids: element.ids,
            event_name: element.event_name,
            event_action: element.event_action,
            cid: element.cid
          })
        }
      }
      this.dataSource = new MatTableDataSource<subscribersTableColumns>(ELEMENT_DATA)
      this.dataSource.paginator = this.paginator
      setTimeout(() => {
        this.table.renderRows()
      }, 1000)
    })
  }

  applySubFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }
}
