import { Pipe, PipeTransform } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'

@Pipe({
  name: 'multilingual'
})
export class MultilingualPipe implements PipeTransform {
  constructor(private http: HttpClient) {}

  private languageCodes: { [key: string]: string } = {
    'serbo-croatian': 'sh',
    armenian: 'hy',
    bengali: 'bn',
    bulgarian: 'bg',
    burmese: 'my',
    chichewa: 'ny',
    croatian: 'hr',
    czech: 'cs',
    danish: 'da',
    dutch: 'nl',
    en: 'en',
    estonian: 'et',
    fijian: 'fj',
    filipino: 'tl',
    finnish: 'fi',
    french: 'fr',
    frisian: 'fy',
    gaelic: 'gd',
    georgian: 'ka',
    german: 'de',
    hindi: 'hi',
    hungarian: 'hu',
    indonesian: 'id',
    inuktitut: 'iu',
    italian: 'it',
    japanese: 'ja',
    kannada: 'kn',
    khmer: 'km',
    korean: 'ko',
    kyrgyz: 'ky',
    lao: 'lo',
    latvian: 'lv',
    lithuanian: 'lt',
    malagasy: 'mg',
    malay: 'ms',
    malayalam: 'ml',
    maltese: 'mt',
    marathi: 'mr',
    nepali: 'ne',
    norwegian: 'no',
    portuguese: 'pt',
    punjabi: 'pa',
    quichua: 'qu',
    romanian: 'ro',
    russian: 'ru',
    samoan: 'sm',
    serbian: 'sr',
    sinhala: 'si',
    slovak: 'sk',
    slovenian: 'sl',
    somali: 'so',
    sotho: 'st',
    spanish: 'es',
    sudanese: 'su',
    swahili: 'sw',
    swedish: 'sv',
    tagalog: 'tl',
    tamil: 'ta',
    telugu: 'te',
    thai: 'th',
    tigrinya: 'ti',
    tongan: 'to',
    turkish: 'tr',
    ukrainian: 'uk',
    uzbek: 'uz',
    vietnamese: 'vi',
    welsh: 'cy',
    yiddish: 'yi',
    zulu: 'zu',
    // Additional RTL languages
    arabic: 'ar',
    hebrew: 'iw',
    pashto: 'ps',
    persian: 'fa',
    sindhi: 'sd',
    thaana: 'dv',
    urdu: 'ur',
    uyghur: 'ug'
  }

  /**
   * Translates an array of texts or a single text to the target language using the Google Translate API.
   * @param text A single string or array of strings to translate.
   * @param targetLanguage The target language for translation.
   * @returns An Observable of translated text(s).
   */
  transform(text: string | string[], targetLanguage: string): Observable<string | string[]> {
    const apiKey = 'AIzaSyDZBU79QDwE28lMF9htfBV3cff92KISeLk'
    targetLanguage = targetLanguage.trim().toLowerCase()
    const langCode = this.languageCodes[targetLanguage] || 'en'
    if (langCode === 'en') {
      return of(text)
    }
    const texts = Array.isArray(text) ? text : [text]
    const encodedTexts = texts.map((t) => encodeURIComponent(t)).join('&q=')

    const apiUrl = `https://translation.googleapis.com/language/translate/v2?target=${langCode}&key=${apiKey}&q=${encodedTexts}`
    return this.http.get(apiUrl).pipe(
      map((response: any) => {
        const translations = response.data.translations.map((t: any) => {
          let translatedText = t.translatedText || (Array.isArray(text) ? texts[0] : text)
          if (langCode === 'ar') {
            translatedText = this.convertToArabicDigits(translatedText)
          }
          return this.decodeHTML(translatedText)
        })
        // Decode HTML entities
        return Array.isArray(text) ? translations : translations[0]
      }),
      catchError((error) => {
        console.error('Translation API error:', error)
        return of(text)
      })
    )
  }

  private decodeHTML(html: string): string {
    const textArea = document.createElement('textarea')
    textArea.innerHTML = html
    return textArea.value
  }

  private convertToArabicDigits(input: string): string {
    const westernToEasternMap = ['٠', '١', '٢', '٣', '٤', '٥', '٦', '٧', '٨', '٩']
    return input.replace(/\d/g, (digit) => westernToEasternMap[parseInt(digit, 10)])
  }
}
