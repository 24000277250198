import { Component, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { DatabaseService } from 'src/app/builder-services/database.service';

@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  styleUrls: ['../points/points.component.scss'],
})
export class UserComponent {
  userDataSource = new MatTableDataSource<any>();
  pointsDataSource = new MatTableDataSource<any>();
  @ViewChild('paginator1') paginator1!: MatPaginator;
  @ViewChild('paginator2') paginator2!: MatPaginator;
  displayedColumns: string[] = [
    'userName',
    'email',
    'points',
    'redeem',
    'date',
    'transactionsHistory',
  ];
  selectedUser: any;
  searchText: string = '';
  toAddPoints: any;
  newPoints: any;

  constructor(
    private builderService: BuilderService,
    private db: DatabaseService
  ) {}

  ngOnInit(): void {
    this.getUsers();
    setTimeout(() => {
      this.userDataSource.paginator = this.paginator1;
    }, 1000);
  }

  /*
  getUsers
    1- Define the path to the users data in the database using the selected project ID.
    2- Retrieve user data from the database using the defined path.
    3- Log the fetched user data for debugging purposes.
    4- Transform the fetched data into an array of user objects:
      4.1- Iterate over each key in the retrieved data object.
      4.2- For each user:
        4.2.1- Ensure the `points` property is an object. If not, initialize it as an empty object.
        4.2.2- Convert the `points` object into an array by concatenating all the point arrays from the object values.
        4.2.3- Calculate the sum of negative points (redeemed points) using `filter` and `reduce`:
          - Filter points with values less than 0.
          - Sum these negative points.
        4.2.4- Extract the latest `initialDate` from the points array by sorting it in descending order based on `initialDate`.
          - If the points array is empty, set `lastInitialDate` to `null`.
        4.2.5- Create a new user object that includes:
          - All original user data.
          - The calculated sum of redeemed points (`redeem`).
          - The latest initial date (`lastInitialDate`).
        4.2.6- Calculate and set the total points using `calculateTotalPoints`.
    5- Log the transformed user data for debugging purposes.
    6- Assign the transformed data to the `userDataSource` for use in the table.
    7- Handle any errors that occur during data retrieval by logging an error message.
*/
  getUsers(): void {
    const usersDataPath = `/projects/${this.builderService.selectedProject}/users`;

    this.db.getDatabase(usersDataPath).subscribe(
      (data: any) => {
        console.log('Fetched user data:', data);

        if (typeof data !== 'object' || data === null) {
          console.error('Unexpected data format:', data);
          return;
        }

        const userArray = Object.keys(data).map((key) => {
          const user = data[key];
          const pointsObject = user.points || {};
          const points = Object.keys(pointsObject).reduce(
            (acc: any[], pointKey) => {
              return acc.concat(pointsObject[pointKey]);
            },
            []
          );

          const redeem = points
            .filter((point: any) => point.points < 0)
            .reduce((sum: number, point: any) => sum + point.points, 0);

          console.log('Total redeemed points for user:', redeem);
          const lastInitialDate =
            points.length > 0
              ? points.sort(
                  (a, b) =>
                    new Date(b.initialDate).getTime() -
                    new Date(a.initialDate).getTime()
                )[0].initialDate
              : null;
          this.calculateTotalPoints(user);
          return {
            ...user,
            redeem,
            lastInitialDate,
          };
        });

        console.log('Transformed user data:', userArray);

        // Update the data source
        this.userDataSource.data = userArray;
      },
      (error) => {
        console.error('Error loading user data:', error);
      }
    );
  }

  /*
    saveChanges
      1- Define the path to the user's points in the database using the selected project ID and user UID.
      2- Define the path to the validity date in the database.
      3- Fetch the validity date from the database.
      4- Check if `newTransaction` and its `initialDate` are present. If not, exit the function.
      5- Parse the `initialDate` from the `newTransaction`.
      6- If `validityData` is available, calculate the `expiryDate` by adding the validity period to the `initialDate`.
      7- Create a `transaction` object that includes `initialDate`, `points`, `isExpired`, and `expiryDate`.
      8- Log the transaction for debugging purposes.
      9- Generate a unique key based on the current timestamp.
      10- Initialize the points array for the user if it doesn't exist.
      11- Push the new transaction into the user's points.
      12- Update the `pointsArray` for the selected user.
      13- Reset the transaction form state.
      14- Update the user's points in the database.
      15- Recalculate the user's total points after adding the transaction.
      16- Handle any errors that occur during the process by logging an error message.
  */

  saveChanges(user: any) {
    const userPointsPath = `/projects/${this.builderService.selectedProject}/users/${user.uid}/points`;
    const validityPath = `/projects/${this.builderService.selectedProject}/settings/points/validity_date`;

    this.db.getDatabase(validityPath).subscribe(
      (validityData: any) => {
        if (!this.newPoints || !this.newPoints.points) {
          this.toAddPoints = false;
          return;
        }

        const currentDate = new Date();
        currentDate.setHours(0, 0, 0, 0);
        const expiryDate = new Date(currentDate);
        expiryDate.setDate(expiryDate.getDate() + validityData);

        const transaction = {
          expiryDate: expiryDate.toLocaleDateString(),
          initialDate: currentDate.toLocaleDateString(),
          isExpired: false,
          points: this.newPoints.points,
        };

        const key = Date.now().toString();
        if (!this.selectedUser.points[key]) {
          this.selectedUser.points[key] = [];
        }
        this.selectedUser.points[key].push(transaction);

        this.pointsArray();
        this.toAddPoints = false;

        this.db.setDatabase(userPointsPath, user.points).subscribe(
          (response) => {
            console.log('User points updated successfully!', response);
          },
          (error) => {
            console.error('Error saving points customization:', error);
          }
        );
      },
      (error) => {
        console.error('Error fetching validity date:', error);
      }
    );
  }

  /*
  showUserHistory
    1- Check if the clicked user is already selected. If so, deselect the user by setting `selectedUser` to `null`.
    2- If a different user is clicked, select the new user and assign them to `selectedUser`.
    3- Generate the `pointsArray` for the selected user by flattening their points into an array.
    4- Wrap `selectedUser.pointsArray` in a `MatTableDataSource` to enable table features like pagination and sorting.
    5- Assign the paginator (`paginator2`) to `pointsDataSource` to enable pagination for the second table.
*/

  showUserHistory(element: any) {
    if (this.selectedUser === element) {
      this.selectedUser = null;
    } else {
      this.selectedUser = element;
      this.pointsArray();
      this.pointsDataSource = new MatTableDataSource(
        this.selectedUser.pointsArray
      );
      setTimeout(() => {
        this.pointsDataSource.paginator = this.paginator2;
      }, 100);
    }
  }

  /*
    pointsArray
      1- Initialize an empty array to hold all points.
      2- Iterate over each key in the selected user's points object.
      3- For each point, push it into the `pointsArray`.
      4- Assign the populated array to `selectedUser.pointsArray` for display.
  */

  pointsArray() {
    const pointsArray: any[] = [];
    Object.keys(this.selectedUser.points).forEach((key) => {
      this.selectedUser.points[key].forEach((point: any) => {
        pointsArray.push(point);
      });
    });

    this.selectedUser.pointsArray = pointsArray;
  }

  /*
    addTransaction
      1- Set the flag to indicate that a new transaction is being added.
      2- Initialize the `newTransaction` empty object.
  */

  addPoints() {
    this.toAddPoints = true;
    this.newPoints = {};
  }

  /*
    calculateTotalPoints
      1- Define the path to the user's total points in the database using the selected project ID and user UID.
      2- Retrieve the points object from the user data. If not present, initialize it as an empty object.
      3- Flatten the points from the object into a single array.
      4- Calculate the total points by summing all point values.
      5- Assign the calculated total to the user's `totalPoints` property.
      6- Update the `totalPoints` in the database at the defined path.
      7- Handle the success and error responses by logging appropriate messages.
  */

  calculateTotalPoints(user: any): void {
    const userTotalPoints = `/projects/${this.builderService.selectedProject}/users/${user.uid}/totalPoints`;
    const pointsObject = user.points || {};

    // Flatten the points from the object into an array
    const points = Object.keys(pointsObject).reduce((acc: any[], pointKey) => {
      return acc.concat(pointsObject[pointKey]);
    }, []);

    user.totalPoints = points.reduce(
      (sum: number, point: any) => sum + Number(point.points),
      0
    );

    this.db.setDatabase(userTotalPoints, user.totalPoints).subscribe(
      (response) => {
        console.log('User points updated successfully!', response);
      },
      (error) => {
        console.error('Error saving points customization:', error);
      }
    );
  }

  /*
  applyFilter
    1- Trim and convert the search text to lowercase.
    2- Define a `filterPredicate` to match users based on `displayName`, `name`, `uid`, or `email`.
    3- Apply the filter to the `userDataSource` using the processed search text.
*/

  applyFilter(): void {
    const filterValue = this.searchText
      ? this.searchText.trim().toLowerCase()
      : '';

    this.userDataSource.filterPredicate = (data: any, filter: string) => {
      return (
        data.displayName?.toLowerCase().includes(filter) ||
        data.name?.toLowerCase().includes(filter) ||
        data.email?.toLowerCase().includes(filter)
      );
    };

    this.userDataSource.filter = filterValue;
  }

  /*
  deletePoint
    1- Define the path to the user's points in the database using the selected project ID and user UID.
    2- Receive the `pointId` of the point to be deleted.
    3- Iterate through the user's points object to find the point with the matching `pointId`.
    4- Confirm if the user wants to delete the point with the found value.
    5- If confirmed, remove the specific point from the user's points object.
    6- Update the pointsArray to reflect the changes.
    7- Update the user's points in the database using the updated points object.
    8- Recalculate the user's total points after the deletion.
    9- Handle any errors that occur during the process by logging an error message.
*/
  deletePoint(user: any, pointId: string): void {
    let pointToDelete: any = null;

    Object.keys(user.points).forEach((key) => {
      const pointsArray = user.points[key];
      const point = pointsArray.find((p: any) => p === pointId);
      if (point) {
        pointToDelete = point;
      }
    });

    if (!pointToDelete) {
      console.error(`Point with ID: ${pointId} not found.`);
      return;
    }

    const confirmDeletion = window.confirm(
      `Are you sure you want to delete the point with value ${pointToDelete.points}?`
    );

    if (confirmDeletion) {
      const userPointsPath = `/projects/${this.builderService.selectedProject}/users/${user.uid}/points`;
      let pointFound = false;

      Object.keys(user.points).forEach((key) => {
        const pointsArray = user.points[key];
        const index = pointsArray.findIndex((p: any) => p === pointId);

        if (index !== -1) {
          pointsArray.splice(index, 1);
          console.log(
            `Deleted point with ID: ${pointId} from key: ${key}, index: ${index}`
          );
          pointFound = true;

          if (pointsArray.length === 0) {
            delete user.points[key];
            console.log(`Removed key: ${key} as it has no more points.`);
          }
        }
      });

      if (pointFound) {
        this.pointsArray();

        this.db.setDatabase(userPointsPath, user.points).subscribe(
          (response) => {
            console.log(
              'User points updated successfully after deletion!',
              response
            );
          },
          (error) => {
            console.error('Error deleting point:', error);
          }
        );

        this.calculateTotalPoints(user);
      } else {
        console.error(`Point with ID: ${pointId} not found.`);
      }
    } else {
      console.log('Deletion canceled by the user.');
    }
  }
}
