<form [formGroup]="paymentSettingsForm" id="paymentSettingsForm" (ngSubmit)="saveSettings()" class="form-container">
  <div class="productsWrapper">
    <div class="payment-methods tableWrapper">
      <!-- Header Section -->
      <div class="tableHeader">
        <h1>{{ 'Payment Gateway' | translate }}</h1>
        <p>{{ 'The payment method appears in the checkout page, Please choose the allowed payment methods that you would like to use' | translate }}</p>
      </div>

      <!-- Payment Method Grid -->
      <div class="payment-method-grid">
        <!-- Main Payment Methods (including Paymob parent) -->
        <ng-container *ngFor="let payment of paymentMethods">
          <div class="payment-method-item" *ngIf="payment.id === 'cod' || payment.id === 'stripe' || payment.id === 'geidea' || payment.id === 'paymob'">
            <div class="payment-card" [ngClass]="{ active: payment.checked }">
              <button type="button" class="payment-button" (click)="togglePayment(payment)">
                <img loading="lazy" [src]="getPaymentIcon(payment.id, payment.checked)" class="payment-icon" alt="{{ payment.title }} icon" />
                <span class="label">{{ payment.title }}</span>
              </button>
              <!-- Conditional Inputs Inside Card -->
              <div *ngIf="payment.checked" class="card-input">
                <ng-container *ngIf="payment.id === 'geidea'">
                  <mat-form-field subscriptSizing="dynamic" appearance="outline" class="card-form-field">
                    <mat-label>Merchant public key</mat-label>
                    <input matInput formControlName="geideaPublicKey" [required]="payment.checked" />
                    <mat-error *ngIf="paymentSettingsForm.get('geideaPublicKey')?.hasError('required')">
                      {{ 'Field required' | translate }}
                    </mat-error>
                    <mat-error *ngIf="paymentSettingsForm.get('geideaPublicKey')?.hasError('invalidPublicKey')">
                      {{ invalidGeideaKey }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field subscriptSizing="dynamic" appearance="outline" class="card-form-field">
                    <mat-label>API password</mat-label>
                    <input matInput formControlName="geideaApiPassword" [required]="payment.checked" />
                    <mat-error *ngIf="paymentSettingsForm.get('geideaApiPassword')?.hasError('required')">
                      {{ 'Field required' | translate }}
                    </mat-error>
                    <mat-error *ngIf="paymentSettingsForm.get('geideaApiPassword')?.hasError('invalidPassword')">
                      {{ invalidGeideaKey }}
                    </mat-error>
                  </mat-form-field>
                </ng-container>
                <ng-container *ngIf="payment.id === 'stripe'">
                  <mat-form-field subscriptSizing="dynamic" appearance="outline" class="card-form-field">
                    <mat-label>{{ 'Secret key' | translate }}</mat-label>
                    <input matInput formControlName="stripApiKey" [required]="payment.checked" />
                    <mat-error *ngIf="paymentSettingsForm.get('stripApiKey')?.hasError('required')">
                      {{ 'Field required' | translate }}
                    </mat-error>
                    <mat-error *ngIf="paymentSettingsForm.get('stripApiKey')?.hasError('invalidStripeApiKey')">
                      {{ invalidStripeApiKey }}
                    </mat-error>
                  </mat-form-field>
                </ng-container>
                <ng-container *ngIf="payment.id === 'paymob'">
                  <mat-form-field subscriptSizing="dynamic" appearance="outline" class="card-form-field">
                    <mat-label>{{ 'Paymob API Key' | translate }}</mat-label>
                    <input matInput formControlName="paymobApiKey" [required]="payment.checked" />
                    <mat-error *ngIf="paymentSettingsForm.get('paymobApiKey')?.hasError('required')">
                      {{ 'API Key required' | translate }}
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field subscriptSizing="dynamic" appearance="outline" class="card-form-field">
                    <mat-label>{{ 'Paymob iFrame URL' | translate }}</mat-label>
                    <input matInput formControlName="paymobIframeUrl" [required]="payment.checked" />
                    <mat-error *ngIf="paymentSettingsForm.get('paymobIframeUrl')?.hasError('required')">
                      {{ 'iFrame URL required' | translate }}
                    </mat-error>
                  </mat-form-field>
                </ng-container>
              </div>
            </div>
          </div>
        </ng-container>

        <!-- Paymob Sub-Methods (appear only if Paymob is checked and fields are filled) -->
        <ng-container *ngIf="isPaymobChecked && arePaymobFieldsFilled()">
          <ng-container *ngFor="let payment of paymobSubMethods">
            <div class="payment-method-item">
              <div class="payment-card" [ngClass]="{ active: payment.checked }">
                <button type="button" class="payment-button" (click)="togglePayment(payment)">
                  <img loading="lazy" [src]="getPaymentIcon(payment.id, payment.checked)" class="payment-icon" alt="{{ payment.title }} icon" />
                  <span class="label">{{ payment.title }}</span>
                </button>
                <div *ngIf="payment.checked" class="card-input">
                  <mat-form-field subscriptSizing="dynamic" appearance="outline" class="card-form-field">
                    <mat-label>{{ payment.description }} Payment ID</mat-label>
                    <input matInput [formControlName]="getControlName(payment.id)" [required]="payment.checked" (ngModelChange)="updatePaymentId(payment, $event)" />
                    <mat-error *ngIf="paymentSettingsForm.get(getControlName(payment.id))?.hasError('required')">
                      {{ 'Field required' | translate }}
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</form>
