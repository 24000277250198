import { Component, OnInit } from '@angular/core'
import { environment } from 'src/environments/environment'
import { LoginService } from '../login/login.service'
import { BuilderService } from '../builder-services/builder.service'
import { SidebarService } from './sidebar.service'
import { AppUsersService } from '../app-users/services/app-users.service'
import { Observable, of } from 'rxjs'
import { catchError, map } from 'rxjs/operators'
export interface MenuItem {
  name: string
  icon: string
  route: string
}

@Component({
  selector: 'sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  isActive = false
  menus: MenuItem[] = []
  projectId = this.builderService.selectedProject
  user_email = this.loginService.getCurrentUser().email
  isOrderManager: boolean = false
  isProductManager: boolean = false
  constructor(
    public loginService: LoginService,
    public builderService: BuilderService,
    private sidebarService: SidebarService,
    private AppUsersService: AppUsersService
  ) {
    this.sidebarService.getMenuItems().subscribe((data) => {
      this.menus = data
    })
  }

  mobile = false
  appDropdown = false
  commerceDropdown = false
  packageType: any
  // sidebarCollapsed = true;
  environment = environment
  ngOnInit(): void {
    setTimeout(() => {
      this.packageType = JSON.parse(this.builderService.cacheProjectReq.response.premium[0].package).type
      console.log(this.packageType)
    }, 4000)

    if (window.screen.width <= 768) {
      // 768px portrait
      this.mobile = true
    }
    this.handleUserRole()
  }

  toggleSideMenu() {
    this.builderService.sidebarOpened = !this.builderService.sidebarOpened
    this.commerceDropdown = false
    this.appDropdown = false
  }

  toggleListedCommerce() {
    this.builderService.sidebarOpened = true
    this.commerceDropdown = !this.commerceDropdown
  }
  toggleGenerate() {
    this.builderService.sidebarOpened = true
    this.appDropdown = !this.appDropdown
  }

  toggleSidebar(className: string, element: HTMLElement) {
    this.builderService.sidebarOpened = true
    element.classList.toggle(className)
  }

  // sidebarHome = () => {
  //   this.sidebarCollapsed = !this.sidebarCollapsed;
  // }
  isAdmin(): boolean {
    if (this.loginService.getCurrentUser().admin) {
      return true
    }
    return false
  }
  checkUserRole(): Observable<string | null> {
    return this.AppUsersService.getUsersByProjectId(this.projectId).pipe(
      map((projectUsers: any) => {
        console.log('this.user_email', this.user_email)
        const currentUser = projectUsers.find((user: any) => this.user_email === user.email)
        console.log('currentUser', currentUser)
        let userRole = currentUser ? currentUser.user_role : null
        return userRole
      }),
      catchError((error) => {
        console.error('Error fetching users by project ID:', error)
        return of(null)
      })
    )
  }

  handleUserRole() {
    this.checkUserRole().subscribe((currentUserRole) => {
      if (currentUserRole == 'orderManager') {
        this.isOrderManager = true
      }
      if (currentUserRole == 'productManager') {
        this.isProductManager = true
      }
    })
  }
}
