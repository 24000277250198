<div id="headerFirstRow" class="headerRow" *ngIf="!this.mobile">
  <div id="builderLogo">
    <img src="assets/images/stacks.png" [routerLink]="['/']" />
    <a [routerLink]="['changelog']" class="desktopOnly">V6.4<mat-icon class="material-icons-outlined">open_in_new</mat-icon></a>
    <!-- <span *ngIf="!builderService.is_premium && finishedFetchingProject && loginService.getCurrentUser().admin" class="stacksBtn green titleBtn">Trial</span> -->
  </div>
  <app-counter id="appCounter" *ngIf="builderService.projectAccess == 'free' && !builderService.globals && builderService.projectStartDate"></app-counter>
  <div class="headerRightWrapper">
    <!-- <button *ngIf="!builderService.getTour() && environment.showStripe && builderService.user_type !== 'self_ltd'" [routerLink]="['payment']" class="upgradePlan stacksBtn blue ArabicButton">
      {{'Subscribe Now' | translate }}
    </button> -->
    <span *ngIf="this.SubscriptionFlow.isSubscriptionVisible" [ngClass]="builderService.premium_status === 'cancelled' || builderService.premium_status === 'deleted' ? 'red' : builderService.premium_status === 'onhold' ? 'orange' : 'green'" class="stacksBtn titleBtn" (click)="SubscriptionFlow.statusPopup()"
      >{{ builderService.premium_status }} Subscription</span
    >

    <button mat-icon-button id="settingsWrapper" [routerLink]="['help']">
      <span class="material-icons material-icons-outlined helpIcon">help_outline</span>
    </button>

    <button *ngIf="!builderService.getTour() && environment.showStripe && builderService.user_type == 'self_ltd'" [routerLink]="['payment']" class="upgradePlan stacksBtn blue glow">
      <span *ngIf="!this.mobile">{{ 'Get Lifetime Offer' | googleTranslate | async | extractGoogleTranslation }} </span>
      <span *ngIf="this.mobile">{{ 'Get Offer' | googleTranslate | async | extractGoogleTranslation }}</span>
    </button>
    <div *ngIf="!builderService.getTour()">
      <button [matMenuTriggerFor]="menu" mat-icon-button id="settingsWrapper" class="desktopOnly" *ngIf="builderService.selectedProject">
        <span class="material-icons material-icons-outlined">settings</span>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item [routerLink]="['generalsettings']">{{ 'General Settings' | translate }}</button>
        <button mat-menu-item [routerLink]="['firebasesettings']">{{ 'Firebase Settings' | translate }}</button>
        <button mat-menu-item [routerLink]="['applicationsettings']">{{ 'Application Settings' | translate }}</button>
        <button mat-menu-item [routerLink]="['contentsettings']">{{ 'Content Settings' | translate }}</button>
        <button mat-menu-item [routerLink]="['applesettings']">{{ 'Apple Settings' | translate }}</button>
      </mat-menu>
    </div>
    <div class="header">
      <button class="langSwitcher" mat-button [matMenuTriggerFor]="menu">
        <!-- Display flag or language code based on selected language -->
        <span *ngIf="languageService.getLanguage() === 'en'">English</span>
        <span *ngIf="languageService.getLanguage() === 'ar'">العربية</span>
      </button>
      <mat-menu #menu="matMenu">
        <!-- Language options -->
        <button mat-menu-item (click)="changeLang('en')">English</button>
        <button mat-menu-item (click)="changeLang('ar')">العربية</button>
      </mat-menu>
    </div>

    <div id="accountWrapper">
      <button [matMenuTriggerFor]="menuAccount" mat-icon-button id="settingsWrapper">
        <span class="material-icons">account_circle</span>
      </button>
      <!-- <mat-icon></mat-icon> -->
      <mat-menu #menuAccount="matMenu">
        <span class="customerUsername"> {{ 'Hi' | translate }} {{ loginService.getCurrentUser().username }}</span>
        <button mat-menu-item *ngIf="loginService.getCurrentUser().admin" (click)="subscriptionList()">Subs List</button>
        <!-- <button mat-menu-item (click)="purchaseLicense()">Purchase License</button> -->
        <a [routerLink]="['billing']" *ngIf="environment.showStripe" mat-menu-item>{{ 'Billing' | translate }}</a>
        <button mat-menu-item (click)="resetTour()" *ngIf="builderService.selectedProject">{{ 'Reset Tour' | translate }}</button>
        <button mat-menu-item (click)="logout()">{{ 'Logout' | translate }}</button>
      </mat-menu>
    </div>
  </div>
</div>
<!-- Normal Second Row -->
<div id="headerSecondRow" class="headerRow" *ngIf="!this.mobile && !builderService.getTour()">
  <div class="headerLeftWrapper">
    <div id="projectSelector" *ngIf="!builderService.getTour()">
      <div id="projectWrapper">
        <span *ngIf="!this.mobile" [ngClass]="this.builderService.projectStatus == 'deleted' ? 'inactiveProject' : 'activeProject'" class="projectStatusDot"></span>
        <span *ngIf="!this.mobile">Project # {{ builderService.selectedProject }}</span>
        <a *ngIf="!this.mobile && !this.builderService.mobile_webview_link && !this.builderService.standalone" href="{{ this.builderService.selectedProjectDomain }}" target="_blank">{{ this.builderService.selectedProjectDomain }}</a>
        <span class="stacksBtn green titleBtn whiteText ArabicButton" *ngIf="!this.mobile && !this.builderService.mobile_webview_link && this.builderService.standalone">
          {{ 'Standalone App' | translate }}
        </span>
        <a *ngIf="this.builderService.mobile_webview_link" href="{{ this.builderService.mobile_webview_link }}" target="_blank">{{ this.builderService.mobile_webview_link }}</a>
        <button class="material-icons-outlined adminLink" *ngIf="!this.mobile && (this.builderService.selectedProjectDomain.includes('http://localhost/stacks/stacks-multisite') || this.builderService.selectedProjectDomain.includes('sites.stacksmarket.co'))" (click)="adminDashboard()" routerLinkActive="active">
          admin_panel_settings
        </button>
        <button *ngIf="!this.mobile" [routerLink]="['/projects']" class="material-icons-outlined editProjects" routerLinkActive="active">edit</button>
        <span *ngIf="builderService.projectAccess == 'webview'">Access: {{ builderService.projectAccess }} {{ this.builderService.mobile_webview_link ? ' - webview' : '' }}</span>
      </div>
    </div>
    <div id="projectPage" *ngIf="this.blockDataService.selectedView?.title && builderService.headerBtn == 'builderView' && this.mobile" [routerLink]="['/views']">{{ this.blockDataService.selectedView?.title }} {{ 'Page' | translate }}</div>
  </div>
  <div id="projectPage" *ngIf="this.blockDataService.selectedView?.title && builderService.headerBtn == 'builderView' && !this.mobile" [routerLink]="['/views']">{{ this.blockDataService.selectedView?.title }} {{ 'Page' | translate }}</div>
  <div class="headerRightWrapper">
    <button *ngIf="!builderService.getTour()" mat-icon-button [routerLink]="['versions']" class="versionsBtn stacksBtn lightBtn blue">
      <mat-icon class="material-icons material-icons-outlined">history</mat-icon>
      {{ 'Versions History' | translate }}
    </button>
    <button mat-icon-button id="updateView" class="updateBtn stacksBtn lightBtn red" form="appSettingsWrapper" *ngIf="builderService.headerBtn == 'appSettings' && builderService.selectedProject">
      <span>{{ 'Update' | translate }}</span>
    </button>
    <button mat-icon-button id="updateView" class="updateBtn stacksBtn lightBtn red" form="firebaseSettingsWrapper" *ngIf="builderService.headerBtn == 'firebasesettings' && builderService.selectedProject">
      <span>{{ 'Update' | translate }}</span>
    </button>
    <button mat-icon-button id="updateView" class="updateBtn stacksBtn lightBtn red" form="multilingual" *ngIf="builderService.headerBtn == 'multilingual' && builderService.selectedProject">
      <span>{{ 'Update' | translate }}</span>
    </button>
    <!-- ecommerce settings page  -->
    <button mat-icon-button id="updateView" class="updateBtn stacksBtn lightBtn red" form="settingsForm" *ngIf="builderService.headerBtn == 'e-commerce/settings' && builderService.selectedProject">
      <span>{{ 'Update Settings' | translate }}</span>
    </button>
    <!-- order Status in single-order/:id page  -->
    <button mat-icon-button id="updateView" class="updateBtn stacksBtn lightBtn red" form="orderStatusForm" *ngIf="builderService.headerBtn == 'orders/single-order' && builderService.selectedProject">
      <span>{{ 'Update' | translate }}</span>
    </button>
    <button mat-icon-button id="updateView" class="updateBtn stacksBtn lightBtn red" form="txtTranslations" *ngIf="builderService.headerBtn == 'txtTranslations' && builderService.selectedProject">
      <span>{{ 'Update' | translate }}</span>
    </button>
    <button mat-icon-button id="updateView" class="updateBtn stacksBtn lightBtn red" form="social" *ngIf="builderService.headerBtn == 'social' && builderService.selectedProject">
      <span>{{ 'Update' | translate }}</span>
    </button>
    <button mat-icon-button id="updateView" class="updateBtn stacksBtn lightBtn red" form="PNForm" *ngIf="builderService.headerBtn == 'pushnotifications' && builderService.selectedProject">
      <span>{{ 'Update' | translate }}</span>
    </button>
    <button mat-icon-button id="updateView" class="updateBtn stacksBtn lightBtn red" form="applicationSettingsWrapper" *ngIf="builderService.headerBtn == 'applicationSettings' && builderService.selectedProject">
      <span>{{ 'Update' | translate }}</span>
    </button>
    <button mat-icon-button id="updateView" class="updateBtn stacksBtn lightBtn red" form="points" *ngIf="builderService.headerBtn == 'points' && builderService.selectedProject">
      <span>{{ 'Update' | translate }}</span>
    </button>
    <button mat-icon-button id="updateView" class="updateBtn stacksBtn lightBtn red" form="contentSettingsWrapper" *ngIf="builderService.headerBtn == 'contentsettings' && builderService.selectedProject">
      <span>{{ 'Update' | translate }}</span>
    </button>
    <button mat-icon-button id="updateView" class="updateBtn stacksBtn lightBtn red" form="appleSettingsWrapper" *ngIf="builderService.headerBtn == 'applesettings' && builderService.selectedProject">
      <span>{{ 'Update' | translate }}</span>
    </button>
    <button mat-icon-button id="updateView" class="updateBtn stacksBtn lightBtn red" form="paymentSettingsForm" *ngIf="builderService.headerBtn == 'paymentSettingsForm' && builderService.selectedProject">
      <span>{{ 'Update' | translate }}</span>
    </button>
    <button mat-icon-button id="updateView" class="updateBtn stacksBtn lightBtn red" form="androidBuildWrapper" *ngIf="builderService.headerBtn == 'android' && builderService.selectedProject">
      <span>{{ 'Generate App' | translate }}</span>
    </button>
    <button mat-icon-button id="updateView" class="updateBtn stacksBtn lightBtn red" form="iosBuildWrapper" *ngIf="builderService.headerBtn == 'ios' && builderService.selectedProject">
      <span>{{ 'Generate App' | translate }}</span>
    </button>
    <!-- <button mat-icon-button id="updateView" class="updateBtn" form="applicationSettingsWrapper"
      *ngIf="builderService.headerBtn == 'applesettings';">
      <span>{{'Update' | translate }}</span>
    </button> -->
    <button mat-icon-button id="updateBuilder" class="updateBtn stacksBtn lightBtn red" [ngClass]="builderService.getTour() ? 'glow' : ''" (click)="updateBuilderView()" *ngIf="builderService.headerBtn == 'builderView' && builderService.selectedProject">
      <span>{{ 'Update' | translate }}</span>
    </button>
    <button mat-icon-button id="updateBuilder" class="updateBtn stacksBtn lightBtn red" (click)="updateBuilderGlobals()" *ngIf="builderService.headerBtn == 'globals' && builderService.selectedProject">
      <span>{{ 'Save Globals' | translate }}</span>
    </button>
    <button *ngIf="builderService.headerBtn == 'builderView'" class="stacksBtn lightBtn green previewAppBtn" [routerLink]="['/app-preview']">
      <mat-icon class="material-icons material-icons-outlined">play_circle</mat-icon>
      {{ 'Preview App' | translate }}
    </button>
    <button class="stacksBtn lightBtn blue resetAppBtn" id="resetView" mat-icon-button (click)="resetView()" *ngIf="this.blockDataService.selectedView?.view_name !== 'home' && builderService.headerBtn == 'builderView'">
      <mat-icon class="material-icons material-icons-outlined">restart_alt</mat-icon>
      {{ 'Reset View' | translate }}
    </button>
  </div>
</div>

<!-- Mobile Second Row -->
<!-- <div id="headerSecondRow" class="headerRow" *ngIf="!builderService.getTour() && this.mobile && builderService.headerBtn !== ''">
  <div class="headerLeftWrapper">
    <div id="projectSelector" *ngIf="!builderService.getTour() && builderService.selectedProject">
      <div id="projectWrapper">
          <button *ngIf="!this.mobile" [routerLink]="['/projects']" class="material-icons-outlined editProjects" routerLinkActive="active">
            edit
          </button>
      </div>
    </div>
    <div id="projectPage" *ngIf="this.blockDataService.selectedView?.title && builderService.headerBtn == 'builderView' && this.mobile" [routerLink]="['/views']">
      {{this.blockDataService.selectedView?.title}} Page
    </div>
  </div>
  <div id="projectPage" *ngIf="this.blockDataService.selectedView?.title && builderService.headerBtn == 'builderView' && !this.mobile" [routerLink]="['/views']">
    {{this.blockDataService.selectedView?.title}} Page
  </div>
  <div class="headerRightWrapper">
    <button mat-icon-button id="updateView" class="updateBtn stacksBtn lightBtn red" form="appSettingsWrapper" *ngIf="builderService.headerBtn == 'appSettings' && builderService.selectedProject">
      <span>{{'Update' | translate }}</span>
    </button>
    <button mat-icon-button id="updateView" class="updateBtn stacksBtn lightBtn red" form="applicationSettingsWrapper"
      *ngIf="builderService.headerBtn == 'applicationSettings' && builderService.selectedProject">
      <span>{{'Update' | translate }}</span>
    </button>
    <button mat-icon-button id="updateView" class="updateBtn stacksBtn lightBtn red" form="contentSettingsWrapper"
      *ngIf="builderService.headerBtn == 'contentsettings' && builderService.selectedProject">
      <span>{{'Update' | translate }}</span>
    </button>
    <button mat-icon-button id="updateView" class="updateBtn stacksBtn lightBtn red" form="appleSettingsWrapper"
      *ngIf="builderService.headerBtn == 'applesettings' && builderService.selectedProject">
      <span>{{'Update' | translate }}</span>
    </button>
    <button mat-icon-button id="updateView" class="updateBtn stacksBtn lightBtn red" form="androidBuildWrapper"
      *ngIf="builderService.headerBtn == 'android' && builderService.selectedProject">
      <span>{{'Generate App' | translate }}</span>
    </button>
    <button mat-icon-button id="updateView" class="updateBtn stacksBtn lightBtn red" form="iosBuildWrapper"
      *ngIf="builderService.headerBtn == 'ios' && builderService.selectedProject">
      <span>{{'Generate App' | translate }}</span>
    </button>
    <button mat-icon-button id="updateBuilder" class="updateBtn stacksBtn lightBtn red" (click)="updateBuilderView()"
      *ngIf="builderService.headerBtn == 'builderView' && builderService.selectedProject">
      <span>{{'Update' | translate }}</span>
    </button>
    <button mat-icon-button id="updateBuilder" class="updateBtn stacksBtn lightBtn red" (click)="updateBuilderGlobals()"
      *ngIf="builderService.headerBtn == 'globals' && builderService.selectedProject">
      <span>{{'Save Globals' | translate }}</span>
    </button>
    <button *ngIf="builderService.headerBtn == 'builderView'" class="stacksBtn lightBtn green previewAppBtn" [routerLink]="['/app-preview']">
      <mat-icon class="material-icons material-icons-outlined">play_circle</mat-icon>
      Preview App
    </button>
    <button class="stacksBtn lightBtn blue resetAppBtn" id="resetView" mat-icon-button (click)="resetView()" *ngIf="this.blockDataService.selectedView?.view_name !== 'home' && builderService.headerBtn == 'builderView'">
      <mat-icon class="material-icons material-icons-outlined">restart_alt</mat-icon>
      Reset View
    </button>
  </div>
</div> -->

<!-- Responsive Header -->
<div id="headerFirstRow" class="headerRow mobileHeader" *ngIf="this.mobile">
  <span id="sideMenu" class="material-icons sideMenu" (click)="toggleSidebarFn()">menu</span>
  <div [ngClass]="builderService.toggleSidebar ? 'showSidebar' : ''" class="sidebarMenu">
    <span id="closeSideMenu" class="material-icons" (click)="toggleSidebarFn()">arrow_back</span>
    <div id="projectsWrapper">
      <span [ngClass]="this.builderService.projectStatus == 'deleted' ? 'inactiveProject' : 'activeProject'" class="projectStatusDot"></span>
      <span [routerLink]="['/projects']">{{ 'Project' | translate }} {{ builderService.selectedProject }}</span>
    </div>
    <button *ngIf="!builderService.getTour() && environment.showStripe && builderService.user_type == 'self_ltd'" [routerLink]="['payment']" class="upgradePlan stacksBtn red">
      <span *ngIf="this.mobile">{{ 'Get Offer' | googleTranslate | async | extractGoogleTranslation }}</span>
    </button>
    <sidebar *ngIf="this.loginService.isLoggedIn() && !builderService.getTour()"></sidebar>
    <app-counter id="appCounter" *ngIf="builderService.projectAccess == 'free' && !builderService.globals"></app-counter>
    <div class="languageSwitcher" *ngIf="countries.selectedCountry.length > 1">
      <button class="langSwitcher" mat-button [matMenuTriggerFor]="menu">English</button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item *ngFor="let country of countries.selectedCountry" (click)="changeLang(country.code)">{{ country.name }}</button>
      </mat-menu>
    </div>
  </div>
  <div id="builderLogo">
    <img src="assets/images/responsive/logo.png" [routerLink]="['/']" />
    <button [routerLink]="['/billing']" class="stacksBtn blue">{{ 'Subscribe' | translate }}</button>
  </div>
  <div class="headerRightWrapper">
    <div *ngIf="!builderService.getTour()">
      <button mat-icon-button id="settingsWrapper" [routerLink]="['help']">
        <span class="material-icons material-icons-outlined helpIcon">help_outline</span>
      </button>
      <button [matMenuTriggerFor]="menu" mat-icon-button id="settingsWrapper" *ngIf="builderService.selectedProject">
        <span class="material-icons material-icons-outlined">{{ 'settings' | translate }} </span>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item [routerLink]="['generalsettings']">{{ 'General Settings' | translate }}</button>
        <!-- <button mat-menu-item [routerLink]="['firebasesettings']">{{'Firebase Settings' | translate }}</button> -->
        <button mat-menu-item [routerLink]="['applicationsettings']">{{ 'Application Settings' | translate }}</button>
        <button mat-menu-item [routerLink]="['contentsettings']">{{ 'Content Settings' | translate }}</button>
        <button mat-menu-item [routerLink]="['applesettings']">{{ 'Apple Settings' | translate }}</button>
      </mat-menu>
    </div>
    <div id="accountWrapper">
      <button [matMenuTriggerFor]="menuAccount" mat-icon-button id="settingsWrapper">
        <span class="material-icons">account_circle</span>
        <span *ngIf="!this.mobile">Welcome {{ loginService.getCurrentUser().username }}</span>
      </button>
      <!-- <mat-icon></mat-icon> -->
      <mat-menu #menuAccount="matMenu">
        <span class="customerUsername">Hi {{ loginService.getCurrentUser().username }}</span>
        <!-- <button mat-menu-item (click)="purchaseLicense()">Purchase License</button> -->
        <a [routerLink]="['/billing']" class="stacksBtn red lightBtn">{{ 'Upgrade Plan' | translate }}</a>
        <a [routerLink]="['billing']" *ngIf="environment.showStripe" mat-menu-item>{{ 'Billing' | translate }}</a>
        <button mat-menu-item (click)="resetTour()" *ngIf="builderService.selectedProject">{{ 'Reset Tour' | translate }}</button>
        <button mat-menu-item (click)="logout()">{{ 'Logout' | translate }}</button>
      </mat-menu>
    </div>
  </div>
</div>

<div id="mobileHeaderSecondRow" *ngIf="this.mobile && !builderService.getTour()" [ngClass]="builderService.getTour() ? 'headerRightElements' : ''">
  <div id="projectPage" *ngIf="!builderService.getTour() && this.blockDataService.selectedView?.title && builderService.headerBtn == 'builderView'" [routerLink]="['/views']">{{ this.blockDataService.selectedView?.title }} {{ 'Page' | translate }}</div>
  <button mat-icon-button id="updateView" class="updateBtn stacksBtn lightBtn red" form="appSettingsWrapper" *ngIf="builderService.headerBtn == 'appSettings' && builderService.selectedProject">
    <span>{{ 'Update' | translate }}</span>
  </button>
  <button mat-icon-button id="updateView" class="updateBtn stacksBtn lightBtn red" form="firebaseSettingsWrapper" *ngIf="builderService.headerBtn == 'firebasesettings' && builderService.selectedProject">
    <span>{{ 'Update' | translate }}</span>
  </button>
  <button mat-icon-button id="updateView" class="updateBtn stacksBtn lightBtn red" form="applicationSettingsWrapper" *ngIf="builderService.headerBtn == 'applicationSettings' && builderService.selectedProject">
    <span>{{ 'Update' | translate }}</span>
  </button>
  <button mat-icon-button id="updateView" class="updateBtn stacksBtn lightBtn red" form="contentSettingsWrapper" *ngIf="builderService.headerBtn == 'contentsettings' && builderService.selectedProject">
    <span>{{ 'Update' | translate }}</span>
  </button>
  <button mat-icon-button id="updateView" class="updateBtn stacksBtn lightBtn red" form="appleSettingsWrapper" *ngIf="builderService.headerBtn == 'applesettings' && builderService.selectedProject">
    <span>{{ 'Update' | translate }}</span>
  </button>
  <button mat-icon-button id="updateView" class="updateBtn stacksBtn lightBtn red" form="androidBuildWrapper" *ngIf="builderService.headerBtn == 'android' && builderService.selectedProject">
    <span>{{ 'Generate App' | translate }}</span>
  </button>
  <button mat-icon-button id="updateView" class="updateBtn stacksBtn lightBtn red" form="iosBuildWrapper" *ngIf="builderService.headerBtn == 'ios' && builderService.selectedProject">
    <span>{{ 'Generate App' | translate }}</span>
  </button>
  <button mat-icon-button id="updateBuilder" class="updateBtn stacksBtn lightBtn red" (click)="updateBuilderView()" [ngClass]="builderService.getTour() && builderService.onboardingBlockAdded ? 'glow ' : ''" *ngIf="builderService.headerBtn == 'builderView' && builderService.selectedProject">
    <span>{{ 'Update' | translate }}</span>
  </button>
  <button mat-icon-button id="updateBuilder" class="updateBtn stacksBtn lightBtn red" (click)="updateBuilderGlobals()" *ngIf="builderService.headerBtn == 'globals' && builderService.selectedProject">
    <span>{{ 'Save Globals' | translate }}</span>
  </button>
  <button class="stacksBtn lightBtn blue resetAppBtn" id="resetView" mat-icon-button (click)="resetView()" *ngIf="this.blockDataService.selectedView?.view_name !== 'home' && builderService.headerBtn == 'builderView'">
    <mat-icon class="material-icons material-icons-outlined">restart_alt</mat-icon>
    {{ 'Reset View' | translate }}
  </button>
</div>
