<div class="containerWrapper">
  <table mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="project_id">
      <th mat-header-cell *matHeaderCellDef id="project_id" mat-sort-header="project_id">Project Id</th>
      <td mat-cell *matCellDef="let element">{{ element.project_id }}</td>
    </ng-container>

    <ng-container matColumnDef="package_name">
      <th mat-header-cell *matHeaderCellDef id="package_name" mat-sort-header="package_name">Name</th>
      <td mat-cell *matCellDef="let element">{{ element.package_name }}</td>
    </ng-container>

    <ng-container matColumnDef="visitors">
      <th mat-header-cell *matHeaderCellDef id="visitors" mat-sort-header="visitors">Number of Visitors</th>
      <td mat-cell *matCellDef="let element">{{ element.visitors }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
