<form [formGroup]="appleSettingsForm" id="appleSettingsWrapper" class="settingsWrapper" (ngSubmit)="onSubmit()">
  <h1>{{ 'Apple Settings' | translate }}</h1>
  <!-- <h2 class="settingsSubHeading">Style settings</h2> -->
  <div class="appleContainer">
    <div>
      <h2>{{ 'Apple Key ID' | translate }}</h2>
      <p>                                                  
        {{ 'Generate an Apple Key' | translate }} <a href="https://appstoreconnect.apple.com/access/integrations/api" target="_blank">{{ 'here' | translate }}</a
        >,<br />
        {{ 'then copy and paste ID' | translate }}
      </p>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Apple Key ID' | translate }}</mat-label>
        <input matInput placeholder="ABCD98765E" formControlName="key_id" value="{{ this.appleSettingsData?.key_id }}" />
      </mat-form-field>
    </div>
    <div>
      <h2>{{ 'Apple Issuer ID' | translate }}</h2>
      <p>
        {{ 'Copy Issuer ID' | translate }} <a href="https://appstoreconnect.apple.com/access/integrations/api" target="_blank">{{ 'here' | translate }}</a
        >, <br />
        {{ 'paste in this field' | translate }}
      </p>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Apple Issuer ID' | translate }}</mat-label>
        <input matInput placeholder="1321wer-6545-7894-aasa-9876543ab123" formControlName="issuer_id" value="{{ this.appleSettingsData?.issuer_id }}" />
      </mat-form-field>
    </div>
    <div>
      <h2>{{ 'Apple Key File Path' | translate }}</h2>
      <p>
        {{ 'Download Apple Key file' | translate }} <a href="https://appstoreconnect.apple.com/access/integrations/api" target="_blank">{{ 'here' | translate }}</a>
      </p>
      <div class="uploadContainer">
        <label class="fileUpload" for="key_filepath"
          >{{ 'Choose p8' | translate }}
          <input type="file" formControlName="key_filepath" id="key_filepath" (change)="this.processFile($event, 'application/pkcs8', 'key_filepath')" accept="application/pkcs8" />
        </label>
      </div>
    </div>
    <div>
      <h2>{{ 'ITunes Email' | translate }}</h2>
      <p>{{ 'Your Itunes account email' | translate }}</p>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'Developers Account Email' | translate }}</mat-label>
        <input matInput type="email" placeholder="info@yourwebsite.com" formControlName="email" value="{{ this.appleSettingsData?.email }}" />
      </mat-form-field>
    </div>

    <div class="appSpecific">
      <h2>{{ 'App Specific Pass' | translate }}</h2>
      <p>{{ 'Used for API Integration' | translate }}</p>
      <div class="appleContainer">
        <div>
          {{ 'Sign in to your' | translate }} <a href="https://appleid.apple.com/" target="_blank">{{ 'Apple-ID-account-page' | translate }}</a>
          {{ 'In the Security section' | translate }}
        </div>
      </div>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'App Specific Pass' | translate }}</mat-label>
        <input matInput type="text" placeholder="abcd-efgh-ijkl-mnop" formControlName="app_specific_pass" value="{{ this.appleSettingsData?.app_specific_pass }}" />
      </mat-form-field>
    </div>
    <div>
      <h2>{{ 'Team Name' | translate }}</h2>
      <p>
        {{ 'Go to your' | translate }} <a href="https://developer.apple.com/account/#!/membership/" target="_blank">{{ 'Membership page' | translate }}</a
        >, {{ 'copy team name' | translate }}
      </p>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'The Developers Account' | translate }}</mat-label>
        <input matInput placeholder="John Doe" formControlName="team_name" value="{{ this.appleSettingsData?.team_name }}" />
      </mat-form-field>
    </div>
    <div>
      <h2>{{ 'Team ID' | translate }}</h2>
      <p>
        {{ 'Go to your' | translate }} <a href="https://developer.apple.com/account/#!/membership/" target="_blank">{{ 'Membership page' | translate }}</a
        >, {{ 'copy team name' | translate }}
      </p>
      <mat-form-field appearance="outline">
        <mat-label>{{ 'The Developers Account Team ID' | translate }}</mat-label>
        <input matInput placeholder="A1BC23456D" formControlName="team_id" value="{{ this.appleSettingsData?.team_id }}" />
      </mat-form-field>
    </div>
  </div>
</form>
