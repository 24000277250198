import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { HelpersService } from 'src/app/helpers.service'

@Injectable({
  providedIn: 'root'
})
export class AppsUploadedService {
  private builderUrl

  constructor(
    private http: HttpClient,
    public helpers: HelpersService,
    private builderService: BuilderService
  ) {
    this.builderUrl = this.helpers.getBuilderUrl()
    // this.builderUrl = 'https://builder.stacksmarket.co/wp-json/v4/';
  }

  getAppsUploaded = (start_from: number = 0, chunk: number = 100) => {
    let data = {
      start_from: start_from,
      chunk: 100
    }

    let optionsTemplate = {
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    }
    return this.http.post(this.builderUrl + 'get_projects_statistics', JSON.stringify(data), optionsTemplate)
  }

  getAllApps = () => {
    let optionsTemplate = {
      headers: new HttpHeaders().append('Content-Type', 'application/json')
    }
    return this.http.post(this.builderUrl + 'topProjects', '', optionsTemplate)
  }
}
