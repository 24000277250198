import { Injectable } from '@angular/core'
import { BuilderService } from '../../builder-services/builder.service'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { HelpersService } from '../../helpers.service'
import { DatabaseService } from '../../builder-services/database.service'
import { Observable } from 'rxjs'
import { OrdersService } from 'src/app/e-commerce/orders/orders.service'
import { AppSettingsService } from 'src/app/settings/services/app-settings.service'

export interface TypeObject {
  type: string
  message: string // Ensure message is always present, but it can be an empty string initially.
}

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsService {
  // nameOptions: string[] = ['User Action', 'Order Status'];
  nameOptions: string[] = ['Order Status']
  project_id = this.builderService.getSelectedProject()

  typeOptions = ['Order Received', 'Processing', 'Confirmed', 'Completed', 'Cancelled','Refunded']

  defaultEvents = [
    {
      id: 1,
      date: this.getFormattedCurrentDate(),
      name: 'Order Status',
      types: [...this.typeOptions],
      selectedType: 'Order Received',
      selectedMessage: 'order received successfully :)'
    }
  ]

  userList: any = []
  deviceIDs: any = []

  private builderUrl: string
  options = {
    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  }
  token: any

  constructor(private builderService: BuilderService, private helper: HelpersService, private http: HttpClient, private databaseService: DatabaseService, private ordersService: OrdersService, private appSettingsService: AppSettingsService) {
    this.builderUrl = this.helper.getBuilderUrl()
  }
  /**
   * Get all the users of the Customer website
   * @param id the Project ID
   */
  getUsers = (id: any) => {
    if (this.builderService.standalone) {
      return new Observable<any>((observer) => {
        this.databaseService.getDatabase('projects/' + this.builderService.selectedProject + '/users').subscribe((response: any) => {
          observer.next(response) // Notify the observer that the operation is complete
          observer.complete()
        })
      })
    } else {
      this.token = this.builderService.getToken()
      let postData = new URLSearchParams()
      postData.set('token', this.token)
      postData.set('projectId', id)
      return this.http.post(this.builderUrl + 'get-users', postData.toString(), this.options)
    }
  }
  /**
   *
   * @param title The title of the Notification
   * @param message The Message of the Notification
   * @param users Comma Separated List of the users
   * @param route The route to be executed when the Notification is received
   * @param specificRoute The specific route to be executed when the Notification is received
   * @returns
   * Send Push Notification
   */
  sendMessage = (databaseURL: string, file: any, senderId: any, title: string, message: string, users: any, route: any = '', specificRoute: any = '') => {
    console.log(users)
    let routing
    let productId
    console.log('sender ID in service: ', senderId)
    this.deviceIDs = []
    for (let i of users) {
      this.deviceIDs.push(i.device_id)
    }
    if (specificRoute) {
      if (route === 'products') {
        routing = '/product-single'
        productId = specificRoute
      }
    } else {
      routing = route
    }
    console.log(this.token)
    console.log(productId)
    let data = {
      page: routing,
      productId: productId,
      specificRoute: specificRoute
    }
    const formData = new FormData()
    formData.append('databaseURL', databaseURL)
    formData.append('serviceAccountFile', file) // File field name must match backend
    formData.append('deviceTokens', this.deviceIDs)
    formData.append('title', title)
    formData.append('body', message)
    formData.append('data', JSON.stringify(data))
    formData.append('senderId', senderId)

    return this.http.post('https://app.stacksmarket.co/send-notification', formData)
  }

  /**
   * Validates that the fields of the push notifications are saved correctly
   * @param id project id
   */
  validatePN = (id: any) => {
    this.token = this.builderService.getToken()
    let postData = new URLSearchParams()
    postData.set('projectId', id)
    postData.set('token', this.token)

    return this.http.post(this.builderUrl + 'validate-notifications', postData.toString(), this.options)
  }

  /**
   * Automates the process of sending push notifications based on the selected order status.
   * @param name - The type of order status to automate notifications for.
   * checkAvailability This function checks if the provided order status is available in the `typeOptions` object.
     If available, it retrieves the automation data from the database and iterates through it.
   * For each automation rule with the matching order status, it retrieves the user data,
   * checks if the user's order status matches the automation rule, and sends a push notification.
   * Finally, it updates the automation data in the database.
   */
  automate(name: string) {
    if (!this.checkAvailability(name)) {
      return // Exit early if availability check fails
    }
    this.databaseService.getDatabase('projects/' + this.project_id + '/automation').subscribe((data) => {
      for (let i = 0; i < data.length; i++) {
        if (data[i].selectedType == name) {
          for (let j = 0; j < this.ordersService.orderData.length; j++) {
            let uid = this.ordersService.orderData[j].user_id
            this.getUsers(this.project_id).subscribe((users: any) => {
              for (let k in users) {
                if (users.hasOwnProperty(uid)) {
                  if (k === uid) {
                    this.userList.push(users[k])
                    console.log('they are equal')
                    this.appSettingsService.getApplicationSettings(this.project_id).subscribe((settings: any) => {
                      if (settings && (settings.android_sender_id || settings.databaseURL)) {
                        this.databaseService.getDatabase('projects/' + this.project_id + '/settings/application_settings').subscribe((response) => {
                          const serviceAccountFile = this.appSettingsService.convertBase64ToFile(response.serviceAccBase64, 'serviceAccount.json')
                          this.sendMessage(settings.databaseURL, serviceAccountFile, settings.android_sender_id, 'Order Update', data[i].selectedMessage, this.userList).subscribe(
                            (response: any) => {
                              this.builderService.showUpdateProgress = false
                            },
                            (err) => {
                              console.log('error', err)
                            }
                          )
                        })
                      }
                    }),
                      (error: any) => {
                        console.error('Error fetching application settings', error)
                      }
                  }
                }
              }
            })
          }
        }
        this.databaseService.setDatabase('projects/' + this.project_id + '/automation', data).subscribe((response) => {})
      }
    })
  }

  /**
   * Checks if a given order status name is available in the predefined type options.
   * @param name - The name of the order status to check for availability.
   * @returns A boolean indicating whether the order status name is available (`true`) or not (`false`).
   */
  checkAvailability(name: string) {
    for (let i of this.typeOptions) {
      if (i === name) {
        return true
      }
    }
    return false
  }

  getFormattedCurrentDate(): string {
    const today = new Date()
    const month = (today.getMonth() + 1).toString().padStart(2, '0') // Add 1 because months are 0-indexed
    const day = today.getDate().toString().padStart(2, '0')
    const year = today.getFullYear()
    return `${month}/${day}/${year}`
  }
}
