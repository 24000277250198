<div class="containerWrapper">
  <div class="statisticsWrapper">
    <div class="statisticHeader">
      <h1>{{ 'Overview' | translate }}</h1>
    </div>
    <div class="statisContent">
      <div class="statisticItem" *ngFor="let item of statisticsData">
        <h3>{{ item.name | translate }}</h3>
        <p>{{ item.value }}</p>
      </div>
    </div>
  </div>

  <div class="tableWrapper">
    <!--page title-->
    <h2>Subscriptions</h2>
    <!--tabel header with add subscriber button & search bar-->
    <div class="tableHeader">
      <div class="headerBtns">
        <button [routerLink]="['/admin/newSub']">+ ADD NEW Subscriber</button>
        <form class="searchBar">
          <input type="text" matInput placeholder="{{ 'Search' | translate }}" [(ngModel)]="searchKey" name="search" (keyup)="applyFilter()" />
        </form>
      </div>
    </div>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
      <!-- Status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
        <td mat-cell *matCellDef="let element">{{ element.status }}</td>
      </ng-container>

      <!-- retrials Column -->
      <!-- <ng-container matColumnDef="retrials">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Retrials</th>
        <td mat-cell *matCellDef="let element">{{ element.retrials }}</td>
      </ng-container>  -->

      <!-- Customer Email Column -->
      <ng-container matColumnDef="customer_email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Email</th>
        <td mat-cell *matCellDef="let element">{{ element.customer_email }}</td>
      </ng-container>

      <!-- Amount Due Column -->
      <ng-container matColumnDef="total_amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Total Amount</th>
        <td mat-cell *matCellDef="let element">{{ element.total_amount }}</td>
        <!--{{getPackageById(element.Package.price_id)?.price }} {{getPackageById(element.Package.price_id)?.currency}}-->
      </ng-container>

      <!-- Invoice Date Column -->
      <ng-container matColumnDef="invoice_date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Start Date</th>
        <td mat-cell *matCellDef="let element">{{ element.start_date | date: 'dd/MM/yyyy' }}</td>
      </ng-container>

      <ng-container matColumnDef="end_date">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>End Date</th>
        <td mat-cell *matCellDef="let element">{{ element.end_date | date: 'dd/MM/yyyy' }}</td>
      </ng-container>

      <!-- payment method column-->
      <ng-container matColumnDef="payment_method">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Payment Method</th>
        <td mat-cell *matCellDef="let element">{{ element.payment_method }}</td>
      </ng-container>

      <!-- Package Type Column -->
      <ng-container matColumnDef="type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Type</th>
        <td mat-cell *matCellDef="let element">{{ element.type }}</td>
      </ng-container>

      <!-- Package Duration Column -->
      <ng-container matColumnDef="duration">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Duration</th>
        <td mat-cell *matCellDef="let element">{{ element.duration }}</td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef>action</th>
        <td mat-cell *matCellDef="let element">
          <button mat-icon-button (click)="editSubscriber(element.stripe_subscription_id ? element.stripe_subscription_id : element.id)">
            <span class="mat-button-wrapper">
              <span class="material-icons material-icons-outlined editIcon">edit</span>
            </span>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator showFirstLastButtons [pageSizeOptions]="[5, 10, 20, 30]"> </mat-paginator>
  </div>
</div>
