import { Injectable } from '@angular/core'
import { BlockDataService } from '../../block-services/block-data.service'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable } from 'rxjs'
import { HelpersService } from '../../helpers.service'
import { BuilderService } from 'src/app/builder-services/builder.service'

@Injectable({
  providedIn: 'root'
})
export class ProductsService extends BlockDataService {
  private builderUrl

  projects = {}
  selectedProject = 0
  headerBtn = ''
  options = {
    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  }
  token: any
  constructor(
    private http: HttpClient,
    public helpers: HelpersService,
    private builderService: BuilderService
  ) {
    super()
    this.token = this.builderService.getToken()
    this.builderUrl = this.helpers.getBuilderUrl()
  }

  /**
   * Get all templates from Backend
   */
  getProducts = (id: any, args: any): Observable<any> => {
    this.token = this.builderService.getToken()
    let postData = new URLSearchParams()
    postData.set('process', 'sync_ui')
    postData.set('token', this.token)
    postData.set('projectId', id)
    postData.set('args', JSON.stringify([args]))

    return this.http.post(this.builderUrl + 'getProducts', postData.toString(), this.options)
  }
}
