import { Component, OnInit } from '@angular/core'
import { Observable } from 'rxjs'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { ProductsService } from '../products/products.service'
import { CategoriesService } from '../categories/categories.service'
import { DatabaseService } from 'src/app/builder-services/database.service'

interface Subcategory {
  categoryImage: string
  subCategoryId: string
  subcategory: string
}

interface Category {
  categoryId: string
  categoryImage: string
  categoryName: string
  subcategories: Subcategory[]
}

@Component({
  selector: 'app-stacks-importer',
  templateUrl: './stacks-importer.component.html',
  styleUrls: ['./stacks-importer.component.scss']
})
export class StacksImporterComponent implements OnInit {
  constructor(
    private builderService: BuilderService,
    private productsService: ProductsService,
    private categoriesService: CategoriesService,
    private db: DatabaseService
  ) {}

  importProgress = 0
  allImagesLength = 0
  imagesUploaded = 0
  parsedCSVData: any
  importingType = 'products'

  wooUser = ''
  wooPass = ''
  wooCommerceItemsToFetch = ''
  woocommerceOffsetPage = 1

  // wooUser = 'ck_fa1a31339560f25cec2f37f005de2e14123326c9';
  // wooPass = 'cs_ec7387a4ad324f963f7c30bb389eeef209019b49';
  wooUrl = ''
  // wooUrl = 'https://test.stacksclients.com';

  corruptedImages: any = []

  ngOnInit(): void {
    this.db.getDatabase('projects/' + this.builderService.selectedProject + '/settings/wooImporter/').subscribe((response) => {
      if (response) {
        this.wooUser = response.wooUser
        this.wooPass = response.wooPass
        this.wooUrl = response.wooUrl
      }
    })
  }

  // Generate a function that imports csv file and converts it to json

  handleFileInput(event: any, type: string) {
    const file = event.target.files[0]

    if (file) {
      // Check if the file has a CSV extension
      if (file.name.toLowerCase().endsWith('.csv')) {
        this.readFileContents(file, type)
      } else {
        // Display an error message for non-CSV files
        alert('Error: Please choose a CSV file.')
      }
    }
  }

  readFileContents(file: File, type: string) {
    const reader = new FileReader()

    reader.onload = (e: any) => {
      const fileContents = e.target.result
      this.parseGoogleSheetData(fileContents, type)
    }

    reader.readAsText(file)
  }

  parseGoogleSheetData(fileContents: string, type: string) {
    this.imagesUploaded = 0
    // Parse the content, convert it to a usable format (e.g., JSON), and then process it.
    if (type === 'products') {
      this.parseProductsCSVData(fileContents).subscribe((parsedCSVData) => {
        console.log(parsedCSVData, parsedCSVData.length)
        for (let index = 0; index < parsedCSVData.length; index++) {
          const element = parsedCSVData[index]
          this.productsService.submitProduct(element).subscribe((response) => {
            console.log(response)
          })
        }
      })
      this.importingType = 'products'
    } else if (type === 'category') {
      this.parseCategoriesCSVData(fileContents).subscribe((parsedCSVData) => {
        this.categoriesService.submitCategory(parsedCSVData).subscribe((result: any) => {
          // this.router.navigate(['/products']);
        })
      })
      this.importingType = 'categories'
      // console.log(parsedCategoriesCSVData);
    }
    // Call a function to add the parsed data to Firebase Firestore.
    // this.addToFirestore(parsedData);
  }

  // Convert the CSV data to JSON.
  parseProductsCSVData(csvData: string) {
    // Function to parse a CSV line considering quoted fields
    function parseCsvLine(line: any) {
      let result = []
      let current = ''
      let inQuotes = false

      for (let char of line) {
        if (char === '"') {
          inQuotes = !inQuotes
        } else if (char === ',' && !inQuotes) {
          result.push(current.trim())
          current = ''
        } else {
          current += char
        }
      }
      result.push(current.trim()) // Add the last field
      return result
    }

    function parseCsvWithMultiLine(csvData: any) {
      var normalizedData = csvData.replace(/\r\n/g, '\n').replace(/\r/g, '\n')
      var lines = []
      var currentLine = ''
      var insideQuotes = false

      // Split into logical lines, respecting quoted newlines
      for (var i = 0; i < normalizedData.length; i++) {
        var char = normalizedData[i]

        if (char === '"') {
          insideQuotes = !insideQuotes
          currentLine += char
        } else if (char === '\n' && !insideQuotes) {
          lines.push(currentLine)
          currentLine = ''
        } else {
          currentLine += char
        }
      }
      if (currentLine) lines.push(currentLine) // Push the last line

      // Parse headers and data
      var headers = parseCsvLine(lines[0])
      var jsonData = []

      for (var i = 1; i < lines.length; i++) {
        var currentLineFields = parseCsvLine(lines[i])
        var obj: any = {}

        for (var j = 0; j < headers.length; j++) {
          obj[headers[j]] = currentLineFields[j] || '' // Handle missing fields
        }

        jsonData.push(obj)
      }

      return jsonData
    }
    var jsonData = parseCsvWithMultiLine(csvData)
    return new Observable<any>((observer) => {
      this.productStructure(jsonData, 'csv').subscribe((transformedAllData) => {
        observer.next(transformedAllData) // Notify the observer that the operation is complete
        observer.complete() // Complete the Observable
      })
    })
  }

  /**
   *
   * @param images Array of images
   * @param jsonData the csv file data
   * @param type The type of image whether image or variation_image "imageUrl"
   * @returns
   */
  uploadImagesToFirebaseStorage(images: any, jsonData: any, type: any, length = 0) {
    return new Observable<any>((observer) => {
      if (!images || images.length == 0) {
        observer.next(jsonData)
        observer.complete()
        return
      }
      console.log(this.imagesUploaded, this.allImagesLength)
      this.fetchImageAsFile(images[length]).then((file: any) => {
        console.log('fetching done')
        this.uploadFile(file).subscribe((imageUploaded) => {
          if (this.allImagesLength > 1) {
            this.importProgress = Math.round(((this.imagesUploaded + 1) / (this.allImagesLength - 1)) * 100)
            this.importProgress > 100 ? (this.importProgress = 100) : this.importProgress
          } else {
            this.importProgress = Math.round((this.imagesUploaded + 1) * 100)
          }
          jsonData[length][type] = imageUploaded
          if (length == images.length - 1) {
            observer.next(jsonData)
            observer.complete()
          } else {
            length++
            this.imagesUploaded++
            this.uploadImagesToFirebaseStorage(images, jsonData, type, length).subscribe((response) => {
              observer.next(response)
              observer.complete()
            })
          }
        })
      })
    })
  }

  // Convert the CSV data to JSON.
  parseCategoriesCSVData(csvData: string) {
    // Function to parse a CSV line considering quoted fields
    function parseCsvLine(line: any) {
      let result = []
      let current = ''
      let inQuotes = false

      for (let char of line) {
        if (char === '"') {
          inQuotes = !inQuotes
        } else if (char === ',' && !inQuotes) {
          result.push(current.trim())
          current = ''
        } else {
          current += char
        }
      }
      result.push(current.trim()) // Add the last field
      return result
    }

    // Normalize line endings and split CSV data
    var normalizedData = csvData.replace(/\r\n/g, '\n').replace(/\r/g, '\n')
    var lines = normalizedData.split('\n')
    var headers = parseCsvLine(lines[0])
    var categories: any = {}

    var jsonData: any = []

    for (var i = 1; i < lines.length; i++) {
      var currentline = parseCsvLine(lines[i])
      var obj: any = {}

      for (var j = 0; j < headers.length; j++) {
        obj[headers[j]] = currentline[j]
      }

      jsonData.push(obj)
    }

    return new Observable<any>((observer) => {
      this.categoryStructure(jsonData, 'csv').subscribe((transformedAllData) => {
        console.log(transformedAllData)
        observer.next(transformedAllData) // Notify the observer that the operation is complete
        observer.complete() // Complete the Observable
      })
    })
  }

  getFilenameFromUrl = (url: string) => {
    // Decode the URL to convert encoded parts to normal characters
    const decodedUrl = decodeURIComponent(url)

    // Split the URL by '/' and get the last part
    const parts = decodedUrl.split('/')

    // Further split the last part by '?' to remove any query parameters
    const lastSegment = parts.pop()?.split('?')[0]

    // Now, split by '-' and take all but the last segment (to remove '1-595x595.jpg')
    const nameParts: any = lastSegment?.split('-')
    nameParts.pop() // Remove the last part ('1-595x595.jpg')

    // Join the parts back together
    return nameParts.join('-')
  }

  fetchImageAsFile = (url: string) => {
    if (!url) {
      return new Promise((resolve, reject) => {
        resolve(null)
      })
    }
    let filename: any = this.getFilenameFromUrl(url)
    if (this.isGoogleDriveUrl(url)) {
      console.log('This is a Google Drive URL.')
      const fileId = url.split('/d/')[1].split('/')[0]
      url = `https://drive.google.com/uc?export=download&id=${fileId}`
      filename = 'image.jpg'
    } else {
      console.log('This is not a Google Drive URL.') // do nothing
      console.log(url)
    }
    console.log(filename)
    // url = environment.proxyUrl+url;
    return fetch(url)
      .then((response) => response.blob())
      .then((blob) => new File([blob], filename, { type: blob.type }))
  }

  isGoogleDriveUrl(url: string) {
    // Regular expression to match Google Drive URL patterns
    const googleDriveRegex = /^(https?:\/\/)?(www\.)?drive\.google\.com\/(file\/d\/|open\?id=)/

    return googleDriveRegex.test(url)
  }

  uploadFile = (file: File) => {
    console.log('start uploading')
    return new Observable<any>((observer) => {
      console.log(file)
      if (!file || file == null) {
        observer.next('')
        observer.complete()
      }
      this.builderService.uploadImage(file, this.builderService.selectedProject, 'importedProducts', file.name ? false : true)?.subscribe(
        (response) => {
          observer.next(response)
          observer.complete()
        },
        (error) => {
          // array unique the corrupted images
          if (this.corruptedImages.indexOf(file.name) == -1) {
            this.corruptedImages.push(file.name)
          }
          console.log(error, file.name)
          observer.next(error)
          observer.complete()
        }
      )
    })
  }

  startImportingWooProducts() {
    if (!this.wooUser || !this.wooPass || !this.wooUrl) {
      alert('Please enter the woocommerce credentials')
      return
    }
    this.db
      .setDatabase('projects/' + this.builderService.selectedProject + '/settings/wooImporter/', {
        wooUser: this.wooUser,
        wooPass: this.wooPass,
        wooUrl: this.wooUrl
      })
      .subscribe((response) => {
        this.importWooCommerceProducts().subscribe((parsedCSVData) => {
          console.log(parsedCSVData, parsedCSVData.length)
          for (let index = 0; index < parsedCSVData.length; index++) {
            const element = parsedCSVData[index]
            this.productsService.submitProduct(element).subscribe((response) => {
              console.log(response)
            })
          }
        })
      })
  }

  startImportingWooCategories() {
    if (!this.wooUser || !this.wooPass || !this.wooUrl) {
      alert('Please enter the woocommerce credentials')
      return
    }
    this.db
      .setDatabase('projects/' + this.builderService.selectedProject + '/settings/wooImporter/', {
        wooUser: this.wooUser,
        wooPass: this.wooPass,
        wooUrl: this.wooUrl
      })
      .subscribe((response) => {
        this.importWooCommerceCats().subscribe((parsedCSVData) => {
          for (let index = 0; index < parsedCSVData.length; index++) {
            const element = parsedCSVData[index]
            this.categoriesService.submitCategory(parsedCSVData).subscribe((result: any) => {
              // this.router.navigate(['/products']);
            })
          }
        })
      })
  }
  importWooCommerceProducts() {
    this.imagesUploaded = 0
    return new Observable<any>((observer) => {
      this.productsService.getWoocommerceProducts(this.wooUser, this.wooPass, this.wooUrl, this.wooCommerceItemsToFetch, this.woocommerceOffsetPage).subscribe((response) => {
        console.log(response)

        var jsonData: any = []
        Object.keys(response).forEach((productId) => {
          let productData = response[productId]
          jsonData.push(productData)
        })

        this.productStructure(jsonData, 'Woocommerce').subscribe((transformedAllData) => {
          observer.next(transformedAllData) // Notify the observer that the operation is complete
          observer.complete() // Complete the Observable
        })
      })
      this.importingType = 'products'
    })
  }

  importWooCommerceCats() {
    this.imagesUploaded = 0
    return new Observable<any>((observer) => {
      this.productsService.getWoocommerceCats(this.wooUser, this.wooPass, this.wooUrl, this.wooCommerceItemsToFetch).subscribe(
        (response) => {
          var jsonData: any = []
          Object.keys(response).forEach((productId) => {
            let categoriesData = response[productId]
            jsonData.push(categoriesData)
          })
          this.categoryStructure(jsonData, 'Woocommerce').subscribe((transformedAllData) => {
            observer.next(transformedAllData) // Notify the observer that the operation is complete
            observer.complete() // Complete the Observable
          })
        },
        (error) => {}
      )
      this.importingType = 'categories'
    })
  }

  // Function to extract attributes
  extractAttributes = (dataRow: any) => {
    if (!dataRow['productName'] || dataRow['productName'] == undefined) {
      return []
    }
    return Object.keys(dataRow)
      .filter((key) => key.startsWith('attribute_'))
      .map((key) => {
        if (dataRow[key] == undefined) {
          return {
            name: '',
            value: ''
          }
        }
        return {
          name: key.replace('attribute_', '').trim(),
          value: dataRow[key].trim()
        }
      })
  }

  productStructure = (jsonData: any, type = 'csv') => {
    return new Observable<any>((observer) => {
      let images: any = []
      let variationImages: any = []
      console.log(jsonData)
      for (var i = 0; i < jsonData.length; i++) {
        if (jsonData[i].image) {
          variationImages.push(jsonData[i].image)
        }
        if (jsonData[i].imageUrl) {
          images.push(jsonData[i].imageUrl)
        }
      }
      let productsMap: any = {}
      this.allImagesLength = images.length + variationImages.length
      // upload normal images
      this.uploadImagesToFirebaseStorage(images, jsonData, 'imageUrl').subscribe((responseImages) => {
        jsonData = responseImages
        // upload variation images
        this.uploadImagesToFirebaseStorage(variationImages, jsonData, 'image').subscribe((responseVarImages) => {
          jsonData = responseVarImages
          for (var i = 0; i < jsonData.length; i++) {
            if (!jsonData[i].productName || jsonData[i].productName == undefined) {
              continue
            }
            let productId = jsonData[i].productId
            console.log(jsonData[i].categoriesIds)
            if (!productsMap[productId]) {
              // Initialize the product in the map if it's not already there
              let stock_status = type == 'Woocommerce' ? jsonData[i].stock_status : ''
              if (type == 'Woocommerce') {
                if (stock_status == 'instock' && jsonData[i].stockManagement == false) {
                  // unlimited quantity
                  jsonData[i].productStock = -1
                } else if (stock_status == 'outofstock') {
                  jsonData[i].productStock = 0
                }
              }
              productsMap[productId] = {
                productId: [jsonData[i].productId],
                categoriesIds: type == 'Woocommerce' ? jsonData[i].categoriesIds : [jsonData[i].categoriesIds],
                subCategoriesIds: type == 'Woocommerce' ? jsonData[i].subCategoriesIds : [jsonData[i].subCategoriesIds],
                date: jsonData[i].date,
                imageUrl: jsonData[i].imageUrl,
                // maxVarPrice: jsonData[i].maxVarPrice,
                // maxVarSale: jsonData[i].maxVarSale,
                // minVarPrice: jsonData[i].minVarPrice,
                // minVarSale: jsonData[i].minVarSale,
                productAttributes: type == 'Woocommerce' ? jsonData[i].productAttributes : this.extractAttributes(jsonData[i]).length > 0 ? this.extractAttributes(jsonData[i]) : [{ name: '', value: '' }],
                productName: jsonData[i].productName,
                productSKU: jsonData[i].productSKU,
                productStock: jsonData[i].productStock,
                estimatedDeliveryTime: jsonData[i].estimatedDeliveryTime,
                estimatedDeliveryUnit: jsonData[i].estimatedDeliveryUnit,
                sizeChartUrl: jsonData[i].sizeChartUrl,
                stockManagement: type == 'Woocommerce' ? jsonData[i].stockManagement : false,
                stock_status: type == 'Woocommerce' ? jsonData[i].stock_status : '',
                productVariations: [],
                regularPrice: jsonData[i].regularPrice,
                salePrice: jsonData[i].salePrice,
                productDescription: jsonData[i].productDescription,
                importType: type
              }
            }

            // console.log(jsonData[i], jsonData[i].variation_values);
            if (type == 'Woocommerce') {
              productsMap[productId].productVariations = jsonData[i].productVariations
            } else {
              // Add the variation to the product
              productsMap[productId].productVariations.push({
                image: jsonData[i].image,
                regularPrice: jsonData[i].variation_regularPrice,
                salePrice: jsonData[i].variation_salePrice,
                stockManagement: type == 'Woocommerce' ? jsonData[i].stockManagement : false,
                stock_status: type == 'Woocommerce' ? jsonData[i].stock_status : '',
                sku: jsonData[i].variation_sku,
                stock: jsonData[i].variation_stock,
                values: jsonData[i].variation_values
              })
            }
          }
          if (this.allImagesLength == 0) {
            this.importProgress = 100
          }
          let transformedAllData = Object.values(productsMap)
          observer.next(transformedAllData) // Notify the observer that the operation is complete
          observer.complete() // Complete the Observable
        })
      })
    })
  }

  categoryStructure = (jsonData: any, type = 'csv') => {
    return new Observable<any>((observer) => {
      let catImages: any = []
      let subCatImages: any = []
      for (var i = 0; i < jsonData.length; i++) {
        if (jsonData[i].categoryImage) {
          catImages.push(jsonData[i].categoryImage)
        }
        if (jsonData[i].subCategoryImage) {
          subCatImages.push(jsonData[i].subCategoryImage)
        }
      }
      this.allImagesLength = catImages.length + subCatImages.length
      this.uploadImagesToFirebaseStorage(catImages, jsonData, 'categoryImage').subscribe((responseImages) => {
        jsonData = responseImages
        // upload variation images
        this.uploadImagesToFirebaseStorage(subCatImages, jsonData, 'subCategoryImage').subscribe((responseSubCatImages) => {
          jsonData = responseSubCatImages
          let categories: { [key: string]: Category } = {}

          for (var i = 0; i < jsonData.length; i++) {
            let categoryId = jsonData[i].categoryId

            // Skip if the categoryId is empty or line is invalid
            if (!categoryId || !jsonData[i].categoryName) {
              continue
            }
            // Initialize category if not already present
            if (!categories[categoryId]) {
              categories[categoryId] = {
                categoryId: categoryId,
                categoryImage: jsonData[i].categoryImage,
                categoryName: jsonData[i].categoryName,
                subcategories: []
              }
            }

            // Add subcategory if present
            if (jsonData[i].subCategoryId) {
              let subCategory: Subcategory = {
                categoryImage: jsonData[i].subCategoryImage,
                subCategoryId: jsonData[i].subCategoryId,
                subcategory: jsonData[i].subCategoryName
              }

              // Check and add subcategory only if it's not already added
              if (!categories[categoryId].subcategories.some((sub: Subcategory) => sub.subCategoryId === jsonData[i].subCategoryId)) {
                categories[categoryId].subcategories.push(subCategory)
              }
            }
          }
          if (this.allImagesLength == 0) {
            this.importProgress = 100
          }
          // Transform into the desired JSON structure
          let transformedAllData = Object.values(categories)
          observer.next(transformedAllData) // Notify the observer that the operation is complete
          observer.complete() // Complete the Observable
        })
      })
    })
  }
}
