import { Component, OnInit } from '@angular/core'
import { BuilderService } from '../builder-services/builder.service'
import { environment } from 'src/environments/environment'
import { MixpanelService } from '../tracking/mixpanel.service'
import { Router } from '@angular/router'

@Component({
  selector: 'app-counter',
  templateUrl: './app-counter.component.html',
  styleUrls: ['./app-counter.component.scss']
})
export class AppCounterComponent implements OnInit {
  public environment = environment
  mobile = false
  daysRemaining: any
  constructor(
    public builderService: BuilderService,
    private mixPanel: MixpanelService,
    private router: Router
  ) {
    if (window.screen.width <= 768) {
      // 768px portrait
      this.mobile = true
    }
  }

  ngOnInit(): void {
    // Current date
    let currentDate: any = new Date()

    // Project start date
    let projectStartDate: any = new Date(this.builderService.projectStartDate)

    // Calculate the trial end date (14 days later)
    let trialEndDate: any = new Date(projectStartDate - currentDate)
    trialEndDate.setDate(trialEndDate.getDate() + 14)

    // Calculate the time difference in milliseconds between trial end date and project start date
    let timeDifference = trialEndDate

    // Calculate the number of days remaining in the trial period
    this.daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24))
    // Ensure that this.daysRemaining is at least 0
    console.log(this.daysRemaining)
    if (this.daysRemaining < 0) {
      this.daysRemaining = 0
      this.builderService.trialEnded = true
      // if(this.builderService.projectAccess == 'free') {
      //   this.router.navigate(['/expired']);
      // }
      // call stop_tunnel in builder
    }
  }

  logAction = () => {
    this.mixPanel.track('Purchase clicked', {
      projectid: this.builderService.selectedProject
    })
  }
}
