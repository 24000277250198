import { Component, OnInit, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatTable, MatTableDataSource } from '@angular/material/table'
import { MatSort } from '@angular/material/sort'
import { ChartConfiguration } from 'chart.js'
import { BaseChartDirective } from 'ng2-charts'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { FormControl, FormGroup, Validators } from '@angular/forms'
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core'
import { MomentDateAdapter } from '@angular/material-moment-adapter'
import { AnalyticsService } from '../analytics.service'
import { Router } from '@angular/router'
import { MatDialog } from '@angular/material/dialog'

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD'
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY'
  }
}

export interface ordersTableColumns {
  order_date: any
  order_id: any
  order_link: any
  order_status: string
  order_total: string
  user_email: string
}

let ELEMENT_DATA: ordersTableColumns[] = []

@Component({
  selector: 'analytics-orders',
  templateUrl: './analytics-orders.component.html',
  styleUrls: ['./analytics-orders.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class AnalyticsOrdersComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatTable)
  table!: MatTable<ordersTableColumns>

  @ViewChild(MatSort) sort!: MatSort
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective

  constructor(
    public analyticsService: AnalyticsService,
    public builderService: BuilderService,
    private router: Router,
    public dialog: MatDialog
  ) {}

  public ordersColumns: string[] = []
  public dataSource: any = []
  public selforders: any
  orders_dates: any = []

  initial_datasource: any

  form = new FormGroup({
    fromDate: new FormControl(null, { validators: [Validators.required] }),
    toDate: new FormControl(null, { validators: [Validators.required] })
  })

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
  }

  ngOnInit(): void {
    this.builderService.getProject(String(localStorage.getItem('selectedProject'))).subscribe((project: any) => {
      // Cache the response
      this.builderService.user_type = project.user_type
      this.builderService.cacheProjectReq.response = project
      this.builderService.selectedProjectDomain = project.details[0].domain
      this.getOrdersAnalytics()

      /**
       * Disable Access for free users
       */
      // if( project.details[0].access == 'free' ) {
      //   const dialogTemplateLocked = this.dialog.open(WorksDialogComponent, {
      //     width: '700px',
      //     maxWidth: '80%',
      //     data: {name: "viewLocked"},
      //   });

      //   dialogTemplateLocked.afterClosed().subscribe(result => {
      //     this.router.navigate(['/']);
      //   });
      // }
    })
  }

  /**
   * Fill Order Table
   */
  getOrdersAnalytics = () => {
    this.builderService.showUpdateProgress = true
    this.ordersColumns = ['order_id', 'order_date', 'order_status', 'order_total', 'user_email', 'order_link']
    ELEMENT_DATA = []
    this.fetchNewOrders()
  }

  /**
   * Retrieves the new Sales from the server
   */
  fetchNewOrders = () => {
    this.builderService.showUpdateProgress = true
    this.analyticsService.getOrders().subscribe((response) => {
      this.builderService.showUpdateProgress = false
      this.renderOrderAnalytics(response)
    })
  }

  /**
   * Renders the Sales data
   */
  renderOrderAnalytics = (result: any) => {
    this.builderService.showUpdateProgress = false
    let all_orders = result
    ELEMENT_DATA = []
    all_orders = Object.entries(all_orders)
      .sort()
      .reduce((o: any, [k, v]) => ((o[k] = v), o), {})
    this.selforders = all_orders
    this.formatChartData(result)
    this.renderChartData()
    for (const key in all_orders) {
      if (Object.prototype.hasOwnProperty.call(all_orders, key)) {
        const element = all_orders[key]
        ELEMENT_DATA.push({ order_id: element.id, order_link: this.builderService.selectedProjectDomain + '/wp-admin/post.php?post=' + element.id + '&action=edit', order_date: element.date, order_status: element.status, order_total: element.total, user_email: element.user_email })
      }
    }
    this.dataSource = new MatTableDataSource<ordersTableColumns>(ELEMENT_DATA)
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.initial_datasource = [...this.dataSource.data]
    setTimeout(() => {
      this.table.renderRows()
    }, 1000)
  }

  /**
   * Renders the chart data labels and data
   * @param data_type : the filtered data type doesn't need reversing the chart however the initial data rendered needs that reversal
   */

  renderChartData = (data_type: string = 'initial') => {
    let chartDataArray = []
    let chartLabelsArray = []
    for (const key in this.orders_dates) {
      if (Object.prototype.hasOwnProperty.call(this.orders_dates, key)) {
        const element = this.orders_dates[key]
        chartLabelsArray.push(key)
        chartDataArray.push(element.length)
      }
    }
    if (data_type == 'filtered') {
      this.subChartData.datasets[0].data = chartDataArray
      this.subChartData.labels = chartLabelsArray
    } else {
      this.subChartData.datasets[0].data = chartDataArray.reverse()
      this.subChartData.labels = chartLabelsArray.reverse()
    }
    this.chart?.update()
  }

  /**
   * The chart data needs date formatting to be year-month-days, this function is intended to grab the creation date from the array and format it correctly
   * @param data : the data that needs formatting
   * @param date : the date object
   */
  formatChartData = (data: any) => {
    this.orders_dates = []
    for (const key in data) {
      if (Object.prototype.hasOwnProperty.call(data, key)) {
        const element = data[key]
        // if(!this.orders_dates[key]) {
        //   this.orders_dates.push(key);
        // }
        let formatted_dates = new Date(element.date)
        let formatted_date = formatted_dates.getFullYear() + '-' + (formatted_dates.getMonth() + 1) + '-' + +formatted_dates.getDate()
        if (!this.orders_dates[formatted_date]) {
          this.orders_dates[formatted_date] = []
        }
        this.orders_dates[formatted_date].push(element)
      }
    }
  }

  // Filter the datasource, format the chart data with dates and renders the chart
  applySubFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
    this.dataSource.filter = filterValue.trim().toLowerCase()
    this.formatChartData(this.dataSource.filteredData)
    this.renderChartData('filtered')
  }

  applyDateFilter() {
    let fixedFromDate = this.addDays(this.form.value.fromDate, 0)
    let fixedToDate = this.addDays(this.form.value.toDate, 1)
    this.dataSource.data = this.dataSource.data.filter((e: { order_date: any }) => new Date(e.order_date) >= fixedFromDate && new Date(e.order_date) <= fixedToDate)
    let selforders = this.orders_dates
    let filteredChartSales: any = []
    let fromDate: any = this.form.value.fromDate
    let toDate: any = this.form.value.toDate
    Object.keys(selforders).map(function (key, index) {
      if (new Date(key) >= fromDate && new Date(key) <= toDate) {
        filteredChartSales[key] = selforders[key]
      }
    })
    this.orders_dates = filteredChartSales
    this.renderChartData()
    if (this.form.invalid) {
      return
    }
  }

  /**
   * Refetches everything again
   */
  clearDateFilter = () => {
    this.fetchNewOrders()
  }

  /**
   * Add Days to the passed date
   * @param date : normal date
   * @param days : days that needs to be added
   * @returns the date after adding the days
   */

  addDays = (date: any, days: any) => {
    var result = new Date(date)
    result.setDate(result.getDate() + days)
    return result
  }

  public subChartData: ChartConfiguration['data'] = {
    datasets: [
      {
        data: [],
        label: 'Orders per day',
        yAxisID: 'y-axis-1',
        backgroundColor: 'rgba(96,132,222,0.3)',
        borderColor: 'rgba(96,132,222,1)',
        pointBackgroundColor: 'rgba(96,132,222,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(96,132,222,0.8)',
        fill: 'origin'
      }
    ]
    // labels: [ 'January', 'February', 'March', 'April', 'May', 'June', 'July' ]
  }

  public subChartOptions: ChartConfiguration['options'] = {
    elements: {
      line: {
        tension: 0.5
      }
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      x: {},
      'y-axis-1': {
        position: 'right',
        grid: {
          color: 'rgba(96,132,222,0.3)'
        },
        ticks: {
          color: 'rgba(96,132,222,1)'
        }
      }
    }
  }
}
