import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { BuilderService } from '../builder-services/builder.service';
import { LoginService } from '../login/login.service';
import { restrictedPagesGuard } from './restricted-pages.guard';

@Injectable({
  providedIn: 'root'
})
export class ExpirationGuard implements CanActivate {

  constructor(private loginService: LoginService, 
    private router: Router,
    private route: ActivatedRoute,
    private restrictedPagesGuard: restrictedPagesGuard,
    private builderService: BuilderService) { }


  canActivate(route: any, state: RouterStateSnapshot) {
    this.builderService.showUpdateProgress = false;
    if(this.builderService.projectAccess == 'free' && this.builderService.trialEnded && !this.loginService.getCurrentUser().admin ) {
      // this.router.navigate(['/expired'], { relativeTo: this.route });
      this.restrictedPagesGuard.howItWorksDialog()
      return false;
    } else {
      return true;
    }
    // this.builderService.getProject(this.builderService.selectedProject).subscribe((project: any) => {
    //   // Cache the response
    //   this.builderService.cacheProjectReq.response = project;
    //   this.builderService.user_type = project.user_type;
    //   if( project.details[0].status !== 'active' && project.details[0].status !== 'stopped' ) {
    //     this.router.navigate(['/expired'], { relativeTo: this.route });
    //     return false;
    //   } else {
    //     return true;
    //   }
    // });
    // return true;
}
  
}
