import { Pipe, PipeTransform } from '@angular/core'
import { BuilderService } from 'src/app/builder-services/builder.service'

@Pipe({
  name: 'extractGoogleTranslation'
})
export class ExtractGoogleTranslationPipe implements PipeTransform {
  constructor(private builderService: BuilderService) {}

  transform(response: any): string {
    let targetLanguage: any = localStorage.getItem('siteLang') ? localStorage.getItem('siteLang') : 'en'

    if (targetLanguage === 'en') {
      return response // Return the original text as an Observable using 'of' operator from 'rxjs'
    }

    if (response && response.data && response.data.translations && response.data.translations.length > 0) {
      return response.data.translations[0].translatedText
    }
    return ''
  }
}
