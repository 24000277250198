import { Component, Input, OnInit, ViewChild, ViewContainerRef } from '@angular/core'
import { BlockFieldsService } from 'src/app/block-services/block-fields.service'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { BlockDataService } from '../../block-services/block-data.service'
import { MixpanelService } from 'src/app/tracking/mixpanel.service'

@Component({
  selector: 'block-text',
  templateUrl: './block-text.component.html',
  styleUrls: ['./block-text.component.scss']
})
export class BlockTextComponent implements OnInit {
  previewBlock = false
  styleFields = false
  blockData: any
  previewFields = false

  blockText = ''
  blockFields: any

  blockId = ''
  marginTop = 0

  fontFamilies = [
    {
      id: 'lato',
      name: 'Lato'
    },
    {
      id: 'poppins',
      name: 'Poppins'
    },
    {
      id: 'default',
      name: 'Default'
    },
    {
      id: '',
      name: 'Global Font'
    }
  ]

  @ViewChild('textPreviewElement', { read: ViewContainerRef })
  textPreviewElement!: ViewContainerRef

  @Input() nestedBlock: any = {}
  @Input() importedBlockData: any

  constructor(
    private blockDataService: BlockDataService,
    private blockFieldsService: BlockFieldsService,
    public builderService: BuilderService,
    private mixPanel: MixpanelService
  ) {}

  ngOnInit() {
    if (this.importedBlockData) {
      this.previewBlock = true
      this.previewFields = false
      this.styleFields = false
    }
    if (this.nestedBlock.type == 'text') {
      this.blockData = this.nestedBlock
      this.blockId = this.nestedBlock.id
      this.previewBlock = true
    } else {
      this.blockData = this.importedBlockData ? this.importedBlockData : this.blockFieldsService.getTextBlockFields()
      if (!this.blockId) {
        this.blockId = this.blockDataService.getUniqueId(2)
      }
    }
    this.blockText = this.blockData.data.value
  }

  /**
   * Appends Block to the Preview, Initial checks if the block is added as initial block addition or appended live
   */
  addBlockToPreview(initial: any = '') {
    let blockTextData = {
      component: BlockTextComponent,
      index: 'latest',
      element: this,
      initial: initial
    }
    this.blockDataService.sendBlockData(blockTextData)
    this.builderService.blocksCollapsed = true
  }
  /**
   *  Appends Block and Appends the Object to the JSON Object
   */
  appendBlock() {
    if (this.builderService.getTour()) {
      this.builderService.onboardingBlockAdded = true
    }
    this.blockId = this.blockDataService.appendBlocksData({ ...this.blockData })
    this.addBlockToPreview()
    this.builderService.logData('Text Block Added', 'Block Add', 'Text Block Added').subscribe(
      (response) => {},
      (err) => {}
    )
    this.mixPanel.track('Block Added', {
      projectid: this.builderService.getSelectedProject(),
      block: 'text'
    })
  }

  /**
   * Sync the clicked block on click inside the mobile preview
   */
  showData(blockId: any) {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      // show data of the last element instead
      blockId = this.blockDataService.getLatestBlockId()
    }
    this.blockFieldsService.clickedBlockId = blockId
    this.blockDataService.sendCurrentBlock(BlockTextComponent)
  }

  /**
   * get all the text block fields from the service and adjust the src only
   * @param $event change event on the input field
   */
  textChange($event: any, blockId: any) {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return
    }

    let textBlockFields = this.blockDataService.getSelectedBlockData(blockId)
    let tinyMCEValue = $event.event.currentTarget.body.innerHTML

    /**
     * Modifying Nested Object Directly doesn't work, so we have to clone the data object first then modify it
     */
    let clonedBlockFieldsData = { ...textBlockFields.data }
    clonedBlockFieldsData.value = tinyMCEValue
    textBlockFields.data = clonedBlockFieldsData
  }

  styleChange(style: any, blockId: any, $event: any, type: string, stylePosition = 'style') {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return
    }
    let textBlockFields = this.blockDataService.getSelectedBlockData(blockId)
    /**
     * Modifying Nested Object Directly doesn't work, so we have to clone the data object first then modify it
     */
    let data = ''
    if (type == 'select') {
      data = $event.value
    } else if (type == 'text') {
      data = $event.currentTarget.value
    } else if (type == 'slider') {
      data = $event.currentTarget.value + 'px'
    }

    if (stylePosition == 'wrapperStyle') {
      let clonedBlockFieldsData = { ...textBlockFields.wrapperStyle }
      clonedBlockFieldsData[style] = data
      textBlockFields.wrapperStyle = clonedBlockFieldsData
    } else if (stylePosition == 'textClass') {
      let clonedBlockFieldsData = { ...textBlockFields.textClass }
      clonedBlockFieldsData[style] = data
      textBlockFields.textClass = clonedBlockFieldsData
    } else {
      let clonedBlockFieldsData = { ...textBlockFields.style }
      clonedBlockFieldsData[style] = data
      textBlockFields.style = clonedBlockFieldsData
    }
  }

  getBlockFields = (id: string) => {
    if (this.importedBlockData) {
      return this.importedBlockData
    }
    return this.blockDataService.getSelectedBlockData(id)
  }

  showStyleTab = () => {
    this.previewBlock = false
    this.previewFields = false
    this.styleFields = true
  }

  showDataTab = () => {
    this.previewBlock = false
    this.previewFields = true
    this.styleFields = false
  }

  checkSidebar = () => {
    if (!this.previewBlock && !this.previewFields && !this.styleFields) {
      return true
    }
    return false
  }

  checkPreview = () => {
    if (this.previewBlock && !this.previewFields && !this.styleFields) {
      return true
    }
    return false
  }

  checkData = () => {
    if (this.previewFields) {
      return true
    }
    return false
  }

  checkStyle = () => {
    if (this.styleFields && !this.previewFields && !this.previewBlock) {
      return true
    }
    return false
  }

  checkSettings = () => {
    if (!this.checkSidebar() && !this.checkPreview()) {
      return true
    }
    return false
  }

  /**
   * Deletes the Block from the mobile Preview
   * Deletes the Block from the JSON Object
   */
  deleteBlock = (blockId: any) => {
    this.blockDataService.removeBlockData(blockId)
  }
}
