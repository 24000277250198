import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatTableDataSource } from '@angular/material/table'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { LoginService } from 'src/app/login/login.service'
import { AppsUploadedService } from '../apps-uploaded/apps-uploaded.service'
import { MatSort } from '@angular/material/sort'

export interface PeriodicElement {
  project_id: string
  visitors: string
  package_name: string
}

@Component({
  selector: 'all-apps',
  templateUrl: './all-apps.component.html',
  styleUrls: ['./all-apps.component.scss']
})
export class AllAppsComponent implements OnInit, AfterViewInit {
  @ViewChild(MatSort, { static: true })
  matSort!: MatSort

  private user_email: string
  public projects_length = 0
  public starting_point: any = 0
  public step = 100
  public chunk = 100

  public active_apps: any = []
  displayedColumns: string[] = ['project_id', 'package_name', 'visitors']
  ELEMENT_DATA: PeriodicElement[] = []
  dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA)
  versionFormControl = new FormControl()
  constructor(
    public builderService: BuilderService,
    private loginService: LoginService,
    private appsUploadedService: AppsUploadedService
  ) {
    this.user_email = loginService.getCurrentUser().email
  }

  ngOnInit(): void {}

  ngAfterViewInit() {
    this.getAppStatuses()
    this.dataSource.sort = this.matSort
  }

  getAppStatuses = () => {
    this.builderService.showUpdateProgress = true
    let newDataArray: PeriodicElement[] = []

    this.appsUploadedService.getAllApps().subscribe((response: any) => {
      for (const key in response) {
        if (response.hasOwnProperty(key)) {
          const element = response[key]
          if (element) {
            newDataArray.push({ project_id: key, visitors: element.visitors, package_name: element.package_name })
          }
        }
      }
      const newData: PeriodicElement[] = newDataArray
      this.ELEMENT_DATA = newData
      this.dataSource.data = newData
      this.builderService.showUpdateProgress = false
    })
  }
}
