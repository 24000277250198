import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { DatabaseService } from '../builder-services/database.service'

@Injectable({
  providedIn: 'root'
})
export class PostsService {
  constructor(
    private builderService: BuilderService,
    private db: DatabaseService
  ) {}

  submitPost = (post: any, post_id: string = '') => {
    let project_id = this.builderService.selectedProject
    post = JSON.parse(JSON.stringify(post))
    return new Observable<any>((observer) => {
      if (!post_id) {
        this.db.setDatabase('projects/' + project_id + '/posts/', post, true).subscribe(
          (response) => {
            observer.next(response) // Notify the observer that the operation is complete
            observer.complete() // Complete the Observable
          },
          (error) => {
            observer.error(error) // Notify the observer of an error
          }
        )
      } else {
        this.db.setDatabase('projects/' + project_id + '/posts/' + post_id, post).subscribe(
          (response) => {
            observer.next(response) // Notify the observer that the operation is complete
            observer.complete() // Complete the Observable
          },
          (error) => {
            observer.error(error) // Notify the observer of an error
          }
        )
      }
    })
  }

  getPosts = () => {
    let project_id = this.builderService.selectedProject
    return new Observable<any>((observer) => {
      this.db.getDatabase('projects/' + project_id + '/posts/').subscribe(
        (response) => {
          if (!response) {
            console.log('No data available')
            observer.next({})
            observer.complete() // Complete the Observable
          } else {
            observer.next(response) // Notify the observer that the operation is complete
            observer.complete() // Complete the Observable
          }
        },
        (error) => {
          observer.error(error) // Notify the observer of an error
        }
      )
    })
  }
  removePost = (post_id: string) => {
    let project_id = this.builderService.selectedProject
    this.db.removeDatabaseEndpoint('projects/' + project_id + '/posts/' + post_id).subscribe((response) => {
      console.log('removed successfully')
    })
  }

  getPost = (post_id: string) => {
    let project_id = this.builderService.selectedProject
    return new Observable<any>((observer) => {
      this.db.getDatabase('projects/' + project_id + '/posts/' + post_id).subscribe(
        (response) => {
          if (response) {
            observer.next(response) // Notify the observer that the operation is complete
            observer.complete() // Complete the Observable
          } else {
            console.log('No data available')
            observer.next({})
            observer.complete() // Complete the Observable
          }
        },
        (error) => {
          observer.error(error) // Notify the observer of an error
        }
      )
    })
  }
}
