import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { BlockDataService } from '../block-services/block-data.service'
import { HelpersService } from '../helpers.service'
import { BuilderService } from '../builder-services/builder.service'

@Injectable({
  providedIn: 'root'
})
export class ConnectivityService extends BlockDataService {
  builderUrl: string
  pluginVersion: number = 6

  constructor(
    private http: HttpClient,
    private helper: HelpersService,
    public builderService: BuilderService
  ) {
    super()
    this.token = this.getToken()
    this.builderUrl = this.helper.getBuilderUrl()
  }

  options = {
    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  }
  token: any

  /**
   * Validates that the plugin is active on the customer website
   * @param customerURL the url of the customer website
   * @returns http get response of the token
   */
  checkPluginActivity = (customerURL: string) => {
    let pluginCustomerURL = customerURL + '/wp-json/avaris-wc-rest/v1/'
    return this.http.get(pluginCustomerURL + 'token')
  }

  /**
   * Checks the plugin version
   * @param customerURL the url of the customer website
   * @returns http get response
   */
  checkPluginVersion = (customerURL: string) => {
    let pluginCustomerURL = customerURL + '/wp-json/v4/'
    return this.http.get(pluginCustomerURL + 'get-stacks-version')
  }

  /**
   * Change website domain
   */
  changeWebsiteDomain = (projectId: any, domain: string) => {
    this.token = this.getToken()
    let postData = new URLSearchParams()
    postData.set('process', 'sync_ui')
    postData.set('projectId', projectId)
    postData.set('token', this.token)
    postData.set('domain', domain)
    postData.set('token', this.token)

    return this.http.post(this.builderUrl + 'updateDomain', postData.toString(), this.options)
  }

  getToken = () => {
    return localStorage.getItem('token')
  }

  connectivityChecker = () => {
    let projectId = this.builderService.selectedProject
    let formattedValidation: any = []
    let latestVersion = '5.0'
    this.getProjectCached().subscribe((project: any) => {
      // Cache the response
      this.builderService.cacheProjectReq.response = project
      let siteURL = project.details[0].domain
      let projectDomain = project.details[0].domain
      this.checkPluginActivity(siteURL).subscribe(
        (pluginResponse: any) => {
          if (pluginResponse.success) {
            /**
             * Get the plugin version
             */
            this.checkPluginVersion(siteURL).subscribe(
              (response: any) => {
                formattedValidation = [
                  {
                    field: 'Status',
                    value: 'Connected'
                  },
                  {
                    field: 'Plugin Version',
                    value: response.version
                  },
                  {
                    field: 'Latest Version',
                    value: latestVersion
                  }
                ]
                this.pluginVersion = parseFloat(response.version)
                if (this.pluginVersion >= 6) {
                  this.builderService.defaultViewsChecker().subscribe(
                    (response) => {
                      console.log(response)
                    },
                    (err) => {
                      console.log(err)
                    }
                  )
                }
              },
              (err) => {
                //Can't Connect to server, plugin Version
                this.builderService.showUpdateProgress = false
                formattedValidation = [
                  {
                    field: 'Status',
                    value: 'Connected'
                  },
                  {
                    field: 'Plugin Version',
                    value: '< ' + latestVersion
                  },
                  {
                    field: 'Latest Version',
                    value: latestVersion
                  }
                ]
                this.pluginVersion = 6
              }
            )
          } else {
            formattedValidation = [
              {
                field: 'Status',
                value: 'Not Connected'
              },
              {
                field: 'Plugin Version',
                value: 'Please install Plugin'
              },
              {
                field: 'Latest Version',
                value: latestVersion
              }
            ]
            this.pluginVersion = 0
          }
        },
        (err) => {
          formattedValidation = [
            {
              field: 'Status',
              value: 'Not Connected'
            },
            {
              field: 'Plugin Version',
              value: 'Please install Plugin'
            },
            {
              field: 'Latest Version',
              value: latestVersion
            }
          ]
          this.pluginVersion = 0
        }
      )
    })
  }
}
