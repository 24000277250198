import { BuilderService } from 'src/app/builder-services/builder.service'
import { CategoriesService } from 'src/app/e-commerce/categories/categories.service'
import { Component, OnInit, ViewChild } from '@angular/core'
import { DatabaseService } from 'src/app/builder-services/database.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatPaginator } from '@angular/material/paginator'
import { MatTableDataSource } from '@angular/material/table'
import { ProductsService } from 'src/app/e-commerce/products/products.service'
import { ActivatedRoute, Router } from '@angular/router'
export interface Product {
  imageUrl?: string
  productName: string
  productSKU: string
  productStock: number
  salePrice?: string
  regularPrice: string
  date?: string
  checked?: boolean
}
@Component({
  selector: 'app-points',
  templateUrl: './points.component.html',
  styleUrls: ['./points.component.scss']
})
export class PointsComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator
  categories: any[] = []
  dataSource = new MatTableDataSource<any>([])
  searchText: string = ''
  displayedColumns: string[] = ['image', 'productName', 'productSKU', 'productStock', 'price', 'date', 'points']
  points!: FormGroup
  products: any[] = []
  selectedCategories: any[] = []
  selectedProducts: MatTableDataSource<any> = new MatTableDataSource<any>()
  selectedProductSet = new Set<any>()
  isEditable = false
  currentSetupId: string | null = null // store the ID from the route (if any)

  constructor(private route: ActivatedRoute, private router: Router, private builderService: BuilderService, private categoriesService: CategoriesService, private db: DatabaseService, private fb: FormBuilder, private productService: ProductsService) {}

  /*
  The `checkAll` function handles the selection of all products based on the checkbox state.

  1. If the checkbox is checked:
     - Iterates through all products and sets their `checked` property to `true`.
     - Updates the `selectedProductSet` to include all products in the `dataSource`.
  
  2. If the checkbox is unchecked:
     - Iterates through all products and sets their `checked` property to `false`.
     - Clears the `selectedProductSet`.

  3. Updates the `selectedProducts` data array with the current selection from the `selectedProductSet`.
*/

  checkAll(event: any): void {
    if (event.checked) {
      this.dataSource.data.forEach((product) => (product.checked = true))
      this.selectedProductSet = new Set(this.dataSource.data)
    } else {
      this.dataSource.data.forEach((product) => (product.checked = false))
      this.selectedProductSet.clear()
    }
    this.selectedProducts.data = Array.from(this.selectedProductSet)
  }

  ngOnInit(): void {
    this.builderService.headerBtn = 'points'
    this.currentSetupId = this.route.snapshot.paramMap.get('id')

    if (this.currentSetupId && this.currentSetupId !== ':id') {
      // We are editing an existing Points setup
      this.loadExistingPointsSetup(this.currentSetupId)
    } else {
      this.getData()
      // We are creating a NEW Points setup
      // Possibly load default form data, or leave everything blank
    }
    this.createForm()
    this.loadData()
    setTimeout(() => {
      this.dataSource.paginator = this.paginator
    }, 3000)
  }
  /**
   * Loads existing points setup data from the database and updates the form.
   * @param setupId - The ID of the points setup to retrieve.
   */
  private loadExistingPointsSetup(setupId: string): void {
    const setupPath = `/projects/${this.builderService.selectedProject}/settings/points/${setupId}`
    this.db.getDatabase(setupPath).subscribe(
      (data: any) => {
        if (data) {
          // Patch the form with existing data
          this.updateFormFields(data)
        }
      },
      (error) => {
        console.error('Error fetching setup data', error)
      }
    )
  }

  /*
  The `onItemChecked` function manages the selection of items based on their type (product or category) and checkbox state.

  1. Updates the `checked` property of the item based on the checkbox state (`event.checked`).

  2. For items of type `product`:
     - If checked, adds the item to the `selectedProductSet`.
     - If unchecked, removes the item from the `selectedProductSet`.
     - Updates the `selectedProducts` data array with the current selection from the `selectedProductSet`.

  3. For items of type `category`:
     - If checked, adds the item to the `selectedCategories` array.
     - If unchecked, removes the item from the `selectedCategories` array based on `categoryId`.
     - Logs the current list of selected categories to the console.
*/

  onItemChecked(item: any, event: any, type: string): void {
    item.checked = event.checked

    if (type === 'product') {
      if (event.checked) {
        this.selectedProductSet.add(item)
      } else {
        this.selectedProductSet.delete(item)
      }
      this.selectedProducts.data = Array.from(this.selectedProductSet)
    } else if (type === 'category') {
      if (event.checked) {
        this.selectedCategories.push(item)
      } else {
        this.selectedCategories = this.selectedCategories.filter((c) => c.categoryId !== item.categoryId)
      }
      console.log('Selected Categories:', this.selectedCategories)
    }
  }

  /*
  The `isChecked` function determines whether an item is checked based on its type (product or category).

  1. Returns `true` if the item is checked; otherwise, returns `false`.
  2. Defaults to `false` if the `item` is undefined or null.

  Note: This function currently does not differentiate between types (`product` or `category`) as it only checks the `checked` property.
*/

  isChecked(item: any, type: 'product' | 'category'): boolean {
    return item?.checked || false
  }

  /**
   * The `loadData()` function retrieves and processes data for products and categories.
   *
   * 1. Fetches the base list of products from `productService`:
   *    - Sets each product's default `checked` to false and `points` to 0.
   *    - If we're editing an existing setup (this.currentSetupId), also fetch the product's
   *      data from /products/<productId>/pointsList/<setupId> to override the default
   *      checked/points values.
   *    - Updates `selectedProductSet` and `selectedProducts.data` accordingly.
   *
   * 2. Fetches the base list of categories from `categoriesService`:
   *    - Sets each category's default `checked` to false and `points` to 0.
   *    - If editing an existing setup, also load the category's
   *      /categories/<categoryId>/pointsList/<setupId> data.
   *    - Updates `selectedCategories` with any categories that end up checked.
   *
   * 3. Handles errors for both product and category fetch operations by logging them to the console.
   */
  loadData(): void {
    // ------------------------------------------------------------------
    // 1. Fetch Products
    // ------------------------------------------------------------------
    this.productService.getProducts().subscribe(
      (products: Product[]) => {
        // Initialize products with default checked=false, points=0
        this.dataSource.data = products.map((p: Product) => ({
          ...p,
          checked: false,
          points: 0
        }))

        // Clear any previously selected products
        this.selectedProductSet.clear()
        this.selectedProducts.data = []

        // If we're editing an existing setup, load the product's
        // points data from /pointsList/<setupId>.
        if (this.currentSetupId && this.currentSetupId !== ':id') {
          this.dataSource.data.forEach((product: any) => {
            const productPointsPath = `/projects/${this.builderService.selectedProject}` + `/products/${product.Id}/pointsList/${this.currentSetupId}`

            this.db.getDatabase(productPointsPath).subscribe((productPoints: any) => {
              if (productPoints) {
                // Override the default false/0 with stored values
                product.checked = !!productPoints.checked
                product.points = productPoints.points || 0

                // If this product is checked, add it to the selected set
                if (product.checked) {
                  this.selectedProductSet.add(product)
                }
              }
              // Update the data source for selected products
              this.selectedProducts.data = Array.from(this.selectedProductSet)
            })
          })
        }
      },
      (error) => {
        console.error('Error fetching products:', error)
      }
    )

    // ------------------------------------------------------------------
    // 2. Fetch Categories
    // ------------------------------------------------------------------
    this.categoriesService.getCategories().subscribe(
      (data) => {
        // Transform the raw object data into an array
        this.categories = Object.keys(data).map((key) => ({
          ...data[key],
          categoryId: key,
          checked: false,
          points: 0
        }))

        // If we're editing an existing setup, load each category's
        // points data from /categories/<categoryId>/pointsList/<setupId>.
        if (this.currentSetupId && this.currentSetupId !== ':id') {
          this.categories.forEach((cat) => {
            const catPointsPath = `/projects/${this.builderService.selectedProject}` + `/categories/${cat.categoryId}/pointsList/${this.currentSetupId}`

            this.db.getDatabase(catPointsPath).subscribe((catPoints: any) => {
              if (catPoints) {
                cat.checked = !!catPoints.checked
                cat.points = catPoints.points || 0
              }
              this.selectedCategories = this.categories.filter((cat) => cat.checked)
            })
          })
        }

        // If you maintain a "selectedCategories" list, update it now
      },
      (error) => {
        console.error('Error fetching categories:', error)
      }
    )
  }

  /*
  The `createForm` function initializes the form structure for handling points and product configurations.

  1. Creates a form group named `points` using `FormBuilder`, with the following controls:
     - `currency_category_point`: A field for points related to currency categories.
     - `currency_product_point`: A field for points related to currency products.
     - `currency_system`: A field for specifying the currency system.
     - `enable_points`: A boolean field to enable or disable points.
     - `min_redeem`: A field for specifying the order system.
     - `order_all_products`: A field for specifying order points for all products, with a minimum value validator.
     - `order_cancelation`: A boolean field for order cancellation.
     - `order_checked_cats_points`: A field for points associated with checked categories.
     - `order_checked_products_points`: A field for points associated with checked products.
     - `order_points`: A field for specifying order points, with a minimum value validator.
     - `order_system`: A field for specifying the order system.
     - `points_system`: A field for specifying the points system.
     - `points`: A field for general points, with a minimum value validator.
     - `product_option`: A field for specifying the product option.
     - `product_points`: A field for product points, with a minimum value validator.
     - `products`: A form array for managing a list of products.
     - `redeem_currency`: A field for specifying the order system.
     - `redeem_points`: A field for specifying the order system.
     - `selectedProduct`: A field for managing the selected product.
     - `units`: A field for specifying units, with a minimum value validator.
     - `validity_date: A field for specifying the validity date of the points or offer.
*/

  createForm(): void {
    this.points = this.fb.group({
      id: [''],
      currency_category_point: ['', Validators.required],
      currency_product_point: ['', Validators.required],
      currency_system: ['', Validators.required],
      enable_points: [false],
      min_redeem: ['', [Validators.required, Validators.min(0)]],
      order_all_products: ['', [Validators.required, Validators.min(0)]],
      order_cancelation: [false],
      order_checked_cats_points: ['', Validators.required],
      order_checked_products_points: ['', Validators.required],
      order_points: ['', [Validators.required, Validators.min(0)]],
      order_system: ['', Validators.required],
      points_system: ['', Validators.required],
      points: ['', [Validators.required, Validators.min(0)]],
      product_option: ['', Validators.required],
      product_points: ['', [Validators.required, Validators.min(0)]],
      products: this.fb.array([]),
      redeem_currency: ['', Validators.required],
      redeem_points: ['', [Validators.required, Validators.min(0)]],
      selectedProduct: ['', Validators.required],
      units: ['', [Validators.required, Validators.min(0)]],
      validity_date: ['', Validators.required]
    })
  }

  /*
  The `getData` function retrieves settings data for points based on the selected project.

  1. Constructs the database path using the `selectedProject` from `builderService` to target the specific settings endpoint.

  2. Fetches data from the database at the constructed path:
     - On successful retrieval, updates the form fields with the fetched data using `updateFormFields`.
     - Logs an error message to the console if the data fetch fails.
*/

  getData(): void {
    const basePath = `/projects/${this.builderService.selectedProject}/settings/points`

    this.db.getDatabase(basePath).subscribe(
      (data) => {
        this.updateFormFields(data)
      },
      (error) => {
        console.log('Error fetching data', error)
      }
    )
  }

  /*
  The `updateFormFields` function updates the form fields with the provided data.

  1. Uses `patchValue` to update the `points` form group with values from the `data` object:
     - Sets each field to the corresponding value from `data`, or defaults to an empty string or false if the value is not provided.

  2. Ensures that all fields in the form group are populated with either the provided data or default values.
*/

  updateFormFields(data: any): void {
    this.points.patchValue({
      currency_category_point: data?.currency_category_point || '',
      currency_product_point: data?.currency_product_point || '',
      currency_system: data?.currency_system || '',
      enable_points: data?.enable_points || false,
      min_redeem: data?.min_redeem || '100',
      order_all_products: data?.order_all_products || '',
      order_cancelation: data?.order_cancelation || false,
      order_checked_cats_points: data?.order_checked_cats_points || '',
      order_checked_products_points: data?.order_checked_products_points || '',
      order_points: data?.order_points || '50',
      order_system: data?.order_system || 'allProductsCategories',
      points_system: data?.points_system || 'byOrder',
      points: data?.points || '',
      product_option: data?.product_option || '',
      product_points: data?.product_points || '',
      redeem_currency: data?.redeem_currency || '10',
      redeem_points: data?.redeem_points || '100',
      selectedProduct: data?.selectedProduct || '',
      units: data?.units || '',
      validity_date: data?.validity_date || '30'
    })
  }

  /*
  The `saveChanges` function updates both category and product data in the database, as well as saving valid points data.

1. **Category Updates**:
   - Constructs the base path for categories using the `selectedProject` from `builderService`.
   - Iterates over each category in the `categories` array:
     - For each category:
       - Builds the path to the current category data using `category.categoryId`.
       - Fetches the current category data from the database.
       - Merges the fetched data with the updated fields:
         - Sets `checked` to the category's `checked` value or `false` if not present.
         - Sets `points` to the category's `points` value or `0` if not present.
       - Saves the updated category data back to the database at the constructed path.
       - Logs an error if fetching the current category data fails.

2. **Product Updates**:
   - Constructs the base path for products using the `selectedProject` from `builderService`.
   - Iterates over each product in `dataSource.data`:
     - Maps each product to include its `Id`, `checked`, and `points`:
       - Sets `checked` to the product's `checked` value or `false` if not present.
       - Sets `points` to the product's `points` value or `0` if not present.
     - Builds the path to the current product data using `product.Id`.
     - Fetches the current product data from the database.
     - Merges the fetched product data with the updated fields:
       - Sets `checked` to the product's `checked` value.
       - Sets `points` to the product's `points` value.
     - Saves the updated product data back to the database at the constructed path.
     - Logs an error if fetching the current product data fails.

3. **Points Form Validation and Save**:
   - Iterates over each control in the `points` form group and marks them as touched.
   - Filters out valid form controls, collecting their values into `validPointsData`.
   - If there is any valid points data:
     - Saves it to the database at the `pointsPath`.
   - If no valid points data exists, logs a warning: "No valid points data to save."
*/

  public saveChanges(): void {
    // 1) Validate form controls
    this.validateForm()

    // 2) Determine the setup ID (create or edit)
    const usedSetupId = this.determineUsedSetupId()

    // 2.5) Patch the "id" into your form before gathering data
    this.points.patchValue({ id: usedSetupId })

    // 3) Gather valid data
    const validPointsData = this.gatherValidFormData()
    if (!validPointsData) {
      console.warn('No valid points data to save.')
      return
    }

    // 4) Save the main points data
    this.saveMainPointsData(usedSetupId, validPointsData)

    // 5) Update categories
    this.updateSelectedCategories(usedSetupId)

    // 6) Update products
    this.updateSelectedProducts(usedSetupId)

    // 7) Optionally navigate if newly created
    this.navToPoints()
  }

  private validateForm(): void {
    Object.keys(this.points.controls).forEach((key) => {
      const control = this.points.get(key)
      if (control) {
        control.markAsTouched()
      }
    })
  }

  private gatherValidFormData(): { [key: string]: any } | null {
    const validPointsData = Object.keys(this.points.controls).reduce((acc, key) => {
      const control = this.points.get(key)
      if (control && control.valid) {
        acc[key] = control.value
      }
      return acc
    }, {} as { [key: string]: any })

    if (Object.keys(validPointsData).length === 0) {
      return null
    }

    return validPointsData
  }

  private determineUsedSetupId(): string {
    // If we are editing an existing setup, use that ID
    if (this.currentSetupId && this.currentSetupId !== ':id') {
      return this.currentSetupId
    }

    // Otherwise, generate a new unique ID
    return this.generateUniqueId()
  }

  private saveMainPointsData(usedSetupId: string, validPointsData: any): void {
    const pointsBasePath = `/projects/${this.builderService.selectedProject}/settings/points/`
    const setupPath = `${pointsBasePath}/${usedSetupId}`

    this.db.setDatabase(setupPath, validPointsData).subscribe(
      () => {},
      (error) => {
        console.error('Error saving main points data:', error)
      }
    )
  }

  private updateSelectedCategories(usedSetupId: string): void {
    const categoriesPath = `/projects/${this.builderService.selectedProject}/categories/`

    // Instead of this.allCategories, just use this.categories
    this.categories.forEach((cat: any) => {
      const categoryPointsListPath = `${categoriesPath}${cat.categoryId}/pointsList`

      this.db.getDatabase(categoryPointsListPath).subscribe(
        (existingPointsList: any) => {
          const updatedPointsList = existingPointsList || {}

          const isSelected = this.selectedCategories.some((selectedCat) => selectedCat.categoryId === cat.categoryId)

          updatedPointsList[usedSetupId] = {
            checked: isSelected,
            points: cat.points || 0
          }

          this.db.setDatabase(categoryPointsListPath, updatedPointsList).subscribe(
            () => {
              console.log(`Updated category ${cat.categoryId} with pointsList[${usedSetupId}]`, updatedPointsList[usedSetupId])
            },
            (error) => {
              console.error(`Error saving category ${cat.categoryId}`, error)
            }
          )
        },
        (error) => {
          console.error(`Error fetching pointsList for category ${cat.categoryId}`, error)
        }
      )
    })
  }

  private updateSelectedProducts(usedSetupId: string): void {
    const productsPath = `/projects/${this.builderService.selectedProject}/products/`

    // The products you loaded are in this.dataSource.data (or you can keep them in a separate this.products array)
    ;(this.dataSource.data as any[]).forEach((product) => {
      const productPointsListPath = `${productsPath}${product.Id}/pointsList`

      this.db.getDatabase(productPointsListPath).subscribe(
        (existingPointsList: any) => {
          const updatedPointsList = existingPointsList || {}

          // Check if product is in the selected set
          const isSelected = this.selectedProductSet.has(product)

          updatedPointsList[usedSetupId] = {
            checked: isSelected,
            points: product.points || 0
          }

          this.db.setDatabase(productPointsListPath, updatedPointsList).subscribe(
            () => {
              console.log(`Updated product ${product.Id} with pointsList[${usedSetupId}]`, updatedPointsList[usedSetupId])
            },
            (error: any) => {
              console.error(`Error saving product ${product.Id}`, error)
            }
          )
        },
        (error: any) => {
          console.error(`Error fetching pointsList for product ${product.Id}`, error)
        }
      )
    })
  }

  private navToPoints(): void {
    // If we just created a new setup (no existing ID), navigate away
    if (!this.currentSetupId || this.currentSetupId === ':id') {
      this.router.navigate(['/points'])
    }
  }

  generateUniqueId = () => {
    const timestamp = new Date().getTime().toString()
    const random = Math.floor(Math.random() * 10000).toString()
    return timestamp + '-' + random
  }

  applyFilter() {
    this.dataSource.filter = this.searchText.trim().toLowerCase()
  }

  editPoductPoints(element: any) {
    element.editing = true
  }

  stopEdit(element: any) {
    element.editing = false
  }
}
