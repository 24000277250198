<div class="containerWrapper" *ngIf="builderService.standalone">
  <div class="standaloneWrapper" *ngIf="builderService.standalone">
    <img src="assets/images/Analytics_placeholder.png" alt="" />
    <h1>{{ 'Analytics arent available' | translate }}</h1>
    <p>{{ 'Please stay tuned' | translate }}</p>
    <div class="buttonWrapper">
      <button [routerLink]="['/connectivity']">{{ 'Connect to Wordpress' | translate }}</button>
    </div>
  </div>
</div>

<div *ngIf="!builderService.standalone">
  <div class="secondarySidebar">
    <div class="secondarySBTitle">
      <h2>{{ 'Analytics' | translate }}</h2>
    </div>
    <div class="secondarySBContent">
      <ul>
        <li class="selected" [routerLink]="['/analytics/page-stats']">{{ 'Page Stats' | translate }}</li>
        <li [routerLink]="['/analytics/orders']">{{ 'Orders' | translate }}</li>
        <!-- <li [routerLink]="['/admin/logs']">Billing</li> -->
      </ul>
    </div>
  </div>
  <div id="selfWrapper" class="containerWrapper withSidebar noBG">
    <!-- <mat-progress-bar mode="indeterminate" *ngIf="PNProgress"></mat-progress-bar> -->
    <div id="titleContainer" class="innerContainerTitle">
      <h1 class="desktopOnly">{{ 'Page Stats' | translate }}</h1>
    </div>
    <div id="selfContainer" class="innerContainerContent">
      <div class="gridWrapper">
        <div class="fullGrid">
          <mat-toolbar>
            <span>{{ 'Mobile App Pages' | translate }}</span>
          </mat-toolbar>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'Filter' | translate }}</mat-label>
            <input matInput (keyup)="applySubFilter($event)" placeholder="Ex. Home" #input />
          </mat-form-field>

          <form [formGroup]="form" class="dateModule" (submit)="applyDateFilter()">
            <mat-form-field>
              <input matInput placeholder="Date From" [matDatepicker]="picker1" formControlName="fromDate" required (click)="picker1.open()" />
              <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
              <mat-datepicker #picker1></mat-datepicker>
            </mat-form-field>
            <mat-form-field>
              <input matInput placeholder="toDate" [matDatepicker]="picker2" formControlName="toDate" required (click)="picker2.open()" />
              <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
              <mat-datepicker #picker2></mat-datepicker>
            </mat-form-field>
            <button class="dateActionBtn stacksBtn blue" mat-raised-button (click)="applyDateFilter()" [disabled]="form.invalid">Submit</button>
            <button mat-raised-button class="dateActionBtn stacksBtn green" type="button" [disabled]="form.invalid" (click)="clearDateFilter()">Clear</button>
          </form>
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8 fullWidthTable" matSort matSortActive="date" matSortDirection="desc">
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'Date' | translate }}</th>
              <td mat-cell *matCellDef="let elements">{{ elements.date }}</td>
            </ng-container>

            <ng-container matColumnDef="email">
              <th mat-header-cell *matHeaderCellDef>{{ 'E-Mail' | translate }}</th>
              <td mat-cell *matCellDef="let elements">{{ elements.email }}</td>
            </ng-container>

            <ng-container matColumnDef="user_id">
              <th mat-header-cell *matHeaderCellDef mat-sort-header>{{ 'UserId' | translate }}</th>
              <td mat-cell *matCellDef="let elements">{{ elements.user_id }}</td>
            </ng-container>

            <ng-container matColumnDef="page_visited">
              <th mat-header-cell *matHeaderCellDef>{{ 'Page Visited' | translate }}</th>
              <td mat-cell *matCellDef="let elements">{{ elements.page_visited }}</td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="pagesColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: pagesColumns"></tr>
          </table>
          <mat-paginator [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons aria-label="Select page of periodic dataSource"> </mat-paginator>
          <div class="fullGrid">
            <div class="widgetChart">
              <canvas baseChart [data]="subChartData" [options]="subChartOptions" type="bar"></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
