<div class="containerWrapper">
  <h1>Plugins</h1>
  <div class="pluginWrapper">
    <div class="plugin" *ngFor="let plugin of plugins; let i = index">
      <h1>{{ plugin.name }}</h1>
      <p>{{ plugin.description }}</p>
      <mat-slide-toggle (change)="onToggleChange(i)" [(ngModel)]="plugin.isChecked">
        <span *ngIf="!plugin.isChecked">Install</span>
        <span *ngIf="plugin.isChecked">Uninstall</span>
      </mat-slide-toggle>
    </div>
  </div>
</div>
