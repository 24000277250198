<form [formGroup]="androidBuildForm" id="androidBuildWrapper" class="containerWrapper buildWrapper">
  <div id="titleContainer">
    <h1>{{ 'Android Builder' | translate }}</h1>
    <div class="howItWorks noselect" (click)="howItWorksDialog()">
      <span class="material-icons-outlined">play_circle_filled</span>
      {{ 'How it works' | translate }}
    </div>
  </div>
  <div class="actionBtns containerSecondLine">
    <div class="buildSettingcontainer">
      <div class="fileContainer">
        <label class="fileUpload" for="encryption_filepath"
          >{{ 'Choose encryption file' | translate }}
          <input type="file" formControlName="encryption_filepath" id="encryption_filepath" (change)="processFileUpload($event, 'application/x-x509-ca-cert', 'encryption_filepath')" accept="application/x-x509-ca-cert" />
        </label>
        <div class="remove">
          <p *ngIf="uploadedFileName">
            File Uploaded: <br />
            <strong>{{ uploadedFileName }}</strong>
          </p>
          <button *ngIf="uploadedFileName" class="remove" (click)="clearEncryptionData()">X</button>
        </div>
      </div>
      <!-- <p class="lGuideText">*this must be a guide text</p> -->
      <div class="fileContainer">
        <button class="stacksBtn blue" type="button" mat-icon-button (click)="generateReleaseKeys()">Generate Release Keys</button>
      </div>
    </div>
    <!-- <p class="rGuideText">*this must be a guide text</p> -->
  </div>
  <div class="containerSecondLine" *ngIf="(!buildStart || value == 100 || error) && !checkingStatus">
    <div class="buildSettingcontainer">
      <!--android version -->
      <div>
        <h2>{{ 'Android Version' | translate }}</h2>
        <div class="androidVersionWrapper">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'Android Version' | translate }}</mat-label>
            <input matInput placeholder="1.0" type="number" min="0.1" step="0.1" max="9999" formControlName="androidVersion" />
          </mat-form-field>
        </div>
        <p>{{ 'version of your Application' | translate }}</p>
      </div>

      <!--android codeversion -->
      <div>
        <h2>{{ 'Android Version Code' | translate }}</h2>
        <div class="androidVersionWrapper">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'Android Version Code' | translate }}</mat-label>
            <input matInput placeholder="1" type="number" min="1" step="1" max="9999" formControlName="androidVersionCode" (input)="onInput($event)" />
          </mat-form-field>
          <button class="stacksBtn lightBtn red" (click)="generateAAB()" [disabled]="androidBuildForm.invalid">{{ 'Generate App' | translate }}</button>
        </div>
        <p>{{ 'version of your Application Code' | translate }}</p>
      </div>
    </div>
    <div *ngIf="warningMessage">
      <p class="warning">{{ warningMessage }}</p>
    </div>
  </div>
  <div *ngIf="buildStart && !checkingStatus && !error" class="buildStart">
    <h1 *ngIf="value < 100">{{ 'Generating Android' | translate }}</h1>
    <h1 *ngIf="value == 100">{{ 'Android Application Generated' | translate }}</h1>

    {{ value }}%
    <mat-progress-bar mode="determinate" class="buildingBar" [value]="value"></mat-progress-bar>
    <div *ngIf="value == 100 && ((!this.builderService.standalone && this.builderService.selectedProjectDomain) || this.builderService.standalone)" id="qrWrapper">
      <!-- <h2>{{'Open your Mobile camera' | translate }}</h2>       -->
      <div class="actionBtns downloadAbb">
        <a href="{{ downloadAABBtn }}" *ngIf="this.builderService.selectedProjectDomain !== 'https://demo.stacksclients.com' || this.webviewApp" class="downloadBtn">Download .aab File</a>
        <!-- <a *ngIf="playType == 'aab'" class="rebuildApp" (click)="generateAAB()">{{'Generate' | translate }}</a> -->
      </div>
    </div>
  </div>
  <div *ngIf="error" class="buildError">
    <h2 *ngIf="buildErrorMsg && buildErrorMsg !== ''">{{ buildErrorMsg }}</h2>
    <h2 *ngIf="!buildErrorMsg && buildErrorMsg !== ''">
      {{ 'Error occured while building' | translate }}<br />
      {{ 'please try again' | translate }} <br />{{ 'If the error' | translate }}
    </h2>
    <div class="actionBtns">
      <a class="rebuildApp" (click)="rebuildApp()">{{ 'Rebuild App' | translate }}</a>
    </div>
  </div>
  <div *ngIf="checkingStatus && !error" class="checkingStatus">
    <h1>{{ 'Checking Build Status' | translate }}</h1>
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
</form>
