<div class="container">
  <h2>{{ 'Points & Rewards' | translate }}</h2>

  <mat-tab-group animationDuration="0ms">
    <!-- Tab 1: Points System -->
    <mat-tab label="{{ 'Points Setup' | translate }}">
      <div class="pointsContainer productsWrapper" [formGroup]="points">
        <div class="pointPart">
          <h3>{{ 'Enable points & rewards' | translate }}</h3>
          <mat-slide-toggle color="primary" formControlName="enable_points"> *{{ 'points will be added automatically for each purchase' | translate }} </mat-slide-toggle>
        </div>

        <div class="pointPart">
          <h3>{{ 'Default Point Assign' | translate }}</h3>
        </div>

        <div class="optionContainer">
          <span class="small-text">{{ 'Points For' | translate }}</span>
          <mat-form-field appearance="outline">
            <mat-select formControlName="points_system" required>
              <mat-option value="byOrder">{{ 'By Order' | translate }}</mat-option>
              <mat-option value="byUnit">{{ 'Currency' | translate }}</mat-option>
              <mat-option value="byProductCategory">{{ 'Product / Category' | translate }}</mat-option>
            </mat-select>
          </mat-form-field>

          <span *ngIf="points.get('points_system')?.value === 'byOrder' || points.get('points_system')?.value === 'byUnit' || points.get('points_system')?.value === 'byProductCategory'" class="small-text middle">{{ 'Select' | translate }}</span>

          <mat-form-field appearance="outline" *ngIf="points.get('points_system')?.value === 'byOrder'">
            <mat-select formControlName="order_system" required>
              <mat-option value="allProductsCategories">{{ 'All Products / Categories' | translate }}</mat-option>
              <mat-option value="selectedProduct">{{ 'Select Product' | translate }}</mat-option>
              <mat-option value="selectedCategory">{{ 'Select Category' | translate }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" *ngIf="points.get('points_system')?.value === 'byUnit'">
            <mat-select formControlName="currency_system" required>
              <mat-option value="currencyAll">{{ 'All Products / Categories' | translate }}</mat-option>
              <mat-option value="currency_product">{{ 'Select Product' | translate }}</mat-option>
              <mat-option value="currency_category">{{ 'Select Category' | translate }}</mat-option>
            </mat-select>
          </mat-form-field>

          <mat-form-field appearance="outline" *ngIf="points.get('points_system')?.value === 'byProductCategory'">
            <mat-select formControlName="product_option" required>
              <mat-option value="allProductsCategories">{{ 'All Products / Categories' | translate }}</mat-option>
              <mat-option value="choosenProduct">{{ 'Select Product' | translate }}</mat-option>
              <mat-option value="choosenCategory">{{ 'Select Category' | translate }}</mat-option>
            </mat-select>
          </mat-form-field>
        </div>

        <div
          class="tableWrapper"
          *ngIf="
            (points.get('product_option')?.value === 'choosenProduct' && points.get('points_system')?.value === 'byProductCategory') ||
            (points.get('currency_system')?.value === 'currency_product' && points.get('points_system')?.value === 'byUnit') ||
            (points.get('order_system')?.value === 'selectedProduct' && points.get('points_system')?.value === 'byOrder')
          "
        >
          <div class="tableHeader">
            <h1>{{ 'Products' | translate }}</h1>
            <div class="headerBtns">
              <form class="searchBar">
                <input type="text" matInput placeholder="{{ 'Search' | translate }}" id="searchInput" [(ngModel)]="searchText" (ngModelChange)="applyFilter()" name="searchText" data-search />
              </form>
            </div>
          </div>
          <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
            <!-- Image Column -->
            <ng-container matColumnDef="image">
              <th mat-header-cell *matHeaderCellDef class="firstTitle">
                <mat-checkbox (change)="checkAll($event)" class="checkAllProductsCheckbox"></mat-checkbox>
                {{ 'Image' | translate }}
              </th>
              <td mat-cell *matCellDef="let element" class="checkbox-container imageContainer">
                <mat-checkbox (change)="onItemChecked(element, $event, 'product')" [checked]="isChecked(element, 'product')"> </mat-checkbox>
                <div class="productImgContainer">
                  <img *ngIf="element.imageUrl" src="{{ element.imageUrl }}" class="image" />
                  <img *ngIf="!element.imageUrl" src="assets/images/empty-image.png" alt="" />
                </div>
              </td>
            </ng-container>

            <!-- Product Name Column -->
            <ng-container matColumnDef="productName">
              <th mat-header-cell *matHeaderCellDef>
                {{ 'name' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.productName }}
              </td>
            </ng-container>

            <!-- SKU Column -->
            <ng-container matColumnDef="productSKU">
              <th mat-header-cell *matHeaderCellDef>
                <div class="hasIcons">{{ 'sku' | translate }}</div>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.productSKU }}
              </td>
            </ng-container>

            <!-- Stock Column -->
            <ng-container matColumnDef="productStock">
              <th mat-header-cell *matHeaderCellDef mat-sort-header="stock">
                {{ 'Stock' | translate }}
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.productStock >= 0 ? (element.productStock == 0 ? 'Out of Stock' : element.productStock) : 'InStock' }}
              </td>
            </ng-container>

            <!-- Price Column -->
            <ng-container matColumnDef="price">
              <th mat-header-cell *matHeaderCellDef>
                <div class="hasIcons">{{ 'Price/Sale' | translate }}</div>
              </th>
              <td mat-cell *matCellDef="let element">
                {{ element.salePrice ? element.regularPrice + ' / ' + element.salePrice : element.regularPrice }}
              </td>
            </ng-container>

            <!-- Date Column -->
            <ng-container matColumnDef="date">
              <th mat-header-cell *matHeaderCellDef>
                <div class="hasIcons">{{ 'Date' | translate }}</div>
              </th>
              <td mat-cell *matCellDef="let element">{{ element.date }}</td>
            </ng-container>

            <ng-container matColumnDef="points">
              <th class="none" mat-header-cell *matHeaderCellDef>
                {{ 'points' | translate }}
              </th>
              <td class="none" mat-cell *matCellDef="let element"></td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
          </table>
          <mat-paginator class="tablePaginator" [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons aria-label="Select page of periodic dataSource"> </mat-paginator>

          <!-- New Table for Selected Products -->
          <div *ngIf="selectedProducts.data.length > 0">
            <h3 class="middleText">
              {{ 'Selected Products' | translate }}
            </h3>
            <div class="tableWrapper">
              <div class="tableHeader"></div>
              <table [dataSource]="selectedProducts" mat-table class="mat-elevation-z8">
                <!-- Image Column -->
                <ng-container matColumnDef="image">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ 'Image' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element" class="imageContainer">
                    <div class="productImgContainer">
                      <img *ngIf="element.imageUrl" src="{{ element.imageUrl }}" class="image" />
                      <img *ngIf="!element.imageUrl" src="assets/images/empty-image.png" alt="" />
                    </div>
                  </td>
                </ng-container>

                <!-- Product Name Column -->
                <ng-container matColumnDef="productName">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ 'Name' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.productName }}
                  </td>
                </ng-container>

                <!-- SKU Column -->
                <ng-container matColumnDef="productSKU">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ 'SKU' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.productSKU }}
                  </td>
                </ng-container>

                <!-- Stock Column -->
                <ng-container matColumnDef="productStock">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ 'Stock' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.productStock >= 0 ? (element.productStock == 0 ? 'Out of Stock' : element.productStock) : 'InStock' }}
                  </td>
                </ng-container>

                <!-- Price Column -->
                <ng-container matColumnDef="price">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ 'Price/Sale' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.salePrice ? element.regularPrice + ' / ' + element.salePrice : element.regularPrice }}
                  </td>
                </ng-container>

                <!-- Date Column -->
                <ng-container matColumnDef="date">
                  <th mat-header-cell *matHeaderCellDef>
                    {{ 'Date' | translate }}
                  </th>
                  <td mat-cell *matCellDef="let element">{{ element.date }}</td>
                </ng-container>

                <ng-container matColumnDef="points">
                  <th
                    class="none"
                    [ngClass]="{
                      show: points.get('product_option')?.value === 'choosenProduct' && points.get('points_system')?.value === 'byProductCategory'
                    }"
                    mat-header-cell
                    *matHeaderCellDef
                  >
                    {{ 'points' | translate }}
                  </th>
                  <td
                    class="none"
                    [ngClass]="{
                      show: points.get('product_option')?.value === 'choosenProduct' && points.get('points_system')?.value === 'byProductCategory'
                    }"
                    mat-cell
                    *matCellDef="let element"
                  >
                    <ng-container *ngIf="!element.editing; else editPoints">
                      <div class="beforeEdit" (click)="editPoductPoints(element)">
                        <p>{{ element.points }}</p>
                        <span class="material-icons material-icons-outlined editIcon">edit</span>
                      </div>
                    </ng-container>
                    <ng-template #editPoints>
                      <input class="translateTextInput" type="number" [(ngModel)]="element.points" [ngModelOptions]="{ standalone: true }" (blur)="stopEdit(element)" (click)="$event.stopPropagation()" />
                    </ng-template>
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
              </table>
            </div>
          </div>
        </div>

        <div
          class="tableCatWrapper"
          *ngIf="
            (points.get('currency_system')?.value === 'currency_category' && points.get('points_system')?.value === 'byUnit') ||
            (points.get('order_system')?.value === 'selectedCategory' && points.get('points_system')?.value === 'byOrder') ||
            (points.get('product_option')?.value === 'choosenCategory' && points.get('points_system')?.value === 'byProductCategory')
          "
        >
          <div class="tableHeader">
            <h1>{{ 'Categories' | translate }}</h1>
          </div>
          <table class="table table-bordered">
            <tbody>
              <tr *ngFor="let cat of categories">
                <td>
                  <mat-accordion>
                    <mat-expansion-panel disabled [expanded]="false">
                      <mat-expansion-panel-header>
                        <mat-checkbox (change)="onItemChecked(cat, $event, 'category')" [checked]="isChecked(cat, 'category')"> </mat-checkbox>
                        <img src="{{ cat.categoryImage }}" alt="" />
                        <p>{{ cat.categoryName }}</p>
                      </mat-expansion-panel-header>
                    </mat-expansion-panel>
                  </mat-accordion>
                </td>
              </tr>
            </tbody>
          </table>

          <div *ngIf="selectedCategories.length > 0">
            <h3 class="middleText">{{ 'Selected Categories' | translate }}</h3>
            <table class="table table-bordered">
              <tbody>
                <tr *ngFor="let cat of selectedCategories">
                  <td>
                    <mat-accordion>
                      <mat-expansion-panel [disabled]="points.get('product_option')?.value !== 'choosenCategory' || points.get('points_system')?.value !== 'byProductCategory'">
                        <mat-expansion-panel-header>
                          <img [src]="cat.categoryImage || 'assets/images/empty-image.png'" alt="" />
                          <p>{{ cat.categoryName }}</p>
                        </mat-expansion-panel-header>
                        <div class="catDesc">
                          <mat-form-field appearance="outline">
                            <input matInput type="number" [(ngModel)]="cat.points" [ngModelOptions]="{ standalone: true }" placeholder="points" />
                          </mat-form-field>
                        </div>
                      </mat-expansion-panel>
                    </mat-accordion>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div class="pointPart">
          <h3>{{ 'Earned point' | translate }}</h3>
          <div>
            <!-- order points by order -->
            <input
              *ngIf="points.get('points_system')?.value === 'byOrder' && points.get('order_system')?.value === 'allProductsCategories'"
              matInput
              placeholder="0"
              formControlName="order_points"
              type="number"
              [ngClass]="{ 'error-border': points.get('order_points')?.invalid && points.get('order_points')?.touched }"
              required
            />

            <!-- order points by selectedProduct -->
            <input
              *ngIf="points.get('points_system')?.value === 'byOrder' && points.get('order_system')?.value === 'selectedProduct'"
              matInput
              placeholder="0"
              formControlName="order_checked_products_points"
              type="number"
              [ngClass]="{ 'error-border': points.get('order_checked_products_points')?.invalid && points.get('order_checked_products_points')?.touched }"
              required
            />

            <!-- order points by selectedCategory -->
            <input
              *ngIf="points.get('points_system')?.value === 'byOrder' && points.get('order_system')?.value === 'selectedCategory'"
              matInput
              placeholder="0"
              formControlName="order_checked_cats_points"
              [ngClass]="{ 'error-border': points.get('order_checked_cats_points')?.invalid && points.get('order_checked_cats_points')?.touched }"
              type="number"
              required
            />

            <input
              *ngIf="points.get('product_option')?.value === 'allProductsCategories' && points.get('points_system')?.value === 'byProductCategory'"
              matInput
              placeholder="0"
              formControlName="product_points"
              [ngClass]="{ 'error-border': points.get('product_points')?.invalid && points.get('product_points')?.touched }"
              type="number"
              required
            />
            <!-- By Units Container  -->
            <div *ngIf="points.get('points_system')?.value === 'byUnit' && (points.get('currency_system')?.value === 'currencyAll' || points.get('currency_system')?.value === 'currency_product' || points.get('currency_system')?.value === 'currency_category')">
              <input matInput placeholder="0" formControlName="units" [ngClass]="{ 'error-border': points.get('units')?.invalid && points.get('units')?.touched }" type="number" required />

              <span>$ ( {{ 'Currency' | translate }} ) {{ 'Assign' | translate }}</span>

              <input
                *ngIf="!(points.get('currency_system')?.value === 'currency_product' || points.get('currency_system')?.value === 'currency_category')"
                matInput
                placeholder="0"
                [ngClass]="{ 'error-border': points.get('points')?.invalid && points.get('points')?.touched }"
                formControlName="points"
                type="number"
                required
              />
              <!-- ByUnits currency_product -->
              <input
                *ngIf="points.get('currency_system')?.value === 'currency_product' && points.get('points_system')?.value === 'byUnit'"
                matInput
                placeholder="0"
                formControlName="currency_product_point"
                [ngClass]="{ 'error-border': points.get('currency_product_point')?.invalid && points.get('currency_product_point')?.touched }"
                type="number"
                required
              />
              <!-- ByUnits currency_category_point -->

              <input
                *ngIf="points.get('currency_system')?.value === 'currency_category' && points.get('points_system')?.value === 'byUnit'"
                matInput
                required
                placeholder="0"
                [ngClass]="{ 'error-border': points.get('currency_category_point')?.invalid && points.get('currency_category_point')?.touched }"
                formControlName="currency_category_point"
                type="number"
              />
            </div>

            <span *ngIf="points.get('points_system')?.value !== 'byProductCategory' && (points.get('product_option')?.value !== 'choosenProduct' || points.get('product_option')?.value !== 'choosenCategory') && points.get('points_system')?.value !== ''">( {{ 'Points' | translate }} )</span>
          </div>
        </div>

        <!-- Redeem -->

        <div class="pointPart">
          <h3>{{ 'Redeem' | translate }}</h3>

          <div>
            <input formControlName="redeem_points" [ngClass]="{ 'error-border': points.get('redeem_points')?.invalid && points.get('redeem_points')?.touched }" matInput type="number" required placeholder="0" />
            <span>{{ 'Points' | translate }}</span>
          </div>
          <div>
            <input formControlName="redeem_currency" [ngClass]="{ 'error-border': points.get('redeem_currency')?.invalid && points.get('redeem_currency')?.touched }" matInput type="number" required placeholder="0" />
            <span>{{ 'Currency' | translate }}</span>
          </div>
          <div>
            <input formControlName="min_redeem" [ngClass]="{ 'error-border': points.get('min_redeem')?.invalid && points.get('min_redeem')?.touched }" matInput type="number" required placeholder="0" />
            <span>{{ 'minimum amount to redeem' | translate }}</span>
          </div>
        </div>

        <div class="pointPart">
          <h3>{{ 'Validity Period' | translate }}</h3>
          <div>
            <input formControlName="validity_date" [ngClass]="{ 'error-border': points.get('validity_date')?.invalid && points.get('validity_date')?.touched }" matInput type="number" required placeholder="0" />
            <span class="expiry"
              ><span class="days">( {{ 'days' | translate }} )</span> {{ 'count from purchase day' | translate }}</span
            >
          </div>
        </div>

        <div class="pointPart">
          <h3>{{ 'Remove Point When Order Cancelled' | translate }}</h3>
          <div>
            <mat-slide-toggle color="primary" class="orderCaneltion" formControlName="order_cancelation">
              {{ 'No / Yes' | translate }}
            </mat-slide-toggle>
          </div>
        </div>
      </div>
    </mat-tab>

    <!-- Tab 2: Tab Label 2 -->
    <mat-tab label="{{ 'Customization' | translate }}">
      <customization></customization>
    </mat-tab>

    <!-- Tab 3: Tab Label 3 -->
    <mat-tab label="{{ 'Users' | translate }}">
      <user></user>
    </mat-tab>
  </mat-tab-group>
</div>

<form id="points" (ngSubmit)="saveChanges()"></form>
