import { Component } from '@angular/core'
import { FormBuilder, Validators } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { WorksDialogComponent } from 'src/app/pages/works-dialog/works-dialog.component'
import { AppSettingsService } from 'src/app/settings/services/app-settings.service'
import { MixpanelService } from 'src/app/tracking/mixpanel.service'
import { BuilderService } from '../../builder-services/builder.service'
import { AndroidbuildService } from '../android-build/androidbuild.service'

@Component({
  selector: 'android-apk',
  templateUrl: './android-apk.component.html',
  styleUrls: ['./android-apk.component.scss']
})
export class AndroidApkComponent {
  buildStart = false
  value = 0
  qrLink = ''
  downloadBtn = ''
  checkingStatus = true
  error = false
  playType = 'apk'
  buildErrorMsg = ''
  warningMessage: string = ''
  generalSettingsData: any = {}
  webviewApp = false
  constructor(
    private formBuilder: FormBuilder,
    public builderService: BuilderService,
    private androidService: AndroidbuildService,
    public dialog: MatDialog,
    private mixPanel: MixpanelService,
    private appSettingsService: AppSettingsService
  ) {
    this.getBuildStatus()
    this.checkWebview()
  }

  androidBuildForm = this.formBuilder.group({
    androidVersion: ['', [Validators.required, Validators.min(0.1), Validators.max(9999)]],
    androidVersionCode: ['', [Validators.required, Validators.min(1), Validators.max(999999), Validators.pattern('^[0-9]*$')]]
  })

  /**
   * Handles input events to ensure only numeric values are entered.
   * @param event - The input event triggered by the user.
   */
  onInput(event: Event): void {
    const inputElement = event.target as HTMLInputElement
    inputElement.value = inputElement.value.replace(/[^0-9]/g, '')
  }

  ngOnInit() {
    setTimeout(() => {
      this.getInitialSettings()
    }, 1000)
  }

  checkWebview = () => {
    this.appSettingsService.getContentSettings(this.builderService.selectedProject).subscribe(
      (settings: any) => {
        if (settings.mobile_webview_link !== null) {
          this.webviewApp = true
        }
      },
      (err) => {
        console.log(err)
      }
    )
  }

  /**
   * Submits a build request to the server
   */
  onSubmit(): void {
    this.value = 0
    this.buildStart = true
    this.builderService.headerBtn = ''
    this.error = false
    if (!this.androidBuildForm.value.androidVersion) {
      this.androidBuildForm.value.androidVersion = '0.1'
    }
    if (!this.androidBuildForm.value.androidVersionCode) {
      this.androidBuildForm.value.androidVersionCode = '0'
    }
    this.mixPanel.track('APK Build', {
      projectid: this.builderService.selectedProject
    })
    this.androidService.startBuild(this.builderService.getSelectedProject(), this.androidBuildForm.value.androidVersion, this.androidBuildForm.value.androidVersionCode).subscribe({
      next: (response: any) => {
        console.log('Response received:', response)
        this.getBuildStatus()
      },
      error: (err) => {
        setTimeout(() => {
          this.getBuildStatus()
          console.error('HTTP Error:', err)
        }, 60000)
      }
    })
  }

  /**
   * Gets the build status from the builder and brain, incase the app is still progressing then we will rerun the same function checker again after 5 seconds
   */
  getBuildStatus = () => {
    this.androidService.getProgress(this.builderService.getSelectedProject(), this.androidBuildForm.value.androidVersion).subscribe((progressRes: any) => {
      this.value = progressRes.data
      this.checkingStatus = false
      if (this.value === 0 && progressRes.status !== 'error') {
        this.buildStart = false
      } else {
        if (this.value <= 100) {
          if (progressRes.status !== 'error' && this.value == 100) {
            this.downloadBtn = progressRes.android_url
            this.qrLink = 'https://qrcode.tec-it.com/API/QRCode?data=' + progressRes.android_url
            this.error = false
            this.buildStart = false
          } else if (progressRes.status == 'error') {
            this.error = true
            this.getBuildError()
            this.builderService.headerBtn == 'android'
          } else {
            setTimeout(() => {
              this.getBuildStatus()
            }, 5000)
            this.error = false
          }
          this.buildStart = true
        }
      }
    })
  }

  getBuildError = () => {
    this.androidService.getBuildError(this.builderService.getSelectedProject()).subscribe((response: any) => {
      this.buildErrorMsg = decodeURIComponent(response.message.replace(/\+/g, ' '))
    })
  }

  rebuildApp = () => {
    this.buildStart = false
    this.checkingStatus = false
    this.error = false
  }

  howItWorksDialog = () => {
    const dialogRef = this.dialog.open(WorksDialogComponent, {
      width: '700px',
      maxWidth: '80%',
      data: { name: 'android' }
    })

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed')
    })
  }

  getInitialSettings = () => {
    let project_id = this.builderService.selectedProject
    this.appSettingsService.getGeneralSettings(project_id).subscribe(
      (settings: any) => {
        this.generalSettingsData = settings !== null ? settings : {}
        const packageName = this.generalSettingsData.package_name
        this.appSettingsService.getApplicationSettings(project_id).subscribe(
          (appSettings: any) => {
            console.log(appSettings)
            const auth0RedirectURI = appSettings.auth0_redirect_uri || 'com.stacks.www://dev-3t3cfn38mfbo482t.us.auth0.com/capacitor/com.stacks.www/callback'
            const auth0PackageNameMatch = auth0RedirectURI.match(/capacitor\/(.*?)\/callback/)
            const auth0PackageName = auth0PackageNameMatch ? auth0PackageNameMatch[1] : ''
            if (packageName !== auth0PackageName || !auth0RedirectURI.startsWith(`${packageName}://`)) {
              this.warningMessage = 'The package name does not match the Auth0 login configuration. Please ensure the package name in the settings matches the Auth0 redirect URI.'
            } else {
              this.warningMessage = ''
            }
          },
          (err) => {
            console.error('Error fetching application settings:', err)
          }
        )
      },
      (err) => {
        console.error('Error fetching general settings:', err)
      }
    )
  }
}
