import { state, style, transition, trigger, animate } from '@angular/animations'
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { ToastService } from './toast.service'

@Component({
  selector: 'stacks-toastr',
  templateUrl: './toastr.component.html',
  styleUrls: ['./toastr.component.scss'],
  animations: [
    trigger('openClose', [
      state(
        'closed',
        style({
          visibility: 'hidden',
          right: '-400px'
        })
      ),
      state(
        'open',
        style({
          top: '76vh',
          right: '4vh'
        })
      ),
      transition('open <=> closed', [animate('0.5s ease-in-out')])
    ])
  ]
})
export class ToastrComponent implements OnInit {
  @ViewChild('element', { static: false }) progressBar!: ElementRef
  progressInterval: any

  constructor(public toastService: ToastService) {}

  ngOnInit() {
    this.toastService.open.subscribe((data) => {
      if (data && data.show) {
        setTimeout(() => {
          if (this.progressBar) {
            this.startCountDown()
          }
        }, 100)
      }
    })
  }
  startCountDown() {
    this.progressBar.nativeElement.style.width = this.toastService.data.progressWidth

    this.progressInterval = setInterval(() => {
      const width = parseInt(this.progressBar.nativeElement.style.width, 10)

      if (width <= 0) {
        this.toastService.hide()
        clearInterval(this.progressInterval)
        return
      }

      this.toastService.data.progressWidth = String(width - 1)
      this.progressBar.nativeElement.style.width = this.toastService.data.progressWidth + '%'
    }, 100)
  }

  stopCountDown() {
    clearInterval(this.progressInterval)
  }

  toastOnClick() {
    if (this.toastService.data?.onClick) {
      this.toastService.data.onClick()
    }
    this.toastService.hide()
  }
}
