// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  proxyUrl: 'https://app.stacksmarket.co/proxy/?url=',
  production: false,
  stacksURL: 'https://staging.stacksmarket.co/',
  premiumProductURL: 'https://stacksmarket.co/?add-to-cart=15958&variation_id=72793&attribute_packages=premium',
  builderURL: 'https://builder.staging.stacksmarket.co/wp-json/v4/',
  builderNativeURL: 'https://builder.staging.stacksmarket.co/',
  loggerURL: 'https://logger.staging.stacksmarket.co/',
  debuggableJSON: true,
  ga: 'G-0C644PNRKD',
  hj: '2650044',
  fb: '123',
  // fb: '371064266731563',
  gtm: '123',
  googleLogin: '339787967114-ikkals4eq2r58s7i70up9f406q01vbd6.apps.googleusercontent.com',
  showStripe: true,
  stripePublishableKey: 'pk_test_51LEVyLDPC5jsdjOiW5jaMOlmKKV4rxG1TgbXvEi1MvMrhfAI0kDIWk3UxapuzSbbvesXOoQtuwqF2HDxpOKBAeRK004RKXelB6',
  manageSubscriptions: 'https://billing.stripe.com/p/login/test_eVa5li5g20z7d1ufYY',
  firebaseConfig: {
    apiKey: 'AIzaSyA00BUWrxC6HeXoI18rKFXRavTEeZwuu2s',
    authDomain: 'neat-planet-378416.firebaseapp.com',
    databaseURL: 'https://neat-planet-378416-default-rtdb.firebaseio.com',
    projectId: 'neat-planet-378416',
    storageBucket: 'neat-planet-378416.appspot.com',
    messagingSenderId: '339787967114',
    appId: '1:339787967114:web:75ab124244232034241b73',
    measurementId: 'G-RJRCW3EKC5'
  },
  packages: [
    {
      access: 'free',
      restricted_pages: []
    },
    {
      access: 'full',
      subPackages: [
        {
          type: 'Starter',
          restricted_pages: ['ios', 'multilingual', 'txtTranslations', 'points']
        },
        {
          type: 'Growth',
          restricted_pages: []
        }
      ]
    }
  ],
  pricing_packages: [
    {
      pricing_id: 'price_1PxrWRDPC5jsdjOiaKwvDeWo',
      type: 'Starter',
      price: 99,
      currency: 'USD',
      software: 'Android',
      duration: 'monthly',
      service_type: 'server_with_stacks',
      description: 'Features Perfect for beginners!',
      features: [
        { name: 'Full Access to Drag & Drop Builder', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Products Importer', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '50,000 notifications / Month', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'App Downloads Analysis', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'WooCommerce Integration', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'No Website Needed', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '5 GB Storage', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '24/7 Support', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Additional Admin Seat $25/seat', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Android Only', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Loyalty Program', icon: 'assets/images/PricingPage/unChecked.png', icon2: 'assets/images/gccLanding/grayCheck.png' },
        { name: 'Multilingual', icon: 'assets/images/PricingPage/unChecked.png', icon2: 'assets/images/gccLanding/grayCheck.png' },
        { name: 'Our Team Designs the App', icon: 'assets/images/PricingPage/unChecked.png', icon2: 'assets/images/gccLanding/grayCheck.png' },
        { name: 'Dedicated Account Manager', icon: 'assets/images/PricingPage/unChecked.png', icon2: 'assets/images/gccLanding/grayCheck.png' }
      ]
    },
    {
      pricing_id: 'price_1PxrXGDPC5jsdjOinEakBsMg',
      type: 'Growth',
      price: 149,
      currency: 'USD',
      software: 'Android & iOS',
      duration: 'monthly',
      service_type: 'server_with_stacks',
      features: [
        { name: 'Full Access to Drag & Drop Builder', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Products Importer', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '100,000 notifications / Month', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'App Downloads Analysis', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'WooCommerce Integration', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'No Website Needed', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '15 GB Storage', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '24/7 Support', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Additional Admin Seat $25/seat', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Android & iOS', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Loyalty Program', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Multilingual', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Our Team Designs the App', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Dedicated Account Manager', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' }
      ]
    },
    {
      pricing_id: 'price_1PxrWRDPC5jsdjOitYnK42AP',
      type: 'Starter',
      price: 990,
      currency: 'USD',
      software: 'Android',
      duration: 'Yearly',
      service_type: 'server_with_stacks',
      features: [
        { name: 'Full Access to Drag & Drop Builder', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Products Importer', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '50,000 notifications / Month', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'App Downloads Analysis', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'WooCommerce Integration', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'No Website Needed', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '5 GB Storage', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '24/7 Support', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Additional Admin Seat $25/seat', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Android Only', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Loyalty Program', icon: 'assets/images/PricingPage/unChecked.png', icon2: 'assets/images/gccLanding/grayCheck.png' },
        { name: 'Multilingual', icon: 'assets/images/PricingPage/unChecked.png', icon2: 'assets/images/gccLanding/grayCheck.png' },
        { name: 'Our Team Designs the App', icon: 'assets/images/PricingPage/unChecked.png', icon2: 'assets/images/gccLanding/grayCheck.png' },
        { name: 'Dedicated Account Manager', icon: 'assets/images/PricingPage/unChecked.png', icon2: 'assets/images/gccLanding/grayCheck.png' }
      ]
    },
    {
      pricing_id: 'price_1PxrXGDPC5jsdjOipukROYtx',
      type: 'Growth',
      price: 1490,
      currency: 'USD',
      software: 'Android & iOS',
      duration: 'Yearly',
      service_type: 'server_with_stacks',
      features: [
        { name: 'Full Access to Drag & Drop Builder', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Products Importer', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '100,000 notifications / Month', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'App Downloads Analysis', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'WooCommerce Integration', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'No Website Needed', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '15 GB Storage', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '24/7 Support', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Additional Admin Seat $25/seat', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Android & iOS', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Loyalty Program', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Multilingual', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Our Team Designs the App', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Dedicated Account Manager', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' }
      ]
    },
    {
      pricing_id: 'price_1QEvGUDPC5jsdjOirs51x99d', // 227 USD / Quarter
      type: 'Starter',
      price: 99,
      currency: 'USD',
      software: 'Android',
      duration: 'Quarterly',
      service_type: 'server_with_stacks',
      features: [
        { name: 'Full Access to Drag & Drop Builder', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Products Importer', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '50,000 notifications / Month', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'App Downloads Analysis', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'WooCommerce Integration', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'No Website Needed', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '5 GB Storage', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '24/7 Support', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Additional Admin Seat $25/seat', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Android Only', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Loyalty Program', icon: 'assets/images/PricingPage/unChecked.png', icon2: 'assets/images/gccLanding/grayCheck.png' },
        { name: 'Multilingual', icon: 'assets/images/PricingPage/unChecked.png', icon2: 'assets/images/gccLanding/grayCheck.png' },
        { name: 'Our Team Designs the App', icon: 'assets/images/PricingPage/unChecked.png', icon2: 'assets/images/gccLanding/grayCheck.png' },
        { name: 'Dedicated Account Manager', icon: 'assets/images/PricingPage/unChecked.png', icon2: 'assets/images/gccLanding/grayCheck.png' }
      ]
    },
    {
      pricing_id: 'price_1QEvHaDPC5jsdjOiwSAX2E4b', // 447 USD / Quarter
      type: 'Growth',
      price: 149,
      currency: 'USD',
      software: 'Android & iOS',
      duration: 'Quarterly',
      service_type: 'server_with_stacks',
      features: [
        { name: 'Full Access to Drag & Drop Builder', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Products Importer', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '100,000 notifications / Month', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'App Downloads Analysis', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'WooCommerce Integration', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'No Website Needed', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '15 GB Storage', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '24/7 Support', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Additional Admin Seat $25/seat', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Android & iOS', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Loyalty Program', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Multilingual', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Our Team Designs the App', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Dedicated Account Manager', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' }
      ]
    }
  ],
  emirates_pricing_packages: [
    // Emirates price
    {
      pricing_id: 'price_1QWURoDPC5jsdjOil5lpd4Vs',
      type: 'Starter',
      price: 1047,
      currency: 'AED',
      software: 'Android',
      duration: 'Quarterly',
      service_type: 'server_with_stacks',
      features: [
        { name: 'Full Access to Drag & Drop Builder', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Products Importer', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '50,000 notifications / Month', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'App Downloads Analysis', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'WooCommerce Integration', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'No Website Needed', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '5 GB Storage', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '24/7 Support', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Additional Admin Seat $25/seat', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Android Only', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Loyalty Program', icon: 'assets/images/PricingPage/unChecked.png', icon2: 'assets/images/gccLanding/grayCheck.png' },
        { name: 'Multilingual', icon: 'assets/images/PricingPage/unChecked.png', icon2: 'assets/images/gccLanding/grayCheck.png' },
        { name: 'Our Team Designs the App', icon: 'assets/images/PricingPage/unChecked.png', icon2: 'assets/images/gccLanding/grayCheck.png' },
        { name: 'Dedicated Account Manager', icon: 'assets/images/PricingPage/unChecked.png', icon2: 'assets/images/gccLanding/grayCheck.png' }
      ]
    },
    {
      pricing_id: 'price_1QWUW5DPC5jsdjOi5GLfQE6F',
      type: 'Starter',
      price: 3490,
      currency: 'AED',
      software: 'Android',
      duration: 'Yearly',
      service_type: 'server_with_stacks',
      features: [
        { name: 'Full Access to Drag & Drop Builder', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Products Importer', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '50,000 notifications / Month', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'App Downloads Analysis', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'WooCommerce Integration', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'No Website Needed', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '5 GB Storage', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '24/7 Support', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Additional Admin Seat $25/seat', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Android Only', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Loyalty Program', icon: 'assets/images/PricingPage/unChecked.png', icon2: 'assets/images/gccLanding/grayCheck.png' },
        { name: 'Multilingual', icon: 'assets/images/PricingPage/unChecked.png', icon2: 'assets/images/gccLanding/grayCheck.png' },
        { name: 'Our Team Designs the App', icon: 'assets/images/PricingPage/unChecked.png', icon2: 'assets/images/gccLanding/grayCheck.png' },
        { name: 'Dedicated Account Manager', icon: 'assets/images/PricingPage/unChecked.png', icon2: 'assets/images/gccLanding/grayCheck.png' }
      ]
    },
    {
      pricing_id: 'price_1QjhNODPC5jsdjOiOxfV1MLc', // 447 AED / Quarter
      type: 'Growth',
      price: 550,
      currency: 'AED',
      software: 'Android & iOS',
      duration: 'Monthly',
      service_type: 'server_with_stacks',
      features: [
        { name: 'Full Access to Drag & Drop Builder', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Products Importer', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '100,000 notifications / Month', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'App Downloads Analysis', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'WooCommerce Integration', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'No Website Needed', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '15 GB Storage', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '24/7 Support', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Additional Admin Seat $25/seat', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Android & iOS', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Loyalty Program', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Multilingual', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Our Team Designs the App', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Dedicated Account Manager', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' }
      ]
    },
    {
      pricing_id: 'price_1QWUYLDPC5jsdjOir81iLpQo', // 447 AED / Quarter
      type: 'Growth',
      price: 1650,
      currency: 'AED',
      software: 'Android & iOS',
      duration: 'Quarterly',
      service_type: 'server_with_stacks',
      features: [
        { name: 'Full Access to Drag & Drop Builder', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Products Importer', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '100,000 notifications / Month', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'App Downloads Analysis', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'WooCommerce Integration', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'No Website Needed', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '15 GB Storage', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '24/7 Support', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Additional Admin Seat $25/seat', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Android & iOS', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Loyalty Program', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Multilingual', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Our Team Designs the App', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Dedicated Account Manager', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' }
      ]
    },
    {
      pricing_id: 'price_1QWUYhDPC5jsdjOi46T48q7u',
      type: 'Growth',
      price: 5500,
      currency: 'AED',
      software: 'Android & iOS',
      duration: 'Yearly',
      service_type: 'server_with_stacks',
      features: [
        { name: 'Full Access to Drag & Drop Builder', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Products Importer', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '100,000 notifications / Month', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'App Downloads Analysis', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'WooCommerce Integration', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'No Website Needed', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '15 GB Storage', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '24/7 Support', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Additional Admin Seat $25/seat', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Android & iOS', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Loyalty Program', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Multilingual', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Our Team Designs the App', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Dedicated Account Manager', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' }
      ]
    }
  ],
  saudi_pricing_packages: [
    // Saudi Arabia price
    {
      pricing_id: 'price_1QWUelDPC5jsdjOiRBr9zUDI',
      type: 'Starter',
      price: 1047,
      currency: 'SAR',
      software: 'Android',
      duration: 'Quarterly',
      service_type: 'server_with_stacks',
      features: [
        { name: 'Full Access to Drag & Drop Builder', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Products Importer', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '50,000 notifications / Month', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'App Downloads Analysis', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'WooCommerce Integration', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'No Website Needed', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '5 GB Storage', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '24/7 Support', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Additional Admin Seat $25/seat', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Android Only', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Loyalty Program', icon: 'assets/images/PricingPage/unChecked.png', icon2: 'assets/images/gccLanding/grayCheck.png' },
        { name: 'Multilingual', icon: 'assets/images/PricingPage/unChecked.png', icon2: 'assets/images/gccLanding/grayCheck.png' },
        { name: 'Our Team Designs the App', icon: 'assets/images/PricingPage/unChecked.png', icon2: 'assets/images/gccLanding/grayCheck.png' },
        { name: 'Dedicated Account Manager', icon: 'assets/images/PricingPage/unChecked.png', icon2: 'assets/images/gccLanding/grayCheck.png' }
      ]
    },
    {
      pricing_id: 'price_1QWUf3DPC5jsdjOiM2fxc46w',
      type: 'Starter',
      price: 3490,
      currency: 'SAR',
      software: 'Android',
      duration: 'Yearly',
      service_type: 'server_with_stacks',
      features: [
        { name: 'Full Access to Drag & Drop Builder', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Products Importer', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '50,000 notifications / Month', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'App Downloads Analysis', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'WooCommerce Integration', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'No Website Needed', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '5 GB Storage', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '24/7 Support', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Additional Admin Seat $25/seat', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Android Only', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Loyalty Program', icon: 'assets/images/PricingPage/unChecked.png', icon2: 'assets/images/gccLanding/grayCheck.png' },
        { name: 'Multilingual', icon: 'assets/images/PricingPage/unChecked.png', icon2: 'assets/images/gccLanding/grayCheck.png' },
        { name: 'Our Team Designs the App', icon: 'assets/images/PricingPage/unChecked.png', icon2: 'assets/images/gccLanding/grayCheck.png' },
        { name: 'Dedicated Account Manager', icon: 'assets/images/PricingPage/unChecked.png', icon2: 'assets/images/gccLanding/grayCheck.png' }
      ]
    },
    {
      pricing_id: 'price_1QWUblDPC5jsdjOibKsiWQIQ', // 447 SAR / Quarter
      type: 'Growth',
      price: 1650,
      currency: 'SAR',
      software: 'Android & iOS',
      duration: 'Quarterly',
      service_type: 'server_with_stacks',
      features: [
        { name: 'Full Access to Drag & Drop Builder', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Products Importer', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '100,000 notifications / Month', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'App Downloads Analysis', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'WooCommerce Integration', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'No Website Needed', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '15 GB Storage', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '24/7 Support', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Additional Admin Seat $25/seat', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Android & iOS', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Loyalty Program', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Multilingual', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Our Team Designs the App', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Dedicated Account Manager', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' }
      ]
    },
    {
      pricing_id: 'price_1QWUc1DPC5jsdjOiR2b9bbrZ',
      type: 'Growth',
      price: 5500,
      currency: 'SAR',
      software: 'Android & iOS',
      duration: 'Yearly',
      service_type: 'server_with_stacks',
      features: [
        { name: 'Full Access to Drag & Drop Builder', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Products Importer', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '100,000 notifications / Month', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'App Downloads Analysis', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'WooCommerce Integration', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'No Website Needed', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '15 GB Storage', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '24/7 Support', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Additional Admin Seat $25/seat', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Android & iOS', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Loyalty Program', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Multilingual', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Our Team Designs the App', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Dedicated Account Manager', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' }
      ]
    }
  ],
  egypt_pricing_packages: [
    //Egypt pricing
    {
      pricing_id: 'price_1QYo94DPC5jsdjOinmJLuzBu',
      type: 'Starter',
      price: 2900,
      currency: 'EGP',
      software: 'Android',
      duration: 'Quarterly',
      service_type: 'server_with_stacks',
      features: [
        { name: 'Full Access to Drag & Drop Builder', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Products Importer', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '50,000 notifications / Month', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'App Downloads Analysis', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'WooCommerce Integration', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'No Website Needed', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '5 GB Storage', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '24/7 Support', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Additional Admin Seat $25/seat', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Android Only', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Loyalty Program', icon: 'assets/images/PricingPage/unChecked.png', icon2: 'assets/images/gccLanding/grayCheck.png' },
        { name: 'Multilingual', icon: 'assets/images/PricingPage/unChecked.png', icon2: 'assets/images/gccLanding/grayCheck.png' },
        { name: 'Our Team Designs the App', icon: 'assets/images/PricingPage/unChecked.png', icon2: 'assets/images/gccLanding/grayCheck.png' },
        { name: 'Dedicated Account Manager', icon: 'assets/images/PricingPage/unChecked.png', icon2: 'assets/images/gccLanding/grayCheck.png' }
      ]
    },
    {
      pricing_id: 'price_1QYo9mDPC5jsdjOiQ8VjSlYC',
      type: 'Starter',
      price: 9900,
      currency: 'EGP',
      software: 'Android',
      duration: 'Yearly',
      service_type: 'server_with_stacks',
      features: [
        { name: 'Full Access to Drag & Drop Builder', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Products Importer', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '50,000 notifications / Month', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'App Downloads Analysis', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'WooCommerce Integration', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'No Website Needed', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '5 GB Storage', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '24/7 Support', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Additional Admin Seat $25/seat', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Android Only', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Loyalty Program', icon: 'assets/images/PricingPage/unChecked.png', icon2: 'assets/images/gccLanding/grayCheck.png' },
        { name: 'Multilingual', icon: 'assets/images/PricingPage/unChecked.png', icon2: 'assets/images/gccLanding/grayCheck.png' },
        { name: 'Our Team Designs the App', icon: 'assets/images/PricingPage/unChecked.png', icon2: 'assets/images/gccLanding/grayCheck.png' },
        { name: 'Dedicated Account Manager', icon: 'assets/images/PricingPage/unChecked.png', icon2: 'assets/images/gccLanding/grayCheck.png' }
      ]
    },
    {
      pricing_id: 'price_1QYoALDPC5jsdjOipGmQk0ig', // 447 EGP / Quarter
      type: 'Growth',
      price: 4490,
      currency: 'EGP',
      software: 'Android & iOS',
      duration: 'Quarterly',
      service_type: 'server_with_stacks',
      features: [
        { name: 'Full Access to Drag & Drop Builder', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Products Importer', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '100,000 notifications / Month', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'App Downloads Analysis', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'WooCommerce Integration', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'No Website Needed', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '15 GB Storage', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '24/7 Support', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Additional Admin Seat $25/seat', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Android & iOS', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Loyalty Program', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Multilingual', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Our Team Designs the App', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Dedicated Account Manager', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' }
      ]
    },
    {
      pricing_id: 'price_1QYoAhDPC5jsdjOiqN4XnrQh',
      type: 'Growth',
      price: 14900,
      currency: 'EGP',
      software: 'Android & iOS',
      duration: 'Yearly',
      service_type: 'server_with_stacks',
      features: [
        { name: 'Full Access to Drag & Drop Builder', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Products Importer', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '100,000 notifications / Month', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'App Downloads Analysis', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'WooCommerce Integration', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'No Website Needed', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '15 GB Storage', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '24/7 Support', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Additional Admin Seat $25/seat', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Android & iOS', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Loyalty Program', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Multilingual', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Our Team Designs the App', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Dedicated Account Manager', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' }
      ]
    }
  ],
  test_pricing_packages: [
    {
      pricing_id: 'price_1QrIhxDPC5jsdjOi4E90ot5g', // 447 USD / Quarter
      type: 'Starter',
      price: 1,
      currency: 'USD',
      software: 'Android & iOS',
      duration: 'Quarterly',
      service_type: 'server_with_stacks',
      features: [
        { name: 'Full Access to Drag & Drop Builder', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Products Importer', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '100,000 notifications / Month', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'App Downloads Analysis', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'WooCommerce Integration', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'No Website Needed', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '15 GB Storage', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '24/7 Support', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Additional Admin Seat $25/seat', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Android & iOS', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Loyalty Program', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Multilingual', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Our Team Designs the App', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Dedicated Account Manager', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' }
      ]
    },
    {
      pricing_id: 'price_1QrIgeDPC5jsdjOiffqWDndS', // 447 USD / Quarter
      type: 'Growth',
      price: 2,
      currency: 'USD',
      software: 'Android & iOS',
      duration: 'Quarterly',
      service_type: 'server_with_stacks',
      features: [
        { name: 'Full Access to Drag & Drop Builder', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Products Importer', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '100,000 notifications / Month', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'App Downloads Analysis', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'WooCommerce Integration', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'No Website Needed', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '15 GB Storage', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: '24/7 Support', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Additional Admin Seat $25/seat', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Android & iOS', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Loyalty Program', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Multilingual', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Our Team Designs the App', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' },
        { name: 'Dedicated Account Manager', icon: 'assets/images/PricingPage/check.png', icon2: 'assets/images/gccLanding/blueCheck.png' }
      ]
    }
  ]
}

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
