<div class="containerWrapper">
  <div id="titleContainer">
    <h1>{{ 'App Preview' | translate }}</h1>
  </div>
  <div class="projectInfo" *ngIf="newSamplePw">
    <p>
      <strong>{{ 'Project ID:' | translate }}</strong> {{ prodjectId }}
    </p>
    <p>
      <strong>{{ 'Password:' | translate }}</strong> {{ newSamplePw }}
    </p>
    <p class="infoDescription">
      {{ 'Download the sample app from the Play Store or Apple Store and use the provided Project ID and Password to explore and test your app.' | translate }}
    </p>
  </div>
  <div id="stacksAppPreview">
    <div class="qrCodeWrapper">
      <div>
        <h3>{{ 'Download Android Sample App From Play Store' | translate }}</h3>
        <img [src]="sampleAppQrCodes.playStore" alt="Android QR Code" />
      </div>
      <div>
        <h3>{{ 'Download iOS Sample App From Apple Store' | translate }}</h3>
        <!-- <h3>{{ "Coming Soon" | translate }}</h3> -->
        <img [src]="sampleAppQrCodes.appleStore" alt="iOS QR Code" />
      </div>
    </div>
  </div>
</div>
