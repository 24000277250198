import { Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { BuilderService } from '../../builder-services/builder.service';
import { WorksDialogComponent } from '../../pages/works-dialog/works-dialog.component';
import { PushNotificationsService } from './push-notifications.service';
import { AppSettingsService } from '../../settings/services/app-settings.service';
import { ProductsService as standaloneProductsService } from '../../e-commerce/products/products.service';
import { CategoriesService as standaloneCategoriesService } from '../../e-commerce/categories/categories.service';
import { CategoriesService } from 'src/app/blocks-wrapper/block-categories/categories.service';
import { ProductsService } from 'src/app/blocks-wrapper/block-products/products.service';
import { PostsService } from 'src/app/blocks-wrapper/block-posts/posts.service';
import { BlockFieldsService } from 'src/app/block-services/block-fields.service';
import { DatabaseService } from 'src/app/builder-services/database.service';

export interface PeriodicElement {
  id: number;
  name: string;
  email: string;
  isChecked?: boolean;
}

@Component({
  selector: 'app-push-notifications',
  templateUrl: './push-notifications.component.html',
  styleUrls: ['./push-notifications.component.scss'],
  encapsulation: ViewEncapsulation.None,

})
export class PushNotificationsComponent implements OnInit {

  ELEMENT_DATA: PeriodicElement[] = [];

  displayedColumns: string[] = ['name', 'email'];
  displayedValidationColumns: string[] = ['field', 'value'];
  // dataSource = this.ELEMENT_DATA;
  dataSource: any;
  clickedRows = new Set<PeriodicElement>();
  clickedUsers: any = [];
  allChecked: boolean = false
  storedProducts: any = [];
  storedPosts: any = [];
  settingsOpen: boolean = false
  listOpen: boolean = false

  @ViewChild(MatPaginator) paginator!: MatPaginator;

  @ViewChild('google_service_json') jsonRm: ElementRef | undefined;

  // MatPaginator Inputs
  length = 1;
  pageSize = 10;

  titleCounter = 65;
  titleClass = '';
  messageCounter = 240;
  messageClass = '';
  mobile = false;
  toggledEmoji: boolean = false;
  toggledEmojiMsg: boolean = false;

  pnValidation = {
    auth_key: false,
    google_sender_id: false,
    google_service_json: false
  };

  validationData: any = [];
  allUsers: any = [];
  applicationSettingsData: any = {};
  PNForm!: FormGroup;
  public jsonFile!: File | undefined;
  public plistFile!: File | undefined;
  public serviceAccountFile!: File | undefined;
  jsonFileName!: string;
  plistFileName!: string;
  serviceAccountFileName!: string
  searchKey: any;
  searchVal: any = undefined;
  foundItem: any;
  senderId: any;
  route: any;
  public now: Date = new Date();
  public formattedDate: string = `${this.now.toLocaleDateString('en-US', { weekday: 'long' })}, ${this.now.getDate()} ${this.now.toLocaleDateString('en-US', { month: 'long' })}`;
  public formattedTime: string = this.now.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    hour12: true,
  }).replace(/ AM| PM/, '');;
  constructor(
    public productsService: ProductsService,
    private appSettingsService: AppSettingsService,
    private formBuilder: FormBuilder,
    public pushNotifications: PushNotificationsService,
    public builderService: BuilderService,
    private router: Router,
    public dialog: MatDialog,
    public standaloneProductsService: standaloneProductsService,
    public standaloneCategoriesService: standaloneCategoriesService,
    public categoriesService: CategoriesService,
    private postsService: PostsService,
    public blockFieldsService: BlockFieldsService,
    private db: DatabaseService
  ) {
    setInterval(() => {
      this.now = new Date();
    }, 60000);
    let projectId = String(localStorage.getItem("selectedProject"));
    let usersList: any = [];
    this.builderService.showUpdateProgress = false;
    this.pushNotifications.getUsers(projectId).subscribe(
      (response: any) => {
        if (this.builderService.standalone) {
          for (const key in response) {
            if (Object.prototype.hasOwnProperty.call(response, key)) {
              const element = response[key];
              console.log(element);
              if (element.device_id) {
                usersList.push({ id: key, name: element.nickname, email: element.email, device_id: element.device_id, device_type: element.device_type });
              }
            }
          }
        } else {
          for (let index = 0; index < response.length; index++) {
            const element = response[index];
            usersList.push({ id: element.data.ID, name: element.data.display_name, email: element.data.user_email });
          }
        }
        this.allUsers = usersList;
        console.log(usersList);
        this.ELEMENT_DATA = usersList;
        this.dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA);
        this.dataSource.paginator = this.paginator;
        this.length = usersList.length;
      }
    );

    /**
     * Verify Google Service JSON and Authentication Fields are Correct
     */
    this.pushNotifications.validatePN(projectId).subscribe(
      (response: any) => {
        this.pnValidation = response;
        let formattedValidation: any = [];
        for (const key in response) {
          if (Object.prototype.hasOwnProperty.call(response, key)) {
            const element = response[key];
            let formattedKey = key.replace(/_/g, " ");
            let fields = { 'field': formattedKey, 'value': element };
            formattedValidation.push(fields);
          }
        }
        this.validationData = new MatTableDataSource<PeriodicElement>(formattedValidation);
      }
    );;
    this.getPosts(this.builderService.getSelectedProject(), this.blockFieldsService.postsBlockData.data, false);

    this.getProducts(this.builderService.getSelectedProject(), this.blockFieldsService.productsBlockData.data, false);
    this.getStandaloneProducts();
    this.getStandaloneCategories();
  }
  ngOnInit(): void {
    if (window.screen.width <= 768) { // 768px portrait
      this.mobile = true;
    }
    this.PNForm = this.formBuilder.group({
      googleSenderId: ['', [Validators.required, Validators.pattern('^[0-9]*$')]],
      googleServiceJson: '',
      googleServicePlist: '',
      message: '',
      route: '',
      specificRoute: '',
      title: '',
      databaseURL: ['', [Validators.required]],
      serviceAccountKeys: ''
    });

    this.getApplicationSettings();
    /**
     * Disable Access for free users
     */
    // this.builderService.getProject(String( localStorage.getItem("selectedProject") ) ).subscribe((project: any) => {
    //   if( project.details[0].access == 'free' ) {
    //     const dialogTemplateLocked = this.dialog.open(WorksDialogComponent, {
    //       width: '700px',
    //       maxWidth: '80%',
    //       data: {name: "viewLocked"},
    //     });

    //     dialogTemplateLocked.afterClosed().subscribe(result => {
    //       this.router.navigate(['/']);
    //     });
    //   }
    // });
    this.dataSource.data = this.allUsers
    this.getPosts(this.builderService.getSelectedProject(), this.blockFieldsService.postsBlockData.data, true);

    this.getProducts(this.builderService.getSelectedProject(), this.blockFieldsService.productsBlockData.data, true);
    this.builderService.showUpdateProgress = false;
  }

  selectAll() {
    console.log(this.dataSource)
    for (let i of this.dataSource._data._value) {
      console.log(i)
      i.checked = true
      console.log(i)
    }
  }

  getProducts = (projectId: number, args: object, cached = true) => {
    this.builderService.showSettingsProgress = true;
    if (cached) {
      this.productsService.getProductsCached().subscribe((response: any) => {
        this.builderService.showSettingsProgress = false;
        let products: any = [];
        let responseProducts = response.products;
        for (const key in responseProducts) {
          if (Object.prototype.hasOwnProperty.call(responseProducts, key)) {
            const element = responseProducts[key];
            if (element) {
              products.push({ 'id': element.id, "name": element.name });
            }
          }
        }
        this.storedProducts = products;
      }, (err: any) => {

      });
    } else {
      this.productsService.getProducts(projectId, args).subscribe((response: any) => {
        this.builderService.showSettingsProgress = false;
        let products: any = [];
        let responseProducts = response.products;
        for (const key in responseProducts) {
          if (Object.prototype.hasOwnProperty.call(responseProducts, key)) {
            const element = responseProducts[key];
            if (element) {
              products.push({ 'id': element.id, "name": element.name });
            }
          }
        }
        this.storedProducts = products;
      }, (err: any) => {

      });
    }

    return true;
  }

  getPosts = (projectId: number, args: object, cached = true) => {
    this.builderService.showSettingsProgress = true;
    if (cached) {
      this.postsService.getPostsCached().subscribe((response) => {
        this.builderService.showSettingsProgress = false;
        let posts: any = [];
        for (const key in response) {
          if (Object.prototype.hasOwnProperty.call(response, key)) {
            const element = response[key];
            if (element) {
              posts.push({ 'ID': element.ID, "post_title": element.post_title, "post_image": element.post_image });
            }
          }
        }
        this.storedPosts = posts;
      }, (err) => {

      });
    } else {
      this.postsService.getPosts(projectId, args).subscribe((response) => {
        this.builderService.showSettingsProgress = false;
        let posts: any = [];
        for (const key in response) {
          if (Object.prototype.hasOwnProperty.call(response, key)) {
            const element = response[key];
            if (element) {
              posts.push({ 'ID': element.ID, "post_title": element.post_title, "post_image": element.post_image });
            }
          }
        }
        this.storedPosts = posts;
      }, (err) => {

      });
    }

    return true;
  }
  getStandaloneProducts = () => {
    this.standaloneProductsService.getProducts().subscribe((response_products: any) => {
      let products: any = [];
      for (const key in response_products) {
        if (Object.prototype.hasOwnProperty.call(response_products, key)) {
          const element = response_products[key];
          products.push({ 'id': element.Id, "name": element.productName });
        }
      }
      this.standaloneProductsService.storedStandaloneProducts = products;
    });
  }
  getStandaloneCategories = () => {
    this.standaloneCategoriesService.getCategories().subscribe((response: any) => {
      let categories: any = [];
      for (const key in response) {
        if (Object.prototype.hasOwnProperty.call(response, key)) {
          const element = response[key];
          categories.push({ 'id': element.categoryId, "name": element.categoryName, "thumbnail": element.categoryImage });
        }
      }
      this.standaloneCategoriesService.storedStandaloneCategories = categories;
    })
  }

  characterCounter = (type: string) => {
    if (type == "title") {
      if (this.PNForm.value.title) {
        this.titleCounter = 65 - this.PNForm.value.title.length;
        if (this.titleCounter < 10 && this.titleCounter > 0) {
          this.titleClass = 'limitWarning';
        }
        else if (this.titleCounter <= 0) {
          this.titleClass = 'limitReached';
        } else {
          this.titleClass = '';
        }
      }
    }

    if (type == "message") {
      if (this.PNForm.value.message) {
        this.messageCounter = 240 - this.PNForm.value.message.length;
        if (this.messageCounter < 10 && this.messageCounter > 0) {
          this.messageClass = 'limitWarning';
        } else if (this.messageCounter <= 0) {
          this.messageClass = 'limitReached';
        } else {
          this.messageClass = '';
        }
      }
    }
  }

  /**
   * Append user ID to the list of clicked users
   * @param userId the id of the user clicked
   */
  checked = (type: any) => {
    if (type == "all") {
      this.allChecked = !this.allChecked

      if (!this.allChecked) {
        this.dataSource.data.forEach((item: PeriodicElement) => (this.clickedUsers.splice(item), item.isChecked = false));
        console.log(this.clickedUsers)

      } else {
        this.dataSource.data.forEach((item: PeriodicElement) => (item.isChecked ? '' : this.clickedUsers.push(item), item.isChecked = true));
        console.log(this.clickedUsers)
      }
    } else {
      let user = type
      user.isChecked = !user.isChecked
      console.log(user)

      if (!user.isChecked) {
        let index = this.getIndex(user)
        this.clickedUsers.splice(index, 1)
        console.log(this.clickedUsers)
        if (this.clickedUsers.length === 0) {
          this.allChecked = false
        }

      } else {
        this.clickedUsers.push(user)
        console.log(this.clickedUsers.length)
      }
    }
    console.log(this.clickedUsers)
  }

  onSubmit() {
    this.foundItem = null
    this.dataSource.data = this.allUsers
    this.searchVal = this.searchKey
    this.dataSource.data.forEach((item: PeriodicElement) => ((item.name === this.searchKey || item.email === this.searchKey) ? this.foundItem = item : ''));
    console.log(this.dataSource.data)
    if (this.foundItem) {
      this.dataSource.data = [this.foundItem]
    } else {
      this.dataSource.data = this.allUsers
    }
    console.log(this.dataSource.data)
  }

  getIndex(user: any) {
    let index
    for (let i = 0; i < this.clickedUsers.length; i++) {
      if (this.clickedUsers[i] == user) {
        index = i;
      }
    }
    return index
  }

  sendMessage = () => {
    let users = this.clickedUsers.join(", ");
    if (!(this.PNForm.value.title && this.PNForm.value.message && users)) {
      alert("Please make sure to write title, message and choose a user");
      return;

    }
    this.builderService.showUpdateProgress = true;
    console.log(this.PNForm.value.route)
    console.log("sender ID: ", this.PNForm.value.googleSenderId)
    this.pushNotifications.sendMessage(this.PNForm.value.databaseURL, this.serviceAccountFile, this.PNForm.value.googleSenderId, this.PNForm.value.title, this.PNForm.value.message, this.clickedUsers, this.PNForm.value.route, this.PNForm.value.specificRoute)
      .subscribe(
        (response: any) => {
          console.log("send message respone", response);
          this.builderService.showUpdateProgress = false;
          const dialogRef = this.dialog.open(WorksDialogComponent, {
            width: '700px',
            maxWidth: '80%',
            data: { name: "messageSent" },
          });

          dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed');
          });
        },
        (err) => {
          console.log("error", err);

        }
      );
  }

  emoji = (event: any, field: string) => {
    if (field == 'message') {
      this.PNForm.patchValue({ message: this.PNForm.value.message + event.char });
    } else {
      this.PNForm.patchValue({ title: this.PNForm.value.title + event.char });
    }

  }

  handleCharDelete(event: any, field: string) {
    if (field == 'message') {
      if (this.PNForm.value.message) {
        if (this.PNForm.value.message.length > 0) {
          this.PNForm.patchValue({ message: this.PNForm.value.message.substr(0, this.PNForm.value.message.length - 2) });
        }
      } else {
        if (this.PNForm.value.title) {
          if (this.PNForm.value.title.length > 0) {
            this.PNForm.patchValue({ title: this.PNForm.value.title.substr(0, this.PNForm.value.title.length - 2) });
          }
        }
      }
    }
  }

  howItWorksDialog = () => {
    const dialogRef = this.dialog.open(WorksDialogComponent, {
      width: '700px',
      maxWidth: '80%',
      data: { name: "pushNotifications" },
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  processFile(fileInput: any, type: string, name: string) {
    console.log(fileInput.target.files)
    const file: File = fileInput.target.files[0];
    if (fileInput.target.files[0].type == 'application/json' && name == "googleServiceJson") {
      this.jsonFile = fileInput.target.files[0];
      if (this.jsonFile) {
        this.jsonFileName = this.jsonFile.name
      }
    }
    if (fileInput.target.files[0].type == '' && name == "googleServicePlist") {
      this.plistFile = fileInput.target.files[0];
      if (this.plistFile) {
        this.plistFileName = this.plistFile.name
      }
    }
    if (fileInput.target.files[0].type == 'application/json' && name == "serviceAccountKeys") {
      this.serviceAccountFile = fileInput.target.files[0];
      if (this.serviceAccountFile) {
        this.serviceAccountFileName = this.serviceAccountFile.name
      }
    }
    const reader = new FileReader();
    reader.addEventListener('load', (event: any) => {
      this.appSettingsService.uploadFile(file, this.builderService.selectedProject, type, name).subscribe(
        (res) => {
          this.applicationSettingsData[name] = res;
          this.builderService.showUpdateProgress = false;
        },
        (err) => {
          this.builderService.showUpdateProgress = false;
          alert("error uploading File, please contact support");
        })
    });
    reader.readAsDataURL(file);
  }

  removeFile(fileName: string) {
    const projectId = this.builderService.selectedProject;
    switch (fileName) {
      case 'serviceAcc':
        this.db.getDatabase('projects/' + projectId + '/settings/application_settings').subscribe((response) => {
          for (let i in response) {
            if (i === 'serviceAccBase64') {
              console.log(i);
              response[i] = '';
            }
          }
          this.db.setDatabase('projects/' + projectId + '/settings/application_settings', response).subscribe((response) => {
          });
        });
        this.serviceAccountFile = undefined;

        break;
      case 'jsonFile':
        console.log("In herer")
        this.db.getDatabase('projects/' + projectId + '/settings/application_settings').subscribe((response) => {
          for (let i in response) {
            if (i === 'jsonFileBase64') {
              console.log(i);
              response[i] = ''
            }
          }
          this.db.setDatabase('projects/' + projectId + '/settings/application_settings', response).subscribe((response) => {
          });
        });
        this.jsonFile = undefined;
        break;
      case 'plistFile':
        this.db.getDatabase('projects/' + projectId + '/settings/application_settings').subscribe((response) => {
          for (let i in response) {
            if (i === 'plistFileBase64') {
              console.log(i);
              response[i] = ''
            }
          }
          this.db.setDatabase('projects/' + projectId + '/settings/application_settings', response).subscribe((response) => {
          });
        });
        this.plistFile = undefined;

        break;
    }


  }

  saveChanges() {
    const projectId = this.builderService.selectedProject;

    this.appSettingsService.getApplicationSettings(projectId).subscribe(
      (currentSettings: any) => {
        const updatedSettings = {
          ...currentSettings,
          android_sender_id: this.PNForm.value.googleSenderId,
          google_service_json: this.jsonFile ? true : false,
          google_service_plist: this.plistFile ? true : false,
          databaseURL: this.PNForm.value.databaseURL,
          service_account_keys: this.serviceAccountFile ? true : false,
          serviceAccountFile: this.serviceAccountFile,
          serviceAccountFileName: this.serviceAccountFile ? this.serviceAccountFile.name : null,
        };
        if (this.serviceAccountFile) {
          this.appSettingsService.serviceAccFile = this.serviceAccountFile;
        } if (this.jsonFile) {
          this.appSettingsService.jsonFile = this.jsonFile;
        }
        if (this.plistFile) {
          this.appSettingsService.plistFile = this.plistFile;
        }
        this.appSettingsService.setApplicationSettings(projectId, updatedSettings).subscribe(
          () => {
            this.appSettingsService.setWPApplicationSettings(this.builderService.selectedProject, updatedSettings).subscribe(
              () => {
                console.log('Push notifications login data saved successfully', updatedSettings);
              },
              (error: any) => {
                console.error('Error updating push notifications login data', error);
              }
            );
          },
          (error: any) => {
            console.error('Error updating push notifications login data', error);
          }
        );
      },
      (error: any) => {
        console.error('Error saving push notifications login data', error);
      }
    );
  }

  /**
   * Fetches and applies application settings for the current project.
   * Retrieves settings such as the Google sender ID and database URL,
   * and updates the form with these values if they exist.
   */
  getApplicationSettings() {
    this.builderService.showUpdateProgress=true
    const projectId = this.builderService.selectedProject;
    this.appSettingsService.getApplicationSettings(projectId).subscribe(
      (settings: any) => {
        console.log(settings)
        if (settings && ((settings.android_sender_id) || (settings.databaseURL))) {
          this.PNForm.patchValue({ googleSenderId: settings.android_sender_id });
          this.PNForm.patchValue({ databaseURL: settings.databaseURL });
          this.serviceAccountFileName = settings.serviceAccountFileName ? settings.serviceAccountFileName : '';
          this.jsonFileName = settings.google_service_json ? 'google-service.json' : '';
          this.plistFileName = settings.google_service_plist ? 'googleService-info.plist' : '';
          this.db.getDatabase('projects/' + projectId + '/settings/application_settings').subscribe((response) => {
            this.serviceAccountFile = this.appSettingsService.convertBase64ToFile(response.serviceAccBase64, 'serviceAccount.json');
            console.log('Recovered File:', this.serviceAccountFile);
            this.db.getDatabase('projects/' + projectId + '/settings/application_settings').subscribe((response) => {
              if (response.jsonFileBase64) {
                this.jsonFile = this.appSettingsService.convertBase64ToFile(response.jsonFileBase64, 'jsonFile.json');
              }
            })
            this.db.getDatabase('projects/' + projectId + '/settings/application_settings').subscribe((response) => {
              if (response.plistFileBase64) {
                this.plistFile = this.appSettingsService.convertBase64ToFile(response.plistFileBase64, 'jsonFile.json');
              }
            })
          })
        }
      }),
      (error: any) => {
        console.error('Error fetching application settings', error);
      }
  }
}
