import { ActivatedRoute, Router } from '@angular/router'
import { Component, OnInit } from '@angular/core'
import { BlockDataService } from 'src/app/block-services/block-data.service'
import { TemplatesService } from '../templates.service'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { WorksDialogComponent } from 'src/app/pages/works-dialog/works-dialog.component'
import { MatDialog } from '@angular/material/dialog'
import { ViewsService } from 'src/app/views/views.service'
import { VersionsService } from 'src/app/pages/versions/versions.service'
import { MixpanelService } from 'src/app/tracking/mixpanel.service'
import { DatabaseService } from 'src/app/builder-services/database.service'

@Component({
  selector: 'template-view',
  templateUrl: './template-view.component.html',
  styleUrls: ['./template-view.component.scss']
})
export class TemplateViewComponent implements OnInit {
  templatesList: Array<any>
  showTemplatesProgress = false
  constructor(
    private templateService: TemplatesService,
    private blockDataService: BlockDataService,
    private router: Router,
    private route: ActivatedRoute,
    public builderService: BuilderService,
    public dialog: MatDialog,
    private viewsService: ViewsService,
    private versions: VersionsService,
    private mixPanel: MixpanelService,
    private database: DatabaseService
  ) {
    this.templatesList = []
    this.showTemplatesProgress = true
    this.builderService.getProject(String(localStorage.getItem('selectedProject'))).subscribe((project: any) => {
      // Cache the response
      this.builderService.user_type = project.user_type
      this.builderService.cacheProjectReq.response = project
      this.database.getDatabase('templates_new').subscribe((standaloneTemplates) => {
        this.loadTemplates(standaloneTemplates, project)
        this.showTemplatesProgress = false
        if (window.screen.width > 768) {
          // 768px portrait
          document.querySelector('template-view')?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
        }
      })
    })
  }
  loadTemplates = (templates: any, project: any) => {
    for (const key in templates) {
      if (Object.prototype.hasOwnProperty.call(templates, key)) {
        const element = templates[key]
        this.templatesList.push({
          img: element.template_image,
          name: element.template_name,
          project: element.project,
          template_id: element.template_id,
          template_category: element.template_category
        })
      }
    }
  }

  ngOnInit(): void {}

  selectNewTemplate = (template: any) => {
    if (this.builderService.getTour()) {
      this.builderService.showUpdateProgress = true
      console.log(template)
      this.database.setDatabase('projects/' + this.builderService.getSelectedProject(), template.project).subscribe((response) => {
        this.router.navigate(['/'], { relativeTo: this.route })
      })
    } else {
      if (confirm('Are you sure you want to select this template?, This replaces all your views with the selected template views')) {
        this.versions.setVersion(this.builderService.getSelectedProject(), 'Auto Version : ' + new Date().toUTCString()).subscribe((response: any) => {
          this.database.setDatabase('projects/' + this.builderService.getSelectedProject(), template.project).subscribe((response) => {
            this.router.navigate(['/'], { relativeTo: this.route })
          })
        })
      }
    }
    this.mixPanel.track('Template Selected', {
      projectid: this.builderService.getSelectedProject(),
      template: template.name
    })
  }
  skipTour = () => {
    this.builderService.setTour(false).subscribe(
      (response) => {
        this.router.navigate(['/'], { relativeTo: this.route })
      },
      (err) => {}
    )
  }

  howItWorksDialog = () => {
    const dialogRef = this.dialog.open(WorksDialogComponent, {
      width: '700px',
      maxWidth: '80%',
      data: { name: 'templates' }
    })

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed')
    })
  }
}
