import { Component, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTable, MatTableDataSource } from '@angular/material/table'
import { ActivatedRoute, Router } from '@angular/router'
import { Observable } from 'rxjs'
import { stripeService } from 'src/app/pages/payment/stripe.service'
import { environment } from '../../../environments/environment'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { SubscriptionFlowService } from '../subscription-flow.service'
import { SubscribersService } from 'src/app/subscribers/subscribers.service'
import { AdminDashboardService } from 'src/app/admin/admin-dashboard/admin-dashboard.service'

export interface subscribersTableColumns {
  type: any
  customer_email: string
  currency: string
  status: string
  invoice_date: any
  retrials: any
  Package: any
  end_date: string
  action: string
  total_amount: any
  payment_method:string
}

@Component({
  selector: 'app-all-subscribers',
  templateUrl: './all-subscribers.component.html',
  styleUrl: './all-subscribers.component.scss'
})
export class AllSubscribersComponent {
  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatTable) table!: MatTable<subscribersTableColumns>
  @ViewChild(MatSort, { static: true }) matSort!: MatSort

  public dataSource = new MatTableDataSource<subscribersTableColumns>([])
  pageLoaded = false

  ELEMENT_DATA: any[] = []
  mobile = false
  searchKey!: string
  
  subscribers: any
  subscribers_data_counts: any[] = []
  displayedColumns: string[] = ['status','customer_email','invoice_date','end_date','payment_method', 'type', 'duration', 'total_amount', 'action']
  stripeSubscriptionId!: string 
  manualSubscribers:any[]=[] 
  statisticsData:any[]=[];

  constructor(private subscribersService: SubscribersService,private router: Router, private route: ActivatedRoute, public subscriptionFlowService: SubscriptionFlowService, private adminDashService: AdminDashboardService, public stripeService: stripeService, private builderService: BuilderService) {}

  ngOnInit(): void {
    if (window.screen.width <= 768) {
      this.mobile = true
    }
    this.builderService.showUpdateProgress = false;

   this.fetchSubscribers()
    this.dataSource.filterPredicate = (data: subscribersTableColumns, filter: string) => {
      const searchTerm = filter.toLowerCase();
      return (
        data.customer_email?.toLowerCase().includes(searchTerm) ||
        data.status?.toLowerCase().includes(searchTerm) ||
        data.payment_method?.toLowerCase().includes(searchTerm) ||
        data.type?.toLowerCase().includes(searchTerm)
      );
    };
  }
  fetchSubscribers() {
    this.builderService.showBillingProgress = true;
  
    // Fetch manual subscribers first
    this.fetchManualSubscribers().subscribe(() => {
      // Fetch stripe subscribers
      this.adminDashService.getSubscriptions().subscribe((response:any) => {
        this.subscribers = response.slice().reverse();
        const mergedSubscribers = [...this.subscribers, ...this.manualSubscribers];
        // Render the merged data        
        this.statisticsData = [
          {name:'Total Subscribers',value: mergedSubscribers.length},
          {name:'Total Active Subscribers',value: mergedSubscribers.filter((subscriper:any) => subscriper.status=='active').length},
          {name:'Total OnHold Subscribers',value: mergedSubscribers.filter((subscriper:any) => subscriper.status=='onhold').length},
          {name:'Total Cancelled Subscribers',value: mergedSubscribers.filter((subscriper:any) => subscriper.status=='cancelled').length},

        ];    
        this.builderService.showBillingProgress = false;
        this.renderSubscribers(mergedSubscribers).subscribe((data: any) => {
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.matSort;
          if (data) {
            this.table.renderRows();
          }
        });
      });
    });
  }
  fetchManualSubscribers() {
    return new Observable((observer) => {
      this.subscribersService.getSubscribers().subscribe((response_subscribers: any) => {
        this.manualSubscribers = []; // Clear previous data
        for (const key in response_subscribers) {
          if (Object.prototype.hasOwnProperty.call(response_subscribers, key)) {
            const element = response_subscribers[key];
            let date: any = element.dateOfSub ? String(element.dateOfSub) : '';  
  
            this.manualSubscribers.push({
              id: key,
              customer_email: element.subscriberEmail,
              name: element.subscriberName,
              type: element.subscriptionPlan,
              end_date: element.renewalDate,
              total_amount: element.subscriptionPrice,
              start_date: date ? date.replaceAll("-", "/") : '',
              status: element.status,
              currency: element.currency,
              payment_method: 'manual'
            });
          }
        }
        observer.next();
        observer.complete();
      });
    });
  }
  
  renderSubscribers(result: any) {
    return new Observable<any>((observer) => {
      this.ELEMENT_DATA = [];
      
      result.forEach((subscription: any) => {
        this.ELEMENT_DATA.push({
          id:subscription.id,
          customer_email:subscription.customer_email ? subscription.customer_email :subscription.customer_data?.email,
          Package: subscription.package?.price_id || '',
          type: subscription.type || subscription.package?.type,
          duration: subscription.package?.period || '',
          status: subscription.status,
          start_date: subscription.start_date || '',
          end_date: subscription.end_date || this.calcSubscriptionNextPaymentDate(subscription),
          stripe_subscription_id: subscription.stripe_subscription_id || '',
          total_amount: subscription.total_amount || 
                        (this.subscriptionFlowService.getPricingPackageById(subscription.package?.price_id)?.price 
                          ? this.subscriptionFlowService.getPricingPackageById(subscription.package?.price_id)?.price + ' ' +
                            this.subscriptionFlowService.getPricingPackageById(subscription.package?.price_id)?.currency 
                          : ''),
          payment_method: subscription.payment_method || (subscription.stripe_subscription_id ? 'stripe' : 'manual'),
          action: ''
        });
      });
  
      // Sorting by latest start_date
       this.ELEMENT_DATA.sort((a, b) => {
        const dateA = new Date(a.start_date).getTime();
        const dateB = new Date(b.start_date).getTime();
        return dateB - dateA; // Sort descending (latest start_date first)
      });
      // Update the table data
      this.dataSource.data = this.ELEMENT_DATA;
      observer.next();
      observer.complete();
    });
  }

  calcSubscriptionNextPaymentDate(subscription: any) {
    switch (subscription.package.period) {
      case 'monthly':
        let startDateM = new Date(subscription.start_date); // Convert start_date to Date object
        startDateM.setMonth(startDateM.getMonth() + 1); // Add one month
        const fullDateMonthely = startDateM.toLocaleString(); // Convert the date back to a string
        return fullDateMonthely;

      case 'quarterly':
        let startDateQr = new Date(subscription.start_date); // Convert start_date to Date object
        startDateQr.setMonth(startDateQr.getMonth() + 3); // Add one month
        const fullDateQr = startDateQr.toLocaleString(); // Convert the date back to a string
        return fullDateQr;

       case 'yearly':  
       let yearlyStartDate = new Date(subscription.start_date); // Convert start_date to Date object
       yearlyStartDate.setFullYear(yearlyStartDate.getFullYear() + 1); // Add one year
       const fullDateYearly=yearlyStartDate.toLocaleString(); // Convert the date back to a string    
       return fullDateYearly;
       
       
       default:
        return subscription.start_date; // Default case if none match
    
      }
  }


  editSubscriber(id: string) {
    if (id.startsWith("sub_")) {
    this.router.navigate([`/subscribers/info/${id}`], { relativeTo: this.route })
    }else{
     this.router.navigate([`/admin/newSub/${id}`], { relativeTo: this.route })
    }


  }
  
  applyFilter() {
    this.dataSource.filter = this.searchKey.trim().toLowerCase();
  }
 
}
