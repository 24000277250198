<div id="loginLogo">
  <img src="assets/images/stacks.png" />
</div>
<div id="middleContainerWrapper">
  <div class="header">
    <button class="langSwitcher" mat-button [matMenuTriggerFor]="menu">
      <!-- Display flag or language code based on selected language -->
      <span *ngIf="languageService.getLanguage() === 'en'">🇺🇸 English</span>
      <span class="arabicText" *ngIf="languageService.getLanguage() === 'ar'">العربية 🇸🇦</span>
    </button>
    <mat-menu #menu="matMenu">
      <!-- Language options -->
      <button mat-menu-item *ngIf="languageService.selectedLanguage === 'ar'" (click)="changeLang('en')">🇺🇸 English</button>
      <button class="arabicText" mat-menu-item *ngIf="languageService.selectedLanguage === 'en'" (click)="changeLang('ar')">🇸🇦 العربية</button>
    </mat-menu>
  </div>
  <mat-progress-bar mode="indeterminate" *ngIf="loginProgress"></mat-progress-bar>
  <div class="loginContainer">
    <form id="loginContainer" [formGroup]="loginForm" (ngSubmit)="onSubmit()">
      <h1 [ngClass]="{ 'arabicTextBold arabicText': languageService.selectedLanguage === 'ar' }">{{ 'Welcome Back' | translate }}</h1>
      <h2 [ngClass]="{ arabicText: languageService.selectedLanguage === 'ar' }" *ngIf="paymentIncluded">{{ 'Login to Claim offer' | translate }}</h2>
      <!-- <div class="socialLogin" (click)="signInWithGoogle()">
            <img src="assets/images/google_login.png" />
            <p>Sign in with google</p>
        </div> -->
      <ul>
        <li>
          <mat-error *ngIf="loginError">
            {{ loginErrorMsg }}
          </mat-error>
        </li>
        <li>
          <mat-form-field appearance="outline">
            <mat-label [ngClass]="{ arabicText: languageService.selectedLanguage === 'ar' }">{{ 'Email' | translate }}</mat-label>
            <input matInput placeholder="" formControlName="username" type="text" />
          </mat-form-field>
        </li>
        <li>
          <mat-form-field appearance="outline">
            <mat-label [ngClass]="{ arabicText: languageService.selectedLanguage === 'ar' }">{{ 'Password' | translate }}</mat-label>
            <input matInput type="{{ isPasswordVisible ? 'text' : 'password' }}" formControlName="password" />
            <button type="button" class="toggle-password-btn" (click)="togglePasswordVisibility()" aria-label="Toggle password visibility">
              <mat-icon>{{ isPasswordVisible ? 'visibility_off' : 'visibility' }}</mat-icon>
            </button>
          </mat-form-field>
        </li>
      </ul>
      <button [ngClass]="{ arabicText: languageService.selectedLanguage === 'ar' }" class="submitLogin" type="submit">
        {{ 'Login' | translate }}
      </button>
      <asl-google-signin-button shape="rectangular" size="large" type="standard" style="margin-top: 17px; align-self: center"></asl-google-signin-button>
      <a class="altAccess" [routerLink]="['/signup']" *ngIf="preservedReturnURL" [queryParams]="{ returnUrl: preservedReturnURL }"
        >{{ 'Dont have account' | translate }} <span>{{ 'Signup' | translate }}</span></a
      >
      <a class="altAccess" [routerLink]="['/signup']" *ngIf="!preservedReturnURL"
        >{{ 'Dont have account' | translate }} <span>{{ 'Signup' | translate }}</span></a
      >
      <a class="forgotPass" target="_blank" href="https://builder.stacksmarket.co/wp-login.php?action=lostpassword">{{ 'Forgot Password' | translate }}</a>
    </form>
  </div>
</div>
