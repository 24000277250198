import { Injectable } from '@angular/core'
import { ActivatedRoute, CanActivate, Router, RouterStateSnapshot } from '@angular/router'
import { LoginService } from '../login/login.service'

@Injectable({
  providedIn: 'root'
})
export class AdminauthGuard implements CanActivate {
  constructor(
    private loginService: LoginService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  canActivate(route: any, state: RouterStateSnapshot) {
    if (this.loginService.getCurrentUser().admin) return true

    this.router.navigate(['/'], { queryParams: { returnUrl: state.url } })
    return false
  }
}
