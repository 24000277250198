import { Component, OnInit, ViewChild } from '@angular/core'
import { MatTableDataSource } from '@angular/material/table'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { DatabaseService } from 'src/app/builder-services/database.service'

export interface AppSample {
  id: string
  activation: boolean
  img: string
  name: string
  projectId: number | null
  creationDate: string
  // category?: string;
  [key: string]: any
}
@Component({
  selector: 'app-dynamic-samples',
  templateUrl: './dynamic-samples.component.html',
  styleUrls: ['./dynamic-samples.component.scss']
})
export class DynamicSamplesComponent implements OnInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatSort) sort!: MatSort
  displayedColumns: string[] = ['img', 'name', 'projectId', 'creationDate', 'activation', 'action']
  appSamples: AppSample[] = []
  dataSource = new MatTableDataSource<AppSample>([])
  isAdding: boolean = false
  // categories: string[] = ['fashion', 'kitchen', 'electronics', 'restaurants', 'furniture', 'book', 'pharmacy', 'shop'];
  newSample: AppSample = {
    id: '',
    img: '',
    name: '',
    projectId: null,
    // category: '',
    creationDate: new Date().toLocaleDateString(),
    activation: true
  }
  constructor(private db: DatabaseService) {
    this.dataSource.filterPredicate = (data: AppSample, filter: string) => {
      const filterLower = filter.trim().toLowerCase()
      const projectIdStr = data.projectId?.toString() || '' // Convert projectId to string for searching
      return projectIdStr.includes(filterLower) || data.name.toLowerCase().includes(filterLower)
    }
  }

  ngOnInit() {
    this.loadAppSamples()
  }
  /**
   * Set up the paginator, sort, and default sorting for the table after the view is initialized
   */
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator
    this.dataSource.sort = this.sort
    this.dataSource.sortingDataAccessor = (data: AppSample, sortHeaderId: string) => {
      if (sortHeaderId === 'projectId') {
        return data.projectId || 0
      }
      return data[sortHeaderId as keyof AppSample] as string | number
    }
    this.dataSource.sort.active = 'projectId'
    this.dataSource.sort.direction = 'desc'
    this.dataSource.sort.sortChange.emit()
  }
  /**
   * Load app samples from the 'samples/' path in the database and populate the table
   */
  loadAppSamples() {
    this.db.getDatabase('samples/').subscribe(
      (response: any) => {
        if (response) {
          this.appSamples = Object.entries(response).map(([key, value]: [string, any]) => {
            return {
              id: key,
              ...value
            } as AppSample
          })
          this.dataSource.data = this.appSamples
        } else {
          this.appSamples = []
          this.dataSource.data = []
        }
      },
      (error) => {
        console.error('Error loading app samples:', error)
      }
    )
  }
  /**
   * Add a new sample to the database if the project ID exists and is not a duplicate
   */
  addSample() {
    if (this.isAdding) return
    this.isAdding = true
    this.db.getDatabase(`projects/${this.newSample.projectId}`).subscribe(
      (projectResponse: any) => {
        if (!Object.keys(projectResponse).length) {
          alert(`Project ID ${this.newSample.projectId} does not exist.`)
          this.isAdding = false
          return
        }

        const isDuplicate = this.appSamples.some((sample) => sample.projectId === this.newSample.projectId)

        if (isDuplicate) {
          alert('This Project ID already exists. Please use a different Project ID.')
          this.isAdding = false
          return
        }

        this.db.getDatabase(`projects/${this.newSample.projectId}/settings/general_settings`).subscribe(
          (response: any) => {
            if (response) {
              const sampleData: AppSample = {
                img: response.icon || 'No Icon',
                name: response.application_name || 'Unnamed App',
                projectId: this.newSample.projectId,
                creationDate: this.newSample.creationDate,
                activation: this.newSample.activation,
                id: ''
              }
              const sampleId = `sample_${Date.now()}`
              sampleData.id = sampleId
              // Commented code for adding category (uncomment to enable)
              // if (this.newSample.category) {
              //   sampleData.category = this.newSample.category || ''; // Add category if provided
              // }
              this.db.setDatabase(`samples/${sampleId}`, sampleData).subscribe(
                (response: any) => {
                  const newSampleWithId: AppSample = { ...sampleData }
                  this.appSamples = [...this.appSamples, newSampleWithId]
                  this.dataSource.data = this.appSamples
                  this.isAdding = false
                  this.loadAppSamples()
                },
                (error) => {
                  console.error('Error adding sample:', error)
                  this.isAdding = false
                }
              )
            }
          },
          (error) => {
            console.error('Error fetching project settings:', error)
            this.isAdding = false
          }
        )
      },
      (error) => {
        console.error('Error checking project existence:', error)
        this.isAdding = false
      }
    )
  }
  /**
   * Toggle the activation status of a sample in the database and update the UI
   */
  toggleActivation(sample: AppSample) {
    const sampleId = sample.id
    const updatedData: AppSample = {
      ...sample,
      activation: !sample.activation
    }
    this.db.setDatabase(`samples/${sampleId}`, updatedData).subscribe(
      (response: any) => {
        this.loadAppSamples()
      },
      (error) => {
        console.error('Error updating activation:', error)
      }
    )
  }
  /**
   * Delete a sample from the database after user confirmation with project ID and name
   */
  deleteSample(sample: AppSample) {
    const sampleId = sample.id
    const confirmationMessage = `Are you sure you want to delete project ${sample.projectId} - ${sample.name}?`

    if (confirm(confirmationMessage)) {
      this.db.removeDatabaseEndpoint(`samples/${sampleId}`).subscribe(
        () => {
          this.loadAppSamples()
        },
        (error) => {
          console.error('Error deleting sample:', error)
        }
      )
    } else {
      console.log('Deletion cancelled by user.')
    }
  }
  /**
   * Filter the table data by project ID or app name
   */
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
    this.dataSource.filter = filterValue.trim().toLowerCase()

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage()
    }
  }
}
