import { Component } from '@angular/core'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { DatabaseService } from 'src/app/builder-services/database.service'
import { Router } from '@angular/router'
import { AppUsersService } from '../services/app-users.service'
export interface User {
  firstName: string
  lastName: string
  emailAddress: string
  phoneNumber: number
  discountType: 'percentage' | 'specific_amount'
}
@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrl: './create-user.component.scss'
})
export class CreateUserComponent {
  hidePassword: boolean = true
  editPage: boolean = false
  userId: any
  user = {
    firstName: '',
    lastName: '',
    emailAddress: '',
    phoneNumber: '',
    password: '',
    selectedRole: '',
    registrationDate: new Date().toISOString().split('T')[0]
  }
  constructor(
    private db: DatabaseService,
    private builderService: BuilderService,
    private router: Router,
    public appUsersService: AppUsersService
  ) {  }

  generateRandomId(): string {
    return Date.now().toString(36) + Math.random().toString(36).substring(2, 8)
  }
  onSubmit(formValue: any) {
    if (!formValue.firstName || !formValue.lastName || !formValue.emailAddress || !formValue.password) {
      console.error('Please fill in all required fields.')
      return
    }

    const newUser = {
      firstName: formValue.firstName,
      lastName: formValue.lastName,
      emailAddress: formValue.emailAddress,
      phoneNumber: formValue.phoneNumber,
      password: formValue.password,
      selectedRole: formValue.selectedRole,
      registrationDate: new Date().toISOString().split('T')[0]
    }

    this.appUsersService.saveUser(newUser).subscribe(
      (response) => {
        let result = JSON.parse(response)
        if (result.success === false) {
          alert('Error creating user: ' + result.message)
          console.error('Error creating user:', result.message)
          return
        }
        console.log('User created successfully:', result)
        this.router.navigate(['/users']) // Redirect after success
      },
      (error) => {
        console.error('Error creating user:', error)
      }
    )
  }
}
