<div class="containerWrapper">
  <div class="innerWrapper">
    <div class="statisticsWrapper">
      <div class="statisticHeader">
        <h1>{{ 'Overview' | translate }}</h1>
      </div>
      <div class="statisContent">
        <div class="statisticItem" *ngFor="let item of statisticsData">
          <h3>{{ item.name | translate }}</h3>
          <p>{{ item.value }}</p>
        </div>
      </div>
    </div>

    <div class="tableWrapper">
      <ng-container *ngIf="users.length !== 0">
        <div class="tableHeader">
          <h1>{{ 'User List' | translate }}</h1>
          <div class="headerBtns">
            <form class="searchBar">
              <input (input)="applyFilter()" [(ngModel)]="searchText" data-search matInput name="searchText" placeholder="{{ 'Search' | translate }}" type="text" />
            </form>
          </div>
        </div>
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8">
          <ng-container matColumnDef="nickname">
            <th mat-header-cell *matHeaderCellDef class="firstTitle">{{ 'User Name' | translate }}</th>
            <td mat-cell *matCellDef="let elements">{{ elements.nickname }}</td>
          </ng-container>

          <ng-container matColumnDef="email">
            <th mat-header-cell *matHeaderCellDef class="firstTitle">{{ 'Email' | translate }}</th>
            <td mat-cell *matCellDef="let elements">{{ elements.email }}</td>
          </ng-container>

          <ng-container matColumnDef="date">
            <th mat-header-cell *matHeaderCellDef class="firstTitle">{{ 'Registration Date' | translate }}</th>
            <td mat-cell *matCellDef="let elements">{{ elements.date }}</td>
          </ng-container>

          <ng-container matColumnDef="email_verified">
            <th mat-header-cell *matHeaderCellDef class="firstTitle">{{ 'Email Verified' | translate }}</th>
            <td mat-cell *matCellDef="let elements" [ngStyle]="elements.email_verified ? { color: '#45ae6b' } : { color: '#de606f' }">{{ elements.email_verified }}</td>
          </ng-container>

          <ng-container matColumnDef="user_role">
            <th mat-header-cell *matHeaderCellDef class="firstTitle">{{ 'User Role' | translate }}</th>
            <td mat-cell *matCellDef="let elements">{{ getRoleLabel(elements.role) }}</td>
          </ng-container>

          <ng-container matColumnDef="action">
            <th mat-header-cell *matHeaderCellDef class="smallHeader firstTitle lastTitle">{{ 'action' | translate }}</th>
            <td mat-cell *matCellDef="let elements">
              <button mat-icon-button (click)="editUser(elements.user_id)" class="viewOrder">
                <span class="mat-button-wrapper">
                  <span class="material-icons-outlined"> visibility </span>
                </span>
              </button>
              <button mat-icon-button (click)="deleteUser(elements.user_id, elements.nickname)">
                <span class="mat-button-wrapper">
                  <span class="material-icons material-icons-outlined deleteIcon"> delete </span>
                </span>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="userColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: userColumns"></tr>
        </table>

        <mat-paginator class="tablePaginator" [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons aria-label="Select page of periodic dataSource"> </mat-paginator>
      </ng-container>
      <div *ngIf="users.length === 0">
        <p class="noOrders">{{ 'No Users Yet' | translate }}</p>
      </div>
    </div>
    <div class="createUserWrapper">
      <button class="stacksBtn blue" [routerLink]="['/create-user']">Create User</button>
    </div>
  </div>
</div>
