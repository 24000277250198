import { environment } from 'src/environments/environment'
import { CanActivate, Router, ActivatedRouteSnapshot } from '@angular/router'
import { BuilderService } from '../builder-services/builder.service'
import { LoginService } from '../login/login.service'
import { DatabaseService } from '../builder-services/database.service'
import { Injectable } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { WorksDialogComponent } from '../pages/works-dialog/works-dialog.component'

@Injectable({
  providedIn: 'root'
})
export class restrictedPagesGuard implements CanActivate {
  user_email: string = ''
  projectAccess: any
  packages = environment.packages
  packageType: string = ''
  project: any
  matchingPackage: any
  constructor(
    private router: Router,
    private builderService: BuilderService,
    private db: DatabaseService,
    private loginService: LoginService,
    public dialog: MatDialog
  ) {
    this.user_email = loginService.getCurrentUser().email
    this.project = this.builderService.cacheProjectReq.response
    setTimeout(() => {
      this.project = this.builderService.cacheProjectReq.response
    }, 2000)
  }
//**
// a method to check the user access and return if the user is allowed to use this page or not
// @params projectAccess(free or purchased), currentPage
// @return boolean 
// */
  isPageRestricted(projectAccess: string, currentPage: string): boolean {
    if ((projectAccess === 'free' && this.project?.details[0]?.status !== 'cancelled') || this.loginService.getCurrentUser().admin) {
      return false
    }
    // get the current package of the user from the environment to see the restricted pages
    for (const pkg of this.packages) {
      if (pkg.access === projectAccess) {
        this.matchingPackage = pkg
      }
    }
    if (!this.matchingPackage) {
      return false
    }
    // first check if the project is premium(purchased), then get the package type (starter, growth)
    // in the environment there is two package ( free and full ) and the full got the subPackages which are starter & growth
    if (this.project.premium) {
      this.packageType = JSON.parse(this.project.premium[0].package).type
      if (this.matchingPackage.subPackages && this.matchingPackage.subPackages.length > 0) {
        // matchingSubPackage is starter or growth
        const matchingSubPackage = this.matchingPackage.subPackages.find((subPkg: any) => subPkg.type === this.packageType)
        if (matchingSubPackage) {
          // get the restricted pages
          const subRestrictedPages = matchingSubPackage.restricted_pages || []
          // if the restricted pages got the current page return true (the page is restricted)
          if (subRestrictedPages.includes(currentPage)) {
            console.log(`Access restricted on sub-package for page: ${currentPage}`)
            return true
          }
        } else {
          console.log(`No matching sub-package for projectAccess: ${projectAccess}`)
        }
      }
    } else {
      // this else for the free package if we want to put a restricted pages to the free 
      const mainRestrictedPages = this.matchingPackage.restricted_pages || []
      if (mainRestrictedPages.includes(currentPage)) {
        console.log(`Access restricted on main package for page: ${currentPage}`)
        return true
      }
    }
    return false
  }

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    try {
      const currentUser = this.loginService.getCurrentUser()

      if (currentUser?.admin) {
        return true
      }
      const currentPage = route.routeConfig?.path || ''
      this.projectAccess = this.project?.details[0]?.access
      if (this.isPageRestricted(this.projectAccess, currentPage)) {
        console.log('Access restricted to this page.')
        this.howItWorksDialog()

        return false
      }
      console.log('Access granted to this page.')
      return true
    } catch (error) {
      console.error('Error in canActivate:', error)
      return false
    }
  }
  howItWorksDialog = () => {
    let dialogRef = this.dialog.open(WorksDialogComponent, {
      width: '1050px',
      maxWidth: '90%',
      data: { name: 'restrictedPage' }
    })

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed')
    })
  }
}
