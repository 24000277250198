import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { HelpersService } from 'src/app/helpers.service'

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  private builderUrl = ''

  projects = {}
  selectedProject = 0
  headerBtn = ''
  options = {
    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  }
  token: any
  constructor(
    private http: HttpClient,
    private helper: HelpersService,
    private builderService: BuilderService
  ) {
    this.builderUrl = this.helper.getBuilderUrl()
  }

  /**
   * Get all templates from Backend
   */
  loginAsUser = (usermail: string): Observable<any> => {
    this.token = this.builderService.getToken()
    let postData = new URLSearchParams()
    postData.set('process', 'sync_ui')
    postData.set('email', usermail)
    postData.set('token', this.token)
    return this.http.post(this.builderUrl + 'loginAsUser', postData.toString(), this.options)
  }

  cancelAllBuilds = (): Observable<any> => {
    this.token = this.builderService.getToken()
    let postData = new URLSearchParams()
    postData.set('token', this.token)
    return this.http.post(this.builderUrl + 'cancelAllBuilds', postData.toString(), this.options)
  }
}
