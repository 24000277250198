import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root'
})
export class GoogleIconsService {
  constructor() {}

  google_icons = [
    'search',
    'home',
    'account_circle',
    'settings',
    'done',
    'info',
    'check_circle',
    'delete',
    'shopping_cart',
    'visibility',
    'favorite',
    'logout',
    'description',
    'favorite_border',
    'lock',
    'schedule',
    'language',
    'face',
    'help_outline',
    'fingerprint',
    'manage_accounts',
    'filter_alt',
    'event',
    'thumb_up',
    'verified',
    'dashboard',
    'calendar_today',
    'login',
    'list',
    'visibility_off',
    'check_circle_outline',
    'date_range',
    'highlight_off',
    'help',
    'article',
    'question_answer',
    'paid',
    'task_alt',
    'lightbulb',
    'shopping_bag',
    'open_in_new',
    'perm_identity',
    'trending_up',
    'credit_card',
    'history',
    'account_balance',
    'delete_outline',
    'report_problem',
    'fact_check',
    'assignment',
    'verified_user',
    'arrow_right_alt',
    'star_rate',
    'account_balance_wallet',
    'autorenew',
    'print',
    'work',
    'view_list',
    'build',
    'today',
    'analytics',
    'store',
    'delete_forever',
    'admin_panel_settings',
    'savings',
    'lock_open',
    'room',
    'code',
    'grade',
    'update',
    'receipt',
    'add_shopping_cart',
    'watch_later',
    'power_settings_new',
    'contact_support',
    'pets',
    'done_all',
    'explore',
    'bookmark',
    'reorder',
    'bookmark_border',
    'account_box',
    'note_add',
    'shopping_basket',
    'pending_actions',
    'payment',
    'drag_indicator',
    'launch',
    'supervisor_account',
    'touch_app',
    'pending',
    'zoom_in',
    'thumb_up_off_alt',
    'assessment',
    'done_outline',
    'exit_to_app',
    'open_in_full',
    'leaderboard',
    'preview',
    'assignment_ind',
    'card_giftcard',
    'view_in_ar',
    'work_outline',
    'feedback',
    'timeline',
    'published_with_changes',
    'swap_horiz',
    'assignment_turned_in',
    'dns',
    'android',
    'sync_alt',
    'book',
    'flight_takeoff',
    'stars',
    'pan_tool',
    'bug_report',
    'accessibility',
    'label',
    'gavel',
    'contact_page',
    'alarm',
    'cached',
    'translate',
    'supervised_user_circle',
    'get_app',
    'space_dashboard',
    'extension',
    'edit_calendar',
    'minimize',
    'add_task',
    'record_voice_over',
    'flutter_dash',
    'accessibility_new',
    'trending_flat',
    'hourglass_empty',
    'thumb_down',
    'help_center',
    'sticky_note_2',
    'rule',
    'support',
    'dashboard_customize',
    'source',
    'find_in_page',
    'view_headline',
    'close_fullscreen',
    'announcement',
    'settings_applications',
    'group_work',
    'loyalty',
    'redeem',
    'tips_and_updates',
    'compare_arrows',
    'swap_vert',
    'restore',
    'sensors',
    'dangerous',
    'euro_symbol',
    'grading',
    'nightlight_round',
    'privacy_tip',
    'subject',
    'track_changes',
    'copyright',
    'table_view',
    'arrow_circle_up',
    'https',
    'ads_click',
    'disabled_by_default',
    'book_online',
    'api',
    'bookmarks',
    'query_builder',
    'toc',
    'build_circle',
    'perm_media',
    'input',
    '3d_rotation',
    'backup',
    'zoom_out',
    'settings_phone',
    'circle_notifications',
    'view_module',
    'open_with',
    'arrow_circle_down',
    'perm_contact_calendar',
    'swipe',
    'card_membership',
    'file_present',
    'label_important',
    'speaker_notes',
    'wysiwyg',
    'perm_phone_msg',
    'arrow_circle_right',
    'pageview',
    'integration_instructions',
    'upgrade',
    'trending_down',
    'calendar_month',
    'g_translate',
    'class',
    'change_history',
    'accessible',
    'production_quantity_limits',
    'settings_accessibility',
    'offline_bolt',
    'model_training',
    'calendar_view_month',
    'expand',
    'aspect_ratio',
    'donut_large',
    'settings_backup_restore',
    'maximize',
    'segment',
    'schedule_send',
    'thumbs_up_down',
    'bookmark_add',
    'thumb_down_off_alt',
    'view_column',
    'alarm_on',
    'view_agenda',
    'settings_ethernet',
    'invert_colors',
    'theaters',
    'important_devices',
    'open_in_browser',
    'opacity',
    'unpublished',
    'commute',
    'addchart',
    'youtube_searched_for',
    'no_accounts',
    'view_week',
    'tour',
    'turned_in',
    'history_toggle_off',
    'shop',
    'system_update_alt',
    'arrow_circle_left',
    'question_mark',
    'settings_input_antenna',
    'mark_as_unread',
    'bookmark_added',
    'hide_source',
    'flight_land',
    'contactless',
    'plagiarism',
    'saved_search',
    'search_off',
    'not_started',
    'rocket_launch',
    'assignment_late',
    'donut_small',
    'mediation',
    'view_carousel',
    'highlight_alt',
    'anchor',
    'turned_in_not',
    'settings_input_component',
    'remove_shopping_cart',
    'flaky',
    'fit_screen',
    'settings_remote',
    'all_inbox',
    'edit_off',
    'toll',
    'view_quilt',
    'hourglass_full',
    'settings_voice',
    'lock_clock',
    'assignment_return',
    'online_prediction',
    'pregnant_woman',
    'camera_enhance',
    'percent',
    'event_seat',
    'swap_horizontal_circle',
    'next_plan',
    'restore_from_trash',
    'add_to_drive',
    'accessible_forward',
    'view_sidebar',
    'markunread_mailbox',
    'calendar_view_week',
    'vertical_split',
    'tab',
    'dynamic_form',
    'settings_power',
    'hotel_class',
    'request_page',
    'try',
    'smart_button',
    'remove_done',
    'offline_pin',
    'card_travel',
    'calendar_view_day',
    'view_stream',
    'rowing',
    'outbox',
    'data_exploration',
    'find_replace',
    'outlet',
    'compress',
    'chrome_reader_mode',
    'alarm_add',
    'assignment_returned',
    'play_for_work',
    'settings_brightness',
    'gif',
    'http',
    'spellcheck',
    'backup_table',
    'credit_card_off',
    'wifi_protected_setup',
    'settings_overscan',
    'restore_page',
    'settings_input_composite',
    'quickreply',
    'comment_bank',
    'polymer',
    'view_day',
    'swap_vertical_circle',
    'line_weight',
    'new_label',
    'token',
    'cancel_schedule_send',
    'send_and_archive',
    'batch_prediction',
    'generating_tokens',
    'outbound',
    'horizontal_split',
    'settings_bluetooth',
    'picture_in_picture',
    'code_off',
    'bookmark_remove',
    'filter_alt_off',
    'balance',
    'currency_exchange',
    'flip_to_front',
    'shop_two',
    'speaker_notes_off',
    'perm_scan_wifi',
    'perm_data_setting',
    'fax',
    'eject',
    'shopping_cart_checkout',
    'free_cancellation',
    'settings_cell',
    'gif_box',
    'settings_input_hdmi',
    'alarm_off',
    'hourglass_disabled',
    'disabled_visible',
    'sensors_off',
    'app_blocking',
    'switch_access_shortcut',
    'work_off',
    'line_style',
    'perm_device_information',
    'picture_in_picture_alt',
    'subtitles_off',
    'rocket',
    'update_disabled',
    'settings_input_svideo',
    'voice_over_off',
    'lock_reset',
    'flip_to_back',
    'view_array',
    'switch_access_shortcut_add',
    'open_in_new_off',
    'all_out',
    'rounded_corner',
    'label_off',
    'text_rotate_vertical',
    'tab_unselected',
    'satellite_alt',
    'explore_off',
    'private_connectivity',
    'text_rotation_none',
    'density_medium',
    'shop_2',
    'not_accessible',
    'view_timeline',
    'perm_camera_mic',
    'pin_invoke',
    'extension_off',
    'terminal',
    'pin_end',
    'text_rotate_up',
    'add_card',
    'text_rotation_angledown',
    'text_rotation_down',
    'text_rotation_angleup',
    'webhook',
    'swipe_left',
    '123',
    'app_shortcut',
    'html',
    'assured_workload',
    'join_full',
    'swipe_right',
    'display_settings',
    'install_desktop',
    'swipe_up',
    'javascript',
    'event_repeat',
    'pan_tool_alt',
    'join_inner',
    'swipe_down',
    'install_mobile',
    'output',
    'abc',
    'data_thresholding',
    'join_left',
    'commit',
    'density_small',
    'pinch',
    'view_cozy',
    'view_kanban',
    'swipe_vertical',
    'join_right',
    'css',
    'swipe_right_alt',
    'view_comfy_alt',
    'view_compact_alt',
    'swipe_down_alt',
    'swipe_up_alt',
    'php',
    'swipe_left_alt',
    'density_large',
    'browse_gallery',
    'network_ping',
    'noise_control_off',
    'hls',
    'hls_off',
    'manage_history',
    'lightbulb_circle',
    'noise_aware',
    'spatial_audio_off',
    'spatial_tracking',
    'spatial_audio',
    'on_device_training',
    'close',
    'menu',
    'expand_more',
    'arrow_back',
    'chevron_right',
    'arrow_forward_ios',
    'arrow_back_ios',
    'arrow_drop_down',
    'cancel',
    'more_vert',
    'chevron_left',
    'arrow_forward',
    'check',
    'expand_less',
    'more_horiz',
    'refresh',
    'apps',
    'payments',
    'arrow_upward',
    'east',
    'campaign',
    'arrow_back_ios_new',
    'arrow_downward',
    'arrow_right',
    'menu_open',
    'double_arrow',
    'fullscreen',
    'unfold_more',
    'maps_home_work',
    'arrow_drop_up',
    'west',
    'arrow_left',
    'south',
    'home_work',
    'north',
    'first_page',
    'fullscreen_exit',
    'north_east',
    'arrow_drop_down_circle',
    'last_page',
    'expand_circle_down',
    'unfold_less',
    'subdirectory_arrow_right',
    'legend_toggle',
    'south_east',
    'app_settings_alt',
    'assistant_direction',
    'subdirectory_arrow_left',
    'north_west',
    'waterfall_chart',
    'switch_left',
    'south_west',
    'switch_right',
    'pivot_table_chart',
    'apps_outage',
    'offline_share',
    'person',
    'notifications',
    'groups',
    'people',
    'share',
    'person_outline',
    'school',
    'person_add',
    'public',
    'emoji_events',
    'group',
    'notifications_active',
    'engineering',
    'construction',
    'people_alt',
    'group_add',
    'whatsapp',
    'psychology',
    'health_and_safety',
    'travel_explore',
    'emoji_emotions',
    'notifications_none',
    'sports_esports',
    'thumb_up_alt',
    'water_drop',
    'sentiment_satisfied',
    'location_city',
    'ios_share',
    'emoji_objects',
    'precision_manufacturing',
    'person_add_alt',
    'sentiment_very_satisfied',
    'military_tech',
    'science',
    'history_edu',
    'cake',
    'sentiment_dissatisfied',
    'coronavirus',
    'emoji_people',
    'sentiment_very_dissatisfied',
    'self_improvement',
    'person_remove',
    'poll',
    'female',
    'sports_soccer',
    'domain',
    'whatshot',
    'recommend',
    'people_outline',
    'mood',
    'male',
    'person_off',
    'masks',
    'workspace_premium',
    'sentiment_neutral',
    'connect_without_contact',
    'person_add_alt_1',
    'back_hand',
    'architecture',
    'hiking',
    'recycling',
    'notifications_off',
    'waving_hand',
    'luggage',
    'thumb_down_alt',
    'mood_bad',
    'front_hand',
    'emoji_nature',
    'catching_pokemon',
    'switch_account',
    'nights_stay',
    'king_bed',
    'emoji_symbols',
    'sports_basketball',
    'notification_add',
    'sports',
    'sports_kabaddi',
    'vaccines',
    'emoji_food_beverage',
    'sick',
    'reduce_capacity',
    'emoji_transportation',
    'interests',
    'transgender',
    'social_distance',
    'sports_tennis',
    'real_estate_agent',
    'deck',
    'elderly',
    'outdoor_grill',
    'clean_hands',
    'add_moderator',
    'follow_the_signs',
    'piano',
    'sanitizer',
    'plus_one',
    'fireplace',
    'handshake',
    'surfing',
    'sports_motorsports',
    'cookie',
    'sports_handball',
    'add_reaction',
    'edit_notifications',
    'sports_baseball',
    'cruelty_free',
    'pages',
    'man',
    'sports_volleyball',
    'heart_broken',
    'sports_football',
    'woman',
    'kayaking',
    'downhill_skiing',
    'skateboarding',
    'single_bed',
    'public_off',
    'personal_injury',
    'compost',
    'safety_divider',
    'remove_moderator',
    'notifications_paused',
    'pix',
    'person_remove_alt_1',
    'hive',
    'nordic_walking',
    'sports_cricket',
    '6_ft_apart',
    'sports_mma',
    'co2',
    'paragliding',
    'sports_golf',
    'group_remove',
    'snowboarding',
    'group_off',
    'party_mode',
    'kitesurfing',
    'snowshoeing',
    'fitbit',
    'ice_skating',
    'sports_rugby',
    'sports_hockey',
    'sledding',
    'scale',
    'sports_martial_arts',
    'south_america',
    'no_luggage',
    'boy',
    'piano_off',
    'domain_add',
    'girl',
    'elderly_woman',
    'sports_gymnastics',
    'scoreboard',
    'scuba_diving',
    'roller_skating',
    'thunderstorm',
    'cyclone',
    'sign_language',
    'tsunami',
    'volcano',
    'flood',
    'landslide',
    'severe_cold',
    'add',
    'add_circle_outline',
    'add_circle',
    'content_copy',
    'send',
    'clear',
    'mail',
    'save',
    'link',
    'remove',
    'filter_list',
    'inventory_2',
    'insights',
    'sort',
    'remove_circle_outline',
    'inventory',
    'bolt',
    'reply',
    'flag',
    'add_box',
    'create',
    'remove_circle',
    'push_pin',
    'block',
    'calculate',
    'undo',
    'how_to_reg',
    'content_paste',
    'file_copy',
    'report',
    'shield',
    'backspace',
    'archive',
    'save_alt',
    'policy',
    'tag',
    'change_circle',
    'redo',
    'content_cut',
    'forward',
    'inbox',
    'outlined_flag',
    'drafts',
    'link_off',
    'biotech',
    'ballot',
    'stacked_bar_chart',
    'markunread',
    'delete_sweep',
    'report_gmailerrorred',
    'square_foot',
    'stream',
    'where_to_vote',
    'add_link',
    'copy_all',
    'move_to_inbox',
    'waves',
    'dynamic_feed',
    'unarchive',
    'reply_all',
    'select_all',
    'low_priority',
    'text_format',
    'font_download',
    'weekend',
    'upcoming',
    'gesture',
    'how_to_vote',
    'attribution',
    'content_paste_search',
    'next_week',
    'content_paste_off',
    'save_as',
    'content_paste_go',
    'report_off',
    'filter_list_off',
    'font_download_off',
    'flag_circle',
    'deselect',
    'edit',
    'navigate_next',
    'photo_camera',
    'image',
    'picture_as_pdf',
    'tune',
    'circle',
    'receipt_long',
    'timer',
    'auto_stories',
    'navigate_before',
    'add_a_photo',
    'collections',
    'auto_awesome',
    'palette',
    'remove_red_eye',
    'music_note',
    'wb_sunny',
    'add_photo_alternate',
    'brush',
    'flash_on',
    'euro',
    'auto_fix_high',
    'control_point',
    'adjust',
    'looks_one',
    'style',
    'camera',
    'camera_alt',
    'straighten',
    'photo_library',
    'audiotrack',
    'portrait',
    'video_camera_front',
    'rotate_right',
    'grid_on',
    'color_lens',
    'crop_free',
    'timelapse',
    'landscape',
    'crop_square',
    'collections_bookmark',
    'slideshow',
    'lens',
    'looks_two',
    'panorama_fish_eye',
    'filter_drama',
    'auto_awesome_motion',
    'filter_vintage',
    'healing',
    'compare',
    'image_search',
    'crop',
    'rotate_left',
    'wb_incandescent',
    'looks_3',
    'blur_on',
    'center_focus_strong',
    'wb_cloudy',
    'flare',
    'dehaze',
    'face_retouching_natural',
    'auto_awesome_mosaic',
    'filter_none',
    'colorize',
    'brightness_4',
    'cases',
    'assistant',
    'nature_people',
    'filter_center_focus',
    'broken_image',
    'photo',
    'flash_off',
    'crop_original',
    'details',
    'brightness_5',
    'tag_faces',
    'brightness_6',
    'flip_camera_android',
    'grain',
    'loupe',
    'flip',
    'flip_camera_ios',
    'brightness_1',
    'currency_rupee',
    'movie_creation',
    'filter_1',
    'panorama',
    'image_not_supported',
    'add_to_photos',
    'center_focus_weak',
    'animation',
    'auto_fix_normal',
    'movie_filter',
    'view_comfy',
    'filter',
    'control_point_duplicate',
    'crop_din',
    'nature',
    'looks_4',
    'leak_add',
    'brightness_7',
    'photo_size_select_actual',
    'video_camera_back',
    'timer_off',
    'texture',
    'transform',
    'photo_camera_front',
    'view_compact',
    'incomplete_circle',
    'motion_photos_on',
    'rotate_90_degrees_ccw',
    'bedtime',
    'mic_external_on',
    'gradient',
    'looks_5',
    'music_off',
    'assistant_photo',
    'thermostat_auto',
    'photo_album',
    'exposure_plus_1',
    'hdr_strong',
    'shutter_speed',
    'filter_tilt_shift',
    'exposure',
    'filter_2',
    'hide_image',
    'looks',
    'vrpano',
    'crop_16_9',
    'flash_auto',
    'linked_camera',
    'blur_circular',
    'brightness_3',
    'motion_photos_auto',
    'brightness_2',
    'tonality',
    'looks_6',
    'wb_twilight',
    'iso',
    'photo_filter',
    'photo_size_select_small',
    'crop_portrait',
    'crop_rotate',
    'crop_7_5',
    'hdr_weak',
    'filter_hdr',
    'photo_size_select_large',
    'filter_3',
    'camera_front',
    'crop_5_4',
    'filter_frames',
    'exposure_zero',
    'camera_roll',
    'filter_b_and_w',
    'burst_mode',
    'blur_linear',
    'crop_3_2',
    'crop_landscape',
    'rotate_90_degrees_cw',
    'switch_camera',
    'switch_video',
    'contrast',
    'filter_9_plus',
    'filter_7',
    'wb_iridescent',
    'grid_off',
    'photo_camera_back',
    'exposure_plus_2',
    'filter_4',
    'motion_photos_paused',
    'monochrome_photos',
    'filter_5',
    'face_retouching_off',
    'logo_dev',
    'auto_fix_off',
    'currency_pound',
    'leak_remove',
    'wb_shade',
    'exposure_neg_1',
    'timer_10',
    'panorama_photosphere',
    'currency_yen',
    'filter_9',
    'video_stable',
    'filter_8',
    'blur_off',
    'motion_photos_off',
    'hdr_on',
    'filter_6',
    'currency_bitcoin',
    'panorama_horizontal',
    'raw_on',
    'vignette',
    'image_aspect_ratio',
    'camera_rear',
    'dirty_lens',
    '30fps_select',
    'timer_3',
    '60fps_select',
    'motion_photos_pause',
    '24mp',
    'panorama_wide_angle_select',
    'exposure_neg_2',
    'currency_ruble',
    'autofps_select',
    'panorama_photosphere_select',
    'panorama_horizontal_select',
    'panorama_wide_angle',
    'mic_external_off',
    'wb_auto',
    'hdr_plus',
    'panorama_vertical_select',
    'panorama_vertical',
    'hdr_enhanced_select',
    '12mp',
    'mp',
    'hevc',
    '23mp',
    'raw_off',
    '18mp',
    'hdr_off',
    '11mp',
    '20mp',
    '3mp',
    '10mp',
    '17mp',
    '21mp',
    '13mp',
    '5mp',
    '19mp',
    '22mp',
    '2mp',
    '8mp',
    '14mp',
    '4mp',
    '16mp',
    '15mp',
    '7mp',
    '9mp',
    '6mp',
    'deblur',
    'currency_lira',
    'currency_yuan',
    'currency_franc',
    'bedtime_off',
    'email',
    'location_on',
    'call',
    'phone',
    'business',
    'chat',
    'mail_outline',
    'vpn_key',
    'list_alt',
    'qr_code_scanner',
    'chat_bubble_outline',
    'alternate_email',
    'forum',
    'chat_bubble',
    'textsms',
    'contact_mail',
    'qr_code_2',
    'qr_code',
    'person_search',
    'sentiment_satisfied_alt',
    'message',
    'comment',
    'contacts',
    'app_registration',
    'import_contacts',
    'contact_phone',
    'live_help',
    'import_export',
    'forward_to_inbox',
    'rss_feed',
    'hourglass_bottom',
    'hourglass_top',
    'mark_email_read',
    'read_more',
    'key',
    'call_end',
    'mark_email_unread',
    'more_time',
    'document_scanner',
    'clear_all',
    'dialpad',
    'phone_enabled',
    'mark_chat_unread',
    '3p',
    'screen_share',
    'cancel_presentation',
    'call_made',
    'hub',
    'call_split',
    'unsubscribe',
    'present_to_all',
    'add_ic_call',
    'mark_chat_read',
    'stay_current_portrait',
    'domain_verification',
    'phonelink_ring',
    'call_received',
    'phonelink_lock',
    'duo',
    'phone_disabled',
    'ring_volume',
    'stay_primary_portrait',
    'phonelink_setup',
    'location_off',
    'swap_calls',
    'voicemail',
    'call_merge',
    'mobile_screen_share',
    'phonelink_erase',
    'person_add_disabled',
    'cell_wifi',
    'call_missed_outgoing',
    'domain_disabled',
    'speaker_phone',
    'stop_screen_share',
    'desktop_access_disabled',
    'call_missed',
    'co_present',
    'print_disabled',
    'pause_presentation',
    'dialer_sip',
    'rtt',
    'invert_colors_off',
    'wifi_calling',
    'portable_wifi_off',
    'spoke',
    'stay_current_landscape',
    'comments_disabled',
    'sip',
    'stay_primary_landscape',
    'nat',
    'no_sim',
    'cell_tower',
    'send_time_extension',
    'mark_unread_chat_alt',
    'key_off',
    'vpn_key_off',
    'file_download',
    'file_upload',
    'download',
    'folder',
    'grid_view',
    'upload_file',
    'cloud_upload',
    'text_snippet',
    'folder_open',
    'cloud',
    'request_quote',
    'cloud_download',
    'drive_file_rename_outline',
    'attachment',
    'upload',
    'download_for_offline',
    'create_new_folder',
    'downloading',
    'folder_shared',
    'cloud_done',
    'topic',
    'download_done',
    'cloud_queue',
    'approval',
    'cloud_off',
    'drive_file_move',
    'workspaces',
    'drive_folder_upload',
    'file_download_done',
    'newspaper',
    'attach_email',
    'rule_folder',
    'cloud_circle',
    'file_open',
    'snippet_folder',
    'file_download_off',
    'cloud_sync',
    'folder_zip',
    'drive_file_move_rtl',
    'folder_delete',
    'folder_copy',
    'difference',
    'folder_off',
    'format_overline',
    'local_shipping',
    'place',
    'menu_book',
    'local_offer',
    'map',
    'badge',
    'category',
    'restaurant',
    'directions_car',
    'volunteer_activism',
    'local_fire_department',
    'my_location',
    'local_mall',
    'near_me',
    'flight',
    'handyman',
    'directions_run',
    'restaurant_menu',
    'layers',
    'medical_services',
    'lunch_dining',
    'park',
    'local_hospital',
    'directions_walk',
    'celebration',
    'pin_drop',
    'local_library',
    'local_atm',
    'local_activity',
    'local_cafe',
    'delivery_dining',
    'rate_review',
    'person_pin',
    'play_arrow',
    'star',
    'warning',
    'attach_money',
    'support_agent',
    'keyboard_arrow_down',
    'light_mode',
    'storefront',
    'feed',
    'sensor_door'
  ]
}
