import { Injectable } from '@angular/core'
import { BuilderService } from '../../builder-services/builder.service'
import { HelpersService } from '../../helpers.service'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { DatabaseService } from '../../builder-services/database.service'
import { Observable } from 'rxjs'
import { getDatabase, ref, remove } from 'firebase/database'

@Injectable({
  providedIn: 'root'
})
export class AppUsersService {
  private builderUrl: string
  token: any
  options = {
    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  }
  constructor(
    private builderService: BuilderService,
    private helper: HelpersService,
    private http: HttpClient,
    private databaseService: DatabaseService
  ) {
    this.builderUrl = this.helper.getBuilderUrl()
  }

  /**
   * Get all the users of the Customer website
   * @param id the Project ID
   */
  getUsers = (id: any) => {
    if (this.builderService.standalone) {
      return new Observable<any>((observer) => {
        this.databaseService.getDatabase('projects/' + this.builderService.selectedProject + '/users').subscribe((response: any) => {
          const result = Object.values(response)
          observer.next(result) // Notify the observer that the operation is complete
          observer.complete()
        })
      })
    } else {
      this.token = this.builderService.getToken()
      let postData = new URLSearchParams()
      postData.set('token', this.token)
      postData.set('projectId', id)
      return this.http.post(this.builderUrl + 'get-users', postData.toString(), this.options)
    }
  }
  /**
   * Get all the users of the same project
   * @param projectId
   */
  getUsersByProjectId = (projectId: any): Observable<any> => {
    this.token = this.builderService.getToken()
    let postData = new URLSearchParams()
    postData.set('token', this.token)
    postData.set('projectId', projectId)
    return this.http.post(this.builderUrl + 'get-users-of-same-project', postData.toString(), this.options)
  }

  /**
     * Retrieves a specific user .
     * @param id - The Project ID.
     * @param userId - The User ID.
     * @returns An Observable that emits the user data.
     * If the application is in standalone mode, it retrieves the user data from the local database.
     * Otherwise, it sends a POST request to the backend API to fetch the user data.

     */
  getUser = (id: any, userId: any) => {
    if (this.builderService.standalone) {
      return new Observable<any>((observer) => {
        this.databaseService.getDatabase('projects/' + this.builderService.selectedProject + '/users/' + userId).subscribe((response: any) => {
          // const result = Object.values(response);
          console.log('from serve', response)

          observer.next(response) // Notify the observer that the operation is complete
          observer.complete()
        })
      })
    } else {
      this.token = this.builderService.getToken()
      let postData = new URLSearchParams()
      postData.set('token', this.token)
      postData.set('projectId', id)
      return this.http.post(this.builderUrl + 'get-users', postData.toString(), this.options)
    }
  }

  /**
   * Removes a user from the specified project in the database.
   * @param user_id - The ID of the user to be removed.
   * @returns An Observable that emits an empty object upon successful removal of the user.
   * The Observable completes after the operation, regardless of success or failure.
   */
  removeUser = (user_id: string) => {
    let project_id = this.builderService.selectedProject
    const db = getDatabase()
    return new Observable<any>((observer) => {
      remove(ref(db, `projects/${project_id}/users/${user_id}`))
        .then(() => {
          // Data remove successfully!
          observer.next({})
          observer.complete() // Complete the Observable
        })
        .catch((error) => {
          // The write failed...
          observer.next({})
          observer.complete() // Complete the Observable
        })
    })
  }
  
   /**
   * saves the user in the database in the same project 
   * @param user 
   * @returns 
   */

   saveUser(user: any): Observable<any> {
    const projectId = this.builderService.selectedProject

    if (!projectId) {
      console.error('No project selected!')
    }

    let postData = new URLSearchParams()
    postData.set('email', user.emailAddress)
    postData.set('password', user.password)
    postData.set('firstname', user.firstName)
    postData.set('lastname', user.lastName)
    postData.set('phone_number', user.phoneNumber)
    postData.set('type', 'client')
    postData.set('role', user.selectedRole)
    postData.set('projectId', projectId)

    return this.http.post(this.builderUrl + 'create_user_in_project', postData.toString(), this.options)
  }

  /**
   * Get a specific WP user by meta 'user_id'
   */
  getStacksUser(userId: string): Observable<any> {
    this.token = this.builderService.getToken()
    let postData = new URLSearchParams()
    postData.set('token', this.token)
    postData.set('user_id', userId)
    return this.http.post(this.builderUrl + 'get-user-by-id', postData.toString(), this.options)
  }

  /**
   * Update user role
   */

  updateUserRole = (userId: string, new_role: string): Observable<any> => {
    this.token = this.builderService.getToken()
    let postData = new URLSearchParams()
    postData.set('token', this.token)
    postData.set('user_id', userId)
    postData.set('new_role', new_role)
    return this.http.post(this.builderUrl + 'update_user_role', postData.toString(), this.options)
  }
  deleteStacksUser = (userId: string): Observable<any> => {
    this.token = this.builderService.getToken()
    let postData = new URLSearchParams()
    postData.set('token', this.token)
    postData.set('user_id', userId)
    return this.http.post(this.builderUrl + 'delete_user', postData.toString(), this.options)
  }
 
}
