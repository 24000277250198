<section class="stacksBlockContainer" id="block-posts">
  <div id="settingsTabs" *ngIf="checkSettings()">
    <a (click)="showDataTab()" [ngClass]="checkData() ? 'active' : ''">{{ 'Data' | translate }}</a>
    <a (click)="showStyleTab()" [ngClass]="checkStyle() ? 'active' : ''">{{ 'Style' | translate }}</a>
  </div>
  <!-- Sidebar -->
  <div *ngIf="checkSidebar()" class="blockSidebar" (click)="appendBlock($event)" [ngClass]="blockDataService.woocommerceActive && !builderService.standalone ? '' : 'disabled'">
    <img src="assets/images/blocks/post.png" alt="" srcset="" />
    <p>{{ 'Posts/Courses' | translate }}</p>
  </div>
  <!-- Mobile Preview -->
  <div class="previewPosts previewMobile {{ getBlockFields(blockId)?.data?.post_type }}" *ngIf="checkPreview()" id="{{ 'blockid' + blockId }}" cdkDrag [cdkDragData]="blockId">
    <mat-progress-bar mode="indeterminate" *ngIf="builderService.showSettingsProgress"></mat-progress-bar>
    <div class="blockControlHandlers" *ngIf="!builderService.globals">
      <div class="editBlock blockActionBtn" (click)="showData(blockId)">
        <mat-icon>edit</mat-icon>
      </div>
      <div class="blockHandle blockActionBtn" cdkDragHandle>
        <mat-icon>open_with</mat-icon>
      </div>
      <div class="deleteBlock blockActionBtn" (click)="deleteBlock(blockId)">
        <mat-icon>close</mat-icon>
      </div>
    </div>
    <div class="blockPlaceholder" *cdkDragPlaceholder></div>
    <div [id]="getBlockFields(blockId)?.style?.posts_style == 'grid' ? 'style_3' : getBlockFields(blockId)?.style?.posts_style == 'grid_1_column' ? 'grid_1_column' : 'style_4'">
      <div class="horizontal-slider-container">
        <p *ngIf="!getPostsData(blockId) || !getPostsData(blockId).length" style="color: red">{{ 'No Posts Available with the selected settings' | translate }}</p>
        <ul class="horizonal-slider" [ngStyle]="getBlockFields(blockId)?.style">
          <li class="item" *ngFor="let post of getPostsData(blockId)" [ngStyle]="getBlockFields(blockId)?.internalStyle">
            <div class="flex-container">
              <div class="picture-container">
                <img *ngIf="post?.post_image" src="{{ post?.post_image }}" [ngStyle]="getBlockFields(blockId)?.internalStyle" />
              </div>
              <div class="description">
                <div class="heading">
                  <span class="card-title" [innerHTML]="post?.post_title"></span>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- Data and Style Tabs -->
  <div class="previewFields" *ngIf="checkData()">
    <mat-progress-bar mode="indeterminate" *ngIf="builderService.showSettingsProgress"></mat-progress-bar>
    <h4 class="listTitle">{{ 'Query' | translate }}</h4>
    <mat-divider></mat-divider>
    <ul>
      <li *ngIf="builderService.standalone">
        <h4 class="listTitle">{{ 'WordPress Website Link' | translate }}</h4>
        <input matInput (change)="queryChange('wordpress_url', blockId, $event, 'text')" value="{{ getBlockFields(blockId).data?.wordpress_url }}" />
      </li>
      <li>
        <mat-label>{{ 'Post Type' | translate }}</mat-label>
        <mat-select (selectionChange)="queryChange('post_type', blockId, $event, 'select')" value="{{ getBlockFields(blockId).data?.post_type }}">
          <mat-option *ngFor="let postType of postTypes" [value]="postType">
            {{ postType }}
          </mat-option>
        </mat-select>
      </li>
      <li>
        <mat-label>{{ 'Single Post Type' | translate }}</mat-label>
        <mat-select (selectionChange)="queryChange('single_type', blockId, $event, 'select')" value="{{ getBlockFields(blockId).data?.single_type }}">
          <mat-option value="native">
            {{ 'Native' | translate }}
          </mat-option>
          <mat-option value="webview">
            {{ 'Webview' | translate }}
          </mat-option>
        </mat-select>
      </li>
      <li>
        <mat-label>{{ 'Source' | translate }}</mat-label>
        <mat-select (selectionChange)="queryChange('source', blockId, $event, 'select')" value="{{ getBlockFields(blockId).data?.source }}">
          <mat-option value="all">
            {{ 'Show All' | translate }}
          </mat-option>
          <mat-option value="by_category">
            {{ 'Category Selection' | translate }}
          </mat-option>
        </mat-select>
      </li>
    </ul>
    <ul>
      <li *ngIf="getBlockFields(blockId)?.data?.source == 'by_category' && getCategoriesData(blockId)?.length">
        <mat-label>{{ 'Categories' | translate }}</mat-label>
        <mat-select multiple (selectionChange)="queryChange('category', blockId, $event, 'select')" [(ngModel)]="getBlockFields(blockId).data.category">
          <mat-option *ngFor="let productCategory of getCategoriesData(blockId)" value="{{ productCategory.id }}">
            {{ productCategory.name }}
          </mat-option>
        </mat-select>
      </li>
    </ul>
    <!-- <ul>
      <li *ngIf="getBlockFields(blockId).data.source == 'by_id'">
        <mat-label>Posts</mat-label>
        <mat-select multiple (selectionChange)="queryChange('posts', blockId, $event, 'select')" 
        [(value)]="getBlockFields(blockId).data.posts" >
          <mat-option *ngFor="let productCategory of storedPosts"
            [value]="productCategory.id">
            {{productCategory.name}} 
          </mat-option>
        </mat-select>
      </li>
    </ul> -->
    <ul>
      <li *ngIf="getBlockFields(blockId).data?.source == 'all'">
        <mat-label>{{ 'Limit' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="10" min="1" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).data?.numberposts }}" (change)="queryChange('numberposts', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
    </ul>
    <ul>
      <li>
        <mat-label>{{ 'Order By' | translate }}</mat-label>
        <mat-select (selectionChange)="queryChange('orderby', blockId, $event, 'select')" value="{{ getBlockFields(blockId).data?.orderby }}">
          <mat-option value="title">
            {{ 'Title' | translate }}
          </mat-option>
          <mat-option value="rand">
            {{ 'Random' | translate }}
          </mat-option>
        </mat-select>
      </li>
    </ul>
    <ul>
      <li>
        <mat-label>{{ 'Order' | translate }}</mat-label>
        <mat-select (selectionChange)="queryChange('order', blockId, $event, 'select')" value="{{ getBlockFields(blockId).data?.order }}">
          <mat-option value="ASC">
            {{ 'Ascendingly' | translate }}
          </mat-option>
          <mat-option value="DESC">
            {{ 'Descendingly' | translate }}
          </mat-option>
        </mat-select>
      </li>
    </ul>
  </div>
  <div class="styleFields" *ngIf="checkStyle()">
    <ul>
      <li>
        <mat-label>{{ 'Posts Style' | translate }}</mat-label>
        <mat-select (selectionChange)="styleChange('posts_style', blockId, $event, 'select')" value="{{ getBlockFields(blockId).style.posts_style }}">
          <mat-option value="list">
            {{ 'List' | translate }}
          </mat-option>
          <mat-option value="grid_1_column">
            {{ 'Grid 1 column' | translate }}
          </mat-option>
          <mat-option value="grid">
            {{ 'Grid 2 column' | translate }}
          </mat-option>
        </mat-select>
      </li>
    </ul>
    <h4 class="listTitle">{{ 'Margins' | translate }}</h4>
    <mat-divider></mat-divider>
    <ul>
      <li>
        <mat-label>{{ 'Margin Top' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="-100" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['margin-top']?.slice(0, -2) }}" (change)="styleChange('margin-top', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
      <li>
        <mat-label>{{ 'Margin Right' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="-100" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['margin-right']?.slice(0, -2) }}" (change)="styleChange('margin-right', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
      <li>
        <mat-label>{{ 'Margin Bottom' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="-100" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['margin-bottom']?.slice(0, -2) }}" (change)="styleChange('margin-bottom', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
      <li>
        <mat-label>{{ 'Margin Left' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="-100" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['margin-left']?.slice(0, -2) }}" (change)="styleChange('margin-left', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
    </ul>
    <mat-label>{{ 'Paddings' | translate }}</mat-label>

    <mat-divider></mat-divider>
    <ul>
      <li>
        <mat-label>{{ 'Padding Top' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="1" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['padding-top']?.slice(0, -2) }}" (change)="styleChange('padding-top', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
      <li>
        <mat-label>{{ 'Padding Right' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="1" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['padding-right']?.slice(0, -2) }}" (change)="styleChange('padding-right', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
      <li>
        <mat-label>{{ 'Padding Bottom' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="1" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['padding-bottom']?.slice(0, -2) }}" (change)="styleChange('padding-bottom', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
      <li>
        <mat-label>{{ 'Padding Left' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="1" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['padding-left']?.slice(0, -2) }}" (change)="styleChange('padding-left', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
    </ul>
    <h4 class="listTitle">{{ 'General Styles' | translate }}</h4>
    <mat-divider></mat-divider>
    <ul>
      <li>
        <mat-label>{{ 'Gutter Internal Spacing' | translate }}</mat-label>
        <mat-slider max="100" min="0" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['grid-gap']?.slice(0, -2) }}" (change)="styleChange('grid-gap', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
      <li>
        <mat-label>{{ 'Border-Radius' | translate }}</mat-label>
        <mat-select (selectionChange)="styleChange('border-radius', blockId, $event, 'select', 'internalStyle')" value="{{ getBlockFields(blockId).internalStyle['border-radius'] }}">
          <mat-option value="">
            {{ 'yes' | translate }}
          </mat-option>
          <mat-option value="initial">
            {{ 'unset' | translate }}
          </mat-option>
        </mat-select>
      </li>
      <li>
        <mat-label>{{ 'Box Shadow' | translate }}</mat-label>
        <mat-select (selectionChange)="styleChange('box-shadow', blockId, $event, 'select', 'internalStyle')" value="{{ getBlockFields(blockId).internalStyle['box-shadow'] }}">
          <mat-option value="">
            {{ 'yes' | translate }}
          </mat-option>
          <mat-option value="initial">
            {{ 'unset' | translate }}
          </mat-option>
        </mat-select>
      </li>
    </ul>
  </div>
</section>
