import { Injectable } from '@angular/core'
import mixpanel from 'mixpanel-browser'
import { environment } from 'src/environments/environment'
import { BuilderService } from '../builder-services/builder.service'
import { LoginService } from '../login/login.service'

@Injectable({
  providedIn: 'root'
})
export class MixpanelService {
  constructor(
    private builderService: BuilderService,
    private loginService: LoginService
  ) {}

  /**
   * Initialize mixpanel.
   *
   * @param {string} userToken
   * @memberof MixpanelService
   */
  init(userToken: string): void {
    if (environment.production) {
      mixpanel.init('298ed44bea1fe4ca93f0cc50f8829012', { cross_subdomain_cookie: true })
      // mixpanel.identify(userToken);
      // let user_email = this.loginService.getCurrentUser().email;
      // let username = this.loginService.getCurrentUser().username;
      // mixpanel.people.set({ "$name": username, "$email": user_email });
    }
  }

  /**
   * Push new action to mixpanel.
   *
   * @param {string} id Name of the action to track.
   * @param {*} [action={}] Actions object with custom properties.
   * @memberof MixpanelService
   */
  track(id: string, action: any = {}): void {
    if (environment.production) {
      let user_email = this.loginService.getCurrentUser().email
      let username = this.loginService.getCurrentUser().username
      let added_data: any = {
        projectid: this.builderService.selectedProject,
        $email: user_email,
        $name: username
      }
      for (const key in added_data) {
        if (Object.prototype.hasOwnProperty.call(added_data, key)) {
          const element = added_data[key]
          action[key] = element
        }
      }
      // removing mixpanel to fix conflict with router.events.subscribe
      mixpanel.track(id, action)
    }
  }
}
