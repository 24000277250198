import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { BuilderService } from '../builder-services/builder.service'
import { DatabaseService } from '../builder-services/database.service'

@Injectable({
  providedIn: 'root'
})
export class SubscribersService {
  constructor(
    private builderService: BuilderService,
    private db: DatabaseService
  ) {}

  submitSubscriber = (subscriber: any, subscriber_id: string = '') => {
    subscriber = JSON.parse(JSON.stringify(subscriber))
    return new Observable<any>((observer) => {
      if (!subscriber_id) {
        this.db.setDatabase('subscribers/', subscriber, true).subscribe(
          (response) => {
            observer.next(response) // Notify the observer that the operation is complete
            observer.complete() // Complete the Observable
          },
          (error) => {
            observer.error(error) // Notify the observer of an error
          }
        )
      } else {
        this.db.setDatabase('subscribers/' + subscriber_id, subscriber).subscribe(
          (response) => {
            observer.next(response) // Notify the observer that the operation is complete
            observer.complete() // Complete the Observable
          },
          (error) => {
            observer.error(error) // Notify the observer of an error
          }
        )
      }
    })
  }

  getSubscribers = () => {
    return new Observable<any>((observer) => {
      this.db.getDatabase('subscribers/').subscribe(
        (response) => {
          if (!response) {
            console.log('No data available')
            observer.next({})
            observer.complete() // Complete the Observable
          } else {
            observer.next(response) // Notify the observer that the operation is complete
            observer.complete() // Complete the Observable
          }
        },
        (error) => {
          observer.error(error) // Notify the observer of an error
        }
      )
    })
  }

  getSubscriber = (subscriber_id: string) => {
    let project_id = this.builderService.selectedProject
    return new Observable<any>((observer) => {
      this.db.getDatabase('subscribers/' + subscriber_id).subscribe(
        (response) => {
          if (!response) {
            console.log('No data available')
            observer.next({})
            observer.complete() // Complete the Observable
          } else {
            observer.next(response) // Notify the observer that the operation is complete
            observer.complete() // Complete the Observable
          }
        },
        (error) => {
          observer.error(error) // Notify the observer of an error
        }
      )
    })
  }

  removeSubscriber = (subscriber_id: string) => {
    return new Observable<any>((observer) => {
      let project_id = this.builderService.selectedProject
      this.db.removeDatabaseEndpoint('subscribers/' + subscriber_id).subscribe((response) => {
        observer.next(response) // Notify the observer that the operation is complete
        observer.complete() // Complete the Observable
      })
    })
  }
}
