import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'app-posts-categories',
  templateUrl: './posts-categories.component.html',
  styleUrls: ['./posts-categories.component.scss']
})
export class PostsCategoriesComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
