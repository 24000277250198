import { Component, OnInit } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { BlockDataService } from '../block-services/block-data.service'
import { BuilderService } from '../builder-services/builder.service'
import { LoginService } from './login.service'
import { SocialAuthService } from '@abacritt/angularx-social-login'
import { GoogleLoginProvider } from '@abacritt/angularx-social-login'
import { LanguageService } from '../language.service'
import { TranslateService } from '@ngx-translate/core'

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  isPasswordVisible: boolean = false

  loginProgress = false
  loginError = false
  loginErrorMsg = ''
  preservedReturnURL: any = ''
  paymentIncluded = false
  constructor(
    private authService: SocialAuthService,
    private blockDataService: BlockDataService,
    private builderService: BuilderService,
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private route: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    public languageService: LanguageService
  ) {}

  loginForm = this.formBuilder.group({
    username: '',
    password: ''
  })

  ngOnInit(): void {
    this.preservedReturnURL = this.route.snapshot.queryParamMap.get('returnUrl')
    if (this.preservedReturnURL && this.preservedReturnURL.includes('payment')) {
      this.paymentIncluded = true
      // Do something if 'payment' is in the URL
    }

    if (this.route.snapshot.queryParamMap.get('coupon_id')) {
      this.builderService.selected_coupon_id = this.route.snapshot.queryParamMap.get('coupon_id')
    }

    if (this.route.snapshot.queryParamMap.get('pricing_id')) {
      this.builderService.selected_pricing_id = this.route.snapshot.queryParamMap.get('pricing_id')
    }

    if (this.loginService.isLoggedIn()) {
      if (this.paymentIncluded) {
        // window.location.href = '/payment';
        this.router.navigate(['/billing'])
      } else {
        this.router.navigate(['/'])
      }
    }

    this.authService.authState.subscribe((user) => {
      if (user == null) {
        return false
      }
      this.loginProgress = true
      this.loginService.loginSocial(user.email).subscribe(
        (login) => {
          this.loginProgress = false
          this.loginError = false
          localStorage.setItem('token', login.token)
          this.blockDataService.sendTokenData(login.token)
          let returnUrl = this.route.snapshot.queryParamMap.get('returnUrl')
          login.tour_ended == '1' ? this.builderService.setTour(false) : this.builderService.setTour(true)
          // Logout from google authenticate after successful login to prevent authentication loop
          // this.authService.signOut();
          if (!login.tour_ended) {
            this.router.navigate(['/site-type'])
            // this.router.navigate(['/templates']);
          } else {
            this.router.navigate([returnUrl || '/'], { relativeTo: this.route })
          }
        },
        (err) => {
          this.loginProgress = false
          this.loginError = true
          this.loginErrorMsg = err.error.message
        }
      )
      return true
    })
  }

  signInWithGoogle(): void {
    this.authService.signIn(GoogleLoginProvider.PROVIDER_ID).then((user) => {
      console.log(user)
    })
  }

  /**
   * Login the user, sets the token and navigate to the correct url
   */
  onSubmit(): void {
    this.loginProgress = true
    let loginFormVal: any = this.loginForm.value
    this.loginService.login(loginFormVal.username, loginFormVal.password).subscribe(
      (login) => {
        this.loginProgress = false
        this.loginError = false
        localStorage.setItem('token', login.token)
        this.blockDataService.sendTokenData(login.token)
        let returnUrl = this.route.snapshot.queryParamMap.get('returnUrl')
        login.tour_ended == '1' ? this.builderService.setTour(false) : this.builderService.setTour(true)

        if (!login.tour_ended) {
          this.router.navigate(['/site-type'])
          // this.router.navigate(['/templates']);
        } else {
          if (returnUrl) {
            this.router.navigate([returnUrl])
          } else {
            this.router.navigate(['/'])
          }
        }
      },
      (err) => {
        this.loginProgress = false
        this.loginError = true
        this.loginErrorMsg = err.error.message
      }
    )
  }

  changeLang(language: string) {
    localStorage.setItem('siteLang', language)
    this.languageService.setLanguage(language)
    this.languageService.loadTranslations(language).then(() => {
      this.translate.use(language)
    })
  }

  togglePasswordVisibility(): void {
    this.isPasswordVisible = !this.isPasswordVisible
  }
}
