import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { DatabaseService } from 'src/app/builder-services/database.service'

@Injectable({
  providedIn: 'root'
})
export class CategoriesService {
  availableSubs: any

  constructor(
    private builderService: BuilderService,
    private db: DatabaseService
  ) {}

  storedStandaloneCategories: any
  submitCategory = (category: any) => {
    let project_id = this.builderService.selectedProject
    category = JSON.parse(JSON.stringify(category))
    return new Observable<any>((observer) => {
      this.db.setDatabase('projects/' + project_id + '/categories/', category).subscribe(
        (response) => {
          observer.next(response) // Notify the observer that the operation is complete
          observer.complete() // Complete the Observable
        },
        (error) => {
          observer.error(error) // Notify the observer of an error
        }
      )
    })
  }

  getCategories = () => {
    let project_id = this.builderService.selectedProject
    return new Observable<any>((observer) => {
      this.db.getDatabase('projects/' + project_id + '/categories/').subscribe(
        (response) => {
          if (!response) {
            console.log('No data available')
            observer.next({})
            observer.complete() // Complete the Observable
          } else {
            observer.next(response) // Notify the observer that the operation is complete
            observer.complete() // Complete the Observable
          }
        },
        (error) => {
          observer.error(error) // Notify the observer of an error
        }
      )
    })
  }

  removeCategory = (category_id: string) => {
    let project_id = this.builderService.selectedProject
    this.db.removeDatabaseEndpoint('projects/' + project_id + '/categories/' + category_id).subscribe(
      (response) => {
        console.log('removed successfully')
      },
      (error) => {}
    )
  }

  getCategory = (category_id: string) => {
    let project_id = this.builderService.selectedProject
    return new Observable<any>((observer) => {
      this.db.getDatabase('projects/' + project_id + '/categories/').subscribe(
        (response) => {
          let found = false
          let cat
          for (let i of response) {
            if (category_id === i.categoryId) {
              found = true
              cat = i
            }
          }
          console.log(response)
          if (found) {
            observer.next(cat) // Notify the observer that the operation is complete
            observer.complete() // Complete the Observable
          } else {
            console.log('No data available')
            observer.next({})
            observer.complete() // Complete the Observable
          }
        },
        (error) => {
          observer.error(error) // Notify the observer of an error
        }
      )
    })
  }

  findCategoryById = (categoriesData: any, categoryIdsToFind: string) => {
    const foundCategories = []
    if (categoryIdsToFind !== undefined) {
      for (let categoryId of categoryIdsToFind) {
        const foundCategory = categoriesData.find((category: { categoryId: string }) => parseInt(category.categoryId) === parseInt(categoryId))
        if (foundCategory) {
          foundCategories.push(foundCategory)
        }
      }
    }

    return foundCategories
  }

  findSubCategoryById = (categoriesData: any, categoryIdsToFind: string) => {
    const foundSubCategories = []
    if (!categoriesData) {
      return []
    }
    for (let category of categoriesData) {
      if (!categoryIdsToFind) {
        continue
      }
      for (let subCategoryId of categoryIdsToFind) {
        if (category.subcategories !== undefined) {
          const foundSubCategory = category.subcategories.find((category: { subCategoryId: string }) => category.subCategoryId === subCategoryId)
          if (foundSubCategory) {
            foundSubCategories.push(foundSubCategory)
          }
        }
      }
    }

    return foundSubCategories
  }

  /**
   * Take the categories array and return array of ids
   */
  getCategoryIdFromCategories = (categories: any, type = 'category') => {
    let categoriesIds = []
    if (!categories) {
      return []
    }
    for (const category of categories) {
      if (type == 'category') {
        categoriesIds.push(category.categoryId)
      } else if (type == 'subcategory') {
        categoriesIds.push(category)
      }
    }
    return categoriesIds
  }
}
