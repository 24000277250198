import { Injectable } from '@angular/core'
import { Routes } from '@angular/router'
import { Subject } from 'rxjs'
@Injectable({
  providedIn: 'root'
})
export class PluginsServiceService {
  private pluginsServices = new Subject<any>()

  constructor() {}

  static getRoutesFromPaths(paths: string[], moduleMap: any): Routes {
    return paths.map((path) => {
      const routePath = this.getRouteFromPath(path)
      const loadChildren = moduleMap[routePath]
      if (!loadChildren) {
        throw new Error(`Cannot find module for path: ${path}`)
      }
      return {
        path: routePath,
        loadChildren: loadChildren
      }
    })
  }

  static getRouteFromPath(path: string): string {
    // Transform the file path to a route path, e.g., 'app/components/my-component/my-component.module' to 'my-component'
    return path.split('/').pop()?.replace('.module', '') || ''
  }

  getPlugingsServices = () => {
    return this.pluginsServices.asObservable()
  }
  setPluginsServices = (data: object) => {
    this.pluginsServices.next(data)
  }
}
