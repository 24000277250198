import { Component, OnInit } from '@angular/core'
import { FormBuilder, FormControl, Validators } from '@angular/forms'
import { ActivatedRoute, Router } from '@angular/router'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { SubscribersService } from '../subscribers.service'
import { AppSettingsService } from 'src/app/settings/services/app-settings.service'

@Component({
  selector: 'app-new-subscriber',
  templateUrl: './new-subscriber.component.html',
  styleUrls: ['./new-subscriber.component.scss']
})
export class NewSubscriberComponent implements OnInit {
  subscriberProgress = false
  subscriberEmail: any
  subscriberName: any
  subscriptionPlan: any
  subscriptionPrice: any
  subscriberIdFromRoute: any
  renewalDate: any
  dateOfSub: any
  status: any
  currency: any
  notes: any

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    public builderService: BuilderService,
    private subscriberService: SubscribersService,
    private appSettings: AppSettingsService
  ) {}

  subscriberForm: any = this.formBuilder.group({
    subscriberName: '',
    subscriberEmail: '',
    dateOfSub: '',
    subscriptionPlan: '',
    subscriptionPrice: '',
    renewalDate: '',
    status: '',
    currency: '',
    notes: ''
  })

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap
    this.subscriberIdFromRoute = routeParams.get('subId')

    if (this.subscriberIdFromRoute) {
      this.subscriberService.getSubscriber(this.subscriberIdFromRoute).subscribe((subscriber: any) => {
        console.log(subscriber)
        // Create an empty FormGroup
        this.subscriberForm = this.formBuilder.group({
          subscriberName: new FormControl(''),
          subscriberEmail: new FormControl(''),
          dateOfSub: new FormControl(''),
          subscriptionPlan: new FormControl(''),
          subscriptionPrice: new FormControl(''),
          renewalDate: new FormControl(''),
          status: new FormControl(''),
          currency: new FormControl(''),
          notes: new FormControl('')
        })

        // Iterate through the properties of the product object and add them as form controls
        for (const key of Object.keys(subscriber)) {
          this.subscriberForm.addControl(key, new FormControl(subscriber[key]))
        }
        this.subscriberEmail = subscriber.subscriberEmail
        this.subscriberName = subscriber.subscriberName
        this.subscriptionPlan = subscriber.subscriptionPlan
        this.subscriptionPrice = subscriber.subscriptionPrice
        this.dateOfSub = subscriber.dateOfSub
        this.renewalDate = subscriber.renewalDate
        this.status = subscriber.status
        this.currency = subscriber.currency
        this.notes = subscriber.notes
      })
    }

    this.subscriberForm = this.formBuilder.group({
      subscriberName: ['', Validators.compose([Validators.required])],
      subscriberEmail: ['', Validators.compose([Validators.required])],
      subscriptionPlan: ['', Validators.compose([Validators.required])],
      subscriptionPrice: ['', Validators.compose([Validators.required])],
      renewalDate: ['', Validators.compose([Validators.required])],
      status: ['', Validators.compose([Validators.required])],
      currency: ['', Validators.compose([Validators.required])],
      notes: ['']
    })
  }

  onSubmit = () => {
    this.subscriberProgress = true
    let subscriberFormVal = this.subscriberForm.value
    if (this.subscriberForm.status == 'INVALID') {
      alert('Please fill all the required fields')
      this.subscriberProgress = false
      return
    }
    if (this.subscriberIdFromRoute) {
      this.subscriberService.submitSubscriber(subscriberFormVal, this.subscriberIdFromRoute).subscribe((result: any) => {
        this.subscriberProgress = false
        this.router.navigate(['/admin/subList'])
      })
    } else {
      subscriberFormVal.dateOfSub = new Date().toLocaleDateString()
      this.subscriberService.submitSubscriber(subscriberFormVal).subscribe((result: any) => {
        this.subscriberProgress = false
        this.router.navigate(['/admin/subList'])
      })
    }
  }

  statusChange = (value: string) => {
    this.status = value
  }

  createProject = () => {
    let email = this.subscriberEmail
    this.builderService.createFreeProject(email).subscribe((response: any) => {
      console.log(response)
      if (response.success == false) {
        alert(response.data)
        return
      }
      this.builderService.selectedProject = response.project_id
      localStorage.setItem('selectedProject', response.project_id)
      this.appSettings.addProjectDefaultSettings(response.project_id).subscribe(() => {
        this.builderService.convertToManualPaymentProject(email, response.project_id).subscribe((response_manual: any) => {
          alert('Project Created Successfully, Project ID:' + response.project_id)
        })
      })
    })
  }
}
