import { Component, OnInit } from '@angular/core'
import { ActivatedRoute } from '@angular/router'
import { OrdersService } from '../orders.service'
import { SettingsService } from '../../settings/settings.service'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { DatabaseService } from 'src/app/builder-services/database.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { LoginService } from 'src/app/login/login.service'
import { PushNotificationsService } from 'src/app/addons/push-notifications/push-notifications.service'

@Component({
  selector: 'app-single-order',
  templateUrl: './single-order.component.html',
  styleUrls: ['./single-order.component.scss']
})
export class SingleOrderComponent implements OnInit {
  public orderIdFromRoute: any = []
  mobile = false
  orderData: any
  currency = ''
  editAddress = false
  possibleStatuses = ['Order Received', 'Confirmed', 'Processing', 'Completed', 'Cancelled', 'Refunded']
  selectedStatus = 'Order Received'
  allowUpdateStatus = false
  addressForm!: FormGroup
  orderStatusForm!: FormGroup
  selectedAddress: any
  addressId!: any
  shippingCountries: any
  possibleGovernates: any
  shippingPrice: any
  customAddress: any
  // 'Size',,'Total Price'
  userColumns = ['image', 'product_name', 'price', 'sale_price', 'size', 'quantity', 'total_price']
  countryDialCodes: any[] = []
  fullPhoneNumber: string = ''
  addressTypes = ['Home', 'Work']
  isExpanded = false

  constructor(
    private route: ActivatedRoute,
    public orderService: OrdersService,
    private settingsService: SettingsService,
    private builderService: BuilderService,
    private db: DatabaseService,
    private formBuilder: FormBuilder,
    public loginService: LoginService,
    private pnService:PushNotificationsService
  ) {}

  ngOnInit(): void {
    const routeParams = this.route.snapshot.paramMap
    this.orderIdFromRoute = routeParams.get('orderId')
    this.builderService.headerBtn = 'orders/single-order'
    this.settingsService.getSettings().subscribe((response: any) => {
      this.currency = response.currency
    })
    if (this.orderIdFromRoute) {
      this.getOrderData(this.orderIdFromRoute)
    }
    this.countryDialCodes = this.orderService.getCountryDialCodes()

    this.addressForm = this.formBuilder.group({
      billing_postcode: [''],
      billing_country: [''],
      billing_state: [''],
      billing_street_Name: [''],
      billing_street_No: [''],
      billing_address_1: [''],
      billing_address_2: [''],
      type: [''],
      order_notes: [''],
      order_id: [''],
      shipping_price: [''],
      first_name: ['', Validators.compose([Validators.maxLength(30)])],
      last_name: ['', Validators.compose([Validators.maxLength(30)])],
      billing_phone: [''],
      phone_number: [''],
      country_code: [''],
      billing_address_type: ['']
    })

    this.orderStatusForm = this.formBuilder.group({
      status: ['']
    })
    if (window.screen.width <= 768) {
      // 768px portrait
      this.mobile = true
    }

    this.getShippingData()
  }

  toggleExpand() {
    this.isExpanded = !this.isExpanded
  }

  /**
   * Fetches the order data for the specified order ID and populates the properties.
   * If the order has an address ID, it calls the `fetchAddress` method to retrieve the address data.
   * If the order does not have an address ID, it retrieves the address data from the user's data and assigns it to the properties.
   * @param orderId - The ID of the order to fetch data for.
   */
  getOrderData(orderId: any) {
    this.orderService.getOrder(orderId).subscribe((orderData: any) => {
      this.orderService.orderData = orderData
      this.orderData = orderData[0];
      this.selectedStatus = this.orderData.order.status;
      this.orderStatusForm.patchValue({
        status: this.selectedStatus
      })
      // checks if orderstatuses exist in db if not the it assigns it to an empty array
      if (this.orderData.order.orderStatuses) {
        this.orderService.orderStatuses = this.orderData.order.orderStatuses
      } else {
        this.orderService.orderStatuses = []
      }
      if (this.orderData.order.address_id != null) {
        this.fetchAddress(orderData[0])
      } else {
        this.addressId = Object.keys(this.orderData.user_data.address)[0]
        for (const key in this.orderData.address) {
          if (Object.prototype.hasOwnProperty.call(this.orderData.address, key)) {
            const element = this.orderData.address[key]
            this.orderData.address = element
            this.selectedAddress = element
            this.customAddress = element.type == 'customAddress' ? element : ''
          }
        }
      }
    })
  }

  /**
   * Updates the status of an order and handles the corresponding points actions.
   * @param allowUpdateStatus - A boolean indicating whether the order status can be updated.
   * @param event - The event object containing the new order status.
   */
  updateStatus(allowUpdateStatus: boolean, event: any) {
    this.selectedStatus = event.value
    this.allowUpdateStatus = allowUpdateStatus
  }

  saveUpdateStatus() {
    // if (this.orderStatusForm.valid) {
    //   console.log('Form submitted with status:', this.orderStatusForm.get('status')?.value);
    // }
    this.orderService.updateOrderStatus(this.orderIdFromRoute, this.selectedStatus, this.orderData.user_id).subscribe((response: any) => {
      this.orderData.status = this.selectedStatus
      this.allowUpdateStatus = false      
      this.pnService.automate(this.selectedStatus)
      if (this.orderData.status === 'Completed') {
        this.addPointsToUser()
      } else {
        this.removePointsFromUser()
      }
    })
  }

  /*
  The `addPointsToUser` and `removePointsFromUser` functions manage the points data for a user within the database.
  1. **Adding Points to User**:
    - Constructs the database path where the points data will be saved using `selectedProject`, `user_id`, and `order_id` from `orderData`.
    - Checks if `toBeEarned` data exists and is not empty:
      - If `toBeEarned` exists:
        - Maps the `toBeEarned` array to prepare the points data.
        - Fetches existing points data from the database:
          - If existing data is found, it appends the new points to the existing data.
          - If no data exists, it uses only the new points.
        - Calls `setDatabase` with the updated points array.
        - **Success Handling**:
          - Logs a message indicating successful saving of the points data.
        - **Error Handling**:
          - Logs an error message if fetching or saving the points data fails.
      - If `toBeEarned` is undefined or empty:
        - Logs a message indicating that there is no data to move.

  2. **Removing Points from User**:
    - Constructs the database path where the points data will be removed using the `selectedProject`, `user_id`, and `order_id` from `orderData`.
    - Calls `removeDatabaseEndpoint` with the constructed path to remove the points data.
    - **Success Handling**:
      - Logs a message indicating successful removal of points data.
    - **Error Handling**:
      - Logs an error message if removing the points data fails.
*/

  addPointsToUser() {
    if (this.orderData.order.toBeEarned) {
      const databasePath = `/projects/${this.builderService.selectedProject}/users/${this.orderData.user_id}/points/${this.orderData.order_id}`
      const pointsData: any[] = this.orderData.order.toBeEarned.map((item: any) => item)
      this.db.getDatabase(databasePath).subscribe(
        (existingData: any[] | null) => {
          let updatedPoints: any[] = []
          if (Array.isArray(existingData)) {
            updatedPoints = [...existingData, ...pointsData]
          } else {
            updatedPoints = pointsData
          }

          this.db.setDatabase(databasePath, updatedPoints, false).subscribe((response) => {
            console.log('toBeEarned points moved successfully')
          })
        },
        (error) => {
          console.error(error)
        }
      )
    } else {
      console.log('toBeEarned is either undefined or empty. Nothing to move.')
    }
  }

  /*
  The `removePointsFromUser` function removes points data from the database for a specific user and order.
    1. **Construct Database Path**:
      - Builds the path to the points data using:
        - `selectedProject` from `builderService`
        - `user_id` from `orderData`
        - `order_id` from `orderData`

    2. **Remove Points Data**:
      - Calls `removeDatabaseEndpoint` on `db` with the constructed path to delete the points data.
      - **Success Handling**:
        - Logs a message indicating that points were removed successfully.
      - **Error Handling**:
        - Logs an error message if the removal operation fails.
*/

  removePointsFromUser() {
    const databasePath = `/projects/${this.builderService.selectedProject}/users/${this.orderData.user_id}/points/${this.orderData.order_id}`

    this.db.removeDatabaseEndpoint(databasePath).subscribe(
      (response) => {
        console.log('Points removed successfully')
      },
      (error) => {
        console.error('Error removing points', error)
      }
    )
  }

  /**
   * Sets the `editAddress` property to `true`, indicating that the user is in the process of editing addresses.
   */
  editAddresses() {
    this.editAddress = true
  }

  /**
   * Fetches and formats address data from the provided `data` object.
   * This function processes the address data from the `data.user_data.address` object,
   * formatting it into an array of address details. It also identifies the custom address
   * associated with the current order and the selected address based on the `order.address_id`.
   * @param {any} data - The data object containing the address information.
   * @returns {void}
   */
  fetchAddress(data: any) {
    const formattedAddresses: any[] = []
    const addresses = data.user_data.address
    Object.keys(addresses).forEach((id) => {
      const details = { id: id, ...addresses[id] }
      formattedAddresses.push({ id, ...details })
      if (details.order_id && details.order_id === this.orderIdFromRoute) {
        this.customAddress = details
        this.addressForm.patchValue(this.customAddress)
      } else if (data.order.address_id == id) {
        this.selectedAddress = details
      }
    })
  }

  /**
   * Retrieves shipping data from the order service and updates the `shippingCountries` property.
   * @returns {void}
   */
  getShippingData(): void {
    this.orderService.getShipping().subscribe((data) => {
      this.shippingCountries = data
    })
  }

  /**
   * Updates the phone number and billing phone fields in the address form.
   * @param {string} countryCode - The country code for the phone number.
   * @param {any} event - The event object containing the phone number value.
   * @returns {void}
   */
  updatePhoneNumber(countryCode: string, event: any) {
    let phoneNumber = event.target.value
    this.addressForm.controls.phone_number.setValue(phoneNumber)
    if (countryCode && phoneNumber) {
      this.fullPhoneNumber = countryCode + phoneNumber
      this.addressForm.controls.billing_phone.setValue(this.fullPhoneNumber)
      this.addressForm.controls.billing_phone.updateValueAndValidity()
    }
  }

  /**
   * Updates the shipping price based on the selected country.
   * @param {any} selectedCountry - The selected country.
   * @returns {void}
   */
  public updateCountry = (selectedCountry: any) => {
    let countryPrice = 0
    for (const key in this.shippingCountries) {
      if (Object.prototype.hasOwnProperty.call(this.shippingCountries, key)) {
        const element = this.shippingCountries[key]
        if (element.countryName == selectedCountry) {
          countryPrice = Number(element.countryPrice)
          this.possibleGovernates = element.governorates
        }
      }
    }
    if (!this.possibleGovernates) {
      this.shippingPrice = Number(countryPrice)
    } else {
      this.shippingPrice = 0
    }
  }

  /**
   * Updates the shipping price based on the selected state.
   * @param {any} selectedState - The selected state
   * @returns {void}
   */
  public updateState(selectedState: any) {
    for (const key in this.possibleGovernates) {
      if (Object.prototype.hasOwnProperty.call(this.possibleGovernates, key)) {
        const element = this.possibleGovernates[key]
        if (element.governorateName == selectedState) {
          this.shippingPrice = Number(element.governoratePrice)
        }
      }
    }
  }

  /**
   * Adds a custom address to the order.
   * - Patches the address form and adding this values (type, order_id, shipping_price).
   * - Calls the `addAddress` method of the `orderService` to add the address.
   * - Upon successful response, sets `editAddress` to false and calls `getOrderData` to refresh the order data.
   */
  addCustomAddress() {
    this.addressForm.patchValue({
      type: 'customAddress',
      order_id: this.orderData.order_id,
      shipping_price: this.shippingPrice
    })
    this.orderService.addAddress(this.orderData.user_id, this.addressForm.value).subscribe((response: any) => {
      this.editAddress = false
      this.getOrderData(this.orderIdFromRoute)
    })
  }

  /**
   * Updates the address data for the current order.
   * - Calls the `updateAddress` method of the `orderService` to update the address.
   * - Upon successful response, sets `editAddress` to false and calls `getOrderData` to refresh the order data.
   */
  updateAddressData() {
    console.log(this.addressForm.value, this.customAddress)
    this.orderService.updateAddress(this.customAddress.id, this.orderData.user_id, this.addressForm.value).subscribe((response: any) => {
      console.log('Address updated successfully', response)
      this.editAddress = false
      this.getOrderData(this.orderIdFromRoute)
      // Reset the form to avoid showing last added data
    })
  }
}
