<section class="stacksBlockContainer" id="block-categories">
  <div id="settingsTabs" *ngIf="checkSettings()">
    <a (click)="showDataTab()" [ngClass]="checkData() ? 'active' : ''">{{ 'Data' | translate }}</a>
    <a (click)="showStyleTab()" [ngClass]="checkStyle() ? 'active' : ''">{{ 'Style' | translate }}</a>
  </div>
  <!-- Sidebar -->
  <div *ngIf="checkSidebar()" [ngClass]="builderService.standalone ? '' : 'disabled'" class="blockSidebar" (click)="appendBlock($event)">
    <img src="assets/images/blocks/category.png" alt="" srcset="" />
    <p>{{ 'Categories' | translate }}</p>
  </div>
  <!-- Mobile Preview -->
  <div class="previewCategories previewMobile" *ngIf="checkPreview() && getBlockFields(blockId)" id="{{ 'blockid' + blockId }}" cdkDrag [cdkDragData]="blockId">
    <mat-progress-bar mode="indeterminate" *ngIf="showCategoriesProgress"></mat-progress-bar>
    <div class="blockControlHandlers" *ngIf="!builderService.globals">
      <div class="editBlock blockActionBtn" (click)="showData(blockId)">
        <mat-icon>edit</mat-icon>
      </div>
      <div class="blockHandle blockActionBtn" cdkDragHandle>
        <mat-icon>open_with</mat-icon>
      </div>
      <div class="deleteBlock blockActionBtn" (click)="deleteBlock(blockId)">
        <mat-icon>close</mat-icon>
      </div>
    </div>
    <div class="blockPlaceholder" *cdkDragPlaceholder></div>
    <div
      [id]="
        getBlockFields(blockId)?.style?.categories_style == 'oneColumn'
          ? 'style_3'
          : getBlockFields(blockId)?.style?.categories_style == 'TwoColumns'
            ? 'style_4'
            : getBlockFields(blockId)?.style?.categories_style == 'ThreeColumns'
              ? 'style_5'
              : getBlockFields(blockId)?.style?.categories_style == 'List'
                ? 'style_6'
                : 'style_7'
      "
    >
      <div class="horizontalSliderCategoryContainer">
        <p *ngIf="!getCategoriesData(blockId)?.[0]" style="color: red">{{ 'No Categories Available with the selected settings' | translate }}</p>
        <ul class="horizonalSliderCategory" [ngStyle]="getBlockFields(blockId)?.style">
          <li class="itemCategory" *ngFor="let cat of getCategoriesData(blockId)">
            <div class="flexCategoriesContainer">
              <div class="pictureCategoriesContainer">
                <img *ngIf="cat?.categoryImage" src="{{ cat?.categoryImage }}" />
                <img *ngIf="!cat?.categoryImage" src="assets/images/empty-image.png" />
              </div>
              <div class="descriptionCategory">
                <div class="headingCategory">
                  <span class="card-title" [innerHTML]="cat?.categoryName"></span>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <!-- Data and Style Tabs -->
  <div class="previewFields" *ngIf="checkData() && getBlockFields(blockId)">
    <mat-progress-bar mode="indeterminate" *ngIf="showCategoriesProgress"></mat-progress-bar>
    <h4 class="listTitle">{{ 'Query' | translate }}</h4>
    <mat-divider></mat-divider>
    <ul>
      <li>
        <mat-label>{{ 'Source' | translate }}</mat-label>
        <mat-select (selectionChange)="queryChange('source', blockId, $event, 'select')" value="{{ getBlockFields(blockId).data?.source }}">
          <mat-option value="all">
            {{ 'Show All' | translate }}
          </mat-option>
          <mat-option value="by_id">
            {{ 'Manual Selection' | translate }}
          </mat-option>
        </mat-select>
      </li>
    </ul>
    <ul>
      <li *ngIf="getBlockFields(blockId).data?.source == 'by_id'">
        <mat-label>{{ 'Categories' | translate }}</mat-label>
        <mat-select multiple (selectionChange)="queryChange('categories', blockId, $event, 'select')" [(value)]="getBlockFields(blockId).data.categories">
          <mat-option *ngFor="let category of this.allCategories" [value]="category.categoryId">
            {{ category.categoryName }}
          </mat-option>
        </mat-select>
      </li>
      <li *ngIf="getBlockFields(blockId).data?.source == 'by_id' && allowedSubcategories.length">
        <mat-label>{{ 'SubCategories' | translate }}</mat-label>
        <mat-select multiple (selectionChange)="queryChange('sub_categories', blockId, $event, 'select')">
          <mat-option *ngFor="let subCategory of allowedSubcategories" value="{{ subCategory.subCategoryId }}">
            {{ subCategory.subcategory }}
          </mat-option>
        </mat-select>
      </li>
    </ul>
    <ul>
      <li *ngIf="getBlockFields(blockId).data.source == 'all'">
        <mat-label>{{ 'Limit' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="50" min="1" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).data?.limit }}" (change)="queryChange('limit', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
    </ul>
    <ul>
      <li>
        <mat-label>{{ 'Order By' | translate }}</mat-label>
        <mat-select (selectionChange)="queryChange('orderby', blockId, $event, 'select')" value="{{ getBlockFields(blockId).data?.orderby }}">
          <mat-option value="title">
            {{ 'Title' | translate }}
          </mat-option>
        </mat-select>
      </li>
    </ul>
    <ul>
      <li>
        <mat-label>{{ 'Order' | translate }}</mat-label>
        <mat-select (selectionChange)="queryChange('order', blockId, $event, 'select')" value="{{ getBlockFields(blockId).data?.order }}">
          <mat-option value="ASC">
            {{ 'Ascendingly' | translate }}
          </mat-option>
          <mat-option value="DESC">
            {{ 'Descendingly' | translate }}
          </mat-option>
        </mat-select>
      </li>
    </ul>
  </div>
  <div class="styleFields" *ngIf="checkStyle() && getBlockFields(blockId)">
    <ul>
      <li>
        <mat-label>{{ 'Categories Style' | translate }}</mat-label>
        <mat-select (selectionChange)="styleChange('categories_style', blockId, $event, 'select')" value="{{ getBlockFields(blockId).style.categories_style }}">
          <mat-option value="oneColumn">
            {{ 'One Column' | translate }}
          </mat-option>
          <mat-option value="TwoColumns">
            {{ 'Two Columns' | translate }}
          </mat-option>
          <mat-option value="List">
            {{ 'List' | translate }}
          </mat-option>
          <mat-option value="List2">
            {{ 'List 2' | translate }}
          </mat-option>
        </mat-select>
      </li>
    </ul>
    <h4 class="listTitle">{{ 'Margins' | translate }}</h4>
    <mat-divider></mat-divider>
    <ul>
      <li>
        <mat-label>{{ 'Margin Top' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="-100" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['margin-top']?.slice(0, -2) }}" (change)="styleChange('margin-top', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
      <li>
        <mat-label>{{ 'Margin Right' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="-100" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['margin-right']?.slice(0, -2) }}" (change)="styleChange('margin-right', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
      <li>
        <mat-label>{{ 'Margin Bottom' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="-100" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['margin-bottom']?.slice(0, -2) }}" (change)="styleChange('margin-bottom', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
      <li>
        <mat-label>{{ 'Margin Left' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="-100" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['margin-left']?.slice(0, -2) }}" (change)="styleChange('margin-left', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
    </ul>
    <mat-label>{{ 'Paddings' | translate }}</mat-label>

    <mat-divider></mat-divider>
    <ul>
      <li>
        <mat-label>{{ 'Padding Top' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="1" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['padding-top']?.slice(0, -2) }}" (change)="styleChange('padding-top', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
      <li>
        <mat-label>{{ 'Padding Right' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="1" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['padding-right']?.slice(0, -2) }}" (change)="styleChange('padding-right', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
      <li>
        <mat-label>{{ 'Padding Bottom' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="1" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['padding-bottom']?.slice(0, -2) }}" (change)="styleChange('padding-bottom', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
      <li>
        <mat-label>{{ 'Padding Left' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="1" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['padding-left']?.slice(0, -2) }}" (change)="styleChange('padding-left', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
    </ul>
  </div>
</section>
