<div class="containerWrapper smallContainerWrapper">
  <form>
    <mat-form-field appearance="outline" id="versionName">
      <mat-label>{{ 'Enter Version Name' | translate }}</mat-label>
      <input matInput [formControl]="versionFormControl" type="text" placeholder="Version Name" />
    </mat-form-field>
  </form>
  <button (click)="newVersion()" id="saveVersion">{{ 'Save Version' | translate }}</button>
</div>
<div class="containerWrapper">
  <table mat-table [dataSource]="dataSource">
    <!--- Note that these columns can be defined in any order.
          The actual rendered columns are set as a property on the row definition" -->

    <!-- Version Name Column -->
    <ng-container matColumnDef="version_name">
      <th mat-header-cell *matHeaderCellDef id="version_name">{{ 'Version Name' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.version_name }}</td>
    </ng-container>

    <!-- Date Column -->
    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef id="date">{{ 'Date' | translate }}</th>
      <td mat-cell *matCellDef="let element">{{ element.date }}</td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef id="version">{{ 'action' | translate }}</th>
      <td mat-cell *matCellDef="let element">
        <button (click)="applyVersion(element.version_name)" class="material-icons-outlined">done</button>
        <button (click)="deleteVersion(element.version_name)" class="material-icons-outlined deleteIcon">delete</button>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</div>
