<div class="containerWrapper">
  <div class="tableWrapper">
    <div class="tableHeader">
      <h1>{{ 'Shipping' | translate }}</h1>
      <div class="headerBtns">
        <button (click)="addCountry()">{{ 'Add New Country' | translate }}</button>
        <form class="searchBar">
          <input type="text" matInput placeholder="{{ 'Search' | translate }}" />
        </form>
      </div>
    </div>
    <table class="table table-bordered">
      <thead></thead>
      <tbody>
        <tr *ngFor="let country of countries; let i = index">
          <td>
            <div class="countryDesc">
              <mat-form-field class="attrValue">
                <mat-label>{{ 'Country Name' | translate }}</mat-label>
                <input matInput [(ngModel)]="country.countryName" (ngModelChange)="activateSave()" class="form-control" />
              </mat-form-field>
              <mat-form-field class="attrValue price">
                <mat-label>{{ 'Shipping Price' | translate }}</mat-label>
                <input matInput type="number" [(ngModel)]="country.countryPrice" (ngModelChange)="activateSave()" class="form-control" />
              </mat-form-field>
              <div class="headerBtns">
                <button mat-icon-button class="input-group-addon" type="button" (click)="addGovernorate(country)">
                  <p class="addGov" *ngIf="!mobile">{{ 'Add Governorate' | translate }}</p>
                  <span *ngIf="mobile" class="material-icons material-icons-outlined addGov">add</span>
                </button>
                <button mat-icon-button class="input-group-addon" type="button" (click)="removeCountry(i)">
                  <span *ngIf="mobile" class="material-icons material-icons-outlined removeCountry">remove</span>
                  <p class="removeCountry" *ngIf="!mobile">{{ 'Remove' | translate }}</p>
                </button>
              </div>
            </div>
            <div class="gov">
              <div class="input-group govs" *ngFor="let gov of country.governorates; let j = index">
                <span *ngIf="mobile" class="material-icons-outlined closeDialog" (click)="removeGov(country, j)"> close </span>
                <div class="govHeader">
                  <mat-form-field class="govName">
                    <mat-label>{{ 'Governorate Name' | translate }}</mat-label>
                    <input matInput [(ngModel)]="gov.governorateName" (ngModelChange)="activateSave()" class="form-control" />
                  </mat-form-field>
                  <mat-form-field class="govPrice">
                    <mat-label>{{ 'Governorate Price' | translate }}</mat-label>
                    <input matInput [(ngModel)]="gov.governoratePrice" (ngModelChange)="activateSave()" class="form-control" />
                  </mat-form-field>
                </div>
                <button *ngIf="!mobile" mat-icon-button class="input-group-addon removeGov" type="button" (click)="removeGov(country, j)">
                  {{ 'Remove' | translate }}
                </button>
              </div>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <button class="btn btn-primary saveCountry" (click)="saveCountries()" [disabled]="!saveState"><i class="ion-checkmark-round"></i> {{ 'Save' | translate }}</button>

    <!-- <pre class="margin-top">{{ categories | json }}</pre> -->
    <mat-paginator class="tablePaginator" [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons aria-label="Select page of periodic dataSource"> </mat-paginator>
  </div>
</div>
