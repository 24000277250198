import { Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { environment } from 'src/environments/environment'

@Injectable({
  providedIn: 'root'
})
export class LogsService {
  options = {
    headers: new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
  }

  constructor(private http: HttpClient) {}

  getLogs = () => {
    return this.http.get(environment.loggerURL + 'api/log/?password=4YT7ydf38ytzaaHJ')
  }
}
