import { Component, Input, OnInit } from '@angular/core'
import { DomSanitizer } from '@angular/platform-browser'
import { BlockFieldsService } from 'src/app/block-services/block-fields.service'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { BlockDataService } from '../../block-services/block-data.service'
import { MixpanelService } from 'src/app/tracking/mixpanel.service'

@Component({
  selector: 'block-video',
  templateUrl: './block-video.component.html',
  styleUrls: ['./block-video.component.scss']
})
export class BlockVideoComponent implements OnInit {
  previewBlock = false
  styleFields = false
  blockData: any

  previewFields = false

  blockFields: any
  videoURL: any = '65JrtwtTOdc'

  blockId = ''

  @Input() nestedBlock: any = {}
  @Input() importedBlockData: any
  showProgress = false
  constructor(
    private blockDataService: BlockDataService,
    private blockFieldsService: BlockFieldsService,
    private sanitizer: DomSanitizer,
    public builderService: BuilderService,
    private mixPanel: MixpanelService
  ) {}

  ngOnInit() {
    if (this.importedBlockData) {
      this.previewBlock = true
      this.previewFields = false
      this.styleFields = false
    }
    if (this.nestedBlock.type == 'video') {
      this.blockData = this.nestedBlock
      this.blockId = this.nestedBlock.id
      this.previewBlock = true
    } else {
      this.blockData = this.importedBlockData ? this.importedBlockData : this.blockFieldsService.getVideoBlockFields()
      if (!this.blockId) {
        this.blockId = this.blockDataService.getUniqueId(2)
      }
    }
    this.videoURL = this.sanitizer.bypassSecurityTrustResourceUrl(this.blockData.data.src)
  }
  /**
   * Appends Block to the Preview, Initial checks if the block is added as initial block addition or appended live
   */
  addBlockToPreview(initial: any = '') {
    let blockVideoData = {
      component: BlockVideoComponent,
      index: 'latest',
      element: this,
      initial: initial
    }
    this.blockDataService.sendBlockData(blockVideoData)
    this.builderService.blocksCollapsed = true
  }

  appendBlock($event: any) {
    if (this.builderService.getTour()) {
      this.builderService.onboardingBlockAdded = true
    }
    let clonedBlockData = { ...this.blockData }
    this.blockId = this.blockDataService.appendBlocksData(clonedBlockData)
    this.addBlockToPreview()
    this.builderService.logData('Video Block Added', 'Block Add', 'Video Block Added').subscribe(
      (response) => {},
      (err) => {}
    )
    this.mixPanel.track('Block Added', {
      projectid: this.builderService.getSelectedProject(),
      block: 'video'
    })
  }

  /**
   * Sync the clicked block on click inside the mobile preview
   */
  showData(blockId: any) {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      // show data of the last element instead
      blockId = this.blockDataService.getLatestBlockId()
    }
    this.blockFieldsService.clickedBlockId = blockId
    this.blockDataService.sendCurrentBlock(BlockVideoComponent)
  }

  queryChange(query: any, blockId: any, $event: any, type: string) {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return
    }
    let productsBlockFields = this.blockDataService.getSelectedBlockData(blockId)
    /**
     * Modifying Nested Object Directly doesn't work, so we have to clone the data object first then modify it
     */
    let clonedBlockFieldsData = { ...productsBlockFields.data }
    let data = ''
    if (type == 'select') {
      data = $event.value
    } else if (type == 'text') {
      data = $event.currentTarget.value
    } else if (type == 'slider') {
      data = $event.currentTarget.value
    }
    if (data.includes('https://www.youtube.com/')) {
      let linkID = this.youtube_parser(data)
      data = 'https://www.youtube.com/embed/' + linkID
    }
    clonedBlockFieldsData[query] = data
    productsBlockFields.data = clonedBlockFieldsData
    this.videoURL = this.sanitizer.bypassSecurityTrustResourceUrl(data)
  }

  youtube_parser(url: string) {
    var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/
    var match = url.match(regExp)
    return match && match[7].length == 11 ? match[7] : false
  }

  styleChange(style: any, blockId: any, $event: any, type: string) {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return
    }
    let textBlockFields = this.blockDataService.getSelectedBlockData(blockId)
    /**
     * Modifying Nested Object Directly doesn't work, so we have to clone the data object first then modify it
     */
    let clonedBlockFieldsData = { ...textBlockFields.style }
    let data = ''
    if (type == 'select') {
      data = $event.value
    } else if (type == 'text') {
      data = $event.currentTarget.value
    } else if (type == 'slider') {
      data = $event.currentTarget.value + 'px'
    }

    clonedBlockFieldsData[style] = data
    textBlockFields.style = clonedBlockFieldsData
  }

  getBlockFields = (id: string) => {
    if (this.importedBlockData) {
      return this.importedBlockData
    }
    return this.blockDataService.getSelectedBlockData(id)
  }

  showStyleTab = () => {
    this.previewBlock = false
    this.previewFields = false
    this.styleFields = true
  }

  showDataTab = () => {
    this.previewBlock = false
    this.previewFields = true
    this.styleFields = false
  }

  checkSidebar = () => {
    if (!this.previewBlock && !this.previewFields && !this.styleFields) {
      return true
    }
    return false
  }

  checkPreview = () => {
    if (this.previewBlock && !this.previewFields && !this.styleFields) {
      return true
    }
    return false
  }

  checkData = () => {
    if (this.previewFields) {
      return true
    }
    return false
  }

  checkStyle = () => {
    if (this.styleFields && !this.previewFields && !this.previewBlock) {
      return true
    }
    return false
  }

  checkSettings = () => {
    if (!this.checkSidebar() && !this.checkPreview()) {
      return true
    }
    return false
  }

  /**
   * Deletes the Block from the mobile Preview
   * Deletes the Block from the JSON Object
   */
  deleteBlock = (blockId: any) => {
    this.blockDataService.removeBlockData(blockId)
  }

  getVideoSrc = (blockId: string) => {
    return this.getBlockFields(blockId)?.data?.src
  }
}
