<div class="containerWrapper buildWrapper">
  <h1>{{ 'Import Products and Categories' | translate }}</h1>
  <div class="importWrapper">
    <label class="fileUpload" for="productInput"
      >{{ 'Choose Products' | translate }}
      <input type="file" id="productInput" class="stacksBtn blue" (change)="handleFileInput($event, 'products')" value="Import CSV" />
    </label>
    <label class="fileUpload" for="categoryInput"
      >{{ 'Choose-Categories' | translate }}
      <input type="file" class="stacksBtn green" id="categoryInput" (change)="handleFileInput($event, 'category')" value="Import CSV" />
    </label>
  </div>
  <div *ngIf="importProgress > 0" class="buildingBar">
    {{ importProgress }}%
    <mat-progress-bar mode="determinate" [value]="importProgress"></mat-progress-bar>
    <div *ngIf="importProgress == 100" class="importCompletedWrapper">
      <p>{{ 'Data Imported Successfully' | translate }}</p>
      <div *ngIf="this.corruptedImages.length">
        <p>{{ 'images corrupted' | translate }}</p>
        <ul>
          <li *ngFor="let image of corruptedImages">{{ image }}</li>
        </ul>
      </div>

      <button class="stacksBtn blue" *ngIf="this.importingType == 'products'" [routerLink]="['/products']">All Products</button>
      <button class="stacksBtn blue" *ngIf="this.importingType == 'categories'" [routerLink]="['/categories/categories']">All Categories</button>
    </div>
  </div>

  <div id="wooCommerceImporter">
    <h1>{{ 'Import WooCommerce Products and Categories' | translate }}</h1>
    <p>{{ 'Import WooCommerce Stacks Databases' | translate }}</p>
    <div class="labelFieldsWrapper">
      <mat-form-field appearance="outline">
        <mat-label for="wooCommerceUsername">{{ 'WooCommerce Site URL' | translate }}</mat-label>
        <input matInput type="text" id="wooCommerceUsername" placeholder="WooCommerce Site URL" [(ngModel)]="wooUrl" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label for="wooCommerceUsername">{{ 'WooCommerce API Username' | translate }}</mat-label>
        <input matInput type="text" id="wooCommerceUsername" placeholder="WooCommerce REST API Username" [(ngModel)]="wooUser" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label for="wooCommercePassword">{{ 'WooCommerce API Password' | translate }}</mat-label>
        <input matInput type="text" id="wooCommercePassword" placeholder="WooCommerce REST API Password" [(ngModel)]="wooPass" />
      </mat-form-field>

      <mat-form-field appearance="outline">
        <mat-label for="wooCommerceItemsToFetch">{{ 'Number of items to fetch' | translate }}</mat-label>
        <input matInput type="text" id="wooCommerceItemsToFetch" placeholder="-1 to fetch all items" [(ngModel)]="wooCommerceItemsToFetch" />
      </mat-form-field>
      <mat-form-field appearance="outline">
        <mat-label for="woocommerceOffsetPage">{{ 'offset Items' | translate }}</mat-label>
        <input matInput type="text" id="woocommerceOffsetPage" placeholder="1" [(ngModel)]="woocommerceOffsetPage" />
      </mat-form-field>
    </div>
    <div class="buttonsWrapper">
      <button class="stacksBtn blue" (click)="startImportingWooProducts()">{{ 'Import WooCommerce Products' | translate }}</button>
      <button class="stacksBtn blue" (click)="startImportingWooCategories()">{{ 'Import WooCommerce Categories' | translate }}</button>
    </div>
  </div>
</div>
