import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatTable, MatTableDataSource } from '@angular/material/table'
import { CloudService } from '../cloud.service'
import { MatSort } from '@angular/material/sort'
import { ChartConfiguration } from 'chart.js'
import { BaseChartDirective } from 'ng2-charts'
import { BuilderService } from 'src/app/builder-services/builder.service'

export interface subscribersTableColumns {
  name: string
  date: string
  mail: string
}

let ELEMENT_DATA: subscribersTableColumns[] = []
@Component({
  selector: 'cloud-all-subs',
  templateUrl: './cloud-all-subs.component.html',
  styleUrls: ['./cloud-all-subs.component.scss']
})
export class CloudAllSubsComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatTable)
  table!: MatTable<subscribersTableColumns>

  @ViewChild(MatSort) sort!: MatSort
  @ViewChild(BaseChartDirective) chart?: BaseChartDirective

  constructor(
    private cloudService: CloudService,
    public builderService: BuilderService
  ) {}
  public subscribersColumns: string[] = []
  public dataSource: any = []
  subs_dates: any = []
  subs_data_counts: any = []
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator
  }

  ngOnInit(): void {
    this.getSubscribers()
  }

  /**
   * Fill subscribers Table
   */
  getSubscribers = () => {
    this.builderService.showUpdateProgress = true
    this.subscribersColumns = ['date', 'name', 'mail']
    ELEMENT_DATA = []
    let cloudSubs = localStorage.getItem('cloudAllSubs')
    if (cloudSubs) {
      this.renderSubscribers(JSON.parse(cloudSubs))
    } else {
      this.fetchNewSubscribers()
    }
  }
  fetchSubscribers = () => {
    localStorage.removeItem('cloudAllSubs')
    this.getSubscribers()
  }
  /**
   * Retrieves the new subscribers from the server
   */
  fetchNewSubscribers = () => {
    this.cloudService.getAllSubscribers().subscribe((result: any) => {
      localStorage.setItem('cloudAllSubs', JSON.stringify(result))
      this.renderSubscribers(result)
    })
  }
  /**
   * Renders the subscriptions data
   */
  renderSubscribers = (result: any) => {
    this.builderService.showUpdateProgress = false
    let all_subs = result.data.all_subs
    all_subs = Object.entries(all_subs)
      .sort()
      .reduce((o: any, [k, v]) => ((o[k] = v), o), {})
    this.subs_data_counts = []
    this.subs_dates = []
    for (const key in all_subs) {
      if (Object.prototype.hasOwnProperty.call(all_subs, key)) {
        const element = all_subs[key]
        if (!element.email.length) {
          continue
        }
        this.subs_dates.push(key)
        this.subs_data_counts.push(element.count)

        this.cloudService.allSubsData[key.substring(0, 7)] = element.allSubs

        for (const key_names in element.names) {
          if (Object.prototype.hasOwnProperty.call(element.names, key_names)) {
            const element_names = element.names[key_names]
            ELEMENT_DATA.push({ date: element.date, name: element.names[key_names], mail: element.email[key_names] })
          }
        }
      }
    }
    this.dataSource = new MatTableDataSource<subscribersTableColumns>(ELEMENT_DATA)
    this.dataSource.paginator = this.paginator
    this.subChartData.labels = this.subs_dates
    this.subChartData.datasets[0].data = this.subs_data_counts
    this.chart?.update()
    setTimeout(() => {
      this.table.renderRows()
    }, 1000)
  }

  applySubFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value
    this.dataSource.filter = filterValue.trim().toLowerCase()
  }

  public subChartData: ChartConfiguration['data'] = {
    datasets: [
      {
        data: [],
        label: 'All Subscribers',
        yAxisID: 'y-axis-1',
        backgroundColor: 'rgba(255,0,0,0.3)',
        borderColor: 'red',
        pointBackgroundColor: 'rgba(148,159,177,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(148,159,177,0.8)',
        fill: 'origin'
      }
    ]
    // labels: [ 'January', 'February', 'March', 'April', 'May', 'June', 'July' ]
  }

  public subChartOptions: ChartConfiguration['options'] = {
    elements: {
      line: {
        tension: 0.5
      }
    },
    scales: {
      // We use this empty structure as a placeholder for dynamic theming.
      x: {},
      'y-axis-1': {
        position: 'right',
        grid: {
          color: 'rgba(255,0,0,0.3)'
        },
        ticks: {
          color: 'red'
        }
      }
    }
  }
}
