<div id="adminWrapper" class="containerWrapper" *ngIf="loginService.getCurrentUser().admin">
  <!-- <mat-progress-bar mode="indeterminate" *ngIf="showTemplatesProgress"></mat-progress-bar> -->
  <h1>Admin Settings</h1>
  <!-- <div id="loginAsUser" class="adminSmallWidget">
        <h2>Login as User</h2>
        <div class="adminInnerContainer">
            <ul>
                <li>
                <mat-form-field appearance="outline">
                    <mat-label>Type email of user</mat-label>
                    <input matInput placeholder="bassam.tarek@creiden.com" id="loginProjectDomain" (change)="updateLoginValue($event)" value="{{this.loginProjectDomain}}">
                </mat-form-field>
                <button href="#" class="stacksBtn blue" (click)="loginAsUser()">Login</button> 
                </li>
            </ul>
        </div>
    </div> -->
  <div class="adminSmallWidget">
    <h2>Admin analytics</h2>
    <div class="adminInnerContainer">
      <ul class="linksList">
        <li [routerLink]="['self']">Self</li>
        <li [routerLink]="['cloud']">Cloud</li>
        <li [routerLink]="['logs']">Logs</li>
        <li [routerLink]="['apps-uploaded']">Apps Uploaded</li>
        <li [routerLink]="['all-apps']">All Apps</li>
        <li [routerLink]="['dynamicSamples']">Dynamic Samples</li>
      </ul>
    </div>
  </div>
  <div class="adminSmallWidget">
    <h2>Admin Settings</h2>
    <div class="adminInnerContainer">
      <button class="stacksBtn blue" (click)="cancelAllBuilds()">Cancel All Builds</button>
  </div>
</div>
