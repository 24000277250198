<section class="stacksBlockContainer" id="block-button">
  <div id="settingsTabs" *ngIf="checkSettings()">
    <a (click)="showDataTab()" [ngClass]="checkData() ? 'active' : ''">{{ 'Data' | translate }}</a>
    <a (click)="showStyleTab()" [ngClass]="checkStyle() ? 'active' : ''">{{ 'Style' | translate }}</a>
  </div>
  <!-- Sidebar -->
  <div *ngIf="checkSidebar()" class="blockSidebar" (click)="appendBlock($event)">
    <img src="assets/images/blocks/button.png" alt="" srcset="" />
    <p>{{ 'button' | translate }}</p>
  </div>
  <!-- Mobile Preview -->
  <div class="previewButton previewMobile" *ngIf="checkPreview()" id="{{ 'blockid' + blockId }}" [cdkDragData]="blockId" cdkDrag>
    <div class="blockControlHandlers" *ngIf="!builderService.globals">
      <div class="editBlock blockActionBtn" (click)="showData(blockId)">
        <mat-icon>edit</mat-icon>
      </div>
      <div class="blockHandle blockActionBtn" cdkDragHandle>
        <mat-icon>open_with</mat-icon>
      </div>
      <div class="deleteBlock blockActionBtn" (click)="deleteBlock(blockId)">
        <mat-icon>close</mat-icon>
      </div>
    </div>
    <div class="blockPlaceholder" *cdkDragPlaceholder></div>
    <div class="btnBlockWrapper" [ngStyle]="getBlockFields(blockId)?.wrapperStyle">
      <button
        class="btnBlock"
        [ngClass]="[getBlockFields(blockId)?.style?.colorSource ? getBlockFields(blockId)?.style?.colorSource : 'globalColors', getBlockFields(blockId).buttonClass?.buttonStyle, getBlockFields(blockId).buttonClass?.buttonSize, getBlockFields(blockId).buttonClass?.buttonIcon]"
        [ngStyle]="getBlockFields(blockId)?.style"
      >
        <mat-icon *ngIf="getBlockFields(blockId).buttonClass?.buttonIcon == 'leftIcon'">{{ getBlockFields(blockId).style?.icon }}</mat-icon>
        {{ getBlockFields(blockId).data?.btnText }}
        <mat-icon *ngIf="getBlockFields(blockId).buttonClass?.buttonIcon == 'rightIcon'">{{ getBlockFields(blockId).style?.icon }}</mat-icon>
      </button>
    </div>
  </div>
  <!-- Data and Style Tabs -->
  <div class="previewFields" *ngIf="checkData()">
    <ul>
      <li>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'Button Text' | translate }}</mat-label>
          <input matInput value="{{ getBlockFields(blockId).data?.btnText }}" (keyup)="queryChange('btnText', blockId, $event, 'text')" />
        </mat-form-field>
      </li>
    </ul>
    <h4 class="listTitle">{{ 'Linking' | translate }}</h4>
    <mat-divider></mat-divider>
    <button class="stacksBtn blue refetchData" (click)="refetchData()">
      <mat-icon class="material-icons material-icons-outlined">refresh</mat-icon>
      {{ ' Refetch Products & Categories' | translate }}
    </button>
    <mat-divider></mat-divider>
    <mat-progress-bar mode="indeterminate" *ngIf="builderService.showSettingsProgress"></mat-progress-bar>
    <ul>
      <li>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'Link Type' | translate }}</mat-label>
          <mat-select (selectionChange)="queryChange('linkType', blockId, $event, 'select')" value="{{ getBlockFields(blockId).data?.linkType }}">
            <mat-option value="home">
              {{ 'Home' | translate }}
            </mat-option>
            <mat-option value="products" *ngIf="!builderService.standalone">
              {{ 'Products' | translate }}
            </mat-option>
            <mat-option value="allProductsCategories">
              {{ 'All Products Categories' | translate }}
            </mat-option>
            <mat-option value="products">
              {{ 'Products' | translate }}
            </mat-option>
            <mat-option value="productsCategories">
              {{ 'Product Category' | translate }}
            </mat-option>
            <mat-option value="productsSubCategories">
              {{ 'Product Sub Category' | translate }}
            </mat-option>
            <mat-option value="posts" *ngIf="!builderService.standalone">
              {{ 'Posts' | translate }}
            </mat-option>
            <mat-option value="externalURL">
              {{ 'Externa url' | translate }}
            </mat-option>
            <mat-option value="account">
              {{ 'Account Page' | translate }}
            </mat-option>
            <mat-option value="signup">
              {{ 'Signup / Signin' | translate }}
            </mat-option>
            <mat-option value="contact">
              {{ 'Contact Us' | translate }}
            </mat-option>
            <mat-option value="about">
              {{ 'About Us' | translate }}
            </mat-option>
            <mat-option value="orders">
              {{ 'Orders' | translate }}
            </mat-option>
            <mat-option value="cart">
              {{ 'Cart' | translate }}
            </mat-option>
            <mat-option value="terms">
              {{ 'Terms' | translate }}
            </mat-option>
            <mat-option *ngFor="let customView of builderService.getCustomViews()" value="{{ customView.view_name }}">
              {{ customView.view_name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </li>
      <li *ngIf="getBlockFields(blockId).data?.linkType == 'products' && !builderService.standalone">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'Product' | translate }}</mat-label>
          <mat-select (selectionChange)="queryChange('product', blockId, $event, 'select')" [(value)]="getBlockFields(blockId).data.product">
            <mat-option *ngFor="let product of storedProducts" [value]="product.id">
              {{ product.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </li>
      <li *ngIf="getBlockFields(blockId).data?.linkType == 'productsCategories' && !builderService.standalone">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'Product Category' | translate }}</mat-label>
          <mat-select (selectionChange)="queryChange('categories', blockId, $event, 'select')" [(value)]="getBlockFields(blockId).data.categories">
            <mat-option *ngFor="let productCategory of categoriesService.storedCategories" [value]="productCategory.id">
              {{ productCategory.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </li>

      <li *ngIf="getBlockFields(blockId).data?.linkType == 'productsCategories' || (getBlockFields(blockId).data?.linkType == 'productsSubCategories' && builderService.standalone)">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'Product Category' | translate }}</mat-label>
          <mat-select (selectionChange)="queryChange('categories', blockId, $event, 'select'); getCat($event)" [(value)]="getBlockFields(blockId).data.categories">
            <mat-option *ngFor="let productCategory of standaloneCategoriesService.storedStandaloneCategories" [value]="productCategory.id">
              {{ productCategory.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </li>
      <li *ngIf="getBlockFields(blockId).data?.linkType == 'productsSubCategories' && builderService.standalone && this.standaloneCategoriesService.availableSubs?.length > 0">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'Product Sub Category' | translate }}</mat-label>
          <mat-select (selectionChange)="queryChange('subcategories', blockId, $event, 'select')" [(value)]="getBlockFields(blockId).data.subcategories">
            <mat-option *ngFor="let subCat of this.standaloneCategoriesService.availableSubs" [value]="subCat.subCategoryId">
              {{ subCat.subcategory }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </li>
      <li *ngIf="getBlockFields(blockId).data?.linkType == 'products' && builderService.standalone">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'Standalone Product' | translate }}</mat-label>
          <mat-select (selectionChange)="queryChange('product', blockId, $event, 'select')" [(value)]="getBlockFields(blockId).data.product">
            <mat-option *ngFor="let product of standaloneProductsService.storedStandaloneProducts" [value]="product.id">
              {{ product.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </li>
      <li *ngIf="getBlockFields(blockId).data?.linkType == 'productsCategories' && builderService.standalone">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'Product Category' | translate }}</mat-label>
          <mat-select (selectionChange)="queryChange('categories', blockId, $event, 'select')" [(value)]="getBlockFields(blockId).data.categories">
            <mat-option *ngFor="let productCategory of standaloneCategoriesService.storedStandaloneCategories" [value]="productCategory.id">
              {{ productCategory.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </li>
      <li *ngIf="getBlockFields(blockId).data?.linkType == 'posts'">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'Post' | translate }}</mat-label>
          <mat-select (selectionChange)="queryChange('post', blockId, $event, 'select')" [(value)]="getBlockFields(blockId).data.post">
            <mat-option *ngFor="let post of storedPosts" [value]="post.ID">
              {{ post.post_title }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </li>
      <li *ngIf="getBlockFields(blockId).data?.linkType == 'externalURL'">
        <mat-form-field appearance="outline">
          <mat-label>{{ 'Externa url' | translate }}</mat-label>
          <input matInput value="{{ getBlockFields(blockId).data?.externalURL }}" (keyup)="queryChange('externalURL', blockId, $event, 'text')" />
        </mat-form-field>
      </li>
    </ul>
  </div>
  <div class="styleFields" *ngIf="checkStyle()">
    <h4 class="listTitle">{{ 'General Settings' | translate }}</h4>
    <mat-divider></mat-divider>
    <ul>
      <!-- <li>
        <mat-label>Width</mat-label>
        <mat-slider max="100" min="0" step="1" thumbLabel="true"
          value="{{getBlockFields(blockId).style['width']?.slice(0, -1)}}" (change)="styleChange('width',
          blockId,
          $event, 'sliderPercentage')">
        </mat-slider>
      </li> -->
      <li>
        <mat-label>{{ 'Style' | translate }}</mat-label>
        <mat-select (selectionChange)="styleChange('buttonStyle', blockId, $event, 'select', 'buttonClass')" value="{{ getBlockFields(blockId).buttonClass?.buttonStyle }}">
          <mat-option value="primaryButton">
            {{ 'Primary' | translate }}
          </mat-option>
          <mat-option value="secondaryButton">
            {{ 'Secondary' | translate }}
          </mat-option>
          <mat-option value="tertiaryButton">
            {{ 'Third' | translate }}
          </mat-option>
          <mat-option value="hyperlinkButton">
            {{ 'Hyperlink' | translate }}
          </mat-option>
        </mat-select>
      </li>
      <li>
        <mat-label>{{ 'Size' | translate }}</mat-label>
        <mat-select (selectionChange)="styleChange('buttonSize', blockId, $event, 'select', 'buttonClass')" value="{{ getBlockFields(blockId).buttonClass?.buttonSize }}">
          <mat-option value="SmallButton">
            {{ 'small' | translate }}
          </mat-option>
          <mat-option value="MediumButton">
            {{ 'Medium' | translate }}
          </mat-option>
          <mat-option value="LargeButton">
            {{ 'large' | translate }}
          </mat-option>
        </mat-select>
      </li>
      <li *ngIf="getBlockFields(blockId).buttonClass?.buttonSize != 'LargeButton'">
        <mat-label>{{ 'Align' | translate }}</mat-label>
        <mat-select (selectionChange)="styleChange('justify-content', blockId, $event, 'select', 'wrapperStyle')" value="{{ getBlockFields(blockId).wrapperStyle ? getBlockFields(blockId).wrapperStyle['justify-content'] : '' }}">
          <mat-option value="left">
            {{ 'Left' | translate }}
          </mat-option>
          <mat-option value="center">
            {{ 'Center' | translate }}
          </mat-option>
          <mat-option value="right">
            {{ 'Right' | translate }}
          </mat-option>
        </mat-select>
      </li>
      <li>
        <mat-label>{{ 'Icon' | translate }}</mat-label>
        <mat-select (selectionChange)="styleChange('buttonIcon', blockId, $event, 'select', 'buttonClass')" value="{{ getBlockFields(blockId).buttonClass?.buttonIcon }}">
          <mat-option value="noIcon">
            {{ 'No Icon' | translate }}
          </mat-option>
          <mat-option value="leftIcon">
            {{ 'Icon on left' | translate }}
          </mat-option>
          <mat-option value="rightIcon">
            {{ 'Icon on right' | translate }}
          </mat-option>
        </mat-select>
      </li>
      <li>
        <button class="stacksBtn green dialogSelectorBtn" (click)="selectIconDialog(blockId)">{{ 'Change Icon' | translate }}</button>
      </li>
      <li class="contentCenter contentContainerColor">
        <label>{{ 'Color Source' | translate }}</label>
        <mat-form-field>
          <mat-select (selectionChange)="styleChange('colorSource', blockId, $event, 'select', 'style')" value="{{ getBlockFields(blockId).style['colorSource'] ? getBlockFields(blockId).style['colorSource'] : 'globalColors' }}">
            <mat-option value="globalColors">
              {{ 'Global Colors' | translate }}
            </mat-option>
            <mat-option value="customColors">
              {{ 'Custom Color' | translate }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </li>
      <li class="contentCenter contentContainerColor" *ngIf="getBlockFields(blockId).style['colorSource'] === 'customColors' && getBlockFields(blockId).buttonClass?.buttonStyle !== 'secondaryButton' && getBlockFields(blockId).buttonClass?.buttonStyle !== 'hyperlinkButton'">
        <label>{{ 'Button Background Color' | translate }}</label>
        <mat-form-field>
          <p class="colorPickerValue">{{ getBlockFields(blockId).style['background-color'] }}</p>
          <label for="color" class="colorLabel">
            <input type="color" id="color" matInput class="colorPicker" name="color" (change)="styleChange('background-color', blockId, $event, 'text')" value="{{ getBlockFields(blockId).style['background-color'] }}" />
          </label>
        </mat-form-field>
      </li>
      <li class="contentCenter contentContainerColor" *ngIf="getBlockFields(blockId).style['colorSource'] == 'customColors'">
        <label>{{ 'Button Text Color' | translate }}</label>
        <mat-form-field>
          <p class="colorPickerValue">{{ getBlockFields(blockId).style?.color }}</p>
          <label for="color" class="colorLabel">
            <input type="color" id="color" matInput class="colorPicker" name="color" (change)="styleChange('color', blockId, $event, 'text')" value="{{ getBlockFields(blockId).style?.color }}" />
          </label>
        </mat-form-field>
      </li>
    </ul>
    <h4 class="listTitle">{{ 'Margins' | translate }}</h4>
    <mat-divider></mat-divider>
    <ul>
      <li>
        <mat-label>{{ 'Margins-top' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="-100" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['margin-top']?.slice(0, -2) }}" (change)="styleChange('margin-top', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
      <li>
        <mat-label>{{ 'Margins-Right' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="-100" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['margin-right']?.slice(0, -2) }}" (change)="styleChange('margin-right', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
      <li>
        <mat-label>{{ 'Margin Bottom' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="-100" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['margin-bottom']?.slice(0, -2) }}" (change)="styleChange('margin-bottom', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
      <li>
        <mat-label>{{ 'Margin Left' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="-100" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['margin-left']?.slice(0, -2) }}" (change)="styleChange('margin-left', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
    </ul>
    <mat-label>{{ 'Paddings' | translate }}</mat-label>
    <mat-divider></mat-divider>
    <ul>
      <li>
        <mat-label>{{ 'Padding Top' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="1" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['padding-top']?.slice(0, -2) }}" (change)="styleChange('padding-top', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
      <li>
        <mat-label>{{ 'Padding Right' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="1" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['padding-right']?.slice(0, -2) }}" (change)="styleChange('padding-right', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
      <li>
        <mat-label>{{ 'Padding Bottom' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="1" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['padding-bottom']?.slice(0, -2) }}" (change)="styleChange('padding-bottom', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
      <li>
        <mat-label>{{ 'Padding Left' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="1" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['padding-left']?.slice(0, -2) }}" (change)="styleChange('padding-left', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
    </ul>
  </div>
</section>
