import { Component, Input, OnInit } from '@angular/core'
import { BlockFieldsService } from 'src/app/block-services/block-fields.service'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { environment } from 'src/environments/environment'
import { BlockDataService } from '../../block-services/block-data.service'
import { MixpanelService } from 'src/app/tracking/mixpanel.service'
import { ProductsService } from '../block-products/products.service'
import { CategoriesService } from '../block-categories/categories.service'
import { PostsService } from '../block-posts/posts.service'
import { ProductsService as standaloneProductsService } from '../../e-commerce/products/products.service'
import { CategoriesService as standaloneCategoriesService } from '../../e-commerce/categories/categories.service'

@Component({
  selector: 'block-slider',
  templateUrl: './block-slider.component.html',
  styleUrls: ['./block-slider.component.scss']
})
export class BlockSliderComponent implements OnInit {
  @Input() importedBlockData: any
  @Input() nestedBlock: any = {}
  blockData: any
  blockFields: any
  blockId = ''
  blockSliderSrc = ''
  previewBlock = false
  previewFields = false
  showProgress = false
  storedCategories: any = []
  storedPosts: any = []
  storedProducts: any = []
  styleFields = false

  constructor(
    private blockDataService: BlockDataService,
    private blockFieldsService: BlockFieldsService,
    private mixPanel: MixpanelService,
    private postsService: PostsService,
    private productsService: ProductsService,
    public builderService: BuilderService,
    public categoriesService: CategoriesService,
    public standaloneCategoriesService: standaloneCategoriesService,
    public standaloneProductsService: standaloneProductsService
  ) {}

  ngOnInit() {
    if (this.importedBlockData) {
      this.previewBlock = true
      this.previewFields = false
      this.styleFields = false
    }
    if (this.nestedBlock.type == 'slider') {
      this.blockData = this.nestedBlock
      this.blockId = this.nestedBlock.id
      this.previewBlock = true
    } else {
      this.blockData = this.importedBlockData ? this.importedBlockData : this.blockFieldsService.getSliderBlockFields()
      if (!this.blockId) {
        this.blockId = this.blockDataService.getUniqueId(2)
      }
    }
    this.blockSliderSrc = this.blockData.data.src

    this.getStandaloneProducts()
    this.getStandaloneCategories()
    if (this.blockDataService.getSelectedBlockData(this.blockId).data.categories) {
      this.getCat({ value: this.blockDataService.getSelectedBlockData(this.blockId).data.categories })
    }
  }
  /**
   * Appends Block to the Preview, Initial checks if the block is added as initial block addition or appended live
   */
  addBlockToPreview(initial: any = '') {
    let blockSliderData = {
      component: BlockSliderComponent,
      index: 'latest',
      element: this,
      initial: initial
    }
    this.blockDataService.sendBlockData(blockSliderData)
    this.builderService.blocksCollapsed = true
  }

  appendBlock($event: any) {
    if (this.builderService.getTour()) {
      this.builderService.onboardingBlockAdded = true
    }
    let clonedBlockData = { ...this.blockData }
    this.blockId = this.blockDataService.appendBlocksData(clonedBlockData)
    this.addBlockToPreview()
    this.builderService.logData('Slider Block Added', 'Block Add', 'Slider Block Added').subscribe(
      (response) => {},
      (err) => {}
    )
    this.mixPanel.track('Block Added', {
      projectid: this.builderService.getSelectedProject(),
      block: 'slider'
    })
  }

  /**
   * Sync the clicked block on click inside the mobile preview
   */
  showData(blockId: any) {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      // show data of the last element instead
      blockId = this.blockDataService.getLatestBlockId()
    }
    this.blockFieldsService.clickedBlockId = blockId
    this.blockDataService.sendCurrentBlock(BlockSliderComponent)
  }

  processFile(fileInput: any, type: string, obj: any, name: string, blockId: string) {
    const file: File = fileInput.target.files[0]
    this.showProgress = true
    if (this.builderService.standalone) {
      this.builderService.uploadImage(file, this.builderService.selectedProject, 'custom-images')?.subscribe((res) => {
        this.showProgress = false
        let imgBlockFields = this.blockDataService.getSelectedBlockData(blockId)
        /**
         * Modifying Nested Object Directly doesn't work, so we have to clone the data object first then modify it
         */
        let clonedBlockFieldsData = { ...imgBlockFields.data }
        clonedBlockFieldsData['slides'][obj][name] = res
        imgBlockFields.data = clonedBlockFieldsData
      })
    } else {
      const reader = new FileReader()
      reader.addEventListener('load', (event: any) => {
        this.builderService.uploadFileCustomerServer(file, this.builderService.selectedProject, type).subscribe(
          (res) => {
            this.showProgress = false
            let imgBlockFields = this.blockDataService.getSelectedBlockData(blockId)
            /**
             * Modifying Nested Object Directly doesn't work, so we have to clone the data object first then modify it
             */
            let clonedBlockFieldsData = { ...imgBlockFields.data }
            clonedBlockFieldsData['slides'][obj][name] = res
            imgBlockFields.data = clonedBlockFieldsData
          },
          (err) => {
            this.showProgress = false
            alert('error uploading file, please contact support')
          }
        )
      })

      reader.readAsDataURL(file)
    }
  }
  /**
   * get all the slider block fields from the service and adjust the src only
   * @param $event change event on the input field
   */
  imgSrcChange($event: any, blockId: any) {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return
    }
  }

  queryChange(query: any, blockId: any, $event: any, type: string, index: 0, dataType: string = 'slides') {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return
    }
    let productsBlockFields = this.getBlockFields(blockId)
    /**
     * Modifying Nested Object Directly doesn't work, so we have to clone the data object first then modify it
     */
    let clonedBlockFieldsData = { ...productsBlockFields.data }
    let data = ''
    if (type == 'select') {
      data = $event.value
    } else if (type == 'text') {
      data = $event.currentTarget.value
    } else if (type == 'slider') {
      data = $event.currentTarget.value
    } else if (type == 'multilevelText') {
      data = $event.currentTarget.value
    } else if (type == 'multilevelSelect') {
      data = $event.value
    }

    if (type == 'multilevelText' || type == 'multilevelSelect') {
      clonedBlockFieldsData[dataType][index][query] = data
    } else {
      clonedBlockFieldsData[query] = data
    }
    productsBlockFields.data = clonedBlockFieldsData
    // console.log(productsBlockFields.data);
    // this.builderService.globalFooter = productsBlockFields;
    if (query == 'post_type') {
      this.blockFieldsService.postsBlockData.data.post_type = data
      this.getPosts(this.builderService.selectedProject, this.blockFieldsService.postsBlockData.data, false)
    }

    if (data == 'posts') {
      this.getPosts(this.builderService.getSelectedProject(), clonedBlockFieldsData, false)
    }

    if (data == 'products' && !this.builderService.standalone) {
      this.getProducts(this.builderService.getSelectedProject(), clonedBlockFieldsData, false)
    }

    if (data == 'products' && this.builderService.standalone) {
      this.getStandaloneProducts()
    }
    if (data == 'productsCategories' && this.builderService.standalone) {
      this.getStandaloneCategories()
    }
  }

  getStandaloneProducts = () => {
    this.standaloneProductsService.getProducts().subscribe((response_products: any) => {
      let products: any = []
      for (const key in response_products) {
        if (Object.prototype.hasOwnProperty.call(response_products, key)) {
          const element = response_products[key]
          products.push({ id: element.Id, name: element.productName })
        }
      }
      this.standaloneProductsService.storedStandaloneProducts = products
    })
  }
  getStandaloneCategories = () => {
    this.standaloneCategoriesService.getCategories().subscribe((response: any) => {
      let categories: any = []
      for (const key in response) {
        if (Object.prototype.hasOwnProperty.call(response, key)) {
          const element = response[key]
          categories.push({ id: element.categoryId, name: element.categoryName, thumbnail: element.categoryImage, subcategories: element.subcategories })
        }
      }
      this.standaloneCategoriesService.storedStandaloneCategories = categories
    })
  }

  setSelectedSubCategory(subCategoryId: any) {
    const existingSubCategory = this.standaloneCategoriesService.availableSubs.find((sub: any) => sub.subCategoryId === subCategoryId)

    if (existingSubCategory) {
      this.blockData.data.subcategories = existingSubCategory.subCategoryId
    }
  }
  getStandaloneSubCats = (selectedCat: any) => {
    this.standaloneCategoriesService.availableSubs = []
    if (selectedCat?.subcategories) {
      for (let sub of selectedCat?.subcategories) {
        this.standaloneCategoriesService.availableSubs.push(sub)
      }
      if (this.blockDataService.getSelectedBlockData(this.blockId).data.subcategories) {
        this.setSelectedSubCategory(this.blockDataService.getSelectedBlockData(this.blockId).data.subcategories)
      }
    }
  }

  getCat(catId: any) {
    this.standaloneCategoriesService.getCategory(catId.value).subscribe((cat) => {
      this.getStandaloneSubCats(cat)
    })
    if (this.blockDataService.getSelectedBlockData(this.blockId).subcategories) {
      this.setSelectedSubCategory(this.blockDataService.getSelectedBlockData(this.blockId).data.subcategories)
    }
  }

  styleChange(style: any, blockId: any, $event: any, type: string) {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return
    }
    let textBlockFields = this.blockDataService.getSelectedBlockData(blockId)
    /**
     * Modifying Nested Object Directly doesn't work, so we have to clone the data object first then modify it
     */
    let clonedBlockFieldsData = { ...textBlockFields.style }
    let data = ''
    if (type == 'select') {
      data = $event.value
    } else if (type == 'text') {
      data = $event.currentTarget.value
    } else if (type == 'slider') {
      data = $event.currentTarget.value + 'px'
    }

    clonedBlockFieldsData[style] = data
    textBlockFields.style = clonedBlockFieldsData
  }

  getBlockFields = (id: string) => {
    if (this.importedBlockData) {
      return this.importedBlockData
    }
    return this.blockDataService.getSelectedBlockData(id)
  }

  showStyleTab = () => {
    this.previewBlock = false
    this.previewFields = false
    this.styleFields = true
  }

  showDataTab = () => {
    this.previewBlock = false
    this.previewFields = true
    this.styleFields = false
  }

  checkSidebar = () => {
    if (!this.previewBlock && !this.previewFields && !this.styleFields) {
      return true
    }
    return false
  }

  checkPreview = () => {
    if (this.previewBlock && !this.previewFields && !this.styleFields) {
      return true
    }
    return false
  }

  checkData = () => {
    if (this.previewFields) {
      return true
    }
    return false
  }

  addSliderItem = (blockId: string) => {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return
    }
    let sliderBlockFields = this.blockDataService.getSelectedBlockData(blockId)
    /**
     * Modifying Nested Object Directly doesn't work, so we have to clone the data object first then modify it
     */
    let clonedBlockFieldsData = { ...sliderBlockFields.data }
    let data: any = ''
    clonedBlockFieldsData.slides.push({
      img: environment.production ? 'https://stacksmarket.co/wp-content/uploads/2021/09/bikes1.jpg' : '../../assets/images/blocks/image-placeholder.png',
      title: '',
      link: 'home',
      index: clonedBlockFieldsData.slides.length
    })
    sliderBlockFields.data = clonedBlockFieldsData
  }

  getCategories = (projectId: number, args: object) => {
    this.showProgress = true
    this.categoriesService.getCategories(projectId, args).subscribe(
      (response) => {
        let categories: any = []
        for (const key in response) {
          if (Object.prototype.hasOwnProperty.call(response, key)) {
            const element = response[key]
            if (element.count > 0) {
              categories.push({ id: element.term_id, name: element.name, thumbnail: element.image_url })
            }
          }
        }
        this.showProgress = false
        this.categoriesService.storedCategories = categories
      },
      (err) => {}
    )
    return true
  }

  getPosts = (projectId: number, args: object, cached = true) => {
    this.showProgress = true
    if (cached) {
      this.postsService.getPostsCached().subscribe(
        (response) => {
          this.showProgress = false
          let posts: any = []
          for (const key in response) {
            if (Object.prototype.hasOwnProperty.call(response, key)) {
              const element = response[key]
              if (element) {
                posts.push({ ID: element.ID, post_title: element.post_title, post_image: element.post_image })
              }
            }
          }
          this.storedPosts = posts
        },
        (err) => {}
      )
    } else {
      this.postsService.getPosts(projectId, args).subscribe(
        (response) => {
          this.showProgress = false
          let posts: any = []
          for (const key in response) {
            if (Object.prototype.hasOwnProperty.call(response, key)) {
              const element = response[key]
              if (element) {
                posts.push({ ID: element.ID, post_title: element.post_title, post_image: element.post_image })
              }
            }
          }
          this.storedPosts = posts
        },
        (err) => {}
      )
    }

    return true
  }

  getProducts = (projectId: number, args: object, cached = true) => {
    this.showProgress = true
    if (cached) {
      this.productsService.getProductsCached().subscribe(
        (response) => {
          this.showProgress = false
          let products: any = []
          let responseProducts = response.products
          for (const key in responseProducts) {
            if (Object.prototype.hasOwnProperty.call(responseProducts, key)) {
              const element = responseProducts[key]
              if (element) {
                products.push({ id: element.id, name: element.name })
              }
            }
          }
          this.storedProducts = products
        },
        (err) => {}
      )
    } else {
      this.productsService.getProducts(projectId, args).subscribe(
        (response) => {
          this.showProgress = false
          let products: any = []
          let responseProducts = response.products
          for (const key in responseProducts) {
            if (Object.prototype.hasOwnProperty.call(responseProducts, key)) {
              const element = responseProducts[key]
              if (element) {
                products.push({ id: element.id, name: element.name })
              }
            }
          }
          this.storedProducts = products
        },
        (err) => {}
      )
    }

    return true
  }

  checkStyle = () => {
    if (this.styleFields && !this.previewFields && !this.previewBlock) {
      return true
    }
    return false
  }

  checkSettings = () => {
    if (!this.checkSidebar() && !this.checkPreview()) {
      return true
    }
    return false
  }

  /**
   * Deletes the Block from the mobile Preview
   * Deletes the Block from the JSON Object
   */
  deleteBlock = (blockId: any) => {
    this.blockDataService.removeBlockData(blockId)
  }

  /**deletes the selected slide */
  deleteslides = (blockId: string, menuIndex: number) => {
    let slidesBlockFields = this.getBlockFields(blockId)
    slidesBlockFields.data.slides.splice(menuIndex, 1)
    this.reindexslides(blockId)
  }

  reindexslides = (blockId: string) => {
    let slidesBlockFields = this.getBlockFields(blockId)
    for (let i = 0; i < slidesBlockFields.data.slides.length; i++) {
      slidesBlockFields.data.slides[i].index = i
    }
  }
}
