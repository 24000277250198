import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { DatabaseService } from 'src/app/builder-services/database.service'

@Injectable({
  providedIn: 'root'
})
export class DiscountService {
  project_id = this.builderService.selectedProject

  constructor(
    private builderService: BuilderService,
    private db: DatabaseService,
    private http: HttpClient
  ) {}

  submitDiscount(discount: any): Observable<any> {
    discount = JSON.parse(JSON.stringify(discount))
    return new Observable<any>((observer) => {
      this.db.setDatabase(`projects/${this.project_id}/discount`, discount, true).subscribe(
        (response) => {
          console.log('discount added Successfully:', response)
          observer.next(response)
          observer.complete()
        },
        (error) => {
          console.log('Error Adding discount:', error)
          observer.error(error)
        }
      )
    })
  }

  getDiscounts(): Observable<any> {
    return new Observable<any>((observer) => {
      this.db.getDatabase(`projects/${this.project_id}/discount`).subscribe(
        (response) => {
          if (!response) {
            console.log('No data available')
            observer.next({})
            observer.complete()
          } else {
            observer.next(response)
            observer.complete()
          }
        },
        (error) => {
          observer.error(error)
        }
      )
    })
  }

  deleteDiscount(discointId: string): Observable<any> {
    const path = `projects/${this.project_id}/discount/${discointId}`
    return this.db.removeDatabaseEndpoint(path)
  }

  getDiscount(discountId: string): Observable<any> {
    const url = `projects/${this.project_id}/discount/${discountId}`
    return this.db.getDatabase(url)
  }

  updateDiscount(id: string, discount: any): Observable<any> {
    discount = JSON.parse(JSON.stringify(discount))
    return new Observable<any>((observer) => {
      this.db.setDatabase(`projects/${this.project_id}/discount/${id}`, discount).subscribe(
        (response) => {
          console.log('discount updated Successfully:', response)
          observer.next(response)
          observer.complete()
        },
        (error) => {
          console.log('Error update discount:', error)
          observer.error(error)
        }
      )
    })
  }
}
