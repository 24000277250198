import { Component, ElementRef, ViewChild } from '@angular/core'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTable, MatTableDataSource } from '@angular/material/table'
import { DiscountService } from '../discount.service'

export interface Discount {
  id: string | null
  name: string
  amount: number
  discountCriteria: 'fixed_products_discount' | 'fixed_categories_discount'
}

@Component({
  selector: 'app-discount-list',
  templateUrl: './discount-list.component.html',
  styleUrl: './discount-list.component.scss'
})
export class DiscountListComponent {
  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatSort) matSort!: MatSort
  @ViewChild(MatTable) table!: MatTable<Discount>
  @ViewChild('searchInput') searchInput!: ElementRef
  displayedColumns: string[] = ['name', 'amount', 'sale_date_from', 'sale_date_to', 'discountCriteria', 'state', 'actions']
  endTime: any
  discountState: any
  dataSource = new MatTableDataSource<Discount>()
  searchTerm: string = ''
  discountCriteriaMap: { [key: string]: string } = {
    fixed_products_discount: 'Fixed Products Discount',
    fixed_categories_discount: 'Fixed Category Discount',
    whole_cart_discount: 'Whole Cart Discount'
  }

  discountStates: { [key: string]: string } = {} // To store discount states

  constructor(private discountService: DiscountService) {}

  //valid not vaild
  ngOnInit() {
    this.loadDiscounts()
  }

  ngAfterViewChecked() {
    if (!this.dataSource.paginator && this.paginator) {
      this.dataSource.paginator = this.paginator
    }
  }

  /**
   * Fetches discounts from the server and populates the Material table with the data.
   * It also initializes the discount timer state for each discount.
   */
  loadDiscounts() {
    this.discountService.getDiscounts().subscribe(
      (discount: any) => {
        // Transform the data to include id as a property
        const discountsArray: Discount[] = Object.keys(discount)
          .map((key) => ({
            id: key,
            ...discount[key] // Spread the properties from the coupon object
          }))
          .reverse()

        setTimeout(() => {
          discountsArray.forEach((discount: any) => {
            this.discountTimerState(discount.id, discount.dateTo, discount.timeTo)
          })
          this.dataSource = new MatTableDataSource<Discount>(discountsArray)
        }, 1000)
      },
      (error) => {
        console.error('Error fetching coupons:', error)
      }
    )
  }

  /**
   * Applies a filter to the Material table data source based on the given filter value.
   * It trims and converts the filter value to lowercase before applying it.
   * If a paginator is present, it sets the first page as the current page after applying the filter.
   * @param filterValue - The value to filter the table data by.
   */
  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase()
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage()
    }
  }

  /**
   * Deletes a discount timer from the DB.
   * Displays a confirmation dialog before proceeding with the deletion.
   * If the user confirms the deletion, it sends a request to the server to delete the discount timer.
   * On success, it logs a success message and refreshes the discount list.
   * On error, it logs an error message.
   *
   * @param discountId - The unique identifier of the discount timer to be deleted.
   */
  deleteDiscount(discountId: string) {
    if (confirm('Are you sure you want to delete this discount Timer?')) {
      this.discountService.deleteDiscount(discountId).subscribe(
        () => {
          console.log('deleted successfully')
          this.loadDiscounts()
        },
        (error) => {
          console.log('Error deleting discount:', error)
        }
      )
    }
  }

  /**
   * Checks if there are any discounts available in the table data source.
   * @returns {boolean} - Returns true if there are discounts available, false otherwise.
   */
  hasdiscounts(): boolean {
    return this.dataSource.data.length > 0
  }

  /**
   * Updates the state of a discount timer based on the given end date and time.
   * The function calculates the remaining time until the end date and updates the state accordingly.
   * If the end date is invalid, it logs an error message and sets the state to "Invalid Date".
   * The function is called every second using setInterval.
   * @param id - The unique identifier of the discount timer.
   * @param dateTo - The end date of the discount timer.
   * @param timeTo - The end time of the discount timer.
   * @returns {void}
   */
  discountTimerState(id: string, dateTo: any, timeTo: any): void {
    const concTime = `${dateTo} ${timeTo}`
    const endTime = new Date(concTime).getTime() // Convert to timestamp in milliseconds

    if (isNaN(endTime)) {
      console.error('Invalid Date Format:', concTime)
      this.discountStates[id] = 'Invalid Date'
      return
    }

    setInterval(() => {
      const now = new Date().getTime()
      const remainingTime = endTime - now

      if (remainingTime <= 0) {
        this.discountStates[id] = 'Expired'
      } else {
        this.discountStates[id] = 'Active'
      }
    }, 1000) // Updates every second
  }
}
