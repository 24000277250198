<div class="containerWrapper">
  <div class="tableWrapper">
    <div class="tableHeader">
      <h1>SUBSCRIBERS</h1>
      <div class="headerBtns">
        <!-- <button [routerLink]="['/stacks-importer']">+ BULK IMPORT</button> -->
        <button [routerLink]="['../newSub']">+ ADD NEW SUBSCRIBER</button>
        <form class="searchBar">
          <input type="text" matInput placeholder="{{ 'Search' | translate }}" />
        </form>
      </div>
    </div>
    <!-- <mat-form-field appearance="outline">
            <mat-label>Filter</mat-label>
            <input matInput (keyup)="applySubFilter($event)" placeholder="Ex. ium" #input>
        </mat-form-field> -->
    <!-- <button mat-raised-button class="fetchData" (click)="fetchproducts()">Fetch New Data</button> -->
    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
      <!-- Position Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef id="name" mat-sort-header="name" sortActionDescription="Sort by name">
          <div class="hasIcons">name</div>
        </th>
        <td [class.productName]="mobile" mat-cell *matCellDef="let elements">{{ elements.name }}</td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="email">
        <th [class.hideTableHeaders]="mobile" mat-header-cell *matHeaderCellDef>
          <div class="hasIcons">email</div>
        </th>
        <td [class.hideTableHeaders]="mobile" mat-cell *matCellDef="let elements">{{ elements.email }}</td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="plan">
        <th mat-header-cell *matHeaderCellDef mat-sort-header="plan" sortActionDescription="Sort by plan">plan</th>
        <td mat-cell *matCellDef="let elements">{{ elements.plan }}</td>
      </ng-container>

      <ng-container matColumnDef="planPrice">
        <th mat-header-cell *matHeaderCellDef>
          <div class="hasIcons">Plan price</div>
        </th>
        <td mat-cell *matCellDef="let elements">{{ elements.planPrice }} {{ elements.currency }}</td>
      </ng-container>

      <ng-container matColumnDef="date">
        <th [class.hideTableHeaders]="mobile" mat-header-cell *matHeaderCellDef>
          <div class="hasIcons">Sub Date</div>
        </th>
        <td [class.hideTableHeaders]="mobile" mat-cell *matCellDef="let elements">{{ elements.subDate }}</td>
      </ng-container>

      <ng-container matColumnDef="renewDate">
        <th [class.hideTableHeaders]="mobile" mat-header-cell *matHeaderCellDef>
          <div class="hasIcons">Renew Date</div>
        </th>
        <td [class.hideTableHeaders]="mobile" mat-cell *matCellDef="let elements">{{ elements.renewDate }}</td>
      </ng-container>
      <ng-container matColumnDef="status">
        <th [class.hideTableHeaders]="mobile" mat-header-cell *matHeaderCellDef>
          <div class="hasIcons">Status</div>
        </th>
        <td [class.hideTableHeaders]="mobile" mat-cell *matCellDef="let elements">{{ elements.status }}</td>
      </ng-container>

      <ng-container matColumnDef="action">
        <th mat-header-cell *matHeaderCellDef class="lastTitle">action</th>
        <td mat-cell *matCellDef="let elements">
          <button mat-icon-button>
            <span class="mat-button-wrapper">
              <span class="material-icons material-icons-outlined editIcon" (click)="editSubscriber(elements.id)">edit</span>
            </span>
          </button>
          <button mat-icon-button>
            <span class="mat-button-wrapper">
              <span class="material-icons material-icons-outlined deleteIcon" (click)="deleteSubscriber(elements.id)">delete</span>
            </span>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="subscribersColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: subscribersColumns"></tr>
    </table>
    <mat-paginator class="tablePaginator" [pageSizeOptions]="[5, 10, 20, 50, 100]" showFirstLastButtons aria-label="Select page of periodic dataSource"> </mat-paginator>
  </div>
</div>
