<section class="stacksBlockContainer" id="block-products">
  <div id="settingsTabs" *ngIf="checkSettings()">
    <a (click)="showDataTab()" [ngClass]="checkData() ? 'active' : ''">{{ 'Data' | translate }}</a>
    <a (click)="showStyleTab()" [ngClass]="checkStyle() ? 'active' : ''">{{ 'Style' | translate }}</a>
  </div>
  <!-- Sidebar -->
  <div *ngIf="checkSidebar()" class="blockSidebar" [ngClass]="builderService.standalone ? '' : 'disabled'" (click)="appendBlock($event)">
    <img src="assets/images/blocks/products.png" alt="" srcset="" />
    <p>{{ 'Products' | translate }}</p>
  </div>
  <!-- Mobile Preview -->
  <div
    class="previewProducts previewMobile"
    [ngClass]="[getBlockFields(blockId)?.style?.products_style ? getBlockFields(blockId)?.style?.products_style : '', getBlockFields(blockId)?.style?.productColumns ? getBlockFields(blockId)?.style?.productColumns : '']"
    *ngIf="checkPreview() && getBlockFields(blockId)"
    id="{{ 'blockid' + blockId }}"
    cdkDrag
    [cdkDragData]="blockId"
  >
    <mat-progress-bar mode="indeterminate" *ngIf="builderService.showSettingsProgress"></mat-progress-bar>
    <div class="blockControlHandlers" *ngIf="!builderService.globals">
      <div class="editBlock blockActionBtn" (click)="showData(blockId)">
        <mat-icon>edit</mat-icon>
      </div>
      <div class="blockHandle blockActionBtn" cdkDragHandle>
        <mat-icon>open_with</mat-icon>
      </div>
      <div class="deleteBlock blockActionBtn" (click)="deleteBlock(blockId)">
        <mat-icon>close</mat-icon>
      </div>
    </div>
    <div class="blockPlaceholder" *cdkDragPlaceholder></div>
    <div [id]="getBlockFields(blockId)?.style?._element_id" [ngClass]="getBlockFields(blockId)?.style?._css_classes" [ngClass]="[getBlockFields(blockId)?.style?.products_style, getBlockFields(blockId)?.style?.productColumns]">
      <div [ngClass]="[getBlockFields(blockId).style?.products_style ? getBlockFields(blockId).style?.products_style : '']">
        <div class="horizontal-slider-container" [ngClass]="getBlockFields(blockId).style?.displayStyle">
          <p *ngIf="getProductsData(blockId) && !getProductsData(blockId)[0]" style="color: red">No Products Available with the selected settings</p>
          <ul class="horizonal-slider" [ngClass]="getBlockFields(blockId).style?.productColumns ? getBlockFields(blockId).style?.productColumns : ''">
            <li class="item" *ngFor="let product of getProductsData(blockId)">
              <div class="flex-container">
                <div class="picture-container">
                  <img *ngIf="product?.imageUrl" src="{{ product?.imageUrl }}" alt="" />
                  <img *ngIf="!product?.imageUrl" src="assets/images/empty-image.png" alt="" />
                  <div class="sale" *ngIf="product?.salePrice && product?.salePrice !== '0.00'">{{ 'Sale' }}!</div>
                </div>
                <div class="fade">
                  <div class="description" [ngClass]="product?.productStock == '' || product?.productStock == '0' || product?.productStock == 0 || product?.stock_status == 'outofstock' ? 'outofStockItem' : ''">
                    <div class="heading">
                      <div class="headingName">
                        {{ product?.productName }}
                      </div>
                      <button
                        [ngStyle]="{ color: getBlockFields(blockId)?.style?.color }"
                        *ngIf="product?.productVariations?.length <= 1 && getBlockFields(blockId).style?.products_style == 'roundedStyle' && getBlockFields(blockId).style?.productColumns != 'oneColumn' && !product.loading"
                        [disabled]="product?.productStock == '' || product?.productStock == '0' || product?.productStock == 0 || product?.stock_status == 'outofstock' ? true : false"
                        [ngClass]="product?.productStock == '' || product?.productStock == '0' || product?.productStock == 0 || product?.stock_status == 'outofstock' ? 'outOfStockCart' : ''"
                      >
                        <mat-icon class="material-icons-outlined">shopping_bag</mat-icon>
                      </button>
                      <button [ngStyle]="{ color: getBlockFields(blockId)?.style?.color }" *ngIf="product?.productVariations?.length > 1 && getBlockFields(blockId).style?.products_style == 'roundedStyle' && getBlockFields(blockId).style?.productColumns != 'oneColumn'">
                        <mat-icon class="material-icons-outlined">tune</mat-icon>
                      </button>
                    </div>
                    <div *ngIf="product?.productVariations?.length > 1" class="pricesWrapper">
                      <div class="pricesMiniWrapper">
                        <div class="mainPrice" *ngIf="product.lowestSalePrice">
                          <span class="currency-symbol">{{ currency }}</span>
                          {{ product?.lowestSalePrice }}
                        </div>
                        <!-- Show first Variations sale and regular price -->
                        <div class="strike" *ngIf="product.lowestSalePrice">
                          <span class="currency-symbol">{{ currency }}</span>
                          {{ product?.correspondingRegularPrice }}
                        </div>
                      </div>

                      <!-- Show lowest regular price if there is no sale -->
                      <div class="price mainPrice" *ngIf="!product.lowestSalePrice">
                        <span class="currency-symbol">{{ currency }}</span>
                        {{ product?.lowestRegularPrice }}
                      </div>

                      <button *ngIf="getBlockFields(blockId).style?.products_style == 'glassStyle' && product?.productStock !== '' && product?.productStock !== '0' && product?.productStock !== 0 && product?.stock_status !== 'outofstock'" [ngStyle]="{ color: getBlockFields(blockId).style?.color }">
                        <mat-icon [ngStyle]="{ color: getBlockFields(blockId).style?.color }" class="material-icons-outlined">tune</mat-icon>
                      </button>
                      <button *ngIf="getBlockFields(blockId).style?.products_style == 'sharpStyle' && product?.productStock !== '' && product?.productStock !== '0' && product?.productStock !== 0 && product?.stock_status !== 'outofstock'" [ngStyle]="{ color: getBlockFields(blockId).style?.color }">
                        <mat-icon class="material-icons-outlined">tune</mat-icon>
                      </button>
                      <div class="roundedButton" *ngIf="getBlockFields(blockId).style?.products_style == 'roundedStyle' && getBlockFields(blockId).style?.productColumns == 'oneColumn'">
                        <button [ngStyle]="{ color: getBlockFields(blockId).style?.color }">
                          <mat-icon class="material-icons-outlined">tune</mat-icon>
                        </button>
                      </div>
                    </div>
                    <button *ngIf="getBlockFields(blockId)?.style?.products_style == 'simpleStyle' && product.productVariations && product?.productVariations?.length > 1" [ngStyle]="{ color: getBlockFields(blockId)?.style?.color }"><mat-icon class="material-icons-outlined">tune</mat-icon> {{ 'View Product' }}</button>
                    <div *ngIf="product?.productVariations?.length <= 1" class="pricesWrapper" [ngClass]="product?.productStock == '' || product?.productStock == '0' || product?.stock_status == 'outofstock' ? 'outofstockPriceWrapper' : ''">
                      <div class="price pricesMiniWrapper" *ngIf="product?.salePrice !== '0.00' && product?.salePrice !== '' && product?.salePrice !== '0' && product?.salePrice">
                        <div class="mainPrice">
                          <span class="currency-symbol">{{ currency }}</span
                          >{{ product?.salePrice }}
                        </div>
                        <div class="strike">
                          <span class="currency-symbol">{{ currency }}</span
                          >{{ product?.regularPrice }}
                        </div>
                      </div>
                      <div class="price mainPrice" *ngIf="product?.salePrice == '0.00' || product?.salePrice == ''">
                        <span class="currency-symbol">{{ currency }}</span
                        >{{ product?.regularPrice }}
                      </div>
                      <button
                        *ngIf="!product.loading && getBlockFields(blockId).style?.products_style == 'glassStyle' && product?.productStock && product?.productStock !== '' && product?.productStock !== '0' && product?.productStock !== 0 && product?.stock_status !== 'outofstock'"
                        [ngStyle]="{ color: getBlockFields(blockId).style?.color }"
                        class="glassBtn"
                      >
                        <span class="material-icons">add_shopping_cart</span>
                      </button>
                      <button
                        *ngIf="
                          !product.loading && getBlockFields(blockId).style?.products_style == 'sharpStyle' && !(product?.productVariations && product?.productVariations?.length > 1) && product?.productStock !== '' && product?.productStock !== '0' && product?.productStock !== 0 && product?.stock_status !== 'outofstock'
                        "
                        [ngStyle]="{ color: getBlockFields(blockId).style?.color }"
                      >
                        <mat-icon class="material-icons-outlined">add</mat-icon>
                      </button>
                      <button
                        [ngStyle]="{ color: getBlockFields(blockId)?.style?.color }"
                        *ngIf="
                          !product.loading &&
                          getBlockFields(blockId).style?.products_style == 'roundedStyle' &&
                          getBlockFields(blockId).style?.productColumns == 'oneColumn' &&
                          !(product?.productVariations && product?.productVariations?.length > 1) &&
                          product?.productStock !== '' &&
                          product?.productStock !== '0' &&
                          product?.productStock !== 0 &&
                          product?.stock_status !== 'outofstock'
                        "
                      >
                        <mat-icon class="material-icons-outlined">shopping_bag</mat-icon>
                      </button>
                      <div class="roundedButton" *ngIf="getBlockFields(blockId).style?.products_style == 'roundedStyle'">
                        <button
                          [ngStyle]="{ color: getBlockFields(blockId)?.style?.color }"
                          *ngIf="
                            getBlockFields(blockId).style?.products_style == 'roundedStyle' &&
                            getBlockFields(blockId).style?.productColumns == 'oneColumn' &&
                            product?.productVariations &&
                            product?.productVariations?.length > 1 &&
                            product?.productStock !== '' &&
                            product?.productStock !== '0' &&
                            product?.productStock !== 0 &&
                            product?.stock_status !== 'outofstock'
                          "
                        >
                          <mat-icon class="material-icons-outlined">tune</mat-icon>
                        </button>
                      </div>
                      <span *ngIf="(getBlockFields(blockId).style?.products_style == 'glassStyle' || getBlockFields(blockId).style?.products_style == 'sharpStyle') && (product?.productStock == '' || product?.productStock == '0' || product?.stock_status == 'outofstock')" class="outofstock">{{ 'Out Of Stock' }}</span>
                    </div>
                    <span *ngIf="getBlockFields(blockId).style?.products_style !== 'glassStyle' && getBlockFields(blockId).style?.products_style !== 'sharpStyle' && (product?.productStock == '' || product?.productStock == '0' || product?.stock_status == 'outofstock')" class="outofstock">{{ 'Out Of Stock' }}</span>
                    <button
                      class="simpleStyle"
                      *ngIf="
                        !product.loading && getBlockFields(blockId).style?.products_style == 'simpleStyle' && !(product?.productVariations && product?.productVariations?.length > 1) && product?.productStock !== '' && product?.productStock !== '0' && product?.productStock !== 0 && product?.stock_status !== 'outofstock'
                      "
                      [ngStyle]="{ color: getBlockFields(blockId).style?.color }"
                    >
                      <mat-icon class="material-icons-outlined">shopping_bag</mat-icon> {{ 'add to cart' }}
                    </button>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <!-- <div
      [ngClass]="[getBlockFields(blockId)?.style?.products_style ? getBlockFields(blockId)?.style?.products_style : '']">
      <div class="horizontal-slider-container" class="{{getBlockFields(blockId)?.style?.displayStyle}}">
        <p *ngIf="getProductsData(blockId) && !getProductsData(blockId)[0]" style="color:red">No Products Available with
          the selected settings</p>
        <ul class="horizonal-slider" [ngClass]="
          getBlockFields(blockId)?.style?.productColumns ? getBlockFields(blockId)?.style?.productColumns : ''">
          <li  class="item" *ngFor="let product of getProductsData(blockId)">
            <div class="flex-container">
              <div class="picture-container">
                <img *ngIf="product.imageUrl" src="{{product.imageUrl}}" alt="">
                <img *ngIf="!product.imageUrl" src="assets/images/empty-image.png">
                <div class="sale" *ngIf="product.salePrice && product.salePrice!=='0.00'">
                  SALE!
                </div>
              </div>
              <div class="fade">
                <div class="description">
                  <!-- <div class="heading" [ngStyle]="getTitleStyles()">
                  <div class="heading">
                    <div class="headingName" *ngIf="(getBlockFields(blockId)?.style?.nameVisibility ?? 'showName') === 'showName'">
                      {{product.productName}}
                    </div>
                    <!-- i want this button appear when roundedStyle is active
                    <button
                      *ngIf=" product.productVariations.length <= 1 && getBlockFields(blockId)?.style?.products_style == 'roundedStyle' && getBlockFields(blockId)?.style?.productColumns != 'oneColumn'"
                      [ngStyle]="{'color': getBlockFields(blockId)?.style?.color}"
                      >
                      <mat-icon class="material-icons-outlined">shopping_bag</mat-icon>
                    </button>
                    <button
                      *ngIf=" product.productVariations.length > 1 && getBlockFields(blockId)?.style?.products_style == 'roundedStyle' && getBlockFields(blockId)?.style?.productColumns != 'oneColumn'"
                      [ngStyle]="{'color': getBlockFields(blockId)?.style?.color}">
                      <mat-icon class="material-icons-outlined">tune</mat-icon>
                    </button>
                  </div>
                    <div *ngIf="product.productVariations.length > 1" class="pricesWrapper">
                        <!-- Show first Variations sale and regular price
                        <div class="strike" *ngIf="product.productVariations[0].salePrice !== '0.00' && product.productVariations[0].salePrice !== ''">
                          <span class="currency-symbol">{{currency}}</span>{{product.productVariations[0].regularPrice}}
                        </div>

                        <div class="mainPrice">
                          <span class="currency-symbol">{{currency}}</span>{{product.productVariations[0].salePrice}}
                        </div>

                      <!-- Show first Variations regular price if there is no sale
                        <div class="price mainPrice" *ngIf="product.productVariations[0].salePrice == '0.00' || product.productVariations[0].salePrice == ''">
                          <span class="currency-symbol">{{currency}}</span>{{product.productVariations[0].regularPrice}}
                        </div>
               
                        <button *ngIf=" getBlockFields(blockId)?.style?.products_style == 'glassStyle'"
                        [ngStyle]="{'color': getBlockFields(blockId)?.style?.color}">
                          <mat-icon 
                          [ngStyle]="{'color': getBlockFields(blockId)?.style?.color}"
                          class="material-icons-outlined">tune</mat-icon>
                        </button>
                        <button *ngIf=" getBlockFields(blockId)?.style?.products_style == 'sharpStyle'"
                        [ngStyle]="{'color': getBlockFields(blockId)?.style?.color}">
                          <mat-icon class="material-icons-outlined">tune</mat-icon>
                        </button>
                        <div class="roundedButton" *ngIf=" getBlockFields(blockId)?.style?.products_style == 'roundedStyle' ">
                          <button
                            *ngIf=" getBlockFields(blockId)?.style?.products_style == 'roundedStyle' && getBlockFields(blockId)?.style?.productColumns == 'oneColumn'"
                            [ngStyle]="{'color': getBlockFields(blockId)?.style?.color}">
                            <mat-icon class="material-icons-outlined">tune</mat-icon>
                          </button>
                        </div>
                    </div>
                    <div *ngIf="product.productVariations.length <= 1" class="pricesWrapper">
                        <div class="price pricesMiniWrapper" *ngIf="product.salePrice !== '0.00'&& product.salePrice !== ''">
                          <div class="strike">
                            <span class="currency-symbol">{{currency}}</span>{{product.regularPrice}}
                          </div>
                          <div class="mainPrice">
                            <span class="currency-symbol">{{currency}}</span>{{product.salePrice}}
                          </div>
                        </div>
                        <div class="price mainPrice" *ngIf="product.salePrice == '0.00' || product.salePrice == ''">
                          <span class="currency-symbol">{{currency}}</span>{{product.regularPrice}}
                        </div>
                      <button *ngIf=" getBlockFields(blockId)?.style?.products_style == 'glassStyle'"
                      [ngStyle]="{'color': getBlockFields(blockId)?.style?.color}">
                        <span class="material-icons">add_shopping_cart</span>
                      </button>
                      <button *ngIf=" getBlockFields(blockId)?.style?.products_style == 'sharpStyle'"
                      [ngStyle]="{'color': getBlockFields(blockId)?.style?.color}">
                        <mat-icon class="material-icons-outlined">add</mat-icon>
                      </button>
                      <div class="roundedButton" *ngIf=" getBlockFields(blockId)?.style?.products_style == 'roundedStyle' ">
                        <button
                          *ngIf=" getBlockFields(blockId)?.style?.products_style == 'roundedStyle' && getBlockFields(blockId)?.style?.productColumns == 'oneColumn'"
                          [ngStyle]="{'color': getBlockFields(blockId)?.style?.color}">
                          <mat-icon class="material-icons-outlined">shopping_bag</mat-icon>
                        </button>
                      </div>
                    </div>
                    <!-- and this appear when roundedStyle is not active
                    <div no-padding *ngIf="product.productStock == '' || product.productStock == '0'">
                      <span class="outofstock">Out Of Stock</span>
                    </div>
                    <button *ngIf=" product.productVariations.length > 1 && getBlockFields(blockId)?.style?.products_style == 'simpleStyle'" 
                    [ngStyle]="{'color': getBlockFields(blockId)?.style?.color}">
                      <mat-icon class="material-icons-outlined">tune</mat-icon> Add to cart
                    </button>
                    
                    <button *ngIf=" product.productVariations.length <= 1 && getBlockFields(blockId)?.style?.products_style == 'simpleStyle'" 
                    [ngStyle]="{'color': getBlockFields(blockId)?.style?.color}">
                      <mat-icon class="material-icons-outlined">shopping_bag</mat-icon> Add to cart
                    </button>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div> -->
  </div>
  <!-- Data and Style Tabs -->
  <div class="previewFields" *ngIf="checkData() && getBlockFields(blockId)">
    <mat-progress-bar mode="indeterminate" *ngIf="builderService.showSettingsProgress"></mat-progress-bar>
    <h4 class="listTitle">
      {{ 'Query' | translate }}
    </h4>
    <mat-divider></mat-divider>
    <ul>
      <li>
        <mat-label>{{ 'Source' | translate }}</mat-label>
        <mat-select (selectionChange)="queryChange('query_post_type', blockId, $event, 'select')" value="{{ getBlockFields(blockId).data?.query_post_type }}">
          <mat-option value="product">
            {{ 'Latest Products' | translate }}
          </mat-option>
          <mat-option value="by_category">
            {{ 'Products Categories' | translate }}
          </mat-option>
        </mat-select>
      </li>
    </ul>
    <ul>
      <li *ngIf="getBlockFields(blockId).data.query_post_type == 'by_category'">
        <mat-label>{{ 'Categories' | translate }}</mat-label>
        <mat-select multiple (selectionChange)="queryChange('products_categories', blockId, $event, 'select')" [value]="getBlockFields(blockId).data.products_categories">
          <mat-option *ngFor="let productCategory of getCategoriesData(blockId)" value="{{ productCategory.categoryId }}">
            {{ productCategory.categoryName }}
          </mat-option>
        </mat-select>
      </li>
      <li *ngIf="getBlockFields(blockId).data.query_post_type == 'by_category' && allowedSubcategories.length">
        <mat-label>{{ 'SubCategories' | translate }}</mat-label>
        <mat-select multiple (selectionChange)="queryChange('products_sub_categories', blockId, $event, 'select')" [value]="getBlockFields(blockId).data.products_sub_categories">
          <mat-option *ngFor="let productSubCategory of allowedSubcategories" value="{{ productSubCategory.subCategoryId }}">
            {{ productSubCategory.subcategory }}
          </mat-option>
        </mat-select>
      </li>
    </ul>
    <ul>
      <li>
        <mat-label>{{ 'Limit' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="50" min="1" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).data?.limit }}" (change)="queryChange('limit', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
    </ul>
    <ul>
      <li>
        <mat-label>{{ 'Order By' | translate }}</mat-label>
        <mat-select (selectionChange)="queryChange('query_orderby', blockId, $event, 'select')" value="{{ getBlockFields(blockId).data?.query_orderby }}">
          <mat-option value="date">
            {{ 'Date' | translate }}
          </mat-option>
          <mat-option value="title">
            {{ 'Title' | translate }}
          </mat-option>
          <mat-option value="price">
            {{ 'Price' | translate }}
          </mat-option>
        </mat-select>
      </li>
    </ul>
    <ul>
      <li>
        <mat-label>{{ 'Order' | translate }}</mat-label>
        <mat-select (selectionChange)="queryChange('query_order', blockId, $event, 'select')" value="{{ getBlockFields(blockId).data?.query_order }}">
          <mat-option value="ASC">
            {{ 'Ascendingly' | translate }}
          </mat-option>
          <mat-option value="DESC">
            {{ 'Descendingly' | translate }}
          </mat-option>
        </mat-select>
      </li>
    </ul>
  </div>
  <div class="styleFields" *ngIf="checkStyle() && getBlockFields(blockId)">
    <ul>
      <li>
        <mat-label>{{ 'List Style' | translate }}</mat-label>
        <mat-select (selectionChange)="styleChange('displayStyle', blockId, $event, 'select')" value="{{ getBlockFields(blockId).style?.displayStyle }}">
          <mat-option value="gridStyle">
            {{ 'Grid Style' | translate }}
          </mat-option>
          <mat-option value="listStyle">
            {{ 'Columns Style' | translate }}
          </mat-option>
        </mat-select>
      </li>
      <li>
        <mat-label>{{ 'Columns Style' | translate }}</mat-label>
        <mat-select (selectionChange)="styleChange('productColumns', blockId, $event, 'select')" value="{{ getBlockFields(blockId).style?.productColumns }}">
          <mat-option value="oneColumn">
            {{ 'One Column' | translate }}
          </mat-option>
          <mat-option value="twoColumns">
            {{ 'Two Columns' | translate }}
          </mat-option>
          <mat-option value="threeColumns">
            {{ 'Three Columns' | translate }}
          </mat-option>
        </mat-select>
      </li>
      <li>
        <mat-label>{{ 'Product Style' | translate }}</mat-label>
        <mat-select (selectionChange)="styleChange('products_style', blockId, $event, 'select')" value="{{ getBlockFields(blockId).style?.products_style }}">
          <mat-option value="simpleStyle">
            {{ 'Simple Style' | translate }}
          </mat-option>
          <mat-option value="glassStyle">
            {{ 'Glass Style' | translate }}
          </mat-option>
          <mat-option value="sharpStyle">
            {{ 'Sharp Style' | translate }}
          </mat-option>
          <mat-option value="roundedStyle">
            {{ 'Rounded Style' | translate }}
          </mat-option>
        </mat-select>
      </li>
      <li>
        <mat-label>{{ 'Show Name' | translate }}</mat-label>
        <mat-select (selectionChange)="styleChange('nameVisibility', blockId, $event, 'select')" value="{{ getBlockFields(blockId).style?.nameVisibility }}">
          <mat-option value="hideName">
            {{ 'Hide Name' | translate }}
          </mat-option>
          <mat-option value="showName">
            {{ 'Show Name' | translate }}
          </mat-option>
        </mat-select>
      </li>
    </ul>
    <h4 class="listTitle">{{ 'Colors' | translate }}</h4>
    <mat-divider></mat-divider>
    <ul>
      <li class="contentCenter contentContainerColor">
        <mat-label>{{ 'Button Text Color' | translate }}</mat-label>
        <mat-form-field>
          <p class="colorPickerValue">{{ getBlockFields(blockId).style?.color }}</p>
          <label for="color" class="colorLabel">
            <input type="color" id="color" matInput class="colorPicker" name="color" (change)="styleChange('color', blockId, $event, 'text')" value="{{ getBlockFields(blockId).style?.color }}" />
          </label>
        </mat-form-field>
      </li>
    </ul>
    <h4 class="listTitle">{{ 'Margins' | translate }}</h4>
    <mat-divider></mat-divider>
    <ul>
      <li>
        <mat-label>{{ 'Margin Top' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="-100" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['margin-top']?.slice(0, -2) }}" (change)="styleChange('margin-top', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
      <li>
        <mat-label>{{ 'Margin Right' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="-100" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['margin-right']?.slice(0, -2) }}" (change)="styleChange('margin-right', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
      <li>
        <mat-label>{{ 'Margin Bottom' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="-100" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['margin-bottom']?.slice(0, -2) }}" (change)="styleChange('margin-bottom', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
      <li>
        <mat-label>{{ 'Margin Left' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="-100" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['margin-left']?.slice(0, -2) }}" (change)="styleChange('margin-left', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
    </ul>
    <mat-label>{{ 'Paddings' | translate }}</mat-label>

    <mat-divider></mat-divider>
    <ul>
      <li>
        <mat-label>{{ 'Padding Top' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="1" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['padding-top']?.slice(0, -2) }}" (change)="styleChange('padding-top', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
      <li>
        <mat-label>{{ 'Padding Right' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="1" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['padding-right']?.slice(0, -2) }}" (change)="styleChange('padding-right', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
      <li>
        <mat-label>{{ 'Padding Bottom' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="1" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['padding-bottom']?.slice(0, -2) }}" (change)="styleChange('padding-bottom', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
      <li>
        <mat-label>{{ 'Padding Left' | translate }}</mat-label>
        <mat-slider showTickMarks discrete max="100" min="1" step="1" thumbLabel="true">
          <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['padding-left']?.slice(0, -2) }}" (change)="styleChange('padding-left', blockId, $event, 'slider')" />
        </mat-slider>
      </li>
    </ul>
  </div>
</section>
