import { Component, OnInit } from '@angular/core'
import { MatDialog } from '@angular/material/dialog'
import { WorksDialogComponent } from '../pages/works-dialog/works-dialog.component'
import { BuilderService } from '../builder-services/builder.service'

@Component({
  selector: 'guided-tour',
  templateUrl: './guided-tour.component.html',
  styleUrls: ['./guided-tour.component.scss']
})
export class GuidedTourComponent implements OnInit {
  isTutVisible = true
  isCompleted = false
  isChecked = false
  checklistData: any

  constructor(
    public dialog: MatDialog,
    public builderService: BuilderService
  ) {}

  ngOnInit(): void {
    this.checklistData = localStorage.getItem('checklist')
    this.checklistData = JSON.parse(this.checklistData)
    for (const key in this.builderService.checklist) {
      if (Object.prototype.hasOwnProperty.call(this.builderService.checklist, key)) {
        const element = this.builderService.checklist[key]
        if (this.checklistData !== undefined && this.checklistData !== null) {
          element.done = this.checklistData[key].done
        }
      }
    }
  }

  toggleDisplay() {
    this.builderService.isListShown = !this.builderService.isListShown
  }
  closeTour() {
    this.builderService.isListShown = true
  }

  dialogueHandler = (dialogueName: string) => {
    const dialogRef = this.dialog.open(WorksDialogComponent, {
      width: '700px',
      maxWidth: '80%',
      data: { name: dialogueName }
    })

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed')
    })
  }

  completeTut() {
    this.isCompleted = true
    this.isTutVisible = !this.isTutVisible
    this.isChecked = true
  }
}
