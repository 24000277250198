import { AfterViewInit, Component, OnInit } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatTableDataSource } from '@angular/material/table'
import { VersionsService } from './versions.service'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { BlockDataService } from 'src/app/block-services/block-data.service'
import { ActivatedRoute, Router } from '@angular/router'

export interface PeriodicElement {
  version_name: string
  date: string
  actions: string
}

@Component({
  selector: 'versions',
  templateUrl: './versions.component.html',
  styleUrls: ['./versions.component.scss']
})
export class VersionsComponent implements OnInit, AfterViewInit {
  displayedColumns: string[] = ['version_name', 'date', 'actions']
  ELEMENT_DATA: PeriodicElement[] = []
  dataSource = new MatTableDataSource<PeriodicElement>(this.ELEMENT_DATA)
  versionFormControl = new FormControl()
  constructor(
    private versions: VersionsService,
    private builderService: BuilderService,
    private blockDataService: BlockDataService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit() {
    Promise.resolve().then(() => {
      this.getVersions()
    })
  }
  newVersion = () => {
    this.builderService.showUpdateProgress = true
    this.versions.setVersion(this.builderService.getSelectedProject(), this.versionFormControl.value).subscribe((response: any) => {
      if (!response.success) {
        alert(response.message)
      }
      this.builderService.showUpdateProgress = false
      this.getVersions()
    })
  }

  getVersions = () => {
    this.builderService.showUpdateProgress = true
    let newDataArray: PeriodicElement[] = []
    this.versions.getVersions(this.builderService.getSelectedProject()).subscribe((versions: any) => {
      for (let index = 0; index < versions.length; index++) {
        const element = versions[index]
        newDataArray.push({ version_name: element.version_name, date: element.created_at, actions: '' })
      }
      const newData: PeriodicElement[] = newDataArray
      this.ELEMENT_DATA = newData
      this.dataSource.data = newData
      this.builderService.showUpdateProgress = false
    })
  }

  applyVersion = (name: string) => {
    if (confirm('Are you sure to apply this version ' + name + ', This replaces all your views with the selected version')) {
      this.builderService.showUpdateProgress = true
      this.versions.applyVersion(this.builderService.getSelectedProject(), name).subscribe((version: any) => {
        this.builderService.showUpdateProgress = false
        this.router.navigate(['/views'], { relativeTo: this.route })
      })
    }
  }

  deleteVersion = (versionName: string) => {
    if (confirm('Are you sure to delete version ' + versionName + ', This step is unrecoverable')) {
      this.builderService.showUpdateProgress = true
      this.versions.deleteVersion(this.builderService.getSelectedProject(), versionName).subscribe((version: any) => {
        this.builderService.showUpdateProgress = false
        this.getVersions()
      })
    }
  }
}
