<div id="viewsWrapper">
  <!-- <mat-progress-bar mode="indeterminate" *ngIf="PNProgress"></mat-progress-bar> -->
  <h1>{{ 'Choose Mobile View' | translate }}</h1>
  <div id="viewsContainer">
    <ul>
      <li id="addNewViewTitle">
        <h4>{{ 'Add New View' | translate }}</h4>
        <div class="mainMobilePreviewShape">
          <form>
            <mat-form-field appearance="outline" id="websiteURL">
              <mat-label>{{ 'View Name' | translate }}</mat-label>
              <input matInput [formControl]="viewNameFormControl" type="text" placeholder="{{ 'My Awesome View' | translate }}" />
              <mat-error *ngIf="viewNameFormControl.hasError('required')">
                {{ 'View Name' | translate }}
                <strong>{{ 'required' | translate }}</strong>
              </mat-error>
            </mat-form-field>
          </form>
          <button (click)="newView()" id="generateView">
            {{ 'Generate View' | translate }}
          </button>
        </div>
      </li>
      <li [routerLink]="['/']" (click)="viewChange('home', 1, 'home')">
        <h4>{{ 'Home' | translate }}</h4>
        <div class="mainMobilePreviewShape">
          <img src="../../assets/images/views/home.png" alt="" />
        </div>
      </li>
      <li [routerLink]="['/']" (click)="viewChange('categoryView', 2, 'category')">
        <h4>{{ 'Category' | translate }}</h4>
        <div class="mainMobilePreviewShape">
          <img src="../../assets/images/views/categories.png" alt="" />
        </div>
      </li>
      <li [routerLink]="['/']" (click)="viewChange('singleCategoryView', 14, 'singlecategory')">
        <h4>{{ 'Single Category' | translate }}</h4>
        <div class="mainMobilePreviewShape">
          <img src="../../assets/images/views/single_category.png" alt="" />
        </div>
      </li>
      <li [routerLink]="['/']" (click)="viewChange('singleView', 3, 'single')">
        <h4>{{ 'Single' | translate }}</h4>
        <div class="mainMobilePreviewShape">
          <img src="../../assets/images/views/components/single/single.png" alt="" />
        </div>
      </li>
      <li [routerLink]="['/']" (click)="viewChange('loginView', 4, 'login')">
        <h4>{{ 'Login' | translate }}</h4>
        <div class="mainMobilePreviewShape">
          <img src="../../assets/images/views/components/login/login.png" alt="" />
        </div>
      </li>
      <li [routerLink]="['/']" (click)="viewChange('signupView', 5, 'signup')">
        <h4>{{ 'Signup' | translate }}</h4>
        <div class="mainMobilePreviewShape">
          <img src="../../assets/images/views/components/signup/signup.png" alt="" />
        </div>
      </li>
      <li [routerLink]="['/']" (click)="viewChange('forgotView', 6, 'forgot')">
        <h4>{{ 'forgotPassword' | translate }}</h4>
        <div class="mainMobilePreviewShape">
          <img src="../../assets/images/views/components/forgot/forgot.png" alt="" />
        </div>
      </li>
      <li [routerLink]="['/']" (click)="viewChange('myAccountView', 7, 'myaccount')">
        <h4>{{ 'My Account' | translate }}</h4>
        <div class="mainMobilePreviewShape">
          <img src="../../assets/images/views/components/myaccount/myaccount.png" alt="" />
        </div>
      </li>
      <li [routerLink]="['/']" (click)="viewChange('singleBlogPostView', 8, 'single blog post')">
        <h4>{{ 'Single Blog Post' | translate }}</h4>
        <div class="mainMobilePreviewShape">
          <img src="../../assets/images/views/components/singleblogpost/singleblogpost.png" alt="" />
        </div>
      </li>
      <li [routerLink]="['/']" (click)="viewChange('cartView', 9, 'cart')">
        <h4>{{ 'Cart' | translate }}</h4>
        <div class="mainMobilePreviewShape">
          <img src="../../assets/images/views/components/cart/cart.png" alt="" />
        </div>
      </li>
      <li [routerLink]="['/']" (click)="viewChange('checkoutView', 13, 'checkout')">
        <h4>{{ 'Checkout' | translate }}</h4>
        <div class="mainMobilePreviewShape">
          <img src="../../assets/images/views/components/checkout/checkout.png" alt="" />
        </div>
      </li>
      <li [routerLink]="['/']" (click)="viewChange('aboutView', 10, 'about')">
        <h4>{{ 'About' | translate }}</h4>
        <div class="mainMobilePreviewShape">
          <img src="../../assets/images/views/components/about/about.png" alt="" />
        </div>
      </li>
      <li [routerLink]="['/']" (click)="viewChange('contactView', 11, 'contact')">
        <h4>{{ 'Contact' | translate }}</h4>
        <div class="mainMobilePreviewShape">
          <img src="../../assets/images/views/components/contact/contact.png" alt="" />
        </div>
      </li>
      <li [routerLink]="['/']" (click)="viewChange('termsView', 12, 'terms')">
        <h4>{{ 'Terms' | translate }}</h4>
        <div class="mainMobilePreviewShape">
          <img src="../../assets/images/views/components/terms/terms.png" alt="" />
        </div>
      </li>
      <li [routerLink]="['/']" (click)="viewChange('searchView', 15, 'search')">
        <h4>{{ 'Search' | translate }}</h4>
        <div class="mainMobilePreviewShape">
          <img src="../../assets/images/views/components/search/search.png" alt="" />
        </div>
      </li>
      <li [routerLink]="['/']" (click)="viewChange('wishlistView', 16, 'wishlist')">
        <h4>{{ 'Wishlist' | translate }}</h4>
        <div class="mainMobilePreviewShape">
          <img src="../../assets/images/views/wishlist.png" alt="" />
        </div>
      </li>
      <li class="customViewWrapper" *ngFor="let customView of builderService.customViews">
        <div class="actionButtons" *ngIf="customView.view_name !== 'global_header' && customView.view_name !== 'global_footer'">
          <div class="leftWrapper">
            <h4 [routerLink]="['/']" (click)="viewChange(customView.view_name, customView.view_id, customView.view_name)">{{ customView.view_name }}</h4>
            <form *ngIf="customView.editViewMode">
              <mat-form-field appearance="outline" id="websiteURL">
                <mat-label>{{ 'Enter View Name' | translate }}</mat-label>
                <input matInput [(ngModel)]="customView.view_name" type="text" placeholder="{{ 'My Awesome View' | translate }}" name="editViewName" />
              </mat-form-field>
              <button class="material-icons-outlined editView" (click)="editView(customView.view_name, customView.view_id); customView.editViewMode = false">done</button>
            </form>
          </div>
          <div class="rightWrapper">
            <button class="material-icons-outlined editView" (click)="customView.editViewMode = true" *ngIf="!customView.editViewMode">edit</button>
            <button class="material-icons-outlined deleteView" *ngIf="!customView.editViewMode" (click)="deleteView(customView.view_id)">delete</button>
          </div>
        </div>
        <div class="mainMobilePreviewShape" [routerLink]="['/']" (click)="viewChange(customView.view_name, customView.view_id, customView.view_name)" *ngIf="customView.view_name !== 'global_header' && customView.view_name !== 'global_footer'">
          <section id="blocksWrapper" class="openedBlocks">
            <div *ngFor="let customData of customView.data">
              <block-image *ngIf="customData.type == 'image'" [importedBlockData]="customData" class="blockWrap"></block-image>
              <block-static *ngIf="customData.type == 'static'" [importedBlockData]="customData" class="blockWrap blockStatic"></block-static>
              <block-text *ngIf="customData.type == 'text'" [importedBlockData]="customData" class="blockWrap"></block-text>
              <block-products *ngIf="customData.type == 'products'" [importedBlockData]="customData" class="blockWrap"></block-products>
              <block-categories *ngIf="customData.type == 'categories'" [importedBlockData]="customData" class="blockWrap"></block-categories>
              <block-posts *ngIf="customData.type == 'posts'" [importedBlockData]="customData" class="blockWrap"></block-posts>
              <block-slider *ngIf="customData.type == 'slider'" [importedBlockData]="customData" class="blockWrap"></block-slider>
              <block-video *ngIf="customData.type == 'video'" [importedBlockData]="customData" class="blockWrap"></block-video>
              <block-button *ngIf="customData.type == 'button'" [importedBlockData]="customData" class="blockWrap"></block-button>
              <block-section *ngIf="customData.type == 'section'" [importedBlockData]="customData" class="blockWrap"></block-section>
              <block-header *ngIf="customData.type == 'header'" [importedBlockData]="customData" class="blockWrap blockHeader" cdkDragDisabled="true"></block-header>
              <block-footer *ngIf="customData.type == 'footer'" [importedBlockData]="customData" class="blockWrap blockFooter" cdkDragDisabled="true"></block-footer>
            </div>
          </section>
        </div>
      </li>
    </ul>
  </div>
</div>
