<section class="stacksBlockContainer" id="block-section" [ngClass]="getSectionWidth(blockId) ? 'fullWidth' : ''">
  <div id="settingsTabs" *ngIf="checkSettings()">
    <a (click)="showDataTab()" [ngClass]="checkData() ? 'active' : ''">{{ 'Data' | translate }}</a>
    <a (click)="showStyleTab()" [ngClass]="checkStyle() ? 'active' : ''">{{ 'Style' | translate }}</a>
  </div>
  <!-- Sidebar -->
  <div *ngIf="checkSidebar()" class="blockSidebar" (click)="appendBlock()">
    <img src="assets/images/blocks/section.png" alt="" srcset="" />
    <p>{{ 'Section' | translate }}</p>
  </div>
  <!-- Mobile Preview -->
  <div class="previewSection previewMobile" *ngIf="checkPreview()" cdkDrag [ngStyle]="getBlockFields(blockId)?.style" [cdkDragData]="blockId">
    <div class="blockControlHandlers" *ngIf="!builderService.globals">
      <div class="editBlock blockActionBtn" (click)="showData(blockId)" #editBtn>
        <mat-icon>edit</mat-icon>
      </div>
      <div class="blockHandle blockActionBtn" cdkDragHandle>
        <mat-icon>open_with</mat-icon>
      </div>
      <div class="deleteBlock blockActionBtn" (click)="deleteBlock(blockId)">
        <mat-icon>close</mat-icon>
      </div>
    </div>
    <div class="blockPlaceholder" *cdkDragPlaceholder></div>
    <div class="sectionBlockContainer">
      <div
        class="columnBlockContainer firstColumn"
        attr.blockid="{{ blockId }}"
        [ngClass]="[columnsNumber(blockId) !== 2 ? 'fullWidth' : '', getBlockFields(blockId).style?.sectionFullScreen ? 'sectionFullScreen' : '']"
        cdkDropList
        (cdkDropListDropped)="dropped($event)"
        id="{{ 'column' + blockId + 'a' }}"
        [ngStyle]="getBlockFields(blockId).elements ? getBlockFields(blockId).elements[0].style : ''"
      >
        <video src="{{ getBlockFields(blockId).style?.sectionBackgroundVideo }}" loop muted autoplay *ngIf="getBlockFields(blockId).style?.sectionBackgroundVideo"></video>
        <div *ngFor="let firstCol of getBlockFields(blockId).elements ? getBlockFields(blockId).elements[0].elements : []">
          <block-image [nestedBlock]="firstCol" *ngIf="firstCol.type == 'image'" class="blockWrap"></block-image>
          <block-text [nestedBlock]="firstCol" *ngIf="firstCol.type == 'text'" class="blockWrap"></block-text>
          <block-products [nestedBlock]="firstCol" *ngIf="firstCol.type == 'products'" class="blockWrap"></block-products>
          <block-categories [nestedBlock]="firstCol" *ngIf="firstCol.type == 'categories'" class="blockWrap"></block-categories>
          <stacks-products [nestedBlock]="firstCol" *ngIf="firstCol.type == 'stacksProducts'" class="blockWrap"></stacks-products>
          <stacks-categories [nestedBlock]="firstCol" *ngIf="firstCol.type == 'stacksCategories'" class="blockWrap"></stacks-categories>
          <block-video [nestedBlock]="firstCol" *ngIf="firstCol.type == 'video'" class="blockWrap"></block-video>
          <block-slider [nestedBlock]="firstCol" *ngIf="firstCol.type == 'slider'" class="blockWrap"></block-slider>
          <block-posts [nestedBlock]="firstCol" *ngIf="firstCol.type == 'posts'" class="blockWrap"></block-posts>
          <block-button [nestedBlock]="firstCol" *ngIf="firstCol.type == 'button'" class="blockWrap"></block-button>
          <block-static [nestedBlock]="firstCol" *ngIf="firstCol.type == 'static'" class="blockWrap"></block-static>
        </div>
      </div>
      <div class="columnBlockContainer secondColumn" *ngIf="columnsNumber(blockId) == 2" attr.blockid="{{ blockId }}" cdkDropList (cdkDropListDropped)="dropped($event)" id="{{ 'column' + blockId + 'b' }}">
        <div *ngFor="let secondCol of getBlockFields(blockId).elements ? getBlockFields(blockId).elements[1].elements : []">
          <block-text [nestedBlock]="secondCol" *ngIf="secondCol.type == 'text'" class="blockWrap"></block-text>
          <block-image [nestedBlock]="secondCol" *ngIf="secondCol.type == 'image'" class="blockWrap"></block-image>
          <block-products [nestedBlock]="secondCol" *ngIf="secondCol.type == 'products'" class="blockWrap"></block-products>
          <block-categories [nestedBlock]="secondCol" *ngIf="secondCol.type == 'categories'" class="blockWrap"></block-categories>
          <stacks-products [nestedBlock]="secondCol" *ngIf="secondCol.type == 'stacksProducts'" class="blockWrap"></stacks-products>
          <stacks-categories [nestedBlock]="secondCol" *ngIf="secondCol.type == 'stacksCategories'" class="blockWrap"></stacks-categories>
          <block-video [nestedBlock]="secondCol" *ngIf="secondCol.type == 'video'" class="blockWrap"></block-video>
          <block-slider [nestedBlock]="secondCol" *ngIf="secondCol.type == 'slider'" class="blockWrap"></block-slider>
          <block-posts [nestedBlock]="secondCol" *ngIf="secondCol.type == 'posts'" class="blockWrap"></block-posts>
          <block-button [nestedBlock]="secondCol" *ngIf="secondCol.type == 'button'" class="blockWrap"></block-button>
          <block-static [nestedBlock]="secondCol" *ngIf="secondCol.type == 'static'" class="blockWrap"></block-static>
        </div>
      </div>
    </div>
  </div>
  <!-- Data and Style Tabs -->
  <div class="previewFields" *ngIf="checkData()">
    <mat-button-toggle-group [formControl]="sectionColumns" aria-label="Section Column" (click)="sectionColumnsChange(blockId)">
      <mat-button-toggle value="1" [checked]="columnsNumber(blockId) !== 2 ? 'true' : null">{{ '1 Column' | translate }} </mat-button-toggle>
      <mat-button-toggle value="2" [checked]="columnsNumber(blockId) == 2 ? 'true' : null">{{ '2 Columns' | translate }} </mat-button-toggle>
    </mat-button-toggle-group>
  </div>
  <div class="styleFields" *ngIf="checkStyle()">
    <h4 class="listTitle">{{ 'Section Settings' | translate }}</h4>
    <mat-divider></mat-divider>
    <ul>
      <li>
        <mat-slide-toggle (change)="sectionFullScreenChange(blockId, $event)" [(ngModel)]="getBlockFields(blockId).style.sectionFullScreen">Full Screen</mat-slide-toggle>
      </li>
      <li>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'Section Background Image' | translate }}</mat-label>
          <input matInput placeholder="red or https://" value="{{ getBlockFields(blockId).style?.background }}" (keyup)="styleChange('sectionBackground', blockId, $event, 'text')" />
        </mat-form-field>
      </li>
      <li>
        <mat-form-field appearance="outline">
          <mat-label>{{ 'Section Background Video' | translate }}</mat-label>
          <input matInput placeholder="https://" value="{{ getBlockFields(blockId).style?.sectionBackgroundVideo }}" (keyup)="styleChange('sectionBackgroundVideo', blockId, $event, 'text')" />
        </mat-form-field>
      </li>
    </ul>
    <div *ngIf="!getBlockFields(blockId).style.sectionFullScreen">
      <ul>
        <li>
          <mat-slide-toggle (change)="sectionWidthChange(blockId, $event)">{{ 'Full Width' | translate }}</mat-slide-toggle>
        </li>
        <li>
          <mat-label>{{ 'Section Height Auto Fit to Content' | translate }}</mat-label>
          <mat-select (selectionChange)="styleChange('section_height_auto', blockId, $event, 'select')" value="{{ getBlockFields(blockId).style?.section_height_auto ? getBlockFields(blockId).style?.section_height_auto : 'no' }}">
            <mat-option value="yes">
              {{ 'yes' | translate }}
            </mat-option>
            <mat-option value="no">
              {{ 'No' | translate }}
            </mat-option>
          </mat-select>
        </li>
        <li *ngIf="getBlockFields(blockId).style?.section_height_auto !== 'yes'">
          <mat-label>{{ 'Section Height' | translate }}</mat-label>
          <mat-slider showTickMarks discrete max="1000" min="1" step="1" thumbLabel="true">
            <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['height']?.slice(0, -2) }}" (change)="styleChange('height', blockId, $event, 'slider')" />
          </mat-slider>
        </li>
        <!-- <mat-list-item>
            <mat-form-field appearance="outline">
              <mat-label>Section Height</mat-label>
              <input matInput placeholder="red or https://" value="{{getBlockFields(blockId).style?.height}}"
                (keyup)="styleChange('sectionHeight', blockId, $event, 'text')">
            </mat-form-field>
          </mat-list-item> -->
        <li>
          {{ 'First Column Settings' | translate }}
        </li>
        <li>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'Column Background' | translate }}</mat-label>
            <input matInput placeholder="red or https://" value="{{ getBlockFields(blockId).elements[0].style?.background }}" (keyup)="styleChange('firstColumnBackground', blockId, $event, 'text')" />
          </mat-form-field>
        </li>
      </ul>
      <mat-divider></mat-divider>
      <h4 class="listTitle">{{ 'Margins' | translate }}</h4>
      <mat-divider></mat-divider>
      <ul>
        <li>
          <mat-label>{{ 'Margin Top' | translate }}</mat-label>
          <mat-slider showTickMarks discrete max="100" min="-100" step="1" thumbLabel="true">
            <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['margin-top']?.slice(0, -2) }}" (change)="styleChange('margin-top', blockId, $event, 'slider')" />
          </mat-slider>
        </li>
        <li>
          <mat-label>{{ 'Margin Right' | translate }}</mat-label>
          <mat-slider showTickMarks discrete max="100" min="-100" step="1" thumbLabel="true">
            <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['margin-right']?.slice(0, -2) }}" (change)="styleChange('margin-right', blockId, $event, 'slider')" />
          </mat-slider>
        </li>
        <li>
          <mat-label>{{ 'Margin Bottom' | translate }}</mat-label>
          <mat-slider showTickMarks discrete max="100" min="-100" step="1" thumbLabel="true">
            <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['margin-bottom']?.slice(0, -2) }}" (change)="styleChange('margin-bottom', blockId, $event, 'slider')" />
          </mat-slider>
        </li>
        <li>
          <mat-label>{{ 'Margin Left' | translate }}</mat-label>
          <mat-slider showTickMarks discrete max="100" min="-100" step="1" thumbLabel="true">
            <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['margin-left']?.slice(0, -2) }}" (change)="styleChange('margin-left', blockId, $event, 'slider')" />
          </mat-slider>
        </li>
      </ul>
      <mat-label>{{ 'Paddings' | translate }}</mat-label>

      <mat-divider></mat-divider>
      <ul>
        <li>
          <mat-label>{{ 'Padding Top' | translate }}</mat-label>
          <mat-slider showTickMarks discrete max="100" min="1" step="1" thumbLabel="true">
            <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['padding-top']?.slice(0, -2) }}" (change)="styleChange('padding-top', blockId, $event, 'slider')" />
          </mat-slider>
        </li>
        <li>
          <mat-label>{{ 'Padding Right' | translate }}</mat-label>
          <mat-slider showTickMarks discrete max="100" min="1" step="1" thumbLabel="true">
            <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['padding-right']?.slice(0, -2) }}" (change)="styleChange('padding-right', blockId, $event, 'slider')" />
          </mat-slider>
        </li>
        <li>
          <mat-label>{{ 'Padding Bottom' | translate }}</mat-label>
          <mat-slider showTickMarks discrete max="100" min="1" step="1" thumbLabel="true">
            <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['padding-bottom']?.slice(0, -2) }}" (change)="styleChange('padding-bottom', blockId, $event, 'slider')" />
          </mat-slider>
        </li>
        <li>
          <mat-label>{{ 'Padding Left' | translate }}</mat-label>
          <mat-slider showTickMarks discrete max="100" min="1" step="1" thumbLabel="true">
            <input matSliderThumb ngModel="{{ getBlockFields(blockId).style['padding-left']?.slice(0, -2) }}" (change)="styleChange('padding-left', blockId, $event, 'slider')" />
          </mat-slider>
        </li>
      </ul>
    </div>
  </div>
</section>
