import { Component, OnInit } from '@angular/core'

@Component({
  selector: 'mobile-view',
  templateUrl: './mobile-view.component.html',
  styleUrls: ['./mobile-view.component.scss']
})
export class MobileViewComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {
    // alert("Mobile View Component");
  }
}
