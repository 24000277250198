import { Component, OnInit, ViewChild } from '@angular/core'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { stripeService } from '../payment/stripe.service'
import { environment } from 'src/environments/environment'
import { BlockDataService } from 'src/app/block-services/block-data.service'
import { SubscriptionFlowService } from 'src/app/subscription-flow/subscription-flow.service'
import { AppSettingsService } from 'src/app/settings/services/app-settings.service'
import { LoginService } from 'src/app/login/login.service'
import { MatTableDataSource } from '@angular/material/table'
import { MatPaginator } from '@angular/material/paginator'

@Component({
  selector: 'billing',
  templateUrl: './billing.component.html',
  styleUrls: ['./billing.component.scss']
})
export class BillingComponent implements OnInit {
  environment = environment
  displayedColumns: string[] = ['date', 'status', 'amount', 'download']
  dataSource = new MatTableDataSource<any>([])

  @ViewChild(MatPaginator) paginator!: MatPaginator
  user: any
  project: any
  generalSettingsData: any = {}
  constructor(
    private loginService: LoginService,
    private appSettingsService: AppSettingsService,
    public builderService: BuilderService,
    public stripeService: stripeService,
    private blockDataService: BlockDataService,
    public SubscriptionFlow: SubscriptionFlowService
  ) {
    this.blockDataService.getStripeData().subscribe((subId: any) => {
      this.builderService.showUpdateProgress = true
      this.stripeService.getInvoices(subId).subscribe((response: any) => {
        this.builderService.showUpdateProgress = false
      })
    })
    if (this.builderService.stripe_subscription_id) {
      this.blockDataService.sendStripeData(this.builderService.stripe_subscription_id)
    }
  }
  ngOnInit(): void {
    this.loadInvoices()

    this.project = this.builderService.cacheProjectReq.response
    this.getInitialSettings()
    this.builderService.getProjects(this.loginService.getCurrentUser().email).subscribe((allProjects) => {
      const firstKey = Object.keys(allProjects)[0]
      this.user = allProjects[firstKey]
    })
  }
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator
  }

  loadInvoices() {
    this.dataSource.data = this.builderService.invoices?.filter((invoice: any) => invoice.status !== 'void')
  }
  getInitialSettings = () => {
    let project_id = this.builderService.selectedProject
    this.appSettingsService.getGeneralSettings(project_id).subscribe(
      (settings: any) => {
        this.generalSettingsData = settings
      },
      (err) => {
        console.log(err)
      }
    )
  }

  billingRedirect = () => {
    this.stripeService.gotoBilling().subscribe((response: any) => {
      window.location.href = response
    })
  }

  cancelSubscription(): void {
    const userConfirmed = confirm('Are you sure you want to cancel the subscription?')
    if (userConfirmed) {
      this.SubscriptionFlow.cancelSubscription().subscribe(
        (response) => {
          console.log('Subscription canceled successfully:', response)
        },
        (error) => {
          console.error('Error canceling subscription:', error)
        }
      )
    } else {
      console.log('Subscription cancel action was aborted by the user.')
    }
  }
}
