<section class="stacksBlockContainer" id="block-footer" [ngClass]="getBlockFieldsCustomPage(blockId).data?.sticky_footer == 'yes' ? 'stickyFooter' : ''">
  <!-- Mobile Preview -->
  <div class="previewFooter previewMobile" [ngClass]="getBlockFieldsCustomPage(blockId).data?.footerStyle" *ngIf="checkPreview() && !getBlockFieldsCustomPage(blockId).data?.global_footer && getBlockFieldsCustomPage(blockId).data?.global_footer !== undefined && !builderService.globals" id="{{ 'blockid' + blockId }}">
    <div class="blockControlHandlers">
      <div class="editBlock blockActionBtn" (click)="showData(blockId)">
        <mat-icon>edit</mat-icon>
      </div>
    </div>
    <!-- Classic Style -->
    <div class="footerWrapper footerWithColor" *ngIf="getBlockFieldsCustomPage(blockId).data?.footerStyle == 'classic'" [ngClass]="getBlockFieldsCustomPage(blockId).data?.footerMenu">
      <div
        class="navbar"
        [ngClass]="{
          navbar: getBlockFieldsCustomPage(blockId).data?.menuItems.length <= 5,
          navbarHeight: getBlockFieldsCustomPage(blockId).data?.menuItems.length > 5,
          globalColors: getBlockFieldsCustomPage(blockId).data?.colorSource !== 'customColors',
          customColors: getBlockFieldsCustomPage(blockId).data?.colorSource === 'customColors'
        }"
        [ngStyle]="{
          'background-color': getBlockFieldsCustomPage(blockId).data?.bgColor ? getBlockFieldsCustomPage(blockId).data?.bgColor : '#3064F8'
        }"
      >
        <div class="nav-item" *ngFor="let menuItem of getBlockFieldsCustomPage(blockId).data?.menuItems; let i = index" [ngStyle]="i == 0 && getBlockFieldsCustomPage(blockId).data?.colorSource == 'customColors' ? { 'border-color': menuItem.color } : {}">
          <i class="material-icons-outlined" [ngStyle]="getBlockFieldsCustomPage(blockId).data?.colorSource == 'customColors' ? { color: menuItem.color } : {}">{{ menuItem.icon }}</i>
          <p *ngIf="menuItem.menuText && menuItem.menuText !== ''" [ngStyle]="{ color: menuItem.color }">
            {{ menuItem.menuText }}
          </p>
        </div>
      </div>
    </div>
    <!-- Circle Style -->
    <div class="footerWrapper footerWithColor circleFooterWrapper" [ngClass]="getBlockFieldsCustomPage(blockId).data?.footerMenu" *ngIf="getBlockFieldsCustomPage(blockId).data?.footerStyle == 'circle'">
      <div
        class="CircleNavbar"
        [ngClass]="{
          CircleNavbar: getBlockFieldsCustomPage(blockId).data?.menuItems.length <= 4,
          CircleNavbarHeight: getBlockFieldsCustomPage(blockId).data?.menuItems.length > 4,
          globalColors: getBlockFieldsCustomPage(blockId).data?.colorSource !== 'customColors',
          customColors: getBlockFieldsCustomPage(blockId).data?.colorSource === 'customColors'
        }"
        [ngStyle]="{
          'background-color': getBlockFieldsCustomPage(blockId).data?.bgColor ? getBlockFieldsCustomPage(blockId).data?.bgColor : '#3064F8'
        }"
      >
        <div class="CircleNavItem" *ngFor="let menuItem of getBlockFieldsCustomPage(blockId).data?.menuItems; let i = index" [ngClass]="getBlockFieldsCustomPage(blockId).data?.circleMainItem == i ? 'special-icon' : ''">
          <div
            *ngIf="getBlockFieldsCustomPage(blockId).data?.circleMainItem == i"
            class="shopping-bag-wrapper"
            [ngStyle]="{
              'background-color': getBlockFieldsCustomPage(blockId).data?.colorSource === 'customColors' ? getBlockFieldsCustomPage(blockId).data?.circleMainBgColor : ''
            }"
          >
            <i class="material-icons-outlined" [ngStyle]="{ color: menuItem.color }">{{ menuItem.icon }}</i>
          </div>
          <div *ngIf="getBlockFieldsCustomPage(blockId).data?.circleMainItem !== i">
            <i class="material-icons-outlined" [ngStyle]="{ color: menuItem.color }">{{ menuItem.icon }}</i>
            <p *ngIf="menuItem.menuText && menuItem.menuText !== ''" [ngStyle]="{ color: menuItem.color }">
              {{ menuItem.menuText }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- Expand Style -->
    <div class="footerWrapper footerWithColor expandFooterWrapper" [ngClass]="getBlockFieldsCustomPage(blockId).data?.footerMenu" *ngIf="getBlockFieldsCustomPage(blockId).data?.footerStyle == 'expand'">
      <div
        class="expand-navbar"
        [ngClass]="{
          'expand-navbar': getBlockFieldsCustomPage(blockId).data?.menuItems.length <= 3,
          expandHeight: getBlockFieldsCustomPage(blockId).data?.menuItems.length > 3,
          globalColors: getBlockFieldsCustomPage(blockId).data?.colorSource !== 'customColors',
          customColors: getBlockFieldsCustomPage(blockId).data?.colorSource === 'customColors'
        }"
        [ngStyle]="{
          'background-color': getBlockFieldsCustomPage(blockId).data?.bgColor ? getBlockFieldsCustomPage(blockId).data?.bgColor : '#3064F8'
        }"
      >
        <div class="expand-nav-item" *ngFor="let menuItem of getBlockFieldsCustomPage(blockId).data?.menuItems">
          <i class="material-icons-outlined" [ngStyle]="{ color: menuItem.color }">{{ menuItem.icon }}</i>
          <p *ngIf="menuItem.menuText && menuItem.menuText !== ''" [ngStyle]="{ color: menuItem.color }">
            {{ menuItem.menuText }}
          </p>
        </div>
      </div>
    </div>
    <!-- <div *ngIf="getBlockFields(blockId).data?.gFooterBanners[gFooterBanner.index].status">
      <img src="../../../assets/images/admob_sample.jpeg" />
    </div> -->
    <img src="../../../assets/images/adsbanner.gif" *ngIf="blockDataService.gAdMobActiveFooter" />
  </div>

  <!-- Global Footer -->
  <div
    class="previewFooter previewMobile"
    [ngClass]="getBlockFieldsCustomPage(blockId).data?.footerStyle == 'expand' ? 'expandPreviewFooter' : ''"
    *ngIf="checkPreview() && (getBlockFieldsCustomPage(blockId).data?.global_footer || getBlockFieldsCustomPage(blockId).data?.global_footer == undefined || builderService.globals)"
    id="{{ 'blockid' + blockId }}"
  >
    <div class="blockControlHandlers">
      <div class="editBlock blockActionBtn" (click)="showData(blockId)">
        <mat-icon>edit</mat-icon>
      </div>
    </div>
    <!-- Classic -->
    <div
      *ngIf="getBlockFields(blockId) && getBlockFields(blockId).data?.footerMenu && (getBlockFields(blockId).data?.footerStyle == 'classic' || !getBlockFields(blockId).data?.footerStyle)"
      class="footerWrapper footerWithColor"
      [ngClass]="getBlockFields(blockId).data?.footerMenu"
      [ngStyle]="{ 'background-color': getBlockFields(blockId).data?.bgColor ? getBlockFields(blockId).data?.bgColor : '#3064F8' }"
    >
      <div
        class="navbar"
        [ngClass]="{
          navbar: getBlockFields(blockId).data?.menuItems?.length <= 5,
          navbarHeight: getBlockFields(blockId).data?.menuItems?.length > 5,
          globalColors: getBlockFields(blockId).data?.colorSource !== 'customColors',
          customColors: getBlockFields(blockId).data?.colorSource === 'customColors'
        }"
        [ngStyle]="{
          'background-color': getBlockFields(blockId).data?.bgColor ? getBlockFields(blockId).data?.bgColor : '#3064F8'
        }"
      >
        <div class="nav-item" *ngFor="let menuItem of getBlockFields(blockId).data?.menuItems; let i = index" [ngStyle]="i == 0 && getBlockFields(blockId).data?.colorSource == 'customColors' ? { 'border-color': menuItem.color } : {}">
          <i class="material-icons-outlined" [ngStyle]="getBlockFields(blockId).data?.colorSource == 'customColors' ? { color: menuItem.color } : {}">{{ menuItem.icon }}</i>
          <p *ngIf="menuItem.menuText && menuItem.menuText !== ''" [ngStyle]="{ color: menuItem.color }">
            {{ menuItem.menuText }}
          </p>
        </div>
      </div>
    </div>
    <div
      *ngIf="getBlockFields(blockId) && !getBlockFields(blockId).data?.footerMenu && getBlockFields(blockId).footerMenu && getBlockFields(blockId).data?.footerStyle == 'classic'"
      class="footerWrapper footerWithColor"
      [ngClass]="getBlockFields(blockId).footerMenu"
      [ngStyle]="{ 'background-color': getBlockFields(blockId).bgColor ? getBlockFields(blockId).bgColor : '#3064F8' }"
    >
      <div class="navbar" [ngClass]="getBlockFields(blockId).data?.colorSource ? getBlockFields(blockId).data?.colorSource : 'globalColors'" [ngStyle]="{ 'background-color': getBlockFields(blockId).data?.bgColor ? getBlockFields(blockId).data?.bgColor : '#3064F8' }">
        <div class="nav-item" *ngFor="let menuItem of getBlockFields(blockId).data?.menuItems">
          <i class="material-icons" [ngStyle]="{ color: menuItem.color }">{{ menuItem.icon }}</i>
          <p *ngIf="menuItem.menuText && menuItem.menuText !== ''" [ngStyle]="{ color: menuItem.color }">
            {{ menuItem.menuText }}
          </p>
        </div>
      </div>
    </div>

    <!-- Circle Style -->
    <div
      *ngIf="getBlockFields(blockId) && getBlockFields(blockId).data?.footerMenu && getBlockFields(blockId).data?.footerStyle == 'circle'"
      class="footerWrapper footerWithColor"
      [ngClass]="getBlockFields(blockId).data?.footerMenu"
      [ngStyle]="{ 'background-color': getBlockFields(blockId).data?.bgColor ? getBlockFields(blockId).data?.bgColor : '#3064F8' }"
    >
      <div class="CircleNavbar" [ngClass]="getBlockFields(blockId).data?.colorSource ? getBlockFields(blockId).data?.colorSource : 'globalColors'" [ngStyle]="{ 'background-color': getBlockFields(blockId).data?.bgColor ? getBlockFields(blockId).data?.bgColor : '#3064F8' }">
        <div class="CircleNavItem" *ngFor="let menuItem of getBlockFields(blockId).data?.menuItems; let i = index" [ngClass]="getBlockFields(blockId).data?.circleMainItem == i ? 'special-icon' : ''">
          <div
            *ngIf="getBlockFields(blockId).data?.circleMainItem == i"
            class="shopping-bag-wrapper"
            [ngStyle]="{
              'background-color': getBlockFields(blockId).data?.colorSource === 'customColors' ? getBlockFields(blockId).data?.circleMainBgColor : ''
            }"
          >
            <i class="material-icons-outlined" [ngStyle]="{ color: menuItem.color }">{{ menuItem.icon }}</i>
          </div>
          <div *ngIf="getBlockFields(blockId).data?.circleMainItem !== i">
            <i class="material-icons-outlined" [ngStyle]="{ color: menuItem.color }">{{ menuItem.icon }}</i>
            <p *ngIf="menuItem.menuText && menuItem.menuText !== ''" [ngStyle]="{ color: menuItem.color }">
              {{ menuItem.menuText }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="getBlockFields(blockId) && !getBlockFields(blockId).data?.footerMenu && getBlockFields(blockId).footerMenu && getBlockFields(blockId).data?.footerStyle == 'circle'"
      class="footerWrapper rowan footerWithColor"
      [ngClass]="getBlockFields(blockId).footerMenu"
      [ngStyle]="{ 'background-color': getBlockFields(blockId).bgColor ? getBlockFields(blockId).bgColor : '#3064F8' }"
    >
      <div class="CircleNavbar" [ngClass]="getBlockFields(blockId).data?.colorSource ? getBlockFields(blockId).data?.colorSource : 'globalColors'" [ngStyle]="{ 'background-color': getBlockFields(blockId).data?.bgColor ? getBlockFields(blockId).data?.bgColor : '#3064F8' }">
        <div class="CircleNavItem" *ngFor="let menuItem of getBlockFields(blockId).data?.menuItems; let i = index" [ngClass]="getBlockFields(blockId).data?.circleMainItem == i ? 'special-icon' : ''">
          <div
            *ngIf="getBlockFields(blockId).data?.circleMainItem == i"
            class="shopping-bag-wrapper"
            [ngStyle]="{
              'background-color': getBlockFields(blockId).data?.colorSource === 'customColors' ? getBlockFields(blockId).data?.circleMainBgColor : ''
            }"
          >
            <i class="material-icons" [ngStyle]="{ color: menuItem.color }">{{ menuItem.icon }}</i>
          </div>
          <div *ngIf="getBlockFields(blockId).data?.circleMainItem !== i">
            <i class="material-icons" [ngStyle]="{ color: menuItem.color }">{{ menuItem.icon }}</i>
            <p *ngIf="menuItem.menuText && menuItem.menuText !== ''" [ngStyle]="{ color: menuItem.color }">
              {{ menuItem.menuText }}
            </p>
          </div>
        </div>
      </div>
    </div>

    <!-- Expand Style -->
    <div
      *ngIf="getBlockFields(blockId) && getBlockFields(blockId).data?.footerMenu && getBlockFields(blockId).data?.footerStyle == 'expand'"
      class="footerWrapper footerWithColor expandFooterWrapper"
      [ngClass]="getBlockFields(blockId).data?.footerMenu"
      [ngStyle]="{ 'background-color': getBlockFields(blockId).data?.bgColor ? getBlockFields(blockId).data?.bgColor : '#3064F8' }"
    >
      <div
        class="expand-navbar"
        [ngClass]="{
          'expand-navbar': getBlockFields(blockId).data?.menuItems?.length <= 3,
          expandHeight: getBlockFields(blockId).data?.menuItems?.length > 3,
          globalColors: getBlockFields(blockId).data?.colorSource !== 'customColors',
          customColors: getBlockFields(blockId).data?.colorSource === 'customColors'
        }"
        [ngStyle]="{
          'background-color': getBlockFields(blockId).data?.bgColor ? getBlockFields(blockId).data?.bgColor : '#3064F8'
        }"
      >
        <div [ngClass]="getBlockFields(blockId).data?.colorSource ? getBlockFields(blockId).data?.colorSource : 'globalColors'" class="expand-nav-item" *ngFor="let menuItem of getBlockFields(blockId).data?.menuItems">
          <i class="material-icons-outlined" [ngStyle]="{ color: menuItem.color }">{{ menuItem.icon }}</i>
          <p *ngIf="menuItem.menuText && menuItem.menuText !== ''" [ngStyle]="{ color: menuItem.color }">
            {{ menuItem.menuText }}
          </p>
        </div>
      </div>
    </div>
    <div
      *ngIf="getBlockFields(blockId) && !getBlockFields(blockId).data?.footerMenu && getBlockFields(blockId).footerMenu && getBlockFields(blockId).data?.footerStyle == 'expand'"
      class="footerWrapper footerWithColor expandFooterWrapper"
      [ngClass]="getBlockFields(blockId).footerMenu"
      [ngStyle]="{ 'background-color': getBlockFields(blockId).bgColor ? getBlockFields(blockId).bgColor : '#3064F8' }"
    >
      <div [ngClass]="getBlockFields(blockId).data?.colorSource ? getBlockFields(blockId).data?.colorSource : 'globalColors'" class="expand-nav-item" *ngFor="let menuItem of getBlockFields(blockId).data?.menuItems">
        <i class="material-icons" [ngStyle]="{ color: menuItem.color }">{{ menuItem.icon }}</i>
        <p *ngIf="menuItem.menuText && menuItem.menuText !== ''" [ngStyle]="{ color: menuItem.color }">
          {{ menuItem.menuText }}
        </p>
      </div>
    </div>
    <!-- <div *ngIf="getBlockFields(blockId).data?.gFooterBanners[gFooterBanner.index].status">
      <img src="../../../assets/images/admob_sample.jpeg" />
    </div> -->
    <img src="../../../assets/images/adsbanner.gif" *ngIf="blockDataService.gAdMobActiveFooter" />
  </div>
  <!-- Data and Style Tabs -->
  <div class="previewFields" *ngIf="checkData()">
    <mat-progress-bar mode="indeterminate" *ngIf="showProgress"></mat-progress-bar>
    <div class="sectionSettings" *ngIf="!builderService.globals">
      <div class="sectionHeader">{{ 'Activate Global Settings' | translate }}</div>
      <ul>
        <li>
          <mat-slide-toggle (change)="toggleChanged(blockId, 'global_footer')" [(ngModel)]="blockFieldsService.readGlobalFooter">
            <span>{{ 'Activate Global footer' | translate }}</span>
          </mat-slide-toggle>
        </li>
      </ul>
    </div>
    <div class="sectionSettings" *ngIf="!blockFieldsService.readGlobalFooter || builderService.globals">
      <div class="sectionHeader">{{ 'Footer Settings' | translate }}</div>
      <ul>
        <li>
          <mat-form-field appearance="outline">
            <mat-label>{{ 'Footer Activation' | translate }}</mat-label>
            <mat-select (selectionChange)="queryChange('footerMenu', blockId, $event, 'select', 0, '')" [(value)]="getBlockFields(blockId).data.footerMenu">
              <mat-option value="active">
                {{ 'Enable Footer Menu' | translate }}
              </mat-option>
              <mat-option value="inactive">
                {{ 'Disable Footer Menu' | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </li>
        <li>
          <mat-form-field appearance="outline">
            <mat-label>Footer Style</mat-label>
            <mat-select (selectionChange)="queryChange('footerStyle', blockId, $event, 'select', 0, '')" [(value)]="getBlockFields(blockId).data.footerStyle">
              <mat-option value="classic">
                {{ 'Classic Style' | translate }}
              </mat-option>
              <mat-option value="expand">
                {{ 'Expand Style' | translate }}
              </mat-option>
              <mat-option value="circle">
                {{ 'Circle Style' | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </li>
        <li *ngIf="getBlockFields(blockId).data.footerStyle == 'circle'">
          <mat-form-field appearance="outline">
            <mat-label>{{ 'Circle Main Item' | translate }}</mat-label>
            <mat-select (selectionChange)="queryChange('circleMainItem', blockId, $event, 'select', 0, '')" [(value)]="getBlockFields(blockId).data.circleMainItem">
              <mat-option [value]="i" *ngFor="let menuItem of getBlockFields(blockId).data?.menuItems; let i = index">
                {{ menuItem.menuText }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </li>
        <li class="contentContainerColor">
          <label>{{ 'Background Color' | translate }}</label>
          <mat-form-field>
            <p class="colorPickerValue">{{ getBlockFields(blockId).data?.bgColor }}</p>
            <label for="bgColor" class="colorLabel">
              <input type="color" id="bgColor" matInput class="colorPicker" name="bgColor" (change)="queryChange('bgColor', blockId, $event, 'text', 0, '')" value="{{ getBlockFields(blockId).data?.bgColor }}" />
            </label>
          </mat-form-field>
        </li>
        <li class="contentCenter">
          <!-- <mat-label>Color Source</mat-label> -->
          <mat-form-field>
            <mat-select (selectionChange)="queryChange('colorSource', blockId, $event, 'select', 0, '')" value="{{ getBlockFields(blockId).data?.colorSource ? getBlockFields(blockId).data?.colorSource : '' }}">
              <mat-option value="" disabled>
                {{ 'Select Color Source' | translate }}
              </mat-option>
              <mat-option value="globalColors">
                {{ 'Global Colors' | translate }}
              </mat-option>
              <mat-option value="customColors">
                {{ 'Custom Color' | translate }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </li>
        <li class="contentCenter" *ngIf="getBlockFields(blockId).data?.colorSource == 'customColors' && getBlockFields(blockId).data?.footerStyle == 'circle'">
          <mat-form-field>
            <mat-label>{{ 'Main Circle Background Color' | translate }}</mat-label>
            <p class="colorPickerValue">{{ getBlockFields(blockId).data?.circleMainBgColor }}</p>
            <label for="circleMainBgColor" class="colorLabel">
              <input type="color" id="circleMainBgColor" matInput class="colorPicker" name="circleMainBgColor" (change)="queryChange('circleMainBgColor', blockId, $event, 'text', 0, '')" value="{{ getBlockFields(blockId).data?.circleMainBgColor }}" />
            </label>
          </mat-form-field>
        </li>
        <ul class="singleMenuSettings">
          <li *ngFor="let menuItem of getBlockFields(blockId).data?.menuItems">
            <button *ngIf="menuItem.index !== 0" class="deleteMenuItem material-icons-outlined" (click)="deleteMenuItem(blockId, menuItem.index)">delete</button>
            <div class="menuItemData" [ngClass]="menuItem.index == 0 ? 'firstMenuItemData' : ''">
              <div class="MenuItemContainer">
                <div class="MenuColorContainer">
                  <button class="stacksBtn green dialogSelectorBtn iconSelectorBtn" (click)="selectIconDialog(blockId, menuItem.index)">Change Icon</button>
                  <label for="color" class="colorLabel">
                    <input
                      type="color"
                      id="color"
                      matInput
                      class="colorPicker"
                      name="color"
                      *ngIf="getBlockFields(blockId).data?.colorSource == 'customColors'"
                      (change)="queryChange('color', blockId, $event, 'multilevelText', menuItem.index, 'menuItems')"
                      value="{{ getBlockFields(blockId).data?.menuItems[menuItem.index]?.color ? getBlockFields(blockId).data?.menuItems[menuItem.index]?.color : '#ffffff' }}"
                    />
                  </label>
                </div>
                <label>{{ 'Menu Item Text' | translate }}</label>
                <mat-form-field>
                  <input
                    type="text"
                    matInput
                    class="menuText"
                    name="menuText"
                    (change)="queryChange('menuText', blockId, $event, 'multilevelText', menuItem.index, 'menuItems')"
                    value="{{ getBlockFields(blockId).data?.menuItems[menuItem.index]?.menuText ? getBlockFields(blockId).data?.menuItems[menuItem.index]?.menuText : 'Home' }}"
                  />
                </mat-form-field>
              </div>

              <ul class="menuLinker">
                <li>
                  <mat-form-field appearance="outline">
                    <mat-label>{{ 'Link Type' | translate }}</mat-label>
                    <mat-select (selectionChange)="queryChange('link', blockId, $event, 'multilevelSelect', menuItem.index, 'menuItems')" value="{{ getBlockFields(blockId).data?.menuItems[menuItem.index].link }}">
                      <mat-option value="home">
                        {{ 'Home' | translate }}
                      </mat-option>
                      <mat-option value="products">
                        {{ 'Products' | translate }}
                      </mat-option>
                      <mat-option value="allProductsCategories">
                        {{ 'All Products Categories' | translate }}
                      </mat-option>
                      <mat-option value="productsCategories">
                        {{ 'Product Category' | translate }}
                      </mat-option>
                      <mat-option value="productsSubCategories">
                        {{ 'Product Sub Category' | translate }}
                      </mat-option>
                      <mat-option value="posts">
                        {{ 'Posts' | translate }}
                      </mat-option>
                      <mat-option value="externalURL">
                        {{ 'External URL' | translate }}
                      </mat-option>
                      <mat-option value="account">
                        {{ 'Account Page' | translate }}
                      </mat-option>
                      <mat-option value="signup">
                        {{ 'Signup / Signin' | translate }}
                      </mat-option>
                      <mat-option value="contact">
                        {{ 'Contact Us' | translate }}
                      </mat-option>
                      <mat-option value="about">
                        {{ 'About Us' | translate }}
                      </mat-option>
                      <mat-option value="orders">
                        {{ 'Orders' | translate }}
                      </mat-option>
                      <mat-option value="wishlist">
                        {{ 'Wishlist' | translate }}
                      </mat-option>
                      <mat-option value="cart">
                        {{ 'Cart' | translate }}
                      </mat-option>
                      <mat-option value="terms">
                        {{ 'Terms' | translate }}
                      </mat-option>
                      <mat-option *ngFor="let customView of builderService.customViews" value="{{ customView.view_name }}">
                        {{ customView.view_name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </li>
                <li *ngIf="getBlockFields(blockId).data?.menuItems[menuItem.index].link == 'products' && !builderService.standalone">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ 'Product' | translate }}</mat-label>
                    <mat-select (selectionChange)="queryChange('product', blockId, $event, 'multilevelSelect', menuItem.index, 'menuItems')" [(value)]="getBlockFields(blockId).data.menuItems[menuItem.index].product">
                      <mat-option *ngFor="let product of storedProducts" [value]="product.id">
                        {{ product.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </li>
                <li *ngIf="getBlockFields(blockId).data?.menuItems[menuItem.index].link == 'products' && builderService.standalone">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ 'Standalone Product' | translate }}</mat-label>
                    <mat-select (selectionChange)="queryChange('product', blockId, $event, 'multilevelSelect', menuItem.index, 'menuItems')" [(value)]="getBlockFields(blockId).data.menuItems[menuItem.index].product">
                      <mat-option *ngFor="let product of standaloneProductsService.storedStandaloneProducts" [value]="product.id">
                        {{ product.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </li>
                <li *ngIf="getBlockFields(blockId).data?.menuItems[menuItem.index].link == 'productsCategories' && !builderService.standalone">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ 'Product Category' | translate }}</mat-label>
                    <mat-select (selectionChange)="queryChange('categories', blockId, $event, 'multilevelSelect', menuItem.index, 'menuItems')" [(value)]="getBlockFields(blockId).data?.menuItems[menuItem.index].categories">
                      <mat-option *ngFor="let productCategory of categoriesService.storedCategories" [value]="productCategory.id">
                        {{ productCategory.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </li>

                <li *ngIf="getBlockFields(blockId).data?.menuItems[menuItem.index].link == 'productsCategories' || (getBlockFields(blockId).data?.menuItems[menuItem.index].link == 'productsSubCategories' && builderService.standalone)">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ 'Product Category' | translate }}</mat-label>
                    <mat-select (selectionChange)="queryChange('categories', blockId, $event, 'multilevelSelect', menuItem.index, 'menuItems'); getCat($event)" [(value)]="getBlockFields(blockId).data?.menuItems[menuItem.index].categories">
                      <mat-option *ngFor="let productCategory of standaloneCategoriesService.storedStandaloneCategories" [value]="productCategory.id">
                        {{ productCategory.name }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </li>
                <li *ngIf="getBlockFields(blockId).data?.menuItems[menuItem.index].link == 'productsSubCategories' && builderService.standalone && this.standaloneCategoriesService.availableSubs?.length > 0">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ 'Product Sub Category' | translate }}</mat-label>
                    <mat-select (selectionChange)="queryChange('subcategories', blockId, $event, 'multilevelSelect', menuItem.index, 'menuItems')" [(value)]="getBlockFields(blockId).data?.menuItems[menuItem.index].subcategories">
                      <mat-option *ngFor="let subCat of this.standaloneCategoriesService.availableSubs" [value]="subCat.subCategoryId">
                        {{ subCat.subcategory }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </li>
                <!-- <li *ngIf="getBlockFields(blockId).data?.menuItems[menuItem.index].link == 'posts'">
                    <mat-label>{{'Post Type' | translate }}</mat-label>
                    <mat-select (selectionChange)="queryChange('post_type', blockId, $event, 'multilevelSelect', menuItem.index, 'menuItems')"
                      value="{{getBlockFields(blockId).data?.menuItems[menuItem.index].post_type}}">
                      <mat-option *ngFor="let postType of postTypes" [value]="postType">
                        {{postType}}
                      </mat-option>
                    </mat-select>
                  </li> -->
                <li *ngIf="getBlockFields(blockId).data?.menuItems[menuItem.index].link == 'posts'">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ 'Post' | translate }}</mat-label>
                    <mat-select (selectionChange)="queryChange('post', blockId, $event, 'multilevelSelect', menuItem.index, 'menuItems')" [(value)]="getBlockFields(blockId).data?.menuItems[menuItem.index].post">
                      <mat-option *ngFor="let post of storedPosts" [value]="post.ID">
                        {{ post.post_title }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </li>
                <li *ngIf="getBlockFields(blockId).data?.menuItems[menuItem.index].link == 'externalURL'">
                  <mat-form-field appearance="outline">
                    <mat-label>{{ 'External URL' | translate }}</mat-label>
                    <input matInput value="{{ getBlockFields(blockId).data?.menuItems[menuItem.index].externalURL }}" (keyup)="queryChange('externalURL', blockId, $event, 'multilevelText', menuItem.index, 'menuItems')" />
                  </mat-form-field>
                </li>
              </ul>
            </div>
          </li>
        </ul>
        <li>
          <button class="addItem" (click)="addMenuItem(blockId)">{{ 'Add Item' | translate }}</button>
        </li>
      </ul>
    </div>
  </div>
</section>
