<h1 class="discHead">Discount Timer List</h1>

<div class="discountWrapper">
  <div *ngIf="!hasdiscounts()" class="noDiscount">
    <button class="addDiscountBtn" routerLink="/discount/add-discount">
      <span>+</span>
      Add New Discount
    </button>
    <img class="addDiscountLogo" src="assets/images/discount.png" />
  </div>
  <div class="tableWrapper" *ngIf="hasdiscounts()">
    <div class="tableHeader">
      <div class="headerBtns">
        <button class="stacksBtn blue" routerLink="/discount/add-discount" class="addNewDiscount">
          <span>+</span>
          Add New Discoount
        </button>
        <form class="searchBar">
          <input type="text" placeholder="Search" [(ngModel)]="searchTerm" [ngModelOptions]="{ standalone: true }" (ngModelChange)="applyFilter($event)" />
        </form>
      </div>
    </div>

    <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
        <td mat-cell *matCellDef="let discount">{{ discount.discountName }}</td>
      </ng-container>

      <ng-container matColumnDef="sale_date_from">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sale From</th>
        <td mat-cell *matCellDef="let discount">{{ discount.dateFrom | date: 'dd-MM-yyyy' }} {{ discount.timeFrom }}</td>
      </ng-container>

      <ng-container matColumnDef="sale_date_to">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Sale To</th>
        <td mat-cell *matCellDef="let discount">{{ discount.dateTo | date: 'dd-MM-yyyy' }} {{ discount.timeTo }}</td>
      </ng-container>

      <ng-container matColumnDef="amount">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Amount</th>
        <td mat-cell *matCellDef="let discount">{{ discount.discountPercentage }} %</td>
      </ng-container>

      <ng-container matColumnDef="discountCriteria">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>Discount Criteria</th>
        <td mat-cell *matCellDef="let discount">
          {{ discountCriteriaMap[discount.discountCriteria] }}
        </td>
      </ng-container>

      <ng-container matColumnDef="state">
        <th mat-header-cell *matHeaderCellDef mat-sort-header>State</th>
        <td mat-cell *matCellDef="let discount">
          {{ discountStates[discount.id] }}
        </td>
      </ng-container>

      <ng-container matColumnDef="actions">
        <th mat-header-cell *matHeaderCellDef>Actions</th>
        <td mat-cell *matCellDef="let discount">
          <button mat-button [routerLink]="['/discount/add-discount', discount.id]" class="editDiscount">
            <span class="material-icons">edit</span>
          </button>
          <button mat-button (click)="deleteDiscount(discount.id)" class="deleteDiscount">
            <span class="material-icons">delete</span>
          </button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
    <mat-paginator #paginator [pageSize]="10" [pageSizeOptions]="[5, 10, 25]" showFirstLastButtons></mat-paginator>
  </div>
</div>
