import { Component, Input, OnInit } from '@angular/core'
import { BlockFieldsService } from 'src/app/block-services/block-fields.service'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { BlockDataService } from '../../block-services/block-data.service'

import { ProductsService } from '../block-products/products.service'
import { ProductsService as standaloneProductsService } from '../../e-commerce/products/products.service'
import { CategoriesService as standaloneCategoriesService } from '../../e-commerce/categories/categories.service'
import { CategoriesService } from '../block-categories/categories.service'
import { PostsService } from '../block-posts/posts.service'
import { MatSelectChange } from '@angular/material/select'
@Component({
  selector: 'block-static',
  templateUrl: './block-static.component.html',
  styleUrls: ['./block-static.component.scss']
})
export class BlockStaticComponent implements OnInit {
  previewBlock = false
  styleFields = false
  blockData: any
  pages: Array<any> = []

  previewFields = false

  blockFields: any

  blockId = ''

  @Input() nestedBlock: any = {}
  @Input() importedBlockData: any
  showProgress = false
  storedCategories: any = []
  storedPosts: any = []
  storedProducts: any = []
  selectedRedirect: string = ''

  constructor(
    private blockDataService: BlockDataService,
    private blockFieldsService: BlockFieldsService,
    public builderService: BuilderService,
    private productsService: ProductsService,
    public standaloneProductsService: standaloneProductsService,
    public categoriesService: CategoriesService,
    public standaloneCategoriesService: standaloneCategoriesService,
    private postsService: PostsService
  ) {}

  ngOnInit() {
    const initialRedirectTo = this.getBlockFields(this.blockId).data?.redirectTo || ''
    this.selectedRedirect = initialRedirectTo

    if (this.importedBlockData) {
      this.previewBlock = true
      this.previewFields = false
      this.styleFields = false
    }
    if (this.nestedBlock.type == 'static') {
      this.blockData = this.nestedBlock
      this.blockId = this.nestedBlock.id
      this.previewBlock = true
    } else {
      this.blockData = this.importedBlockData ? this.importedBlockData : this.blockFieldsService.staticBlockData
      if (!this.blockId) {
        this.blockId = this.blockDataService.getUniqueId(2)
      }
    }

    if (this.checkSettings()) {
      this.showDataTab()
    }
  }

  // getStaticVariationImage(blockId: string) {
  //   let blockView = this.getBlockFields(blockId).data.view;

  //   let variationView = this.getBlockFields(blockId).data.variations.block;
  //   return "../../../assets/images/views/components/"+blockView+"/"+variationView+".png";
  // }
  /**
   * Appends Block to the Preview, Initial checks if the block is added as initial block addition or appended live
   */
  addBlockToPreview(initial: any = '') {
    let blockStaticData = {
      component: BlockStaticComponent,
      index: 'latest',
      element: this,
      initial: initial
    }

    this.blockDataService.sendBlockData(blockStaticData)
  }

  appendBlock($event: any) {
    let clonedBlockData = { ...this.blockData }
    this.blockId = this.blockDataService.appendBlocksData(clonedBlockData)
    this.addBlockToPreview()
    this.builderService.logData('Image Block Added', 'Block Add', 'Image Block Added').subscribe(
      (response) => {},
      (err) => {}
    )
  }

  /**
   * Sync the clicked block on click inside the mobile preview
   */
  showData(blockId: any) {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      // show data of the last element instead
      blockId = this.blockDataService.getLatestBlockId()
    }
    this.blockFieldsService.clickedBlockId = blockId
    this.blockDataService.sendCurrentBlock(BlockStaticComponent)
  }

  styleChange(style: any, blockId: any, $event: any, type: string) {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return
    }
    let textBlockFields = this.blockDataService.getSelectedBlockData(blockId)
    /**
     * Modifying Nested Object Directly doesn't work, so we have to clone the data object first then modify it
     */
    let clonedBlockFieldsData = { ...textBlockFields.style }
    let data = ''
    if (type == 'select') {
      data = $event.value
    } else if (type == 'text') {
      data = $event.currentTarget.value
    } else if (type == 'slider') {
      data = $event.currentTarget.value + 'px'
    }

    clonedBlockFieldsData[style] = data
    textBlockFields.style = clonedBlockFieldsData
  }

  getBlockFields = (id: string) => {
    if (this.importedBlockData) {
      return this.importedBlockData
    }
    return this.blockDataService.getSelectedBlockData(id)
  }

  showStyleTab = () => {
    this.previewBlock = false
    this.previewFields = false
    this.styleFields = true
  }

  showDataTab = () => {
    this.previewBlock = false
    this.previewFields = true
    this.styleFields = false
  }

  checkSidebar = () => {
    if (!this.previewBlock && !this.previewFields && !this.styleFields) {
      return true
    }
    return false
  }

  checkPreview = () => {
    if (this.previewBlock && !this.previewFields && !this.styleFields) {
      return true
    }
    return false
  }

  checkData = () => {
    if (this.previewFields) {
      return true
    }
    return false
  }

  checkStyle = () => {
    if (this.styleFields && !this.previewFields && !this.previewBlock) {
      return true
    }
    return false
  }

  checkSettings = () => {
    if (!this.checkSidebar() && !this.checkPreview()) {
      return true
    }
    return false
  }

  /**
   * Deletes the Block from the mobile Preview
   * Deletes the Block from the JSON Object
   */
  deleteBlock = (blockId: any) => {
    this.blockDataService.removeBlockData(blockId)
  }

  queryChange(query: any, blockId: any, $event: any, type: string) {
    // Check Block Availability before taking action
    if (!this.blockDataService.checkBlockAvailability(blockId)) {
      return
    }
    let buttonBlockFields = this.blockDataService.getSelectedBlockData(blockId)
    /**
     * Modifying Nested Object Directly doesn't work, so we have to clone the data object first then modify it
     */
    let clonedBlockFieldsData = { ...buttonBlockFields.data }
    let data = ''
    if (type == 'select') {
      data = $event.value
    } else if (type == 'text') {
      data = $event.currentTarget.value
    } else if (type == 'slider') {
      data = $event.currentTarget.value + 'px'
    } else if (type == 'sliderPercentage') {
      data = $event.value + '%'
    }

    clonedBlockFieldsData[query] = data
    buttonBlockFields.data = clonedBlockFieldsData
  }

  getBlockFieldsCustomPage = (id: string) => {
    if (this.importedBlockData) {
      return this.importedBlockData
    }
    if (!this.blockDataService.blocksData) {
      return false
    }
    return this.blockDataService.getSelectedBlockData(id)
  }
  getPosts = (projectId: number, args: object, cached = true) => {
    this.showProgress = true
    if (cached) {
      this.postsService.getPostsCached().subscribe(
        (response) => {
          this.showProgress = false
          let posts: any = []
          for (const key in response) {
            if (Object.prototype.hasOwnProperty.call(response, key)) {
              const element = response[key]
              if (element) {
                posts.push({ ID: element.ID, post_title: element.post_title, post_image: element.post_image })
              }
            }
          }
          this.storedPosts = posts
        },
        (err) => {}
      )
    } else {
      this.postsService.getPosts(projectId, args).subscribe(
        (response) => {
          this.showProgress = false
          let posts: any = []
          for (const key in response) {
            if (Object.prototype.hasOwnProperty.call(response, key)) {
              const element = response[key]
              if (element) {
                posts.push({ ID: element.ID, post_title: element.post_title, post_image: element.post_image })
              }
            }
          }
          this.storedPosts = posts
        },
        (err) => {}
      )
    }

    return true
  }

  getProducts = (projectId: number, args: object, cached = true) => {
    this.showProgress = true
    if (cached) {
      this.productsService.getProductsCached().subscribe(
        (response) => {
          this.showProgress = false
          let products: any = []
          let responseProducts = response.products
          for (const key in responseProducts) {
            if (Object.prototype.hasOwnProperty.call(responseProducts, key)) {
              const element = responseProducts[key]
              if (element) {
                products.push({ id: element.id, name: element.name })
              }
            }
          }
          this.storedProducts = products
        },
        (err) => {}
      )
    } else {
      this.productsService.getProducts(projectId, args).subscribe(
        (response) => {
          this.showProgress = false
          let products: any = []
          let responseProducts = response.products
          for (const key in responseProducts) {
            if (Object.prototype.hasOwnProperty.call(responseProducts, key)) {
              const element = responseProducts[key]
              if (element) {
                products.push({ id: element.id, name: element.name })
              }
            }
          }
          this.storedProducts = products
        },
        (err) => {}
      )
    }

    return true
  }

  getStandaloneProducts = () => {
    this.standaloneProductsService.getProducts().subscribe((response_products: any) => {
      let products: any = []
      for (const key in response_products) {
        if (Object.prototype.hasOwnProperty.call(response_products, key)) {
          const element = response_products[key]
          products.push({ id: element.Id, name: element.productName })
        }
      }
      this.standaloneProductsService.storedStandaloneProducts = products
    })
  }
  getStandaloneCategories = () => {
    this.standaloneCategoriesService.getCategories().subscribe((response: any) => {
      let categories: any = []
      for (const key in response) {
        if (Object.prototype.hasOwnProperty.call(response, key)) {
          const element = response[key]
          categories.push({ id: element.categoryId, name: element.categoryName, thumbnail: element.categoryImage })
        }
      }
      this.standaloneCategoriesService.storedStandaloneCategories = categories
    })
  }

  onRedirectSelectionChange(event: MatSelectChange) {
    this.selectedRedirect = event.value
    this.queryChange('redirectTo', this.blockId, event, 'select')
  }
}
