import { Injectable } from '@angular/core'
import { ActivatedRoute, Router, RouterStateSnapshot } from '@angular/router'
import { BuilderService } from '../builder-services/builder.service'

@Injectable({
  providedIn: 'root'
})
export class Tourguard {
  constructor(
    private builderService: BuilderService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  canActivate = (route: any, state: RouterStateSnapshot) => {
    if (localStorage.getItem('tourStart')) {
      // Is the tour active so that i can start this page ?
      if (!this.builderService.getTour()) return true

      // this.router.navigate(['/site-type']);
      // this.router.navigate(['/templates']);
      return false
    } else {
      return false
    }
  }
}
