import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { DatabaseService } from 'src/app/builder-services/database.service'

@Injectable({
  providedIn: 'root'
})
export class ShippingService {
  constructor(
    private builderService: BuilderService,
    private db: DatabaseService
  ) {}

  submitCountry = (country: any) => {
    let project_id = this.builderService.selectedProject
    country = JSON.parse(JSON.stringify(country))
    return new Observable<any>((observer) => {
      this.db.setDatabase('projects/' + project_id + '/shipping/', country).subscribe(
        (response) => {
          observer.next(response) // Notify the observer that the operation is complete
          observer.complete() // Complete the Observable
        },
        (error) => {
          observer.error(error) // Notify the observer of an error
        }
      )
    })
  }

  getCountries = () => {
    let project_id = this.builderService.selectedProject
    return new Observable<any>((observer) => {
      this.db.getDatabase('projects/' + project_id + '/shipping/').subscribe(
        (response) => {
          if (!response) {
            console.log('No data available')
            observer.next({})
            observer.complete() // Complete the Observable
          } else {
            observer.next(response) // Notify the observer that the operation is complete
            observer.complete() // Complete the Observable
          }
        },
        (error) => {
          observer.error(error) // Notify the observer of an error
        }
      )
    })
  }
}
