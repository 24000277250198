import { ActivatedRoute, Router } from '@angular/router'
import { Component, OnInit } from '@angular/core'
import { BlockDataService } from 'src/app/block-services/block-data.service'
import { TemplatesService } from '../templates.service'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { WorksDialogComponent } from 'src/app/pages/works-dialog/works-dialog.component'
import { MatDialog } from '@angular/material/dialog'
import { ViewsService } from 'src/app/views/views.service'
import { VersionsService } from 'src/app/pages/versions/versions.service'
import { MixpanelService } from 'src/app/tracking/mixpanel.service'
import { DatabaseService } from 'src/app/builder-services/database.service'
import { CategoriesService } from 'src/app/e-commerce/categories/categories.service'

@Component({
  selector: 'app-templates-new',
  templateUrl: './templates-new.component.html',
  styleUrls: ['./templates-new.component.scss']
})
export class TemplatesNewComponent implements OnInit {
  templatesList: Array<any>
  showTemplatesProgress = false
  constructor(
    private templateService: TemplatesService,
    private blockDataService: BlockDataService,
    private router: Router,
    private route: ActivatedRoute,
    public builderService: BuilderService,
    public dialog: MatDialog,
    private viewsService: ViewsService,
    private versions: VersionsService,
    private mixPanel: MixpanelService,
    private database: DatabaseService,
    private categoriesService: CategoriesService
  ) {
    this.templatesList = []
    this.showTemplatesProgress = true
    this.builderService.getProject(String(localStorage.getItem('selectedProject'))).subscribe((project: any) => {
      // Cache the response
      this.builderService.user_type = project.user_type
      this.builderService.cacheProjectReq.response = project
      this.database.getDatabase('templates_new').subscribe((standaloneTemplates) => {
        this.loadTemplates(standaloneTemplates, project)
        this.showTemplatesProgress = false
        if (window.screen.width > 768) {
          // 768px portrait
          document.querySelector('template-view')?.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' })
        }
      })
    })
  }
  loadTemplates = (templates: any, project: any) => {
    for (const key in templates) {
      if (Object.prototype.hasOwnProperty.call(templates, key)) {
        const element = templates[key]
        this.templatesList.push({
          img: element.template_image,
          name: element.template_name,
          project: element.project,
          template_id: element.template_id,
          template_category: element.template_category
        })
      }
    }
  }

  ngOnInit(): void {}

  selectNewTemplate = (template: any) => {
    let template_project = template.project
    let primary_color = template.project.settings.application_settings.mobile_primary_color
    let secondary_color = template.project.settings.application_settings.mobile_text_color
    let global_settings = template.project.settings.global_settings
    let views = template_project.views
    let products = template.project.products
    console.log(products)
    let categories = template.project.categories
    let shipping = template.project.shipping
    if (this.builderService.getTour()) {
      this.builderService.showUpdateProgress = true
      this.database.setDatabase('projects/' + this.builderService.getSelectedProject() + '/settings/application_settings/mobile_primary_color', primary_color).subscribe((response) => {})
      this.database.setDatabase('projects/' + this.builderService.getSelectedProject() + '/settings/application_settings/mobile_text_color', secondary_color).subscribe((response) => {})
      this.database.setDatabase('projects/' + this.builderService.getSelectedProject() + '/settings/global_settings', global_settings).subscribe((response) => {})
      this.database.setDatabase('projects/' + this.builderService.getSelectedProject() + '/views', views).subscribe((response) => {})
      this.database.setDatabase('projects/' + this.builderService.getSelectedProject() + '/products', products).subscribe((response) => {})
      this.database.setDatabase('projects/' + this.builderService.getSelectedProject() + '/categories', categories).subscribe((response) => {
        if (this.builderService.getTour()) {
          this.router.navigate(['/applicationsettings'], { relativeTo: this.route })
        } else {
          this.router.navigate(['/'], { relativeTo: this.route })
        }
      })
      // this.database.setDatabase('projects/' + this.builderService.getSelectedProject() + '/shipping', shipping).subscribe((response) => {
      //   if(this.builderService.getTour()) {
      //     this.router.navigate(['/products'], { relativeTo: this.route });
      //   } else {
      //     this.router.navigate(['/'], { relativeTo: this.route });
      //   }
      // });
    } else {
      if (confirm('Are you sure you want to select this template?, This replaces all your views with the selected template views')) {
        this.builderService.showUpdateProgress = true
        this.versions.setVersion(this.builderService.getSelectedProject(), 'Auto Version : ' + new Date().toUTCString()).subscribe((response: any) => {
          const products_keys = Object.keys(products)
          const lastProdKey = products_keys[products_keys.length - 1] // Determine the last key
          let categories_values: any = []
          Object.keys(categories).forEach((key) => {
            categories_values.push(categories[key])
          })

          this.categoriesService.submitCategory(categories_values).subscribe(() => {
            // Check if the current key is the last key
            products_keys.forEach((prod_key) => {
              const prod_value = products[prod_key]
              this.database.setDatabase('projects/' + this.builderService.getSelectedProject() + '/products/', prod_value, true).subscribe(() => {
                if (prod_key === lastProdKey) {
                  this.database.setDatabase('projects/' + this.builderService.getSelectedProject() + '/settings/application_settings/mobile_primary_color', primary_color).subscribe(() => {
                    this.database.setDatabase('projects/' + this.builderService.getSelectedProject() + '/settings/application_settings/mobile_text_color', secondary_color).subscribe(() => {
                      // this.database.setDatabase('projects/' + this.builderService.getSelectedProject() + '/settings/global_settings/', global_settings).subscribe(() => {
                      this.database.setDatabase('projects/' + this.builderService.getSelectedProject() + '/views/', views).subscribe((response) => {
                        this.builderService.showUpdateProgress = false
                        if (!this.builderService.standalone) {
                          this.viewsService.allViewsFormatter(views, true)
                          setTimeout(() => {
                            this.builderService.updateProjectWithNewViews()
                          }, 500)
                        } else {
                          this.router.navigate(['/'], { relativeTo: this.route })
                        }
                      })
                      // });
                    })
                  })
                }
              })
            })
          })

          // this.database.setDatabase('projects/' + this.builderService.getSelectedProject() + '/products', products).subscribe((response) => {
          // });
          // this.database.setDatabase('projects/' + this.builderService.getSelectedProject() + '/categories', categories).subscribe((response) => {
          // });

          // this.database.setDatabase('projects/' + this.builderService.getSelectedProject(), template.project).subscribe((response) => {

          // });
        })
      }
    }
    this.mixPanel.track('Template Selected', {
      projectid: this.builderService.getSelectedProject(),
      template: template.name
    })
  }
  skipTour = () => {
    this.builderService.setTour(false).subscribe(
      (response) => {
        this.router.navigate(['/'], { relativeTo: this.route })
      },
      (err) => {}
    )
  }

  howItWorksDialog = () => {
    const dialogRef = this.dialog.open(WorksDialogComponent, {
      width: '700px',
      maxWidth: '80%',
      data: { name: 'templates' }
    })

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed')
    })
  }
}
