import { ActivatedRoute, Router } from '@angular/router'
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core'
import { BuilderService } from 'src/app/builder-services/builder.service'
import { LiveAnnouncer } from '@angular/cdk/a11y'
import { MatCheckbox } from '@angular/material/checkbox'
import { MatPaginator, PageEvent } from '@angular/material/paginator'
import { MatSort, Sort } from '@angular/material/sort'
import { MatTable, MatTableDataSource } from '@angular/material/table'
import { ProductsService } from '../products.service'

export interface productsTableColumns {
  id: string
  image: string
  name: string
  sku: string
  stock: string
  price: string
  date: string
  publish: boolean
  action: string
  checked?: boolean
}

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit, AfterViewInit {
  @ViewChild(MatPaginator) paginator!: MatPaginator
  @ViewChild(MatTable)
  table!: MatTable<productsTableColumns>
  @ViewChild(MatSort, { static: true })
  matSort!: MatSort
  @ViewChild('selectAllCheckbox') selectAllCheckbox!: MatCheckbox
  products: any[] = []
  dataSource = new MatTableDataSource<any>([])
  pageSize = 5
  pageIndex = 0
  hasCheckedItems: boolean = false
  mobile = false
  searchText: string = ''
  displayedProducts: any[] = []
  public productsColumns: string[] = ['image', 'name', 'sku', 'stock', 'price', 'date', 'publish', 'action']
  
  constructor(private productsService: ProductsService, public builderService: BuilderService, private router: Router, private route: ActivatedRoute, private cdr: ChangeDetectorRef, private _liveAnnouncer: LiveAnnouncer) {}

  ngOnInit(): void {
    this.getAllData()
    if (window.screen.width <= 768) {
      this.mobile = true
    }
  }

  /**
   * Fetch all products and load the first page initially
   */
  getAllData() {
    this.productsService.getProducts().subscribe((response: any) => {
      if (response) {
        this.products = Object.values(response).map((product: any) => ({
          id: product.Id,
          image: product.imageUrl,
          name: product.productName,
          sku: product.productSKU,
          stock: product.productStock !== undefined ? product.productStock : '',
          price: product.salePrice ? `${product.regularPrice} / ${product.salePrice}` : product.regularPrice,
          date: product.date,
          action: '',
          publish: product.publish
        }))
        this.updateDisplayedProducts()
      }
    })
  }

  /**
   * Updates the displayed products based on pagination.
   */
  updateDisplayedProducts() {
    const startIndex = this.pageIndex * this.pageSize
    const endIndex = startIndex + this.pageSize
    this.displayedProducts = this.products.slice(startIndex, endIndex)
  }

  /**
   * Handles page change event to update displayed products.
   */
  onPageChange(event: PageEvent) {
    this.pageSize = event.pageSize
    this.pageIndex = event.pageIndex
    this.updateDisplayedProducts()
  }

  /**
   * Select/unselect all checkboxes in the current page.
   */
  toggleSelectAllItems() {
    const allItemsChecked = this.displayedProducts.every((item) => item.checked)
    this.displayedProducts.forEach((item) => (item.checked = !allItemsChecked))
    this.hasCheckedItems = !allItemsChecked
  }

  /**
   * Check if at least one item is selected.
   */
  onCheckboxItem() {
    this.hasCheckedItems = this.displayedProducts.some((item) => item.checked)
    this.cdr.detectChanges()
  }

  /**
   * Deletes selected products
   */
  deleteCheckedItems() {
    const checkedItems = this.displayedProducts.filter((item) => item.checked)
    if (checkedItems.length === 0) {
      console.log('No items selected for deletion.')
      return
    }

    const itemNames = checkedItems.map((item) => item.name).join(', ')

    const confirmation = window.confirm(`Are you sure you want to delete the selected items?\nItems: ${itemNames}`)
    this.builderService.showUpdateProgress = true
    if (confirmation) {
      console.log('Deleting items:', checkedItems)
      for (let index = 0; index < checkedItems.length; index++) {
        const element = checkedItems[index]
        this.deleteProduct(element.id, element.image, false)
      }
      this.products = this.products.filter((item) => !item.checked)
      this.updateDisplayedProducts()
      this.selectAllCheckbox.checked = false
      this.hasCheckedItems = false
    } else {
      console.log('Deletion canceled.')
    }
  }

  ngAfterViewInit(): void {
    this.dataSource.paginator = this.paginator
  }

  uncheckAllCheckboxes() {
    this.displayedProducts.forEach((item) => (item.checked = false))
    this.selectAllCheckbox.checked = false
    this.hasCheckedItems = false
    this.cdr.detectChanges()
  }

  deleteProduct = (id: string, imageName: string, singleItem = true) => {
    if (singleItem) {
      const confirmation = confirm('Are you sure you want to delete this product?')
      if (!confirmation) {
        return
      }
    }
    this.productsService.removeProduct(id).subscribe(() => {
      if (imageName) {
        this.builderService.deleteImage(imageName).subscribe(
          () => {
            this.getAllData()
          },
          (error) => {
            this.getAllData()
          }
        )
      } else {
        this.getAllData()
      }
      this.updateDisplayedProducts()
      this.builderService.showUpdateProgress = false
    })
  }

  editProduct(id: string) {
    this.router.navigate([`/products/new-product/${id}`], { relativeTo: this.route })
  }
  /** Announce the change in sort state for assistive technology. */
  announceSortChange(sortState: Sort) {
    // This example uses English messages. If your application supports
    // multiple language, you would internationalize these strings.
    // Furthermore, you can customize the message to add additional
    // details about the values being sorted.
    if (sortState.direction) {
      this._liveAnnouncer.announce(`Sorted ${sortState.direction}ending`)
    } else {
      this._liveAnnouncer.announce('Sorting cleared')
    }
  }

  applyFilter() {
    const filterValue = this.searchText.trim().toLowerCase()
    this.displayedProducts = this.products.filter((data) => data.name.toLowerCase().includes(filterValue) || data.sku.toLowerCase().includes(filterValue) || data.date.includes(filterValue))
  }

  /**
   * Change the product visibility to hide or show the product by sending the status to the db.
   */
  onToggleChange(product: productsTableColumns, event: any) {
    this.productsService.setProductStatus(product.id, event.checked).subscribe(response => {}) 
  }
}
