import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BuilderService } from 'src/app/builder-services/builder.service';
import { DatabaseService } from 'src/app/builder-services/database.service';

@Component({
  selector: 'customization',
  templateUrl: './customization.component.html',
  styleUrls: ['../points/points.component.scss'],
})
export class CustomizationComponent implements OnInit {
  points!: FormGroup;

  constructor(
    private fb: FormBuilder,
    private builderService: BuilderService,
    private db: DatabaseService
  ) {}

  ngOnInit(): void {
    this.createForm();
    this.updateFormFields();
  }

  updateFormFields() {
    const pointsPath = `/projects/${this.builderService.selectedProject}/settings/points/customization`;

    this.db.getDatabase(pointsPath).subscribe(
      (data) => {
        if (data) {
          this.points.patchValue(data); // Pre-fill form with data
        }
      },
      (error) => {
        console.error('Error loading points customization data:', error);
      }
    );
  }

  createForm() {
    this.points = this.fb.group({
      customization: ['', Validators.required],
      popupMessage: [''],
      pointsPushNotification: [false],
      notificationDate: [''],
      expirationDate: [''],
      termsConditions: [''],
    });
  }

  saveChanges() {
    const pointsPath = `/projects/${this.builderService.selectedProject}/settings/points/customization`;
    const pointsData = this.points.value;

    this.db.setDatabase(pointsPath, pointsData).subscribe(
      (response) => {
        console.log('Points customization saved successfully!', response);
      },
      (error) => {
        console.error('Error saving points customization:', error);
      }
    );
  }
}
